import * as React from 'react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import { observer } from 'mobx-react';
import { AddressEntity, BusinessEntity } from 'Models/Entities';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';
import { useEffect } from 'react';
import useAsync from 'Hooks/useAsync';
import If from '../If/If';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface ReferralPartnerBusinessFieldsProps {
    businessEntity: BusinessEntity,
    onAfterChange?: (validateSuccessful: boolean) => void,
    disabled?: boolean,
	showAddress?: boolean,
}

const ReferralPartnerBusinessFields = observer((props: ReferralPartnerBusinessFieldsProps) => {
	const {
		businessEntity, onAfterChange, disabled, showAddress,
	} = props;

	useEffect(() => {
		if (!businessEntity.address) {
			runInAction(() => {
				businessEntity.address = new AddressEntity();
			});
		}
	}, [businessEntity]);

	useAsync(async () => {
		const validationPromises = [];
		if (businessEntity.abn) {
			validationPromises.push(businessEntity.validateReferralPartnerField('abn'));
		}
		await Promise.all(validationPromises);
	}, [businessEntity]);

	/**
     * Handle for the change event for the inputs. This method will attempt to validate a given field
     * iff there are existing errors. So that the user can immediately see when their input stops the error
     * @param field The name of the field to be validated
     * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
     * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
     */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateReferralPartnerField(field);
		}

		if (field === 'abn' && businessEntity.abnFinishedValidating) {
			runInAction(() => {
				businessEntity.abnFinishedValidating = true;
				if (businessEntity) {
					businessEntity.name = businessEntity.entityName;  // Prefill organisation name
				}
			});
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(businessEntity.errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};

	return (
		<div className="organisation-fields">
			<TextField
				className="business-name"
				model={businessEntity}
				modelProperty="name"
				label="Business name"
				placeholder="Acme Pty Ltd"
				onChangeAndBlur={_ => onChange('name', true)}
				onAfterChange={_ => onChange('name')}
				errors={businessEntity.errors.name}
				isRequired
				isDisabled={disabled}
			/>

			<div>
				<IconTextBox
					className="business-number abn"
					model={businessEntity}
					modelProperty="abn"
					label="Australian Business Number (ABN)"
					placeholder="12 345 678 901"
					onChangeAndBlur={async () => {
						await onChange('abn', true);

						runInAction(() => {
							businessEntity.useCorporateTrusteeACN = '';
							businessEntity.trusteeACN = '';
							businessEntity.trusteeName = '';
							// @ts-ignore: settings dates to undefined
							businessEntity.dateOfBirth = null;
						});
					}}
					onAfterChange={debounce(async () => {
						await onChange('abn');

						runInAction(() => {
							businessEntity.useCorporateTrusteeACN = '';
							businessEntity.trusteeACN = '';
							businessEntity.trusteeName = '';
							// @ts-ignore: settings dates to undefined
							businessEntity.dateOfBirth = null;
						});
					}, 300)}
					errors={businessEntity.errors.abn}
					displayIconContainer={businessEntity.abnFinishedValidating}
					iconDisplay={businessEntity.abnFinishedValidating && businessEntity.entityStatus !== '' && businessEntity.entityStatus === 'Active'
						? IconDisplay.Valid : IconDisplay.Error}
					isDisabled={disabled}
				/>
				{businessEntity.entityName && (
					<small className="field-details">
						<b>Status:</b> { businessEntity.entityStatus }<br />
						<b>Type:</b> { businessEntity.entityTypeDescription }<br />
						<b>Entity Name:</b> { businessEntity.entityName }
					</small>
				)}
			</div>

			<TextField
				className="email"
				model={businessEntity}
				modelProperty="email"
				label="Business email"
				placeholder="hello@company.com"
				onChangeAndBlur={() => onChange('email', true)}
				onAfterChange={() => onChange('email')}
				errors={businessEntity.errors.email}
				isRequired
				isDisabled={disabled}
			/>

			<TextField
				className="phone"
				model={businessEntity}
				modelProperty="phone"
				label="Business phone number"
				placeholder="1300 123 456"
				onChangeAndBlur={_ => onChange('phone', true)}
				onAfterChange={_ => onChange('phone')}
				errors={businessEntity.errors.phone}
				isDisabled={disabled}
			/>

			<If condition={!!businessEntity.address && !!showAddress}>
				<TextField
					className="line-1 half-width"
					label="Address 1"
					placeholder="Address 1"
					model={businessEntity.address}
					modelProperty="line1"
					onChangeAndBlur={() => onChange('line1', true)}
					onAfterChange={() => onChange('line1')}
					isDisabled={disabled}
				/>
				<TextField
					className="line-2 half-width"
					label="Address 2"
					placeholder="Address 2"
					model={businessEntity.address}
					modelProperty="line2"
					onChangeAndBlur={() => onChange('line2', true)}
					onAfterChange={() => onChange('line2')}
					isDisabled={disabled}
				/>
				<TextField
					className="suburb half-width"
					label="Suburb"
					placeholder="Suburb"
					model={businessEntity.address}
					modelProperty="suburb"
					onChangeAndBlur={() => onChange('suburb', true)}
					onAfterChange={() => onChange('suburb')}
					isDisabled={disabled}
				/>
				<TextField
					className="state half-width"
					label="State"
					placeholder="State"
					model={businessEntity.address}
					modelProperty="state"
					onChangeAndBlur={() => onChange('state', true)}
					onAfterChange={() => onChange('state')}
					isDisabled={disabled}
				/>
				<TextField
					className="postcode half-width"
					label="Postcode"
					placeholder="Postcode"
					model={businessEntity.address}
					modelProperty="postcode"
					errors={businessEntity.errors['address.postcode']}
					onAfterChange={() => onChange('address.postcode')}
					onChangeAndBlur={() => onChange('address.postcode', true)}
					isDisabled={disabled}
				/>
				<TextField
					className="country half-width"
					label="Country"
					placeholder="Country"
					model={businessEntity.address}
					modelProperty="country"
					onChangeAndBlur={() => onChange('country', true)}
					onAfterChange={() => onChange('country')}
					isDisabled={disabled}
				/>
			</If>
		</div>
	);
});

export default ReferralPartnerBusinessFields;
