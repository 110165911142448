/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsigntemplatecontent from 'Models/Security/Acl/VisitorsEsigntemplatecontent';
import UserEsigntemplatecontent from 'Models/Security/Acl/UserEsigntemplatecontent';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsigntemplatecontentEntityAttributes extends IModelAttributes {
	version: number;
	content: string;
	requireswitness: boolean;
	usesmsauth: boolean;

	esigntemplateId: string;
	esigntemplate: Models.EsigntemplateEntity | Models.IEsigntemplateEntityAttributes;
	esignworkflows: Array<
		| Models.EsignworkflowEntity
		| Models.IEsignworkflowEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsigntemplatecontentEntity', 'ESignTemplateContent')
// % protected region % [Customise your entity metadata here] end
export default class EsigntemplatecontentEntity extends Model
	implements IEsigntemplatecontentEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsigntemplatecontent(),
		new UserEsigntemplatecontent(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsigntemplatecontentEntity, number>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public version: number;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Content'] off begin
	@observable
	@attribute<EsigntemplatecontentEntity, string>()
	@CRUD({
		name: 'Content',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public content: string;
	// % protected region % [Modify props to the crud options here for attribute 'Content'] end

	// % protected region % [Modify props to the crud options here for attribute 'RequiresWitness'] off begin
	@observable
	@attribute<EsigntemplatecontentEntity, boolean>()
	@CRUD({
		name: 'RequiresWitness',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public requireswitness: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'RequiresWitness'] end

	// % protected region % [Modify props to the crud options here for attribute 'UseSMSAuth'] off begin
	@observable
	@attribute<EsigntemplatecontentEntity, boolean>()
	@CRUD({
		name: 'UseSMSAuth',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public usesmsauth: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'UseSMSAuth'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplate'] off begin
		name: 'ESignTemplate',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.EsigntemplateEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplate'] end
	})
	public esigntemplateId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esigntemplate: Models.EsigntemplateEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflow'] off begin
		name: 'ESignWorkflows',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.EsignworkflowEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignworkflows',
			oppositeEntity: () => Models.EsignworkflowEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignWorkflow'] end
	})
	public esignworkflows: Models.EsignworkflowEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsigntemplatecontentEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsigntemplatecontentEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.content !== undefined) {
				this.content = attributes.content;
			}
			if (attributes.requireswitness !== undefined) {
				this.requireswitness = attributes.requireswitness;
			}
			if (attributes.usesmsauth !== undefined) {
				this.usesmsauth = attributes.usesmsauth;
			}
			if (attributes.esigntemplateId !== undefined) {
				this.esigntemplateId = attributes.esigntemplateId;
			}
			if (attributes.esigntemplate !== undefined) {
				if (attributes.esigntemplate === null) {
					this.esigntemplate = attributes.esigntemplate;
				} else if (attributes.esigntemplate instanceof Models.EsigntemplateEntity) {
					this.esigntemplate = attributes.esigntemplate;
					this.esigntemplateId = attributes.esigntemplate.id;
				} else {
					this.esigntemplate = new Models.EsigntemplateEntity(attributes.esigntemplate);
					this.esigntemplateId = this.esigntemplate.id;
				}
			}
			if (attributes.esignworkflows !== undefined && Array.isArray(attributes.esignworkflows)) {
				for (const model of attributes.esignworkflows) {
					if (model instanceof Models.EsignworkflowEntity) {
						this.esignworkflows.push(model);
					} else {
						this.esignworkflows.push(new Models.EsignworkflowEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		esigntemplate {
			${Models.EsigntemplateEntity.getAttributes().join('\n')}
		}
		esignworkflows {
			${Models.EsignworkflowEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			esignworkflows: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsigntemplatecontentEntity.prototype, 'created');
CRUD(modifiedAttr)(EsigntemplatecontentEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
