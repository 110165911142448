import { BusinessEntity, CustomerEntity } from 'Models/Entities';
// eslint-disable-next-line object-curly-newline
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { IconDisplay, IconTextBox } from 'Views/Components/TextBox/IconTextBox';
import React, { useCallback, useMemo } from 'react';
// eslint-disable-next-line object-curly-newline
import { acnStatusOptions, acnTypeOptions, state, stateOptions } from 'Models/Enums';
import { observer, useLocalStore } from 'mobx-react';

import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import GroupSelector from './GroupSelector';
import { LocaleCompareAttrAsc } from 'Util/StringUtils';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { debounce } from 'lodash';
import moment from 'moment';
import { runInAction } from 'mobx';
import { store } from 'Models/Store';
import If from 'Views/Components/If/If';
import LiquorLicenceDetailsTable from './LiquorLicenceSection/LiquorLicenceDetailsTable';
import ComboInput from 'Views/Components/ComboInput/ComboInput';

export interface AddCustomerProps {
}

const AddCustomer = observer((props: AddCustomerProps) => {
	const [showLiquorLicenceTable, setShowLiquorLicenceTable] = React.useState(false);

	const customer = useLocalStore(() => new CustomerEntity({
		organisationId: store.getUser?.organisation?.id,
	}));

	const editDisabled = useMemo(() => {
		return store.userPermissions.intelManageCustomerGroups !== true;
	}, []);

	const validateLiquorLicence = useCallback(async () => {
		await customer.validateField('drinksLicence');
	}, [customer]);

	const onSave = async () => {
		await customer.validateAddCustomerFields();

		if (Object.keys(customer.errors).filter(x => x !== 'acn').length === 0) {
			// Set relation path without group entity
			const relationPath = {
				groupss: {},
			};

			// Add groups to relationPath if we're adding new groups
			if (customer.groupss.some(gc => gc.groups.id === undefined)) {
				relationPath.groupss = {
					groups: {},
				};
			}
			await customer.save(relationPath);
			store.routerHistory.push(`/monitor/customers/${customer.id}`);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateLiquorLicence = useCallback(debounce(validateLiquorLicence, 300), [validateLiquorLicence]);

	return (
		<div className="add-customer">
			<p className="section-title">Contact information</p>
			<TextField
				className="first-name half-width"
				model={customer}
				modelProperty="firstName"
				label="First name"
				placeholder="First name"
			/>
			<TextField
				className="last-name half-width"
				model={customer}
				modelProperty="lastName"
				label="Last name"
				placeholder="Last name"
			/>
			<DatePicker
				className="date-of-birth"
				model={customer}
				modelProperty="dateOfBirth"
				label="Date of Birth"
				placeholder="Date of Birth"
			/>

			<p className="section-title">Business information</p>
			<ComboboxSetter
				className="business-entity-filter"
				value={customer.businessEntityId}
				setValue={value => {
					runInAction(() => {
						customer.businessEntityId = value;
					});
					customer.validateField('businessEntity');
				}}
				getOptionValue={(value?: string) => value}
				placeholder="Business entity"
				label="Business entity"
				searchable
				errors={customer.errors.businessEntity}
				options={store.getUser?.organisation?.businessEntitys
					.filter(BusinessEntity.IsLinkedToUser)
					.filter(x => x.enabledForMonitor)
					.sort(LocaleCompareAttrAsc('name'))
					.map(businessEntity => {
						return {
							display: businessEntity.name,
							value: businessEntity.id,
						};
					}) ?? []}
			/>
			<TextField
				className="business-name"
				model={customer}
				modelProperty="businessName"
				label="Business name"
				placeholder="Business name"
				onAfterChange={() => customer.validateField('businessName')}
				errors={customer.errors.businessName}
				isRequired
			/>
			<TextField
				className="debtor-id"
				model={customer}
				modelProperty="debtorID"
				label="Debtor ID"
				placeholder="Debtor ID"
				onAfterChange={() => {
					if (!!customer.errors.debtorID) {
						customer.validateField('debtorID');
					}
				}}
				errors={customer.errors.debtorID}
			/>
			<GroupSelector
				className="groups-customer"
				customer={customer}
				isDisabled={editDisabled}
			/>
			<IconTextBox
				className="abn"
				model={customer}
				modelProperty="abn"
				label="ABN"
				placeholder="ABN"
				onChangeAndBlur={() => customer.validateField('abn')}
				onAfterChange={debounce(() => customer.validateField('abn'), 300)}
				errors={customer.errors.abn}
				displayIconContainer={customer.abnFinishedValidating}
				iconDisplay={customer.entityStatus !== '' && customer.entityStatus === 'Active'
					? IconDisplay.Valid : IconDisplay.Error}
				isRequired
			/>
			<small className="field-details">
				<b>Status:</b> { customer.entityStatus }<br />
				<b>Type:</b> { customer.entityTypeDescription }<br />
				<b>Entity Name:</b> { customer.entityName }
			</small>
			<IconTextBox
				className="acn"
				model={customer}
				modelProperty="acn"
				label="ACN"
				placeholder="ACN"
				onChangeAndBlur={() => customer.validateField('acn')}
				onAfterChange={debounce(() => customer.validateField('acn'), 300)}
				errors={customer.errors.acn}
				displayIconContainer={customer.acnFinishedValidating}
				iconDisplay={customer.acnStatus !== '' && customer.acnStatus === 'REGD'
					? IconDisplay.Valid : IconDisplay.Warning}
			/>
			<small className="field-details">
				<b>Status:</b> { acnStatusOptions[customer.acnStatus] }<br />
				<b>Type:</b> { acnTypeOptions[customer.acnEntityTypeCode] }<br />
				<b>Entity Name:</b> { customer.acnEntityName }
			</small>
			<br />
			<p className="section-title">Liquor Licence information</p>
			<ComboInput
				label="Liquor Licence"
				errors={[customer.errors.drinksLicenceState, customer.errors.drinksLicence]}
			>
				<Combobox
					className="liquor-licence-state"
					model={customer}
					modelProperty="drinksLicenceState"
					label=""
					placeholder="State"
					options={(Object.keys(stateOptions) as [state])
						.map<ComboboxOption<state>>(value => {
							return {
								display: value === 'NONE' ? 'Select Jurisdiction' : stateOptions[value],
								value: value,
							};
						})}
					onAfterChange={debouncedValidateLiquorLicence}
					isRequired
					isDisabled={editDisabled}
					errors={customer.errors.drinksLicenceState}
					searchable={false}
				/>
				<IconTextBox
					model={customer}
					modelProperty="drinksLicence"
					placeholder="Licence Number"
					onChangeAndBlur={validateLiquorLicence}
					onAfterChange={debouncedValidateLiquorLicence}
					errors={customer.errors.drinksLicence}
					displayIconContainer={customer.liquorLicenceFinishedValidating}
					iconDisplay={customer.liquorLicenceResponseDetails?.responseCode === 0
						? IconDisplay.Valid : IconDisplay.Error}
					isRequired={false}
					isDisabled={editDisabled}
					label=""
				/>
			</ComboInput>
			<br />
			<small className="field-details">
				<If condition={Object.keys(customer.liquorLicenceResponseDetails?.data ?? {}).length > 1}>
					<Button
						className="liquor-licence-details__button"
						display={Display.Text}
						colors={Colors.Primary}
						onClick={() => setShowLiquorLicenceTable(true)}
					>
						{/* eslint-disable-next-line max-len */}
						{`${customer.selectedLiquorLicenceDetails ? 'View' : 'Choose'} Licence Details`}
					</Button>
					<LiquorLicenceDetailsTable
						liquorLicenceDetails={customer.liquorLicenceResponseDetails}
						liquorLicenceNumber={customer.drinksLicence}
						liquorLicenceState={customer.drinksLicenceState}
						previousUniqueId={customer.selectedLiquorLicenceDetails?.uniqueId}
						// eslint-disable-next-line max-len
						updateLiquorLicenceDetails={(uniqueId:string) => { customer.updateLiquorLicenceFields(uniqueId); }}
						openModal={showLiquorLicenceTable}
						closeModal={() => setShowLiquorLicenceTable(false)}
					/>
				</If>
				<br />
				<b>Status:</b> { customer.drinksLicenceStatus ? ` ${customer.drinksLicenceStatus}` : ' -'}
				<br />
				<b>Channel:</b>
				{/* eslint-disable-next-line max-len */}
				{ customer.selectedLiquorLicenceDetails && customer.selectedLiquorLicenceDetails
					? ` ${customer.selectedLiquorLicenceDetails.channel}`
					: ' -'}
				<br />
				<b>Sub Channel:</b>
				{/* eslint-disable-next-line max-len */}
				{ customer.selectedLiquorLicenceDetails && customer.selectedLiquorLicenceDetails
					? ` ${customer.selectedLiquorLicenceDetails.subChannel}`
					: ' -'}
				<br />
				<b>Channel Category: </b>
				{!customer.selectedLiquorLicenceDetails
					? '-'
					: customer.selectedLiquorLicenceDetails.channelCategory}
				<br />
				<b>Active Since: </b>
				{!customer.selectedLiquorLicenceDetails
					? ' -'
					// eslint-disable-next-line max-len
					: moment(customer.selectedLiquorLicenceDetails.dateCreated).format('Do MMM YYYY')}
			</small>

			<div className="button-container">
				<Button
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => store.routerHistory.push('/monitor/customers')}
				>
					Cancel
				</Button>
				<ButtonAsyncState
					colors={Colors.Primary}
					display={Display.Solid}
					readonly={Object.keys(customer.errors).filter(x => x !== 'acn').length > 0}
					onPress={onSave}
				>
					Add
				</ButtonAsyncState>
			</div>
		</div>
	);
});
export default AddCustomer;
