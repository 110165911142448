import * as React from 'react';

import { CustomerEntity } from 'Models/Entities';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import classNames from 'classnames';
import useAsync from 'Hooks/useAsync';
import { useMemo } from 'react';
import { IAdverseAlertsSummary, AdverseAlertsSummary } from '../AdverseAlerts/AdverseAlertsSummary';

const CustomerOverviewAdverseAlerts = (props: {
	customer: CustomerEntity,
	onAdverseAlertsClick: () => void,
}) => {
	const { customer, onAdverseAlertsClick } = props;

	const adverseAlertsSummary = useAsync<AdverseAlertsSummary>(async () => {
		const { data } = await axios.get(`${SERVER_URL}/api/entity/AlaresEntity/customer-summary/${customer.id}`);
		const properties = data as IAdverseAlertsSummary;
		return new AdverseAlertsSummary(properties);
	}, []);

	const responseCompleted = useMemo<boolean>(() => adverseAlertsSummary.type === 'data', [adverseAlertsSummary]);
	const totalCount = useMemo<number>(() => {
		if (adverseAlertsSummary.type === 'data') {
			return adverseAlertsSummary.data.totalCount;
		}
		return customer.newestAlares?.adverseAlerts ?? 0;
	}, [adverseAlertsSummary, customer.newestAlares]);

	function renderSummary(summaryName: string, completed: boolean, count?: number) {
		const adjustedCount = completed ? count ?? 0 : '-';
		return (
			<div>
				<p className="field-name">{summaryName}</p>
				<p className={classNames('count', {
					highlight: typeof adjustedCount === 'number' && adjustedCount > 0,
				})}
				>
					{adjustedCount}
				</p>
			</div>
		);
	}

	return (
		<div className="overview-adverse-alerts" {...HandleEvents(onAdverseAlertsClick)}>
			<div className="header">
				<h6>Adverse Alerts</h6>
				<If condition={totalCount > 0}>
					<div className="adverse-alerts-icon">
						<span className="icon icon-only icon-material-error" />
					</div>
				</If>
			</div>
			<div className="counts">
				{renderSummary('ATO Defaults', responseCompleted, adverseAlertsSummary.data?.atoDefaults)}
				{renderSummary(
					'Court Cases & Adjudications',
					responseCompleted, 
					(adverseAlertsSummary.data?.courtCases ?? 0) + (adverseAlertsSummary.data?.adjudications ?? 0),
				)}
				{renderSummary('Insolvencies', responseCompleted, adverseAlertsSummary.data?.insolvencies)}
				<a
					href="/"
					className="icon-right icon-material-north-east"
				>
					View adverse alerts
				</a>
			</div>
		</div>
	);
};

export default CustomerOverviewAdverseAlerts;
