import { Editor } from '@monaco-editor/react';
import React, { useEffect, useMemo } from 'react';
import editorOptions from './EditorConfig';
import classNames from 'classnames';
import If from 'Views/Components/If/If';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import ESignTemplateContentInputFields from '../ESignTemplateContentPart/ESignTemplateContentInputFields';
import HtmlTemplateUtil from '../ESignTemplateContentPart/HtmlTemplateUtil';

export interface DocumentContentDetail {
	availableFieldIds: string[];
	preFillFields: Record<string, string[]>;
	requiredFields: Record<string, {signature: string, witness?: string}>;
	htmlString: string;
}

interface Props {
	numberOfSignatory: number;
	requireWitness: boolean;
	templateContentDetail: DocumentContentDetail;
	updateTemplateContent: (content: string) => void;
	isReadOnly?: boolean;
}

const TemplateContent: React.FunctionComponent<Props> = observer(({
	numberOfSignatory,
	requireWitness,
	templateContentDetail,
	updateTemplateContent,
	isReadOnly = true,
}) => {
	const initialContent = useMemo(() => {
		const initialCssContent = templateContentDetail.htmlString?.match(/<style>([\s\S]*?)<\/style>/s)?.[1] ?? '';

		const hasStyleTag = templateContentDetail.htmlString?.includes('<style>');
		let initialHtmlContent = templateContentDetail.htmlString;
		if (hasStyleTag) {
			initialHtmlContent = templateContentDetail.htmlString.replace(/<style>[\s\S]*?<\/style>/g, '');
		}

		return {
			initialHtmlContent,
			initialCssContent,
		};
	}, [templateContentDetail]);

	const [htmlContent, setHtmlContent] = React.useState(initialContent.initialHtmlContent);
	const [cssContent, setCssContent] = React.useState(initialContent.initialCssContent);
	const [expandContent, setExpandContent] = React.useState(false);

	const modifyAndUpdateContent = (htmlString: string) => {
		const requiredFieldsWithIdAndName = HtmlTemplateUtil.requiredFieldsWithIdAndNames(
			numberOfSignatory,
			requireWitness,
		);
		const getFieldIdFromHtmlString = HtmlTemplateUtil.getFieldIdFromHtmlString(htmlString);
		const availablePreFillFields = HtmlTemplateUtil.availablePreFillFields(htmlString);

		const UpdatedTemplateContentDetail: DocumentContentDetail = {
			availableFieldIds: getFieldIdFromHtmlString,
			preFillFields: availablePreFillFields,
			requiredFields: requiredFieldsWithIdAndName,
			htmlString: htmlString,
		};

		// stringify the object and update the content
		updateTemplateContent(JSON.stringify(UpdatedTemplateContentDetail));
	};

	const handleHtmlChange = (value: string) => {
		setHtmlContent(value);

		// add css to the template content
		runInAction(() => {
			const hasStyleTag = value.includes('<style>');
			let updatedContent = value;

			if (hasStyleTag) {
				updatedContent = value.replace(/<style>.*<\/style>/, `<style>${cssContent}</style>`);
			} else {
				updatedContent = value.replace('</head>', `<style>${cssContent}</style></head>`);
			}
			modifyAndUpdateContent(updatedContent);
		});
	};

	const handleCssChange = (value: string) => {
		setCssContent(value);
		runInAction(() => {
			const hasStyleTag = templateContentDetail.htmlString.includes('<style>');
			let updatedContent = templateContentDetail.htmlString;
			if (hasStyleTag) {
				updatedContent = templateContentDetail.htmlString
					.replace(/<style>.*<\/style>/, `<style>${value}</style>`);
			} else {
				updatedContent = templateContentDetail.htmlString
					.replace('</head>', `<style>${value}</style></head>`);
			}
			modifyAndUpdateContent(updatedContent);
		});
	};

	// update the content when the version changes
	useEffect(() => {
		setHtmlContent(initialContent.initialHtmlContent);
		setCssContent(initialContent.initialCssContent);
	}, [initialContent.initialCssContent, initialContent.initialHtmlContent]);

	return (
		<>
			<If condition={!isReadOnly}>
				<ESignTemplateContentInputFields
					numberOfSignatory={numberOfSignatory}
					requireWitness={requireWitness}
					documentContentDetail={templateContentDetail}
				/>
			</If>
			<div className={classNames(
				'template-content-container',
				{
					'expand-content': expandContent,
				},
			)}
			>
				<If condition={!isReadOnly && !expandContent}>
					<div className="editor-section">
						<p>HTML</p>
						<Editor
							height="40vh"
							className="html-editor"
							options={editorOptions}
							defaultLanguage="html"
							theme="vs-dark"
							value={htmlContent}
							onChange={value => {
								handleHtmlChange(value ?? '');
							}}
						/>
					</div>
					<div className="editor-section">
						<p>CSS</p>
						<Editor
							height="40vh"
							className="css-editor"
							options={editorOptions}
							defaultLanguage="css"
							theme="vs-dark"
							value={cssContent}
							onChange={value => {
								handleCssChange(value ?? '');
							}}
						/>
					</div>
				</If>
				<div className={classNames(
					'preview-section',
					{
						'is-read-only': isReadOnly,
					},
				)}
				>
					<If condition={!isReadOnly && !expandContent}>
						<hr />
					</If>
					<div className="preview-section__header">
						<p>{isReadOnly ? 'Content' : 'Result'}</p>
						<If condition={!isReadOnly}>
							<Button
								colors={Colors.None}
								sizes={Sizes.Small}
								display={Display.Text}
								icon={{ icon: expandContent ? 'collapse-right' : 'expand-2', iconPos: 'icon-left' }}
								onClick={() => setExpandContent(preState => !preState)}
							/>
						</If>
					</div>
					<iframe
						className="preview-section__iframe"
						title="preview"
						srcDoc={templateContentDetail.htmlString}
					/>
				</div>
			</div>
		</>
	);
});

export default TemplateContent;
