/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsingworkflow from 'Models/Security/Acl/VisitorsEsingworkflow';
import UserEsingworkflow from 'Models/Security/Acl/UserEsingworkflow';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsignworkflowEntityAttributes extends IModelAttributes {
	status: Enums.esignworkflowstatus;
	stage: Enums.esignworkflowstage;
	signatorycount: number;
	rejectioncount: number;
	notificationsemail: string;
	requestcontent: string;
	legacyapplicationid: number;
	base64signedcontract: string;

	esignceremonysigneess: Array<
		| Models.EsignceremonysigneeEntity
		| Models.IEsignceremonysigneeEntityAttributes
	>;
	esigntemplatecontentId: string;
	esigntemplatecontent: Models.EsigntemplatecontentEntity | Models.IEsigntemplatecontentEntityAttributes;
	esigntemplateId: string;
	esigntemplate: Models.EsigntemplateEntity | Models.IEsigntemplateEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsignworkflowEntity', 'ESignWorkflow')
// % protected region % [Customise your entity metadata here] end
export default class EsignworkflowEntity extends Model
	implements IEsignworkflowEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsingworkflow(),
		new UserEsingworkflow(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<EsignworkflowEntity, Enums.esignworkflowstatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.esignworkflowstatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.esignworkflowstatusOptions),
		displayFunction: (attr: Enums.esignworkflowstatus) => Enums.esignworkflowstatusOptions[attr],
	})
	public status: Enums.esignworkflowstatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Stage'] off begin
	@observable
	@attribute<EsignworkflowEntity, Enums.esignworkflowstage>()
	@CRUD({
		name: 'Stage',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.esignworkflowstageOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.esignworkflowstageOptions),
		displayFunction: (attr: Enums.esignworkflowstage) => Enums.esignworkflowstageOptions[attr],
	})
	public stage: Enums.esignworkflowstage;
	// % protected region % [Modify props to the crud options here for attribute 'Stage'] end

	// % protected region % [Modify props to the crud options here for attribute 'SignatoryCount'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsignworkflowEntity, number>()
	@CRUD({
		name: 'SignatoryCount',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public signatorycount: number;
	// % protected region % [Modify props to the crud options here for attribute 'SignatoryCount'] end

	// % protected region % [Modify props to the crud options here for attribute 'RejectionCount'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsignworkflowEntity, number>()
	@CRUD({
		name: 'RejectionCount',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public rejectioncount: number;
	// % protected region % [Modify props to the crud options here for attribute 'RejectionCount'] end

	// % protected region % [Modify props to the crud options here for attribute 'NotificationsEmail'] off begin
	@observable
	@attribute<EsignworkflowEntity, string>()
	@CRUD({
		name: 'NotificationsEmail',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public notificationsemail: string;
	// % protected region % [Modify props to the crud options here for attribute 'NotificationsEmail'] end

	// % protected region % [Modify props to the crud options here for attribute 'RequestContent'] off begin
	@observable
	@attribute<EsignworkflowEntity, string>()
	@CRUD({
		name: 'RequestContent',
		displayType: 'textarea',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public requestcontent: string;
	// % protected region % [Modify props to the crud options here for attribute 'RequestContent'] end

	// % protected region % [Modify props to the crud options here for attribute 'LegacyApplicationID'] off begin
	@Validators.Integer()
	@observable
	@attribute<EsignworkflowEntity, number>()
	@CRUD({
		name: 'LegacyApplicationID',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyapplicationid: number;
	// % protected region % [Modify props to the crud options here for attribute 'LegacyApplicationID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Base64SignedContract'] off begin
	@observable
	@attribute<EsignworkflowEntity, string>()
	@CRUD({
		name: 'Base64SignedContract',
		displayType: 'textarea',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public base64signedcontract: string;
	// % protected region % [Modify props to the crud options here for attribute 'Base64SignedContract'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignees'] off begin
		name: 'ESignCeremonySigneess',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.EsignceremonysigneeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignceremonysigneess',
			oppositeEntity: () => Models.EsignceremonysigneeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignees'] end
	})
	public esignceremonysigneess: Models.EsignceremonysigneeEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplateContent'] off begin
		name: 'ESignTemplateContent',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.EsigntemplatecontentEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplateContent'] end
	})
	public esigntemplatecontentId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esigntemplatecontent: Models.EsigntemplatecontentEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplate'] off begin
		name: 'ESignTemplate',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.EsigntemplateEntity,
		// % protected region % [Modify props to the crud options here for reference 'ESignTemplate'] end
	})
	public esigntemplateId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public esigntemplate: Models.EsigntemplateEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsignworkflowEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsignworkflowEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.stage !== undefined) {
				this.stage = attributes.stage;
			}
			if (attributes.signatorycount !== undefined) {
				this.signatorycount = attributes.signatorycount;
			}
			if (attributes.rejectioncount !== undefined) {
				this.rejectioncount = attributes.rejectioncount;
			}
			if (attributes.notificationsemail !== undefined) {
				this.notificationsemail = attributes.notificationsemail;
			}
			if (attributes.requestcontent !== undefined) {
				this.requestcontent = attributes.requestcontent;
			}
			if (attributes.legacyapplicationid !== undefined) {
				this.legacyapplicationid = attributes.legacyapplicationid;
			}
			if (attributes.base64signedcontract !== undefined) {
				this.base64signedcontract = attributes.base64signedcontract;
			}
			if (attributes.esignceremonysigneess !== undefined && Array.isArray(attributes.esignceremonysigneess)) {
				for (const model of attributes.esignceremonysigneess) {
					if (model instanceof Models.EsignceremonysigneeEntity) {
						this.esignceremonysigneess.push(model);
					} else {
						this.esignceremonysigneess.push(new Models.EsignceremonysigneeEntity(model));
					}
				}
			}
			if (attributes.esigntemplatecontentId !== undefined) {
				this.esigntemplatecontentId = attributes.esigntemplatecontentId;
			}
			if (attributes.esigntemplatecontent !== undefined) {
				if (attributes.esigntemplatecontent === null) {
					this.esigntemplatecontent = attributes.esigntemplatecontent;
				} else if (attributes.esigntemplatecontent instanceof Models.EsigntemplatecontentEntity) {
					this.esigntemplatecontent = attributes.esigntemplatecontent;
					this.esigntemplatecontentId = attributes.esigntemplatecontent.id;
				} else {
					this.esigntemplatecontent = new Models.EsigntemplatecontentEntity(attributes.esigntemplatecontent);
					this.esigntemplatecontentId = this.esigntemplatecontent.id;
				}
			}
			if (attributes.esigntemplateId !== undefined) {
				this.esigntemplateId = attributes.esigntemplateId;
			}
			if (attributes.esigntemplate !== undefined) {
				if (attributes.esigntemplate === null) {
					this.esigntemplate = attributes.esigntemplate;
				} else if (attributes.esigntemplate instanceof Models.EsigntemplateEntity) {
					this.esigntemplate = attributes.esigntemplate;
					this.esigntemplateId = attributes.esigntemplate.id;
				} else {
					this.esigntemplate = new Models.EsigntemplateEntity(attributes.esigntemplate);
					this.esigntemplateId = this.esigntemplate.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		esignceremonysigneess {
			${Models.EsignceremonysigneeEntity.getAttributes().join('\n')}
		}
		esigntemplatecontent {
			${Models.EsigntemplatecontentEntity.getAttributes().join('\n')}
		}
		esigntemplate {
			${Models.EsigntemplateEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			esignceremonysigneess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsignworkflowEntity.prototype, 'created');
CRUD(modifiedAttr)(EsignworkflowEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
