import { Tooltip } from '../Tooltip/Tooltip';
import { Combobox } from '../Combobox/Combobox';
import classNames from 'classnames';
import * as React from 'react';
import { observer } from 'mobx-react';

export interface EntityActiveStatusProps<T> {
	model: T,
	modelProperty: string,
	statusText: string,
	disabled?: boolean,
	tooltip?: string,
	onAfterChange?: () => Promise<void>,
}

// component for rendering the active/inactive status dropdown for a business entity.
// handles saving of the business entity. assumes the prop is an observable
function EntityActiveStatus<T>(props: EntityActiveStatusProps<T>) {
	const {
		tooltip, disabled, onAfterChange, model, modelProperty, statusText,
	} = props;

	return (
		<div className="active-status-container">
			{!tooltip ? '' : (
				<Tooltip
					id="active-status-tooltip"
					content={tooltip}
				/>
			)}
			<Combobox
				label=""
				options={[{ display: `${statusText} Active`, value: true },
					{ display: `${statusText} Inactive`, value: false },
				]}
				model={model}
				className={classNames(
					model[modelProperty] ? 'active' : 'inactive',
					'active-dropdown',
					{ disabled: disabled },
				)}
				modelProperty={modelProperty}
				onAfterChange={onAfterChange}
				isDisabled={disabled}
				searchable={false}
			/>
		</div>
	);
}

export default observer(EntityActiveStatus);
