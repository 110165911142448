import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardSidebarComponentProps } from 'Views/Components/Wizard/Wizard';
import classNames from 'classnames';
import If from 'Views/Components/If/If';

const WizardProgressSummary = observer((props: WizardSidebarComponentProps) => {
	const { currentSlide, stepInformation, changePage } = props;

	// If there are any minimised steps, we don't show them if we haven't reached them
	// However, if our current index is greater or equal to one, then we show them all
	const firstMinimisedSlide = stepInformation.findIndex(x => x.minimisedUntilReached);
	const minimiseSlides = currentSlide < firstMinimisedSlide;
	const slidesToShow = stepInformation
		.map((x, index) => ({
			slideIndex: index,
			minimisedUntilReached: x.minimisedUntilReached,
			name: x.name,
			subtitle: x.sidebarSubtitle,
			hideSlide: x.hideSlide || x.hideStepInSidebar,
		}))
		.filter(x => x.hideSlide !== true)
		.filter(x => !minimiseSlides || !x.minimisedUntilReached);

	if (minimiseSlides && slidesToShow.length !== stepInformation.length) {
		slidesToShow.push({
			slideIndex: firstMinimisedSlide,
			minimisedUntilReached: false,
			hideSlide: false,
			name: '...',
			subtitle: undefined,
		});
	}

	return (
		<div className="wizard-progress-summary">
			<h3>Current progress</h3>
			<div className="progress-item-list">
				{slidesToShow.map(slide => (
					<button
						className={classNames('progress-item', {
							hoverable: currentSlide > slide.slideIndex,
							'not-allowed': currentSlide < slide.slideIndex,
						})}
						tabIndex={currentSlide >= slide.slideIndex ? 0 : -1}
						key={slide.name + slide.slideIndex}
						onClick={e => {
							changePage(false, slide.slideIndex);
							if (e.detail !== 0) {
								// Detail is used to detect the number of times the element was clicked, to detect double-clicks
								// But it's always 0 for keyboard events, so this allows us to differentiate between a user
								// who clicks this element, vs. a user who tabs to it and presses Enter. We want to prevent
								// the button from staying focused when clicked, without messing with keyboard users.
								e.currentTarget.blur();
							}
						}}
						disabled={currentSlide < slide.slideIndex}
					>
						<span
							className={classNames(
								'progress-icon',
								{
									'current-slide': currentSlide === slide.slideIndex,
									'previous-slide': currentSlide > slide.slideIndex,
								},
							)}
						/>
						<div className="progress-title">
							{slide.name}

							<If condition={!!slide.subtitle}>
								<div className="progress-subtitle">{slide.subtitle}</div>
							</If>
						</div>
					</button>
				))}
			</div>
		</div>
	);
});

export default WizardProgressSummary;
