import * as React from 'react';
import {
	registrationStatus, registrationWithCreatedComboboxOptions,
} from '../../../Models/Enums';
import { IWhereCondition } from '../ModelCollection/ModelQuery';
import { buildSearchConditions } from '../../../Util/GraphQLUtils';
import moment from 'moment/moment';
import { gql } from '@apollo/client';
import { store } from '../../../Models/Store';
import { OrganisationEntity, RegistrationEntity } from '../../../Models/Entities';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useMemo, useState } from 'react';
import { TextFieldSetter } from '../TextBox/TextFieldSetter';
import classNames from 'classnames';
import { Button, Colors, Display } from '../Button/Button';
import ComboboxSetter from '../Combobox/ComboboxSetter';
import { DatePicker } from '../DatePicker/DatePicker';
import If from '../If/If';
import * as Enums from '../../../Models/Enums';
import RequestWrap, { GetRefreshKey } from '../RequestWrap/RequestWrap';
import EntityList from '../EntityList/EntityList';
import RegistrationStatusBox from '../Registration/RegistrationStatusBox';
import MeatballMenu from '../EntityList/MeatballMenu';
import TablePagination from '../Pagination/TablePagination';
import ProductSelectedBox from '../Clients/ProductSelectedBox';
import { confirmModal } from '../Modal/ModalUtils';
import alertToast from '../../../Util/ToastifyUtils';
import GenerateReferralModal from './GenerateReferralModal';
import * as ReferralPartnerUtils from '../../../Util/ReferralPartnerUtils';
import { buildBlob } from '../../../Util/CsvUtils';
import { saveAs } from 'file-saver';

// Method to debounce and fetch the referrals
const fetchReferralsDebounced = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	status: registrationStatus | 'ALL',
	dateRange: string,
	setCount: (newCount: number) => void,
) => {
	return fetchReferrals(
		search,
		sortColumn,
		sortDescending,
		page,
		status,
		dateRange,
		setCount,
	);
}, 300, {
	leading: true,
});

const fetchReferrals = (async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	status: registrationStatus | 'ALL',
	dateRange: string,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['userName', 'organisationName', 'userEmail'], search, comparison);
	}

	// Setup status filter
	const statusConditions: IWhereCondition<string>[] = [];
	if (!!status && status !== 'ALL') {
		statusConditions.push({
			comparison: 'equal', path: 'status', value: status,
		});
	}

	// Setup referrer start / end date type filter
	const startDateConditions: IWhereCondition<string>[] = [];
	const endDateConditions: IWhereCondition<string>[] = [];

	if (dateRange.length > 0) {
		startDateConditions.push({
			path: 'created',
			value: [moment(dateRange[0]).format('YYYY-MM-DD')],
			comparison: 'greaterThanOrEqual',
		});

		endDateConditions.push({
			path: 'created',
			value: [moment(dateRange[1]).format('YYYY-MM-DD')],
			comparison: 'lessThanOrEqual',
		});
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];
	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}
	if (statusConditions.length > 0) {
		allConditions.push(statusConditions);
	}
	if (startDateConditions.length > 0) {
		allConditions.push(startDateConditions);
	}
	if (endDateConditions.length > 0) {
		allConditions.push(endDateConditions);
	}

	// Add sorting
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: sortColumn, descending: sortDescending });

	const query = gql`
		query registrationEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]]
		) {
			registrationEntitys(conditions: $conditions, skip: $skip, take:$take, orderBy: $orderBy) {
				id
				userName
				organisationName
				userEmail
				created
				registrationData
				status
				products
				startDate
				completedDate
			}
			${!noPagination ? `countRegistrationEntitys(conditions: $conditions) {
				number
			}` : ''}
		}
	`;

	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: REGISTRATIONS_PAGE_LENGTH,
			skip: REGISTRATIONS_PAGE_LENGTH * (page || 0),
			conditions: allConditions,
		},
	});
	if (!noPagination) {
		setCount(data.countRegistrationEntitys.number);
	}
	return data.registrationEntitys.map((registration: any) => new
	RegistrationEntity(registration)) as RegistrationEntity[];
});

const ReferralList = () => {
	const [search, setSearch] = useState('');
	// default to sort by newest, the user can't actually manually sort by this but I think most users won't have it
	// sorted and this helps for new referral generation to appear at the top
	const [sortColumn, setSortColumn] = useState('created');
	const [sortDescending, setSortDescending] = useState(true);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [status, setStatus] = useState('ALL' as registrationStatus | 'ALL');
	const [dateRange, setDateRange] = useState('');
	const [triggerUpdate, setTriggerUpdate] = useState(0); // Increment to force the referral list to refresh
	const [generateModalOpen, setGenerateModalOpen] = useState(false);

	const dateEntity = useMemo(() => {
		return {
			value: dateRange,
		};
	}, [dateRange]);

	/**
	 * Callback for sorting changes
	 * @param header The name of the sorting column
	 */
	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};

	// Method to fetch and return the OrganisationEntity based on the page number and sorting
	const fetchReferralsForExport = async () => {
		const registrations: RegistrationEntity[] = await fetchReferrals(
			search,
			sortColumn,
			sortDescending,
			page,
			status,
			dateRange,
			setCount,
			true,
		);
		const result = [] as string[][];
		const columns = [
			{
				displayName: 'Name',
				value: ({ userName }: RegistrationEntity) => userName,
			},
			{
				displayName: 'Organisation',
				value: ({ organisationName }: RegistrationEntity) => organisationName,
			},
			{
				displayName: 'Email',
				value: ({ userEmail }: RegistrationEntity) => userEmail,
			},
			{
				displayName: 'Start date',
				value: ({ startDate }: RegistrationEntity) => !startDate ? 'N/A' : moment(startDate)
					.format('DD/MM/YYYY'),
			},
			{
				displayName: 'Completed date',
				value: ({ completedDate }: RegistrationEntity) => !completedDate ? 'N/A' : moment(completedDate)
					.format('DD/MM/YYYY'),
			},
			{
				displayName: 'Products',
				value: ({ products }: RegistrationEntity) => products,
			},
			{
				displayName: 'Status',
				value: ({ status: referralStatus }: RegistrationEntity) => referralStatus,
			},
		];
		const headers = columns.map(column => column.displayName);
		result.push(headers);
		registrations.forEach(registration => {
			result.push(
				columns.map(column => column.value(registration) ?? ''),
			);
		});
		return result;
	};

	/*
	 * method to handle deleting a created registration, opens a confirmation modal. this method should only be called on
	 * created registrations. However, security should handle not deleting registrations that shouldn't be deleted
	 */
	const deleteRegistration = async (registration: RegistrationEntity) => {
		try {
			await confirmModal(
				'Delete registration?',
				'If you delete this registration, the generated referral link will no longer be functional',
				{
					confirmText: 'Delete',
				},
			);
		} catch {
			return false;
		}

		try {
			await registration.delete();
			return true;
		} catch (e: any) {
			alertToast('Registration could not be deleted, please refresh and try again', 'error');
		}
		return false;
	};

	return (
		<div className="referrals-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						labelVisible={false}
						placeholder="Search by Name, Organisation or Email"
					/>

					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => {
							setShowFilters(show => !show);
							setStatus('ALL');
						}}
					>
						Filter By
					</Button>
				</div>
				<div className="actions">
					<Button
						className="generate-referral-btn"
						icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={_ => { setGenerateModalOpen(true); }}
					>
						Generate Referral
					</Button>

					<Button
						className="export-button"
						colors={Colors.Alternate}
						display={Display.Outline}
						onClick={async () => {
							confirmModal(
								'Export CSV',
								'Export your referrals and save to a CSV file?',
								{
									cancelText: 'Cancel',
									confirmText: 'Export',
								},
							).then(async () => {
								const exportClients = await fetchReferralsForExport();
								const blob = buildBlob(exportClients, ',', '"');
								saveAs(blob, `exported-referrals-${moment().format('YYYY-MM-DD')}.csv`);
							});
						}}
					>
						Export CSV
					</Button>
				</div>
				<If condition={showFilters}>
					<div className="search-filter-section referral-partner-list">
						<ComboboxSetter
							className="status-filter"
							value={status}
							setValue={setStatus}
							getOptionValue={(value?: string) => value}
							placeholder="Status"
							label=""
							labelVisible={false}
							searchable
							options={registrationWithCreatedComboboxOptions}
						/>
						<DatePicker
							className="start-date-filter"
							model={dateEntity}
							modelProperty="value"
							mode="range"
							placeholder="All Start Dates"
							onAfterChange={() => {
								if (!!dateEntity.value) {
									setDateRange(dateEntity.value);
								}
							}}
						/>
					</div>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the Registration List */}
				<RequestWrap
					request={() => fetchReferralsDebounced(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
						dateRange,
						setCount,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
						dateRange,
						triggerUpdate,
					)}
				>
					{(registrations: RegistrationEntity[]) => (
						<>
							<EntityList
								collection={registrations}
								columns={[
									{
										displayName: 'Name',
										columnName: 'userName',
										value: registration => registration.userName
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => { onSortChange('userName'); },
										className: 'field-user-name',
									},
									{
										displayName: 'Organisation',
										columnName: 'organisationName',
										value: registration => registration.organisationName
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => { onSortChange('organisationName'); },
										className: 'field-organisation-name',
									},
									{
										displayName: 'Email Address',
										columnName: 'userEmail',
										value: registration => registration?.userEmail
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => { onSortChange('userEmail'); },
										className: 'field-user-email',
									},
									{
										displayName: 'Start Date',
										columnName: 'startDate',
										value: registration => !!registration.startDate
											? moment(registration.startDate).format('DD/MM/YYYY')
											: <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('startDate');
										},
										className: 'field-start-date',
									},
									{
										displayName: 'Products',
										columnName: 'products',
										value: registration => !!registration.products ? (
											<ProductSelectedBox
												ppsrEnabled={registration.products.includes('PPSR')}
												approveEnabled={registration.products.includes('Approve')}
												intelEnabled={registration.products.includes('Monitor')}
											/>
										) : <span className="not-provided">No Selection</span>,
										sortable: false,
										className: 'field-products',
									},
									{
										displayName: 'Status',
										columnName: 'status',
										value: registration => {
											return (
												<RegistrationStatusBox
													status={registration.status}
												/>
											);
										},
										sortable: true,
										sortClicked: () => {
											onSortChange('status');
										},
										className: 'field-status',
									},
									{
										displayName: '',
										columnName: 'meatball-menu',
										value: registration => (
											<MeatballMenu actions={[
												{
													display: (
														<span className="icon-left icon-bin-2">
															Delete Referral
														</span>
													),
													action: async () => {
														if (await deleteRegistration(registration)) {
															setTriggerUpdate(prev => prev + 1);
														}
													},
													visible: registration.status === 'CREATED',
												},
												{
													display: (
														<span className="icon-left icon-content-copy">
															Copy Link
														</span>
													),
													action: () => {
														ReferralPartnerUtils
															.copyReferralLinkToClipboard(registration.id);
													},
													visible: true,
												},
												{
													display: (
														<span className="icon-left icon-mail">
															Send Via Email
														</span>
													),
													action: () => {
														window.open(ReferralPartnerUtils.getMailToLink(registration
															.userName, registration.userEmail, registration.id, store
															.userDisplayName));
													},
													visible: true,
												},
											]}
											/>
										),
										sortable: false,
										className: 'meatball-menu',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								rowClassName={() => 'no-click'}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={REGISTRATIONS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
			<If condition={generateModalOpen}>
				<GenerateReferralModal
					onClose={() => setGenerateModalOpen(false)}
					onLinkGeneration={() => setTriggerUpdate(oldValue => oldValue + 1)}
				/>
			</If>
		</div>
	);
};

export default ReferralList;

export const REGISTRATIONS_PAGE_LENGTH = 10;
