export interface IAdverseAlertsSummary {
	courtCases: number,
	adjudications: number,
	insolvencies: number,
	atoDefaults: number,
}

export class AdverseAlertsSummary implements IAdverseAlertsSummary {
	get courtCases(): number {
		return this.data.courtCases ?? 0;
	}

	get adjudications(): number {
		return this.data.adjudications ?? 0;
	}

	get insolvencies(): number {
		return this.data.insolvencies ?? 0;
	}

	get atoDefaults(): number {
		return this.data.atoDefaults ?? 0;
	}

	get totalCount(): number {
		let sum = 0;
		['courtCases', 'adjudications', 'insolvencies', 'atoDefaults'].forEach(field => {
			sum += this.data[field] ?? 0;
		});
		return sum;

	}

	readonly data: Partial<IAdverseAlertsSummary>;

	constructor(properties: Partial<IAdverseAlertsSummary>) {
		this.data = properties;
	}
}