import * as React from 'react';

export interface InlineSpinnerProps {
	size?: 'Large'|'Small';
	width?: 'Full'|'None';
}

export default function InlineSpinner(props: InlineSpinnerProps) {
	const {
		size = 'Large',
		width = 'Full',
	} = props;

	return (
		<div className={`spinner-container inline size--${size} width--${width}`}>
			<div className="simple-loader" />
		</div>
	);
}
