import { ESignUserSigneeCeremony, ESignUserWitnessCeremony } from 'ESign/Types/Summary/ESignUserCeremony';
import { observer } from 'mobx-react';
import { SigningDocumentContentDetail } from '../ESignSigningSection';
import React, { useCallback, useEffect, useState } from 'react';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import ESignCeremonyType from 'ESign/Types/Enums/ESignCeremonyType';
import moment from 'moment';
import { produce } from 'immer';
import SignatureModal from '../Common/SignatureModal';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import {
	checkIfAllFieldsAreFilled,
	getInputFieldForCurrentUser,
	getInputFieldType,
	getSanitizedHtml,
} from 'ESign/Utils/SigningContentHelper';
import NavigationButton from '../Common/NavigationButton';

type Props = {
	signature: string;
	ceremonyDetail: ESignUserSigneeCeremony | ESignUserWitnessCeremony;
	signingDocumentContentDetail: SigningDocumentContentDetail;
	updateDocumentContent: (content: string, hasCompleted: boolean, signature: string) => void;
}

const ESignSigningContent: React.FunctionComponent<Props> = observer(({
	signature,
	ceremonyDetail,
	signingDocumentContentDetail,
	updateDocumentContent,
}) => {
	const [signatureDetail, setSignatureDetail] = useState<{
		showModal: boolean;
		signatureFieldId: string;
		signature: string;
	}>({
		showModal: false,
		signatureFieldId: '',
		signature: signature,
	});

	const isSignee = ceremonyDetail.type === ESignCeremonyType.SIGNEE;
	const currentUserUniqueElementId = isSignee ? ceremonyDetail.id : ceremonyDetail.signeeCeremony.id;

	const renderEditField = (inputFieldId: string, inputFieldValue: string) => {
		const inputFieldType = getInputFieldType(inputFieldId);

		switch (inputFieldType) {
			case 'signature': {
				return (
					<>
						{inputFieldValue.length !== 0 && (
							<span className="esign-input-signature-field">
								<img
									id={inputFieldId}
									style={{ zIndex: 1, position: 'relative' }}
									width={80}
									height={56}
									src={inputFieldValue}
									alt="signature"
								/>
								<Button
									className="esign-button-sign-here"
									buttonProps={{ id: inputFieldId, style: { marginRight: '5px' } }}
									sizes={Sizes.Small}
									colors={Colors.Primary}
									display={Display.Outline}
									onClick={() => setSignatureDetail({
										showModal: true,
										signatureFieldId: inputFieldId,
										signature: inputFieldValue || signatureDetail.signature,
									})}
								>
									Update Signature
								</Button>
							</span>
						)}
						{inputFieldValue.length === 0 && (
							<Button
								className="esign-button-sign-here"
								buttonProps={{ id: inputFieldId }}
								colors={Colors.Primary}
								display={Display.Outline}
								onClick={() => setSignatureDetail({
									showModal: true,
									signatureFieldId: inputFieldId,
									signature: inputFieldValue || signatureDetail.signature,
								})}
							>
								Sign Here
							</Button>
						)}
					</>
				);
			}
			case 'date-signed': {
				return (
					<input
						className="esign-input-date-signed"
						key={inputFieldId}
						id={inputFieldId}
						value={moment(new Date().toUTCString()).format('YYYY-MM-DD')}
						type="date"
						readOnly
					/>
				);
			}
			case 'date': {
				return (
					<input
						className="esign-input-date-field"
						key={inputFieldId}
						id={inputFieldId}
						value={moment(inputFieldValue).format('YYYY-MM-DD')}
						type="date"
						onChange={e => {
							handleDocumentUpdate(inputFieldId, e.target.value);
						}}
					/>
				);
			}
			default: {
				return (
					<input
						className="esign-input-text-field"
						type="text"
						key={inputFieldId}
						id={inputFieldId}
						value={inputFieldValue}
						onChange={e => {
							handleDocumentUpdate(inputFieldId, e.target.value);
						}}
					/>
				);
			}
		}
	};

	const replaceHtmlContent = (domNode:any) => {
		const editableInputFieldIdWithValue = getInputFieldForCurrentUser(
			isSignee,
			currentUserUniqueElementId,
			signingDocumentContentDetail.inputFieldWithValue,
		);

		const editableElementIds = Object.keys(editableInputFieldIdWithValue);
		if (domNode.attribs && domNode.attribs.id && editableElementIds.includes(domNode.attribs.id)) {
			const inputFieldId = domNode.attribs.id;
			const inputFieldValue = editableInputFieldIdWithValue[inputFieldId];
			domNode.attribs.value = inputFieldValue;
			return (
				renderEditField(inputFieldId, inputFieldValue)
			);
		}
		// if the element is not an input field, return the element as is
		// if it is an input field, return the input field with the value
		// and make readonly
		if (domNode.type === 'tag' && domNode.name === 'input') {
			domNode.attribs.readonly = 'true';
		}

		return domToReact(domNode);
	};

	const addSignedAcknowledgementElement = useCallback((signatureFieldId: string, htmlString: string) => {
		let signedAcknowledgement = `<span id="${signatureFieldId}-date-signed" style="max-width: 200px; padding-left: 5px;">Digitally signed<br/>by ${ceremonyDetail.name}<br/>Email:${ceremonyDetail.email}<br/>Date:${moment(new Date().toUTCString())}"`;

		if (ceremonyDetail.type === ESignCeremonyType.WITNESS) {
			signedAcknowledgement += `<br/>I hereby declare that I have witnessed the signing of this document by ${ceremonyDetail.signeeCeremony.name}`;
		}

		signedAcknowledgement += '</span>';

		const htmlContent = new DOMParser().parseFromString(htmlString, 'text/html');
		const signatureField = htmlContent.getElementById(signatureFieldId);
		if (signatureField) {
			if (signatureField.nextElementSibling && signatureField.nextElementSibling.id === `${signatureFieldId}-date-signed`) {
				signatureField.nextElementSibling.remove();
			}
			signatureField.insertAdjacentHTML('afterend', signedAcknowledgement);
			return htmlContent.documentElement.outerHTML;
		}
		return htmlString;
	}, [ceremonyDetail]);

	const handleDocumentUpdate = useCallback((inputFieldId: string, data: string) => {
		const updatedInputField = {
			...signingDocumentContentDetail.inputFieldWithValue,
			[currentUserUniqueElementId]: {
				...signingDocumentContentDetail.inputFieldWithValue[currentUserUniqueElementId], [inputFieldId]: data,
			},
		};

		const isSignatureField = getInputFieldType(inputFieldId) === 'signature';
		const searchString = isSignatureField
			? `id="${inputFieldId}"(.*)src="[^"]*"`
			: `id="${inputFieldId}"(.*)value="[^"]*"`;
		const replaceString = isSignatureField
			? `id="${inputFieldId}"$1src="${data}" width="80" height="50" alt="signature"`
			: `id="${inputFieldId}"$1value="${data}"`;

		// update the html string
		let updatedHtmlString = signingDocumentContentDetail.htmlString
			.replace(
				// matches any string that starts with id="inputFieldId"
				// followed by any character except for
				// followed by value="any string that does not contain double quote"
				// and replace it with the matched string
				new RegExp(searchString, 'g'),
				replaceString,
			);

		if (isSignatureField) {
			updatedHtmlString = addSignedAcknowledgementElement(inputFieldId, updatedHtmlString);
		}

		// update the updateDocumentContent
		const finalizedDocumentDetail = {
			...signingDocumentContentDetail,
			inputFieldWithValue: updatedInputField,
			htmlString: updatedHtmlString,
		};

		// check if all fields are filled and signed
		const hasCompleted = checkIfAllFieldsAreFilled(
			updatedInputField[currentUserUniqueElementId],
			isSignee,
			currentUserUniqueElementId,
			signingDocumentContentDetail.inputFieldWithValue,
		);

		updateDocumentContent(JSON.stringify(finalizedDocumentDetail), hasCompleted, signatureDetail.signature);
	}, [
		currentUserUniqueElementId,
		isSignee,
		signatureDetail.signature,
		signingDocumentContentDetail,
		updateDocumentContent,
		addSignedAcknowledgementElement,
	]);

	const updateDateSignedField = useCallback(() => {
		const dateSignedFieldId = Object.keys(getInputFieldForCurrentUser(
			isSignee,
			currentUserUniqueElementId,
			signingDocumentContentDetail.inputFieldWithValue,
		)).find(key => key.includes('date-signed'));
		if (dateSignedFieldId) {
			const currentDate = moment(new Date().toUTCString()).format('YYYY-MM-DD');
			handleDocumentUpdate(dateSignedFieldId, currentDate);
		}
	}, [currentUserUniqueElementId, handleDocumentUpdate, isSignee, signingDocumentContentDetail.inputFieldWithValue]);

	const renderDocument = () => {
		const sanitizedHtmlString = getSanitizedHtml(signingDocumentContentDetail.htmlString);
		const DocumentContent = (HTMLReactParser(sanitizedHtmlString, {
			replace: replaceHtmlContent,
		}));

		return (
			<>
				<NavigationButton
					inputFieldWithValue={getInputFieldForCurrentUser(
						isSignee,
						currentUserUniqueElementId,
						signingDocumentContentDetail.inputFieldWithValue,
					)}
				/>
				{DocumentContent}
			</>
		);
	};

	// used to update the date signed field initially
	useEffect(() => {
		updateDateSignedField();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="esign-ceremony-document-wrapper">
				<div className="esign-documents">
					{renderDocument()}
				</div>
			</div>
			{signatureDetail.showModal && (
				<SignatureModal
					signatureDetail={signatureDetail}
					updateSignature={(value: string, signatureId: string) => {
						handleDocumentUpdate(signatureId, value);
						setSignatureDetail({ ...signatureDetail, signature: value });
					}}
					isWitness={ceremonyDetail.type === ESignCeremonyType.WITNESS}
					signeeName={ceremonyDetail.type === ESignCeremonyType.WITNESS ? ceremonyDetail.signeeCeremony.name : ''}
					closeModal={() => setSignatureDetail(produce(signatureDetail, draft => {
						draft.showModal = false;
					}))}
				/>
			)}
		</>
	);
});

export default ESignSigningContent;
