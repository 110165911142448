import * as React from 'react';
import { ApplicationEntity } from '../../../Models/Entities';
import { observer } from 'mobx-react';
import { TextField } from '../TextBox/TextBox';
import CustomAttributeFile from '../CRUD/Attributes/CustomAttributeFile';
import { AttributeCRUDOptions } from '../../../Models/CRUDOptions';
import { EntityFormMode } from '../Helpers/Common';

export interface ApplicationFieldsProps {
    applicationEntity: ApplicationEntity,
    onAfterChange?: (validateSuccessful: boolean) => void,
    readonly?: boolean,
    create?: boolean,
}

const ApplicationFields = observer((props: ApplicationFieldsProps) => {
	const {
		applicationEntity, onAfterChange, readonly, create,
	} = props;

	/**
     * Handle for the change event for the inputs. This method will attempt to validate a given field
     * iff there are existing errors. So that the user can immediately see when their input stops the error
     * @param field The name of the field to be validated
     * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
     * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
     */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!applicationEntity.errors[field]) {
			await applicationEntity.validateField(field);
		}
	};

	// WHEN ADDING INPUTS TO THIS COMPONENT, ENSURE ANY DEPENDENCY ARRAYS ARE UPDATED THAT ARE USING THIS COMPONENT,
	// E.G. ApplicationListItem.tsx -> useHasChanged
	return (
		<div className="application-fields">
			<section>
				<TextField
					model={applicationEntity}
					modelProperty="applicationName"
					className="application-name"
					label="Application name"
					placeholder="Application name"
					isDisabled={readonly}
					onChangeAndBlur={async () => { await onChange('applicationName', true); }}
					onAfterChange={async () => { await onChange('applicationName'); }}
					errors={applicationEntity.errors.applicationName}
					isRequired
				/>
				<p className="application-section-title">CONTACT DETAILS</p>
				<TextField
					model={applicationEntity}
					modelProperty="contactName"
					className="contact-name"
					label="Contact"
					placeholder="Contact name"
					isDisabled={readonly}
				/>

				<TextField
					model={applicationEntity}
					modelProperty="email"
					className="email"
					label="Email address"
					placeholder="Email address"
					isDisabled={readonly}
					onChangeAndBlur={async () => { await onChange('email', true); }}
					onAfterChange={async () => { await onChange('email'); }}
					errors={applicationEntity.errors.email}
					isRequired
				/>

				<TextField
					model={applicationEntity}
					modelProperty="phone"
					className="phone"
					label="Phone number"
					placeholder="Phone number"
					isDisabled={readonly}
					onChangeAndBlur={async () => { await onChange('phone', true); }}
					onAfterChange={async () => { await onChange('phone'); }}
					errors={applicationEntity.errors.phone}
					isRequired
				/>
			</section>
			<section>
				<CustomAttributeFile
					model={applicationEntity}
					fileAttribute="termsAndConditions"
					options={new AttributeCRUDOptions(
						'termsAndConditionsId',
						{
							name: 'Terms and conditions',
							displayType: 'file',
						},
					)}
					formMode={EntityFormMode.EDIT}
					isReadonly={readonly}
				/>

				<CustomAttributeFile
					model={applicationEntity}
					fileAttribute="privacyPolicy"
					options={new AttributeCRUDOptions(
						'privacyPolicyId',
						{
							name: 'Privacy policy',
							displayType: 'file',
						},
					)}
					formMode={EntityFormMode.EDIT}
					isReadonly={readonly}
				/>

				<CustomAttributeFile
					model={applicationEntity}
					fileAttribute="logo"
					options={new AttributeCRUDOptions(
						'logoId',
						{
							name: 'Business logo',
							displayType: 'file',
						},
					)}
					formMode={EntityFormMode.EDIT}
					imageOnly
					isReadonly={readonly}
				/>
			</section>
		</div>
	);
});

export default ApplicationFields;
