import React, { useState } from 'react';
import { store } from 'Models/Store';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import { OrganisationEntity } from 'Models/Entities';
import EntityList from 'Views/Components/EntityList/EntityList';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import classNames from 'classnames';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { gql } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import moment from 'moment';
import ProductSelectedBox from './ProductSelectedBox';
import If from 'Views/Components/If/If';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import ExportInvoicesModal from 'Views/Components/Clients/ExportInvoicesModal';
import { formatAbn, noSpaces, safeGqlSearch } from 'Util/StringUtils';
import { confirmModal } from '../Modal/ModalUtils';
import { buildBlob } from 'Util/CsvUtils';
import { saveAs } from 'file-saver';
import { buildSearchConditions } from '../../../Util/GraphQLUtils';

// Method to fetch and return the OrganisationEntitys based on the page number and sorting
const fetchClientsDebounced = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	return fetchClients(
		search,
		sortColumn,
		sortDescending,
		page,
		setCount,
		noPagination,
	);
}, 300, {
	// We use leading, so that one-off changes like sorting columns apply immediately
	// Changing the search text will trigger on the first character, and then again after the final change
	leading: true,
});

const fetchClients = (async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['primaryBusinessEntity.name',
			'primaryBusinessEntity.abn'], search, comparison);
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];
	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}

	// Add sorting
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: sortColumn, descending: sortDescending });

	const query = gql`
		query organisationEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]]
		) {
			organisationEntitys(conditions: $conditions, skip: $skip, take:$take, orderBy: $orderBy) {
				id
				created
				approveEnabled
				ppsrEnabled
				intelEnabled
				isReferralPartner
				isActive
				legacyID
				primaryBusinessEntity {
					id
					name
					abn
				}
				registration {
					id
					referringOrganisation {
						primaryBusinessEntity {
							id
							name
						}
					}
				}
			}
			${!noPagination ? `countOrganisationEntitys(conditions: $conditions) {
				number
			}` : ''}
		}
	`;

	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: !noPagination ? ORGANISATIONS_PAGE_LENGTH : null,
			skip: !noPagination ? ORGANISATIONS_PAGE_LENGTH * (page || 0) : null,
			conditions: allConditions,
		},
	});

	if (!noPagination) {
		setCount(data?.countOrganisationEntitys?.number);
	}

	return data.organisationEntitys.map((organisation: any) => new
	OrganisationEntity(organisation)) as OrganisationEntity[];
});

const ClientList = () => {
	// For the search bar display
	const [search, setSearch] = useState('');
	const [sortColumn, setSortColumn] = useState('primaryBusinessEntity.name');
	const [sortDescending, setSortDescending] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showExportInvoicesModal, setShowExportInvoicesModal] = useState(false);

	/**
	 * Callback for sorting changes
	 * @param header The name of the sorting column
	 */
	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};

	// Method to fetch and return the OrganisationEntity based on the page number and sorting
	const fetchClientsForExport = async () => {
		const organisations: OrganisationEntity[] = await fetchClients(
			search,
			sortColumn,
			sortDescending,
			page,
			setCount,
			true,
		);
		const result = [] as string[][];
		const columns = [
			{
				displayName: 'Organisation name',
				value: ({ primaryBusinessEntity }: OrganisationEntity) => primaryBusinessEntity.name,
			},
			{
				displayName: 'ABN',
				value: ({ primaryBusinessEntity }: OrganisationEntity) => primaryBusinessEntity.abn,
			},
			{
				displayName: 'Legacy ID',
				value: ({ legacyID }: OrganisationEntity) => (legacyID ?? '').toString(),
			},
			{
				displayName: 'Products',
				value: ({
					intelEnabled, approveEnabled, ppsrEnabled, isReferralPartner,
				}: OrganisationEntity) => {
					const products = ['Monitor', 'Approve', 'PPSR', 'RP'];
					const output: string[] = [];
					[intelEnabled, approveEnabled, ppsrEnabled, isReferralPartner].forEach(
						(enabled, index) => enabled ? output.push(products[index]) : null,
					);
					return output.join(', ');
				},
			},
			{
				displayName: 'Registered on',
				value: ({ created }: OrganisationEntity) => created
					? moment(created).format('DD/MM/YYYY') : '',
			},
			{
				displayName: 'Referred by',
				value: ({ registration }: OrganisationEntity) => registration?.referringOrganisation
					?.primaryBusinessEntity?.name,
			},
			{
				displayName: 'Status',
				value: ({ isActive }: OrganisationEntity) => isActive.toString(),
			},
		];
		const headers = columns.map(column => column.displayName);
		result.push(headers);
		organisations.forEach(client => {
			result.push(
				columns.map(column => column.value(client) ?? ''),
			);
		});

		return result;
	};

	return (
		<div className="client-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						onAfterChange={() => setPage(0)}
						labelVisible={false}
						placeholder="Search by Name or ABN"
					/>
				</div>
				<div className="actions">
					<Button
						className="export-invoices"
						display={Display.Outline}
						colors={Colors.Primary}
						onClick={() => setShowExportInvoicesModal(true)}
					>
						Export Invoices
					</Button>

					<Button
						className="export-button"
						colors={Colors.Alternate}
						display={Display.Outline}
						onClick={async () => {
							confirmModal(
								'Export CSV',
								'Export your clients and save to a CSV file?',
								{
									cancelText: 'Cancel',
									confirmText: 'Export',
								},
							).then(async () => {
								const exportClients = await fetchClientsForExport();
								const blob = buildBlob(exportClients, ',', '"');
								saveAs(blob, `exported-clients-${moment().format('YYYY-MM-DD')}.csv`);
							});
						}}
					>
						Export CSV
					</Button>
				</div>
				<If condition={showExportInvoicesModal}>
					<ExportInvoicesModal
						onClose={() => setShowExportInvoicesModal(false)}
					/>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the Client List */}
				<RequestWrap
					request={() => fetchClientsDebounced(
						search,
						sortColumn,
						sortDescending,
						page,
						setCount,
						false,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
					)}
				>
					{(organisations: OrganisationEntity[]) => (
						<>
							<EntityList
								collection={organisations}
								columns={[
									{
										displayName: 'Organisation name',
										columnName: 'primaryBusinessEntity.name',
										value: organisation => {
											if (organisation.primaryBusinessEntity?.name != null) {
												return organisation.isActive === false
													? (
														<span className="inactive">
															{organisation.primaryBusinessEntity?.name}
														</span>
													)
													: organisation.primaryBusinessEntity?.name;
											}
											return <span className="not-provided">Not Provided</span>;
										},
										sortable: true,
										sortClicked: () => {
											onSortChange('primaryBusinessEntity.name');
										},
										className: 'field-organisation-name',
									},
									{
										displayName: 'ABN',
										columnName: 'primaryBusinessEntity.abn',
										value: organisation => formatAbn(organisation.primaryBusinessEntity?.abn)
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('primaryBusinessEntity.abn');
										},
										className: 'field-abn',
									},
									{
										displayName: 'Products',
										columnName: 'products',
										value: organisation => (
											<ProductSelectedBox
												ppsrEnabled={organisation.ppsrEnabled}
												approveEnabled={organisation.approveEnabled}
												intelEnabled={organisation.intelEnabled}
												isReferrer={organisation.isReferralPartner}
											/>
										),
										sortable: false,
										className: 'field-products',
									},
									{
										displayName: 'Registered on',
										columnName: 'completedDate',
										value: organisation => organisation.created
											? moment(organisation.created).format('DD/MM/YYYY')
											: <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('created');
										},
										className: 'field-start-date',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								onClickRow={organisation => {
									store.routerHistory.push(`/hub/clients/${organisation.id}`);
								}}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={ORGANISATIONS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default ClientList;

export const ORGANISATIONS_PAGE_LENGTH = 10;
