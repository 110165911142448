import { RouteComponentProps } from 'react-router-dom';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import UploadHistoryList from 'Views/Components/Intel/Customer/UploadHistoryList';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface UploadHistoryPageProps extends RouteComponentProps {
}

const UploadHistoryPage = (props: UploadHistoryPageProps) => {
	const breadCrumbTags = [
		{ label: 'ATB Uploads History' },
	];

	return (
		<AccessIntelSecuredPage
			routeComponentProps={props}
			groups={['Super Administrators', 'SUPER_USER']}
			product="monitor"
		>
			<div className="body-content list-page">
				<PageBreadcrumbs tags={breadCrumbTags}/>
				<UploadHistoryList/>
			</div>
		</AccessIntelSecuredPage>
	);
}
export default UploadHistoryPage;
