import * as React from 'react';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';

import DashboardAgedTrialBalanceUpdateButton
	from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceUpdateButton';
import DashboardAgedTrialBalanceRiskBuckets
	from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceRiskBuckets';
import DashboardAgedTrialBalanceSummary
	from 'Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceSummary';

const DashboardAgedTrialBalanceGraph = React.lazy(
	() => import('Views/Components/Intel/Dashboard/DashboardAgedTrialBalanceGraph'),
);

function DashboardAgedTrialBalance(props: {
	beIds: string[]
}) {
	const { beIds } = props;

	return (
		<div className="dashboard-aged-trial-balance flex-col">
			<DashboardAgedTrialBalanceUpdateButton />
			<div className="flex-row">
				<DashboardAgedTrialBalanceRiskBuckets beIds={beIds} />
				<div className="atb-data flex-col">
					<DashboardAgedTrialBalanceSummary beIds={beIds} />
					<div className="atb-data-graph rounded-box">
						<React.Suspense fallback={<InlineSpinner />}>
							<DashboardAgedTrialBalanceGraph />
						</React.Suspense>
					</div>
				</div>
			</div>
		</div>
	);
}
export default DashboardAgedTrialBalance;
