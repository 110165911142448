import moment from 'moment/moment';
import * as React from 'react';
import { AsicDocument } from 'Models/Interfaces/AlaresReport';
import AdverseAlertsTable from './AdverseAlertsTable';

const AdverseAlertsAsicUpdates = (props: {
	documents: AsicDocument[],
}) => {
	const { documents } = props;

	return (
		<>
			<AdverseAlertsTable
				tableName="ASIC updates"
				className="documents"
				collection={documents}
				columns={[
					{
						displayName: 'Date',
						columnName: 'date',
						value: document => document.convertedDate ? moment(document.convertedDate).format('Do MMMM YYYY') : '-',
						sortable: false,
						className: 'field-date',
					},
					{
						displayName: 'Form code',
						columnName: 'formCode',
						value: document => document.form_code ?? "-",
						sortable: false,
						className: 'field-form-code',
					},
					{
						displayName: 'Description',
						columnName: 'description',
						value: document => document.description,
						sortable: false,
						className: 'field-description',
					},
					{
						displayName: 'Document No.',
						columnName: 'documentNo',
						value: document => document.identifier ?? "-",
						sortable: false,
						className: 'field-document-no',
					},
				]}
				sortColumn="date"
				sortDescending
				idColumn="identifier"
			/>
		</>
	);
};

export default AdverseAlertsAsicUpdates;
