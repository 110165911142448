import React, { useState } from 'react';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import HandleEvents from 'Util/HandleEvents';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import { BusinessEntity, OrganisationEntity, UserEntity } from 'Models/Entities';
import EntityList from 'Views/Components/EntityList/EntityList';
import { userTypeOptions } from 'Models/Enums';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import classNames from 'classnames';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { gql } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import MultiComboboxSetter from 'Views/Components/Combobox/MultiComboboxSetter';
import { HasCondition, IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import alertToast from 'Util/ToastifyUtils';
import MeatballMenu from 'Views/Components/EntityList/MeatballMenu';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import {
	LocaleCompareAttrAsc, noSpaces, safeGqlSearch, booleanToActive,
} from 'Util/StringUtils';

// Method to fetch and return the UserEntitys based on the page number and sorting
const fetchUsers = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	businessEntityIds: string[],
	organisationIds: string[],
	setCount: (newCount: number) => void,
) => {
	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	const searchConditions: IWhereCondition<string>[] = [];
	if (!!search && search !== '') {
		// We are currently only searching on email and name fields
		const searchTermsWithSpaces = safeGqlSearch(search);
		const searchTermsWithoutSpaces = noSpaces(searchTermsWithSpaces);
		searchConditions.push({
			case: comparison, comparison: 'like', path: 'email', value: [`%${searchTermsWithSpaces}%`],
		});
		searchConditions.push({
			case: comparison, comparison: 'like', path: 'email', value: [`%${searchTermsWithoutSpaces}%`],
		});
		searchTermsWithSpaces.split(' ').forEach(searchTerm => {
			// ensure we aren't searching for a blank space
			if (searchTerm.trim() !== '') {
				['firstName', 'lastName'].forEach(path => {
					searchConditions.push({
						case: comparison, comparison: 'like', path: path, value: [`%${searchTerm}%`],
					});
				});
			}
		});
	}

	// Setup organisation ID filter
	const organisationConditions: IWhereCondition<string>[] = [];
	if (!!organisationIds && organisationIds.length > 0) {
		organisationConditions.push({
			comparison: 'in', path: 'organisationId', value: organisationIds,
		});
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];
	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}
	if (organisationConditions.length > 0) {
		allConditions.push(organisationConditions);
	}

	// are we sorting on conjoined columns
	const orderBy: {path: string, descending: boolean}[] = [];
	const columns = sortColumn.split(' ');
	columns.forEach(column => orderBy.push({ path: column, descending: sortDescending }));

	// Build Has conditions. (Used for filtering on business entities)
	const hasConditions: HasCondition<any>[][] = [];
	if (businessEntityIds.length > 0) {
		const internalHasConditions: HasCondition<any>[] = [{
			path: 'businessEntitys',
			conditions: [[
				{
					path: 'businessEntityId',
					comparison: 'in',
					value: businessEntityIds,
				},
			]],
		}];
		hasConditions.push(internalHasConditions);
	}

	const query = gql`
		query userEntity(
			$orderBy: [OrderByGraph], 
			$take: Int, 
			$skip: Int, 
			$conditions: [[WhereExpressionGraph]],
			$has: [[HasConditionType]]
		) {
			userEntitys(conditions: $conditions, has: $has, skip: $skip, take:$take, orderBy: $orderBy) {
				firstName
				lastName
				id
				email
				isActive
				userType
				organisation {
					id
					primaryBusinessEntity {
						id
						name
					}
				}
				businessEntitys {
					id
					businessEntity {
						id
						name
					}
				}
			}
			countUserEntitys(conditions: $conditions, has: $has) {
				number
			}
		}
	`;

	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: MAX_USERS,
			skip: MAX_USERS * (page || 0),
			conditions: allConditions,
			has: hasConditions,
		},
	});
	setCount(data.countUserEntitys.number);
	return data.userEntitys.map((user: any) => new UserEntity(user)) as UserEntity[];
},
300,
{
	// We use leading, so that one-off changes like sorting columns apply immediately
	// Changing the search text will trigger on the first character, and then again after the final change
	leading: true,
});

// Fetch organisations options. Used for organisation ID filter
const fetchOrganisationOptions = AwesomeDebouncePromise(async (
	search?: string,
) => {
	const organisations = await OrganisationEntity.fetchOrganisationList(search) ?? [];
	return organisations
		.map(x => {
			return {
				display: x.primaryBusinessEntity?.name,
				value: x.id,
			};
		}) as ComboboxOption<string>[];
}, 300);

const UsersList = () => {
	// For the search bar display
	const [search, setSearch] = useState('');
	const [sortColumn, setSortColumn] = useState('firstName lastName');
	const [sortDescending, setSortDescending] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [businessEntityIds, setBusinessEntityIds] = useState([] as string[]);
	const [organisationIds, setOrganisationIds] = useState([] as string[]);
	const [triggerUpdate, setTriggerUpdate] = useState(0); // Increment to force the user list to refresh

	/**
	 * Callback for sorting changes
	 * @param header The name of the sorting column
	 */
	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};

	const changeIsActive = async (user: UserEntity, active: boolean) => {
		// If you're deactivating the last organisation manager, we prevent this
		if (!active && await user.isLastOrganisationManager()) {
			alertToast('You cannot deactivate the last organisation manager in an organisation', 'error');
			return;
		}

		const deactivateSelf = !active && user.id === store.userId;
		try {
			if (!active) {
				await confirmModal(
					`Deactivate ${deactivateSelf ? 'your account?' : 'user?'}`,
					deactivateSelf
						? "If you deactivate your account, you will be logged out, and won't be able to log in again."
						: 'If you deactivate this user, they will be unable to login to the Access Intell platform.',
				);
			} else {
				await confirmModal(
					'Activate user?',
					'If you activate this user, they will be able to login to the Access platform again.',
				);
			}
		} catch {
			return;
		}

		try {
			await axios.post(`${SERVER_URL}/api/entity/UserEntity/updateFields`, {
				id: user.id,
				isActive: active,
			});
		} catch (e: any) {
			alertToast(`You do not have access to ${active ? 'activate' : 'deactivate'} this user`, 'error');
			return;
		}

		if (deactivateSelf) {
			store.routerHistory.push('/logout');
		} else {
			alertToast(`User ${active ? 'activated' : 'deactivated'}`, 'success');

			// Refresh the list, so we see the change
			setTriggerUpdate(x => x + 1);
		}
	};

	const organisationBusinessColumn = store.userType === 'SUPER_USER'
		? {
			displayName: 'Organisation',
			columnName: 'organisation.primaryBusinessEntity.name',
			value: (user: UserEntity) => user.organisation?.primaryBusinessEntity?.name,
			sortable: true,
			sortClicked: () => {
				onSortChange('organisation.primaryBusinessEntity.name');
			},
			className: 'field-organisation',
		}
		: {
			displayName: 'Business Entities',
			columnName: 'businessEntities',
			value: (user: UserEntity) => user.businessEntitys?.map(x => x.businessEntity.name).join(', '),
			sortable: false,
			className: 'field-business-entities',
		};

	return (
		<div className="users-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						onAfterChange={() => setPage(0)}
						label="A search for entities"
						labelVisible={false}
						placeholder="Search by Name or Email"
					/>

					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => {
							setShowFilters(show => !show);
							setBusinessEntityIds([]);
							setOrganisationIds([]);
						}}
					>
						Filter By
					</Button>
				</div>
				<If condition={store.userPermissions.commonManageUsers !== 'SELF'}>
					<div className="new-user">
						<Button
							className="new-user-btn"
							icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
							display={Display.Solid}
							colors={Colors.Primary}
							{...HandleEvents(() => store.routerHistory.push('/hub/users/new'))}
						>
							New User
						</Button>
					</div>
				</If>
				<If condition={showFilters}>
					<div className="search-filter-section">
						<If condition={store.userType === 'SUPER_USER'}>
							<MultiComboboxSetter
								className="organisation-filter"
								value={organisationIds}
								setValue={setOrganisationIds}
								getOptionValue={(value?: string) => value}
								placeholder="Clients"
								label=""
								labelVisible={false}
								searchable
								initialOptions={fetchOrganisationOptions}
								options={fetchOrganisationOptions}
								onAfterSet={() => setPage(0)}
							/>
						</If>
						<If condition={store.userType !== 'SUPER_USER'}>
							<MultiComboboxSetter
								className="business-entity-filter"
								value={businessEntityIds}
								setValue={setBusinessEntityIds}
								getOptionValue={(value?: string) => value}
								placeholder="Business entities"
								label=""
								labelVisible={false}
								searchable
								onAfterSet={() => setPage(0)}
								options={store.getUser?.organisation?.businessEntitys
									.filter(BusinessEntity.IsLinkedToUser)
									.sort(LocaleCompareAttrAsc('name'))
									.map(
										x => { return { display: x.name, value: x.id }; },
									) ?? []}
							/>
						</If>
					</div>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the User List */}
				<RequestWrap
					request={() => fetchUsers(
						search,
						sortColumn,
						sortDescending,
						page,
						businessEntityIds,
						organisationIds,
						setCount,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
						businessEntityIds,
						organisationIds,
						triggerUpdate,
					)}
				>
					{(users: UserEntity[]) => (
						<>
							<EntityList
								collection={users}
								columns={[
									{
										displayName: 'Name',
										columnName: 'firstName lastName',
										value: user => `${user.firstName} ${user.lastName}`,
										sortable: true,
										sortClicked: () => {
											onSortChange('firstName lastName');
										},
										className: 'field-name',
									},
									{
										displayName: 'Email Address',
										columnName: 'email',
										value: user => user.email,
										sortable: true,
										sortClicked: () => {
											onSortChange('email');
										},
										className: 'field-email',
									},
									organisationBusinessColumn,
									{
										displayName: 'User Type',
										columnName: 'userType',
										value: user => userTypeOptions[user.userType],
										sortable: true,
										sortClicked: () => {
											onSortChange('userType');
										},
										className: 'field-user-type',
									},
									{
										displayName: 'Status',
										columnName: 'isActive',
										value: user => booleanToActive(user.isActive),
										sortable: true,
										sortClicked: () => {
											onSortChange('isActive');
										},
										className: 'field-status',
									},
									{
										displayName: '',
										columnName: 'meatball-menu',
										value: user => (
											<MeatballMenu actions={[
												{
													display: 'Activate user',
													action: () => changeIsActive(user, true),
													visible: !user.isActive && user.canEdit,
												},
												{
													display: 'Deactivate user',
													action: () => changeIsActive(user, false),
													visible: user.isActive && user.canEdit,
												},
											]}
											/>
										),
										sortable: false,
										className: 'meatball-menu',
										hide: store.userPermissions.commonManageUsers === 'SELF',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								onClickRow={user => {
									store.routerHistory.push(`/hub/users/${user.id}`);
								}}
								rowClassName={user => classNames({ disabled: !user.isActive })}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={MAX_USERS}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default UsersList;

export const MAX_USERS = 10;
