import { SERVER_URL } from 'Constants';
import axios from 'axios';

async function uploadAtoDebt(file: File, fileDate: string, override: boolean): Promise<void> {
	const url = `${SERVER_URL}/api/entity/AtoFileEntity/upload`;

	const formData = new FormData();
	formData.append('csvFile', file);
	formData.append('fileDate', fileDate);
	formData.append('override', override.toString());

	await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export default uploadAtoDebt;
