import { useEffect, useMemo, useState } from "react";
import { produce } from "immer";

export function ValidateIsEmpty(value: unknown): boolean {
	if (value === undefined || value === null) {
		return true;
	}

	if (typeof value === "string") {
		if (value === "") {
			return true;
		}
	}

	return false;
}

export type Validation = {
	get: (key: string, force?: boolean) => string[];
    has: (key: string, force?: boolean) => boolean;
    isValid: boolean;
    errors: Partial<Record<string, string[]>>;
    errorCount: number;
}

type ValidationEntry<T> = { value: T | undefined | null; validate?: (validator: Validator<T | undefined | null>) => void; isRequired?: boolean; requiredText?: string };

type ValidationFactory = ValidationEntry<string> | ValidationEntry<boolean> | ValidationEntry<number>;

type ValidationFactoryBuilder = Record<string, ValidationFactory>;

export function useValidation<T extends ValidationFactoryBuilder, K extends Extract<keyof T, string>>(factory: T, forceReveal: boolean = false) {
	const [revealed, setRevealed] = useState<{ [key: string]: boolean }>({});
	const [originalCache] = useState(factory);

	const factoryKeys = useMemo(() => Object.keys(factory) as K[], [factory]);

	const formErrors = useMemo(() => {
		const formErrorsBuilder: Partial<Record<K, string[]>> = {};

		factoryKeys.forEach(key => {
			const value = factory[key].value;
			const factoryValue = factory[key];

			const validator = new Validator<any>(key, value);

			if (!factoryValue) {
				return;
			}

			if (factoryValue.isRequired) {
				validator.assertRequired(factoryValue.requiredText);

				if (factoryValue.validate) {
					factoryValue.validate(validator);
				}
			} else {
				if (factoryValue.validate && !ValidateIsEmpty(value)) {
					factoryValue.validate(validator);
				}
			}

			if (validator.hasErrors()) {
				formErrorsBuilder[key] = validator.getErrors();
			}
		});

		return formErrorsBuilder;
	}, [factory, factoryKeys]);

	function isShadowed(key: K, reveal: boolean) {
		return !forceReveal && !reveal && !revealed[key];
	}

	function getErrors(key: K, force: boolean = false) {
		if (isShadowed(key, force)) {
			return [];
		}

		const errors = formErrors[key];
		if (!errors) {
			return [];
		}

		return errors;
	}

	function hasErrors(key: K, force: boolean = false) {
		return (getErrors(key, force) as string[]).length !== 0;
	}

	useEffect(() => {
		setRevealed(
			produce(revealed, draft => {
				factoryKeys.forEach(key => {
					if (originalCache[key]){
						if (factory[key]["value"] !== originalCache[key]["value"]) {
							draft[key] = true;
						}
					}
				});
			})
		);
	}, [factory, factoryKeys, originalCache, revealed]);

	const errorCount = useMemo(() => Object.keys(formErrors).reduce<number>((prev, key) => prev + (formErrors[key as K] as string[]).length, 0), [formErrors]);

	return {
		get: getErrors,
		has: hasErrors,
		isValid: errorCount === 0,
		errors: formErrors,
		errorCount: errorCount,
	} as Validation;
}
export class Validator<T> {
	private readonly name: string;
	private readonly value: T;

	private readonly errors: string[] = [];

	constructor(name: string, value: T) {
		this.name = name;
		this.value = value;
	}

	public assertRule(func: (value: T) => string | string[] | boolean | void, message?: string): this;
	public assertRule(func: (value: T, name: string) => string[] | boolean | void, message?: string): this {
		const result = func(this.value, this.name);

		if (typeof result === "object") {
			if (result.length > 0) {
				result.forEach(s => this.errors.push(s));
			}
		}

		if (typeof result === "string") {
			if (result) {
				this.errors.push(result);
			}
		}

		if (typeof result === "boolean") {
			if (!result && message) {
				this.errors.push(message);
			}
		}

		return this;
	}

	public assertRequired(message = "Field is required.") {
		this.assertRule(value => !ValidateIsEmpty(value), message);

		return this;
	}

	public assertTruthy(message: string) {
		this.assertRule(value => !!value, message);

		return this;
	}

	public assertType(type: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function", message = "Invalid value entered.") {
		this.assertRule(value => {
			return typeof value === type;
		}, message);

		return this;
	}

	public assertLengthBetween(min: number, max: number, message = `Value must be between ${min} and ${max} characters.`) {
		this.assertRule(value => {
			let valueLength = 0;

			if (typeof value === "string") {
				valueLength = value.length;
			} else if (typeof value === "number" || typeof value === "bigint") {
				valueLength = value.toString().length;
			} else {
				return false;
			}

			return valueLength >= min && valueLength <= max;
		}, message);

		return this;
	}

	public assertNumberBetween(min: number, max: number, message = `Value must be between ${min} and ${max}.`) {
		this.assertRule(value => {
			const number = Number(value);

			if (isNaN(number)) {
				return false;
			}

			return number >= min && number <= max;
		}, message);

		return this;
	}

	public assertGreaterThan(min: number, includeMin: boolean = false, message = `Value must be greater than ${min}.`) {
		this.assertRule(value => {
			const number = Number(value);

			if (isNaN(number)) {
				return false;
			}

			if (includeMin) {
				return number >= min;
			}

			return number > min;
		}, message);

		return this;
	}

	public assertLessThan(max: number, includeMax: boolean = false, message = `Value must be less than ${max}.`) {
		this.assertRule(value => {
			const number = Number(value);

			if (isNaN(number)) {
				return false;
			}

			if (includeMax) {
				return number <= max;
			}

			return number < max;
		}, message);

		return this;
	}

	public hasErrors() {
		return this.errors.length > 0;
	}

	public getErrors() {
		return this.errors;
	}
}