import { observer, useLocalStore } from 'mobx-react';
import { BusinessEntity, SpgEntity } from '../../../Models/Entities';
import { Button, Colors, Display } from '../Button/Button';
import * as React from 'react';
import If from '../If/If';
import { action, runInAction } from 'mobx';
import SpgDetailsModal from './SpgDetailsModal';
import alertToast from '../../../Util/ToastifyUtils';
import SpgEntityListItem from './SpgListItem';
import { store } from '../../../Models/Store';
import { businessEntitiesPermissionScopeOrder } from '../../../Models/Enums';

export interface BusinessEntityPpsrDetailsProps {
	businessEntity: BusinessEntity,
}

const BusinessEntityPpsrDetails = observer((props: BusinessEntityPpsrDetailsProps) => {
	const { businessEntity } = props;

	const [showAddModal, setShowAddModal] = React.useState(false);
	const newSpgEntity = useLocalStore(() => new SpgEntity());
	const accessibleBusinessEntities = businessEntity.organisation
		?.businessEntitys
		.map(entity => entity.id) ?? [''];

	// function to determine if an spg entity is readonly. readonly if the user cannot manage business entities or
	// they can manage business entities within their organisation and they can access the business entity
	const canEdit = (id: string) => {
		const managePermission = store.userPermissions.commonManageBusinessEntities ?? 'NONE';
		// for users below all and above none, I can edit business entities I belong to
		const canEditMyBusinessEntity = businessEntitiesPermissionScopeOrder[managePermission]
			> businessEntitiesPermissionScopeOrder.NONE && accessibleBusinessEntities.includes(id);
		return managePermission === 'ALL' ? true : canEditMyBusinessEntity;
	};

	const renderCantAccessPpsr = () => {
		if (store.userType === 'ORGANISATION_MANAGER' || store.userType === 'SUPER_USER') {
			return (
				<div className="cant-access-ppsr">
					<div className="content">
						<h3>Looking for Access PPSR?</h3>
						<p>
							Correctly process PPSR registrations with one click and easily manage your entire portfolio
							of security interests in one place with full visibility.
						</p>
						<p>
							To setup PPSR, please click below.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								if (businessEntity.organisationId === store.getUser?.organisation?.id) {
									if (businessEntity.organisation.ppsrEnabled) {
										store.routerHistory
											.push(
												`/hub/organisation/business-entities/${businessEntity.id}`,
												{ showProductModal: 'add-ppsr' },
											);
									} else {
										store.routerHistory
											.push('/hub/organisation/products');
									}
								} else if (businessEntity.organisation.ppsrEnabled) {
									store.routerHistory.push(
										`/hub/clients/${businessEntity.organisationId}/`
											+ `business-entities/${businessEntity.id}`,
										{ showProductModal: 'add-ppsr' },
									);
								} else {
									store.routerHistory.push(`/hub/clients/${businessEntity.organisationId}/products`);
								}
							}}
							className="ppsr-setup"
						>
							PPSR product setup
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="cant-access-ppsr">
				<div className="content">
					<h3>Looking for Access PPSR?</h3>
					<p>
						Correctly process PPSR registrations with one click and easily manage your entire portfolio of
						security interests in one place with full visibility.
					</p>
					<br />
					<p>
						To setup PPSR, please contact your Organisation Manager.
					</p>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.ppsrMarketingUrl, '_blank');
						}}
						className="ppsr-learn-more"
					>
						Learn more about Access PPSR
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="business-entity-ppsr-details">
			{ store.canAccessPPSR && businessEntity.organisation.ppsrEnabled && businessEntity.enabledForPPSR ? (
				<>
					<div className="title-container">
						<h3>PPSR details</h3>
						<Button
							className="add-spg"
							colors={Colors.Primary}
							display={Display.Outline}
							icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
							onClick={() => setShowAddModal(true)}
							disabled={!canEdit(businessEntity.id)}
						>
							Add new SPG
						</Button>
					</div>
					<div className="entity-list-wrap">
						{businessEntity.spgss.map((spgEntity, index) => (
							<SpgEntityListItem
								spgEntity={spgEntity}
								key={spgEntity.id}
								readonly={!canEdit(businessEntity.id)}
							/>
						))}
					</div>
					<If condition={showAddModal}>
						<SpgDetailsModal
							spgEntity={newSpgEntity}
							onClose={() => setShowAddModal(false)}
							onSubmit={action(async () => {
								const spgToAdd = new SpgEntity(newSpgEntity);
								spgToAdd.businessEntityId = businessEntity.id;
								spgToAdd.isActive = true;
								try {
									await spgToAdd.save();
									runInAction(() => {
										businessEntity.spgss = [...businessEntity.spgss, spgToAdd];
										alertToast('SPG entity successfully created', 'success');
									});
								} catch (exception) {
									alertToast('SPG entity could not be created, please refresh '
										+ 'and try again', 'error');
								}

								runInAction(() => {
									newSpgEntity.spgNumber = '';
									newSpgEntity.accessCode = '';
									newSpgEntity.organisationalNumbers = '';
									newSpgEntity.contactName = '';
									newSpgEntity.email = '';
									newSpgEntity.phone = '';
									newSpgEntity.businessEntityId = '';
									setShowAddModal(false);
								});
							})}
						/>
					</If>
				</>
			) : renderCantAccessPpsr()}
		</div>
	);
});

export default BusinessEntityPpsrDetails;
