import classNames from "classnames";
import { observer } from "mobx-react";

type Props = {
	onClick?: (group: string, isSelected: boolean) => void;
	name: string; // Name of group
	colour: string; // Base colour of the component
	selected: boolean;
}

export const ReferralPartnerGroupSection: React.FunctionComponent<Props> = observer(({onClick, name, colour, selected}) => {
	if (onClick !== undefined) {
		return (
			<button
				className="referral-partner-group"
				style={{ backgroundColor: selected ? colour : 'grey' }}
				onClick={() => {
					onClick(name, selected);
				}}
			>
				{name}
				<span className={classNames('icon icon-right', selected ? 'icon-check' : 'icon-cross')} />
			</button>
		);
	}
	return (
		<div className="referral-partner-group" style={{ backgroundColor: colour }}>
			{name}
		</div>
	);
});
