import * as React from 'react';
import { ApplicationEntity } from '../../../Models/Entities';
import { observer } from 'mobx-react';
import useHasChanged from '../../../Hooks/useHasChanged';
import moment from 'moment';
import EntityActiveStatus from './EntityActiveStatus';
import alertToast from '../../../Util/ToastifyUtils';
import classNames from 'classnames';
import If from '../If/If';
import { Button, Colors, Display } from '../Button/Button';
import ApplicationFields from '../Approve/ApplicationFields';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface ApplicationListItemProps {
    applicationEntity: ApplicationEntity,
    readonly?: boolean,
}

const ApplicationListItem = observer((props: ApplicationListItemProps) => {
	const { applicationEntity, readonly } = props;

	const [expanded, setExpanded] = React.useState(false);
	const [privateApplicationEntity, setPrivateApplicationEntity] = React.useState(
		new ApplicationEntity(applicationEntity),
	);
	// use a custom hasChanged hook to determine if the save should be disabled because nothing has changed
	// this assumes that the reference to errors always changes when an error
	const saveDisabled = useHasChanged(
		ApplicationEntity,
		privateApplicationEntity,
		['applicationName', 'email', 'contactName', 'phone', 'logoId', 'logo', 'termsAndConditions',
			'termsAndConditionsId', 'privacyPolicy', 'privacyPolicyId'],
		(oldValue, newValue) => oldValue !== newValue,
		['errors'],
		(original, changed) => Object.keys(changed.errors).length > 0,
	);

	return (
		<div className="application-entity-item">
			<div className="application-entity-label-area">
				<div className="application-entity-details">
					<div className="application-entity-name">
						{applicationEntity.applicationName}
					</div>
					<div className="application-entity-date">
						Application date: {moment(applicationEntity.applicationDate).format('DD/MM/YYYY')}
					</div>
				</div>
				<div className="application-entity-active-status">
					<EntityActiveStatus
						model={applicationEntity}
						modelProperty="isActive"
						statusText="Application status:"
						disabled={readonly}
						onAfterChange={async () => {
							const savedEntity = new ApplicationEntity(applicationEntity);
							try {
								await applicationEntity.save({}, {
									contentType: 'multipart/form-data',
								});
							} catch (exception) {
								applicationEntity.assignAttributes(savedEntity);
								alertToast('Application status could not be changed, '
                                    + 'please refresh and try again', 'error');
							}
						}}
					/>
				</div>
				<button
					type="button"
					className={classNames('expand-application-entity', {
						expanded: expanded,
					})}
					onClick={() => setExpanded(oldValue => !oldValue)}
					aria-label="Expand application entity"
				/>
			</div>
			<If condition={expanded}>
				<div className="application-entity-expanded-area">
					<ApplicationFields
						applicationEntity={privateApplicationEntity}
						readonly={!applicationEntity.isActive || readonly}
					/>
					<If condition={applicationEntity.isActive && !readonly}>
						<ButtonAsyncState
							className="application-entity-save"
							colors={Colors.Primary}
							display={Display.Solid}
							onPress={async () => {
								try {
									await privateApplicationEntity.validateAllFields();
									if (Object.keys(privateApplicationEntity.errors).length > 0) return;
									await privateApplicationEntity.save({}, {
										contentType: 'multipart/form-data',
									});
									applicationEntity.assignAttributes(privateApplicationEntity);
									setPrivateApplicationEntity(new ApplicationEntity(privateApplicationEntity));
									alertToast('Application successfully saved', 'success');
								} catch (exception) {
									privateApplicationEntity.assignAttributes(applicationEntity);
									alertToast('Application could not be saved, '
                                        + 'please refresh and try again', 'error');
								}
							}}
							readonly={saveDisabled}
						>
							Save changes
						</ButtonAsyncState>
					</If>
				</div>
			</If>
		</div>
	);
});

export default ApplicationListItem;
