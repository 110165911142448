import * as React from 'react';
import {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import {
	Route,
	Switch,
	useRouteMatch,
} from 'react-router';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import {
	CreditBureauEntity,
	OrganisationAuthorisedCreditBureau,
	OrganisationEntity,
} from 'Models/Entities';

import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { Colors, Display } from 'Views/Components/Button/Button';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { CheckboxGroup } from 'Views/Components/Checkbox/CheckboxGroup';
import CheckboxSetter from 'Views/Components/Checkbox/CheckboxSetter';
import If from 'Views/Components/If/If';
import ActionCard from 'Views/Components/ActionCard/ActionCard';

import XeroLogo from 'Assets/Images/Xero_logo-blue.png';
import OrganisationMonitorXeroDetails from './OrganisationMonitorXeroDetails';

type IntegrationPartner = 'Xero';
type MatchParams = {
	organisationId?: string;
	integrationPartner?: IntegrationPartner;
}

export interface OrganisationMonitorProps {
	originalOrganisationEntity: OrganisationEntity,
}

const OrganisationMonitor = (props: OrganisationMonitorProps) => {
	const match = useRouteMatch<MatchParams>();
	const { params } = match;
	const { integrationPartner } = params;

	const integrationPartnerXero = integrationPartner === 'Xero';

	const { originalOrganisationEntity } = props;

	const [organisation, setOrganisation] = useState(new OrganisationEntity(originalOrganisationEntity));
	const [hasChanges, setHasChanges] = useState(false);

	const userCanEdit = (store.userPermissions.commonManageOrganisations !== 'NONE');

	const selfPath = (params.organisationId)
		? `/hub/clients/${params?.organisationId}/monitor`
		: '/hub/organisation/monitor';
	const closeIntegrationModal = useCallback(() => {
		store.routerHistory.push(selfPath);
	}, [selfPath]);

	/**
	 * Method to save the updated user information. Raises an alert toast on success and on error.
	 */
	const onSavePressed = async () => {
		try {
			organisation.industryCodesId = organisation.industryCodes?.id;
			await organisation.save({
				authorisedCreditBureaus: {},
			},
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'authorisedCreditBureaus',
						],
					},
				],
			});
			originalOrganisationEntity.assignAttributes(organisation);
		} catch (e) {
			alertToast(
				'Organisation Monitor details could not be saved at this moment. '
					+ 'Please refresh and try again.',
				'error',
			);
			return;
		}

		alertToast('Organisation Monitor details have been successfully updated', 'success');

		setHasChanges(false);
	};

	if (!organisation.intelEnabled) {
		// The user shouldn't get get here, but just in case, show a blank tab.
		return (
			<div className="organisation-details organisation-monitor">
				<h4>Monitor details</h4>
			</div>
		);
	}

	return (
		<div className="organisation-details organisation-monitor">
			<h4>Monitor details</h4>

			<div className="authorised-label-container">
				<p>Authorised applications</p>
			</div>

			<CreditBureauCheckboxes
				organisation={organisation}
				userCanEdit={userCanEdit}
				setHasChanges={setHasChanges}
			/>

			<If condition={organisation.integrationXeroEnabled ?? false}>
				<div className="accounting-system-cntr">
					<div className="accounting-system-label">Integration Details</div>
					<IntegrationCardXeroOrganisation organisation={organisation} selfPath={selfPath} />
				</div>
			</If>

			<If condition={userCanEdit}>
				<div>
					<ButtonAsyncState
						className="save"
						colors={Colors.Primary}
						display={Display.Solid}
						onPress={onSavePressed}
						readonly={!hasChanges}
					>
						Save Changes
					</ButtonAsyncState>
				</div>
			</If>

			<If condition={organisation.integrationXeroEnabled ?? false}>
				<OrganisationMonitorXeroDetails
					isOpen={integrationPartnerXero}
					organisation={organisation}
					onClose={closeIntegrationModal}
					updateOrganisation={o => {
						originalOrganisationEntity.assignAttributes(o);
						setOrganisation(new OrganisationEntity(originalOrganisationEntity));
					}}
				/>
			</If>
		</div>
	);
};

interface CreditBureauCheckboxesProps {
	organisation: OrganisationEntity;
	userCanEdit: boolean;
	setHasChanges: (set: boolean) => void;
}

const CreditBureauCheckboxes = observer((props : CreditBureauCheckboxesProps) => {
	const { organisation: org, userCanEdit, setHasChanges } = props;

	const onChecked = (creditBureau: CreditBureauEntity) => action((event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			org.authorisedCreditBureaus.push(
				new OrganisationAuthorisedCreditBureau(
					{ authorisedCreditBureauId: creditBureau.id },
				),
			);
		} else {
			let index;
			do {
				index = org.authorisedCreditBureaus.findIndex(
					(x: OrganisationAuthorisedCreditBureau) => (
						x.authorisedCreditBureauId === creditBureau.id
					),
				);
				if (index !== -1) {
					org.authorisedCreditBureaus.splice(index, 1);
				}
			} while (index !== -1);
		}
		setHasChanges(true);
	});

	return (
		<CheckboxGroup
			label=""
			className="authorised-applications"
			tooltip="ok"
		>
			{CreditBureauEntity.creditBureausSync.map(creditBureau => (
				<CheckboxSetter
					key={creditBureau.id}
					value={org.authorisedCreditBureaus.some(
						(x: OrganisationAuthorisedCreditBureau) => x.authorisedCreditBureauId === creditBureau.id,
					)}
					onChecked={onChecked(creditBureau)}
					label={creditBureau.name}
					isDisabled={!userCanEdit}
				/>
			))}
		</CheckboxGroup>
	);
});

const IntegrationCardXeroOrganisation = (props: { organisation: OrganisationEntity, selfPath: string}) => {
	const { organisation, selfPath } = props;

	const {
		integrationXeroTokenExpiry: tokenExpiry,
	} = organisation;

	const isConnected = !!tokenExpiry;

	if (isConnected) {
		return (
			<ActionCard
				className="xero"
				title="Xero"
				logo={XeroLogo}
				content={(
					<>
						Details TODO
					</>
				)}
				link={{
					text: 'Manage',
					display: Display.Solid,
					colors: Colors.Success,
					href: `${selfPath}/Xero`,
				}}
			/>
		);
	}

	return (
		<ActionCard
			className="xero"
			title="Xero"
			logo={XeroLogo}
			content={(
				<>
					Xero accounting software is made for small businesses.
					Automate admin and work from anywhere, anytime.
				</>
			)}
			link={{
				text: 'Connect',
				display: Display.Outline,
				colors: Colors.Primary,
				href: `${selfPath}/Xero`,
			}}
		/>
	);
};

export default OrganisationMonitor;
