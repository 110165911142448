import Modal from 'Views/Components/Modal/Modal';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { UserEntity } from 'Models/Entities';
import { TextField } from 'Views/Components/TextBox/TextBox';
import Password from 'Views/Components/Password/Password';
import HandleEvents from 'Util/HandleEvents';

export interface UpdateEmailState {
	password: string,
	newEmail: string;
	errors: { [attr: string]: string };
}
const defaultUpdateEmailState: UpdateEmailState = {
	password: '',
	newEmail: '',
	errors: {},
};

export interface UpdateEmailModalProps {
	showModal: boolean,
	user: UserEntity,
	onModalClose?: () => void,
}

// eslint-disable-next-line react/function-component-definition
const UpdateEmailModal = (props: UpdateEmailModalProps) => {
	const { showModal, user, onModalClose } = props;

	const [state, setState] = useState(defaultUpdateEmailState);
	const [sending, setSending] = useState(false);
	const isMyAccount = store.userId === user.id;

	const onClose = () => {
		setState(defaultUpdateEmailState);

		if (!!onModalClose) {
			onModalClose();
		}
	};

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Store the current state to be changed and set later
		const updatedState: UpdateEmailState = { ...state };

		// Clear the error list
		updatedState.errors = {};

		// Validate fields prior to submitting
		if (isMyAccount && !updatedState.password) {
			updatedState.errors.password = 'Current Password is required';
		} else if (!updatedState.newEmail) {
			updatedState.errors.newEmail = 'New email is required';
		} else if (updatedState.newEmail === user.email) {
			updatedState.errors.newEmail = 'New email must not be the same as current email';
		}

		// Store the errors in the reset password state
		setState(updatedState);

		// If there are no errors then we post to the endpoint
		if (Object.keys(updatedState.errors).length <= 0) {
			setSending(true);

			// change the url and request data based on if it's our account
			let url: string;
			let data: { password?: string, newEmail: string, oldEmail?: string };
			if (isMyAccount) {
				url = `${SERVER_URL}/api/account/update-email-request`;
				data = {
					password: state.password,
					newEmail: state.newEmail,
				};
			} else {
				url = `${SERVER_URL}/api/account/update-user-email`;
				data = {
					oldEmail: user.email,
					newEmail: state.newEmail,
				};
			}
			axios.post(
				url,
				data,
			)
				.then(() => {
					alertToast(
						`A confirmation email has been sent to ${state.newEmail}. Once confirmed, 
						${isMyAccount ? 'your' : "this user's"} email will be updated.`,
						'success',
					);
					onClose();
				})
				.catch(({ response }) => {
					setSending(false);
					const errorMessage = response.status === 500 ? 'Incorrect current password' : 'Authorization Error';
					alertToast(`Email could not be changed: ${errorMessage}`, 'error');
				});
		}
	};

	return (
		<Modal
			isOpen={showModal}
			label="Update email"
			onRequestClose={onClose}
			className="access-modal update-email-modal"
		>
			<div className="update-modal">
				<div className="update-form">
					<form className="register" onSubmit={onSubmit}>
						<div className="title forgot-title">
							<h5>Update email address</h5>
						</div>
						<p className="forgot-password-desc">
							Enter an updated email. This new email will be sent a confirmation, once confirmed
							the new email will take affect.
						</p>
						{isMyAccount
							? (
								<Password
									model={state}
									modelProperty="password"
									label="Current password"
									placeholder="Enter current password"
									onAfterChange={event => setState(stateToUpdate => {
										stateToUpdate.password = event.target.value;
										return { ...stateToUpdate };
									})}
									errors={state.errors.password}
								/>
							)
							: null}

						<TextField
							model={state}
							modelProperty="newEmail"
							label="New email address"
							placeholder="Enter email address"
							onAfterChange={event => setState(stateToUpdate => {
								stateToUpdate.newEmail = event.target.value;
								return { ...stateToUpdate };
							})}
							errors={state.errors.newEmail}
						/>

						<ButtonGroup alignment={Alignment.HORIZONTAL}>
							<Button
								type="button"
								className="cancel-btn"
								display={Display.Outline}
								sizes={Sizes.Medium}
								{...HandleEvents(onClose)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="update-email-btn"
								display={Display.Solid}
								sizes={Sizes.Medium}
								disabled={sending}
							>
								Update
							</Button>
						</ButtonGroup>
					</form>
				</div>
			</div>
		</Modal>
	);
};
export default UpdateEmailModal;
