import { Redirect, RouteComponentProps } from 'react-router';
import queryString from 'querystring';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import Spinner from 'Views/Components/Spinner/Spinner';
import React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';

// This page doesn't actually show any content. (other than a spinner) It just checks whether the user's attempt to
// change their email is successful. Then it redirects the user to the login page, with either a success or error message.
const UpdateEmailTransientPage = (props: RouteComponentProps) => {
	const { location } = props;
	const queryParams = location.search.substring(1);
	const { id, token, newEmail } = queryString.parse(queryParams);

	// When the component loads, we make a request to the server
	const response = useAsync(async () => axios.post(
		'/api/account/update-email',
		{
			id: id,
			token: token,
			newEmail: newEmail,
		},
	), []);

	if (response.type === 'loading') {
		return <Spinner />;
	}

	if (response.type === 'error') {
		console.error(response.error);
		alertToast(
			'There was an error while attempting to update your email. Please retry the process, or contact'
			+ ' Access Intell to help.',
			'error',
		);
	} else {
		// If we've successfully changed emails, we log the user out so they can login again
		store.clearLoggedInUser();
		alertToast('Email changed successfully. You can now use the new email to login to your account', 'success');
	}

	return (
		<Redirect to="/login" />
	);
};

export default UpdateEmailTransientPage;
