import * as React from 'react';
import {
	Adjudication, Insolvency, Party,
} from 'Models/Interfaces/AlaresReport';
import AdverseAlertsTable from './AdverseAlertsTable';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import AdverseAlertsAtoUpdateDetailsModal from './AdverseAlertsAtoUpdateDetailsModal';

export interface UpdateTableType {
	uuid: string,
	notificationTimeString: string,
	notificationTime: Date,
	type: string,
	courtName: string,
	caseType: string,
	caseNumber: string,
	caseName: string,
	dataType: ('insolvency' | 'adjudication'),
	insolvency?: Insolvency,
	adjudication?: Adjudication,
	parties?: Party[],
}

const AdverseAlertsInsolvencies = (props: {
	insolvencies: Insolvency[],
}) => {
	const { insolvencies } = props;
	const [highlightedInsolvency, setHighlightedInsolvency] = useState<UpdateTableType | undefined>(undefined);

	const insolvenciesList = useMemo<UpdateTableType[]>(() => {
		const reducedList = insolvencies.map(x => {
			return {
				uuid: x.uuid,
				notificationTimeString: x.notification_time,
				notificationTime: moment(x.notification_time).toDate(),
				type: x.type,
				courtName: x.court_name,
				caseType: x.case_type,
				caseNumber: x.case_number?.toString(),
				caseName: x.case_name?.toString(),
				dataType: 'insolvency',
				insolvency: x,
				adjudication: undefined,
				parties: x.parties,
			} as UpdateTableType;
		});

		reducedList.sort((a, b) => b.notificationTime.getTime() - a.notificationTime.getTime());

		return reducedList;
	}, [insolvencies]);

	const closeInsolvencyModal = useCallback(() => {
		setHighlightedInsolvency(undefined);
	}, []);

	return (
		<>
			<AdverseAlertsTable
				tableName="Insolvencies"
				className="insolvencies"
				collection={insolvenciesList ?? []}
				columns={[
					{
						displayName: 'Date',
						columnName: 'date',
						value: update => moment(update.notificationTime).format('Do MMMM YYYY'),
						sortable: false,
						className: 'field-date',
					},
					{
						displayName: 'Type',
						columnName: 'type',
						value: update => update.type,
						sortable: false,
						className: 'field-update',
					},
					{
						displayName: 'Court name',
						columnName: 'courtName',
						value: update => update.courtName,
						sortable: false,
						className: 'field-court-name',
					},
					{
						displayName: 'Case type',
						columnName: 'caseType',
						value: update => update.caseType,
						sortable: false,
						className: 'field-case-type',
					},
					{
						displayName: 'Case number',
						columnName: 'number',
						value: update => update.caseNumber,
						sortable: false,
						className: 'field-case-number',
					},
					{
						displayName: 'Case name',
						columnName: 'name',
						value: update => update.caseName,
						sortable: false,
						className: 'field-case-name',
					},
					{
						displayName: '',
						columnName: 'details',
						value: update => (
							<span
								className="icon icon-right icon-open-in-new"
								{...HandleEvents(() => setHighlightedInsolvency(update))}
							>
								DETAILS
							</span>
						),
						sortable: false,
						className: 'field-details',
					},
				]}
				sortColumn="date"
				sortDescending
				idColumn="uuid"
			/>
			<If condition={!!highlightedInsolvency}>
				<AdverseAlertsAtoUpdateDetailsModal
					update={highlightedInsolvency ?? {} as UpdateTableType}
					onClose={closeInsolvencyModal}
				/>
			</If>
		</>
	);
};

export default AdverseAlertsInsolvencies;
