import { ESignPagingOptions, ESignUserSummary } from 'ESign/Types/Summary/ESignUserSummary';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { SERVER_URL } from 'Constants';
import { ESignInitialResponse } from 'ESign/Types/ESignInitialResponse';
import { ESignUserCeremonySaveRequest } from 'ESign/Types/ESignSaveRequest';
import ESignCeremonyType from 'ESign/Types/Enums/ESignCeremonyType';
import { store } from 'Models/Store';

const X_ESIGNATURE_HEADER = 'X-ESignature';
const REQUEST_TIMEOUT = 10000;

const defaultConfig = {
	timeout: REQUEST_TIMEOUT,
};

axios.defaults.baseURL = SERVER_URL;

axios.interceptors.response.use(
	async response => {
		return response;
	},
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(config => {
	if (config.url?.startsWith('/api/esign')) {
		config.headers[X_ESIGNATURE_HEADER] = store.configuration.eSignPublicKey;
	}

	// if the user is authorised, add the specific header with key access-esignature to the request
	return config;
});

const requests = {
	get: <T>(url: string, config?: AxiosRequestConfig) => axios.get<T>(url, config).then(responseBody),
	post: <T>(url: string, body: object) => axios.post<T>(url, body).then(responseBody),
	put: <T>(url: string, body: object) => axios.put<T>(url, body).then(responseBody),
	delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const ESignVerification = {
	// eslint-disable-next-line max-len
	sendOTP: (userId: string, workflowId: string) => requests.get<string>(`/api/esign/user/${userId}/send/${workflowId}`),
	// eslint-disable-next-line max-len
	verifyOTP: (userId: string, accessCode: string) => requests.post(`/api/esign/user/${userId}/verify`, { accessCode }),
};

const ESignUser = {
	get: (userId: string, workflowId: string) => requests.get<ESignInitialResponse>(`/api/esign/user/${userId}/${workflowId}`),
	getSummary: (userId: string, pagingOptions: ESignPagingOptions) => requests.get<ESignUserSummary>(`/api/esign/user/${userId}/summary`, {
		params: pagingOptions,
	}),
	getIpAddressFromIpIfy: () => requests.get<{ip: string}>(
		'https://api.ipify.org?format=json',
		defaultConfig,
	),
	getIpFromGeoLocation: () => requests.get<{IPV4: string}>(
		'https://geolocation-db.com/json/',
		defaultConfig,
	),
	getIpFromIpApi: () => requests.get<{ip: string}>(
		'https://ipapi.co/json/',
		defaultConfig,
	),
	saveTemplateContentOnly: (
		userId: string,
		ceremonyId: string,
		content: string,
		signature: string,
		type: ESignCeremonyType,
	) => requests.post<void>(
		`/api/esign/user/${userId}/save/${ceremonyId}/content`,
		{ type: type, content: content, signature: signature },
	),
	saveCeremony: (
		userId: string,
		ceremonyId: string,
		requestBody: ESignUserCeremonySaveRequest,
	) => requests.post<{
		succeed: boolean, errors: Record<string, string>
	}>(`/api/esign/user/${userId}/save/${ceremonyId}`, requestBody),

	rejectCeremony: (userId: string, ceremonyId: string, comment: string, ceremonyType: ESignCeremonyType) => requests
		.post<void>(`/api/esign/user/${userId}/reject/${ceremonyId}`, { comment: comment, ceremonyType: ceremonyType }),
};

const EsignWorkflow = {
	markExpired: (workflowId: string) => requests.post<void>(`/api/esign/workflow/${workflowId}/expired`, {}),
};

const eSignEndpoints = {
	ESignVerification,
	ESignUser,
	EsignWorkflow,
};

export default eSignEndpoints;
