import * as React from 'react';
import { OrganisationEntity } from 'Models/Entities';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import Modal from 'Views/Components/Modal/Modal';
import useAsync from 'Hooks/useAsync';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { confirmModalAsync } from '../Modal/ModalUtils';
import ComboboxSetter from '../Combobox/ComboboxSetter';
import { LocaleCompareAttrAsc } from 'Util/StringUtils';
import axios from 'axios';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import InlineSpinner from '../Spinner/InlineSpinner';
import alertToast from 'Util/ToastifyUtils';

export interface OrganisationMonitorXeroDetailsProps {
	isOpen: boolean;
	organisation: OrganisationEntity;
	updateOrganisation: (o: OrganisationEntity) => void;
	onClose: () => void;
}

type TenantBasic = {tenantId: string, tenantName: string, businessEntityId?: string};
type BusinessEntityBaisc = {id: string, name: string, tenantId?: string};

const OrganisationMonitorXeroDetails = (props: OrganisationMonitorXeroDetailsProps) => {
	const {
		isOpen,
		organisation,
		updateOrganisation,
		onClose,
	} = props;

	const {
		integrationXeroTokenExpiry: tokenExpiry,
	} = organisation;

	const [hasChanges, setHasChanges] = useState(false);
	const [tenants, setTenants] = useState<TenantBasic[]>([]);
	const [businessEntities, setBusinessEntities] = useState<BusinessEntityBaisc[]>([]);

	const setBusinessEntityTenantId = (tenantId: string, businessEntityId: string) => {
		setHasChanges(true);
		setTenants(mappedTenants => {
			const newMappedTenants = [...mappedTenants];
			const tenantIndex = newMappedTenants.findIndex(t => t.tenantId === tenantId);
			const businessEntityIdIndex = newMappedTenants.findIndex(t => t.businessEntityId === businessEntityId);

			if (businessEntityIdIndex >= 0) {
				newMappedTenants[businessEntityIdIndex].businessEntityId = undefined;
			}
			if (tenantIndex >= 0) {
				newMappedTenants[tenantIndex].businessEntityId = businessEntityId;
			}

			return newMappedTenants;
		});
	};

	const isConnected = !!tokenExpiry;

	const getXeroLoginLinkUrl = (organisationId: string) => {
		return `/api/AccountingIntegration/Xero/login/${organisationId}`;
	};
	const getXeroDisconnectUrl = (organisationId: string) => {
		return `/api/AccountingIntegration/Xero/disconnect/${organisationId}`;
	};
	const getXeroTenantListUrl = (organisationId: string) => {
		return `/api/AccountingIntegration/Xero/tenant/list/${organisationId}`;
	};
	const getXeroTenantLinkBusinessEntitiesUrl = () => {
		return '/api/AccountingIntegration/Xero/tenant/link-businessentities';
	};

	const onSaveChanges = async () => {
		const modal = confirmModalAsync(
			'Confirm Save Changes',
			'Are you sure you wish to change the link between Xero Organisation and Business Entity?',
		);

		try {
			await modal.result();
		} catch (e) {
			// Cancel
			return;
		}

		const businessEntityMapTenantId = {};
		businessEntities.forEach(b => {
			businessEntityMapTenantId[b.id] = null;
		});
		tenants.forEach(t => {
			if (t.businessEntityId) {
				businessEntityMapTenantId[t.businessEntityId] = t.tenantId;
			}
		});

		try {
			await axios.post(
				getXeroTenantLinkBusinessEntitiesUrl(),
				{
					businessEntityMapTenantId,
				},
			);
		} catch (e) {
			alertToast('An error occured linking Business Entities to Xero Organisation(s).', 'error');
			return;
		}

		alertToast('Business Entities linked to Xero Organisation(s) successfully.', 'success');

		modal.close();
		onClose();
	};

	const onDisconnect = async () => {
		const modal = confirmModalAsync('Confirm Disconnect from Xero', (
			<>
				Disconnecting from Xero will also unlink all your Business Entities, and
				automated ATB Upload will no longer be available.
			</>
		), {
			confirmText: 'Disconnect',
			waitingText: 'Disconnecting...',
		});

		try {
			await modal.result();
		} catch (e) {
			// Cancel
			return;
		}

		try {
			await axios.post(getXeroDisconnectUrl(organisation.id));
		} catch (e) {
			// Ignore any error
		}

		alertToast('Xero has been disconnected from this Organisation.', 'success');

		organisation.integrationXeroTokenExpiry = null;
		updateOrganisation(organisation);

		// Worse solution, but the alternative is some very ugly code.
		// Wait for the state change to take effect before closing the modal.
		// Without the wait, a console error is thrown.
		await new Promise<void>(r => setTimeout(r, 100));

		modal.close();
		onClose();
	};

	const onConnect = (e: MouseEvent<HTMLAnchorElement>) => {
		// This "disables" the link after it has been clicked
		if (e.currentTarget.getAttribute('disabled') !== null) {
			e.preventDefault();
		} else {
			e.currentTarget.setAttribute('disabled', '');
		}
	};

	useAsync(async (): Promise<void> => {
		if (!isConnected || !isOpen) {
			return;
		}

		const { data } = await axios.get(getXeroTenantListUrl(organisation.id));

		const mappedTenants: TenantBasic[] = data.tenants;
		const businessEntitysRaw: BusinessEntityBaisc[] = data.businessEntities;

		const businessEntityTenantIdMap = {};
		businessEntitysRaw.forEach(b => {
			if (b.tenantId) {
				businessEntityTenantIdMap[b.tenantId] = b.id;
			}
		});

		mappedTenants.forEach(t => {
			t.businessEntityId = businessEntityTenantIdMap[t.tenantId];
		});

		setTenants(mappedTenants);
		setBusinessEntities(businessEntitysRaw);
	}, [isConnected, organisation]);

	if (!isConnected) {
		return (
			<Modal
				isOpen={isOpen}
				label="Xero"
				onRequestClose={onClose}
				className="organisation-monitor-xero-details not-connected"
			>
				<h4>Manage Xero</h4>
				<div className="instructions">
					<ol>
						<li>Click [Connect] Below</li>
						<li>Log in with your Xero Account</li>
						<li>Select all the applicable Organisations in Xero</li>
						<li>Click [Continue with X organisations] to confirm and return</li>
						<li>Associate your Xero Businesses in Access Intel</li>
					</ol>

					<a
						href={getXeroLoginLinkUrl(organisation.id)}
						onClick={onConnect}
					>
						<ButtonAsyncState
							display={Display.Solid}
							colors={Colors.Primary}
							onPress={() => new Promise<void>(_ => {})}
							waitingText="Redirecting..."
						>
							Connect
						</ButtonAsyncState>
					</a>
				</div>
				<div key="actions" className="modal__actions">
					<Button
						display={Display.Outline}
						colors={Colors.Primary}
						onClick={onClose}
					>
						Cancel
					</Button>
				</div>
			</Modal>
		);
	}

	const loadingTenants = (!tenants || !tenants.length);

	return (
		<Modal
			isOpen={isOpen}
			label="Xero"
			onRequestClose={onClose}
			className="organisation-monitor-xero-details connected"
		>
			<h4>Manage Xero</h4>
			<h5>Link Business Entities</h5>
			<div className="tenant-list">
				<div className="thead">
					<div>Xero Organisation</div>
					<div>Business Entity</div>
				</div>
				{tenants.map(tenant => (
					<div key={tenant.tenantId}>
						<div>{tenant.tenantName}</div>
						<div>
							<ComboboxSetter
								className="business-entity-selector"
								value={tenant.businessEntityId}
								setValue={value => setBusinessEntityTenantId(tenant.tenantId, value)}
								getOptionValue={(value?: string) => value}
								placeholder="Business Entity"
								label=""
								searchable
								options={businessEntities
									.sort(LocaleCompareAttrAsc('name'))
									.map(businessEntity => {
										return {
											display: businessEntity.name,
											value: businessEntity.id,
										};
									}) ?? []}
							/>
						</div>
					</div>
				))}
			</div>
			{loadingTenants && (<InlineSpinner />)}
			<div className="actions modal__actions">
				<Button
					className="xero-disconnect"
					display={Display.Outline}
					colors={Colors.Error}
					sizes={Sizes.Small}
					onClick={onDisconnect}
				>
					Disconnect Xero
				</Button>
				<a
					className="btn btn--outline btn--primary btn--sm xero-reconnect"
					href={getXeroLoginLinkUrl(organisation.id)}
				>
					Update Xero Organisations
				</a>
			</div>
			<div className="modal__actions">
				<Button
					display={Display.Outline}
					colors={Colors.Primary}
					onClick={onClose}
				>
					Close
				</Button>
				<Button
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={onSaveChanges}
					disabled={!hasChanges}
				>
					Save Changes
				</Button>
			</div>
		</Modal>
	);
};

export default OrganisationMonitorXeroDetails;
