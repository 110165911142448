import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router';
import { observable, runInAction } from 'mobx';
import {
	BusinessEntity,
	OrganisationEntity,
	RegistrationEntity, UserEntity,
} from 'Models/Entities';
import { store } from 'Models/Store';
import Wizard, { WizardStepInformation } from 'Views/Components/Wizard/Wizard';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import Spinner from 'Views/Components/Spinner/Spinner';
import alertToast from 'Util/ToastifyUtils';
import AppSettings from 'Models/AppSettings';
import ReferralPartnerRegistrationIndex
	from '../Components/Wizard/ReferralPartnerRegistration/ReferralPartnerRegistrationIndex';
import ReferralRegistrationData from '../../Models/Entities/ReferralRegistrationData';
import ReferralPartnerBusinessDetails
	from '../Components/Wizard/ReferralPartnerRegistration/ReferralPartnerBusinessDetails';
import ReferralPartnerBenefitsStep from '../Components/Wizard/ReferralPartnerRegistration/ReferralPartnerBenefitsStep';
import ReferralPartnerTermsAndConditionsStep
	from '../Components/Wizard/ReferralPartnerRegistration/ReferralPartnerTermsAndConditionsStep';
import ShowProgressSavedModalPasscode from '../Components/Wizard/ShowProgressSavedModalPasscode';
import checkRegistrationAvailability from 'Util/CheckRegistrationAvailability';
import AccountDetailsStep from '../Components/Wizard/OrganisationRegistration/AccountDetailsStep';
import RegistrationSubmittedStep from '../Components/Wizard/OrganisationRegistration/RegistrationSubmittedStep';
import moment from 'moment';

// Put the names in a separate list, so that we can reference them elsewhere
// If you edit the registration step list, make sure to edit this too
export const registrationStepNames = [
	'Referral Partner Registration Index',
	'Register your details',
	'Business details',
	'Benefits',
	'Terms & conditions',
];

const ReferralPartnerRegistrationPage = observer((props: RouteComponentProps<{ registrationId?: string}>) => {
	const { match } = props;
	// state used to see if we need to re-run query as the passcode was validated
	const [passcodeState, setPassscodeState] = React.useState('unvalidated' as ('unvalidated' | 'validated'));

	// if cancel on the modal is pressed we close the modal and just change the path
	const onModalClose = () => {
		store.modal.hide();
		store.routerHistory.push('/hub/referral-partner/registration');
	};

	// sends the passcode email
	const sendPasscodeEmail = async (id: string) => {
		await axios.post(
			`${SERVER_URL}/api/entity/RegistrationEntity/saveProgress`,
			{
				Id: id,
				RegistrationType: 'Referral Partner',
			},
		);
	};

	// function to return a new and empty registration
	const getNewRegistration = async () => {
		return observable(new RegistrationEntity({
			referralStatus: 'INCOMPLETE',
			parsedReferralData: new ReferralRegistrationData({
				user: new UserEntity(),
				organisation: new OrganisationEntity({
					primaryBusinessEntity: new BusinessEntity(),
				}),
				page: 0,
			}),
			startDate: moment().utc().toDate(),
		}));
	};

	const response = useAsync(
		async () => {
			if (!match.params.registrationId) {
				return getNewRegistration();
			}

			// check the status of the registration. If the registration is completed or does not exist, raise a toast
			// and push to empty registration
			try {
				// this endpoint will thrown an error if exists or completed.
				await axios.post(
					`${SERVER_URL}/api/entity/RegistrationEntity/checkProgress/${match.params.registrationId}`,
				);
			} catch (exception) {
				alertToast('No matching registration exists. It may have already been completed. Otherwise, '
						+ 'you can start a new registration now.', 'error');
				store.routerHistory.push('/hub/referral-partner/registration');
				return getNewRegistration();
			}

			// if we haven't been validated check the passcode
			if (passcodeState === 'unvalidated') {
				await checkRegistrationAvailability(match.params.registrationId, setPassscodeState, onModalClose, sendPasscodeEmail);

				return null;
			}
			// if we have gotten here we know the passcode has been validated
			try {
				const result = await axios.post(
					`${SERVER_URL}/api/entity/RegistrationEntity/continue`,
					{
						id: `${match.params.registrationId}`,
					},
				);
				const registration = new RegistrationEntity(result.data);
				if (!registration.startDate) {
					registration.startDate = moment().utc().toDate();
				}
				return registration;
			} catch (e) {
				alertToast('No matching registration exists. It may have already been completed. Otherwise, '
					+ 'you can start a new registration now.', 'error');
				store.routerHistory.push('hub/referral-partner/registration');
				return new RegistrationEntity();
			}
		},
		[passcodeState], // we re-run when the passcode has been validated
	);

	if (store.loggedIn) {
		return <Redirect to="/" />;
	}

	if (response.type === 'loading' || response.type === 'error') {
		return <Spinner />;
	}
	if (response.data === null) {
		return (null);
	}

	// Describes the steps and the components
	// If you edit this list, make sure to edit the registrationStepNames list above too
	const registrationEntity = response.data as RegistrationEntity;
	const wizardStepInformation: WizardStepInformation[] = [
		{
			name: 'Referral Partner Registration Index',
			component: ReferralPartnerRegistrationIndex,
			hideStepInSidebar: true,
			hideSidebarComponents: true,
			disableSave: true,
		},
		{
			name: 'Register your details',
			component: AccountDetailsStep,
			topBarTitle: 'Referral Partner Program',
			// since the save progress needs an email we disable it on the first step
			disableSaveProgress: true,
		},
		{
			name: 'Business details',
			component: ReferralPartnerBusinessDetails,
			topBarTitle: 'Referral Partner Program',
		},
		{
			name: 'Benefits',
			component: ReferralPartnerBenefitsStep,
			topBarTitle: 'Referral Partner Program',
		},
		{
			name: 'Terms & conditions',
			component: ReferralPartnerTermsAndConditionsStep,
			topBarTitle: 'Referral Partner Program',
			disableSave: true,
		},
		{
			name: 'Registration Submitted',
			component: RegistrationSubmittedStep,
			hideStepInSidebar: true,
			hideTopBar: true,
			hideSidebarComponents: true,
			disableSave: true,
			options: {
				submissionType: 'referral-partner',
			},
		},
	];

	return (
		<div className="body-content referral-partner-registration-page">
			<Wizard
				stepInformation={wizardStepInformation}
				wizardName="Referral Partner Registration"
				model={registrationEntity.parsedReferralData}
				pageProperty="page"
				onCancel={() => store.routerHistory.push('/login')}
				onSaveDraft={async () => {
					await registrationEntity.save();
					await axios.post(
						`${SERVER_URL}/api/entity/RegistrationEntity/saveProgress`,
						{
							Id: registrationEntity.id,
							RegistrationType: 'Referral Partner',
						},
					);
					ShowProgressSavedModalPasscode(
						registrationEntity.userEmail,
					);
				}}
				onChangePage={async (pageIndex: number) => {
					// We don't want to save on every page changes because some pages
					// may not require this because they have unique logic.
					// we decrement the page count here because we see if the previous page had save disabled
					if (wizardStepInformation[pageIndex - 1].disableSave) {
						return;
					}

					await registrationEntity.save();

					// If the registration email hasn't been sent then we send the
					// continue link and passcode to the given email
					if (!registrationEntity.parsedReferralData.continueEmailSent) {
						await sendPasscodeEmail(registrationEntity.id);
						runInAction(() => {
							registrationEntity.parsedReferralData.continueEmailSent = true;
						});
						store.routerHistory.push(`/hub/referral-partner/registration/${registrationEntity.id}`);
					}
				}}
				data={registrationEntity} // We pass in the registration entity so that attach files step can get the ID
			/>
		</div>
	);
});

export default ReferralPartnerRegistrationPage;
