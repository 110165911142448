import * as React from 'react';
import {
	BusinessEntity, BusinessEntityUser, OrganisationEntity,
} from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useMemo, useState } from 'react';
import If from 'Views/Components/If/If';
import AddBusinessDetailsModal from 'Views/Components/BusinessEntities/AddBusinessDetailsModal';
import { action, runInAction } from 'mobx';
import { TextFieldSetter } from '../TextBox/TextFieldSetter';
import classNames from 'classnames';
import EntityList from '../EntityList/EntityList';
import ProductSelectedBox from '../Clients/ProductSelectedBox';
import alertToast from '../../../Util/ToastifyUtils';
import { standardisePhone } from '../../../Util/AttributeUtils';

export type BusinessEntityListProps = {
	organisation: OrganisationEntity,
	currentPath: string,
}

const BusinessEntityList = observer((props: BusinessEntityListProps) => {
	const {
		organisation, currentPath,
	} = props;

	const [showAddModal, setShowAddModal] = useState(false);
	const newBusinessEntity = useLocalStore(() => new BusinessEntity());
	const [search, setSearch] = useState('');

	// this is what the table actually uses, we perform the default sorting and searching here.
	// we ensure the list has the primary business entity at the top and inactive entities at the bottom.
	// we sort by name, so for two same status entities they are sorted by name
	const sortedBusinessEntities = useMemo(() => {
		const modifiedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		const re = new RegExp(modifiedSearch, 'i');
		return organisation.businessEntitys
			.filter(BusinessEntity.IsLinkedToUser)
			.filter(x => search !== '' ? x.abn.search(re) !== -1 || x.name.search(re) !== -1 : true)
			.sort((one, two) => {
				// ensure the primary business entity is at the top
				if (one.id === organisation.primaryBusinessEntityId) {
					return -1;
				}
				if (two.id === organisation.primaryBusinessEntityId) {
					return 1;
				}
				if (one.isActive !== two.isActive && !one.isActive) {
					return 1;
				}
				if (one.isActive !== two.isActive && !two.isActive) {
					return -1;
				}
				return one.name.localeCompare(two.name);
			});
	}, [search, organisation.businessEntitys, organisation.primaryBusinessEntityId]);

	return (
		<>
			<h4>Business entities</h4>
			<div className="business-entity-list">
				<div className="search-container">
					<div className="searchbar search">
						<TextFieldSetter
							className={classNames('search-input search__collection')}
							value={search}
							setValue={setSearch}
							label=""
							labelVisible={false}
							placeholder="Search for business entity by Business name or ABN"
						/>
					</div>
					<div className="actions">
						<Button
							className="add-business-entity"
							display={Display.Outline}
							icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
							colors={Colors.Primary}
							onClick={() => setShowAddModal(true)}
						>
							Add business entity
						</Button>
					</div>
				</div>
				<div className="collection-component">
					<EntityList
						collection={sortedBusinessEntities}
						columns={[
							{
								displayName: 'Business name',
								columnName: 'name',
								value: businessEntity => businessEntity.name,
								className: 'field-business-name',
							},
							{
								displayName: 'ABN',
								columnName: 'abn',
								value: businessEntity => businessEntity.abn,
								className: 'field-abn',
							},
							{
								displayName: 'Products',
								columnName: 'products',
								value: businessEntity => (
									<ProductSelectedBox
										ppsrEnabled={businessEntity.enabledForPPSR}
										approveEnabled={businessEntity.enabledForApprove}
										intelEnabled={businessEntity.enabledForMonitor}
									/>
								),
								className: 'field-products',
							},
							{
								displayName: 'Entity name',
								columnName: 'entity-name',
								value: businessEntity => businessEntity.entityName,
								className: 'field-entity-name',
							},
							{
								displayName: 'Entity type',
								columnName: 'entity-type',
								value: businessEntity => businessEntity.entityTypeDescription,
								className: 'field-entity-type',
							},
							{
								displayName: 'Phone',
								columnName: 'phone',
								value: businessEntity => !businessEntity.phone ? <b>Not provided</b>
									: standardisePhone(businessEntity.phone),
								className: 'field-phone',
							},
							{
								displayName: 'Entity email',
								columnName: 'email',
								value: businessEntity => !businessEntity.email ? organisation
									.primaryBusinessEntity.email : businessEntity.email,
								className: 'field-email',
							},
							{
								displayName: 'Default',
								columnName: 'primaryBusinessEntity',
								value: businessEntity => businessEntity.id === organisation
									.primaryBusinessEntityId ? (
										<span
											className="icon"
										/>
									) : '',
								className: 'field-default',
							},
						]}
						idColumn="id"
						sortColumn="name"
						sortDescending
						onClickRow={businessEntity => {
							store.routerHistory
								.push(`${currentPath}/${businessEntity.id}`);
						}}
						rowClassName={businessEntity => classNames('no-even-shading', {
							disabled: !businessEntity.isActive,
						})}
					/>
				</div>
				<If condition={showAddModal}>
					<AddBusinessDetailsModal
						businessEntity={newBusinessEntity}
						organisationEmail={organisation?.primaryBusinessEntity?.email}
						onClose={() => setShowAddModal(false)}
						onSubmit={action(async () => {
							// Clone the business entity, so we can use the old object to add future business entities
							const businessEntityToAdd = new BusinessEntity(newBusinessEntity);
							businessEntityToAdd.organisationId = organisation.id;
							businessEntityToAdd.isActive = true;
							if (store.getUser?.organisation?.id === organisation.id) {
								businessEntityToAdd.users = [new BusinessEntityUser({
									userId: store.getUser?.id,
								})];
							}
							try {
								await businessEntityToAdd.save({
									address: {},
									users: {},
								});
								runInAction(() => {
									organisation.businessEntitys = [...organisation.businessEntitys,
										businessEntityToAdd];
									if (store.getUser?.organisation?.id === organisation.id) {
										store.getUser?.organisation?.businessEntitys.push(businessEntityToAdd);
										store.getUser.businessEntityIds?.push(businessEntityToAdd.id);
									}
									alertToast('Business entity successfully created', 'success');
								});
							} catch (exception) {
								alertToast('Business entity could not be created, please refresh '
									+ 'and try again', 'error');
							}

							runInAction(() => {
								// Reset the original business entity
								newBusinessEntity.clearFields();
								setShowAddModal(false);
							});
						})}
						additionalValidationFields={[]}
						isPrimaryBusinessEntity={false}
					/>
				</If>
			</div>
		</>
	);
});
export default BusinessEntityList;
