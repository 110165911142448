import { state } from "Models/Enums";
import { ComboboxOption } from "Views/Components/Combobox/Combobox";

type titleSearchOptionForQLD = "title-reference" | "lot-plan" | "individual-owner" | "organisation-owner" | "address" | "previous-title-reference";
const titleSearchOptionsForQLD: { [key in titleSearchOptionForQLD]: string } = {
	"title-reference": "Title reference",
	"lot-plan": "Lot on plan",
	"individual-owner": "Owner (individual)",
	"organisation-owner": "Owner (organisation)",
	"address": "Address",
	"previous-title-reference": "Previous title reference",
};

type titleSearchOptionForACT = "volume-folio" | "address" | "parcel";
const titleSearchOptionsForACT: { [key in titleSearchOptionForACT]: string } = {
	"volume-folio": "Volume/folio",
	"address": "Address",
	"parcel": "Parcel",
};

type titleSearchOptionForNSW = "title-reference" | "individual-owner" | "organisation-owner" | "address";
const titleSearchOptionsForNSW: { [key in titleSearchOptionForNSW]: string } = {
	"title-reference": "Title reference",
	"individual-owner": "Owner/lessee (individual)",
	"organisation-owner": "Owner/lessee (organisation)",
	"address": "Address",
};

type titleSearchOptionForNT = "volume-folio" | "lot-town" | "address";
const titleSearchOptionsForNT: { [key in titleSearchOptionForNT]: string } = {
	"volume-folio": "Volume/folio",
	"lot-town": "Lot/town",
	"address": "Property address",
};

type titleSearchOptionForTAS = "volume-folio" | "address";
const titleSearchOptionsForTAS: { [key in titleSearchOptionForTAS]: string } = {
	"volume-folio": "Volume/folio",
	"address": "Address",
};

type titleSearchOptionForSA = "volume-folio" | "address" | "plan-parcel";
const titleSearchOptionsForSA: { [key in titleSearchOptionForSA]: string } = {
	"volume-folio": "Volume/folio",
	"address": "Address",
	"plan-parcel": "Plan/parcel",
};

type titleSearchOptionForWA = "title-reference" | "individual-owner" | "organisation-owner" | "address";
const titleSearchOptionsForWA: { [key in titleSearchOptionForWA]: string } = {
	"title-reference": "Title reference",
	"individual-owner": "Owner (individual)",
	"organisation-owner": "Owner (organisation)",
	"address": "Address",
};

export type PropertySearchByTitleOptionForRegion = titleSearchOptionForQLD | titleSearchOptionForACT | titleSearchOptionForNSW | titleSearchOptionForNT | titleSearchOptionForTAS | titleSearchOptionForSA | titleSearchOptionForWA;

export const getPropertySearchByTitleAvailableOptions = 
(region: state): ComboboxOption<PropertySearchByTitleOptionForRegion>[] => {
	switch(region) {
		case 'QLD':
			return (Object.keys(titleSearchOptionsForQLD) as [titleSearchOptionForQLD])
				.map<ComboboxOption<titleSearchOptionForQLD>>(value => {
					return {
						display: titleSearchOptionsForQLD[value],
						value: value,
					};
				});
		case 'ACT':
			return (Object.keys(titleSearchOptionsForACT) as [titleSearchOptionForACT])
				.map<ComboboxOption<titleSearchOptionForACT>>(value => {
					return {
						display: titleSearchOptionsForACT[value],
						value: value,
					};
				});
		case 'NSW':
			return (Object.keys(titleSearchOptionsForNSW) as [titleSearchOptionForNSW])
				.map<ComboboxOption<titleSearchOptionForNSW>>(value => {
					return {
						display: titleSearchOptionsForNSW[value],
						value: value,
					};
				});
		case 'NT':
			return (Object.keys(titleSearchOptionsForNT) as [titleSearchOptionForNT])
				.map<ComboboxOption<titleSearchOptionForNT>>(value => {
					return {
						display: titleSearchOptionsForNT[value],
						value: value,
					};
				});
		case 'TAS':
			return (Object.keys(titleSearchOptionsForTAS) as [titleSearchOptionForTAS])
				.map<ComboboxOption<titleSearchOptionForTAS>>(value => {
					return {
						display: titleSearchOptionsForTAS[value],
						value: value,
					};
				});
		case 'SA':
			return (Object.keys(titleSearchOptionsForSA) as [titleSearchOptionForSA])
				.map<ComboboxOption<titleSearchOptionForSA>>(value => {
					return {
						display: titleSearchOptionsForSA[value],
						value: value,
					};
				});
		case 'WA':
			return (Object.keys(titleSearchOptionsForWA) as [titleSearchOptionForWA])
				.map<ComboboxOption<titleSearchOptionForWA>>(value => {
					return {
						display: titleSearchOptionsForWA[value],
						value: value,
					};
				});
		default:
			return [];
	}
}