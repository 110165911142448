import React from 'react'
import { ReferralPartnerGroupSection } from './ReferralPartnerGroupSection';
import { store } from 'Models/Store';
import If from 'Views/Components/If/If';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import alertToast from 'Util/ToastifyUtils';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { Button, Colors, Display, Sizes } from "Views/Components/Button/Button";
import { ButtonGroup } from "Views/Components/Button/ButtonGroup";

type Props = {
	customerId: string;
	organisationId: string;
	orgName: string; // Name of the partner organisation
	blurb: string; // Referral blurb text
	groupName: string; // Name of the Referral Partner Group
	groupColour: string; // Base colour of the Referral Partner Group
	logoId: string; // Organisation logo
	showBlurb: boolean; // Show full blurb
}

export const ReferralPartnerCard:React.FunctionComponent<Props> = observer(({
	customerId,
	organisationId,
	orgName,
	blurb,
	groupName,
	groupColour,
	logoId,
	showBlurb
}) => {
	const handleProceedClick = async () => {
		try  {
			const response = await axios.post<boolean>(`${SERVER_URL}/api/support-tickets?category=ReferToCollection`, {
				organisationId: organisationId,
			});

			if (response.data) {
				alertToast('Successfully referred for collection', 'success');
				store.modal.hide();
			} else {
				alertToast('An error occurred while processing your request, If error persist send this details via Email at processing@accessintell.com', 'error');
			}
		} catch (e) {
			alertToast('An error occurred while processing your request', 'error');
		}
	}

	return (
		<div className="referral-partner-tile">
			<div className="header">
				<img
					src={logoId !== null ? `${SERVER_URL}/api/files/${logoId}` : `${SERVER_URL}/favicon.ico`}
					alt={`Logo for ${orgName}`}
				/>
				<ReferralPartnerGroupSection
					name={groupName}
					colour={groupColour}
					selected
				/>
			</div>
			<div className="body">
				<h5>{orgName}</h5>
				<p className="referral-partner-blurb">{blurb}</p>
			</div>
			<If condition={showBlurb}>
				<ButtonGroup key="actions" className="modal__actions">
					<Button
						colors={Colors.Error}
						sizes={Sizes.Medium}
						display={Display.Text}
						onClick={() => store.modal.hide()}
					>
						Close
					</Button>

					<ButtonAsyncState
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						display={Display.Solid}
						onPress={handleProceedClick}
					>
						Proceed
					</ButtonAsyncState>
				</ButtonGroup>
			</If>
			<If condition={!showBlurb}>
				<button
					type="button"
					className="btn btn--solid btn--primary"
					onClick={() => {
						store.modal.show(
							'Referral Partner',
							<ReferralPartnerCard
								customerId={customerId}
								organisationId={organisationId}
								orgName={orgName}
								blurb={blurb}
								groupName={groupName}
								groupColour={groupColour}
								logoId={logoId}
								showBlurb
							/>,
							{className: 'modal-referral-partner-card'}
						);
					}}
				>
					Find Out More
				</button>
			</If>
		</div>
	)
});