import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import { BusinessEntity } from 'Models/Entities';
import {
	Button,
	Colors,
	Display,
} from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import ActionCard from 'Views/Components/ActionCard/ActionCard';
import alertToast from 'Util/ToastifyUtils';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';

import XeroLogo from 'Assets/Images/Xero_logo-blue.png';

export interface BusinessEntityIntelProps {
	businessEntity: BusinessEntity,
	onUpdate: () => void,
}

const BusinessEntityMonitor = observer((props: BusinessEntityIntelProps) => {
	const { businessEntity: originalBusinessEntity, onUpdate } = props;
	const userCanEdit = (store.userPermissions.commonManageBusinessEntities !== 'NONE');

	const [hasChanges, setHasChanges] = useState(false);
	const [businessEntity] = useState(() => {
		return new BusinessEntity(originalBusinessEntity);
	});

	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateField(field);
		}

		const validateSuccessful = Object.keys(businessEntity.errors).length <= 0;
		setHasChanges(validateSuccessful);
	};

	// True if any of the accounting integrations has been configured,
	// detectable by the presense of the tenant name.
	const hasAccountingIntegration = (
		!!businessEntity.integrationXeroTenantName
	);

	const saveChanges = async () => {
		await businessEntity.validateMonitorFields();

		if (Object.keys(businessEntity.errors).length !== 0) {
			alertToast('Business Entity details could not be saved', 'error');
			setHasChanges(false);
			return;
		}

		await businessEntity.save({}, {
			updateOptions: [
				{
					key: 'fieldsToUpdate',
					value: [
						'automatedAtbProcessingDayOfMonth',
						'enableEmailNotifications',
						'monitorNotificationsEmail',
					],
					graphQlType: '[String]',
				},
			],
		});

		originalBusinessEntity.automatedAtbProcessingDayOfMonth = businessEntity.automatedAtbProcessingDayOfMonth;
		originalBusinessEntity.enableEmailNotifications = businessEntity.enableEmailNotifications;
		originalBusinessEntity.monitorNotificationsEmail = businessEntity.monitorNotificationsEmail;

		alertToast('Business Entity details have been successfully updated', 'success');

		setHasChanges(false);
	};

	const renderCantAccessIntel = () => {
		if (store.userType === 'ORGANISATION_MANAGER' || store.userType === 'SUPER_USER') {
			return (
				<div className="be-cant-access-intel">
					<div className="content">
						<h3>Looking for Access Monitor?</h3>
						<p>
							Automatically receive real time credit scores on all customers.  Easily connect
							your accounting system or upload your customer data and understand your credit
							risk at a glance.
						</p>
						<p>
							To setup Monitor, please click below.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								if (businessEntity.organisationId === store.getUser?.organisation?.id) {
									store.routerHistory
										.push('/hub/organisation/products');
								} else {
									store.routerHistory.push(`/hub/clients/${businessEntity.organisationId}/products`);
								}
							}}
							className="intel-setup"
						>
							Monitor product setup
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="be-cant-access-intel">
				<div className="content">
					<h3>Looking for Access Monitor?</h3>
					<p>
						Automatically receive real time credit scores on all customers.  Easily connect
						your accounting system or upload your customer data and understand your credit
						risk at a glance.
					</p>
					<br />
					<p>
						To setup Monitor, please contact your Organisation Manager.
					</p>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.monitorMarketingUrl, '_blank');
						}}
						className="intel-learn-more"
					>
						Learn more about Access Monitor
					</Button>
				</div>
			</div>
		);
	};

	if (!(store.canAccessIntel && businessEntity.organisation.intelEnabled && businessEntity.enabledForMonitor)) {
		return (
			<div className="business-entity-intel-details">
				{renderCantAccessIntel()}
			</div>
		);
	}

	return (
		<div className="business-entity-intel-details">
			<h4>Monitor details</h4>
			<div className="accounting-system-cntr">
				<div className="accounting-system-label">Monitor business entity notifications</div>

				<div className="business-entity-monitor">
					<Checkbox
						className="input-group__checkbox2 input-padding__checkbox"
						model={businessEntity}
						modelProperty="enableEmailNotifications"
						label="Enable email notification"
						onAfterChecked={() => onChange('monitorNotificationsEmail', true)}
						isDisabled={!userCanEdit}
					/>
					<TextField
						className="email"
						model={businessEntity}
						modelProperty="monitorNotificationsEmail"
						label="Notifications email"
						placeholder="Enter Email"
						onAfterChange={() => onChange('monitorNotificationsEmail')}
						onChangeAndBlur={() => onChange('monitorNotificationsEmail', true)}
						errors={businessEntity.errors.monitorNotificationsEmail}
						isDisabled={!userCanEdit}
					/>
				</div>
			</div>

			<div className="accounting-system-cntr">
				<div className="accounting-system-label">Integration Details</div>
				<XeroIntegrationCardBusinessEntity businessEntity={businessEntity} onUpdate={onUpdate} />

				<If condition={hasAccountingIntegration}>
					<div className="scheduled-updates-label"><p>Schedule updates</p></div>
					<div className="dayofMonth">
						<Combobox
							model={businessEntity}
							modelProperty="automatedAtbProcessingDayOfMonth"
							label="Day of Month"
							getOptionValue={(value?: number) => value}
							options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => ({ display: value, value }))}
							onAfterChange={() => onChange('automatedAtbProcessingDayOfMonth')}
						/>
					</div>
				</If>
			</div>

			<div>
				<ButtonAsyncState
					className="save"
					colors={Colors.Primary}
					display={Display.Solid}
					onPress={saveChanges}
					readonly={!hasChanges}
				>
					Save Changes
				</ButtonAsyncState>
			</div>
		</div>
	);
});

export const XeroIntegrationCardBusinessEntity = (props: { businessEntity: BusinessEntity, onUpdate: () => void }) => {
	const { businessEntity, onUpdate } = props;
	const {
		integrationXeroTenantName: tenantName,
	} = businessEntity;

	const isConnected = !!tenantName;

	if (isConnected) {
		return (
			<ActionCard
				className="xero"
				title="Xero"
				logo={XeroLogo}
				content={(
					<>
						<p><b>Business</b>: {tenantName}</p>
						<i>This connection is managed by your organisation.</i>
					</>
				)}
				button={{
					text: 'Connected',
					display: Display.Solid,
					colors: Colors.Success,
				}}
			/>
		);
	}

	return (
		<ActionCard
			className="xero"
			title="Xero"
			logo={XeroLogo}
			content={(
				<>
					Xero accounting software is made for small businesses.
					Automate admin and work from anywhere, anytime.<br />
					<i>This connection is managed by your organisation.</i>
				</>
			)}
		/>
	);
};

export default BusinessEntityMonitor;
