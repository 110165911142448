/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import {
	Button,
	Display,
	Colors,
	Sizes,
} from 'Views/Components/Button/Button';
import { action, observable } from 'mobx';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { IUserResult, IStore } from 'Models/Store';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import Password from 'Views/Components/Password/Password';
import { isEmail } from 'Validators/Functions/Email';
import alertToast from 'Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { useContext } from 'react';
import { TwoFactorContext, TwoFactorMethods } from 'Services/TwoFactor/Common';
import useStore from 'Hooks/useStore';
import { login } from 'Services/Api/AuthorizationService';
import { buildUrl } from 'Util/FetchUtils';
// % protected region % [Add any extra imports here] on begin
import AccessLogo from 'Assets/Images/Access_logo.svg';
import checkTermsAccepted from 'Util/CheckTermsAccepted';
import { update } from '@intercom/messenger-js-sdk';
/* eslint-disable indent */
// % protected region % [Add any extra imports here] end

// % protected region % [Customise ILoginState here] off begin
interface ILoginState {
	username: string;
	password: string;
	rememberMe: boolean;
	errors: {
		username?: string,
		password?: string,
		[attr: string]: string | undefined,
	};
}
// % protected region % [Customise ILoginState here] end

// % protected region % [Customise defaultLoginState here] off begin
const defaultLoginState: ILoginState = {
	username: '',
	password: '',
	rememberMe: false,
	errors: {},
};
// % protected region % [Customise defaultLoginState here] end

// % protected region % [Add any extra constants here] off begin
// % protected region % [Add any extra constants here] end

interface LoginPageInternalProps extends RouteComponentProps {
	store: IStore;
	twoFactorMethods: TwoFactorMethods;
	// % protected region % [Add any extra LoginPageInternalProps fields here] off begin
	// % protected region % [Add any extra LoginPageInternalProps fields here] end
}

@observer
// % protected region % [Override class signature here] off begin
class LoginPageInternal extends React.Component<LoginPageInternalProps> {
// % protected region % [Override class signature here] end
	@observable
	private loginState: ILoginState = defaultLoginState;

	// % protected region % [Add any extra fields here] off begin
	// % protected region % [Add any extra fields here] end

	// % protected region % [Override render here] on begin
	public render() {
		let contents = null;
		const { store, location } = this.props;

		if (store.loggedIn) {
			const { redirect, api } = queryString.parse(location.search.substring(1));
			if (api && !Array.isArray(api) && api.match(/^\/api\//)) {
				window.location.href = api;
			}
			if (redirect && !Array.isArray(redirect)) {
				return <Redirect to={redirect} />;
			}
			return <Redirect to="/" />;
		}

		contents = (
			<div className="body-content login-page">
				<div className="login-form">
					<form className="login" onSubmit={this.onLoginClicked}>
						<div className="logo-container">
							<img alt="Access Intell Group Logo" src={AccessLogo} />
						</div>
						<div className="title">
							<h5>Login</h5>
						</div>
						<TextField
							id="login_username"
							className="login-username"
							model={this.loginState}
							modelProperty="username"
							placeholder="Email"
							inputProps={{ autoComplete: 'username', type: 'email' }}
							isRequired
							errors={this.loginState.errors.username}
						/>
						<Password
							id="login_password"
							className="login-password"
							model={this.loginState}
							modelProperty="password"
							placeholder="Password"
							inputProps={{ autoComplete: 'current-password' }}
							isRequired
							errors={this.loginState.errors.password}
						/>
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="login-buttons">
							<Button
								type="submit"
								className="login-submit btn--primary"
								display={Display.Solid}
								disabled={this.loginState.username === '' || this.loginState.password === ''}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'login_submit' }}
							>
								Login
							</Button>
						</ButtonGroup>
						<div className="forgot-container">
							<Button
								display={Display.Text}
								colors={Colors.Primary}
								className="link-forgotten-password link-rm-txt-dec"
								onClick={this.onForgottenPasswordClick}
								buttonProps={{ id: 'btn-forgot_password' }}
							>
								Forgot your password?
							</Button>
						</div>
					</form>
				</div>
				<div className="login-gradient">
					<div className="login-pattern" />
				</div>
			</div>
		);
		return contents;
	}
	// % protected region % [Override render here] end

	// % protected region % [Override onLoginClicked here] on begin
	@action
	private onLoginClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.loginState.errors = {};

		if (!this.loginState.username) {
			this.loginState.errors.username = 'Email Address is required';
		} else if (!isEmail(this.loginState.username)) {
			this.loginState.errors.username = 'This is not a valid email address';
		}
		if (!this.loginState.password) {
			this.loginState.errors.password = 'Password is required';
		}

		if (Object.keys(this.loginState.errors).length <= 0) {
			login(this.loginState.username, this.loginState.password)
				.then(data => {
					if (data.type === '2fa-required') {
						const validMethods = Object.keys(this.props.twoFactorMethods);
						if (validMethods.indexOf(data.method) > -1) {
							const { redirect } = queryString.parse(this.props.location.search.substring(1));
							const params: { [key: string]: string } = {
								rememberMe: this.loginState.rememberMe.toString(),
							};
							if (typeof redirect === 'string') {
								params.redirect = redirect;
							}
							this.props.history.push(buildUrl(`login/2fa/${data.method.toLocaleLowerCase()}`, params));
						} else {
							alertToast('Invalid 2 factor method', 'error');
						}
					} else {
						this.onLoginSuccess(data);
					}
				})
				.catch(response => {
					const errorMessages = getErrorMessages(response).map((error: any) => {
						if (typeof error === 'string') {
							return error.replace(/"/g, ''); // Remove strange quotes that get added
						}

						const message = typeof error.message === 'object'
							? JSON.stringify(error.message)
							: error.message;
						return (message);
					});

					// If there are no error message, or if they're all empty, we add an error message
					if (errorMessages.every(x => !x)) {
						errorMessages.push('Unable to login. Please try again, or contact Access Intell for help.');
					}
					alertToast(errorMessages,
						'error');
				});
		}
	};
	// % protected region % [Override onLoginClicked here] end

	// % protected region % [Override onStartRegisterClicked here] off begin
	@action
	private onStartRegisterClicked: React.MouseEventHandler = () => {
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		this.props.store.routerHistory.push(`/register?${!!redirect ? `redirect=${redirect}` : ''}`);
	};
	// % protected region % [Override onStartRegisterClicked here] end

	// % protected region % [Override login success logic here] on begin
	@action
	private onLoginSuccess = (userResult: IUserResult) => {
		this.props.store.setLoggedInUser(userResult);
		checkTermsAccepted();
		update({
			user_id: this.props.store.userId,
			email: this.props.store.email,
			name: this.props.store.userDisplayName,
		});

		const { redirect, api } = queryString.parse(this.props.location.search.substring(1));

		if (api && !Array.isArray(api) && api.match(/^\/api\//)) {
			window.location.href = api;
		}
		if (redirect && !Array.isArray(redirect)) {
			this.props.store.routerHistory.push(redirect);
		} else {
			this.props.store.routerHistory.push('/');
		}
	};
	// % protected region % [Override login success logic here] end

	// % protected region % [Override onForgottenPasswordClick here] off begin
	@action
	private onForgottenPasswordClick = () => {
		this.props.store.routerHistory.push('/reset-password-request');
	};
	// % protected region % [Override onForgottenPasswordClick here] end

	// % protected region % [Add class methods here] off begin
	// % protected region % [Add class methods here] end
}

// % protected region % [Override LoginPage here] off begin
function LoginPage(props: RouteComponentProps) {
	const twoFactorMethods = useContext(TwoFactorContext);
	const store = useStore();

	return <LoginPageInternal {...props} store={store} twoFactorMethods={twoFactorMethods} />;
}
// % protected region % [Override LoginPage here] end

// % protected region % [Override default export here] off begin
export default LoginPage;
// % protected region % [Override default export here] end

// % protected region % [Add additional exports here] off begin
// % protected region % [Add additional exports here] end
