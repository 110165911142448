import * as React from 'react';
import { Case } from 'Models/Interfaces/AlaresReport';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import AdverseAlertsTable from './AdverseAlertsTable';
import moment from 'moment';

export interface AdverseAlertsCaseDetailsModalProps {
	courtCase: Case,
	onClose: () => void,
}

const AdverseAlertsCaseDetailsModal = (props: AdverseAlertsCaseDetailsModalProps) => {
	const { courtCase, onClose } = props;

	return (
		<Modal
			isOpen
			label={`Action Number: ${courtCase.index}`}
			onRequestClose={onClose}
			className="access-modal adverse-alerts-details-modal"
		>
			<div className="top-row">
				<h4>Action Number: {courtCase.index}</h4>
				<h6>{courtCase.case_name}</h6>
				<h6>Case ID: {courtCase.case_number}</h6>
			</div>

			<div className="content">
				<div className="fields">
					<p className="field"><b>Court:</b> {courtCase.court_name}</p>
					<p className="field"><b>Jurisdiction:</b> {courtCase.jurisdiction}</p>
					<p className="field">
						<b>Next hearing date:</b> {
							courtCase.next_hearing_date
								? moment(courtCase.next_hearing_date).format('Do MMMM YYYY')
								: 'N/A'
						}
					</p>
					<p className="field">
						<b>Notification date:</b> {moment(courtCase.notificationTime).format('Do MMMM YYYY')}
					</p>
					<p className="field"><b>Court suburb:</b> {courtCase.suburb}</p>
					<p className="field"><b>Case type:</b> {courtCase.case_type}</p>
					<p className="field">
						<b>Most recent event:</b> {
							courtCase.most_recent_event
								? moment(courtCase.most_recent_event).format('Do MMMM YYYY')
								: 'N/A'
						}
					</p>
				</div>

				<AdverseAlertsTable
					tableName="Parties"
					collection={courtCase.parties ?? []}
					columns={[
						{
							displayName: 'Name',
							columnName: 'name',
							value: party => party.name,
							sortable: false,
							className: 'field-name',
						},
						{
							displayName: 'Role',
							columnName: 'role',
							value: party => party.role,
							sortable: false,
							className: 'field-role',
						},
						{
							displayName: 'Representative',
							columnName: 'representative',
							value: party => party.representative_name,
							sortable: false,
							className: 'field-representative',
						},
						{
							displayName: 'ABN',
							columnName: 'abn',
							value: party => party.abn,
							sortable: false,
							className: 'field-abn',
						},
						{
							displayName: 'ACN',
							columnName: 'acn',
							value: party => party.acn,
							sortable: false,
							className: 'field-acn',
						},
					]}
					sortColumn="name"
					sortDescending
					idColumn="name"
				/>
				<AdverseAlertsTable
					tableName="Hearings"
					collection={courtCase.hearings.sort((a, b) => {
						return moment(b.datetime).toDate().getTime() - moment(a.datetime).toDate().getTime();
					}) ?? []}
					columns={[
						{
							displayName: 'Date/Time',
							columnName: 'date',
							value: hearing => moment(hearing.datetime).format('Do MMM YYYY'),
							sortable: false,
							className: 'field-date',
						},
						{
							displayName: 'Reason',
							columnName: 'reason',
							value: hearing => hearing.reason,
							sortable: false,
							className: 'field-reason',
						},
						{
							displayName: 'Officer',
							columnName: 'officer',
							value: hearing => hearing.officer,
							sortable: false,
							className: 'field-officer',
						},
						{
							displayName: 'Court room',
							columnName: 'courtRoom',
							value: hearing => hearing.court_room,
							sortable: false,
							className: 'field-court-room',
						},
						{
							displayName: 'Court location',
							columnName: 'courtLocation',
							value: hearing => hearing.court_name,
							sortable: false,
							className: 'field-court-name',
						},
						{
							displayName: 'Court address',
							columnName: 'courtAddress',
							value: hearing => hearing.court_address,
							sortable: false,
							className: 'field-court-address',
						},
						{
							displayName: 'Court suburb',
							columnName: 'courtSuburb',
							value: hearing => hearing.court_suburb,
							sortable: false,
							className: 'field-court-suburb',
						},
						{
							displayName: 'Type',
							columnName: 'type',
							value: hearing => hearing.type,
							sortable: false,
							className: 'field-type',
						},
						{
							displayName: 'List no.',
							columnName: 'listNo',
							value: hearing => hearing.list_no,
							sortable: false,
							className: 'field-list-no',
						},
						{
							displayName: 'Outcome',
							columnName: 'outcome',
							value: hearing => hearing.outcome,
							sortable: false,
							className: 'field-outcome',
							showNA: true,
						},
					]}
					sortColumn="date"
					sortDescending
					idColumn="date"
				/>
			</div>

			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
			</div>
		</Modal>
	);
};

export default AdverseAlertsCaseDetailsModal;
