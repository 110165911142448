import { useValidation } from "Hooks/useValidation";
import { useState, useEffect, useMemo } from "react";
import { TextFieldSetter } from "Views/Components/TextBox/TextFieldSetter";
import { SearchByDetailInfoSubItem } from "./PropertySearchDefaults";
import ComboboxSetter from "Views/Components/Combobox/ComboboxSetter";
import { ComboboxOption } from "Views/Components/Combobox/Combobox";
import If from "Views/Components/If/If";

type SearchByDetailInfoSectionProps = {
	value: string | undefined;
	setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
	label: string;
	isRequired?: boolean;
	placeholder?: string;
	errors?: string[];
	subItems?: SearchByDetailInfoSubItem[];
}

export const SearchByDetailInfoSection: React.FunctionComponent<SearchByDetailInfoSectionProps> = ({
	value, setValue, label, isRequired:isFieldRequired = true, placeholder, errors, subItems
}) => {
	if (!subItems) {
		return (
			<TextFieldSetter
				value={value}
				setValue={setValue}
				label={label}
				placeholder={placeholder}
				isRequired={isFieldRequired}
				errors={errors}
			/>
		);
	}

	return (<SearchByDetailInfoSubSection setValue={setValue} items={subItems} title={label} />);
}

type SearchByDetailInfoSubSectionProps = {
	setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
	title: string;
	items: SearchByDetailInfoSubItem[];
}

const SearchByDetailInfoSubSection: React.FunctionComponent<SearchByDetailInfoSubSectionProps> = ({
	setValue,
	title,
	items,
}) => {
	const initialFieldValues = useMemo(() => {
		const values = items.reduce((acc, item) => {
			acc[item.name] = '';
			return acc;
		}, {} as Record<string, string>);
		// remove the items that are not required as per the current items key
		Object.keys(values).forEach(key => {
			if (!items.find(item => item.name === key)) {
				delete values[key];
			}
		});

		return values;

	}, [items]);

	const [textFields, setTextFields] = useState<Record<string, string>>(initialFieldValues);

	const validationConfig = items.reduce((acc, item) => {
		const fieldName = item.name;
		acc[fieldName] = {
			value: textFields[fieldName],
			isRequired: item.isRequired,
			requiredText: item.requiredText,
		};
		return acc;
	}, {} as Record<string, { value: string; isRequired: boolean; requiredText?: string }>);

	const validation = useValidation(validationConfig, false);

	useEffect(() => {
		if (validation.isValid) {
			setValue(JSON.stringify(textFields));
		} else {
			setValue(undefined);
		}
		// clean the textFields 
	}, [textFields, setValue, validation.isValid]);

	useEffect(() => {
		const hasTextFieldsAsPerItem = Object.keys(textFields).every(key => items.find(item => item.name === key));

		if (!hasTextFieldsAsPerItem) {
			setTextFields(initialFieldValues);
		}

	}, [initialFieldValues, items, textFields]);

	return (
		<div className='group-fields'>
			<div className='group-fields-title'>{title}</div>
			<div className='group-fields-section'>
				{items.map(item => (
					<>
						<If condition={item.type === 'select'}>
							<ComboboxSetter
								key={item.name}
								label={item.label}
								isRequired={item.isRequired}
								placeholder={item.placeholder}
								errors={validation.get(item.name, false)}
								getOptionValue={v => v}
								value={textFields[item.name]}
								setValue={v => setTextFields(prev => ({...prev, [item.name]: v}))}
								options={
									item.selectOptions?.map(option =>
										({ label: option, value: option, display: option } as ComboboxOption<string>)
									) ?? []
								}
							/>
						</If>
						<If condition={item.type === 'text'}>
							<TextFieldSetter
								key={item.name}
								value={textFields[item.name]}
								setValue={v => setTextFields(prev => ({...prev, [item.name]: v}))}
								label={item.label}
								isRequired={item.isRequired}
								placeholder={item.placeholder}
								errors={validation.get(item.name, false)}
							/>
						</If>
					</>
				))}
			</div>
		</div>
	)
}