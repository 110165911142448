import { action } from 'mobx';
import React, { useState } from 'react';
import * as uuid from 'uuid';
import { observer } from 'mobx-react';

export interface ToggleSwitchProps<T> {
	model: T;
	modelProperty: string;
    onAfterChecked?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ToggleSwitch = observer(<T extends unknown>(props: ToggleSwitchProps<T>) => {
	const { model, modelProperty, onAfterChecked } = props;
	const onToggle = action((event: React.ChangeEvent<HTMLInputElement>) => {
		model[modelProperty] = event.target.checked;
		if (!!onAfterChecked) {
			onAfterChecked(event, event.target.checked);
		}
	});

	const [id] = useState(uuid.v4());

	return (
		<label htmlFor={id} className="toggle-container">
			<div className="toggle-switch">
				<input id={id} type="checkbox" checked={model[modelProperty] ?? false} onChange={onToggle} />
				<span className="switch" />
			</div>
			<div className="toggle-label">
				{model[modelProperty] ? 'Yes' : 'No'}
			</div>
		</label>
	);
});
export default ToggleSwitch;
