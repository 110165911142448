import alertToast from 'Util/ToastifyUtils';

async function getLocationCoordinates(): Promise<{ latitude: number; longitude: number } | undefined> {
	return new Promise((resolve, reject) => {
		function success(position: GeolocationPosition) {
			const { latitude, longitude } = position.coords;
			resolve({ latitude, longitude });
		}

		function error(err: GeolocationPositionError) {
			console.warn(`ERROR(${err.code}): ${err.message}`);
			resolve(undefined);
		}

		if (navigator.geolocation) {
			navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
				if (permissionStatus.state !== 'denied') {
					navigator.geolocation.getCurrentPosition(success, error);
				}
			});
		} else {
			alertToast('Geolocation is not supported in your browser.', 'error');
		}
	});
}

export default getLocationCoordinates;
