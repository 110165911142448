import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import queryString from 'querystring';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import Spinner from 'Views/Components/Spinner/Spinner';
import alertToast from 'Util/ToastifyUtils';

export interface IntegrationSetupPageMatch {
	partner: string
}

// This page doesn't actually show any content (other than a spinner). It just completes the user's attempt to connect
// their account with the integration partner. If it succeeeds, then it redirects the user back to where they started
// the integration connections with a success message, otherwise it redirects the user to the login page with an error
// message.
const IntegrationSetupPage = (props: RouteComponentProps<IntegrationSetupPageMatch>) => {
	const { location, match } = props;
	const { partner } = match.params;

	const queryParams = queryString.parse(
		location.search.substring(1),
	);

	// When the component loads, we make a request to the server
	const response = useAsync(async () => axios.post<{redirect: string, errorMessage: string|null}>(
		`/api/AccountingIntegration/${partner}/login-callback`,
		queryParams,
	), []);

	if (response.type === 'loading') {
		return <Spinner />;
	}

	if (response.type === 'error') {
		console.error(response.error);
		alertToast(
			'There was an error while attempting to complete the integration.'
				+ ' Please retry the process, or contact Access Intell to help.',
			'error',
		);

		return (
			<Redirect to="/" />
		);
	}

	const { redirect, errorMessage } = response.data.data;

	if (errorMessage) {
		alertToast(errorMessage, 'error');
	} else {
		alertToast(`${partner} was setup successfully!`, 'success');
	}

	return (
		<Redirect to={redirect || '/'} />
	);
};

export default IntegrationSetupPage;
