import { observer } from 'mobx-react';
import React from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import ProductSummary from './ProductSummary';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import EntityList from 'Views/Components/EntityList/EntityList';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import { FetchRegistrationResponse, PpsrRegistrationData } from 'Views/Components/Intel/Customer/PpsrRegistrationTypes';
import { CustomerEntity } from 'Models/Entities';
import classNames from 'classnames';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import If from 'Views/Components/If/If';
import useStore from 'Hooks/useStore';

export interface PpsrRegistrationsTabProps {
	customer: CustomerEntity,
}

const PpsrRegistrations = observer((props: PpsrRegistrationsTabProps) => {
	const { customer } = props;
	const [page, setPage] = React.useState(0);
	const store = useStore();

	const fetchPpsrRegistrations = AwesomeDebouncePromise(async () => {
		// if we can't access ppsr don't bother fetching anything
		if (store.getUser?.organisation?.ppsrEnabled !== true) {
			return {} as FetchRegistrationResponse;
		}
		try {
			const { data } = await axios.get(
				`${SERVER_URL}/api/entity/CustomerEntity/registrations/${customer.id}/page/${page + 1}`,
			);
			return data as FetchRegistrationResponse;
		} catch (e) {
			return {} as FetchRegistrationResponse;
		}
	}, 300, {
		leading: true,
	});

	const renderCantAccessPpsr = () => {
		if (store.userType === 'ORGANISATION_MANAGER') {
			return (
				<div className="cant-access-ppsr">
					<div className="content">
						<h3>Looking for PPSR registrations?</h3>
						<p>
							If you are looking to add PPSR registrations, please visit the product page to set up
							Access PPSR.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								store.routerHistory.push('/hub/organisation/products');
							}}
							className="ppsr-setup"
						>
							PPSR Product Setup
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="cant-access-ppsr">
				<div className="content">
					<h3>Looking for PPSR registrations?</h3>
					<p>
						If you are looking to add PPSR registrations, please contact your Organisation Manager.
					</p>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.ppsrMarketingUrl, '_blank');
						}}
						className="ppsr-setup"
					>
						Learn More About Access PPSR
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="ppsr-registrations">
			{store.getUser?.organisation?.ppsrEnabled === true
				/* Component to fetch and render the list */
				? (
					<RequestWrap
						request={() => fetchPpsrRegistrations()}
						refreshKey={GetRefreshKey(
							page,
						)}
					>
						{(registrations: FetchRegistrationResponse) => (
							<>
								<div className="summary-section">
									<ProductSummary
										type="threeMonths"
										count={registrations.summary?.expireNextThreeMonths ?? 0}
										product="ppsr"
									/>
									<ProductSummary
										type="sixMonths"
										count={(registrations.summary?.expireNextSixMonths ?? 0)
											- (registrations.summary?.expireNextThreeMonths ?? 0)}
										product="ppsr"
									/>
									<ProductSummary
										type="year"
										count={(registrations.summary?.expireNextYear ?? 0)
											- (registrations.summary?.expireNextSixMonths ?? 0)}
										product="ppsr"
									/>
									<ProductSummary
										type="current"
										count={registrations.summary?.current ?? 0}
										product="ppsr"
									/>
								</div>
								<div className="collection-component">
									<EntityList
										collection={registrations.registrations?.data ?? []}
										columns={[
											{
												displayName: 'PPSR no.',
												columnName: 'ppsrRegistrationNumber',
												value: (registration: PpsrRegistrationData) => (
													<>
														<If condition={registration.match !== 'match found'}>
															<Tooltip
																id={`match-tooltip-${registration.id}`}
																content="Possible match"
																place="top"
															/>
														</If>
														<If condition={store.canAccessPPSR}>
															<Link
																className={classNames({
																	possibleMatch: registration.match !== 'match found',
																})}
																to={`/monitor/customers/${customer.id}/registration/${
																	registration.id}`}
															>
																{registration.ppsrRegistrationNumber}
															</Link>
														</If>
														<If condition={!store.canAccessPPSR}>
															{registration.ppsrRegistrationNumber}
														</If>
													</>
												),
												sortable: false,
												className: 'field-ppsr-no',
											},
											{
												displayName: 'GONI',
												columnName: 'goni',
												value: (registration: PpsrRegistrationData) => registration.goni,
												sortable: false,
												className: 'field-goni',
											},
											{
												displayName: 'SPG Name',
												columnName: 'securedPartyGroup.name',
												value: (registration: PpsrRegistrationData) => registration
													.securedPartyGroup.name,
												sortable: false,
												className: 'field-spg-name',
											},
											{
												displayName: 'Collateral class',
												columnName: 'collateralClass',
												value: (registration: PpsrRegistrationData) => registration
													.collateralClass,
												sortable: false,
												className: 'field-collateral-class',
											},
											{
												displayName: 'End date',
												columnName: 'ppsrRegistrationEndTime',
												value: (registration: PpsrRegistrationData) => moment(
													registration.ppsrRegistrationEndTime,
												).format('DD/MM/YYYY'),
												sortable: false,
												className: 'field-end-date',
											},
											{
												displayName: 'Status',
												columnName: 'status',
												value: (registration: PpsrRegistrationData) => (
													<span className={classNames({
														valid: registration.status === 'Registered',
													})}
													>
														{registration.status}
													</span>
												),
												sortable: false,
												className: 'field-status',
											},
											{
												displayName: '',
												columnName: '',
												value: (registration: PpsrRegistrationData) => (
													// eslint-disable-next-line max-len
													<a href={`${store.appSettings.ppsrBaseUrl}/registration/home.php?rt=All&query=${registration.ppsrRegistrationNumber}`}>
														PPSR
													</a>
												),
												sortable: false,
												className: 'field-access-ppsr',
											},
										].filter(x => x.className !== 'field-access-ppsr' || store.canAccessPPSR)}
										idColumn="id"
										sortColumn=""
										sortDescending={false}
									/>
									<section className="collection__load">
										<TablePagination
											perPage={REGISTRATIONS_PAGE_LENGTH}
											pageNo={page}
											totalRecords={registrations.registrations?.total ?? 0}
											onPageChange={setPage}
										/>
									</section>
								</div>
							</>
						)}
					</RequestWrap>
				)
				: renderCantAccessPpsr()}
		</div>
	);
});

export default PpsrRegistrations;

export const REGISTRATIONS_PAGE_LENGTH = 10;
