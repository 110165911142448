import React from 'react';
import BusinessEntity from '../../../../Models/Entities/BusinessEntity';
import Tabs, { ITabConfig } from '../../Tabs/Tabs';
import BusinessEntityESignUser from './BusinessEntityESignUser';
import BusinessEntityTemplateList from './BusinessEntityTemplateList';
import { canEditBusinessEntity } from '../BusinessEntityDetails';

type Props = {
	businessEntity: BusinessEntity;
	reload: () => void;
}

const BusinessEntityESignTabs: React.FunctionComponent<Props> = ({ businessEntity, reload }) => {
	const canEdit = canEditBusinessEntity(businessEntity);

	const renderTabs = () => {
		const sections:ITabConfig[] = [{
			className: 'business-entity-template-details',
			name: 'Templates',
			component: (<BusinessEntityTemplateList
				businessEntity={businessEntity}
				reload={reload}
			/>),
			key: 'esign-templates',
		}];

		// only show the users tab if the user has permission to edit the business entity
		if (canEdit) {
			sections.push({
				className: 'business-entity-esign-users',
				name: 'Users',
				component: (<BusinessEntityESignUser businessEntityId={businessEntity.id} />),
				key: 'esign-users',
			});
		}

		return sections;
	};

	return (
		<Tabs tabs={renderTabs()} />
	);
};

export default BusinessEntityESignTabs;
