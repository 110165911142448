import React, { useState } from 'react';
import {
	Grid,
	GridColumn,
	GridRow,
	Icon,
	Modal, ModalActions, ModalContent, ModalHeader,
	Segment,
} from 'semantic-ui-react';
import InputSignature from './InputSignature';
import { observer } from 'mobx-react';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';

type Props = {
	signatureDetail: {
		showModal: boolean;
		signatureFieldId: string;
		signature: string;
	};
	updateSignature: (signature: string, signatureId: string) => void;
	isWitness: boolean;
	signeeName: string;
	closeModal: () => void;
}

const SignatureModal: React.FunctionComponent<Props> = observer(({
	signatureDetail, updateSignature, isWitness, signeeName, closeModal,
}) => {
	const [signatureImage, setSignatureImage] = useState<string>(signatureDetail.signature);
	const [hasWitnessConfirmed, sethasWitnessConfirmed] = useState<{checked: boolean}>({ checked: !isWitness });

	return (
		<Modal
			className="portal frontend esign-signature-modal"
			onClose={closeModal}
			open
			size="tiny"
		>
			<ModalHeader>
				<Icon name="pencil" />
				Draw Signature
			</ModalHeader>
			<ModalContent>
				<InputSignature
					signature={signatureImage}
					updateSignature={value => setSignatureImage(value)}
				/>
				{isWitness && (
					<Checkbox
						className="esign-witness-checkbox"
						model={hasWitnessConfirmed}
						modelProperty="checked"
						onChecked={(_, checked) => sethasWitnessConfirmed({ ...hasWitnessConfirmed, checked })}
						label={`I hereby declare that I have witnessed the signing of this document by ${signeeName}`}
					/>
				)}
			</ModalContent>
			<ModalActions>
				<Grid>
					<GridRow>
						<GridColumn width={8} textAlign="left">
							<Button
								display={Display.Text}
								sizes={Sizes.Medium}
								onClick={closeModal}
								colors={Colors.Error}
							>
								Close
							</Button>
						</GridColumn>
						<GridColumn width={8} textAlign="right">
							<Button
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={Colors.Primary}
								onClick={() => {
									updateSignature(signatureImage, signatureDetail.signatureFieldId);
									closeModal();
								}}
								disabled={signatureImage.length === 0 || (isWitness && !hasWitnessConfirmed.checked)}
							>
								Confirm
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</ModalActions>
		</Modal>
	);
});

export default SignatureModal;
