import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import { store } from 'Models/Store';
import { BusinessEntity, SpgEntity } from 'Models/Entities';
import { Button, Display, Colors } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';

export type EditMonitorDetailsModalProps = {
	businessEntity: BusinessEntity,
	onClose: () => void,
	onSubmit: () => Promise<void>,
	readonly ?: boolean,
	addNew?: boolean,
	onAfterChange?: (validateSuccessful: boolean) => void,
}

const EditMonitorDetailsModal = observer((props: EditMonitorDetailsModalProps) => {
	const {
		businessEntity, onClose, onSubmit, onAfterChange, readonly, addNew,
	} = props;
	const disabled = readonly || !(store.userPermissions.commonManageOrganisations !== 'NONE');

	// Create a duplicate of the business entity, so that we can edit it while still being able to cancel and not save anything
	// This component assumes that nothing else will edit the entity in the background. (Since this should be covering everything)
	const privateBusinessEntity = useLocalStore(() => {
		const entity = new BusinessEntity(businessEntity);
		entity.enabledForMonitor = true;
		return entity;
	});

	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!privateBusinessEntity.errors[field]) {
			await privateBusinessEntity.validateField(field);
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(privateBusinessEntity.errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};

	const submit = async () => {
		await privateBusinessEntity.validateMonitorFields();
		if (Object.keys(privateBusinessEntity.errors).length === 0) {
			// Save all updated fields back to original business entity
			runInAction(() => {
				businessEntity.enabledForMonitor = true;
				businessEntity.monitorNotificationsEmail = privateBusinessEntity.monitorNotificationsEmail;
				businessEntity.enableEmailNotifications = privateBusinessEntity.enableEmailNotifications;
			});
			await onSubmit();
		}
	};
	return (
		<Modal
			isOpen
			label="Edit Monitor details"
			onRequestClose={onClose}
			className="access-modal"
		>
			{ addNew ? <h4>Monitor details</h4> : <h4>Edit Monitor details</h4> }
			<Checkbox
				className="input-group__checkbox2 input-padding__checkbox"
				model={privateBusinessEntity}
				modelProperty="enableEmailNotifications"
				label="Enable email notification"
				isDisabled={disabled}
			/>
			<TextField
				className="email"
				model={privateBusinessEntity}
				modelProperty="monitorNotificationsEmail"
				label="Notifications email"
				placeholder="Enter Email"
				onChangeAndBlur={() => onChange('monitorNotificationsEmail', true)}
				onAfterChange={() => onChange('monitorNotificationsEmail')}
				isDisabled={disabled}
				errors={privateBusinessEntity.errors.monitorNotificationsEmail}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					className="save"
					colors={Colors.Primary}
					display={Display.Solid}
					onPress={submit}
				>
					Save Changes
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});
export default EditMonitorDetailsModal;
