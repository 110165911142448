/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAtoRecord from 'Models/Security/Acl/VisitorsAtoRecord';
import UserAtoRecord from 'Models/Security/Acl/UserAtoRecord';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAtoRecordEntityAttributes extends IModelAttributes {
	abn: string;
	acn: string;
	legalName: string;
	businessName: string;
	debtAmount: number;
	recordStatus: Enums.atoRecordStatus;
	recordInfoText: string;
	entityType: Enums.atoEntityType;
	recordAdded: Date;
	recordUpdated: Date;
	atorecorderrors: string;

	atoFileId: string;
	atoFile: Models.AtoFileEntity | Models.IAtoFileEntityAttributes;
	monitoredId: string;
	monitored: Models.MonitoredEntity | Models.IMonitoredEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AtoRecordEntity', 'ATO Record')
// % protected region % [Customise your entity metadata here] end
export default class AtoRecordEntity extends Model
	implements IAtoRecordEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAtoRecord(),
		new UserAtoRecord(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'ABN'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'ABN',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'ACN',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legal Name'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'Legal Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public legalName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Legal Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'Business Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public businessName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Debt Amount'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtoRecordEntity, number>()
	@CRUD({
		name: 'Debt Amount',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public debtAmount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Debt Amount'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Status'] off begin
	@observable
	@attribute<AtoRecordEntity, Enums.atoRecordStatus>()
	@CRUD({
		name: 'Record Status',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.atoRecordStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.atoRecordStatusOptions),
		displayFunction: (attr: Enums.atoRecordStatus) => Enums.atoRecordStatusOptions[attr],
	})
	public recordStatus: Enums.atoRecordStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Record Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Info Text'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'Record Info Text',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public recordInfoText: string;
	// % protected region % [Modify props to the crud options here for attribute 'Record Info Text'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Type'] off begin
	@observable
	@attribute<AtoRecordEntity, Enums.atoEntityType>()
	@CRUD({
		name: 'Entity Type',
		displayType: 'enum-combobox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.atoEntityTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.atoEntityTypeOptions),
		displayFunction: (attr: Enums.atoEntityType) => Enums.atoEntityTypeOptions[attr],
	})
	public entityType: Enums.atoEntityType;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Added'] off begin
	@observable
	@attribute<AtoRecordEntity, Date>()
	@CRUD({
		name: 'Record Added',
		displayType: 'datetimepicker',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public recordAdded: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Record Added'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Updated'] off begin
	@observable
	@attribute<AtoRecordEntity, Date>()
	@CRUD({
		name: 'Record Updated',
		displayType: 'datetimepicker',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public recordUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Record Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'ATORecordErrors'] off begin
	@observable
	@attribute<AtoRecordEntity, string>()
	@CRUD({
		name: 'ATORecordErrors',
		displayType: 'textarea',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public atorecorderrors: string;
	// % protected region % [Modify props to the crud options here for attribute 'ATORecordErrors'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATO File'] off begin
		name: 'ATO File',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.AtoFileEntity,
		// % protected region % [Modify props to the crud options here for reference 'ATO File'] end
	})
	public atoFileId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public atoFile: Models.AtoFileEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Monitored'] off begin
		name: 'Monitored',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.MonitoredEntity,
		// % protected region % [Modify props to the crud options here for reference 'Monitored'] end
	})
	public monitoredId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public monitored: Models.MonitoredEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAtoRecordEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAtoRecordEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.acn !== undefined) {
				this.acn = attributes.acn;
			}
			if (attributes.legalName !== undefined) {
				this.legalName = attributes.legalName;
			}
			if (attributes.businessName !== undefined) {
				this.businessName = attributes.businessName;
			}
			if (attributes.debtAmount !== undefined) {
				this.debtAmount = attributes.debtAmount;
			}
			if (attributes.recordStatus !== undefined) {
				this.recordStatus = attributes.recordStatus;
			}
			if (attributes.recordInfoText !== undefined) {
				this.recordInfoText = attributes.recordInfoText;
			}
			if (attributes.entityType !== undefined) {
				this.entityType = attributes.entityType;
			}
			if (attributes.recordAdded !== undefined) {
				if (attributes.recordAdded === null) {
					this.recordAdded = attributes.recordAdded;
				} else {
					this.recordAdded = moment(attributes.recordAdded).toDate();
				}
			}
			if (attributes.recordUpdated !== undefined) {
				if (attributes.recordUpdated === null) {
					this.recordUpdated = attributes.recordUpdated;
				} else {
					this.recordUpdated = moment(attributes.recordUpdated).toDate();
				}
			}
			if (attributes.atorecorderrors !== undefined) {
				this.atorecorderrors = attributes.atorecorderrors;
			}
			if (attributes.atoFileId !== undefined) {
				this.atoFileId = attributes.atoFileId;
			}
			if (attributes.atoFile !== undefined) {
				if (attributes.atoFile === null) {
					this.atoFile = attributes.atoFile;
				} else if (attributes.atoFile instanceof Models.AtoFileEntity) {
					this.atoFile = attributes.atoFile;
					this.atoFileId = attributes.atoFile.id;
				} else {
					this.atoFile = new Models.AtoFileEntity(attributes.atoFile);
					this.atoFileId = this.atoFile.id;
				}
			}
			if (attributes.monitoredId !== undefined) {
				this.monitoredId = attributes.monitoredId;
			}
			if (attributes.monitored !== undefined) {
				if (attributes.monitored === null) {
					this.monitored = attributes.monitored;
				} else if (attributes.monitored instanceof Models.MonitoredEntity) {
					this.monitored = attributes.monitored;
					this.monitoredId = attributes.monitored.id;
				} else {
					this.monitored = new Models.MonitoredEntity(attributes.monitored);
					this.monitoredId = this.monitored.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		atoFile {
			${Models.AtoFileEntity.getAttributes().join('\n')}
		}
		monitored {
			${Models.MonitoredEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AtoRecordEntity.prototype, 'created');
CRUD(modifiedAttr)(AtoRecordEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
