import * as React from 'react';
import useAsync from '../../../Hooks/useAsync';
import { AtbRecordEntity } from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import gql from 'graphql-tag';
import Modal from '../Modal/Modal';
import { TextField } from '../TextBox/TextBox';
import { Button, Display } from '../Button/Button';
import { action } from 'mobx';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { verifyAbn } from 'Util/StringUtils';
import { SERVER_URL } from 'Constants';
import axios from 'axios';

export type AddNewCustomerModalProps = {
	atbRecordId: string,
	businessEntityId: string,
	onSubmit: () => void,
	onClose: () => void,
}

const AddNewCustomerModal = ((props: AddNewCustomerModalProps) => {
	const {
		atbRecordId,
		businessEntityId,
		onSubmit,
		onClose,
	} = props;

	const [inputErrorAbn, setInputErrorAbn] = useState<string|undefined>();
	const [inputErrorDebtorId, setInputErrorDebtorId] = useState<string|undefined>();

	const response = useAsync(async (): Promise<AtbRecordEntity> => {
		const results = await store.apolloClient.query({
			query: gql`
				query {
					atbRecordEntity (id: "${atbRecordId}") {
						${AtbRecordEntity.getAllAttributes().join('\n')}
					}
				}
			`,
			fetchPolicy: 'no-cache',
		});

		return new AtbRecordEntity(results.data.atbRecordEntity);
	}, [atbRecordId]);

	const validateAbn = React.useMemo(() => {
		return debounce(async (abn?: string) => {
			if (!abn) {
				setInputErrorAbn('ABN is required');
				return;
			}

			if (abn.replace(/\D/g, '').length < 11) {
				setInputErrorAbn('The ABN must contain 11 digits');
				return;
			}

			const abnResponse = await verifyAbn(abn.toString());

			if (abnResponse?.exception?.exceptionDescription) {
				setInputErrorAbn(abnResponse.exception.exceptionDescription);
				return;
			}

			const status = abnResponse.businessEntity.entityStatus.entityStatusCode;
			if (status !== 'Active') {
				setInputErrorAbn('The ACN must be currently active');
				return;
			}

			setInputErrorAbn('');
		}, 300);
	}, []);

	const validateDebtorId = React.useMemo(() => {
		return debounce(async (debtorId?: string) => {
			if (!debtorId) {
				setInputErrorDebtorId('Debtor Id is required');
				return;
			}

			const { data } = await axios.get(
				`${SERVER_URL}/api/entity/CustomerEntity/validate-debtor-id/${businessEntityId}/${debtorId}`,
			);

			setInputErrorDebtorId(
				data !== true
					? 'Debtor Id must be unique'
					: '',
			);
		}, 300);
	}, [businessEntityId]);

	const handleCustomerDebtorIdChange = React.useMemo(() => {
		return action((event: React.ChangeEvent<HTMLInputElement>) => {
			if (!response.data) {
				return;
			}

			response.data.originalDataConverted.DebtorId = event.target.value;
			validateDebtorId(event.target.value);
		});
	}, [response.data, validateDebtorId]);

	const handleCustomerAbnChange = React.useMemo(() => {
		return action((event: React.ChangeEvent<HTMLInputElement>) => {
			if (!response.data) {
				return;
			}

			response.data.originalDataConverted.CustomerAbn = event.target.value;
			validateAbn(event.target.value);
		});
	}, [response.data, validateAbn]);

	const onConfirm = React.useCallback(async () => {
		if (inputErrorAbn !== '') {
			return;
		}
		if (!!response.data) {
			response.data.originalData = JSON.stringify(response.data.originalDataConverted);
			await response.data.save();
		}

		onSubmit();
	}, [inputErrorAbn, response.data, onSubmit]);

	useEffect(() => {
		if (!response.data) {
			return;
		}

		validateAbn(response.data.originalDataConverted.CustomerAbn);
		validateDebtorId(response.data.originalDataConverted.DebtorId);
	}, [response.data, validateAbn, validateDebtorId]);

	if (response.type === 'loading' || response.data === undefined) {
		return null;
	}

	return (
		<Modal
			isOpen
			label="Add New Customer"
			onRequestClose={onClose}
			className="access-modal add-new-customer-modal"
		>
			<h6>Add New Customer</h6>
			<TextField
				label="Debtor Id"
				model={response.data.originalDataConverted}
				modelProperty="DebtorId"
				errors={inputErrorDebtorId}
				onChangeAndBlur={handleCustomerDebtorIdChange}
				onAfterChange={handleCustomerDebtorIdChange}
				isRequired
			/>
			<TextField
				label="Customer Name"
				model={response.data.originalDataConverted}
				modelProperty="CustomerName"
			/>
			<IconTextBox
				model={response.data.originalDataConverted}
				modelProperty="CustomerAbn"
				label="ABN"
				labelVisible
				onChangeAndBlur={handleCustomerAbnChange}
				onAfterChange={handleCustomerAbnChange}
				errors={inputErrorAbn}
				isRequired
				displayIconContainer
				iconDisplay={inputErrorAbn === '' ? IconDisplay.Valid : IconDisplay.Warning}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button
					key="confirm"
					onClick={onConfirm}
					disabled={inputErrorAbn !== '' || inputErrorDebtorId !== ''}
					display={Display.Solid}
				>
					Submit
				</Button>
			</div>
		</Modal>
	);
});

export default AddNewCustomerModal;
