/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserProviderProductOrganisation from 'Models/Security/Acl/UserProviderProductOrganisation';
import VisitorsProviderProductOrganisation from 'Models/Security/Acl/VisitorsProviderProductOrganisation';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProviderProductOrganisationEntityAttributes extends IModelAttributes {
	price: number;

	organisationId: string;
	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	providerProductId: string;
	providerProduct: Models.ProviderProductEntity | Models.IProviderProductEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProviderProductOrganisationEntity', 'Provider Product Organisation')
// % protected region % [Customise your entity metadata here] end
export default class ProviderProductOrganisationEntity extends Model
	implements IProviderProductOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserProviderProductOrganisation(),
		new VisitorsProviderProductOrganisation(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<ProviderProductOrganisationEntity, number>()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] off begin
		name: 'Provider Product',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.ProviderProductEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider Product'] end
	})
	public providerProductId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public providerProduct: Models.ProviderProductEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProviderProductOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProviderProductOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.providerProductId !== undefined) {
				this.providerProductId = attributes.providerProductId;
			}
			if (attributes.providerProduct !== undefined) {
				if (attributes.providerProduct === null) {
					this.providerProduct = attributes.providerProduct;
				} else if (attributes.providerProduct instanceof Models.ProviderProductEntity) {
					this.providerProduct = attributes.providerProduct;
					this.providerProductId = attributes.providerProduct.id;
				} else {
					this.providerProduct = new Models.ProviderProductEntity(attributes.providerProduct);
					this.providerProductId = this.providerProduct.id;
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
		providerProduct {
			${Models.ProviderProductEntity.getAttributes().join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			reportRequests: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProviderProductOrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(ProviderProductOrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
