import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import { observer } from 'mobx-react';
import ReferralList from '../Components/ReferralPartners/ReferralList';
import RegistrationTotal from '../Components/ReferralPartners/RegistrationTotal';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface ReferralPartnerReferralListPageProps {}

const ReferralPartnerReferralListPage = observer((props: RouteComponentProps<ReferralPartnerReferralListPageProps>) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content list-page referral-partner-referrals-page">
				<div className="top-container">
					<PageBreadcrumbs
						tags={
							[
								{ label: 'Referrals' },
							]
						}
					/>
					<RegistrationTotal
						entityName="referrals"
						statusField="status"
					/>
				</div>
				<ReferralList />
			</div>
		</AccessIntelSecuredPage>
	);
});
export default ReferralPartnerReferralListPage;
