import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import AgedTrialBalanceSteps from 'Views/Components/AgedTrialBalance/AgedTrialBalanceSteps';
import AgedTrialBalanceDetail from 'Views/Components/AgedTrialBalance/AgedTrialBalanceDetail';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import {
	AtbFileEntity,
} from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import { atbFileType } from 'Models/Enums';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import If from 'Views/Components/If/If';
import { saveAs } from 'file-saver';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { CalculatePricing, PricingDetail } from 'Util/PricingHelper';

export type UploadATBRouteType = 'agedtrialbalance'|'customers';

interface UploadAgedTrialBalancePageParams {
	type: UploadATBRouteType
	fileId?: string
}

const uploadTypeToATBFileType = (uploadATBRouteType: UploadATBRouteType): atbFileType => {
	switch (uploadATBRouteType) {
		case 'agedtrialbalance':
			return 'STANDARD';
		case 'customers':
			return 'CUSTOMERS_ONLY';
	}

	return 'UNKNOWN';
};

const refetchProcessingFrequencyMilliseconds = 2 * 1000; // 2 seconds

const UploadAgedTrialBalancePage = (props: RouteComponentProps<UploadAgedTrialBalancePageParams>) => {
	const {
		match,
		history,
	} = props;

	const { type, fileId } = match.params;
	const customersOnly = type === 'customers';

	const [fileUploading, setFileUploading] = useState(false);
	const [acceptPriceIncreaseRequired, setAcceptPriceIncreaseRequired] = useState(false);
	const [acceptedPriceIncrease, setAcceptedPriceIncrease] = useState(false);
	const [noUploadErrors, setNoUploadErrors] = useState(false);
	const [currentPricingDetail, setCurrentPricingDetail] = useState<PricingDetail|null>(null);
	const [atbFileEntity, setAtbFileEntity] = useState(new AtbFileEntity({
		userId: store.userId,
		atbFileType: uploadTypeToATBFileType(type),
		targetMonth: new Date(
			new Date().getFullYear(),
			new Date().getMonth() - 1,
			1,
			0,
			0,
			0,
		),
		addNewCustomers: true,
	}));

	useEffect(() => {
		CalculatePricing({
			OrganisationId: store.getUser?.organisation?.id,
		}).then(setCurrentPricingDetail);
	}, []);

	const submitEnabled = React.useMemo(() => {
		return (
			noUploadErrors
			&& (
				!acceptPriceIncreaseRequired
				|| acceptedPriceIncrease
			)
		);
	}, [acceptPriceIncreaseRequired, acceptedPriceIncrease, noUploadErrors]);

	const handleClose = React.useCallback(() => {
		history.push('/monitor');
	}, [history]);

	const handleOnPress = React.useCallback(async () => {
		const fileName = customersOnly ? 'Customer' : 'Aged Trial Balance';
		try {
			await axios.get(
				`${SERVER_URL}/api/entity/AtbFileEntity/submit-report/${atbFileEntity.id}`,
			);
			saveAs(`${SERVER_URL}/api/entity/AtbFileEntity/export/${atbFileEntity.id}`);
			if (atbFileEntity.countAtbRecordWarnings > 0) {
				alertToast(`There were warnings with your uploaded ${fileName} file. `
					+ 'Please see the downloaded warnings report', 'warning');
				saveAs(
					`${SERVER_URL}/api/entity/AtbFileEntity/${atbFileEntity.id}/warning-report`,
				);
			}
			history.push('/monitor');
		} catch (e: any) {
			if (e?.response?.status === 400 && e?.response?.data) {
				alertToast(e?.response?.data, 'error');
				return;
			}
			alertToast('Error submitting report. Please try again.', 'error');
			console.error(e);
		}
	}, [customersOnly, atbFileEntity.id, atbFileEntity.countAtbRecordWarnings, history]);

	const response = useAsync(async () => {
		if (!fileId) {
			return;
		}

		const results = await store.apolloClient.query({
			query: gql`
				query fetchAtbFileEntityById($atbFileEntityId: ID, $atbFileEntityIdString: String) {
					atbFileEntity (id: $atbFileEntityId) {
						${AtbFileEntity.getAllAttributes()}
					}
					countAtbRecordEntityProcessingErrors (where: [
						{path: "atbFileId", comparison: equal, value: [$atbFileEntityIdString]},
						{path: "processingErrors", comparison: equal, value: null, negate: true}
					]) {
						number
					}
					countAtbRecordEntityProcessingWarnings (where: [
						{path: "atbFileId", comparison: equal, value: [$atbFileEntityIdString]},
						{path: "processingWarnings", comparison: equal, value: null, negate: true}
					]) {
						number
					}
					processed:countAtbRecordEntitys (where: [{path: "atbFileId", comparison: equal,
						value: [$atbFileEntityIdString]}, {path: "errorsProcessed", comparison: equal, value: "true"}])
					{
						number
					}
					total:countAtbRecordEntitys (where: [{path: "atbFileId", comparison: equal,
							value: [$atbFileEntityIdString]}]) {
						number
					}
				}`,
			variables: {
				atbFileEntityId: fileId,
				atbFileEntityIdString: fileId,
			},
			fetchPolicy: 'no-cache',
		});

		if (!results.data.atbFileEntity) {
			throw new Error('Unable to find matching business entity');
		}

		const _atbFileEntity = new AtbFileEntity(results.data.atbFileEntity);
		_atbFileEntity.countAtbRecordErrors = results.data.countAtbRecordEntityProcessingErrors.number;
		_atbFileEntity.countAtbRecordWarnings = results.data.countAtbRecordEntityProcessingWarnings.number;
		_atbFileEntity.totalAtbRecords = results.data?.total?.number ?? 0;
		_atbFileEntity.processedAtbRecords = results.data?.processed?.number ?? 0;
		setAtbFileEntity(_atbFileEntity);

		// Use the local scope object just incase the state scope model isn't updated
		if (['QUEUED', 'PROCESSING'].includes(_atbFileEntity.atbJobStatus)) {
			setTimeout(() => {
				response.refresh();
			}, refetchProcessingFrequencyMilliseconds);
		}
	}, [fileId]);

	if (response.type === 'data' && fileId && !atbFileEntity.id) {
		const fileName = customersOnly ? 'Customer' : 'Aged Trial Balance';
		alertToast(`Unknown ${fileName} file.`, 'error');
		history.push(`/monitor/upload/${type}`);
		return (<Spinner />);
	}
	if (response.type === 'error') {
		alertToast('An error occured. Please refresh the page.', 'error');
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor" hideNavigation>
			<div className="body-content">
				<div className="agedtrialbalance-page">
					<div className="panels">
						<div className="steps">
							<div className="header">
								<h3>Upload your {customersOnly ? 'Customers' : 'ATB Data'}</h3>
								<If condition={!customersOnly}>
									<Tooltip
										id="update-atb-data-reminder"
										content={'Upload your Customer Aged Trial Balance before the 10th of each '
											+ 'month to maintain accurate ongoing risk scores'}
									/>
								</If>
								<If condition={customersOnly}>
									<Tooltip
										id="update-customer-data-reminder"
										content="Upload your customers"
									/>
								</If>
							</div>
							<AgedTrialBalanceSteps
								atbFileEntity={atbFileEntity}
								currentPricingDetail={currentPricingDetail}
								onBeginUpload={() => {
									setFileUploading(true);
								}}
								onCompleteUpload={_atbFileEntity => {
									setAtbFileEntity(_atbFileEntity);
									setFileUploading(false);

									if (!fileId) {
										history.push(`/monitor/upload/${type}/${_atbFileEntity.id}`);
									} else {
										setTimeout(
											() => response.refresh(),
											refetchProcessingFrequencyMilliseconds,
										);
									}
								}}
							/>
						</div>
						<div className="detail">
							<If condition={fileUploading || !!atbFileEntity?.id}>
								<AgedTrialBalanceDetail
									fileUploading={fileUploading}
									atbFileEntity={atbFileEntity}
									currentPricingDetail={currentPricingDetail}
									onPriceIncreaseAcceptRequired={
										required => setAcceptPriceIncreaseRequired(old => {
											if (required !== old) {
												setAcceptedPriceIncrease(false);
											}

											return required;
										})
									}
									onNoErrors={() => setNoUploadErrors(true)}
									onClose={() => handleClose()}
								/>
							</If>
						</div>
					</div>
					<div className="button-container">
						<If condition={acceptPriceIncreaseRequired}>
							<Checkbox
								label={'I acknowledge that my monthly invoice will'
									+ ' increase as a result of adding more customers'}
								key="acceptedPriceIncrease"
								model={{ value: acceptedPriceIncrease }}
								modelProperty="value"
								onChecked={(_, checked) => setAcceptedPriceIncrease(checked)}
							/>
						</If>
						<Button
							colors={Colors.Primary}
							display={Display.Outline}
							onClick={() => handleClose()}
						>
							Cancel
						</Button>
						<ButtonAsyncState
							colors={Colors.Primary}
							display={Display.Solid}
							readonly={!submitEnabled}
							onPress={handleOnPress}
							waitingText="Submitting..."
						>
							Submit Data
						</ButtonAsyncState>
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default UploadAgedTrialBalancePage;
