import * as React from 'react';
import { useState } from 'react';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { store } from 'Models/Store';
import HandleEvents from 'Util/HandleEvents';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';

export interface ProductSwitcherProps {
	productClass: 'hub'|'monitor',
}

const ProductSwitcher = (props: ProductSwitcherProps) => {
	const { productClass } = props;
	const [expanded, setExpanded] = useState(false);

	const approveLoginUrl = `${store.appSettings.approveBaseUrl}${store.appSettings.approveLoginPath}`;
	const ppsrLoginUrl = `${store.appSettings.ppsrBaseUrl}${store.appSettings.ppsrLoginPath}`;

	return (
		<>
			<div
				className={classNames('nav__home', { expanded: expanded })}
			>
				<span
					className={classNames('nav-home-toggle icon-apps icon-right', { expanded: expanded })}
					role="button"
					aria-label="Product switcher toggle"
					tabIndex={0}
					{...HandleEvents(() => setExpanded(!expanded))}
				>
					<div
						className={classNames('nav-logo', productClass, { expanded: expanded })}
						role="img"
						aria-label="Product Switcher"
					/>
				</span>
				<If condition={expanded}>
					<div className="product-switcher-overlay">
						<If condition={store.canAccessIntel || store.canAccessApprove || store.canAccessPPSR}>
							<p className="product-switcher-description">Switch to</p>
						</If>
						<If condition={store.canAccessApprove}>
							<a
								className="product-wrap"
								href={approveLoginUrl}
								aria-label="Approve"
								target="_blank"
								rel="noreferrer"
							>
								<div className="product-logo light-blue" role="img" aria-label="Approve logo">
									<div className="nav-logo approve" />
								</div>
								<span>Approve (Applications)</span>
							</a>
						</If>
						<If condition={store.canAccessPPSR}>
							<a
								className="product-wrap"
								href={ppsrLoginUrl}
								aria-label="PPSR"
								target="_blank"
								rel="noreferrer"
							>
								<div className="product-logo blue" role="img" aria-label="PPSR logo">
									<div className="nav-logo ppsr" />
								</div>
								<span>PPSR (Securities)</span>
							</a>
						</If>
						<If condition={store.canAccessIntel}>
							<a
								className="product-wrap"
								href={
									store.appSettings.monitorEnabled
										? '/monitor/dashboard'
										: store.appSettings.monitorMarketingUrl
								}
								aria-label="Monitor"
								target="_blank"
								rel="noreferrer"
							>
								<div className="product-logo purple" role="img" aria-label="Monitor logo">
									<div className="nav-logo intel" />
								</div>
								<span>Monitor (Tracking)</span>
							</a>
						</If>
						<Link
							to="/hub/products"
							className="access-hub-link icon-home-2 icon-left"
							aria-label="AccessHub"
							onClick={() => {
								// only call the me endpoint if we are changing from the monitor product, this is to
								// ensure we aren't impersonating another organisation in the Hub
								if (window.location.pathname.includes('monitor')) {
									axios.get(`${SERVER_URL}/api/account/me`)
										.then(({ data }) => store.setLoggedInUser(data))
										.catch();
								}
							}}
						>
							AccessHub
						</Link>
					</div>
				</If>
			</div>
		</>
	);
};

export default ProductSwitcher;
