import * as React from 'react';
import { CustomerEntity } from 'Models/Entities';
import { LocaleFormatCurrency } from 'Util/StringUtils';

const CustomerOverviewDebtSummary = (props: {
	customer: CustomerEntity,
}) => {
	const { customer } = props;
	return (
		<div className="total-amounts">
			<div className="total total-outstanding">
				<h6 className="title">Total Outstanding</h6>
				<p>
					{customer.newestAtbRecord?.id
						? LocaleFormatCurrency(customer.newestAtbRecord.totalDebt ?? 0)
						: 'N/A'}
				</p>
			</div>
			<div className="total total-overdue">
				<h6 className="title">Total Overdue</h6>
				<p>
					{customer.newestAtbRecord?.id
						? LocaleFormatCurrency(customer.newestAtbRecord?.totalOverdue ?? 0)
						: 'N/A'}
				</p>
			</div>
		</div>
	);
};

export default CustomerOverviewDebtSummary;
