import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import UploadTerms from 'Views/Components/Settings/UploadTerms';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface AppSettingsPageProps extends RouteComponentProps {}

const AppSettingsPage = (props: AppSettingsPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props} groups={['Super Administrators', 'SUPER_USER']}>
			<div className="body-content app-settings-page">
				<div className="invisible-page-wrap">
					<div className="top-container">
						<PageBreadcrumbs
							tags={
								[
									{ label: 'Settings' },
								]
							}
						/>
					</div>
					<div className="white-box">
						<h4>Terms & conditions</h4>
						<UploadTerms />
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default AppSettingsPage;
