import React, { useMemo } from 'react';
import { EsigntemplateEntity } from '../../../../Models/Entities';
import { observer } from 'mobx-react';
import Modal from '../../Modal/Modal';
import { Button, Display } from '../../Button/Button';
import ButtonAsyncState from '../../Button/ButtonAsyncState';
import { useState } from 'react';
import alertToast from '../../../../Util/ToastifyUtils';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { sendTemplateRequestEmail, sendUpdateTemplateRequestEmail } from 'Util/ESignRequestTemplateUtils';

export type RequestESignTemplateFields = {
	businessName: {data: string, error?: string},
	templateName: {data: string, error?: string},
	reasonRequired: {data: string, error?: string},
	lifespanIndays: {data?: number, error?: string},
	reminderFrequencyInDays: {data?: number, error?: string},
	numberofSignees: {data: number, error?: string},
	requireWitness: {data?: boolean, error?: string},
	smsAuth: {data?: boolean, error?: string},
}

export type Props = {
	businessEntityName: string,
	esigntemplateEntity: EsigntemplateEntity,
	onClose: () => void,
	isNewTemplate: boolean,
}

const ESignRequestTemplateModal: React.FunctionComponent<Props> = observer(props => {
	const {
		esigntemplateEntity,
		businessEntityName,
		onClose,
		isNewTemplate,
	} = props;

	const eSignTemplateContent = useMemo(() => {
		if (esigntemplateEntity.esigntemplatecontents.length === 0) return undefined;

		return esigntemplateEntity.esigntemplatecontents.reduce(
			(prev, current) => (prev.version > current.version) ? prev : current,
		);
	}, [esigntemplateEntity]);

	const [eSignRequestTemplateFields, setESignRequestTemplateFields] = useState<RequestESignTemplateFields>({
		businessName: { data: businessEntityName },
		templateName: { data: esigntemplateEntity.name ?? '' },
		reasonRequired: { data: '' },
		lifespanIndays: { data: esigntemplateEntity.lifespanindays },
		reminderFrequencyInDays: { data: esigntemplateEntity.reminderfrequencyindays },
		numberofSignees: { data: esigntemplateEntity.minimumnumberofsignees ?? 0 },
		requireWitness: { data: eSignTemplateContent?.requireswitness },
		smsAuth: { data: eSignTemplateContent?.usesmsauth },
	});
	const [hasDisabled, setHasDisabled] = useState(true);

	const validateField = (field: keyof RequestESignTemplateFields) => {
		let error;
		const fieldData = eSignRequestTemplateFields[field].data;

		switch (field) {
			case 'templateName':
				if (!fieldData) error = 'Template Name cannot be empty';
				break;
			case 'lifespanIndays':
				if (!fieldData) error = 'Life span cannot be empty';
				else if (fieldData as number <= 0) error = 'Life span cannot be less than 1';
				else if (fieldData as number > 10) error = 'Life span cannot exceed 10 days';
				else if (fieldData as number % 1 !== 0) error = 'Life span must be a valid days';
				break;
			case 'reminderFrequencyInDays':
				if (fieldData === undefined) error = 'Reminder Frequency cannot be empty';
				else if (fieldData as number < 0) error = 'Reminder Frequency cannot be empty';
				else if (fieldData as number > (eSignRequestTemplateFields.lifespanIndays.data ?? 0)) error = 'Reminder Frequency cannot exceed the life span';
				break;
			case 'numberofSignees':
				if (!fieldData) error = 'Number of signee cannot be empty';
				else if (fieldData as number <= 0) error = 'Number of signee cannot be less than 1';
				else if (fieldData as number % 1 !== 0) error = 'Number of signee must be a valid number';
				else if (fieldData as number > 10) error = 'Number of signee cannot exceed 10';
				break;
			case 'reasonRequired':
			case 'businessName':
				if (!fieldData) error = `${field.replace(/([A-Z])/g, ' $1')} cannot be empty`;
				break;
			case 'requireWitness':
			case 'smsAuth':
				if (fieldData === undefined) error = `${field.replace(/([A-Z])/g, ' $1')} cannot be empty`;
				break;
		}

		return error;
	};

	const handleUpdateTemplateFields = (field: keyof RequestESignTemplateFields, value: string | number | boolean) => {
		const error = validateField(field);
		setHasDisabled(!!error);

		if (field !== 'lifespanIndays') {
			setESignRequestTemplateFields({ ...eSignRequestTemplateFields, [field]: { data: value, error: error } });
			return;
		}

		setESignRequestTemplateFields({
			...eSignRequestTemplateFields,
			[field]: { data: value as number, error: error },
			reminderFrequencyInDays: { data: undefined, error: validateField('reminderFrequencyInDays') },
		});
	};

	const handleSendEmail = async () => {
		const isValid = Object.keys(eSignRequestTemplateFields).map(
			field => validateField(field as keyof RequestESignTemplateFields) === undefined,
		);

		if (!isValid.includes(false)) {
			const createRequestTemplate = async () => (isNewTemplate
				? sendTemplateRequestEmail(eSignRequestTemplateFields)
				: sendUpdateTemplateRequestEmail(eSignRequestTemplateFields));

			await createRequestTemplate();
			onClose();
			return;
		}

		setESignRequestTemplateFields(Object.keys(eSignRequestTemplateFields).reduce((acc, key) => ({
			...acc,
			[key]: {
				...eSignRequestTemplateFields[key],
				error: validateField(key as keyof RequestESignTemplateFields),
			},
		}), {} as RequestESignTemplateFields));
		setHasDisabled(true);
		alertToast('Please fill in all required fields', 'error');
	};

	return (
		<Modal
			isOpen
			label=""
			onRequestClose={onClose}
			className="access-modal add-application-modal"
		>
			<h4>Request a new {isNewTemplate ? 'Template' : 'Version'}</h4>
			<div className="template-fields">
				<section>
					<TextField
						model={eSignRequestTemplateFields.businessName}
						modelProperty="data"
						label="Business Entity"
						placeholder="Name of the Business Entity"
						onAfterChange={event => handleUpdateTemplateFields('businessName', event.target.value)}
						errors={eSignRequestTemplateFields.businessName.error}
						isReadOnly
					/>
					<TextField
						model={eSignRequestTemplateFields.reasonRequired}
						modelProperty="data"
						label="Reason Required"
						placeholder="Specify the reason"
						onAfterChange={event => handleUpdateTemplateFields('reasonRequired', event.target.value)}
						errors={eSignRequestTemplateFields.reasonRequired.error}
						isRequired
					/>
					<TextField
						model={eSignRequestTemplateFields.templateName}
						modelProperty="data"
						label="Template Name"
						placeholder="Template Name"
						onAfterChange={event => handleUpdateTemplateFields('templateName', event.target.value)}
						errors={eSignRequestTemplateFields.templateName.error}
						isRequired
					/>
					<NumberTextField
						model={eSignRequestTemplateFields.lifespanIndays}
						modelProperty="data"
						label="Lifespan in Days"
						placeholder="Lifespan in Days"
						tooltip="Indicates the number of days the document should remain valid"
						onAfterChange={event => handleUpdateTemplateFields('lifespanIndays', event.target.value)}
						errors={eSignRequestTemplateFields.lifespanIndays.error}
						isRequired
					/>
					<Combobox
						model={eSignRequestTemplateFields.reminderFrequencyInDays}
						modelProperty="data"
						label="Reminder Frequency in Days"
						placeholder="Reminder Frequency in Days"
						tooltip="Frequency of remainders received based on the lifespan of the document"
						options={Array.from({ length: eSignRequestTemplateFields.lifespanIndays.data ?? 0 }, (_, i) => i).map(item => ({
							display: item === 0 ? 'No Reminders' : `Every ${item} day(s)`,
							value: item,
						}))}
						searchable={false}
						onAfterChange={(_, data) => handleUpdateTemplateFields('reminderFrequencyInDays', data.value as number)}
						errors={eSignRequestTemplateFields.reminderFrequencyInDays.error}
						isRequired
					/>
					<NumberTextField
						model={eSignRequestTemplateFields.numberofSignees}
						modelProperty="data"
						label="Number of Signatory needed"
						placeholder="Number of Signatory needed"
						tooltip="Indicates the number of signees required to sign the document"
						onAfterChange={event => handleUpdateTemplateFields('numberofSignees', event.target.value)}
						errors={eSignRequestTemplateFields.numberofSignees.error}
						isRequired
					/>
					<Combobox
						model={eSignRequestTemplateFields.requireWitness}
						modelProperty="data"
						label="Require to be witnessed"
						placeholder="Require to be witnessed"
						options={[
							{ value: true, display: 'Yes' },
							{ value: false, display: 'No' },
						]}
						onAfterChange={(_, data) => handleUpdateTemplateFields('requireWitness', data.value as boolean)}
						searchable={false}
						errors={eSignRequestTemplateFields.requireWitness.error}
						isRequired
					/>
					<Combobox
						model={eSignRequestTemplateFields.smsAuth}
						modelProperty="data"
						label="SMS Required"
						placeholder="SMS Required"
						options={[
							{ value: true, display: 'Yes' },
							{ value: false, display: 'No' },
						]}
						searchable={false}
						onAfterChange={(_, data) => handleUpdateTemplateFields('smsAuth', data.value as boolean)}
						errors={eSignRequestTemplateFields.smsAuth.error}
						isRequired
					/>
				</section>
			</div>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					readonly={hasDisabled}
					onPress={handleSendEmail}
					display={Display.Solid}
				>
					Send Email
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});

export default ESignRequestTemplateModal;
