import * as React from 'react';
import { CustomerEntity } from '../../../Models/Entities';
import { observer } from 'mobx-react';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import { useCallback } from 'react';
import { acnStatusOptions, acnTypeOptions } from 'Models/Enums';
import { debounce } from 'lodash';
import Modal from '../Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
export interface ValidateCustomerProps {
	customer: CustomerEntity,
	onAfterChange?: (validateSuccessful: boolean) => void,
	readonly?: boolean,
	onClose: () => void,
}

const ValidateCustomerFields = observer((props: ValidateCustomerProps) => {
	const {
		customer, onClose,
	} = props;

	const [privateCustomer] = React.useState(() => {
		const newCustomer = new CustomerEntity(customer);
		return newCustomer;
	});
	const validateAbn = useCallback(async () => {
		await privateCustomer.validateField('abn');
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateAbn = useCallback(debounce(validateAbn, 300), [validateAbn]);

	const validateAcn = useCallback(async () => {
		await privateCustomer.validateField('acn');
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateAcn = useCallback(debounce(validateAcn, 300), [validateAcn]);

	const validateTrusteeACN = useCallback(async () => {
		await privateCustomer.validateField('trusteeACN');
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateTrusteeACN = useCallback(debounce(validateTrusteeACN, 300), [validateTrusteeACN]);
	
	return (
		<Modal
			isOpen
			label="Validate Customer Fields"
			onRequestClose={onClose}
			className="access-modal"
		>
			<h4> Validate a customer</h4>
			<div className="inputs">
				<div className="inputs-column stretch">
					<div className="abn-container input-container">
						<IconTextBox
							className="business-number abn"
							model={privateCustomer}
							modelProperty="abn"
							placeholder="ABN"
							onChangeAndBlur={validateAbn}
							onAfterChange={debouncedValidateAbn}
							errors={privateCustomer.errors.abn}
							isRequired
							displayIconContainer={privateCustomer.abnFinishedValidating}
							iconDisplay={privateCustomer.entityStatus !== '' && privateCustomer
								.entityStatus === 'Active' ? IconDisplay.Valid : IconDisplay.Error}
							isDisabled={false}
							label="ABN"
						/>
						<div className="status"><b>Status:</b> {!privateCustomer.entityStatus ? '-'
							: privateCustomer.entityStatus}<br/>
						</div>
						<div className="type"><b>Type:</b> {!privateCustomer.entityTypeDescription ? '-'
							: privateCustomer.entityTypeDescription}<br/>
						</div>
						<div className="entity-name"><b>Entity Name:</b> {!privateCustomer.entityName ? '-'
							: privateCustomer.entityName}<br/>
						</div>
					</div>
				</div>
			</div>
			<div className="asic-acn inputs">
				<div className="inputs-column stretch">
					<div className="acn-container input-container">
						<IconTextBox
							className="business-number acn"
							model={privateCustomer}
							modelProperty="acn"
							placeholder="ACN"
							onChangeAndBlur={validateAcn}
							onAfterChange={debouncedValidateAcn}
							errors={privateCustomer.errors.acn}
							isRequired
							displayIconContainer={privateCustomer.acnFinishedValidating}
							iconDisplay={privateCustomer.acnStatus !== '' && privateCustomer.acnStatus === 'REGD'
								? IconDisplay.Valid : IconDisplay.Warning}
							isDisabled={false}
							label="ACN"
						/>
						<div className="status"><b>Status:</b> {!privateCustomer.acnStatus ? '-'
							: acnStatusOptions[privateCustomer.acnStatus]}<br/>
						</div>
						<div className="type"><b>Type:</b> {!privateCustomer.acnEntityTypeCode ? '-'
							: acnTypeOptions[privateCustomer.acnEntityTypeCode]}<br/>
						</div>
						<div className="entity-name"><b>Entity Name:</b> {!privateCustomer.acnEntityName ? '-'
							: privateCustomer.acnEntityName}<br/>
						</div>
					</div>
				</div>
			</div>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Solid}>Close</Button>
			</div>
		</Modal>
	);
});

export default ValidateCustomerFields;

