import * as React from 'react';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';
import { store } from 'Models/Store';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { IOptionReferrerCharityEntityAttributes, OptionReferrerCharityEntity, OrganisationEntity }
	from 'Models/Entities';
import { observer } from 'mobx-react';

export interface CharitySelectorProps {
    className?: string;
    organisation: OrganisationEntity, // should be observable
    errors?: string | string[];
    isDisabled?: boolean,
    isRequired?: boolean,
    label?: string,
    onAfterChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
	placeholder?: string,
}

const CharitySelector = observer((props: CharitySelectorProps) => {
	const {
		organisation, isDisabled, isRequired, onAfterChange, className, errors, label, placeholder,
	} = props;

	const getCharityOptions = async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		// if the fetch throws an error or is the charity list is empty return N/A as the charity
		try {
			const charityResults = await store.apolloClient
				.query({
					query: gql`
							query fetchCharities($searchTerm: String) {
								optionReferrerCharityEntitys (
									where: [{
										path: "name",
										comparison: like,
										value: [$searchTerm],
										case: CURRENT_CULTURE_IGNORE_CASE
									}],
									orderBy: [{path: "name", descending: false}],
								) {
									id
									name
									description
								}
							}
						`,
					variables: {
						searchTerm: !!searchTerm ? `%${searchTerm}%` : '%',
					},
					fetchPolicy: 'no-cache',
				});

			if (charityResults.data.optionReferrerCharityEntitys.length === 0) {
				return [{ display: 'N/A', value: 'N/A' }];
			}
			return charityResults.data.optionReferrerCharityEntitys
				.map((x: OptionReferrerCharityEntity) => {
					return { display: x.name, value: x.name };
				});
		} catch (e) {
			console.error(e);
			// If we can't get any charities, we fall back to the code below.
			return [{ display: 'N/A', value: 'N/A' }];
		}
	};

	return (
		<Combobox
			className={classNames('charity-selector', className)}
			model={organisation}
			modelProperty="referrerCharityName"
			label={label ?? 'Charity'}
			placeholder={placeholder ?? 'Charity name'}
			isDisabled={isDisabled}
			isRequired={isRequired}
			initialOptions={getCharityOptions}
			options={AwesomeDebouncePromise(getCharityOptions, 250)}
			searchable
			onAfterChange={onAfterChange}
			errors={errors}
			getOptionValue={(charity?: string) => charity}
			optionEqualFunc={
				(name: string|number|boolean|undefined, charity?: string) => charity === name
			}
		/>
	);
});

export default CharitySelector;
