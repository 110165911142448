/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserAtbRecordEntity from 'Models/Security/Acl/UserAtbRecordEntity';
import VisitorsAtbRecordEntity from 'Models/Security/Acl/VisitorsAtbRecordEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAtbRecordEntityAttributes extends IModelAttributes {
	originalData: string;
	entityName: string;
	phone: string;
	terms: string;
	acn: string;
	processingWarnings: string;
	paymentRiskRating: Enums.riskScore;
	processingErrors: string;
	dp91to120: number;
	totalDebt: number;
	trusteeACN: string;
	dp31to60: number;
	dp61to90: number;
	debtorID: string;
	dp1to30: number;
	dp120plus: number;
	dpcurrent: number;
	sicCode: string;
	abn: string;
	errorsProcessed: boolean;
	customerName: string;

	atbFileId: string;
	atbFile: Models.AtbFileEntity | Models.IAtbFileEntityAttributes;
	customerId?: string;
	customer?: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	address?: Models.AddressEntity |
		Models.IAddressEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	totalOverdue: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AtbRecordEntity', 'ATB Record')
// % protected region % [Customise your entity metadata here] end
export default class AtbRecordEntity extends Model
	implements IAtbRecordEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserAtbRecordEntity(),
		new VisitorsAtbRecordEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] on begin
		'totalOverdue',
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] on begin
		'totalOverdue',
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Original Data'] off begin
	/**
	 * Json object of original data
	 */
	@Validators.Required()
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Original Data',
		displayType: 'textarea',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public originalData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Original Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Entity Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Terms'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Terms',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public terms: string;
	// % protected region % [Modify props to the crud options here for attribute 'Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'ACN',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Processing Warnings'] off begin
	/**
	 * Json Object with Warnings from processing
	 */
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Processing Warnings',
		displayType: 'textarea',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public processingWarnings: string;
	// % protected region % [Modify props to the crud options here for attribute 'Processing Warnings'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment Risk Rating'] off begin
	/**
	 * The payment risk rating for the customer given this set of ATB data
	 */
	@observable
	@attribute<AtbRecordEntity, Enums.riskScore>()
	@CRUD({
		name: 'Payment Risk Rating',
		displayType: 'enum-combobox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.riskScoreOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.riskScoreOptions),
		displayFunction: (attr: Enums.riskScore) => Enums.riskScoreOptions[attr],
	})
	public paymentRiskRating: Enums.riskScore;
	// % protected region % [Modify props to the crud options here for attribute 'Payment Risk Rating'] end

	// % protected region % [Modify props to the crud options here for attribute 'Processing Errors'] off begin
	/**
	 * Json Object with Errors from processing
	 */
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Processing Errors',
		displayType: 'textarea',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public processingErrors: string;
	// % protected region % [Modify props to the crud options here for attribute 'Processing Errors'] end

	// % protected region % [Modify props to the crud options here for attribute 'DP91to120'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DP91to120',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dp91to120: number;
	// % protected region % [Modify props to the crud options here for attribute 'DP91to120'] end

	// % protected region % [Modify props to the crud options here for attribute 'Total Debt'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'Total Debt',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public totalDebt: number;
	// % protected region % [Modify props to the crud options here for attribute 'Total Debt'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Trustee ACN',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trusteeACN: string;
	// % protected region % [Modify props to the crud options here for attribute 'Trustee ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'DP31to60'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DP31to60',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dp31to60: number;
	// % protected region % [Modify props to the crud options here for attribute 'DP31to60'] end

	// % protected region % [Modify props to the crud options here for attribute 'DP61to90'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DP61to90',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dp61to90: number;
	// % protected region % [Modify props to the crud options here for attribute 'DP61to90'] end

	// % protected region % [Modify props to the crud options here for attribute 'Debtor ID'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Debtor ID',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public debtorID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Debtor ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'DP1to30'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DP1to30',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dp1to30: number;
	// % protected region % [Modify props to the crud options here for attribute 'DP1to30'] end

	// % protected region % [Modify props to the crud options here for attribute 'DP120plus'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DP120plus',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dp120plus: number;
	// % protected region % [Modify props to the crud options here for attribute 'DP120plus'] end

	// % protected region % [Modify props to the crud options here for attribute 'DPCurrent'] off begin
	@Validators.Numeric()
	@observable
	@attribute<AtbRecordEntity, number>()
	@CRUD({
		name: 'DPCurrent',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public dpcurrent: number;
	// % protected region % [Modify props to the crud options here for attribute 'DPCurrent'] end

	// % protected region % [Modify props to the crud options here for attribute 'SIC Code'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'SIC Code',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sicCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'SIC Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'ABN',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN'] end

	// % protected region % [Modify props to the crud options here for attribute 'Errors Processed'] off begin
	/**
	 * Whether or not the ATB Record has been processed
	 */
	@observable
	@attribute<AtbRecordEntity, boolean>()
	@CRUD({
		name: 'Errors Processed',
		displayType: 'checkbox',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public errorsProcessed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Errors Processed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Customer Name'] off begin
	@observable
	@attribute<AtbRecordEntity, string>()
	@CRUD({
		name: 'Customer Name',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public customerName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Customer Name'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] off begin
		name: 'ATB File',
		displayType: 'reference-combobox',
		order: 220,
		referenceTypeFunc: () => Models.AtbFileEntity,
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] end
	})
	public atbFileId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public atbFile: Models.AtbFileEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer'] off begin
		name: 'Customer',
		displayType: 'reference-combobox',
		order: 230,
		referenceTypeFunc: () => Models.CustomerEntity,
		// % protected region % [Modify props to the crud options here for reference 'Customer'] end
	})
	public customerId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customer: Models.CustomerEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Address'] off begin
		name: 'Address',
		displayType: 'reference-combobox',
		order: 240,
		referenceTypeFunc: () => Models.AddressEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'addresss',
			oppositeEntity: () => Models.AddressEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Address'] end
	})
	public address?: Models.AddressEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public totalOverdue: number;

	@observable
	public originalDataConverted: any;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAtbRecordEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAtbRecordEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.originalData !== undefined) {
				this.originalData = attributes.originalData;
			}
			if (attributes.entityName !== undefined) {
				this.entityName = attributes.entityName;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.terms !== undefined) {
				this.terms = attributes.terms;
			}
			if (attributes.acn !== undefined) {
				this.acn = attributes.acn;
			}
			if (attributes.processingWarnings !== undefined) {
				this.processingWarnings = attributes.processingWarnings;
			}
			if (attributes.paymentRiskRating !== undefined) {
				this.paymentRiskRating = attributes.paymentRiskRating;
			}
			if (attributes.processingErrors !== undefined) {
				this.processingErrors = attributes.processingErrors;
			}
			if (attributes.dp91to120 !== undefined) {
				this.dp91to120 = attributes.dp91to120;
			}
			if (attributes.totalDebt !== undefined) {
				this.totalDebt = attributes.totalDebt;
			}
			if (attributes.trusteeACN !== undefined) {
				this.trusteeACN = attributes.trusteeACN;
			}
			if (attributes.dp31to60 !== undefined) {
				this.dp31to60 = attributes.dp31to60;
			}
			if (attributes.dp61to90 !== undefined) {
				this.dp61to90 = attributes.dp61to90;
			}
			if (attributes.debtorID !== undefined) {
				this.debtorID = attributes.debtorID;
			}
			if (attributes.dp1to30 !== undefined) {
				this.dp1to30 = attributes.dp1to30;
			}
			if (attributes.dp120plus !== undefined) {
				this.dp120plus = attributes.dp120plus;
			}
			if (attributes.dpcurrent !== undefined) {
				this.dpcurrent = attributes.dpcurrent;
			}
			if (attributes.sicCode !== undefined) {
				this.sicCode = attributes.sicCode;
			}
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.errorsProcessed !== undefined) {
				this.errorsProcessed = attributes.errorsProcessed;
			}
			if (attributes.customerName !== undefined) {
				this.customerName = attributes.customerName;
			}
			if (attributes.atbFileId !== undefined) {
				this.atbFileId = attributes.atbFileId;
			}
			if (attributes.atbFile !== undefined) {
				if (attributes.atbFile === null) {
					this.atbFile = attributes.atbFile;
				} else if (attributes.atbFile instanceof Models.AtbFileEntity) {
					this.atbFile = attributes.atbFile;
					this.atbFileId = attributes.atbFile.id;
				} else {
					this.atbFile = new Models.AtbFileEntity(attributes.atbFile);
					this.atbFileId = this.atbFile.id;
				}
			}
			if (attributes.customerId !== undefined) {
				this.customerId = attributes.customerId;
			}
			if (attributes.customer !== undefined) {
				if (attributes.customer === null) {
					this.customer = attributes.customer;
				} else if (attributes.customer instanceof Models.CustomerEntity) {
					this.customer = attributes.customer;
					this.customerId = attributes.customer.id;
				} else {
					this.customer = new Models.CustomerEntity(attributes.customer);
					this.customerId = this.customer.id;
				}
			}
			if (attributes.address !== undefined) {
				if (attributes.address === null) {
					this.address = attributes.address;
				} else if (attributes.address instanceof Models.AddressEntity) {
					this.address = attributes.address;
				} else {
					this.address = new Models.AddressEntity(attributes.address);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.totalOverdue !== undefined) {
				this.totalOverdue = attributes.totalOverdue;
			}
			if (attributes.originalData !== undefined) {
				try {
					this.originalDataConverted = JSON.parse(attributes.originalData);
				} catch (e) {
					// most likely because non json information was saved
					console.error(e);
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		atbFile {
			${Models.AtbFileEntity.getAttributes().join('\n')}
		}
		customer {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
		address {
			${Models.AddressEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			address: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'address',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AtbRecordEntity.prototype, 'created');
CRUD(modifiedAttr)(AtbRecordEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
