import * as React from 'react';
import { AlaresLicence } from 'Models/Interfaces/AlaresReport';
import AdverseAlertsTable from './AdverseAlertsTable';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import { useCallback, useState } from 'react';
import AdverseAlertsLicenceDetailsModal from './AdverseAlertsLicenceDetailsModal';

const AdverseAlertsLicences = (props: {
	licences: AlaresLicence[],
}) => {
	const { licences } = props;
	const [highlightedLicence, setHighlightedLicence] = useState<AlaresLicence | undefined>(undefined);

	const closeLicenceModal = useCallback(() => {
		setHighlightedLicence(undefined);
	}, []);

	return (
		<>
			<AdverseAlertsTable
				tableName="Building licences"
				className="licences"
				collection={licences}
				columns={[
					{
						displayName: 'State',
						columnName: 'state',
						value: licence => licence.state,
						sortable: false,
						className: 'field-state',
					},
					{
						displayName: 'Licence No.',
						columnName: 'licenceNo',
						value: licence => !licence.url && !licence.number
							? licence.number
							: <a href={licence.url}>{licence.number}</a>,
						sortable: false,
						className: 'field-licence-no',
					},
					{
						displayName: 'Licence holder',
						columnName: 'licenceHolder',
						value: licence => licence.licence_holder,
						sortable: false,
						className: 'field-licence-holder',
					},
					{
						displayName: 'Licence category',
						columnName: 'licenceCategory',
						value: licence => licence.category,
						sortable: false,
						className: 'field-category',
					},
					{
						displayName: 'Licence class',
						columnName: 'class',
						value: licence => {
							let correct = licence.classes.find(x => x.status.toLowerCase() === 'active');
							if (!correct) {
								if (licence.classes.length === 0) {
									return null;
								}
								[correct] = licence.classes;
							}
							return (
								<div>
									{correct.class}<br />
									{correct.grade}<br />
									<b>Active</b>
								</div>
							);
						},
						sortable: false,
						className: 'field-class',
					},
					{
						displayName: '',
						columnName: 'details',
						value: licence => (
							<span
								className="icon icon-right icon-open-in-new"
								{...HandleEvents(() => setHighlightedLicence(licence))}
							>
								DETAILS
							</span>
						),
						sortable: false,
						className: 'field-details',
					},
				]}
				sortColumn="date"
				sortDescending
				idColumn="number"
			/>
			<If condition={!!highlightedLicence}>
				<AdverseAlertsLicenceDetailsModal
					licence={highlightedLicence ?? {} as AlaresLicence}
					onClose={closeLicenceModal}
				/>
			</If>
		</>
	);
};

export default AdverseAlertsLicences;
