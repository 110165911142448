import * as React from 'react';
import { DisplayType } from '../Models/Enums';
import { Checkbox } from './Checkbox';

export interface ICbCheckboxSetterProps<T> {
	/** The current value */
	value?: T;
	/** The setter to apply a new value */
	setValue?: ((newValue: T) => void);
	id?: string;
	name?: string;
	className?: string;
	displayType?: DisplayType;
	label?: string;
	labelVisible?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	tooltip?: string;
	subDescription?: string;
	onChecked?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
	onAfterSet?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	errors?: string | string[];
}

function CheckboxSetter<T>(props: ICbCheckboxSetterProps<T>) {
	const {
		value, setValue, onAfterSet, onChecked,
	} = props;

	// Setup an object to store the value on, so that the combobox can read it
	const valueParent = {
		value: value,
	};

	return (
		<Checkbox
			model={valueParent}
			modelProperty="value"
			onAfterChecked={(event, checked) => {
				if (!!setValue && !!valueParent.value) {
					setValue(valueParent.value);
				}

				if (!!onAfterSet) {
					onAfterSet(event, checked);
				}
			}}
			{...props}
		/>
	);
}

export default CheckboxSetter;
