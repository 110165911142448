import * as React from 'react';
import { ApplicationEntity, BusinessEntity } from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';
import moment from 'moment';
import ApplicationFields from '../Approve/ApplicationFields';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export type EditApproveDetailsModalProps = {
	businessEntity: BusinessEntity,
	onClose: () => void,
	onSubmit: () => Promise<void>,
	addNew?: boolean,
}

const EditApproveDetailsModal = observer((props: EditApproveDetailsModalProps) => {
	const {
		businessEntity, onClose, onSubmit, addNew,
	} = props;

	// Create a duplicate of the business entity, so that we can edit it while still being able to cancel and not save anything
	// This component assumes that nothing else will edit the entity in the background. (Since this should be covering everything)
	const privateBusinessEntity = useLocalStore(() => {
		const entity = new BusinessEntity(businessEntity);
		entity.applicationss = [new ApplicationEntity({ applicationName: entity.name }), ...entity.applicationss];
		entity.enabledForApprove = true;
		return entity;
	});

	const submit = async () => {
		await privateBusinessEntity.applicationss[0].validateAllFields();
		if (Object.keys(privateBusinessEntity.applicationss[0].errors).length === 0) {
			// Save all updated fields back to original business entity
			runInAction(() => {
				businessEntity.applicationss = privateBusinessEntity.applicationss;
				businessEntity.applicationss[0].isActive = true;
				businessEntity.enabledForApprove = true;
				businessEntity.applicationss[0].applicationDate = moment().utc().toDate();
				businessEntity.applicationss[0].businessEntityId = businessEntity.id;
			});
			await onSubmit();
		}
	};

	const onChange = async (be: BusinessEntity, field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!be.errors[field]) {
			await be.validateField(field);
		}
	};

	return (
		<Modal
			isOpen
			label="Edit Approve details"
			onRequestClose={onClose}
			className="access-modal"
		>
			{ addNew ? <h4>Approve details</h4> : <h4>Edit Approve details</h4> }
			<ApplicationFields applicationEntity={privateBusinessEntity.applicationss[0]} create={addNew} />
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					readonly={Object.keys(privateBusinessEntity.applicationss[0].errors).length > 0}
					onPress={submit}
					display={Display.Solid}
				>
					Save updates
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});
export default EditApproveDetailsModal;
