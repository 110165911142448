/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsEsignuser from 'Models/Security/Acl/VisitorsEsignuser';
import UserEsignuser from 'Models/Security/Acl/UserEsignuser';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEsignuserEntityAttributes extends IModelAttributes {
	name: string;
	phone: string;
	email: string;
	signature: string;
	status: Enums.esignuserstatus;

	esignceremonysigneess: Array<
		| Models.EsignceremonysigneeEntity
		| Models.IEsignceremonysigneeEntityAttributes
	>;
	esignceremonywitnessss: Array<
		| Models.EsignceremonywitnessEntity
		| Models.IEsignceremonywitnessEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EsignuserEntity', 'ESignUser')
// % protected region % [Customise your entity metadata here] end
export default class EsignuserEntity extends Model
	implements IEsignuserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsEsignuser(),
		new UserEsignuser(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<EsignuserEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	@observable
	@attribute<EsignuserEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@observable
	@attribute<EsignuserEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Signature'] off begin
	@observable
	@attribute<EsignuserEntity, string>()
	@CRUD({
		name: 'Signature',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public signature: string;
	// % protected region % [Modify props to the crud options here for attribute 'Signature'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<EsignuserEntity, Enums.esignuserstatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.esignuserstatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.esignuserstatusOptions),
		displayFunction: (attr: Enums.esignuserstatus) => Enums.esignuserstatusOptions[attr],
	})
	public status: Enums.esignuserstatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignees'] off begin
		name: 'ESignCeremonySigneess',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.EsignceremonysigneeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignceremonysigneess',
			oppositeEntity: () => Models.EsignceremonysigneeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonySignees'] end
	})
	public esignceremonysigneess: Models.EsignceremonysigneeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonyWitnesss'] off begin
		name: 'ESignCeremonyWitnessss',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.EsignceremonywitnessEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'esignceremonywitnessss',
			oppositeEntity: () => Models.EsignceremonywitnessEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ESignCeremonyWitnesss'] end
	})
	public esignceremonywitnessss: Models.EsignceremonywitnessEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEsignuserEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEsignuserEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.signature !== undefined) {
				this.signature = attributes.signature;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.esignceremonysigneess !== undefined && Array.isArray(attributes.esignceremonysigneess)) {
				for (const model of attributes.esignceremonysigneess) {
					if (model instanceof Models.EsignceremonysigneeEntity) {
						this.esignceremonysigneess.push(model);
					} else {
						this.esignceremonysigneess.push(new Models.EsignceremonysigneeEntity(model));
					}
				}
			}
			if (attributes.esignceremonywitnessss !== undefined && Array.isArray(attributes.esignceremonywitnessss)) {
				for (const model of attributes.esignceremonywitnessss) {
					if (model instanceof Models.EsignceremonywitnessEntity) {
						this.esignceremonywitnessss.push(model);
					} else {
						this.esignceremonywitnessss.push(new Models.EsignceremonywitnessEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		esignceremonysigneess {
			${Models.EsignceremonysigneeEntity.getAttributes().join('\n')}
		}
		esignceremonywitnessss {
			${Models.EsignceremonywitnessEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			esignceremonysigneess: {},
			esignceremonywitnessss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EsignuserEntity.prototype, 'created');
CRUD(modifiedAttr)(EsignuserEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
