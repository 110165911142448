import { useESignStore } from 'ESign/Stores/ESignStore';
import LoadableState from 'ESign/Types/ESignLoadableState';
import ESignSigningStage from 'ESign/Types/ESignSigningStage';
import { ESignWitnessDetail } from 'ESign/Types/ESignWitnessDetail';
import ESignCeremonyType from 'ESign/Types/Enums/ESignCeremonyType';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Grid, GridColumn } from 'semantic-ui-react';

type Props = {
	templateContent: string;
	updatedSignature: string;
	witnessDetail: ESignWitnessDetail | undefined;
	previousSection: () => void;
	nextSection: () => void;
	stage: ESignSigningStage;
	changeStage: (stage: ESignSigningStage) => void;
	hasError: Record<ESignSigningStage, boolean>
}

const SigningStepFooter: React.FunctionComponent<Props> = ({
	templateContent,
	updatedSignature,
	witnessDetail,
	previousSection,
	nextSection,
	stage,
	changeStage,
	hasError,
}) => {
	const {
		workflowStore: {
			selectedCeremony, saveCeremony, loadingState, setLoadingState,
		},
	} = useESignStore();

	const isSigneeCeremonyType = selectedCeremony?.type === ESignCeremonyType.SIGNEE;
	const requireWitness = selectedCeremony?.workflow.templateContent.requireWitness ?? false;

	const possibleStatusBeforeSigned = ['VISITED', 'SENT', 'NOT_STARTED', 'CREATED'];
	const isStatusSigned = !(selectedCeremony?.status && possibleStatusBeforeSigned.includes(selectedCeremony.status));

	const isExpired = moment(selectedCeremony?.workflow?.created)
		.add(selectedCeremony?.workflow.template.lifeSpan, 'days').isBefore(moment());

	const requireWitnessSection = isSigneeCeremonyType && requireWitness;

	const handleCeremonyCompletion = async () => {
		await saveCeremony(templateContent, updatedSignature, witnessDetail);
		nextSection();
	};

	const handleNext = async () => {
		switch (stage) {
			case 'Content':
				changeStage(requireWitnessSection ? 'Witness' : 'Review');
				break;
			case 'Witness':
				changeStage('Review');
				break;
			case 'Review':
				await handleCeremonyCompletion();
				break;
		}
	};

	const handleBack = () => {
		switch (stage) {
			case 'Content':
				previousSection();
				break;
			case 'Witness':
				changeStage('Content');
				break;
			case 'Review':
				if (isStatusSigned || isExpired) {
					previousSection();
				} else {
					if (loadingState === LoadableState.Rejected) {
						setLoadingState(LoadableState.Unloaded);
					}
					changeStage(requireWitnessSection ? 'Witness' : 'Content');
				}
				break;
		}
	};

	return (
		<Grid textAlign="justified" stackable>
			<GridColumn width={16} verticalAlign="middle">
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="esign-ceremony-actions">
					<Button
						colors={Colors.Error}
						display={Display.Text}
						sizes={Sizes.Medium}
						onClick={() => {
							handleBack();
						}}
						disabled={loadingState === LoadableState.Pending}
					>
						Back
					</Button>
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						onClick={handleNext}
						disabled={
							hasError[stage]
							|| loadingState === LoadableState.Pending
							|| loadingState === LoadableState.Rejected
							|| (isStatusSigned && stage === 'Review')
							|| (isExpired && stage === 'Review')
						}
					>
						{stage === 'Review'
							? 'Complete'
							: 'Next'}
					</Button>
				</ButtonGroup>
			</GridColumn>
		</Grid>
	);
};

export default observer(SigningStepFooter);
