import * as React from 'react';
import Modal from '../Modal/Modal';
import { TextFieldSetter } from '../TextBox/TextFieldSetter';
import { Errors } from '../../../Util/CustomTypes';
import { isEmail } from '../../../Validators/Functions/Email';
import { Button, Display } from '../Button/Button';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import alertToast from '../../../Util/ToastifyUtils';
import HandleEvents from '../../../Util/HandleEvents';
import * as ReferralPartnerUtils from 'Util/ReferralPartnerUtils';
import { store } from '../../../Models/Store';

export type GenerateReferralModalProps = {
    onClose: () => void,
	onLinkGeneration: () => void,
}

const GenerateReferralModal = (props: GenerateReferralModalProps) => {
	const { onClose, onLinkGeneration } = props;
	// recipient fields
	const [recipientFields, setRecipientFields] = React.useState({
		name: '',
		organisation: '',
		email: '',
	});
	// show the copy link modal state
	const [showCopyLink, setShowCopyLink] = React.useState(false);
	// created registration id
	const [registrationId, setRegistrationId] = React.useState('');
	// form errors
	const [errors, setErrors] = React.useState({} as Errors);

	// handles on copy link click
	const onCopyClick = () => {
		ReferralPartnerUtils.copyReferralLinkToClipboard(registrationId);
	};

	// handles on send via email click
	const onEmailClick = () => {
		const mailToLink = ReferralPartnerUtils.getMailToLink(recipientFields.name, recipientFields.email,
			registrationId, store.userDisplayName);
		window.open(mailToLink);
	};

	// handles on generate referral confirmation click
	const onGenerateReferralConfirmationClick = async () => {
		// run validation on all recipient fields
		let oldErrors = requiredValidator('organisation', recipientFields.organisation, {});
		oldErrors = requiredValidator('name', recipientFields.name, oldErrors);
		oldErrors = emailValidator('email', recipientFields.email, oldErrors);
		setErrors(oldErrors);
		// if there are any errors skip processing
		if (Object.keys(oldErrors).length > 0) {
			return;
		}

		// call the generate-referral endpoint
		try {
			const result = await axios.post(
				`${SERVER_URL}/api/entity/RegistrationEntity/generate-referral`,
				recipientFields,
			);
			// now show the copy link modal
			setShowCopyLink(true);
			setRegistrationId(result.data);
			// run link generation steps
			onLinkGeneration();
		} catch (e: any) {
			alertToast(e.response.data, 'error');
		}
	};

	// handles validation for an email field
	function emailValidator(fieldName: string, field: string, oldErrors: Errors) {
		if (!field) {
			const error = {};
			error[fieldName] = `Recipient ${fieldName} is required`;
			return { ...oldErrors, ...error };
		}

		if (!isEmail(field)) {
			const error = {};
			error[fieldName] = `Recipient ${fieldName} must be a valid email`;
			return { ...oldErrors, ...error };
		}

		const copy = { ...oldErrors };
		delete copy[fieldName];
		return copy;
	}

	// handles validation for a string required field
	function requiredValidator(fieldName: string, field: string, oldErrors: Errors) {
		if (!field) {
			const error = {};
			error[fieldName] = `Recipient ${fieldName} is required`;
			return { ...oldErrors, ...error };
		}

		const copy = { ...oldErrors };
		delete copy[fieldName];
		return copy;
	}

	// handles returning an object with updated values
	function setFieldValue(oldValue: { name: string, email: string, organisation: string}, fieldName: ('name' | 'email'
			| 'organisation'), value: string) {
		const copy = { ...oldValue };
		copy[fieldName] = value;
		return copy;
	}

	// renders the copy link modal
	const renderCopyLink = () => {
		const referralLink = ReferralPartnerUtils.getReferralLink(registrationId);
		return (
			<div className="link-display">
				<label htmlFor="registration-link">Your unique referral link</label><br />
				<a id="registration-link" href={referralLink}>{referralLink}</a><br />
				<div className="actions-container">
					<span className="icon-left icon-content-copy" {...HandleEvents(onCopyClick)}>
						<b>Copy Link</b>
					</span>
					<span className="icon-left icon-mail" {...HandleEvents(onEmailClick)}>
						<b>Send Via Email</b>
					</span>
				</div>
				<div className="button-container">
					<Button key="cancel" onClick={onClose} display={Display.Outline}>Close</Button>
				</div>
			</div>
		);
	};

	// renders the recipient fields modal
	const renderRecipientFields = () => {
		return (
			<>
				<div className="recipient-fields">
					<TextFieldSetter
						className="recipient-name"
						value={recipientFields.name}
						setValue={value => setRecipientFields(oldValue => setFieldValue(oldValue, 'name', value))}
						label="Recipient name"
						placeholder="Name"
						isRequired
						onChangeAndBlur={_ => {
							setErrors(requiredValidator('name', recipientFields.name, errors));
						}}
						onAfterChange={_ => {
							if (!!errors.name) {
								setErrors(requiredValidator('name', recipientFields.name, errors));
							}
						}}
						errors={errors.name}
					/>
					<TextFieldSetter
						className="recipient-organisation"
						value={recipientFields.organisation}
						setValue={value => setRecipientFields(oldValue => setFieldValue(oldValue,
							'organisation', value))}
						label="Recipient organisation"
						placeholder="Organisation"
						isRequired
						onChangeAndBlur={_ => {
							setErrors(requiredValidator('organisation', recipientFields.organisation, errors));
						}}
						onAfterChange={_ => {
							if (!!errors.organisation) {
								setErrors(requiredValidator('organisation', recipientFields.organisation, errors));
							}
						}}
						errors={errors.organisation}
					/>
					<TextFieldSetter
						className="recipient-email"
						value={recipientFields.email}
						setValue={value => setRecipientFields(oldValue => setFieldValue(oldValue, 'email', value))}
						label="Recipient email address"
						placeholder="Email address"
						isRequired
						onChangeAndBlur={_ => {
							setErrors(emailValidator('email', recipientFields.email, errors));
						}}
						onAfterChange={_ => {
							if (!!errors.email) {
								setErrors(emailValidator('email', recipientFields.email, errors));
							}
						}}
						errors={errors.email}
					/>
				</div>
				<div className="button-container">
					<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
					<ButtonAsyncState
						key="confirm"
						readonly={Object.keys(errors).length > 0}
						onPress={onGenerateReferralConfirmationClick}
						display={Display.Solid}
					>
						Generate Link
					</ButtonAsyncState>
				</div>
			</>
		);
	};

	return (
		<Modal
			isOpen
			label="Generate Referral Link"
			className="access-modal generate-referral-link"
			onRequestClose={onClose}
		>
			<h4>Generate Referral Link</h4>
			{showCopyLink ? renderCopyLink() : renderRecipientFields()}
		</Modal>
	);
};

export default GenerateReferralModal;
