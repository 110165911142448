import * as React from 'react';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { Loader } from 'semantic-ui-react';
import { AlaresReport, AlaresReportUtil } from 'Models/Interfaces/AlaresReport';
import { CustomerEntity } from 'Models/Entities';
import moment from 'moment';
import AdverseAlertsCases from './AdverseAlertsCases';
import AdverseAlertsBusinessSummary from './AdverseAlertsBusinessSummary';
import AdverseAlertsAbrSummary from './AdverseAlertsAbrSummary';
import AdverseAlertsAsicUpdates from './AdverseAlertsAsicUpdates';
import AdverseAlertsLicences from './AdverseAlertsLicences';
import AdverseAlertsAdjudications from './AdverseAlertsAdjudications';
import AdverseAlertsInsolvencies from './AdverseAlertsInsolvencies';
import AdverseAlertsAtoDefaults from './AdverseAlertsAtoDefaults';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { Display, Sizes } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { confirmModalAsync } from 'Views/Components/Modal/ModalUtils';
import { AtoDefaultSummary } from 'Models/Interfaces/AtoDefaultSummary';

const AdverseAlertsReport = (props: {
	customer: CustomerEntity,
}) => {
	const { customer } = props;

	const [createNewReportDisabled, setCreateNewReportDisabled] = React.useState(false);

	const response = useAsync(async () => {
		const { data } = await axios.get(`${SERVER_URL}/api/entity/AlaresEntity/report/${customer.id}`);

		if (data === null) return null;

		const responseData = data as { alaresReport: AlaresReport, atoDefaults: AtoDefaultSummary[] };
		const report = responseData.alaresReport;

		report.casesList = AlaresReportUtil.getOrderedCasesList(report);
		report.asic_documents_list = AlaresReportUtil.getOrderedAsicDocuments(report);

		report.licencesList = Object.values(report.licences);
		report.adjudicationsList = Object.values(report.adjudications);
		report.insolvenciesList = Object.values(report.insolvencies);
		return { report: report, atoDefaults: responseData.atoDefaults };
	}, [customer]);

	const createNewReport = React.useCallback(async () => {
		const modal = confirmModalAsync(
			'Confirm Retrieve New Alares Report',
			(
				<>
					<b>Force the system to retrieve a new report from Alares.</b>
					<br />
					<i>This will delete any un-processed (likely stuck)
						Alares Reports to ensure the new report is used.
					</i>
				</>
			),
		);

		try {
			await modal.result();
		} catch (e) {
			return;
		}

		await axios.get(`${SERVER_URL}/api/entity/AlaresEntity/report/${customer.id}/new`);

		setCreateNewReportDisabled(true);

		modal.close();
	}, [customer.id]);

	return (
		<div className={classNames('adverse-alerts-report', {
			loading: response.type === 'loading',
		})}
		>
			<If condition={response.type === 'loading'}>
				<Loader inline active size="massive">
					Loading
				</Loader>
			</If>
			<If condition={response.type !== 'loading' && (response.error || !response.data)}>
				<div className="error-container">
					<h4>
						Adverse alerts are currently being generated.<br />
						If this persists for multiple days please contact Access Intell.
					</h4>
					{store.userType === 'SUPER_USER' && (
						<ButtonAsyncState
							display={Display.Solid}
							waitingText="Confirming..."
							onPress={createNewReport}
							readonly={createNewReportDisabled}
						>
							Retrieve Alares Report
						</ButtonAsyncState>
					)}
				</div>
			</If>
			{response.type === 'data' && !!response.data && (
				<>
					<h3>
						Adverse alerts
						{store.userType === 'SUPER_USER' && (
							<ButtonAsyncState
								sizes={Sizes.Small}
								display={Display.Outline}
								waitingText="Confirming..."
								onPress={createNewReport}
								readonly={createNewReportDisabled}
								buttonProps={{
									style: { float: 'right' },
								}}
							>
								Retrieve New Alares Report
							</ButtonAsyncState>
						)}
					</h3>
					<AdverseAlertsBusinessSummary customer={customer} alaresReport={response.data.report} />
					<AdverseAlertsAbrSummary alaresReport={response.data.report} />
					<AdverseAlertsCases cases={response.data.report?.casesList ?? []} />
					<AdverseAlertsAsicUpdates documents={response.data.report?.asic_documents_list ?? []} />
					<AdverseAlertsAtoDefaults records={response.data.atoDefaults ?? []} />
					<AdverseAlertsLicences licences={response.data.report?.licencesList ?? []} />
					<AdverseAlertsAdjudications adjudications={response.data.report?.adjudicationsList ?? []} />
					<AdverseAlertsInsolvencies insolvencies={response.data.report?.insolvenciesList ?? []} />
				</>
			)}
		</div>
	);
};

export default AdverseAlertsReport;
