import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import {
	AddressEntity,
	ApplicationEntity,
	BusinessEntity,
	IndustryCodesEntity,
	OrganisationEntity,
	SpgEntity,
} from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import If from 'Views/Components/If/If';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import alertToast from 'Util/ToastifyUtils';
import useAsync from 'Hooks/useAsync';
import ReferralPartnerDetails from '../Components/ReferralPartners/ReferralPartnerDetails';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface ReferralPartnerBusinessPageProps {}

const ReferralPartnerBusinessPage = observer((props: RouteComponentProps<ReferralPartnerBusinessPageProps>) => {
	const [currentTab, setCurrentTab] = React.useState('business-details');
	const organisation = useAsync(() => fetchOrganisationData(), [store.getUser?.organisation?.id]);
	const fetchOrganisationData = async (): Promise<OrganisationEntity> => {
		const results = await store.apolloClient.query({
			query: gql`
				query fetchOrganisationById($organisationId: ID) {
					organisationEntity(id: $organisationId) {
						${OrganisationEntity.getAllAttributes().join('\n')}
						primaryBusinessEntity {
							${BusinessEntity.getAllAttributes().join('\n')}
							address {
								${AddressEntity.getAllAttributes().join('\n')}
							}
						}
					}
				}`,
			variables: {
				organisationId: store.getUser?.organisation?.id,
			},
			fetchPolicy: 'no-cache',
		});

		if (!!results.data.organisationEntity) {
			return observable(new OrganisationEntity(results.data.organisationEntity));
		}
		throw new Error();
	};

	if (organisation.type === 'error') {
		alertToast('Unable to find matching organisation', 'error');
		store.routerHistory.push('/');
		return <Spinner />;
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content referral-partner-page">
				<div className="invisible-page-wrap">
					<If condition={organisation.type === 'loading'}>
						<InlineSpinner />
					</If>
					{organisation.type === 'data' && (
						<>
							<div className="top-container">
								<PageBreadcrumbs
									tags={
										[{ label: 'Business' }]
									}
								/>
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								<button
									className={classNames('tab-selector', 'business-details', {
										active: currentTab === 'business-details',
									})}
									{...HandleEvents(action(() => {
										setCurrentTab('business-details');
									}))}
								>
									Business details
								</button>
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': currentTab === 'business-details',
							})}
							>
								<If condition={currentTab === 'business-details'}>
									<ReferralPartnerDetails
										organisation={organisation.data}
										orgName={organisation.data.primaryBusinessEntity.name}
										hideBenefitDetails
									/>
								</If>
							</div>
						</>
					)}
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
});
export default ReferralPartnerBusinessPage;
