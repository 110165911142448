import * as React from 'react';
import { CustomerEntity } from 'Models/Entities';
import classNames from 'classnames';
import CustomerOverviewRiskScore from './CustomerOverviewRiskScore';
import CustomerOverviewAdverseAlerts from './CustomerOverviewAdverseAlerts';
import CustomerOverviewDebtSummary from './CustomerOverviewDebtSummary';
import { createRef } from 'react';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import CustomerOverviewNoAtbRecords from './CustomerOverviewNoAtbRecords';

const CustomerDebtGraph = React.lazy(() => import('Views/Components/Intel/Customer/Overview/CustomerDebtGraph'));

export interface CustomerOverviewProps {
	customer: CustomerEntity,
	onAdverseAlertsClick: () => void,
}

const CustomerOverview = (props: CustomerOverviewProps) => {
	const { customer, onAdverseAlertsClick } = props;
	const topRowRef = createRef<HTMLDivElement>();
	const overviewTabRef = createRef<HTMLDivElement>();

	return (
		<div
			className={classNames('customer-overview', {
				'hidden-information': !customer?.newestAtbRecord?.id,
			})}
			ref={overviewTabRef}
		>
			<div className="top-row" ref={topRowRef}>
				<CustomerOverviewDebtSummary customer={customer} />
				<CustomerOverviewRiskScore customer={customer} />
				<CustomerOverviewAdverseAlerts customer={customer} onAdverseAlertsClick={onAdverseAlertsClick} />
			</div>

			<CustomerOverviewNoAtbRecords customer={customer} />

			<div className="bottom-row">
				<React.Suspense fallback={<InlineSpinner />}>
					<CustomerDebtGraph customer={customer} topRowRef={topRowRef} overviewTabRef={overviewTabRef} />
				</React.Suspense>
			</div>
		</div>
	);
};

export default CustomerOverview;
