import classNames from 'classnames';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import EntityList, { IEntityListHeaderProps } from 'Views/Components/EntityList/EntityList';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import * as React from 'react';
import { useMemo, useState } from 'react';

export interface AdverseAlertsTableProps<T> {
	tableName: string,
	className?: string,
	collection: T[],
	columns: Array<IEntityListHeaderProps<T>>,
	sortColumn: string, // Specifies which column is currently being sorted
	sortDescending: boolean, // Specifies whether the sort column is sorted in descending or ascending order
	idColumn: string, // Should be a field containing a unique value on the entity. 'id' is usually used.
	onClickRow?: (entity: T) => void, // If set, clicking on a row will run this function
	rowClassName?: (entity: T) => string, // Adds a class to the entire row
}

function AdverseAlertsTable<T>(props: AdverseAlertsTableProps<T>) {
	const {
		tableName, className, collection, columns, sortColumn, sortDescending, idColumn, onClickRow, rowClassName,
	} = props;

	const [showTable, setShowTable] = useState(false);
	const [pageNo, setPageNo] = useState(0);
	const shortenedCollection = useMemo(() => collection
		.slice((ALARES_ITEMS_PER_PAGE * pageNo), (ALARES_ITEMS_PER_PAGE * pageNo) + ALARES_ITEMS_PER_PAGE),
	[pageNo, collection]);

	return (
		<div className={classNames('section alares-table', className)}>
			<div className="section-title-container">
				<h6 className="section-title">{tableName}</h6>
				<span className="section-count"><p>{collection.length}</p></span>
				<span
					className={classNames('accordion-icon icon-only icon-chevron-down', {
						open: showTable,
					})}
					{...HandleEvents(() => setShowTable(oldValue => !oldValue))}
				/>
			</div>
			<If condition={showTable}>
				<EntityList
					collection={shortenedCollection}
					columns={columns}
					sortColumn={sortColumn}
					sortDescending={sortDescending}
					idColumn={idColumn}
					onClickRow={onClickRow}
					rowClassName={rowClassName}
				/>
				<section className="collection__load">
					<TablePagination
						perPage={ALARES_ITEMS_PER_PAGE}
						pageNo={pageNo}
						totalRecords={collection.length}
						onPageChange={setPageNo}
					/>
				</section>
			</If>
		</div>
	);
}

export default AdverseAlertsTable;

export const ALARES_ITEMS_PER_PAGE = 3;