import * as React from 'react';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';

export type FileDownloadLinkProps = {
	fileId?: string,
	fallbackValue?: string,
}

// Copied from AttributeFile.tsx, since it's not exported there
interface FileMetadata {
	id: string;
	created: string;
	modified: string;
	fileName: string;
	contentType: string;
	length: number;
}

const FileDownloadLink = (props: FileDownloadLinkProps) => {
	const { fileId, fallbackValue } = props;

	const fileMetadata = useAsync(async () => {
		if (!fileId) {
			return null;
		}

		return axios.get(`${SERVER_URL}/api/files/metadata/${fileId}`)
			.then(x => x.data as FileMetadata);
	}, [fileId]);

	if (!fileId) {
		return <span className="file-download-link no-file">{fallbackValue ?? '-'}</span>;
	}

	if (fileMetadata.type !== 'data' || fileMetadata.data === null) {
		return null;
	}

	return (
		<a
			className="file-download-link"
			href={`${SERVER_URL}/api/files/${fileId}`}
			target="_blank"
			rel="noreferrer"
		>
			{fileMetadata.data.fileName}
		</a>
	);
};
export default FileDownloadLink;
