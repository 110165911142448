import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { CreditBureauEntity, OrganisationAuthorisedCreditBureau } from 'Models/Entities';

import { CheckboxGroup } from 'Views/Components/Checkbox/CheckboxGroup';
import CheckboxSetter from 'Views/Components/Checkbox/CheckboxSetter';
import React from 'react';
import { WizardComponentProps } from '../Wizard';
import { action } from 'mobx';
import { observer } from 'mobx-react';

const IntelDisclosingStep:React.FunctionComponent<WizardComponentProps> = observer(props => {
	const {
		model, changePage, currentStep,
	} = props;

	const handleChange = () => {
		if (model.organisation.intelEnabled) {
			if (model && model.businessEntitys && model.businessEntitys[0]) {
				model.businessEntitys[0].enableMonitorProduct();
			}
		}
		changePage();
	};

	const renderCheckboxes = () => {
		const checkboxes: JSX.Element[] = [];
		CreditBureauEntity.creditBureausSync.forEach(bureau => {
			checkboxes.push(
				<CheckboxSetter
					value={model.organisation.authorisedCreditBureaus.some(
						(x: OrganisationAuthorisedCreditBureau) => x.authorisedCreditBureauId === bureau.id,
					)}
					onChecked={action(event => {
						if (event.target.checked) {
							model.organisation.authorisedCreditBureaus.push(
								new OrganisationAuthorisedCreditBureau({ authorisedCreditBureauId: bureau.id }),
							);
						} else {
							const index = model.organisation.authorisedCreditBureaus.findIndex(
								(x: OrganisationAuthorisedCreditBureau) => x.authorisedCreditBureauId === bureau.id,
							);
							if (index !== -1) {
								model.organisation.authorisedCreditBureaus.splice(index, 1);
							}
						}
					})}
					label={bureau.name}
					key={bureau.id}
				/>,
			);
		});

		return checkboxes;
	};

	return (
		<div className="step-wrap">
			<div className="step-contents registration-step intel-disclosing-step align-one-third">
				<div className="alignment-box">
					<h5 className="step-header">
						Create your risk score profile
					</h5>
					<div className="text-container">
						<p className="center-text">
							Access Intell aggregates data from multiple sources to deliver the best risk insights. Our
							payment risk scores require payment data to be uploaded and can only be provided by one
							source at a time.
						</p>
					</div>
					<div className="text-container">
						<p className="center-text">
							Please note - this can be changed at any time.
						</p>
					</div>
					<div className="checkboxgroup-container">
						<CheckboxGroup
							label="Please identify where you would like us to obtain your payment risk scores."
							className="authorised-applications"
						>
							{renderCheckboxes()}
						</CheckboxGroup>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={handleChange}
				>
					{!!currentStep.options?.showSubmit ? 'Submit' : 'Next'}
				</Button>
			</div>
		</div>
	);
});

export default IntelDisclosingStep;
