import React from 'react';
import { observer } from 'mobx-react';
import {
	Route, Router, RouteComponentProps, Switch,
} from 'react-router-dom';
import ESignPaths from 'ESign/Router/ESignPaths';
import ESignUserPage from 'ESign/Pages/ESignUserPage';
import { createBrowserHistory } from 'history';
import PageNotFound from 'Views/Pages/PageNotFound';

const ESignRoutes: React.FunctionComponent<RouteComponentProps> = observer(props => {
	return (
		<Router history={props.history}>
			<Switch>
				<Route path={ESignPaths.user} component={ESignUserPage} />
				<Route component={PageNotFound} />
			</Switch>
		</Router>
	);
});

export const routerHistory = createBrowserHistory();

export default ESignRoutes;
