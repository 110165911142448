/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import _ from 'lodash';
// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

// % protected region % [Customise types here] off begin
export type transformFunction = (attr: string) => IStandardisedOption | null;
export interface IStandardisedOption {
	query: string | string[];
	extraOptions?: { [option: string]: any };
}
// % protected region % [Customise types here] end

// % protected region % [Customise standardiseDate here] on begin
export type standardiseDateOptions = {
	/** How should the date time be serialised. If nothing is selected then default to dateTime */
	serialiseAs?: 'date' | 'time' | 'dateTime' | 'dateTimeWithoutTz',
}

/**
 * Converts a date string to a date string in ISO8601 format.
 * @param attr The date string
 * @param serialiseAs What date format should be used.
 * If this value is 'dateTime' then format is YYYY-MM-DDTHH:mm:ss
 * If this value is 'date' then format is YYYY-MM-DD
 * If this value is 'time' then format is HH:mm:ss
 * @returns A date string in the specified date format or null if the provided date was not valid
 */
export function standardiseDate(
	attr: string,
	{
		serialiseAs = 'dateTime',
	}: standardiseDateOptions = {},
): IStandardisedOption | null {
	if (attr === undefined || attr === null) {
		return null;
	}

	const formats = [
		'DD-MM-YYYY 00:00:00',
		'DD-MM-YYYY HH:mm:ss',
		'DD/MM/YYYY 00:00:00',
		'DD/MM/YYYY HH:mm:ss',
		'YYYY-MM-DD 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
		'YYYY/MM/DD 00:00:00',
		'YYYY/MM/DD HH:mm:ss',
		'MM-DD-YYYY 00:00:00',
		'MM-DD-YYYY HH:mm:ss',
		'MM/DD/YYYY 00:00:00',
		'MM/DD/YYYY HH:mm:ss',
	];
	const momentDate = moment(attr, formats);

	// Some invalid dates won't be marked invalid but just exist in year 0
	if (!momentDate.isValid() || momentDate.year() === 0) {
		return null;
	}

	const dateOnly = momentDate.hours() === 0
		&& momentDate.minutes() === 0
		&& momentDate.seconds() === 0;

	if (serialiseAs === 'date') {
		const query = serialiseDate(momentDate);
		if (query) {
			return { query };
		}
	} else if (serialiseAs === 'time') {
		const query = serialiseTime(momentDate);
		if (query) {
			return { query };
		}
	} else if (serialiseAs === 'dateTime' || serialiseAs === 'dateTimeWithoutTz') {
		const startDate = serialiseAs === 'dateTime'
			? serialiseDateTime(momentDate)
			: serialiseDateTimeNoTz(momentDate);
		const endDate = serialiseAs === 'dateTime'
			? serialiseDateTime(moment(momentDate).add(1, 'day'))
			: serialiseDateTimeNoTz(moment(momentDate).add(1, 'day'));

		if (startDate === null || endDate === null) {
			return null;
		}

		if (dateOnly) {
			return {
				query: [startDate, endDate],
				extraOptions: {
					comparison: 'between',
				},
			};
		}

		return { query: startDate };
	}

	return null;
}
// % protected region % [Customise standardiseDate here] end

// % protected region % [Customise standardiseInteger here] off begin
/**
 * Determines if an input is an int for the purposes of search
 * @param attr The query string to check if it is an int
 */
export function standardiseInteger(attr: string): IStandardisedOption | null {
	const value = Number(attr);
	if (Number.isNaN(value) || !Number.isInteger(value)) {
		return null;
	}

	const maxInt = 2147483647;
	const minInt = -2147483648;

	if (value > maxInt || value < minInt) {
		return null;
	}

	return { query: attr };
}
// % protected region % [Customise standardiseInteger here] end

// % protected region % [Customise standardiseFloat here] off begin
/**
 * Determines if an input is an float for the purposes of search
 * @param attr The query string to check if it is a float
 */
export function standardiseFloat(attr: string): IStandardisedOption | null {
	if (Number.isNaN(Number(attr))) {
		return null;
	}

	return { query: attr };
}
// % protected region % [Customise standardiseFloat here] end

// % protected region % [Customise standardiseBoolean here] off begin
/**
 * Determines if an input is an bool for the purposes of search
 * @param attr The query string to check if it is a bool
 */
export function standardiseBoolean(attr: string): IStandardisedOption | null {
	if (['true', 'false'].indexOf(attr) >= 0) {
		return { query: attr };
	}

	return null;
}
// % protected region % [Customise standardiseBoolean here] end

// % protected region % [Customise standardiseString here] off begin
/**
 * Returns a search query for a string that is case insensitive
 * @param attr The string to search for
 */
export function standardiseString(attr: string): IStandardisedOption | null {
	return {
		query: `%${attr}%`,
		extraOptions: {
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
		},
	};
}
// % protected region % [Customise standardiseString here] end

// % protected region % [Customise standardiseUuid here] off begin
/**
 * Returns a search query for a complete Uuid
 * @param attr The string to search for
 */
export function standardiseUuid(attr: string): IStandardisedOption | null {
	const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
	if (!attr.match(regex)) {
		return null;
	}

	return { query: attr };
}
// % protected region % [Customise standardiseUuid here] end

// % protected region % [Customise standardiseEnum here] off begin
/**
 * Returns a search query for a string that is case insensitive
 * @param attr The string to search for
 * @param enumOptions The enum options to sort through
 */
export function standardiseEnum(attr: string, enumOptions: Record<string, string>): IStandardisedOption | null {
	const enumKey = _.invert(enumOptions)[attr];
	if (!enumKey) {
		return null;
	}

	return { query: enumKey };
}
// % protected region % [Customise standardiseEnum here] end

// % protected region % [Customise serialiseDate here] on begin
/**
 * Serialise a date object as a date only string.
 * @param attr The date to serialise
 * @returns A string in the format YYYY-MM-DD
 */
export function serialiseDate(attr: string | Date | moment.Moment) {
	if (attr === undefined || attr === null) {
		return null;
	}

	return moment(attr).format('YYYY-MM-DD');
}
// % protected region % [Customise serialiseDate here] end

// % protected region % [Customise serialiseTime here] on begin
/**
 * Serialise a date object as a time only string.
 * @param attr The date to serialise
 * @returns A string in the format HH:mm:ss
 */
export function serialiseTime(attr: string | Date | moment.Moment) {
	if (attr === undefined || attr === null) {
		return null;
	}

	return moment(attr).format('HH:mm:ss');
}
// % protected region % [Customise serialiseTime here] end

// % protected region % [Customise serialiseDateTime here] on begin
/**
 * Serialise a date object as a date time string.
 * @param attr The date to serialise
 * @returns A string in the format YYYY-MM-DDTHH:mm:ssZ
 */
export function serialiseDateTime(attr: string | Date | moment.Moment) {
	if (attr === undefined || attr === null) {
		return null;
	}

	return moment(attr).format('YYYY-MM-DDTHH:mm:ssZ');
}
// % protected region % [Customise serialiseDateTime here] end

// % protected region % [Customise serialiseDateTimeNoTz here] on begin
/**
 * Serialise a date object as a date time string without a timezone.
 * @param attr The date to serialise
 * @returns A string in the format YYYY-MM-DDTHH:mm:ss
 */
export function serialiseDateTimeNoTz(attr: string | Date | moment.Moment) {
	if (attr === undefined || attr === null) {
		return null;
	}

	return moment(attr).format('YYYY-MM-DDTHH:mm:ss');
}
// % protected region % [Customise serialiseDateTimeNoTz here] end

// % protected region % [Add extra AttributeUtils methods here] on begin
// function to correctly display a phone number
export function standardisePhone(attr: string) : string {
	const phoneNoSpaces = attr.replace(/\s+/g, '');
	// to make the form 3333 3333
	if (phoneNoSpaces.length === 8) {
		return `${phoneNoSpaces.substring(0, 4)} ${phoneNoSpaces.substring(4, 8)}`;
	}
	// to make the form 0400 000 000
	if (phoneNoSpaces.length === 10 && phoneNoSpaces.startsWith('04')) {
		return `${phoneNoSpaces.substring(0, 4)} ${phoneNoSpaces.substring(4, 7)} ${phoneNoSpaces.substring(7, 10)}`;
	}
	// to make the form 07 3333 3333
	if (phoneNoSpaces.length === 10) {
		return `${phoneNoSpaces.substring(0, 2)} ${phoneNoSpaces.substring(2, 6)} ${phoneNoSpaces.substring(6, 10)}`;
	}
	// to make the form +61 468 484 278
	if (phoneNoSpaces.startsWith('+') && phoneNoSpaces.length === 12) {
		return `${phoneNoSpaces.substring(0, 3)} ${phoneNoSpaces.substring(3, 6)} `
			+ `${phoneNoSpaces.substring(6, 9)} ${phoneNoSpaces.substring(9, 12)}`;
	}
	// just return the number we received if doesn't match above
	return attr;
}
// % protected region % [Add extra AttributeUtils methods here] end
