import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import classNames from 'classnames';
import { action } from 'mobx';
import { store } from 'Models/Store';
import If from '../../If/If';

const ProductsStep = observer((props: WizardComponentProps) => {
	const {
		model, changePage,
	} = props;

	return (
		<div className="step-wrap">
			<div className="step-contents align-one-third products-step">
				<div className="alignment-box">
					<h5 className="step-header">Which products are you interested in?</h5>
					<p className="step-description">
						Select all that you&apos;re interested in, and we&apos;ll calculate your monthly subscription
						costs.
					</p>
					<p className="alert-box info">
						Select all three products and enjoy 20% off and gain access to more features
					</p>
					<div className="product-selector-wrap">
						<div className="product-selector approve">
							<a
								className="product-link"
								href={store.appSettings.approveMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access Approve"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<h6 className="description-title">Applications</h6>
									<p className="description-text">
										Receive digital trade applications with verified data and approve new customers
										with confidence in seconds rather than days.
									</p>
									<p className="description-subtext">
										Downloadable credit reports priced separately as marked.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>6 Minutes</p>
									</div>
									<Button
										className={classNames('product-select-btn', {
											selected: model.organisation?.approveEnabled,
										})}
										colors={Colors.Primary}
										display={Display.Solid}
										onClick={action(() => {
											model.organisation.approveEnabled = model.organisation.approveEnabled
												!== true;
										})}
									>
										{model.organisation.approveEnabled ? 'Selected' : 'Select Product'}
									</Button>
								</div>
							</div>
						</div>
						<div className="product-selector ppsr">
							<a
								className="product-link"
								href={store.appSettings.ppsrMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access PPSR"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<h6 className="description-title">PPSR</h6>
									<p className="description-text">
										Correctly process PPSR registrations with one click and easily manage your
										entire portfolio of security interests in one place with full visibility.
									</p>
									<p className="description-subtext">
										Registration processing fees as indicated.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>4 Minutes</p>
									</div>
									<Button
										className={classNames('product-select-btn', {
											selected: model.organisation.ppsrEnabled,
										})}
										colors={Colors.Primary}
										display={Display.Solid}
										onClick={action(() => {
											model.organisation.ppsrEnabled = model.organisation.ppsrEnabled !== true;
										})}
									>
										{model.organisation.ppsrEnabled ? 'Selected' : 'Select Product'}
									</Button>
								</div>
							</div>
						</div>
						<div className="product-selector intel">
							<a
								className="product-link"
								href={store.appSettings.monitorMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access Monitor"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<h6 className="description-title">Monitor</h6>
									<p className="description-text">
										Automatically receive real time credit scores on all customers.  Easily connect
										your accounting system or upload your customer data and understand your credit
										risk at a glance.
									</p>
									<p className="description-subtext">
										Downloadable credit reports priced separately as marked.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>2 Minutes</p>
									</div>
									<Button
										className={classNames(
											'product-select-btn',
											{ selected: model.organisation.intelEnabled },
										)}
										colors={Colors.Primary}
										display={Display.Solid}
										onClick={action(() => {
											/* eslint-disable-next-line max-len */
											model.organisation.intelEnabled = model.organisation.intelEnabled !== true;
										})}
									>
										{model.organisation.intelEnabled ? 'Selected' : 'Select Product'}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
			</div>
		</div>
	);
});
export default ProductsStep;
