import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
import * as React from 'react';
import HandleEvents from 'Util/HandleEvents';
import { store } from 'Models/Store';

// Used if you want to use the global modal to show this, then have it hide itself automatically
export default function ShowProgressSavedModalPasscode(email: string) {
	const onCloseModal = () => {
		store.modal.hide();
	};

	const progressSavedModalPasscode = (
		<div className="update-modal">
			<div className="update-form">
				<h5>Progress Saved</h5>
				<p>
					An email has been sent to <b>{email}</b> containing your link and passcode so
					you can complete your registration at a later date
				</p>

				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirm-pwd-buttons">
					<Button
						type="button"
						className="cancel-btn"
						display={Display.Outline}
						sizes={Sizes.Medium}
						{...HandleEvents(onCloseModal)}
					>
						Close
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);

	store.modal.show('Progress saved', progressSavedModalPasscode, {
		className: 'access-modal progress-saved-modal-passcode',
		onRequestClose: onCloseModal,
	});
}
