import * as React from 'react';
import { Button, Colors, Display } from '../Components/Button/Button';
import { store } from '../../Models/Store';

interface ProductMonitorComingSoonProps {
}

const ProductMonitorComingSoon = (props: ProductMonitorComingSoonProps) => {
	return (
		<div className="cant-access-intel">
			<div className="content">
				<h1>The Access Monitor portal is currently not available.</h1>
				<p>Access Monitor is currently in the final stages of development and will be available soon.</p>
				<div className="buttons">
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.monitorMarketingUrl, '_blank');
						}}
						className="ppsr-setup"
					>
						Learn More About Monitor
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ProductMonitorComingSoon;
