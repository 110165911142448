import { CustomerEntity } from 'Models/Entities';
import { Dictionary } from 'Util/CustomTypes';
import { formatAbn } from 'Util/StringUtils';
import riskScoreBox from 'Views/Components/Intel/RiskScoreBox';
import { ReactNode } from 'react';
import { riskScoreOptions } from 'Models/Enums';
import WarningsCount from 'Views/Components/Intel/WarningsCount';
import classNames from 'classnames';
import { store } from 'Models/Store';

export interface CustomerColumnType {
	defaultDisplayName: string,
	gqlSnippet: string,
	displayFunction: (customer: CustomerEntity) => ReactNode | string,
	stringDisplayFunction?: (customer: CustomerEntity) => string,
	sortColumn?: string,
	tooltip?: string,
	onClickValue?: (customer: CustomerEntity) => void,
}

function getNewestATBGraphqlSnippet(field : string) {
	return `atbRecords (orderBy: [{path: "atbFile.targetMonth", descending:true}, {path: "atbFile.modified",
				descending: true}], take: 1, where: [{path: "atbFile.atbProcessingStatus", value: ["PROCESSED"]}]) {
			id
			${field}
		}
	`;
}

function getMonitoredGqlSnippet(field: string) {
	return `
		monitoredss {
			monitoreds {
				id
				${field}
			}
		}
	`;
}

function getAtoDebtGqlSnippet(field: string){
	const atoDebt = `
		atoDebt {
			id
			${field}
		}
	`;
	return getMonitoredGqlSnippet(atoDebt);
}



const CustomerColumnTypes = {
	atoDebt: {
		defaultDisplayName: 'ATO Debt',
		gqlSnippet: getAtoDebtGqlSnippet('amount'),
		displayFunction: (customer: CustomerEntity) => {
			const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitoredCustomers.length === 0) {
				return '$0';
			}

			const atoDebts = monitoredCustomers.map(m => m.atoDebt).filter(m => !!m);
			if (atoDebts.length === 0) {
				return '$0';
			}

			const amount = atoDebts?.map(m => m?.amount ?? 0).reduce((a, b) => a + b, 0);
			return `$${amount}`;
		},
		tooltip: 'This is the total amount of debt owed to the ATO',
	},
	debtorID: {
		defaultDisplayName: 'Debtor ID',
		gqlSnippet: 'debtorID',
		displayFunction: (customer: CustomerEntity) => customer.debtorID,
		sortColumn: 'debtorID',
	},
	businessName: {
		defaultDisplayName: 'Business Name',
		gqlSnippet: 'businessName',
		displayFunction: (customer: CustomerEntity) => customer.businessName,
		sortColumn: 'businessName',
	},
	abn: {
		defaultDisplayName: 'ABN',
		gqlSnippet: 'abn',
		displayFunction: (customer: CustomerEntity) => formatAbn(customer.abn),
		sortColumn: 'abn',
	},
	entityStatus: {
		defaultDisplayName: 'Entity status',
		gqlSnippet: 'businessEntityStatus',
		displayFunction: (customer: CustomerEntity) => (
			<span className={classNames('entity-status-icon', {
				valid: customer.businessEntityStatus,
				invalid: !customer.businessEntityStatus,
			})}
			/>
		),
		stringDisplayFunction: (customer: CustomerEntity) => customer.businessEntityStatus ? 'valid' : 'invalid',
		sortColumn: 'businessEntityStatus',
	},
	businessEntities: {
		defaultDisplayName: 'Business Entity',
		gqlSnippet:
			`businessEntityId
			businessEntity {
				id
				name
			}`,
		displayFunction: (customer: CustomerEntity) => customer.businessEntity.name,
		tooltip: 'Supplying entity',
	},
	groups: {
		defaultDisplayName: 'Groups',
		gqlSnippet:
			`groupss {
				id
				groups {
					id
					name
				}
			}`,
		displayFunction: (customer: CustomerEntity) => customer.groupss.map(g => g.groups.name).join(', '),
		tooltip: 'Attached groups',
	},
	insolvencyRiskRating: {
		defaultDisplayName: 'Insolvency Risk Rating',
		gqlSnippet: `
			newestAlares {
				id
				insolvencyRiskRating
			}
		`,
		displayFunction: (customer: CustomerEntity) => riskScoreBox({
			score: customer.newestAlares?.insolvencyRiskRating,
		}),
		stringDisplayFunction: (customer: CustomerEntity) => (
			riskScoreOptions[customer.newestAlares?.insolvencyRiskRating || 'UNKNOWN']),
		sortColumn: 'insolvencyRiskRating',
		tooltip: 'This is the probability of the customer becoming insolvent in the coming 12 months',
	},
	adverseAlerts: {
		defaultDisplayName: 'Adverse Alerts',
		gqlSnippet: `
			newestAlares {
				id
				adverseAlerts
			}
		`,
		displayFunction: (customer: CustomerEntity) => WarningsCount({
			count: customer?.newestAlares?.adverseAlerts,
		}),
		stringDisplayFunction: (customer: CustomerEntity) => customer?.newestAlares?.adverseAlerts.toString(),
		sortColumn: 'adverseAlerts',
		tooltip: 'This links to details of events or information which indicates risk such as court action',
		onClickValue: customer => {
			store.routerHistory
				.push(`/monitor/customers/${customer.id}`,
					{ navigateTo: 'adverse-alerts' });
		},
	},
	paymentRiskRating: {
		defaultDisplayName: 'Payment Risk Rating',
		gqlSnippet: 'paymentRiskRating',
		displayFunction: (customer: CustomerEntity) => riskScoreBox({
			score: customer.paymentRiskRating ?? 'UNKNOWN',
		}),
		stringDisplayFunction: (customer: CustomerEntity) => (
			riskScoreOptions[customer.paymentRiskRating ?? 'UNKNOWN']),
		sortColumn: 'customPaymentRiskRating',
		tooltip: 'Payment Risk Rating updated on 15th of each month based on the most recent ATB data. If you don’t upload your ATB the risk indicator may not be current or be displayed.',
	},
	totalDebt: {
		defaultDisplayName: 'Total Outstanding',
		gqlSnippet: getNewestATBGraphqlSnippet('totalDebt'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].totalDebt ?? 0}`
			: '',
		sortColumn: 'atbFile totalOutstanding',
		tooltip: 'This is the total amount owed',
	},
	totalOverdue: {
		defaultDisplayName: 'Total Overdue',
		gqlSnippet: getNewestATBGraphqlSnippet('totalOverdue'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].totalOverdue ?? 0}`
			: '',
		sortColumn: 'atbFile totalOverdue',
		tooltip: 'This is the total amount owing that is beyond agreed payment terms',
	},
	currentDebt: {
		defaultDisplayName: 'Current Debt',
		gqlSnippet: getNewestATBGraphqlSnippet('dpcurrent'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dpcurrent ?? 0}`
			: '',
		sortColumn: 'atbFile currentDebt',
		tooltip: 'This is the amount owing that is not past its due date',
	},
	dp1to30: {
		defaultDisplayName: '1-30 DPD',
		gqlSnippet: getNewestATBGraphqlSnippet('dp1to30'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dp1to30 ?? 0}`
			: '',
		sortColumn: 'atbFile dp1to30',
		tooltip: 'This is the amount that is between 1 day and 30 days overdue',
	},
	dp31to60: {
		defaultDisplayName: '31-60 DPD',
		gqlSnippet: getNewestATBGraphqlSnippet('dp31to60'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dp31to60 ?? 0}`
			: '',
		sortColumn: 'atbFile dp31to60',
		tooltip: 'This is the amount that is between 31 days and 60 days overdue',
	},
	dp61to90: {
		defaultDisplayName: '61-90 DPD',
		gqlSnippet: getNewestATBGraphqlSnippet('dp61to90'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dp61to90 ?? 0}`
			: '',
		sortColumn: 'atbFile dp61to90',
		tooltip: 'This is the amount that is between 61 days and 90 days overdue',
	},
	dp91to120: {
		defaultDisplayName: '91-120 DPD',
		gqlSnippet: getNewestATBGraphqlSnippet('dp91to120'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dp91to120 ?? 0}`
			: '',
		sortColumn: 'atbFile dp91to120',
		tooltip: 'This is the amount that is between 91 days and 120 days overdue',
	},
	dp120plus: {
		defaultDisplayName: '120+ DPD',
		gqlSnippet: getNewestATBGraphqlSnippet('dp120plus'),
		displayFunction: (customer: CustomerEntity) => customer.atbRecords.length > 0
			? `$${customer.atbRecords[0].dp120plus ?? 0}`
			: '',
		sortColumn: 'atbFile dp120plus',
		tooltip: 'This is the amount that is more than 120 days overdue',
	},
} as Dictionary<CustomerColumnType>;
export default CustomerColumnTypes;
