import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { CustomerAuditEntity, CustomerEntity } from 'Models/Entities';
import { gql } from '@apollo/client';
import {
	BusinessEntity,
	IBusinessEntityAttributes,
} from 'Models/Entities';
import { Button, Display } from 'Views/Components/Button/Button';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import Modal from 'Views/Components/Modal/Modal';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import { runInAction } from 'mobx';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import {
	CreateCustomerAudit,
	GetCustomerSingleAuditDescriptionFromString,
} from 'Views/Components/Intel/Customer/CustomerAudits/Gibs/FetchCustomerAudits';
import alertToast from 'Util/ToastifyUtils';
import useStore from 'Hooks/useStore';
import useHasChanged from 'Hooks/useHasChanged';

export type EditCustomerBusinessEntityModalProps = {
	customer: CustomerEntity,
	errors?: string | string[],
	onClose: () => void,
}

const EditCustomerBusinessEntityModal = observer((props: EditCustomerBusinessEntityModalProps) => {
	const {
		customer,
		errors,
		onClose,
	} = props;

	const store = useStore();

	const [privateCustomer, setPrivateCustomer] = useState(new CustomerEntity(customer));

	const saveDisable = useHasChanged(
		CustomerEntity,
		privateCustomer,
		['businessEntityId'],
		(oldValue, newValue) => oldValue !== newValue && !(!oldValue && !newValue),
		['errors'],
		(original, changed) => (Object.keys(changed?.errors ?? {}).length > 0),
	);

	const getBusinessEntityName = async (businessEntityId: string): Promise<string> => {
		try {
			const businessEntityNameResult = await store.apolloClient.query({
				query: gql`
					query fetchBusinessEntityById($beId: ID) {
						businessEntity(id: $beId) {
							name
						}
					}
				`,
				variables: {
					beId: businessEntityId,
				},
				fetchPolicy: 'no-cache',
			});
			return businessEntityNameResult.data.businessEntity.name;
		} catch (e) {
			return '';
		}
	};

	const getBusinessEntityOptions = async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		try {
			const businessEntitysResult = await store.apolloClient
				.query({
					query: gql`
						query fetchBusinessEntitys($searchTerm: String) {
							businessEntitys(
								where: [{
									path: "name",
									comparison: like,
									value: [$searchTerm],
									case: CURRENT_CULTURE_IGNORE_CASE
								},
								{
									path: "organisationId"
									comparison: equal,
									value: ["${customer.organisationId}"],
								}],
								orderBy: [{path: "name", descending: false}],
							) {
								id
								name
							}
						}
					`,
					variables: {
						searchTerm: !!searchTerm ? `%${searchTerm}%` : '%',
					},
					fetchPolicy: 'no-cache',
				});

			return businessEntitysResult.data.businessEntitys
				.map((x: Partial<IBusinessEntityAttributes> | undefined) => {
					const businessEntity = new BusinessEntity(x);
					return {
						display: businessEntity.name,
						value: businessEntity.id,
					};
				});
		} catch (e) {
			return [];
		}
	};

	const submit = async () => {
		try {
			if (store.userType !== 'SUPER_USER') {
				alertToast('Could not save changes to business entity', 'error');
				return;
			}

			if (privateCustomer.atbRecords.length > 0) {
				alertToast('Cannot change business entity - Customer has ATB Records attached');
				return;
			}

			privateCustomer.validateField('businessEntity');

			if (Object.keys(privateCustomer.errors).length !== 0) {
				alertToast('Could not save changes to business entity', 'error');
				return;
			}

			const originalBusinessEntityName = await getBusinessEntityName(customer.businessEntityId);
			const newBusinessEntityName = await getBusinessEntityName(privateCustomer.businessEntityId);

			privateCustomer.customerAuditss = [];
			const customerAuditsDescription = GetCustomerSingleAuditDescriptionFromString(
				originalBusinessEntityName,
				newBusinessEntityName,
				'Business entity',
			);

			if (customerAuditsDescription !== undefined) {
				const newCustomerAudit = CreateCustomerAudit(
					customer.id,
					'OTHER',
					customerAuditsDescription,
				);
				privateCustomer.customerAuditss.push(newCustomerAudit);
			}

			privateCustomer.saveWithFetchBack(
				{
					customerAuditss: {},
				},
				{},
				`
					customerAuditss {
						${CustomerAuditEntity.getAttributes().join('\n')}
					}
				`,
			);

			alertToast('Customer business entity successfully updated', 'success');
			onClose();
		} catch {
			alertToast('Could not save changes to customer business entity', 'error');
		}
	};

	return (
		<Modal
			isOpen
			label="Change Business Entity"
			onRequestClose={onClose}
			className="access-modal modal-overflow"
		>
			<h4>Change Business Entity</h4>
			<ComboboxSetter
				className={classNames('businessentity-selector')}
				label="Business Entity"
				value={privateCustomer.businessEntityId}
				setValue={value => {
					runInAction(() => {
						privateCustomer.businessEntityId = value;
					});
				}}
				getOptionValue={(value?: string) => value}
				initialOptions={getBusinessEntityOptions}
				options={AwesomeDebouncePromise(getBusinessEntityOptions, 250)}
				searchable
				errors={errors}
				key={privateCustomer.organisationId}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button key="confirm" onClick={submit} display={Display.Solid} disabled={saveDisable}>Save</Button>
			</div>
		</Modal>
	);
});
export default EditCustomerBusinessEntityModal;
