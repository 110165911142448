import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
	Grid, GridColumn, GridRow, Icon, Segment, Tab, TabPane,
} from 'semantic-ui-react';

type Props = {
	signature: string;
	updateSignature: (signature: string) => void;
}
const InputSignature: React.FunctionComponent<Props> = ({ signature, updateSignature }) => {
	const [parentWindowWidth, setParentWindowWidth] = useState<number>(0);
	const sigCanvasRef = useRef<SignatureCanvas>(null);

	const handleOnEnd = () => {
		const newSignatureDataUrl = sigCanvasRef.current?.getTrimmedCanvas()
			.toDataURL('image/png');
		updateSignature(newSignatureDataUrl ?? '');
	};

	const handleOnClear = () => {
		sigCanvasRef.current?.clear();
		updateSignature('');
	};

	useEffect(() => {
		setParentWindowWidth(document.getElementById('signature-canvas-col')?.offsetWidth ?? 400);
	}, [parentWindowWidth]);

	const renderTabPanes = () => {
		const panes = [
			{
				menuItem: 'Draw Signature',
				render: () => (
					<TabPane>
						<Grid textAlign="center" centered stackable>
							<GridRow id="signature-canvas-col">
								<GridColumn width={16}>
									<SignatureCanvas
										ref={sigCanvasRef}
										canvasProps={{
											className: 'input-signature-canvas',
											width: Math.floor((parentWindowWidth) * 0.9),
											height: '200vh',
										}}
										penColor="black"
										onEnd={handleOnEnd}
									/>
								</GridColumn>
							</GridRow>
							<GridRow>
								<GridColumn width={16} textAlign="center">
									<Button
										display={Display.Text}
										sizes={Sizes.Medium}
										colors={Colors.Error}
										onClick={handleOnClear}
									>
										<Icon name="eraser" />
										Clear
									</Button>
								</GridColumn>
							</GridRow>
						</Grid>
					</TabPane>
				),
			},
		];

		if (signature.length > 0 && signature.startsWith('data:image/png;')) {
			panes.push({
				menuItem: 'Existing Signature',
				render: () => (
					<TabPane>
						<Segment textAlign="center" basic>
							<img
								src={signature}
								alt="signature"
								style={{ width: '40%', height: '24vh', padding: '30px' }}
							/>
						</Segment>
					</TabPane>
				),
			});
		}

		return panes;
	};

	return (
		<Grid centered>
			<GridRow>
				<GridColumn width={16}>
					<Tab
						basic="true"
						panes={renderTabPanes()}
					/>
				</GridColumn>
			</GridRow>
		</Grid>
	);
};

export default InputSignature;
