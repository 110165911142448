import * as React from 'react';
import { observer } from 'mobx-react';
import { BusinessEntity } from 'Models/Entities';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { CheckboxGroup } from 'Views/Components/Checkbox/CheckboxGroup';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import OrganisationalNumberBox from './OrganisationalNumberBox';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface PpsrFieldsProps {
	businessEntity: BusinessEntity,
	onAfterChange?: (validateSuccessful: boolean) => void,
}

const PpsrFields = observer((props: PpsrFieldsProps) => {
	const {
		businessEntity, onAfterChange,
	} = props;

	/**
	 * Handle for the change event for the inputs. This method will attempt to validate a given field
	 * iff there are existing errors. So that the user can immediately see when their input stops the error
	 * @param field The name of the field to be validated
	 * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
	 * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
	 */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateField(field);
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(businessEntity.errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};

	return (
		<div className="ppsr-registration-info">
			<TextField
				className="spg-name"
				model={businessEntity.spgss[0]}
				modelProperty="spgName"
				label="Existing SPG name? (If applicable)"
				placeholder="Existing SPG name"
			/>
			<TextField
				className="spg-number"
				model={businessEntity.spgss[0]}
				modelProperty="spgNumber"
				label="Existing SPG number? (If applicable)"
				placeholder="Existing SPG number"
			/>

			<TextField
				className="access-code"
				model={businessEntity.spgss[0]}
				modelProperty="accessCode"
				label="Existing access code? (If applicable)"
				placeholder="Access code"
			/>

			<OrganisationalNumberBox
				model={businessEntity.spgss[0]}
				modelProperty="organisationalNumbers"
			/>

			<TextField
				className="email"
				model={businessEntity.spgss[0]}
				modelProperty="email"
				label="PPSR email address (e.g. PPSR@example.com)"
				placeholder="PPSR email address (e.g. PPSR@example.com)"
				onChangeAndBlur={() => onChange('ppsrEmail', true)}
				onAfterChange={() => onChange('ppsrEmail')}
				errors={businessEntity.errors.ppsrEmail}
				isRequired
			/>

			<CheckboxGroup
				label="Type of business?"
			>
				<Checkbox
					model={businessEntity}
					modelProperty="hire"
					className="hire"
					label="Hire"
				/>

				<Checkbox
					model={businessEntity}
					modelProperty="supplyGnE"
					className="supplyGnE"
					label="Supply of Goods & Equipment"
				/>

				<Checkbox
					model={businessEntity}
					modelProperty="finance"
					className="finance"
					label="Finance"
				/>
			</CheckboxGroup>

			<TextArea
				model={businessEntity}
				className="summary"
				modelProperty="summary"
				label="Brief summary of your business"
				labelVisible
				placeholder="Summary of your business"
				isRequired
				onChangeAndBlur={() => onChange('summary', true)}
				onAfterChange={() => onChange('summary')}
				errors={businessEntity.errors.summary}
			/>
		</div>
	);
});
export default PpsrFields;
