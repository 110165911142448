import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import ReferralPartnerRegistration from 'Assets/Animations/referral-partner-registration.json';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import LottieIcon from 'Views/Components/Animations/LottieIcon';

const ReferralPartnerRegistrationIndex = observer((props: WizardComponentProps) => {
	const { changePage } = props;

	return (
		<div className="step-wrap referral-partner-registration-index">
			<div className="step-contents registration-step align-one-third">
				<div className="alignment-box">
					<LottieIcon autoplay width={250} height={200} animationData={ReferralPartnerRegistration} />
					<div className="step-header">
						<h5>Referral Partner Program</h5>
					</div>
					<div>
						<p>
							{ /* eslint-disable-next-line max-len */ }
							Sign up and set up your Access Intell referral partner account and receive great perks <br />
							for both you and your clients.
						</p>
					</div>
					<div>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								changePage();
							}}
							className="sign-up-now"
						>
							Sign Up Now
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
});

export default ReferralPartnerRegistrationIndex;
