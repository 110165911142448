import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import React from 'react';
import useAsync from 'Hooks/useAsync';
import { IWhereCondition } from '../ModelCollection/ModelQuery';

const fetchRegistrationData = async (statusField: string) => {
	const referralPartnerCount = gql`
		query registrationEntitys($completeCondition: [WhereExpressionGraph],
			$incompleteCondition: [WhereExpressionGraph]
		) {
			complete:countRegistrationEntitys (where: $completeCondition) {
				number
			}
			incomplete:countRegistrationEntitys (where: $incompleteCondition) {
				number
			}
		}
	`;

	const referralPartnerCountResult = await store.apolloClient.query({
		query: referralPartnerCount,
		fetchPolicy: 'network-only',
		variables: {
			completeCondition: [{ path: statusField, value: ['COMPLETE'] }],
			incompleteCondition: [{ path: statusField, value: ['INCOMPLETE'] }],
		},
	});

	return {
		countComplete: referralPartnerCountResult.data?.complete?.number || 0,
		countIncomplete: referralPartnerCountResult.data?.incomplete?.number || 0,
	};
};

const ReferralPartnersTotal = (props: {
	entityName: string,
	statusField: string,
}) => {
	const { entityName, statusField } = props;
	const data = useAsync(async () => fetchRegistrationData(statusField), []);

	return (
		<div className="referral-partner-count-ctr">
			<div className="col-1">
				<div className="referral-partner-label">Total {entityName}:</div>
				<div className="complete-count-ctr">
					<span className="complete-count">{data?.data?.countComplete ?? '-'}</span>
					<div className="complete-count-label">COMPLETE</div>
				</div>
				<div className="complete-count-ctr">
					<span className="incomplete-count">{data?.data?.countIncomplete ?? '-'}</span>
					<div className="incomplete-count-label">INCOMPLETE</div>
				</div>
			</div>
		</div>
	);
};

export default ReferralPartnersTotal;
