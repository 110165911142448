/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserInvoiceEntity from 'Models/Security/Acl/UserInvoiceEntity';
import VisitorsInvoiceEntity from 'Models/Security/Acl/VisitorsInvoiceEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import moment from 'moment';
import { LocaleFormatCurrency } from 'Util/StringUtils';
// % protected region % [Add any further imports here] end

export interface IInvoiceEntityAttributes extends IModelAttributes {
	referredBy: string;
	customerCount: number;
	monthlyExposure: number;
	usingApprove: boolean;
	usingMonitor: boolean;
	usingPPSR: boolean;
	userCount: number;
	discountPrice: number;
	finalPrice: number;
	lineItems: string;
	monthlyPrice: number;

	organisationId: string;
	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('InvoiceEntity', 'Invoice')
// % protected region % [Customise your entity metadata here] end
export default class InvoiceEntity extends Model
	implements IInvoiceEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserInvoiceEntity(),
		new VisitorsInvoiceEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Referred By'] off begin
	/**
	 * The name of the referral partner that referred this Organisation
	 */
	@observable
	@attribute<InvoiceEntity, string>()
	@CRUD({
		name: 'Referred By',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public referredBy: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referred By'] end

	// % protected region % [Modify props to the crud options here for attribute 'Customer Count'] off begin
	/**
	 * Customer Count
	 */
	@Validators.Integer()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'Customer Count',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public customerCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Customer Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Monthly Exposure'] off begin
	/**
	 * Monthly Exposure
	 */
	@Validators.Numeric()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'Monthly Exposure',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public monthlyExposure: number;
	// % protected region % [Modify props to the crud options here for attribute 'Monthly Exposure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Using Approve'] off begin
	/**
	 * Using Approve
	 */
	@observable
	@attribute<InvoiceEntity, boolean>()
	@CRUD({
		name: 'Using Approve',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public usingApprove: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Using Approve'] end

	// % protected region % [Modify props to the crud options here for attribute 'Using Monitor'] off begin
	/**
	 * Using Monitor
	 */
	@observable
	@attribute<InvoiceEntity, boolean>()
	@CRUD({
		name: 'Using Monitor',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public usingMonitor: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Using Monitor'] end

	// % protected region % [Modify props to the crud options here for attribute 'Using PPSR'] off begin
	/**
	 * Using PPSR
	 */
	@observable
	@attribute<InvoiceEntity, boolean>()
	@CRUD({
		name: 'Using PPSR',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public usingPPSR: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Using PPSR'] end

	// % protected region % [Modify props to the crud options here for attribute 'User Count'] off begin
	/**
	 * User Count
	 */
	@Validators.Integer()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'User Count',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public userCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'User Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Discount Price'] off begin
	/**
	 * Discount Price
	 */
	@Validators.Numeric()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'Discount Price',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public discountPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Discount Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Final Price'] off begin
	/**
	 * Final Price
	 */
	@Validators.Numeric()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'Final Price',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public finalPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Final Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Line Items'] off begin
	/**
	 * Line items that make up the Final Price
	 */
	@observable
	@attribute<InvoiceEntity, string>()
	@CRUD({
		name: 'Line Items',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lineItems: string;
	// % protected region % [Modify props to the crud options here for attribute 'Line Items'] end

	// % protected region % [Modify props to the crud options here for attribute 'Monthly Price'] off begin
	/**
	 * Monthly Price
	 */
	@Validators.Numeric()
	@observable
	@attribute<InvoiceEntity, number>()
	@CRUD({
		name: 'Monthly Price',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public monthlyPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Monthly Price'] end

	/**
	 * Invoice
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IInvoiceEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IInvoiceEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.referredBy !== undefined) {
				this.referredBy = attributes.referredBy;
			}
			if (attributes.customerCount !== undefined) {
				this.customerCount = attributes.customerCount;
			}
			if (attributes.monthlyExposure !== undefined) {
				this.monthlyExposure = attributes.monthlyExposure;
			}
			if (attributes.usingApprove !== undefined) {
				this.usingApprove = attributes.usingApprove;
			}
			if (attributes.usingMonitor !== undefined) {
				this.usingMonitor = attributes.usingMonitor;
			}
			if (attributes.usingPPSR !== undefined) {
				this.usingPPSR = attributes.usingPPSR;
			}
			if (attributes.userCount !== undefined) {
				this.userCount = attributes.userCount;
			}
			if (attributes.discountPrice !== undefined) {
				this.discountPrice = attributes.discountPrice;
			}
			if (attributes.finalPrice !== undefined) {
				this.finalPrice = attributes.finalPrice;
			}
			if (attributes.lineItems !== undefined) {
				this.lineItems = attributes.lineItems;
			}
			if (attributes.monthlyPrice !== undefined) {
				this.monthlyPrice = attributes.monthlyPrice;
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public listExpands = `
		organisation {
			id
			primaryBusinessEntity {
				id
				name
			}
		}
	`;

	public defaultExpands = `
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}

			primaryBusinessEntity {
				id
				name
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		const organisationName = this.organisation?.getDisplayName() ?? this.organisationId;
		return [
			moment(this.created).format('YYYY-MM'),
			LocaleFormatCurrency(this.finalPrice),
			organisationName,
		].join(' | ');
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(InvoiceEntity.prototype, 'created');
CRUD(modifiedAttr)(InvoiceEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
