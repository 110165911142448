import * as React from 'react';
import { store } from 'Models/Store';
import { Button, Display } from 'Views/Components/Button/Button';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import axios from 'axios';
import { SERVER_URL } from 'Constants';

interface AcceptedTermsModalProps {
	model: {
		acceptedTerms: boolean;
	},
	onSuccess: () => void,
}

const AcceptedTermsModal = observer((props: AcceptedTermsModalProps) => {
	const { model, onSuccess } = props;
	return (
		<React.Fragment key="access-modal">
			<h4>Updated terms & conditions</h4>
			<p>To continue using the application, review and agree to the updated terms & conditions.</p>
			<label htmlFor="accepted-terms" className="terms-label">
				<input
					type="checkbox"
					id="accepted-terms"
					onChange={action(() => {
						model.acceptedTerms = !model.acceptedTerms;
					})}
					checked={model.acceptedTerms}
					aria-describedby="terms-description"
				/>
				<span id="terms-description">
					I have read and agree to
					the <a href="/api/files/document/terms" target="_blank">Terms & Conditions</a>
				</span>
			</label>
			<div key="actions" className="modal__actions">
				<Button
					onClick={onSuccess}
					display={Display.Solid}
					disabled={!model.acceptedTerms}
				>
					Submit
				</Button>
			</div>
		</React.Fragment>
	);
});

const checkTermsAccepted = () => {
	// If user is not an organisation manager, or if their organisation has already accepted terms,
	// then we don't need to do anything
	const userResult = store.getUser;
	if (!userResult || userResult.userType !== 'ORGANISATION_MANAGER' || !userResult.termsUpdated) {
		return;
	}
	if (
		!!userResult.organisation?.acceptedterms
		&& userResult.organisation.acceptedterms > userResult.termsUpdated
	) {
		return;
	}

	// We're an organisation manager, and our organisation hasn't accepted the current terms. We show a modal to accept
	// them now.
	const onSuccess = () => {
		axios.post(`${SERVER_URL}/api/entity/OrganisationEntity/acceptTerms`, {
			organisationId: userResult.organisation?.id,
		});
		store.modal.hide();
	};

	const onCancel = () => {
		// Do nothing. The user can't close the modal by clicking outside of it.
	};

	const modalModel = observable({
		acceptedTerms: false as boolean,
	});

	const modalDom = (
		<AcceptedTermsModal model={modalModel} onSuccess={onSuccess} />
	);

	store.modal.show('Updated Terms & conditions', modalDom, {
		className: 'access-modal',
		onRequestClose: onCancel,
	});
};
export default checkTermsAccepted;
