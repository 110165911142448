import React from 'react';
import { ESignWitnessDetail } from 'ESign/Types/ESignWitnessDetail';
import { observer } from 'mobx-react';
import { useESignStore } from 'ESign/Stores/ESignStore';
import LoadableState from 'ESign/Types/ESignLoadableState';
import Spinner from 'Views/Components/Spinner/Spinner';
import { SigningDocumentContentDetail } from './ESignSigningSection';
import {
	Grid,
	GridColumn,
	GridRow, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow,
} from 'semantic-ui-react';

type Props = {
	templateContent: string;
	witnessDetails: ESignWitnessDetail | undefined;
}
const ESignReviewSection: React.FunctionComponent<Props> = ({
	templateContent,
	witnessDetails,

}) => {
	const { workflowStore: { loadingState } } = useESignStore();

	const readOnlyContent = (content: string): string => {
		const parsedContent = JSON.parse(content) as SigningDocumentContentDetail;
		const parser = new DOMParser();
		const sanitizedContent = parser.parseFromString(parsedContent.htmlString, 'text/html');

		const elements = Array.from(sanitizedContent.body.querySelectorAll('input'));

		elements.forEach(element => {
			element.setAttribute('readonly', 'true');
		});

		return sanitizedContent.documentElement.outerHTML;
	};

	if (loadingState === LoadableState.Pending) {
		return <Spinner />;
	}

	return (
		<Grid>
			<GridRow grow>
				<GridColumn width={16}>
					<div className="esign-ceremony-document-wrapper">
						<iframe
							className="esign-documents"
							title="hello"
							srcDoc={readOnlyContent(templateContent)}
							width="100%"
							height="500vh"
							referrerPolicy="same-origin"
							content="text/html"
						/>
					</div>
				</GridColumn>
			</GridRow>
			{ witnessDetails && (
				<GridRow centered className="esign-review-witness-section">
					<Table celled striped>
						<TableHeader>
							<TableRow>
								<TableHeaderCell colSpan="2">Witness Details</TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							<TableRow>
								<TableCell collapsing>Witness Full Name</TableCell>
								<TableCell>{witnessDetails.name}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell collapsing>Witness Email Address</TableCell>
								<TableCell>{witnessDetails.email}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell collapsing>Witness Mobile Number</TableCell>
								<TableCell>{witnessDetails.phone ?? 'N/A'}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</GridRow>
			)}
		</Grid>
	);
};

export default observer(ESignReviewSection);
