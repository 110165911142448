import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { TextField } from '../Components/TextBox/TextBox';
import { DisplayType } from '../Components/Models/Enums';
import { NumberTextField } from '../Components/NumberTextBox/NumberTextBox';
import { TextArea } from '../Components/TextArea/TextArea';
import { DatePicker } from '../Components/DatePicker/DatePicker';
import { TimePicker } from '../Components/TimePicker/TimePicker';
import { RadioButtonGroup } from '../Components/RadioButton/RadioButtonGroup';
import { Checkbox } from '../Components/Checkbox/Checkbox';
import { Alignment, ButtonGroup, Sizing } from '../Components/Button/ButtonGroup';
import {
	Button, Colors, Display, Sizes,
} from '../Components/Button/Button';
import Collection from '../Components/Collection/Collection';
import Tabs from '../Components/Tabs/Tabs';
import Modal from '../Components/Modal/Modal';
import alert from '../../Util/ToastifyUtils';
import { store } from 'Models/Store';
import { alertModal, confirmModal } from '../Components/Modal/ModalUtils';
import { DateRangePicker } from '../Components/DateRangePicker/DateRangePicker';
import { Combobox } from '../Components/Combobox/Combobox';
import { MultiCombobox } from '../Components/Combobox/MultiCombobox';
import { CheckboxGroup } from '../Components/Checkbox/CheckboxGroup';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { DateTimeRangePicker } from '../Components/DateTimeRangePicker/DateTimeRangePicker';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';

const ElementsPage = observer(() => {
	const [elementState] = useState(() => observable({
		model: {
			email: 'admin@workingmouse.com.au',
			date: new Date(),
			range: [new Date(), new Date()],
		},
		modelEmpty: {},
		collectionSearch: '',
		collectionData: [
			{ id: '1', title: 'Hello 1', description: 'world 1' },
			{ id: '2', title: 'Hello 2', description: 'world 2' },
			{ id: '3', title: 'Hello 3', description: 'world 3' },
			{ id: '4', title: 'Hello 4', description: 'world 4' },
			{ id: '5', title: 'Hello 5', description: 'world 5' },
		],
		modalState: {
			firstOpen: false,
		},
	}));

	return (
		<div className="body-content">
			<div className="elements">
				<h3>Headings</h3>
				<h1>Heading 1</h1>
				<h2>Heading 2</h2>
				<h3>Heading 3</h3>
				<h4>Heading 4</h4>
				<h5>Heading 5</h5>
				<h6>Heading 6</h6>

				<h3>Text Tags</h3>
				<p className="txt-body">
					Just the regular old body text within the application. Just the regular old body
					text within the application. Just the regular old body text within the application. Just the regular
					old body text within the application. Just the regular old body text within the application. Just
					the regular old body text within the application. Just the regular old body text within the
					application.
				</p>
				<p className="txt-sm-body">
					The smaller body text within the application. The smaller body text within the
					application. The smaller body text within the application. The smaller body text within the
					application. The smaller body text within the application. The smaller body text within the
					application. The smaller body text within the application. The smaller body text within the
					application. The smaller body text within the application. The smaller body text within the
					application. The smaller body text within the application.
				</p>
				<p className="txt-lg-body">
					The larger body text within the application. The larger body text within the application. The
					larger body text within the application. The larger body text within the application.
					The larger body text within the application. The larger body text within the application. The larger
					body text within the application. The larger body text within the application. The larger body text
					within the application.
				</p>
				<p className="txt-italics">
					The text which will be italics within the application. The text which will be italics within the
					application. The text which will be italics within the application. The text which will be italics
					within the application. The text which will be italics within the application. The text
					which will be italics within the application. The text which will be italics within the application.
				</p>
				<p className="txt-bold">
					The text which will be bold within the application. The text which will be bold within the
					application. The text which will be bold within the application. The text which will be bold within
					the application. The text which will be bold within the application. The text which will be bold
					within the application. The text which will be bold within the application.
				</p>

				<h3>Lists</h3>
				<ol>
					<li>Ordered Item</li>
					<li>Ordered Item</li>
					<ul>
						<li>Unordered Item</li>
						<li>Unordered Item</li>
					</ul>
					<li>Ordered Item</li>
					<li>
						Ordered Item
						<ol>
							<li>Ordered Item</li>
							<li>Ordered Item</li>
						</ol>
					</li>
				</ol>

				<ul>
					<li>Unordered Item</li>
					<li>Unordered Item</li>
					<ul>
						<li>Unordered Item</li>
						<li>Unordered Item</li>
					</ul>
					<li>Unordered Item</li>
					<li>Unordered Item</li>
				</ul>

				<ul className="list-unstyled">
					<li>Unstyled Item</li>
					<li>Unstyled Item</li>
					<ul className="list-unstyled">
						<li>Indented Unstyled Item</li>
						<li>Indented Unstyled Item</li>
						<li>Indented Unstyled Item</li>
					</ul>
					<li>Unstyled Item</li>
					<li>Unstyled Item</li>
				</ul>

				<h3>Links</h3>
				<div>
					<a href="/" className="link-sm">Small Link</a>
				</div>
				<div>
					<a href="/">Medium Link</a>
				</div>
				<div>
					<a href="/" className="link-md">Large Link</a>
				</div>
				<div>
					<a href="/" className="link-italics">Italics Link</a>
				</div>
				<div>
					<a href="/" className="link-bold">Bold Link</a>
				</div>
				<div>
					<a href="/" className="link-rm-txt-dec">No Style Link</a>
				</div>

				<h3>Text Field</h3>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Textfield"
					labelVisible
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Required Textfield"
					isRequired
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Not Required Text Field"
					isRequired={false}
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Disabled Textfield"
					isDisabled
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Readonly Textfield"
					isReadOnly
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Textfield with Tooltip"
					tooltip="Toolitp for Email"
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="otherExample"
					className="class-other"
					displayType={DisplayType.BLOCK}
					label="Placeholder for Textfield"
					placeholder="Placeholder for Textfield would go here"
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Clearable Textfield"
					clickToClear
				/>
				<NumberTextField
					model={elementState.modalState}
					modelProperty="mynumber"
					label="Number Text Field"
				/>
				<NumberTextField
					model={elementState.modalState}
					modelProperty="mynumber"
					label="Clearable Number Text Field"
					clickToClear
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="noLabelTextfield"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="No Label Textfield"
					placeholder="No Label Textfield"
					labelVisible={false}
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.INLINE}
					label="Inline Textfield"
				/>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					label="Static Textfield"
					staticInput
				/>

				<h3>TextArea</h3>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Textarea"
					labelVisible
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Required Textarea"
					isRequired
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Not Required Textarea"
					isRequired={false}
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Disabled Textarea"
					isDisabled
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Readonly Textarea"
					isReadOnly
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Textarea with Tooltip"
					tooltip="Toolitp for Email"
				/>
				<TextArea
					model={elementState.modelEmpty}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Textarea with Placeholder"
					placeholder="Placeholder for Email"
				/>
				<TextArea
					model={elementState.modelEmpty}
					modelProperty="email"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="Label Email"
					placeholder="Textarea without Label"
					labelVisible={false}
				/>

				<h3>Dropdown</h3>
				<Combobox
					model={elementState.modalState}
					modelProperty="DropdownModelProperty"
					label="Regular Dropdown"
					options={[
						{ display: 'Hello', value: 'hello' },
						{ display: 'World', value: 'world' },
						{ display: 'Lorem', value: 'lorem' },
						{ display: 'Ipsum', value: 'ipsum' },
					]}
					searchable={false}
				/>

				<Combobox
					model={elementState.modalState}
					modelProperty="DropdownModelProperty"
					label="Searchable Dropdown"
					options={[
						{ display: 'Hello', value: 'hello' },
						{ display: 'World', value: 'world' },
						{ display: 'Lorem', value: 'lorem' },
						{ display: 'Ipsum', value: 'ipsum' },
					]}
				/>

				<MultiCombobox
					model={elementState.modalState}
					modelProperty="MutliDropdownProperty"
					label="Multi Dropdown"
					options={[
						{ display: 'Hello', value: 'hello' },
						{ display: 'World', value: 'world' },
						{ display: 'Lorem', value: 'lorem' },
						{ display: 'Ipsum', value: 'ipsum' },
					]}
				/>

				<Combobox
					model={elementState.modalState}
					modelProperty="DropdownModelProperty"
					label="Disabed Dropdown"
					options={[
						{ display: 'Hello', value: 'hello' },
						{ display: 'World', value: 'world' },
						{ display: 'Lorem', value: 'lorem' },
						{ display: 'Ipsum', value: 'ipsum' },
					]}
					isDisabled
				/>

				<Combobox
					model={elementState.modalState}
					modelProperty="DropdownModelProperty"
					label="Disabed Dropdown"
					options={[
						{ display: 'Hello', value: 'hello' },
						{ display: 'World', value: 'world' },
						{ display: 'Lorem', value: 'lorem' },
						{ display: 'Ipsum', value: 'ipsum' },
						{ display: 'maximus', value: 'maximus' },
						{ display: 'turpi', value: 'turpi' },
						{ display: 'consectetur', value: 'consectetur' },
						{ display: 'adipiscing', value: 'adipiscing' },
						{ display: 'elit', value: 'elit' },
						{ display: 'Cras', value: 'Cras' },
						{ display: 'lobortis', value: 'lobortis' },
						{ display: 'ultrices', value: 'ultrices' },
					]}
					inputProps={{ isMulti: true }}
					isDisabled
				/>

				<h3>Radio Buttons</h3>
				<RadioButtonGroup
					name="group1"
					model={elementState.modalState}
					modelProperty="RadioButtonMP1"
					options={[
						{ value: 'test1', display: 'General' },
						{ value: 'test2', display: 'General' },
						{ value: 'test3', display: 'General' },
						{ value: 'test4', display: 'General' },
					]}
				/>

				<RadioButtonGroup
					name="group2"
					model={elementState.modalState}
					modelProperty="RadioButtonMP2"
					options={[
						{ value: 'test11', display: 'Disabled' },
						{ value: 'test12', display: 'Disabled' },
						{ value: 'test13', display: 'Disabled' },
						{ value: 'test14', display: 'Disabled' },
					]}
					isDisabled
				/>

				<RadioButtonGroup
					name="group3"
					model={elementState.modalState}
					modelProperty="RadioButtonMP3"
					options={[
						{ value: 'test21', display: 'Read Only' },
						{ value: 'test22', display: 'Read Only' },
						{ value: 'test23', display: 'Read Only' },
						{ value: 'test24', display: 'Read Only' },
					]}
					isReadOnly
				/>

				<RadioButtonGroup
					name="group3"
					model={elementState.modalState}
					modelProperty="RadioButton123"
					label="Radio Group Label with Tooltip"
					options={[
						{ value: 'test211', display: 'General' },
						{ value: 'test221', display: 'General' },
						{ value: 'test231', display: 'General' },
						{ value: 'test241', display: 'General' },
					]}
					tooltip="Let me help you friend"
				/>

				<RadioButtonGroup
					name="group3"
					model={elementState.modalState}
					modelProperty="RadioButtonMP3"
					label="Radio Group Label with Readonly Radio Buttons"
					options={[
						{ value: 'test21', display: 'Read Only' },
						{ value: 'test22', display: 'Read Only' },
						{ value: 'test23', display: 'Read Only' },
						{ value: 'test24', display: 'Read Only' },
					]}
					isReadOnly
				/>

				<h3>Checkbox</h3>
				<CheckboxGroup
					label="Header for this checkbox group"
				>
					<Checkbox
						model={elementState.modalState}
						modelProperty="hfg"
						label="General Checkbox"
						tooltip="tooltip!"
					/>
					<Checkbox
						model={elementState.modalState}
						modelProperty="abc"
						label="General Checkbox"
					/>
					<Checkbox
						model={elementState.modalState}
						modelProperty="qwe"
						label="General Checkbox"
					/>
				</CheckboxGroup>

				<Checkbox
					model={elementState.modalState}
					modelProperty="ghfg"
					label="General Checkbox"
				/>

				<Checkbox
					model={elementState.modalState}
					modelProperty="jhjgh"
					label="Disabled Checkbox"
					isDisabled
				/>

				<Checkbox
					model={elementState.modalState}
					modelProperty="dhdfh"
					label="Readonly Checkbox"
					isReadOnly
				/>

				<Checkbox
					model={elementState.modalState}
					modelProperty="jgfj"
					label="Checkbox with Tooltip"
					tooltip="tooltip!"
				/>

				<h3>Date Picker</h3>
				<DatePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="general-date-picker"
					displayType={DisplayType.BLOCK}
					label="General Date Picker"
					labelVisible
				/>

				<DatePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="required-date-picker"
					displayType={DisplayType.BLOCK}
					label="Required Date Picker"
					isRequired
				/>

				<DatePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="disabled-date-picker"
					displayType={DisplayType.BLOCK}
					label="Disabled Date Picker"
					isDisabled
				/>

				<DatePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="readonly-date-picker"
					displayType={DisplayType.BLOCK}
					label="Readonly Date Picker"
					isReadOnly
				/>

				<DatePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="tooltip-date-picker"
					displayType={DisplayType.BLOCK}
					label="Date Picker with Tooltip"
					tooltip="Toolitp for Email"
				/>

				<DatePicker
					model={elementState.modelEmpty}
					modelProperty="date"
					name="NameEmail"
					className="placeholder-date-picker"
					displayType={DisplayType.BLOCK}
					label="Date Picker with Placeholder"
					placeholder="15/02/1992"
				/>

				<DatePicker
					model={elementState.modelEmpty}
					modelProperty="date"
					name="NameEmail"
					className="no-label-date-picker"
					displayType={DisplayType.BLOCK}
					label="Date Picker without Label"
					labelVisible={false}
				/>

				<h3>Date Range Picker</h3>
				<DateRangePicker
					model={elementState.modalState}
					modelProperty="range"
					name="NameEmail"
					className="general-date-range-picker"
					displayType={DisplayType.BLOCK}
					label="General Date Range Picker"
					labelVisible
				/>

				<DateRangePicker
					model={elementState.modalState}
					modelProperty="range"
					name="NameEmail"
					className="restricted-date-range-picker"
					displayType={DisplayType.BLOCK}
					label="Restricted Date Range Picker"
					labelVisible
					minDate="today"
					maxDate={((new Date().getDate()) + 14).toString()}
				/>

				<h3>Date-Time Picker</h3>
				<DateTimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="date-time-hf-picker"
					displayType={DisplayType.BLOCK}
					label="Date-Time Picker With Human-Friendly Format"
					labelVisible
					humanFriendly
				/>

				<h3>Date-Time Range Picker</h3>
				<DateTimeRangePicker
					model={elementState.modalState}
					modelProperty="range"
					name="NameEmail"
					className="general-date-time-range-picker"
					displayType={DisplayType.BLOCK}
					label="General Date-Time Range Picker"
					labelVisible
				/>

				<h3>Time Picker</h3>
				<TimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="general-time-picker"
					displayType={DisplayType.BLOCK}
					label="General Time Picker"
					labelVisible
				/>
				<TimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="required-time-picker"
					displayType={DisplayType.BLOCK}
					label="Required Time Picker"
					isRequired
				/>
				<TimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="disabled-time-picker"
					displayType={DisplayType.BLOCK}
					label="Disabled Time Picker"
					isDisabled
				/>
				<TimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="readonly-time-picker"
					displayType={DisplayType.BLOCK}
					label="Readonly Time Picker"
					isReadOnly
					placeholder="HH:MM"
				/>

				<TimePicker
					model={elementState.modalState}
					modelProperty="date"
					name="NameEmail"
					className="tooltip-time-picker"
					displayType={DisplayType.BLOCK}
					label="24hr Time Picker with Tooltip"
					tooltip="Toolitp for Email"
					time24hr
				/>

				<TimePicker
					model={elementState.modelEmpty}
					modelProperty="date"
					name="NameEmail"
					className="placeholder-time-picker"
					displayType={DisplayType.BLOCK}
					humanFriendly
					label="24hr Time Picker with Placeholder and Human-Friendly Formatting"
					placeholder="24hr Time Picker with Placeholder and Human-Friendly Formatting"
					time24hr
				/>
				<TimePicker
					model={elementState.modelEmpty}
					modelProperty="date"
					name="NameEmail"
					className="no-label-time-picker"
					displayType={DisplayType.BLOCK}
					label="Time picker without label"
					labelVisible={false}
					placeholder="Time Picker without Label"
				/>

				<h3>Input Validation</h3>
				<TextField
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Textfield"
					labelVisible
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>
				<TextArea
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Textarea"
					labelVisible
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>
				<DatePicker
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Date Picker"
					labelVisible
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>
				<TimePicker
					model={elementState.modalState}
					modelProperty="email"
					id="IdEmail"
					name="NameEmail"
					className="class-email"
					displayType={DisplayType.BLOCK}
					label="General Time Picker"
					labelVisible
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>

				<RadioButtonGroup
					name="group1"
					model={elementState.modalState}
					modelProperty="RBMP"
					options={[
						{ value: 'test41', display: 'General' },
						{ value: 'test42', display: 'General' },
						{ value: 'test43', display: 'General' },
						{ value: 'test44', display: 'General' },
					]}
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>

				<Checkbox
					model={elementState.modalState}
					modelProperty="abc"
					label="General Checkbox"
				/>
				<Checkbox
					model={elementState.modalState}
					modelProperty="ghfg"
					label="General Checkbox"
					errors={[
						'There appears to be an error here, should probably do something about that',
						'There appears to be an error here, should probably do something about that',
					]}
				/>
				<Checkbox
					model={elementState.modalState}
					modelProperty="abc"
					label="General Checkbox"
				/>
				<Checkbox
					model={elementState.modalState}
					modelProperty="abc"
					label="General Checkbox"
				/>

				<h3>Buttons</h3>
				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					<Button className="test" display={Display.Solid}>Solid Button</Button>
					<Button className="test" display={Display.Outline}>Outline Button</Button>
					<Button className="test" display={Display.Text}>Text Button</Button>
				</ButtonGroup>

				<h3>Button Colours</h3>
				<Button className="test" display={Display.Solid} colors={Colors.Primary}>Primary Button</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Secondary}>Secondary Button</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Success}>Success Button</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Warning}>Warning Button</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Error}>Error Button</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Primary} disabled>
					Disabled Button
				</Button>

				<h3>Button Outline Colours</h3>
				<Button className="test" display={Display.Outline} colors={Colors.Primary}>Primary Button</Button>
				<Button className="test" display={Display.Outline} colors={Colors.Secondary}>Secondary Button</Button>
				<Button className="test" display={Display.Outline} colors={Colors.Success}>Success Button</Button>
				<Button className="test" display={Display.Outline} colors={Colors.Warning}>Warning Button</Button>
				<Button className="test" display={Display.Outline} colors={Colors.Error}>Error Button</Button>
				<Button className="test" display={Display.Outline} colors={Colors.Primary} disabled>
					Disabled Button
				</Button>

				<h3>Button Text Colours</h3>
				<Button className="test" display={Display.Text} colors={Colors.Primary}>Primary Button</Button>
				<Button className="test" display={Display.Text} colors={Colors.Secondary}>Secondary Button</Button>
				<Button className="test" display={Display.Text} colors={Colors.Success}>Success Button</Button>
				<Button className="test" display={Display.Text} colors={Colors.Warning}>Warning Button</Button>
				<Button className="test" display={Display.Text} colors={Colors.Error}>Error Button</Button>
				<Button className="test" display={Display.Text} colors={Colors.Primary} disabled>
					Disabled Button
				</Button>

				<h3>Button Sizes</h3>
				<Button className="test" display={Display.Solid} colors={Colors.Primary} sizes={Sizes.Small}>
					Small
					Button
				</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Primary} sizes={Sizes.Medium}>
					Medium
					Button
				</Button>
				<Button className="test" display={Display.Solid} colors={Colors.Primary} sizes={Sizes.Large}>
					Large
					Button
				</Button>

				<h3>Button Icons</h3>
				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					<Button
						className="test"
						display={Display.Solid}
						colors={Colors.Primary}
						icon={{ icon: 'academy', iconPos: 'icon-left' }}
					>
						Icon Before Text
					</Button>
					<Button
						className="test"
						display={Display.Outline}
						colors={Colors.Success}
						icon={{ icon: 'academy', iconPos: 'icon-right' }}
					>
						Icon After Text
					</Button>
					<Button
						className="test"
						display={Display.Outline}
						colors={Colors.Warning}
						labelVisible={false}
						icon={{ icon: 'academy', iconPos: 'icon-right' }}
					>
						No Text
					</Button>
				</ButtonGroup>

				<h3>Button Groups</h3>
				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					<Button display={Display.Solid} colors={Colors.Primary}>Horizontal Button Group</Button>
					<Button display={Display.Solid} colors={Colors.Success}>Horizontal Button Group</Button>
					<Button display={Display.Outline} colors={Colors.Secondary}>Horizontal Button Group</Button>
				</ButtonGroup>
				<ButtonGroup alignment={Alignment.VERTICAL}>
					<Button display={Display.Solid} colors={Colors.Primary}>Horizontal Button Group</Button>
					<Button display={Display.Solid} colors={Colors.Warning}>Horizontal Button Group</Button>
					<Button display={Display.Outline} colors={Colors.Error}>Horizontal Button Group</Button>
				</ButtonGroup>
				<ButtonGroup sizing={Sizing.GROW}>
					<Button display={Display.Solid} colors={Colors.Primary}>Horizontal Button Group</Button>
					<Button display={Display.Solid} colors={Colors.Success}>Horizontal Button Group</Button>
					<Button display={Display.Outline} colors={Colors.Warning}>Horizontal Button Group</Button>
				</ButtonGroup>

				<h3>Collection</h3>
				<Collection
					collection={elementState.collectionData}
					headers={[
						{ displayName: 'id', name: 'id' },
						{ displayName: 'title', name: 'title' },
						{ displayName: 'description', name: 'description' },
					]}
				/>
				<h3>Collection with context menu</h3>
				<Collection
					collection={elementState.collectionData}
					headers={[
						{ displayName: 'id', name: 'id' },
						{ displayName: 'title', name: 'title' },
						{ displayName: 'description', name: 'description' },
					]}
					actionsMore={[
						{
							customItem: 'aaaa',
							onEntityClick: (event, entity) => {
								console.log(entity);
							},
						},
						{
							groupName: 'group1',
							actions: [
								{
									label: 'aaaa',
									icon: 'edit',
									iconPos: 'icon-left',
									onEntityClick: (event, entity) => {
										console.log(entity);
									},
								},
								{
									customItem: 'bbbb',
									onEntityClick: (event, entity) => {
										console.log(entity);
									},
								},
								{
									groupName: 'group11',
									actions: [
										{
											label: 'aaaa',
											icon: 'edit',
											iconPos: 'icon-left',
											onEntityClick: (event, entity) => {
												console.log(entity);
											},
										},
										{
											customItem: 'bbbb',
											onEntityClick: (event, entity) => {
												console.log(entity);
											},
										},
									],
								},
							],
						},
						{
							customItem: 'bbbb',
							onEntityClick: (event, entity) => {
								console.log(entity);
							},
						},
					]}
				/>

				<h3>Searchable Collection</h3>
				<Collection
					collection={elementState.collectionData.filter(model => (elementState.collectionSearch.trim()
						? model.id === elementState.collectionSearch.trim()
						: true))}
					selectableItems
					onSearchTriggered={searchTerm => runInAction(() => {
						elementState.collectionSearch = searchTerm;
					})}
					idColumn="id"
					headers={[
						{ displayName: 'id', name: 'id' },
						{ displayName: 'title', name: 'title' },
						{ displayName: 'description', name: 'description' },
					]}
					actions={[
						{ label: 'Alert', action: model => alert(`Model ${model.id} alerted`) },
					]}
					expandList={model => `id: ${model.id} | title: ${model.title} | description: ${model.description}`}
				/>

				<Tabs tabs={[
					{ component: 'A', name: 'A Tab', key: 'a' },
					{ component: 'B', name: 'B Tab', key: 'b' },
					{ component: 'C', name: 'C Tab', key: 'c' },
				]}
				/>

				<h3>Modals & Alerts</h3>

				<div>
					<Button
						className="open-custom-modal"
						onClick={() => runInAction(() => {
							elementState.modalState.firstOpen = true;
						})}
					>
						Open custom modal
					</Button>
					<Modal
						isOpen={elementState.modalState.firstOpen}
						label="An example modal!"
						onRequestClose={() => runInAction(() => {
							elementState.modalState.firstOpen = false;
						})}
					>
						<h4>This is some modal content</h4>
						<p>This is a paragraph!!!</p>
						<i>
							Wow, an
							{'<i>'}
							{' '}
							tag
						</i>
						<Button
							onClick={() => runInAction(() => {
								elementState.modalState.firstOpen = false;
							})}
						>
							Close
						</Button>
						<Button
							className="show-flash-alert-test"
							onClick={(): void => alert('Success!', 'success', {})}
						>
							Flash Alert Integration Test
						</Button>
						<Button
							className="show-dismissible-alert-test"
							onClick={(): void => alert('Error!', 'error', {})}
						>
							Dismissible Alert Integration Test
						</Button>
					</Modal>
					<Button
						className="open-imperative-modal"
						onClick={() => {
							store.modal.show(
								'my label',
								(
									<div>
										<p key="1">Anything can be in here!</p>
										<img key="2" srcSet="" alt="There were goat pictures in MDSaaS.Target?" />
										<p key="3">Even pictures of goats!</p>
										<Button key="4" onClick={() => store.modal.hide()}>Close</Button>
									</div>
								),
							);
						}}
					>
						Imperative modal
					</Button>
					<Button
						className="open-confirm-modal"
						onClick={() => {
							confirmModal('Big decisions await...', 'Yeah or nah?')
								.then(() => console.log('success'))
								.catch(() => console.log('fail'));
						}}
					>
						Confirm
					</Button>
					<Button
						className="open-alert-modal"
						onClick={() => {
							alertModal('Alerting!', '💯🔥 ARE YOU ALERTED!? 🔥💯');
						}}
					>
						Alert
					</Button>
				</div>

				<div>
					<Button
						className="show-error-alert"
						onClick={(): void => alert('Error!', 'error', { autoClose: false })}
					>
						Show Error
					</Button>
					<Button
						className="show-info-alert"
						onClick={(): void => alert('Info!', 'info', { autoClose: false, position: 'top-center' })}
					>
						Show Info
					</Button>
					<Button
						className="show-warning-alert"
						onClick={(): void => alert('Warning!', 'warning', { position: 'top-center' })}
					>
						Show Warning
					</Button>
					<Button
						className="show-success-alert"
						onClick={(): void => alert('Success!', 'success', {})}
					>
						Show Success
					</Button>
				</div>

				<h3>Breadcrumbs</h3>
				<PageBreadcrumbs
					className="breadcrumbs-1"
					tags={
						[
							{ label: 'customer groups', link: '#', className: 'home-level' },
							{ label: 'customers', link: '#', className: 'customers-level' },
							{ label: 'customer1', link: '#', className: 'customer-level' },
							{ label: 'edit', link: '#', className: 'action-level' },
						]
					}
				/>
			</div>
		</div>
	);
});

export default ElementsPage;
