import { store } from "Models/Store";
import { useState } from "react";
import { Button, Colors, Display, Sizes } from "../Button/Button";
import { ButtonGroup } from "../Button/ButtonGroup";
import If from "../If/If";
import Modal from "../Modal/Modal";

export interface SectionTitleProps {
    Link: string;
    Text: string;
    DisplayButton: boolean;
}

const SectionTitle = ({ Link, Text, DisplayButton }: SectionTitleProps) => {
	const isManager = store.userType === 'ORGANISATION_MANAGER';
	const isSuper = store.userType === 'SUPER_USER';

	const [showInfoModal, setShowInfoModal] = useState(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		if (isManager || isSuper) {
			window.open("/hub/organisation/products", '_self');
		}
		else {
			setShowInfoModal(true);
		}
	}

	return (
		<div className="section-title">
			<a
				href={Link}
				className="title-link"
				target="_blank"
				rel="noreferrer"
			>
				<h5>Access {Text}</h5>
				<div className="icon-north-east icon-left title-icon"/>
			</a>
				
			<If condition={DisplayButton}>
				<div className="product-selected-box" onClick={handleClick}>
					Activate {Text}
				</div>
				<If condition={showInfoModal}>
					<Modal
						isOpen
						label="Info"
						className="info-modal"
					>
						<span>
							To activate Access {Text}, please contact your Organisation Manager.
						</span>
						<ButtonGroup className="modal__actions">
							<Button
								colors={Colors.Error}
								sizes={Sizes.Medium}
								display={Display.Text}
								onClick={() => setShowInfoModal(false)}
							>
								Close
							</Button>
						</ButtonGroup>
					</Modal>
				</If>
			</If>
		</div>
	);
}

export default SectionTitle;