import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import RegistrationList from 'Views/Components/Registration/RegistrationList';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface RegistrationListPageProps extends RouteComponentProps {
}

const RegistrationListPage = (props: RegistrationListPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content list-page">
				<PageBreadcrumbs
					tags={
						[
							{ label: 'Registrations' },
						]
					}
				/>
				<RegistrationList />
			</div>
		</AccessIntelSecuredPage>
	);
};
export default RegistrationListPage;
