import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { userPermissionScopeOrder } from '../Models/Enums';
import { store } from '../Models/Store';
import * as Pages from './Pages';
import PageNotFound from './Pages/PageNotFound';

export interface FrontendAuthorisedHubProps {
    path: string,
}

// Monitor routes should not be accessed if the user cannot access Monitor
export const FrontendAuthorisedHub = (props: FrontendAuthorisedHubProps) => {
	const { path } = props;

	const isClientUser = store.getUser?.organisation?.isClient === true;
	const isReferralPartnerUser = store.getUser?.organisation?.isReferralPartner === true;
	const canManageUsers = (isClientUser && userPermissionScopeOrder[store.userPermissions.commonManageUsers ?? 'SELF']
		> userPermissionScopeOrder.SELF);
	const isSuperUser = store.userType === 'SUPER_USER';
	const canManageOrganisations = store.userPermissions.commonManageOrganisations !== 'NONE';

	const pages: {
		path: string,
		component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined,
		isAccessible?: boolean,
	}[] = [
		{ path: '/hub/products', component: Pages.ProductPage, isAccessible: true },
		{ path: '/hub/account', component: Pages.AccountPage, isAccessible: true },
		{
			path: '/hub/users/new',
			component: Pages.NewUserPage,
			isAccessible: canManageUsers,
		},
		{
			path: '/hub/users/:userId',
			component: Pages.UserAccountPage,
			isAccessible: canManageUsers,
		},
		{
			path: '/hub/users',
			component: Pages.UsersPage,
			isAccessible: canManageUsers,
		},
		{
			path: '/hub/registrations/:registrationId',
			component: Pages.ViewRegistrationPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/registrations',
			component: Pages.RegistrationListPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/referral-partners/:registrationId',
			component: Pages.ReferralPartnerPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/referral-partners',
			component: Pages.ReferralPartnersListPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/clients/:organisationId/setup/:products',
			component: Pages.ProductSetupPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/clients/:organisationId/business-entities/:businessEntityId',
			component: Pages.BusinessEntityPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/clients/:organisationId',
			component: Pages.OrganisationPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/clients',
			component: Pages.ClientListPage,
			isAccessible: isSuperUser,
		},
		{
			path: '/hub/organisation/setup/:products',
			component: Pages.ProductSetupPage,
			isAccessible: canManageOrganisations,
		},
		{
			path: '/hub/organisation/business-entities/:businessEntityId',
			component: Pages.BusinessEntityPage,
			isAccessible: isClientUser,
		},
		{
			path: '/hub/organisation',
			component: Pages.OrganisationPage,
			isAccessible: isClientUser,
		},
		{
			path: '/hub/business',
			component: Pages.ReferralPartnerBusinessPage,
			isAccessible: isReferralPartnerUser && !isClientUser,
		},
		{
			path: '/hub/referrals',
			component: Pages.ReferralPartnerReferralListPage,
			isAccessible: isReferralPartnerUser,
		},
		{
			path: '/hub/settings',
			component: Pages.AppSettingsPage,
			isAccessible: isSuperUser,
		},
	];

	const getAccessibleRoutes = () => {
		return pages
			.filter(page => page.isAccessible)
			.map(page => <Route key={page.path} path={page.path} component={page.component} />);
	};

	return (
		<Switch>
			<Redirect exact from="/hub" to={`${path}/hub/products`} />
			{
				getAccessibleRoutes()
			}
			<Route path="/hub" component={PageNotFound} />
		</Switch>
	);
};
