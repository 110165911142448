/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsProviderProduct from 'Models/Security/Acl/VisitorsProviderProduct';
import UserProviderProduct from 'Models/Security/Acl/UserProviderProduct';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProviderProductEntityAttributes extends IModelAttributes {
	code: string;
	description: string;
	cost: number;

	providerProductOrganisations: Array<
		| Models.ProviderProductOrganisationEntity
		| Models.IProviderProductOrganisationEntityAttributes
	>;
	providerId: string;
	provider: Models.ProviderEntity | Models.IProviderEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProviderProductEntity', 'Provider Product')
// % protected region % [Customise your entity metadata here] end
export default class ProviderProductEntity extends Model
	implements IProviderProductEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsProviderProduct(),
		new UserProviderProduct(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	@observable
	@attribute<ProviderProductEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<ProviderProductEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cost'] off begin
	@Validators.Numeric()
	@observable
	@attribute<ProviderProductEntity, number>()
	@CRUD({
		name: 'Cost',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public cost: number;
	// % protected region % [Modify props to the crud options here for attribute 'Cost'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] off begin
		name: 'Provider Product Organisations',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.ProviderProductOrganisationEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'providerProductOrganisations',
			oppositeEntity: () => Models.ProviderProductOrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] end
	})
	public providerProductOrganisations: Models.ProviderProductOrganisationEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider'] off begin
		name: 'Provider',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.ProviderEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider'] end
	})
	public providerId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public provider: Models.ProviderEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProviderProductEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProviderProductEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.cost !== undefined) {
				this.cost = attributes.cost;
			}
			if (attributes.providerProductOrganisations !== undefined && Array.isArray(attributes.providerProductOrganisations)) {
				for (const model of attributes.providerProductOrganisations) {
					if (model instanceof Models.ProviderProductOrganisationEntity) {
						this.providerProductOrganisations.push(model);
					} else {
						this.providerProductOrganisations.push(new Models.ProviderProductOrganisationEntity(model));
					}
				}
			}
			if (attributes.providerId !== undefined) {
				this.providerId = attributes.providerId;
			}
			if (attributes.provider !== undefined) {
				if (attributes.provider === null) {
					this.provider = attributes.provider;
				} else if (attributes.provider instanceof Models.ProviderEntity) {
					this.provider = attributes.provider;
					this.providerId = attributes.provider.id;
				} else {
					this.provider = new Models.ProviderEntity(attributes.provider);
					this.providerId = this.provider.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		providerProductOrganisations {
			${Models.ProviderProductOrganisationEntity.getAttributes().join('\n')}
		}
		provider {
			${Models.ProviderEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			providerProductOrganisations: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProviderProductEntity.prototype, 'created');
CRUD(modifiedAttr)(ProviderProductEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
