import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

type PpsrSummary = 'threeMonths' | 'sixMonths' | 'year' | 'current';
type ApproveSummary = 'notSubmitted' | 'referred' | 'pending' | 'notStarted';
type Product = 'ppsr' | 'approve';
export interface RegistrationSummaryProps {
	count: number|string,
	type: PpsrSummary | ApproveSummary,
	product: Product,
}

const ppsrTextMap = {
	threeMonths: 'Due to expire within 3 months',
	sixMonths: 'Due to expire between 3 and 6 months',
	year: 'Due to expire between 6 and 12 months',
	current: 'Current',
};

const approveTextMap = {
	notSubmitted: 'Not Submitted',
	referred: 'Referred',
	pending: 'Pending',
	notStarted: 'Not Started',
};

const ProductSummary = observer((props: RegistrationSummaryProps) => {
	const { count, type, product } = props;

	function summaryText() {
		switch (product) {
			case 'ppsr':
				return ppsrTextMap[type];
			case 'approve':
				return approveTextMap[type];
			default:
				return '';
		}
	}

	return (
		<div className={classNames(['product-summary', type])}>
			<div className="summary-text">
				{summaryText()}
			</div>
			<div className="count-text">
				<h5>{count}</h5>
			</div>
		</div>
	);
});

export default ProductSummary;
