import { SERVER_URL } from 'Constants';
import { EsignuserEntity } from 'Models/Entities';
import axios, { AxiosError } from 'axios';

/**
 * Asynchronously requests a list of ESign users associated with a specific business entity.
 *
 * This function fetches a paginated list of ESign users based on the provided business entity ID, page number, search term, and page size. It constructs a request to a predefined server URL, appending the business entity ID to the endpoint. The function then awaits the response from the server, which is expected to return a JSON object containing an array of ESign user entities and a count of the total number of users matching the search criteria.
 *
 * @param {string} businessEnitityId - The unique identifier of the business entity for which ESign users are being requested.
 * @param {number} pageNo - The page number for pagination, indicating which set of users to fetch.
 * @param {string} search - A search term used to filter the ESign users by matching attributes such as name or email.
 * @param {number} [pageSize=10] - The number of ESign users to return per page. Defaults to 10 if not specified.
 * @returns {Promise<{data: EsignuserEntity[], count: number}>} A promise that resolves to an object containing an array of ESign user entities and the total count of users matching the search criteria. Throws an error if the request fails.
 * @throws {AxiosError} Throws an AxiosError if the request to the server fails.
 */
async function requestESignUsers(businessEnitityId: string, pageNo: number, search: string, pageSize: number = 10): Promise<{ data: EsignuserEntity[]; count: number; }> {
	const baseUrl = `${SERVER_URL}/api/entity/ESignuserEntity/${businessEnitityId}`;
	try {
		const response = await axios.get<{data: EsignuserEntity[], count: number}>(
			baseUrl,
			{ params: { pageNo: pageNo, pageSize: pageSize, searchTerm: search } },
		);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error as AxiosError;
	}
}

export default requestESignUsers;
