import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import AccountTile from 'Views/Components/Account/AccountTile';
import { UserEntity } from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import AccountPermissions from 'Views/Components/Account/AccountPermissions';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import ToggleUserActivatedButton from 'Views/Components/Account/ToggleUserActivatedButton';
import { store } from 'Models/Store';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

@observer
class UserAccountPage extends React.Component<RouteComponentProps<{ userId: string}>> {
	@observable private currentTab: ('account-information' | 'account-permissions') = 'account-information'

	@observable private user?: UserEntity;

	@observable private name?: { firstName: string, lastName: string };

	public componentDidMount(): void {
		this.fetchUserData();
	}

	// Handler for updating the name in the container
	@action setName = (firstName: string, lastName: string) => {
		this.name = { firstName, lastName };
	}

	private fetchUserData = async () => {
		if (!this.props.match.params.userId) {
			return;
		}

		const userResult = await UserEntity.fetchUser(this.props.match.params.userId);
		if (userResult) {
			runInAction(() => {
				this.user = userResult;
				this.name = { firstName: userResult.firstName, lastName: userResult.lastName };
			});
		}
	}

	render() {
		const {
			match,
			location,
		} = this.props;
		const { path } = match;
		const contents = (
			<AccessIntelSecuredPage routeComponentProps={this.props}>
				<div className="body-content user-account-page">
					<div className="invisible-page-wrap">
						{!!this.user && !!this.name
							? (
								<>
									<div className="top-container">
										<PageBreadcrumbs
											tags={
												[
													{ label: 'Users', link: '/hub/users' },
													{ label: `${this.name.firstName} ${this.name.lastName}` },
												]
											}
										/>
										<div>
											<ToggleUserActivatedButton user={this.user} />
										</div>
									</div>
									<div className="tab-selector-wrap">
										{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
											IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
										<button
											className={classNames('tab-selector', 'account-information', {
												active: !location.pathname.includes('permissions'),
											})}
											{...HandleEvents(action(() => {
												this.currentTab = 'account-information';
												store.routerHistory.push(
													`/hub/users/${this.user?.id}`,
												);
											}))}
										>
											User Account Information
										</button>
										<button
											className={classNames('tab-selector', 'account-permissions', {
												active: location.pathname.includes('permissions'),
											})}
											{...HandleEvents(action(() => {
												this.currentTab = 'account-permissions';
												store.routerHistory.push(
													`/hub/users/${this.user?.id}/permissions`,
												);
											}))}
										>
											Permissions
										</button>
									</div>
									<div className={classNames('white-box', {
										'square-top-left-border': this.currentTab === 'account-information',
									})}
									>
										<Switch>
											<Route
												path={`${path}/permissions`}
												component={() => {
													return (
														<AccountPermissions user={this.user ?? new UserEntity()} />
													);
												}}
											/>
											<Route
												path="/"
												component={() => {
													return (
														<AccountTile
															originalUser={this.user ?? new UserEntity()}
															setName={this.setName}
														/>
													);
												}}
											/>
										</Switch>
									</div>
								</>
							)
							: <Spinner />}

					</div>
				</div>
			</AccessIntelSecuredPage>
		);

		return contents;
	}
}

export default UserAccountPage;
