import * as React from 'react';

import {
	AddressEntity,
	ApplicationEntity,
	BusinessEntity,
	EsigntemplateEntity,
	EsigntemplatecontentEntity,
	LogoEntity,
	SpgEntity,
} from 'Models/Entities';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { action, observable } from 'mobx';
import { useEffect, useState } from 'react';

import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import BusinessEntityApproveDetails from '../Components/BusinessEntities/BusinessEntityApproveDetails';
import BusinessEntityDetails from '../Components/BusinessEntities/BusinessEntityDetails';
import BusinessEntityESignTabs from 'Views/Components/BusinessEntities/ESignTemplateSection/BusinessEntityESignTabs';
import BusinessEntityMonitor from '../Components/BusinessEntities/BusinessEntityMonitor';
import BusinessEntityPpsrDetails from '../Components/BusinessEntities/BusinessEntityPpsrDetails';
// eslint-disable-next-line max-len
import EntityActiveStatus from '../Components/BusinessEntities/EntityActiveStatus';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import Spinner from 'Views/Components/Spinner/Spinner';
import alertToast from 'Util/ToastifyUtils';
import { businessEntitiesPermissionScopeOrder } from 'Models/Enums';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import useAsync from 'Hooks/useAsync';

export interface BusinessEntityPageProps {
	organisationId: string,
	businessEntityId?: string,
}

class QueryError extends Error {}

const BusinessEntityPage = observer((props: RouteComponentProps<BusinessEntityPageProps>) => {
	const {
		match,
		location,
	} = props;

	const { businessEntityId } = match.params;
	const isClientsList = !!match.params.organisationId;
	// If we haven't been passed an organisation ID, we use the current user's
	const organisationId = match.params.organisationId ?? store.getUser?.organisation?.id;
	const { path } = match;

	const [organisationName, setOrganisationName] = useState('');
	const [businessEntityName, setBusinessEntityName] = useState('');
	// we allow a way for a product modal to be opened straight away
	const showProductModal = React.useMemo(() => {
		// wrap in a try catch block as it might not exist
		try {
			const { showProductModal: stateShowProductModal } = location.state as { showProductModal: string };
			return (stateShowProductModal || 'none') as ('add-ppsr' | 'add-approve' | 'none');
		} catch (exception) {
			return 'none';
		}
	}, [location.state]);
	// we replace the state so it doesn't open again
	window.history.replaceState({}, document.title);

	const fetchResponse = useAsync<BusinessEntity, any|QueryError>(
		() => fetchBusinessEntityData(),
		[businessEntityId],
	);

	// determines if vertical whitespace should grow on tab (if the user does not have access to a product)
	const growWhiteSpaceTab = () => {
		return (location.pathname === getRoute(businessEntityId, 'ppsr')
				&& !(store.canAccessPPSR && fetchResponse.data?.enabledForPPSR))
			|| (location.pathname === getRoute(businessEntityId, 'approve')
				&& !(store.canAccessApprove && fetchResponse.data?.enabledForApprove))
			|| (location.pathname === getRoute(businessEntityId, 'intel')
				&& !(store.canAccessIntel && (fetchResponse.data?.organisation.intelEnabled || fetchResponse
					.data?.primaryOrganisation?.intelEnabled)));
	};

	const fetchBusinessEntityData = async (): Promise<BusinessEntity> => {
		const results = await store.apolloClient.query({
			query: gql`
				query fetchBusinessEntityById($businessEntityId: ID) {
					businessEntity(id: $businessEntityId) {
						${BusinessEntity.getAllAttributes().join('\n')}
						primaryOrganisation {
							id
							intelEnabled
							approveEnabled
							ppsrEnabled
							primaryBusinessEntity {
								id
								name
								email
							}
							businessEntitys {
								id
							}
							authorisedCreditBureaus {
								id
								authorisedCreditBureauId
							}
						}
						organisation {
							id
							intelEnabled
							ppsrEnabled
							approveEnabled
							primaryBusinessEntity {
								id
								name
								email
							}
							businessEntitys {
								id
							}
							authorisedCreditBureaus {
								id
								authorisedCreditBureauId
							}
						}
						address {
							${AddressEntity.getAllAttributes().join('\n')}
						}
						spgss {
							${SpgEntity.getAllAttributes().join('\n')}
						}
						applicationss {
							${ApplicationEntity.getAllAttributes().join('\n')}
						}
						esigntemplatess {
							${EsigntemplateEntity.getAllAttributes().join('\n')}
							esigntemplatecontents {
								${EsigntemplatecontentEntity.getAllAttributes().join('\n')}
							}
							logo {
								id
								fileNameId
							}
						}
						logoss {
							${LogoEntity.getAllAttributes().join('\n')}
						}
					}
				}`,
			variables: {
				businessEntityId,
			},
			fetchPolicy: 'no-cache',
		});

		if (!results.data.businessEntity) {
			throw new QueryError('Unable to find matching business entity');
		}

		return observable(new BusinessEntity(results.data.businessEntity));
	};

	useEffect(() => {
		setOrganisationName(
			fetchResponse.data?.organisation?.primaryBusinessEntity.name
			|| fetchResponse.data?.primaryOrganisation?.primaryBusinessEntity.name
			|| '',
		);
		setBusinessEntityName(fetchResponse.data?.name || '');
	}, [fetchResponse]);

	const getRoute = (routeBusinessEntityId: string|null = null, routePart: string|null = null): string => {
		const route = isClientsList
			? [`/hub/clients/${organisationId}`]
			: ['/hub/organisation'];

		if (routeBusinessEntityId) {
			route.push('business-entities', routeBusinessEntityId);
		}

		if (routePart) {
			route.push(routePart);
		}

		return route.join('/');
	};

	if (fetchResponse.type === 'error') {
		if (fetchResponse.error instanceof QueryError) {
			alertToast(fetchResponse.error.message, 'error');
		} else {
			console.error(fetchResponse.error);
			alertToast('An error occured', 'error');
		}
		store.routerHistory.push(getRoute());
		return <Spinner />;
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content business-entities-page">
				<div className={classNames(
					'invisible-page-wrap',
					{
						grow: growWhiteSpaceTab(),
					},
				)}
				>
					<If condition={fetchResponse.type === 'loading'}>
						<InlineSpinner />
					</If>
					{fetchResponse.type === 'data' && (
						<>
							<div className="top-container">
								<PageBreadcrumbs
									tags={
										isClientsList
											? [
												{ label: 'Clients', link: '/hub/clients' },
												{ label: organisationName, link: getRoute(null, 'business-entities') },
												{ label: businessEntityName },
											]
											: [
												{ label: 'Organisation', link: getRoute(null, 'business-entities') },
												{ label: businessEntityName },
											]
									}
								/>
								<EntityActiveStatus
									model={fetchResponse.data}
									modelProperty="isActive"
									statusText="Entity status:"
									disabled={(fetchResponse.data.id === fetchResponse.data
										.primaryOrganisation?.primaryBusinessEntityId)
										|| (businessEntitiesPermissionScopeOrder[
											store.userPermissions.commonManageBusinessEntities ?? 'NONE']
											< businessEntitiesPermissionScopeOrder.WITHIN_ORGANISATION)}
									tooltip={'Once deactivated, this business entity can be re-activated again. '
										+ 'Entity status refers to the business entity status NOT the ABR status.'}
									onAfterChange={async () => {
										const savedEntity = new BusinessEntity(fetchResponse.data);
										// don't alert the user on a successful save, only on unsuccessful
										try {
											await fetchResponse.data.save();
										} catch (exception) {
											fetchResponse.data.assignAttributes(savedEntity);
											alertToast('Business entity status could not be changed, '
												+ 'please refresh and try again', 'error');
										}
									}}
								/>
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								<button
									className={classNames('tab-selector', 'business-entities', {
										active: location.pathname === getRoute(businessEntityId),
									})}
									{...HandleEvents(action(
										() => store.routerHistory.push(getRoute(businessEntityId)),
									))}
								>
									Business entity details
								</button>
								<button
									className={classNames('tab-selector', 'ppsr', {
										active: location.pathname === getRoute(businessEntityId, 'ppsr'),
									})}
									{...HandleEvents(action(
										() => store.routerHistory.push(getRoute(businessEntityId, 'ppsr')),
									))}
								>
									PPSR
								</button>
								<button
									className={classNames('tab-selector', 'approve', {
										active: location.pathname === getRoute(businessEntityId, 'approve'),
									})}
									{...HandleEvents(action(
										() => store.routerHistory.push(getRoute(businessEntityId, 'approve')),
									))}
								>
									Approve
								</button>
								<button
									className={classNames('tab-selector', 'intel', {
										active: location.pathname === getRoute(businessEntityId, 'monitor'),
									})}
									{...HandleEvents(action(
										() => store.routerHistory.push(getRoute(businessEntityId, 'monitor')),
									))}
								>
									Monitor
								</button>
								<button
									className={classNames('tab-selector', 'esign', {
										active: location.pathname === getRoute(businessEntityId, 'esign-details'),
									})}
									{...HandleEvents(action(
										() => store.routerHistory.push(getRoute(businessEntityId, 'esign-details')),
									))}
								>
									ESign
								</button>
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': location.pathname === getRoute(businessEntityId),
								'inner-padding': location.pathname.includes('products'),
							})}
							>
								<Switch>
									<Route
										path={`${path}/ppsr`}
										component={() => {
											return (
												<BusinessEntityPpsrDetails
													businessEntity={fetchResponse.data}
												/>
											);
										}}
									/>
									<Route
										path={`${path}/approve`}
										component={() => {
											return (
												<BusinessEntityApproveDetails
													businessEntity={fetchResponse.data}
												/>
											);
										}}
									/>
									<Route
										path={`${path}/monitor`}
										component={() => {
											return (
												<BusinessEntityMonitor
													businessEntity={fetchResponse.data}
													onUpdate={fetchResponse.refresh}
												/>
											);
										}}
									/>
									<Route
										path={`${path}/esign-details`}
										component={() => (
											<BusinessEntityESignTabs
												businessEntity={fetchResponse.data}
												reload={fetchResponse.refresh}
											/>
										)}
									/>
									<Route
										path={path}
										component={() => {
											return (
												<BusinessEntityDetails
													isPrimary={!!fetchResponse.data?.primaryOrganisation?.id}
													originalBusinessEntity={fetchResponse.data}
													primaryBusinessEntity={
														fetchResponse.data.primaryOrganisation?.primaryBusinessEntity
														?? fetchResponse.data?.organisation.primaryBusinessEntity
													}
													setName={setBusinessEntityName}
													openProductModal={showProductModal}
												/>
											);
										}}
									/>
								</Switch>
							</div>
						</>
					)}
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
});
export default BusinessEntityPage;
