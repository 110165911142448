import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IIconProps } from '../Helpers/Common';
import { Button, Display, Sizes } from '../Button/Button';
import { Tooltip } from '../Tooltip/Tooltip';

export interface IPageBreadCrumbsTag {
	label: string;
	link?: string;
	disableLink?: boolean;
	actionButton?: {
		onClick: () => void;
		icon: string;
		tooltip?: {
			id: string;
			content: string;
		}
		disabledTooltip?: {
			id: string;
			content: string;
		}
	}
	className?: string;
	_isHomeTag?: boolean; // Deprecated. Used internally
	_isCurrentTag?: boolean;
	_index?: number;
}

export interface IPageBreadcrumbsProps {
	tags: IPageBreadCrumbsTag[];
	className?: string;
	icon?: IIconProps,
}

// Copied from the bot component, but with some tweaks, since the bot component didn't have protected regions
@observer
export class PageBreadcrumbs extends React.Component<IPageBreadcrumbsProps, any> {
	private getTagNode = (tag: IPageBreadCrumbsTag) => {
		let className = classNames(tag.className);

		if (!tag._isHomeTag) {
			className = classNames(
				className,
				`icon-${(this.props.icon && this.props.icon) ? this.props.icon.icon : 'chevron-right'}`,
				(this.props.icon && this.props.icon) ? this.props.icon.iconPos : 'icon-left',
			);
		}

		const tagLabelWrapper = tag._isCurrentTag || tag.disableLink || tag.actionButton
			? tag.label
			: (
				<Link to={tag.link ? tag.link : '#'}>
					{tag.label}
				</Link>
			);

		return (
			<li className={className} key={`${tag.label}${tag._index}`}>
				{tagLabelWrapper}
				{tag.actionButton && (
					<Button
						display={Display.Text}
						onClick={tag.actionButton.onClick}
						icon={{ icon: tag.actionButton.icon, iconPos: 'icon-right' }}
						sizes={Sizes.ExtraLarge}
						disabled={tag.disableLink}
					/>
				)}
				{tag.actionButton?.tooltip && !tag.disableLink && (
					<Tooltip
						id={tag.actionButton.tooltip.id}
						content={tag.actionButton.tooltip.content}
					/>
				)}
				{tag.actionButton?.disabledTooltip && tag.disableLink && (
					<Tooltip
						id={tag.actionButton.disabledTooltip.id}
						content={tag.actionButton.disabledTooltip.content}
					/>
				)}
			</li>
		);
	};

	render() {
		const { className, tags } = this.props;
		return (
			<ul className={classNames('breadcrumbs', className)}>
				{
					tags.map((tag, index) => {
						return this.getTagNode(
							{
								...tag,
								_index: index,
								_isHomeTag: (index === 0),
								_isCurrentTag: (tags.length === (index + 1)),
							},
						);
					})
				}
			</ul>
		);
	}
}
