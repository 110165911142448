import * as React from 'react';
import { store } from 'Models/Store';
import { Button, Display } from 'Views/Components/Button/Button';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import Cookies from 'js-cookie';
import { TextField } from 'Views/Components/TextBox/TextBox';
import alertToast from './ToastifyUtils';
import HandleEvents from './HandleEvents';

interface CheckRegistrationModalProps {
	model: {
		acceptedTerms: boolean;
		passcode: string;
	},
	onSuccess: () => void,
	onCancel: () => void,
	onSendEmail: () => Promise<void>,
}

const CheckRegistrationModal = observer((props: CheckRegistrationModalProps) => {
	const {
		model, onSuccess, onCancel, onSendEmail,
	} = props;
	return (
		<React.Fragment key="access-modal">
			<div className="registration-passcode-modal">
				<h4>Continue registration process</h4>
				<p>To complete your in-progress registration, please enter the passcode sent to you via email.</p>
				<TextField
					label="Passcode"
					model={model}
					modelProperty="passcode"
					placeholder="Passcode"
				/>
				<p className="resend-email">Unable to find your passcode?&nbsp;
					<span
						role="button"
						aria-label="Product switcher toggle"
						{...HandleEvents(() => onSendEmail())}
					>
						Resend passcode via email
					</span>
				</p>
				<div key="actions" className="modal__actions">
					<Button
						onClick={onCancel}
						display={Display.Outline}
					>
						Cancel
					</Button>
					<Button
						onClick={onSuccess}
						display={Display.Solid}
						disabled={!model.passcode}
					>
						Submit
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
});

// If the visitor has the cookie set and the passcode is valid in the cookie then don't show the modal
// Otherwise we open a modal for the user to enter a passcode
const checkRegistrationAvailability = async (
	id: string,
	onCorrectPasscode: (state: ('validated' | 'unvalidated')) => void,
	onClose: () => void,
	sendPasscodeEmail: (id: string) => Promise<any>,
) => {
	const modalModel = observable({
		acceptedTerms: false as boolean,
		passcode: '' as string,
	});

	// check the passcode was valid
	const checkPasscode = async () => {
		await axios.post(
			`${SERVER_URL}/api/entity/RegistrationEntity/checkPasscode`,
			{
				id: `${id}`,
				passcode: modalModel.passcode,
			},
		);
		onCorrectPasscode('validated');
		store.modal.hide();
	};

	const cookie = Cookies.get('registrationProcess');
	if (cookie !== undefined) {
		try {
			await checkPasscode();
			return true;
		} catch (exception) {
			// ignored as we will just display the modal instead
		}
	}

	const onSuccess = async () => {
		try {
			await checkPasscode();
		} catch (exception) {
			alertToast('The given passcode is incorrect', 'error');
		}
	};

	const onCancel = () => {
		// Do nothing. The user can't close the modal by clicking outside of it.
	};

	const modalDom = (
		<CheckRegistrationModal
			model={modalModel}
			onSuccess={onSuccess}
			onCancel={onClose}
			onSendEmail={() => sendPasscodeEmail(id)}
		/>
	);

	store.modal.show('Registration Process Passcode', modalDom, {
		className: 'access-modal',
		onRequestClose: onCancel,
	});

	return false;
};
export default checkRegistrationAvailability;
