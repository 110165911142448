import React, { useEffect } from 'react';
import { CustomerAuditDescription, saveCustomerAudit } from './FetchCustomerAudits';
import Modal from 'Views/Components/Modal/Modal';
import {
	Display,
	Colors,
	Sizes,
	Widths,
	Button,
} from 'Views/Components/Button/Button';
import { TextField } from 'Views/Components/TextBox/TextBox';
import alertToast from 'Util/ToastifyUtils';
import If from 'Views/Components/If/If';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { auditCategory, auditCategoryOptions, customerAuditCategory, customerAuditCategoryOptions } from 'Models/Enums';

interface Props {
	initialDescription: CustomerAuditDescription;
	customerId: string;
	openModal: boolean;
	closeModal: () => void;
	category: customerAuditCategory | auditCategory;
	refreshList?: () => void;
	isReadOnly?: boolean;
}

const CustomerAuditDescriptionModal: React.FunctionComponent<Props> = ({
	initialDescription,
	customerId,
	openModal,
	closeModal,
	category,
	refreshList,
	isReadOnly = true,
}) => {
	const [description, setDescription] = React.useState(initialDescription);

	useEffect(() => {
		setDescription(initialDescription);
	}, [initialDescription]);

	const onSave = async () => {
		try {
			await saveCustomerAudit(customerId, description, 'COMMENT');
		} catch (e) {
			alertToast('Comment could not be saved at this moment. Please'
				+ 'refresh and try again.', 'error');
			return;
		}
		alertToast('Comment saved successfully', 'success');
		closeModal();
		if (refreshList) {
			refreshList();
		}
	};

	return (
		<Modal
			className="customer-audits description-modal"
			isOpen={openModal}
			label={isReadOnly ? 'Note' : 'Add Comment'}
		>
			<header className="description-modal__header">
				<h4>
					{isReadOnly ? 
						`${customerAuditCategoryOptions[category] 
					?? auditCategoryOptions[category]} Update` 
						: 'Add Comment'}
				</h4>
			</header>
			<section className="description-modal__body">
				<TextField
					model={description}
					modelProperty="title"
					placeholder="Title"
					onAfterChange={() => {
						setDescription({ ...description, title: description.title });
					}}
					isRequired
					isReadOnly={isReadOnly}
				/>
				<TextArea
					model={description}
					modelProperty="message"
					placeholder="Input your description here..."
					onAfterChange={() => {
						setDescription({ ...description, message: description.message });
					}}
					isRequired
					isReadOnly={isReadOnly}
				/>
			</section>
			<div className="description-modal__footer">
				<Button
					buttonProps={{ style: { width: '176px', height: '48px' } }}
					type="button"
					display={isReadOnly ? Display.Solid : Display.Outline}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					widths={Widths.auto}
					onClick={closeModal}
				>
					{isReadOnly ? 'OK' : 'Cancel'}
				</Button>
				<If condition={!isReadOnly}>
					<Button
						buttonProps={{ style: { width: '176px', height: '48px' } }}
						type="button"
						display={Display.Solid}
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						widths={Widths.auto}
						onClick={onSave}
						disabled={description.title === ''
							|| description.message === ''
							|| description.message === undefined}
					>
						Add Comment
					</Button>
				</If>
			</div>
		</Modal>
	);
};

export default CustomerAuditDescriptionModal;
