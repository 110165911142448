import { EsignuserEntity } from 'Models/Entities';
import {
	Colors, Display,
} from 'Views/Components/Button/Button';
import EntityList, { IEntityListHeaderProps } from 'Views/Components/EntityList/EntityList';
import React, { useMemo, useState } from 'react';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import { AxiosError } from 'axios';

import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import { confirmModalAsync } from 'Views/Components/Modal/ModalUtils';
import { esignuserstatusOptions } from 'Models/Enums';
import ESignUserViewOtpModal, { UserAuthTokenResponse } from './Gibs/ESignUserViewOtpModal';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import classNames from 'classnames';
import requestESignUsers from 'Hooks/Api/requestESignUsers';
import { requestESignUserOtp } from 'Hooks/Api/requestESignUserOtp';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import alertToast from 'Util/ToastifyUtils';

type Props = {
	businessEntityId: string;
}

const BusinessEntityESignUser: React.FunctionComponent<Props> = ({ businessEntityId }) => {
	const ESIGN_USERS_PAGE_LENGTH = 10; // Number of ESign users to fetch per page

	const [search, setSearch] = useState('');
	const [pageNo, setPageNo] = useState(0);
	const [userAuthTokenResponse, setUserAuthTokenResponse] = useState<UserAuthTokenResponse>();

	const fetchESignUsers = async () => {
		const debouncedRequestESignUsers = AwesomeDebouncePromise(
			requestESignUsers,
			400,
			{ leading: true },
		);

		try {
			const response = await debouncedRequestESignUsers(businessEntityId, pageNo + 1, search, ESIGN_USERS_PAGE_LENGTH);
			return response;
		} catch (error) {
			alertToast('An error occurred while fetching ESign users. Please try again later.', 'error');
			throw error as AxiosError;
		}
	};

	const fetchESignUserOtp = async (userId: string) => {
		try {
			const response = await requestESignUserOtp(businessEntityId, userId);
			alertToast('OTP for ESign user has been successfully generated', 'success');
			setUserAuthTokenResponse(response);
		} catch (error) {
			alertToast('An error occurred while generating OTP for ESign user. Please try again later.', 'error');
			throw error as AxiosError;
		}
	};

	const refreshKey = useMemo(() => {
		return GetRefreshKey(search, pageNo);
	}, [pageNo, search]);

	const handleAction = async (entity: EsignuserEntity) => {
		const modal = confirmModalAsync(
			'Are you sure?',
			(
				<div>
					<p>Generating a new OTP (One-Time-Password) token for {entity.name}.</p>
					<br />
					<p>
						This action will revoke the current verification process.&nbsp;
						The user will need to verify their identity again with newly generated OTP-Code to proceed.
					</p>
				</div>
			),
		);

		try {
			const isSuccess = await modal.result();
			if (isSuccess) {
				await fetchESignUserOtp(entity.id);
			}
		} catch {
			return;
		} finally {
			modal.close();
		}
	};

	const renderErrorContent = (error: AxiosError) => {
		console.error('Error fetching ESign users', error);
		return (
			<div className="error-info__page">
				<div className="error-info__elements">
					<div className="error-info__info">
						<h3>There has been an error</h3>
						<p>Unable to load the page at this time. Please contact us if the problem persists.</p>
					</div>
				</div>
			</div>
		);
	};

	const renderActionButton = (entity: EsignuserEntity) => {
		return (
			<ButtonAsyncState
				display={Display.Solid}
				colors={Colors.Primary}
				readonly={entity.status !== 'ACTIVE'}
				onPress={() => handleAction(entity)}
			>
				Generate OTP
			</ButtonAsyncState>
		);
	};

	const columns: IEntityListHeaderProps<EsignuserEntity>[] = [
		{
			value: (entity: EsignuserEntity) => entity.name,
			displayName: 'Name',
			columnName: 'name',
			showNA: true,
		},

		{
			value: entity => entity.email.toLowerCase(),
			displayName: 'Email Address',
			columnName: 'email',
			showNA: true,
		},
		{
			value: entity => entity.phone,
			displayName: 'Phone Number',
			columnName: 'phone',
			showNA: true,
		},
		{
			value: entity => esignuserstatusOptions[entity.status],
			displayName: 'Status',
			columnName: 'status',
			showNA: true,
		},
		{
			value: renderActionButton,
			displayName: 'Action',
			columnName: 'action',
			showNA: true,
		},
	];

	return (
		<div className="customer-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						labelVisible={false}
						onAfterChange={() => setPageNo(0)}
						placeholder="Search by Email Address..."
					/>
				</div>
			</div>
			<div className="collection-component">
				<RequestWrap
					refreshKey={refreshKey}
					request={fetchESignUsers}
					loadingContent={<InlineSpinner />}
					errorContent={renderErrorContent}
				>
					{(response: {data: EsignuserEntity[], count: number}) => (
						<>
							{(response.count < 1 && search.length < 0) && (
								<p>Please come back later. We couldn&apos;t find any esign users at the moment.</p>
							)}
							{(response.count < 1 && search.length > 0) && (
								<p>No users found matching the email address {search}</p>
							)}
							{response.count > 0 && (
								<>
									<EntityList
										collection={response.data}
										idColumn="id"
										columns={columns}
										sortColumn="name"
										sortDescending
									/>
									{response.count > ESIGN_USERS_PAGE_LENGTH && (
										<TablePagination
											perPage={ESIGN_USERS_PAGE_LENGTH}
											pageNo={pageNo}
											totalRecords={response.count}
											onPageChange={setPageNo}
										/>
									)}
								</>
							)}
						</>
					)}
				</RequestWrap>
			</div>
			{!!userAuthTokenResponse && (
				<ESignUserViewOtpModal
					userAuthTokenResponse={userAuthTokenResponse}
					closeModal={() => setUserAuthTokenResponse(undefined)}
				/>
			)}
		</div>

	);
};

export default BusinessEntityESignUser;
