import React, { useMemo } from 'react';
import { Container } from 'semantic-ui-react';

/**
 * Regular expression for matching digits.
 */
// eslint-disable-next-line prefer-regex-literals
export const REGEX_DIGIT = new RegExp(/^\d+$/);

interface Props {
	value: string;
	onChange: (value: string) => void;
	valueLength: number;
}

/**
 * Functional component for rendering an OTP input field.
 *
 * @param {Props} props - The props passed to the OtpInput component.
 * @param {string} props.value - The value of the OTP input.
 * @param {(value: string) => void} props.onChange - The callback function invoked when the OTP input changes.
 * @param {number} props.valueLength - The length of the OTP value.
 * @returns {JSX.Element} - Returns the JSX representation of the OtpInput component.
 *
 * @example
 * // Rendering an OtpInput component with required props
 * <OtpInput
 *     value="123456"
 *     onChange={handleChange}
 *     valueLength={6}
 * />
 */
const OtpInput: React.FunctionComponent<Props> = ({ value, valueLength, onChange }) => {
	/**
     * Memoized array of individual value items for the OTP input.
     */
	const valueItems = useMemo(() => {
		const valueArray = value.split('');
		const items: Array<string> = [];

		for (let i = 0; i < valueLength; i++) {
			const char = valueArray[i];

			if (REGEX_DIGIT.test(char)) {
				items.push(char);
			} else {
				items.push('');
			}
		}

		return items;
	}, [value, valueLength]);

	/**
     * Focuses on the next input element.
     *
     * @param {HTMLElement} target - The current input element.
     */
	const focusToNextInput = (target: HTMLElement) => {
		const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

		if (nextElementSibling) {
			nextElementSibling.focus();
		}
	};

	/**
     * Focuses on the previous input element.
     *
     * @param {HTMLElement} target - The current input element.
     */
	const focusToPrevInput = (target: HTMLElement) => {
		const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	/**
     * Event handler for input change.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
     * @param {number} idx - The index of the input element.
     */
	const inputOnChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number,
	) => {
		const { target } = e;
		let targetValue = target.value.trim();
		const isTargetValueDigit = REGEX_DIGIT.test(targetValue);

		if (!isTargetValueDigit && targetValue !== '') {
			return;
		}

		const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

		// only delete digit if next input element has no value
		if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : ' ';

		const targetValueLength = targetValue.length;

		if (targetValueLength === 1) {
			const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

			onChange(newValue);

			if (!isTargetValueDigit) {
				return;
			}

			focusToNextInput(target);
		} else if (targetValueLength === valueLength) {
			onChange(targetValue);

			target.blur();
		}
	};

	/**
     * Event handler for key down.
     *
     * @param {React.KeyboardEvent<HTMLInputElement>} e - The key down event.
     */
	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { key } = e;
		const target = e.target as HTMLInputElement;

		if (key === 'ArrowRight' || key === 'ArrowDown') {
			e.preventDefault();
			return focusToNextInput(target);
		}

		if (key === 'ArrowLeft' || key === 'ArrowUp') {
			e.preventDefault();
			return focusToPrevInput(target);
		}

		const targetValue = target.value;

		// keep the selection range position
		// if the same digit was typed
		target.setSelectionRange(0, targetValue.length);

		if (e.key !== 'Backspace' || targetValue !== '') {
			return;
		}

		focusToPrevInput(target);
	};

	/**
     * Event handler for input focus.
     *
     * @param {React.FocusEvent<HTMLInputElement>} e - The focus event.
     */
	const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const { target } = e;

		// keep focusing back until previous input
		// element has value
		const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

		if (prevInputEl && prevInputEl.value === '') {
			return prevInputEl.focus();
		}

		target.setSelectionRange(0, target.value.length);
	};

	return (
		<Container className="otp-container" textAlign="center">
			<div className="otp-group">
				{valueItems.map((digit, index) => (
					<input
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						type="text"
						inputMode="numeric"
						autoComplete="one-time-code"
						pattern="\d{1}"
						maxLength={valueLength}
						className="otp-input"
						value={digit}
						onChange={e => inputOnChange(e, index)}
						onKeyDown={inputOnKeyDown}
						onFocus={inputOnFocus}
					/>
				))}
			</div>
		</Container>
	);
};

export default OtpInput;
