import { SERVER_URL } from 'Constants';
import useAsync from 'Hooks/useAsync';
import { DashboardApiResponse } from 'Views/Pages/Monitor/MonitorDashboardPage';
import axios from 'axios';

type UseDashboardDataArgs = {
	organisationId: string,
	businessIds: string[],
}

export default function useDashboardData(args: UseDashboardDataArgs) {
	const { businessIds, organisationId } = args;

	const request = useAsync<DashboardApiResponse | undefined>(async () => {
		if (businessIds === undefined || businessIds.length === 0) return undefined;
		const queryString = `${businessIds.map(id => `businessIds=${encodeURIComponent(id)}`).join('&')}`;
		const res = await axios.get<DashboardApiResponse>(`${SERVER_URL}/api/AgedDebtData/dashboard-data?${queryString}`);
		return res.data;
	}, [organisationId, businessIds]);

	return request;
}
