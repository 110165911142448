/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
	Button,
	Display,
	Sizes,
	Colors,
} from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { TextField } from '../Components/TextBox/TextBox';
import { store } from 'Models/Store';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { isEmail } from 'Validators/Functions/Email';
import alert from '../../Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { resetPasswordRequest } from 'Services/Api/AccountService';
// % protected region % [Add any extra imports here] on begin
import AccessGroupLogo from 'Assets/Images/Access_logo.svg';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any extra imports here] end

interface IResetRequestState {
	username: string;
	errors: { [attr: string]: string };
	// % protected region % [Add extra reset password request state properties here] off begin
	// % protected region % [Add extra reset password request state properties here] end
}

const defaultResetRequestState: IResetRequestState = {
	username: '',
	errors: {},
	// % protected region % [Instantiate extra reset password request state properties here] off begin
	// % protected region % [Instantiate extra reset password request state properties here] end
};

@observer
export default class ResetPasswordRequestPage extends React.Component<RouteComponentProps> {
	@observable
	private ResetRequestState: IResetRequestState = defaultResetRequestState;

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		contents = (
			<div className="body-content login-page">
				<div className="login-form">
					<form className="reset-password" onSubmit={this.onResetPasswordClicked}>
						<div className="logo-container">
							<img alt="Access Intell Group Logo" src={AccessGroupLogo} />
						</div>
						<div className="login-title forgot-title">
							<h5>Forgotten Password</h5>
						</div>
						<div className="forgot-password-desc">
							<p>Enter your email address and we will send a link to reset your password</p>
						</div>
						<TextField
							id="username"
							className="username"
							model={this.ResetRequestState}
							modelProperty="username"
							placeholder="Email"
							inputProps={{ autoComplete: 'username' }}
							isRequired
							errors={this.ResetRequestState.errors.username}
						/>
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="reset-pwd-buttons">
							<Button
								className="request-pwd btn--primary"
								type="submit"
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'reset_password' }}
								disabled={this.currentRequestCount > 0}
							>
								{this.currentRequestCount > 0 ? 'Sending' : 'Submit'}
							</Button>
						</ButtonGroup>
					</form>
				</div>
				<div className="login-gradient">
					<div className="login-pattern" />
				</div>
			</div>
		);
		// % protected region % [Override contents here] end
		return contents;
	}

	@action
	private onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onResetPasswordClicked here] on begin
		event.preventDefault();

		this.ResetRequestState.errors = {};

		if (!this.ResetRequestState.username) {
			this.ResetRequestState.errors.username = 'Email Address is required';
		} else if (!isEmail(this.ResetRequestState.username)) {
			this.ResetRequestState.errors.username = 'This is not a valid email address';
		}

		if (Object.keys(this.ResetRequestState.errors).length <= 0) {
			this.currentRequestCount++;
			axios.post(
				`${SERVER_URL}/api/account/reset-password-request`,
				{
					username: this.ResetRequestState.username,
				},
			)
				.then(() => {
					this.onResetPasswordSent();
				})
				.catch(response => {
					const errorMessages = getErrorMessages(response).map((error: any) => (error.message));
					alert(errorMessages, 'error');
				})
				.finally(action(() => {
					this.currentRequestCount--;
				}));
		}
		// % protected region % [Override onResetPasswordClicked here] end
	};

	@action
	private onCancelResetClicked: React.MouseEventHandler = () => {
		// % protected region % [Override onCancelResetClicked here] off begin
		store.routerHistory.push('/login');
		// % protected region % [Override onCancelResetClicked here] end
	};

	@action
	private onResetPasswordSent = () => {
		// % protected region % [Override onResetPasswordSent here] on begin
		alert('Reset Password Email has been sent.', 'success');
		store.routerHistory.push('/login');
		// % protected region % [Override onResetPasswordSent here] end
	};

	// % protected region % [Add class methods here] on begin
	@observable currentRequestCount: number = 0;

	// % protected region % [Add class methods here] end
}
