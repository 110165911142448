/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Override IconPositions here] on begin
export type IconPositions = 'icon-top' | 'icon-left' | 'icon-bottom' | 'icon-right' | 'icon-only';
// % protected region % [Override IconPositions here] end

// % protected region % [Override IIconProps here] off begin
export interface IIconProps {
	icon?: string;
	iconPos?: IconPositions;
}
// % protected region % [Override IIconProps here] end

// % protected region % [Override EntityFormMode here] off begin
export enum EntityFormMode {
	VIEW = 'view',
	CREATE = 'create',
	EDIT = 'edit'
}
// % protected region % [Override EntityFormMode here] end

// % protected region % [Override AttributeFormMode here] off begin
export enum AttributeFormMode {
	VIEW = 'view',
	EDIT = 'edit',
	HIDE = 'hide'
}
// % protected region % [Override AttributeFormMode here] end
