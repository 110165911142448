import * as React from 'react';
import Axios from 'axios';
import FileUpload from 'Views/Components/FileUpload/FileUpload';
import { Model } from 'Models/Model';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileUploadPreview, UploadPreview } from 'Views/Components/FileUpload/UploadPreview';
import type { AttributeFileProps } from 'Views/Components/CRUD/Attributes/AttributeFile';
import { IAttributeProps } from 'Views/Components/CRUD/Attributes/IAttributeProps';

/*
 * This is the botwritten AttributeFile.tsx, but with some sizable changes. I didn't want to mess with the botwritten
 * component too much, so I made it a separate component
 */

export interface CustomAttributeFileProps<T extends Model> extends AttributeFileProps<T> {
	tooltip?: string;
	disableDelete?: boolean;
	onAfterDelete?: () => void;
	contentType?: string;
}

// Redeclaring since it wasn't exported from AttributeFile.tsx
interface FileMetadata {
	id: string;
	created: string;
	modified: string;
	fileName: string;
	contentType: string;
	length: number;
}

@observer
export default class CustomAttributeFile<T extends Model> extends React.Component<CustomAttributeFileProps<T>> {
	protected readonly initialFileId?: string;

	@observable
	protected fileMetadata?: FileMetadata;

	@action
	protected onFetchSucceeded = (metadata: FileMetadata) => {
		this.fileMetadata = metadata;
	};

	constructor(props: AttributeFileProps<T>) {
		super(props);

		const { model, options } = this.props;

		if (model[options.attributeName]) {
			this.initialFileId = model[options.attributeName];
		}
	}

	protected loadFile = () => {
		const fileId = this.props.model[this.props.options.attributeName];
		if (fileId) {
			Axios.get(`${SERVER_URL}/api/files/metadata/${fileId}`)
				.then(x => x.data)
				.then(metadata => this.onFetchSucceeded(metadata));
		}
	};

	public componentDidMount() {
		const { formMode } = this.props;

		if (formMode === EntityFormMode.VIEW || formMode === EntityFormMode.EDIT) {
			this.loadFile();
		}
	}

	public render() {
		const {
			fileAttribute,
			isReadonly,
			imageOnly,
			model,
			isRequired,
			onAfterChange,
			onAfterDelete,
			className,
			errors,
			options,
			maxFileSize,
			tooltip,
			disableDelete,
			contentType,
		} = this.props;

		return (
			<FileUpload
				preview={(_file, onDelete) => {
					const file = _file || model[fileAttribute] as File;

					if (!file && model[options.attributeName]) {
						let url = `${SERVER_URL}/api/files/${model[options.attributeName]}`;
						if (!imageOnly) {
							url += '?download=true';
						}

						return (
							<UploadPreview
								fileUrl={url}
								onDelete={onDelete}
								imagePreview={imageOnly}
								fileName={this.fileMetadata?.fileName}
								download
							/>
						);
					}

					if (file) {
						return (
							<FileUploadPreview
								fileBlob={file}
								onDelete={onDelete}
								imagePreview={imageOnly}
								fileName={file.name}
								download
							/>
						);
					}

					return (
						<div className="upload__file preview empty">
							No file selected.
						</div>
					);
				}}
				model={model}
				modelProperty={fileAttribute}
				imageUpload={imageOnly}
				label={options.displayName}
				errors={errors}
				className={className}
				isReadOnly={isReadonly}
				isRequired={isRequired}
				contentType={contentType}
				onAfterChange={(event: any) => {
					// Set the file metadata based on our file
					// We do this so that we have a filename in our preview
					const file = event as File;
					this.onFetchSucceeded({
						id: '',
						created: '',
						modified: '',
						fileName: file.name,
						contentType: file.type,
						length: file.size,
					});

					runInAction(() => {
						model[fileAttribute] = file;
					});

					if (!!onAfterChange) {
						onAfterChange(event);
					}
				}}
				// onAfterDelete={this.onAfterDelete}
				onAfterDelete={() => {
					runInAction(() => {
						model[fileAttribute] = undefined;
					});

					if (!!onAfterDelete) {
						onAfterDelete();
					}
				}}
				maxFileSize={maxFileSize}
				tooltip={tooltip}
				disableDelete={disableDelete}
			/>
		);
	}
}
