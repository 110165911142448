import If from 'Views/Components/If/If';
import moment from 'moment/moment';
import RiskScoreBox from 'Views/Components/Intel/RiskScoreBox';
import { insolvencyRiskToTooltip } from 'Models/Enums';
import * as React from 'react';
import { AlaresReport } from 'Models/Interfaces/AlaresReport';
import { CustomerEntity } from 'Models/Entities';

const AdverseAlertsBusinessSummary = (props: {
	customer: CustomerEntity,
	alaresReport?: AlaresReport,
}) => {
	const { customer, alaresReport } = props;
	const findFormerNames = (report?: AlaresReport) => {
		let formerNames = Array.prototype.concat.apply(
			[],
			Object.values(report?.cases ?? [])
				.map(c => c.other_names.split(',')),
		);
		formerNames = formerNames
			.map(t => t.trim())
			.filter((value, index, array) => array.indexOf(value) === index);
		return formerNames.join(', ');
	};

	const findNames = (report?: AlaresReport) => {
		const names = Object.values(report?.cases ?? [])
			.map(x => x.name.trim())
			.filter((value, index, array) => array.indexOf(value) === index);
		return names.join(', ');
	};

	return (
		<div className="section business-summary">
			<div className="business-details">
				<h5>Business details</h5>
				<div className="fields">
					<p className="field"><b>Name:</b> {findNames(alaresReport)}</p>
					<p className="field"><b>Former names:</b> {findFormerNames(alaresReport)}</p>
					<p className="field"><b>ABN:</b> {customer.abn}</p>
					<If condition={!!customer.acn}>
						<p className="field"><b>ACN:</b> {customer.acn}</p>
					</If>
					<If condition={!!customer.trusteeACN}>
						<p className="field"><b>Trustee ACN:</b> {customer.trusteeACN}</p>
					</If>
					<p className="field">
						<b>Extract date:</b> {moment(alaresReport?.created_at).format('Do MMMM YYYY')}
					</p>
				</div>
			</div>
			<div className="insolvency-risk">
				<h5>Insolvency Risk Score</h5>
				<RiskScoreBox score={customer.newestAlares?.insolvencyRiskRating} />
				<p className="insolvency-risk-tooltip">
					{insolvencyRiskToTooltip[customer.newestAlares?.insolvencyRiskRating]}
				</p>
			</div>
		</div>
	);
};

export default AdverseAlertsBusinessSummary;
