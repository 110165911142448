import * as React from 'react';
import { AlaresLicence } from 'Models/Interfaces/AlaresReport';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import AdverseAlertsTable from './AdverseAlertsTable';
import moment from 'moment';
import If from 'Views/Components/If/If';

export interface AdverseAlertsLicenceDetailsModalProps {
	licence: AlaresLicence,
	onClose: () => void,
}

const AdverseAlertsLicenceDetailsModal = (props: AdverseAlertsLicenceDetailsModalProps) => {
	const { licence, onClose } = props;

	return (
		<Modal
			isOpen
			label={`Licence: ${licence.number}`}
			onRequestClose={onClose}
			className="access-modal adverse-alerts-details-modal"
		>
			<div className="top-row">
				<h4>Licence: {licence.number}</h4>
			</div>

			<div className="content">
				<div className="fields">
					<p className="field"><b>State:</b> {!licence.state ? 'N/A' : licence.state}</p>
					<p className="field"><b>Authority:</b> {!licence.authority ? 'N/A' : licence.authority}</p>
					<p className="field"><b>Number:</b> {!licence.number ? 'N/A' : licence.number}</p>
					<p className="field"><b>Holder:</b> {!licence.licence_holder ? 'N/A' : licence.licence_holder}</p>
					<p className="field"><b>Address:</b> {!licence.address ? 'N/A' : licence.address}</p>
					<p className="field"><b>Category:</b> {!licence.category ? 'N/A' : licence.category}</p>
					<p className="field">
						<b>Notification date:</b> {!licence.notification_time
							? 'N/A'
							: moment(licence.notification_time).format('Do MMMM YYYY')}
					</p>
					<If condition={!!licence.url && !licence.url.includes('alares')}>
						<p className="field"><b>Licence Search:</b> <a href={licence.url}>Link</a></p>
					</If>
				</div>

				<AdverseAlertsTable
					tableName="Classes"
					collection={licence.classes ?? []}
					columns={[
						{
							displayName: 'Class',
							columnName: 'class',
							value: licenceClass => licenceClass.class,
							sortable: false,
							className: 'field-class',
						},
						{
							displayName: 'Grade',
							columnName: 'grade',
							value: licenceClass => licenceClass.grade,
							sortable: false,
							className: 'field-grade',
						},
						{
							displayName: 'Status',
							columnName: 'status',
							value: licenceClass => licenceClass.status,
							sortable: false,
							className: 'field-status',
						},
						{
							displayName: 'Details',
							columnName: 'details',
							value: licenceClas => licenceClas.details,
							sortable: false,
							className: 'field-details',
						},
					]}
					sortColumn="status"
					sortDescending
					idColumn="unique_id"
				/>
				<AdverseAlertsTable
					tableName="Parties"
					collection={licence.parties.sort((a, b) => {
						return moment(b.start_date, 'YYYY-MM-DD').toDate().getTime()
							- moment(a.start_date, 'YYYY-MM-DD').toDate().getTime();
					}) ?? []}
					columns={[
						{
							displayName: 'Start date',
							columnName: 'date',
							value: party => moment(party.start_date, 'YYYY-MM-DD').format('Do MMM YYYY'),
							sortable: false,
							className: 'field-date',
						},
						{
							displayName: 'End date',
							columnName: 'endDate',
							value: party => !party.end_date
								? null
								: moment(party.end_date, 'YYYY-MM-DD').format('Do MMM YYYY'),
							sortable: false,
							className: 'field-date',
						},
						{
							displayName: 'Name',
							columnName: 'name',
							value: party => party.name,
							sortable: false,
							className: 'field-name',
						},
						{
							displayName: 'Type',
							columnName: 'type',
							value: party => party.type,
							sortable: false,
							className: 'field-type',
						},
						{
							displayName: 'Role',
							columnName: 'role',
							value: party => party.role,
							sortable: false,
							className: 'field-role',
						},
						{
							displayName: 'Address',
							columnName: 'address',
							value: party => party.address,
							sortable: false,
							className: 'field-address',
						},
						{
							displayName: 'ABN',
							columnName: 'abn',
							value: party => party.abn,
							sortable: false,
							className: 'field-abn',
						},
						{
							displayName: 'ACN',
							columnName: 'acn',
							value: party => party.acn,
							sortable: false,
							className: 'field-acn',
						},
					]}
					sortColumn="start_date"
					sortDescending
					idColumn="name"
				/>
			</div>

			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
			</div>
		</Modal>
	);
};

export default AdverseAlertsLicenceDetailsModal;
