import * as React from 'react';
import { useState } from 'react';
import { AtbFileEntity, AtbRecordEntity } from 'Models/Entities';
import useAsync from 'Hooks/useAsync';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import If from '../If/If';
import { saveAs } from 'file-saver';
import { SERVER_URL } from 'Constants';
import { ProcessingErrorInputType, ProcessingErrorType } from 'Models/Enums';
import { runInAction } from 'mobx';
import axios from 'axios';
import ProcessingErrorList from './ProcessingErrorList';
import { Button, Colors, Display } from '../Button/Button';
import { ButtonGroup } from '../Button/ButtonGroup';
import alertToast from 'Util/ToastifyUtils';
import copyTextToClipboard from 'Util/CopyTextToClipboard';
import { CalculatePricing, PricingDetail } from 'Util/PricingHelper';
import { LocaleFormatCurrency } from 'Util/StringUtils';

interface AgedTrialBalanceDetailProps {
	fileUploading: boolean,
	atbFileEntity: AtbFileEntity,
	currentPricingDetail: PricingDetail|null,
	onPriceIncreaseAcceptRequired: (required: boolean) => void
	onNoErrors: () => void,
	onClose: () => void,
}

export interface ProcessingErrorRow {
	AtbRecordEntityId: string;
	ErrorId: string;
	Row: number;
	Customer: string;
	Error: ProcessingError;
}
export interface ProcessingError {
	Type: ProcessingErrorType;
	InputType: ProcessingErrorInputType;
	Field: string;
	Column: string;
	Detail: string|undefined;
	Value: string|undefined;
	Required: boolean;
}

interface CustomerCredits {
	CurrentPrice: number;
	NewCustomers: number;
	NewPrice?: number;
	NewCustomerCredits?: number;
	RemainingCustomerCredits: number;
}

function CustomerPriceNotification(props: { CalculatedCustomerCredits: CustomerCredits|null }) {
	const { CalculatedCustomerCredits } = props;

	if (!CalculatedCustomerCredits) {
		return null;
	}

	const {
		CurrentPrice,
		NewPrice,
		RemainingCustomerCredits,
	} = CalculatedCustomerCredits;

	if (RemainingCustomerCredits === -1) {
		// The client is in the maximum tier
		return null;
	}
	if (NewPrice === undefined) {
		// The client's billing is not changing
		return null;
	}

	return (
		<p className="alert-box info">
			Uploading new customers<br />
			Proceeding with this file upload will increase your monthly subscription
			from <b>{LocaleFormatCurrency(CurrentPrice)}</b> to <b>{LocaleFormatCurrency(NewPrice)}</b> and
			give you <b>access to an extra {RemainingCustomerCredits} new customers</b>
		</p>
	);
}

const maxNumberOfErrorRecords = 100;

const GetErrorId = (atbRecordEntityId: string, column: string, type: string) => {
	return btoa([atbRecordEntityId, column, type].join('-'));
};

const GetProcessingRowErrors = (atbRecord: AtbRecordEntity): ProcessingErrorRow[] => {
	const { Row } = JSON.parse(atbRecord.originalData || '{}');
	return (JSON.parse(atbRecord.processingErrors || '[]') as ProcessingError[])
		.map(error => {
			return ({
				ErrorId: GetErrorId(atbRecord.id, error.Column, error.Type),
				AtbRecordEntityId: atbRecord.id,
				Row: Row,
				Customer: atbRecord.customerName,
				Error: error,
			} as ProcessingErrorRow);
		});
};

const AgedTrialBalanceDetail = (props: AgedTrialBalanceDetailProps) => {
	const {
		fileUploading,
		atbFileEntity,
		currentPricingDetail,
		onPriceIncreaseAcceptRequired,
		onNoErrors,
		onClose,
	} = props;

	const [calculatedCustomerCredits, setCalculatedCustomerCredits] = useState<CustomerCredits|null>(null);
	const [expandedErrorRows, setExpandedErrorRows] = useState<string[]>([]);
	const [lastResolvedErrorRow, setLastResolvedErrorRow] = useState<string|null>(null);

	const response = useAsync(
		async (): Promise<ProcessingErrorRow[]> => {
			if (!currentPricingDetail
				|| !atbFileEntity.id
				|| atbFileEntity.atbJobStatus !== 'PROCESSED'
				|| (
					atbFileEntity.atbFileType === 'STANDARD'
					&& atbFileEntity.countAtbRecordErrors >= maxNumberOfErrorRecords
				)
			) {
				return [];
			}

			const results = await store.apolloClient.query({
				query: gql`
					query fetchAtbRecordEntitys($atbFileEntityId: String) {
						atbRecordEntitys (where: [
							{path: "atbFileId", comparison: equal, value: [$atbFileEntityId]},
							{path: "processingErrors", comparison: equal, value: null, negate: true}
						]) {
							id
							customerName
							originalData
							processingErrors
						}
						countAtbRecordEntityProcessingErrors (where: [
							{path: "atbFileId", comparison: equal, value: [$atbFileEntityId]},
							{path: "processingErrors", comparison: equal, value: null, negate: true}
						]) {
							number
						}
						countAtbRecordEntityNewCustomers (where: [
							{path: "atbFileId", comparison: equal, value: [$atbFileEntityId]}
						]) {
							number
						}
					}
				`,
				variables: {
					atbFileEntityId: atbFileEntity.id,
				},
				fetchPolicy: 'no-cache',
			});

			runInAction(() => {
				atbFileEntity.countAtbRecordErrors = results.data.countAtbRecordEntityProcessingErrors.number;
			});

			setCalculatedCustomerCredits(
				await calculateCustomerCredits(
					currentPricingDetail,
					calculatedCustomerCredits,
					results.data.countAtbRecordEntityNewCustomers.number,
				),
			);

			const errors: ProcessingErrorRow[] = (results.data.atbRecordEntitys as AtbRecordEntity[])
				.reduce(
					(list, atbRecordEntity) => (
						list.concat(GetProcessingRowErrors(atbRecordEntity))
					),
					[] as ProcessingErrorRow[],
				)
				.sort((a, b) => {
					const diff = a.Row - b.Row;
					if (diff === 0) {
						return a.Error.InputType === 'Customer' ? 1 : -1;
					}
					return diff;
				});

			if (!errors.length) {
				setLastResolvedErrorRow(null);

				if (!!onNoErrors) {
					onNoErrors();
				}

				return [];
			}

			if (errors.some(e => e.ErrorId === lastResolvedErrorRow)) {
				alertToast('Error unable to be resolved.', 'error');
			}

			setLastResolvedErrorRow(null);
			setExpandedErrorRows(oldExpandedErrorsIds => updateExpandedErrorsGivenNewErrorList(
				oldExpandedErrorsIds,
				errors.map(errorRow => errorRow.ErrorId),
			));

			return errors;
		},
		[atbFileEntity.id, atbFileEntity.atbJobStatus, currentPricingDetail],
		{ suppressSubsequentLoadingState: true },
	);

	const onClickDownloadErrorReport = React.useCallback(() => {
		if (!atbFileEntity.id) {
			return;
		}

		saveAs(
			`${SERVER_URL}/api/entity/AtbFileEntity/${atbFileEntity.id}/error-report`,
		);
	}, [atbFileEntity.id]);

	const handleCopyLink = React.useCallback(() => {
		// eslint-disable-next-line no-restricted-globals
		copyTextToClipboard(location.href);
	}, []);

	const onResolveError = React.useCallback(
		async (atbRecordEntityId: string, processingError: ProcessingError, value: string|undefined) => {
			setLastResolvedErrorRow(GetErrorId(atbRecordEntityId, processingError.Column, processingError.Type));

			const resolvedProcessingError = {
				...processingError,
				Value: value,
			};

			try {
				await axios.post(
					`${SERVER_URL}/api/entity/AtbRecordEntity/${atbRecordEntityId}/resolve-error`,
					resolvedProcessingError,
				);
			} catch (e: any) {
				if (e?.response?.status === 400 && e?.response?.data) {
					alertToast(e?.response?.data, 'error');
					return;
				}
				alertToast('Error saving change. Please try again.', 'error');
				console.error(e);
				return;
			}

			response.refresh();
		},
		[response],
	);

	const deleteAtbRow = React.useCallback(async (atbRecordEntityId: string) => {
		try {
			await store.apolloClient.mutate({
				mutation: gql`
				mutation {
					deleteAtbRecordEntity (atbRecordEntityIds: ["${atbRecordEntityId}"]) {
						id
					}
				}
			`,
				fetchPolicy: 'network-only',
			});
		} catch (e) {
			alertToast('Row could not be deleted, please refresh and try again', 'error');
			console.error(e);
		}
		response.refresh();
	}, [response]);

	const onToggleExpand = React.useCallback((errorId: string) => {
		setExpandedErrorRows(prevState => {
			if (prevState.includes(errorId)) {
				return prevState.filter(prevErrorId => prevErrorId !== errorId);
			}

			return [...prevState, errorId];
		});
	}, []);

	const updateExpandedErrorsGivenNewErrorList = React.useCallback(
		(oldExpandedErrorsIds: string[], newAllErrorsIds: string[]): string[] => {
			const prevAllErrorIds = response.data?.map(errorRow => errorRow.ErrorId) ?? [];

			const newAllErrorsIdsLookup = {};
			newAllErrorsIds.forEach(errorId => {
				newAllErrorsIdsLookup[errorId] = true;
			});

			const newExpandedErrorIds = [];

			oldExpandedErrorsIds.forEach(oldErrorId => {
				if (oldErrorId in newAllErrorsIdsLookup) {
					newExpandedErrorIds.push(oldErrorId);
					return;
				}

				// If we don't find the oldErrorId, iterate over the old list of errors starting
				// from the position of oldErrorId and add the next errorId if it exists in
				// the new list of errors.
				const oldIndex = prevAllErrorIds.findIndex(errorId => oldErrorId === errorId);
				for (let i = oldIndex + 1; i < prevAllErrorIds.length; i++) {
					if (prevAllErrorIds[i] in newAllErrorsIdsLookup) {
						newExpandedErrorIds.push(prevAllErrorIds[i]);
						return;
					}
				}
			});

			if (!newExpandedErrorIds.length) {
				newExpandedErrorIds.push(newAllErrorsIds[0]);
			}

			return newExpandedErrorIds;
		},
		[response.data],
	);

	const calculateCustomerCredits = React.useCallback(async (
		_currentPricingDetail: PricingDetail,
		lastCalculatedCustomerCredits: CustomerCredits|null,
		newCustomerCount: number,
	) => {
		if (lastCalculatedCustomerCredits?.NewCustomers === newCustomerCount) {
			return lastCalculatedCustomerCredits;
		}

		if (newCustomerCount === 0) {
			onPriceIncreaseAcceptRequired(false);
			return null;
		}

		if (!_currentPricingDetail.customerCountBucket) {
			onPriceIncreaseAcceptRequired(false);

			// We're already in the largest bucket
			return {
				CurrentPrice: _currentPricingDetail.total,
				NewCustomers: newCustomerCount,
				RemainingCustomerCredits: -1,
			};
		}

		if ((_currentPricingDetail.customerCount + newCustomerCount) <= _currentPricingDetail.customerCountBucket) {
			onPriceIncreaseAcceptRequired(false);

			return {
				CurrentPrice: _currentPricingDetail.total,
				NewCustomers: newCustomerCount,
				RemainingCustomerCredits: (
					_currentPricingDetail.customerCountBucket - _currentPricingDetail.customerCount
				),
			};
		}

		onPriceIncreaseAcceptRequired(true);

		const newPricingDetail = await CalculatePricing({
			OrganisationId: store.getUser?.organisation?.id,
			CustomerCountDelta: newCustomerCount,
		});

		return {
			CurrentPrice: _currentPricingDetail.total,
			NewCustomers: newCustomerCount,
			NewPrice: newPricingDetail.total,
			NewCustomerCredits: newPricingDetail.customerCountBucket,
			RemainingCustomerCredits: newPricingDetail.customerCountBucket - newPricingDetail.customerCount,
		};
	}, [onPriceIncreaseAcceptRequired]);

	if (response.type === 'error') {
		console.error(response.error);
		return (
			<p>An error has occurred.</p>
		);
	}

	if (fileUploading || atbFileEntity?.atbJobStatus !== 'PROCESSED' || response.type === 'loading') {
		let loadingText = 'Loading';
		let loadingBody = null;

		if (fileUploading) {
			loadingText = 'Uploading';
			loadingBody = (
				<div className="loading-message">
					Please don&apos;t close this page until the upload is complete.
				</div>
			);
		} else if (atbFileEntity?.atbJobStatus !== 'PROCESSED') {
			const { processedAtbRecords, totalAtbRecords } = atbFileEntity;
			loadingText = atbFileEntity?.atbJobStatus === 'PROCESSING'
				? `Processing ${Math.round((processedAtbRecords / totalAtbRecords) * 100)}%`
				: 'Queued for processing';
			loadingBody = (
				<div className="loading-message">
					An email will be sent to you once the processing is complete.<br />
					<br />
					You can safely refresh, or close this page without losing any progress.
					Copy the link to this page to comeback.<br />
					<br />
					<ButtonGroup>
						<Button
							colors={Colors.Primary}
							display={Display.Outline}
							onClick={handleCopyLink}
						>
							Copy Link
						</Button>
						<Button
							colors={Colors.Primary}
							display={Display.Outline}
							onClick={onClose}
						>
							Close
						</Button>
					</ButtonGroup>
				</div>
			);
		}

		return (
			<div className="agedtrialbalancedetail">
				<h3>Error Report</h3>
				<Loader active inline="centered">{loadingText}</Loader>
				{loadingBody}
			</div>
		);
	}

	if ((atbFileEntity.atbFileType === 'STANDARD' || atbFileEntity.atbFileType === 'CUSTOMERS_ONLY')
		&& atbFileEntity.countAtbRecordErrors >= maxNumberOfErrorRecords) {
		return (
			<div className="agedtrialbalancedetail errors">
				<h3>Error Report</h3>
				<div className="information">
					<span className="icon icon-information icon-only">
						{atbFileEntity.countAtbRecordErrors} errors found.
					</span>
					<button
						type="button"
						className="download-error-report btn btn--text"
						onClick={onClickDownloadErrorReport}
					>
						Download Error Report
					</button>
				</div>
				<div className="container too-many-errors">
					<p>
						<b>Too many errors have been found that can be corrected here.</b><br />
						Please download the error report, make the required corrections in your original file,
						and upload it again.<br />
						<br />
						<button
							type="button"
							className="btn btn--solid btn--primary"
							onClick={onClickDownloadErrorReport}
						>
							Download Error Report
						</button>
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className={['agedtrialbalancedetail', !atbFileEntity.countAtbRecordErrors ? '' : 'errors'].join(' ')}>
			<h3>Error Report</h3>
			<div className="information">
				<span className={[
					'icon icon-only',
					!atbFileEntity.countAtbRecordErrors
						? 'icon-check-circle'
						: 'icon-warning',
				].join(' ')}
				>
					{atbFileEntity.countAtbRecordErrors}&nbsp;
					error{atbFileEntity.countAtbRecordErrors !== 1 ? 's' : ''}&nbsp;
					found.
				</span>
				<If condition={!!response.data.length}>
					<button
						type="button"
						className="download-error-report btn btn--text"
						onClick={onClickDownloadErrorReport}
					>
						Download Error Report
					</button>
				</If>
			</div>
			<div className="container">
				<ProcessingErrorList
					atbFileEntityId={atbFileEntity.id}
					businessEntityId={atbFileEntity.businessEntityId}
					errorRows={response.data}
					expandedErrorRows={expandedErrorRows}
					onToggleExpand={onToggleExpand}
					onResolveError={onResolveError}
					deleteAtbRow={deleteAtbRow}
				/>
			</div>
			<CustomerPriceNotification
				CalculatedCustomerCredits={calculatedCustomerCredits}
			/>
		</div>
	);
};
export default AgedTrialBalanceDetail;
