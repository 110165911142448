import { Tooltip } from '../Tooltip/Tooltip';
import { ComboboxOption } from '../Combobox/Combobox';
import React, { useEffect, useState } from 'react';
import MultiComboboxSetter from '../Combobox/MultiComboboxSetter';

export type EntitySwitcherProps = {
	value: string[] | undefined;
	setValue: (value: string[] | undefined) => void;
	getOptions: (searchTerm?: string) => ComboboxOption<string>[];
	handleAfterSet?: () => void;
	tooltipContent?: string;
	// The type of the object in the options to be used for ui text.
	objectName?: string;
}

/**
 * Component form multi-selecting entities
 */
export default function MultiEntitySwitcher(props: EntitySwitcherProps) {
	const {
		getOptions, setValue, value, tooltipContent, objectName = 'object', handleAfterSet,
	} = props;

	const [optionsLength, setOptionsLength] = useState<number>(0);

	useEffect(() => {
		const fetchOptionsLength = async () => {
			const options = getOptions();
			setOptionsLength(options.length);
		};
		fetchOptionsLength();
	}, [getOptions, value]);

	const placeholder = optionsLength > 1 ? `Select a ${objectName}` : `No ${objectName} to select`;
	const disabled = optionsLength <= 1;

	function onHandleAfterSet() {
		if (handleAfterSet) handleAfterSet();
	}
	return (
		<div className="entity-context-switcher">
			{tooltipContent && (
				<Tooltip
					id="entity-switcher-tooltip"
					content={tooltipContent}
				/>
			)}

			<MultiComboboxSetter
				isDisabled={disabled}
				placeholder={placeholder}
				value={value}
				setValue={setValue}
				className="entity-switcher-dropdown"
				searchable
				getOptionValue={(id?: string) => id}
				onAfterSet={() => onHandleAfterSet()}
				label=""
				labelVisible={false}
				options={getOptions()}
			/>
		</div>
	);
}
