/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserAlaresWatchlistNotification from 'Models/Security/Acl/UserAlaresWatchlistNotification';
import VisitorsAlaresWatchlistNotification from 'Models/Security/Acl/VisitorsAlaresWatchlistNotification';
import UserAlaresWebhookAlert from 'Models/Security/Acl/UserAlaresWebhookAlert';
import VisitorsAlaresWebhookAlert from 'Models/Security/Acl/VisitorsAlaresWebhookAlert';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAlaresWebhookAlertEntityAttributes extends IModelAttributes {
	watchlistEntityId: string;
	referenceId: string;
	status: Enums.processingStatus;
	content: string;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AlaresWebhookAlertEntity', 'Alares Webhook Alert')
// % protected region % [Customise your entity metadata here] end
export default class AlaresWebhookAlertEntity extends Model
	implements IAlaresWebhookAlertEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserAlaresWatchlistNotification(),
		new VisitorsAlaresWatchlistNotification(),
		new UserAlaresWebhookAlert(),
		new VisitorsAlaresWebhookAlert(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Watchlist Entity Id'] off begin
	@observable
	@attribute<AlaresWebhookAlertEntity, string>()
	@CRUD({
		name: 'Watchlist Entity Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public watchlistEntityId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Watchlist Entity Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reference Id'] off begin
	@observable
	@attribute<AlaresWebhookAlertEntity, string>()
	@CRUD({
		name: 'Reference Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public referenceId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Reference Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<AlaresWebhookAlertEntity, Enums.processingStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.processingStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.processingStatusOptions),
		displayFunction: (attr: Enums.processingStatus) => Enums.processingStatusOptions[attr],
	})
	public status: Enums.processingStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Content'] off begin
	/**
	 * Alares report in json
	 */
	@observable
	@attribute<AlaresWebhookAlertEntity, string>()
	@CRUD({
		name: 'Content',
		displayType: 'textarea',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public content: string;
	// % protected region % [Modify props to the crud options here for attribute 'Content'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAlaresWebhookAlertEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAlaresWebhookAlertEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.watchlistEntityId !== undefined) {
				this.watchlistEntityId = attributes.watchlistEntityId;
			}
			if (attributes.referenceId !== undefined) {
				this.referenceId = attributes.referenceId;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.content !== undefined) {
				this.content = attributes.content;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AlaresWebhookAlertEntity.prototype, 'created');
CRUD(modifiedAttr)(AlaresWebhookAlertEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
