import { runInAction } from 'mobx';
import * as React from 'react';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import classnames from 'classnames';
import HandleEvents from 'Util/HandleEvents';

export interface IEntityListProps<T> {
	collection: T[], // List of objects to include in the table
	columns: Array<IEntityListHeaderProps<T>>; // Specifies which columns are included in the table
	sortColumn: string; // Specifies which column is currently being sorted
	sortDescending: boolean; // Specifies whether the sort column is sorted in descending or ascending order
	idColumn: string; // Should be a field containing a unique value on the entity. 'id' is usually used.
	onClickRow?: (entity: T) => void; // If set, clicking on a row will run this function
	rowClassName?: (entity: T) => string; // Adds a class to the entire row
}

export interface IEntityListHeaderProps<T> {
	value: ((entity: T) => (string | React.ReactNode)); // A function which returns the content of an individual cell
	displayName: string | React.ReactNode | ((name: string) => (string | React.ReactNode)); // The human-readable name of this column
	columnName: string; // The machine-readable name for this column. Should be unique
	sortable?: boolean; // If true, this column will show sort arrows and call sortClicked if the header is clicked
	sortClicked?: (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => IOrderByCondition<T>
		| undefined | void; // This function will run when a sortable header is clicked
	className?: string; // This class is added to both the column header and the individual cells in this column
	hide?: boolean; // Can be used to hide a column
	showNA?: boolean; // Should we show 'N/A' if there is no text
	onClickValue?: (entity: T) => void;
}

export default class EntityList<T> extends React.Component<IEntityListProps<T>> {
	/**
	 * Method to return the class names for the table header based off the header values
	 * @param header The header for a column on the table
	 * @returns A string of the class names for the header
	 */
	private headerClassNames(header: IEntityListHeaderProps<T>) {
		const { sortColumn, sortDescending } = this.props;

		return classnames(
			header.className,
			{
				sortable: header.sortable && header.columnName !== sortColumn,
				'sortable--asc': header.sortable && header.columnName === sortColumn && !sortDescending,
				'sortable--des': header.sortable && header.columnName === sortColumn && sortDescending,
			},
		);
	}

	/**
	 * Method to render the row of headers for the table
	 * @returns The table row of the headers for each column
	 */
	private renderHeaders() {
		const { columns } = this.props;

		return (
			<tr>
				{columns.filter(column => !column.hide).map(column => {
					return (
						<th
							className={this.headerClassNames(column)}
							onClick={
								event => {
									runInAction(() => {
										if (column.sortClicked) {
											column.sortClicked(event);
										}
									});
								}
							}
							key={column.columnName}
						>
							{column.displayName}
						</th>
					);
				})}
			</tr>
		);
	}

	/**
	 * Get the value from the entity
	 * @returns the column value for the given entity
	 */
	private getValue(column: IEntityListHeaderProps<T>, entity: T) {
		const value = column.value(entity);
		if (column.showNA) {
			return (value === null || value === undefined || value === '') ? 'N/A' : value;
		}
		return value;
	}

	/**
	 * Method to return the all entities in row format
	 * @returns The table rows where each row is Entity Data
	 */
	private renderRow = (entity: T, index: number) => {
		const {
			columns,
			idColumn,
			onClickRow,
			rowClassName,
		} = this.props;

		const key = idColumn ? entity[idColumn] : `row-${index}`;

		return (
			<tr
				key={key}
				className={!!rowClassName ? rowClassName(entity) : undefined}
				tabIndex={0}
				{...HandleEvents(!!onClickRow
					? () => onClickRow(entity)
					: undefined)}
			>
				{columns.filter(column => !column.hide).map(column => (
					<td
						key={`${key}-${column.columnName}`}
						className={column.className}
						{...HandleEvents(event => {
							if (column.onClickValue) {
								event.preventDefault();
								column.onClickValue(entity);
								event.stopPropagation();
							}
						})}
					>
						{this.getValue(column, entity)}
					</td>
				))}
			</tr>
		);
	}

	public render() {
		const { collection } = this.props;

		return (
			<div className="collection__list entity-list">
				<table>
					<thead>
						{this.renderHeaders()}
					</thead>
					<tbody>
						{collection.map(this.renderRow)}
					</tbody>
				</table>
			</div>
		);
	}
}
