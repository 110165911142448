import * as React from 'react';

import { Button, Colors, Display } from '../Button/Button';
import { CalculatePricing, PricingDetail } from 'Util/PricingHelper';

import If from '../If/If';
import { LocaleFormatCurrency } from 'Util/StringUtils';
import { LocaleFormatNumberDecimal } from 'Util/StringUtils';
import { OrganisationEntity } from 'Models/Entities';
import RemoveProductModal from './RemoveProductModal';
import { SERVER_URL } from 'Constants';
import { action } from 'mobx';
import alertToast from 'Util/ToastifyUtils';
import axios from 'axios';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import useStore from 'Hooks/useStore';

export interface OrganisationProductsSelectorProps {
    organisation: OrganisationEntity,
	fetchOrganisation: () => void,
}

type ProductRemoverModal = 'none'|'approve'|'ppsr'|'intel';

const OrganisationProductsSelector = observer((props: OrganisationProductsSelectorProps) => {
	const store = useStore();
	const { organisation, fetchOrganisation } = props;
	// products state is 'enabled' => it is selected and enabled for the organisation
	// 'selected' => selected but not yet enabled (they have click select product but not completed wizard)
	// 'no' => not selected or enabled.
	const [productsState, setProductsState] = React.useState<{
		intel: 'enabled'|'selected'|'no',
		approve: 'enabled'|'selected'|'no',
		ppsr: 'enabled'|'selected'|'no',
	}>({
		intel: organisation.intelEnabled ? 'enabled' : 'no',
		approve: organisation.approveEnabled ? 'enabled' : 'no',
		ppsr: organisation.ppsrEnabled ? 'enabled' : 'no',
	});

	const [currentPricingDetail, setCurrentPricingDetail] = React.useState<PricingDetail|null>(null);
	const [newPricingDetail, setNewPricingDetail] = React.useState<PricingDetail|null>(null);

	useEffect(() => {
		setProductsState({
			intel: organisation.intelEnabled ? 'enabled' : 'no',
			approve: organisation.approveEnabled ? 'enabled' : 'no',
			ppsr: organisation.ppsrEnabled ? 'enabled' : 'no',
		});

		setCurrentPricingDetail(null);

		CalculatePricing({
			OrganisationId: organisation.id,
			IntelEnabled: organisation.intelEnabled,
			ApproveEnabled: organisation.approveEnabled,
			PpsrEnabled: organisation.ppsrEnabled,
		}).then(setCurrentPricingDetail);
	}, [organisation.id, organisation.intelEnabled, organisation.approveEnabled, organisation.ppsrEnabled]);

	useEffect(() => {
		setNewPricingDetail(null);

		if (!currentPricingDetail) {
			return;
		}

		if (
			(productsState.intel !== 'no') === organisation.intelEnabled
			&& (productsState.approve !== 'no') === organisation.approveEnabled
			&& (productsState.ppsr !== 'no') === organisation.ppsrEnabled
		) {
			// If there is no difference, then don't calculate
			setNewPricingDetail(currentPricingDetail);
			return;
		}

		CalculatePricing({
			OrganisationId: organisation.id,
			IntelEnabled: productsState.intel !== 'no',
			ApproveEnabled: productsState.approve !== 'no',
			PpsrEnabled: productsState.ppsr !== 'no',
			Discounts: organisation.discountss
				.filter(d => d.source !== 'MIGRATION'),
		}).then(setNewPricingDetail);
	}, [
		organisation.id, organisation.intelEnabled, organisation.approveEnabled, organisation.ppsrEnabled,
		organisation.discountss, productsState, currentPricingDetail,
	]);

	// describe if/what modal is open to remove a product
	const [modalOpen, setModalOpen] = React.useState('none' as ProductRemoverModal);
	const myOrganisation = store.getUser?.organisation?.id === organisation.id;

	const requirePricingChange = React.useMemo(() => (
		currentPricingDetail !== null
		&& (
			currentPricingDetail.lineItems.some(d => d.type === 'DiscountMigration')
			|| currentPricingDetail.customerCount === 0
			|| currentPricingDetail.monthlyExposure === 0
		)
	), [currentPricingDetail]);

	// function to display the button text on the product cards
	const buttonText = React.useCallback((product: string) : string => {
		switch (product) {
			case ('enabled'):
				return 'Remove product';
			case ('selected'):
				return 'Selected';
			default:
				return 'Select product';
		}
	}, []);

	/**
	 * function to handle when a product button is clicked
	 * if the product is currently enabled we display the removal modal.
	 * if the product is not enabled we select it
	 * if the product is selected we unselect it
	*/
	const onToggleProductButtonClick = React.useCallback((product: string) => {
		setProductsState(old => {
			const productState = { ...old };

			switch (productState[product]) {
				case ('enabled'):
					setModalOpen(product as ProductRemoverModal);
					break;
				case ('no'):
					productState[product] = 'selected';
					break;
				default:
					productState[product] = 'no';
					break;
			}

			return productState;
		});
	}, []);

	const productsString = React.useCallback(() => {
		const products = [] as string[];

		if (organisation.approveEnabled) {
			products.push('Access Approve');
		}
		if (organisation.ppsrEnabled) {
			products.push('Access PPSR');
		}
		if (organisation.intelEnabled) {
			products.push('Access Monitor');
		}

		if (products.length === 0) {
			return 'Access Hub';
		}

		if (products.length > 1) {
			const last1 = products.pop();
			const last2 = products.pop();
			products.push(`${last2} and ${last1}`);
		}

		return products.join(', ');
	}, [organisation.approveEnabled, organisation.ppsrEnabled, organisation.intelEnabled]);

	const monthlySubscriptionString = React.useCallback(() => {
		if (!currentPricingDetail) {
			return '';
		}
		return LocaleFormatCurrency(currentPricingDetail.total);
	}, [currentPricingDetail]);

	const customerCountBucketString = React.useCallback(() => {
		if (!currentPricingDetail) {
			return '';
		}
		return LocaleFormatNumberDecimal(currentPricingDetail.customerCountBucket);
	}, [currentPricingDetail]);

	const userCountBucketString = React.useCallback(() => {
		if (!currentPricingDetail) {
			return '';
		}
		if (currentPricingDetail.userCountBucket < currentPricingDetail.userCount) {
			return LocaleFormatNumberDecimal(currentPricingDetail.userCount);
		}
		return LocaleFormatNumberDecimal(currentPricingDetail.userCountBucket);
	}, [currentPricingDetail]);

	/**
	 * Function to handle when the removal modal is submitted
	 */
	const onRemoveProduct = action(async (product: string): Promise<void> => {
		try {
			// remove the associated products
			if (product === 'intel') {
				await organisation.removeIntelProduct();
			}
			if (product === 'approve') {
				await organisation.removeApproveProduct();
			}
			if (product === 'ppsr') {
				await organisation.removePpsrProduct();
			}
			// save the organisation
			await organisation.save({
				primaryBusinessEntity: {},
			});
			const promises = [];
			for (const be of organisation.businessEntitys) {
				promises.push(be.save());
			}
			// save the individual business entities
			await Promise.all(promises);
			// re-fetch the organisation in case the backend reset anything
			fetchOrganisation();
			await axios.get(`${SERVER_URL}/api/account/me`)
				.then(({ data }) => store.setLoggedInUser(data))
				.catch(() => {});
		} catch (e) {
			alertToast('Product could not be removed at this moment.', 'error');
			setModalOpen('none');
			return;
		}
		alertToast('Product was successfully removed', 'success');
		setModalOpen('none');
	});

	const productOptions = {
		intel: 'Monitor',
		approve: 'Approve',
		ppsr: 'PPSR',
	};

	/**
	 * Function to return a list of modals for each product
	 * @returns Modals for each product
	 */
	const renderModals = () => {
		return (
			<>
				{Object.entries(productOptions).map(([product, label]) => (
					<RemoveProductModal
						product={product}
						label={label}
						isOpen={modalOpen === product}
						onClose={() => setModalOpen('none')}
						onSubmit={async () => { await onRemoveProduct(product); }}
						key={product}
					/>
				))}
			</>
		);
	};

	/**
	 * Function to return the url of the product setup.
	 * This is determined on what products are being set up and whether the organisation is our own.
	 * @returns the url of the product setup
	 */
	const getSetupUrl = () => {
		let url = '';

		// determine if the organisation is our own
		if (!myOrganisation) {
			url += `/hub/clients/${organisation.id}/setup/`;
		} else {
			url += '/hub/organisation/setup/';
		}

		// append the products that are being setup, being joined by '-'
		url += Object.keys(productsState).map(product => {
			if (productsState[product] === 'selected') {
				return product;
			}
			return '';
		}).filter(product => product !== '').join('-');

		return url;
	};

	return (
		<>
			<div className="organisation-products-selector">
				<h4>Product plans</h4>
				<p className="terms-date">
					<a href="/api/files/document/terms" target="_blank">
						<b>Terms & Conditions</b>
					</a> accepted on {organisation.acceptedterms
						? organisation.acceptedterms.toLocaleDateString() : ''}
				</p>
				<p>If there are products you are interested in activating, select it and we&apos;ll calculate your
					monthly subscription costs
				</p>
				<div className="products-step">
					<div className="product-selector-wrap">
						<div className="product-selector approve">
							<a
								className="product-link"
								href={store.appSettings.approveMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access Approve"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<div className="product-description-title-container">
										<h6 className="description-title">Applications</h6>
										<If condition={productsState.approve === 'enabled'}>
											<span className="product-active">Active</span>
										</If>
									</div>
									<p className="description-text">
										Receive digital trade applications with verified data and approve new customers
										with confidence in seconds rather than days.
									</p>
									<p className="description-subtext">
										Downloadable credit reports priced separately as marked.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>6 Minutes</p>
									</div>
									<Button
										className={classNames('product-select-btn', {
											selected: productsState.approve === 'selected',
											enabled: productsState.approve === 'enabled',
										})}
										colors={Colors.Primary}
										display={Display.Outline}
										onClick={action(() => {
											onToggleProductButtonClick('approve');
										})}
									>
										{buttonText(productsState.approve)}
									</Button>
								</div>
							</div>
						</div>
						<div className="product-selector ppsr">
							<a
								className="product-link"
								href={store.appSettings.ppsrMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access PPSR"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<div className="product-description-title-container">
										<h6 className="description-title">PPSR</h6>
										<If condition={productsState.ppsr === 'enabled'}>
											<span className="product-active">Active</span>
										</If>
									</div>
									<p className="description-text">
										Correctly process PPSR registrations with one click and easily manage your
										entire portfolio of security interests in one place with full visibility.
									</p>
									<p className="description-subtext">
										Registration processing fees as indicated.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>4 Minutes</p>
									</div>
									<Button
										className={classNames('product-select-btn', {
											selected: productsState.ppsr === 'selected',
											enabled: productsState.ppsr === 'enabled',
										})}
										colors={Colors.Primary}
										display={Display.Outline}
										onClick={action(() => {
											onToggleProductButtonClick('ppsr');
										})}
									>
										{buttonText(productsState.ppsr)}
									</Button>
								</div>
							</div>
						</div>
						<div className="product-selector intel">
							<a
								className="product-link"
								href={store.appSettings.monitorMarketingUrl}
								target="_blank"
								rel="noreferrer"
							>
								<div className="icon-north-east icon-right" />
								<div
									className="product-logo"
									role="img"
									aria-label="Access Monitor"
								/>
							</a>
							<div className="product-information">
								<div className="product-description">
									<div className="product-description-title-container">
										<h6 className="description-title">Monitor</h6>
										<If condition={productsState.intel === 'enabled'}>
											<span className="product-active">Active</span>
										</If>
									</div>
									<p className="description-text">
										Automatically receive real time credit scores on all customers.  Easily connect
										your accounting system or upload your customer data and understand your credit
										risk at a glance.
									</p>
									<p className="description-subtext">
										Downloadable credit reports priced separately as marked.
									</p>
								</div>
								<div className="product-footer">
									<div className="setup-details">
										<p>Set up time</p>
										<span className="separator" />
										<p>2 Minutes</p>
									</div>
									<Button
										className={classNames('product-select-btn', {
											selected: productsState.intel === 'selected',
											enabled: productsState.intel === 'enabled',
										})}
										colors={Colors.Primary}
										display={Display.Outline}
										onClick={action(() => {
											onToggleProductButtonClick('intel');
										})}
									>
										{buttonText(productsState.intel)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<If condition={store.userType === 'SUPER_USER'}>
					<div className="current-pricing">
						<p className="price">
							For up to <strong>{userCountBucketString()}</strong> users
							and <strong>{customerCountBucketString()}</strong> customers,
							your monthly subscription fee will be
							<strong> {monthlySubscriptionString()}</strong> (exclusive of GST).
						</p>
					</div>
				</If>
			</div>
			<If condition={Object.values(productsState).some(value => value === 'selected')}>
				<div className="product-billing-information">
					<div className="pricing">
						<If condition={!requirePricingChange}>
							<h5>Monthly billing {
								newPricingDetail !== null
									? `${LocaleFormatCurrency(newPricingDetail.total)}/mo`
									: '~'
							}
							</h5>
						</If>
						<If condition={requirePricingChange}>
							<p className="pricing-change">
								With this application you will be moved to the current pricing structure.
							</p>
						</If>
						<p>Currently {
							currentPricingDetail !== null
								? `${LocaleFormatCurrency(currentPricingDetail.total)}/mo`
								: '~'
						}
						</p>
					</div>
					<If condition={Object.values(productsState).some(value => value === 'selected')}>
						<div className="button-container">
							<Button
								display={Display.Outline}
								colors={Colors.Primary}
								onClick={() => {
									const oldState = cloneDeep(productsState);
									Object.keys(oldState).forEach(product => {
										if (oldState[product] === 'selected') {
											oldState[product] = 'no';
										}
									});
									setProductsState({ ...oldState });
								}}
							>
								Deselect product(s)
							</Button>
							<Button
								display={Display.Solid}
								colors={Colors.Primary}
								onClick={() => {
									store.routerHistory.push(getSetupUrl());
								}}
							>
								Complete application
							</Button>
						</div>
					</If>
				</div>
			</If>
			{renderModals()}
		</>
	);
});

export default OrganisationProductsSelector;
