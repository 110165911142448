import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import AddCustomer from 'Views/Components/Intel/Customer/AddCustomer';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface AddCustomerPageProps extends RouteComponentProps {
}

const AddCustomerPage = (props: AddCustomerPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content add-customer-page">
				<div className="invisible-page-wrap">
					<div className="top-container">
						<PageBreadcrumbs
							tags={
								[
									{ label: 'Customers', link: '/monitor/customers' },
									{ label: 'New Customer' },
								]
							}
						/>
					</div>
					<div className="white-box">
						<AddCustomer />
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};
export default AddCustomerPage;
