import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import EditCustomerHeadings from 'Views/Components/Intel/Customer/EditCustomerHeadings';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface RegistrationListPageProps extends RouteComponentProps {
}

const RegistrationListPage = (props: RegistrationListPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content customer-headings-page">
				<PageBreadcrumbs
					tags={
						[
							{ label: 'Customers', link: '/monitor/customers' },
							{ label: 'Update table headers' },
						]
					}
				/>
				<EditCustomerHeadings />
			</div>
		</AccessIntelSecuredPage>
	);
};
export default RegistrationListPage;
