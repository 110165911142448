import axios from "axios";
import { SERVER_URL } from "Constants";
import useAsync from "Hooks/useAsync";
import { runInAction } from "mobx";
import { observer, useLocalStore } from "mobx-react"
import { useEffect } from "react";
import { Button, Colors, Display, Sizes } from "Views/Components/Button/Button";
import { ButtonGroup } from "Views/Components/Button/ButtonGroup";
import { IOrganisation, IPartnerGroupEntity } from "Views/Components/Intel/Customer/ReferForCollection"
import Modal from "Views/Components/Modal/Modal";
import InlineSpinner from "Views/Components/Spinner/InlineSpinner";
import { Tooltip } from "Views/Components/Tooltip/Tooltip";
import { ReferralPartnerGroupSection } from "./ReferralPartnerGroupSection";
import { ReferralPartnerCard } from "./ReferralPartnerCard";

type Props = {
	onClose: () => void;
}

export const ReferToCollection:React.FunctionComponent<Props> =  observer(({ onClose }) => {
	const localStore = useLocalStore(() => ({
		referralPartners: [] as IOrganisation[],
		selectedGroups: [] as string[],
	}));

	const responsePartners = useAsync<IOrganisation[]>(async () => {
		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/OrganisationEntity/getReferralPartners`);
			runInAction(() => { localStore.referralPartners = data; });
			return data as IOrganisation[];
		} catch (e) {
			return [];
		}
	}, []);

	const responsePartnerGroups = useAsync<IPartnerGroupEntity[]>(async () => {
		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/ReferralPartnerGroupEntity`);
			const groups = data.data as IPartnerGroupEntity[];
			runInAction(() => { localStore.selectedGroups = groups.map(x => x.name); });
			return groups;
		} catch (e) {
			return [];
		}
	}, []);

	const onGroupClick = (group: string, isSelected: boolean) => {
		if (isSelected) {
			// If clicked group is currently selected
			if (localStore.selectedGroups.includes(group)) {
				runInAction(() => {
					localStore.selectedGroups = localStore.selectedGroups.filter(x => !x.match(group));
					localStore.referralPartners = localStore.referralPartners
						.filter(x => localStore.selectedGroups
							.includes(x.referralPartnerGroup.name));
				});
			} else { // Else we can assume the group is not currently selected
				runInAction(() => {
					localStore.selectedGroups = [...localStore.selectedGroups, group];
				});
			}
		} else {
			runInAction(() => {
				localStore.selectedGroups.push(group);
				localStore.referralPartners = responsePartners
					?.data
					?.filter(x => localStore.selectedGroups.includes(x.referralPartnerGroup.name)) ?? [];
			});
		}
	};

	const renderPartnerGroups = (entity: IPartnerGroupEntity) => {
		return (
			<ReferralPartnerGroupSection
				key={entity.id}
				onClick={onGroupClick}
				name={entity.name}
				colour={entity.colour}
				selected={localStore.selectedGroups.includes(entity.name)}
			/>
		);
	};

	const renderPartners = (entity: IOrganisation) => {
		return (
			<ReferralPartnerCard
				key={entity.id}
				customerId={""}
				organisationId={entity.id}
				orgName={entity.primaryBusinessEntity.name}
				blurb={entity.referralPartnerBlurb}
				groupName={entity.referralPartnerGroup?.name}
				groupColour={entity.referralPartnerGroup.colour}
				logoId={entity.referralPartnerLogoId}
				showBlurb={false}
			/>
		);
	};

	useEffect(() => {
		if (responsePartnerGroups.data) {
			runInAction(() => {
				localStore.selectedGroups = responsePartnerGroups.data.map(x => x.name);
			});
		}
	}, [localStore, responsePartnerGroups.data]);


	return (
		<Modal isOpen={true} className="refer-for-collection refer-for-collection-modal" label="">
			<h4>
				Refer for collection
				<Tooltip
					id="abr-sync-tooltip"
					content={
						'Instant access to the best partner solutions. '
							+ 'Click on the links to access the services you ned.'
					}
				/>
			</h4>

			{(responsePartners.type === 'loading' || !responsePartners.data) && (
				<InlineSpinner />
			)}
			{responsePartners.type !== 'loading' && !responsePartners.data && (
				<span>
					No Referral Partners available
				</span>
			)}
			{responsePartnerGroups.data !== undefined && (
				<div className="filter-collection">
					{responsePartnerGroups.data.map(renderPartnerGroups)}
				</div>
			)}
			{localStore.referralPartners !== undefined && (
				<div className="collection-component">
					{localStore.referralPartners.map(renderPartners)}
				</div>
			)}
			<p className="description-text">
				Partner service links are provided for ease, speed and convenience.
				Access Intell does not recommend or endorse any particular service.
				Engaging with any legal or debt collection provider should be done at
				your own discretion and after conducting thorough research.
				Access Intell disclaims any liability for action taken based on
				the information displayed and access links presented.
				By using this service, you acknowledge that you have read and understood
				this disclaimer and agree to hold us harmless from any consequences
				arising from your reliance on the information provided.
			</p>
			<ButtonGroup className="modal__actions">
				<Button
					colors={Colors.Error}
					sizes={Sizes.Medium}
					display={Display.Text}
					onClick={onClose}
				>
					Close
				</Button>
			</ButtonGroup>
		</Modal>
	)
});