import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import CustomerList from 'Views/Components/Intel/Customer/CustomerList';
import CustomerHeadingsData from 'Models/Entities/CustomerHeadingsData';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import { useCustomerListPageConfiguration } from './useCustomerListPageConfiguration/useCustomerListPageConfiguration';
import { useESignStore } from 'ESign/Stores/ESignStore';
import { SessionKeys } from 'Constants';
import { useEffect } from 'react';

export interface CustomerListPageProps extends RouteComponentProps {
}

const CustomerListPage = (props: CustomerListPageProps) => {
	const { location } = props;

	// Run first before trigging fetches
	const customerListPageConfig = useCustomerListPageConfiguration(location);

	const defaultHeadings = CustomerHeadingsData.defaultHeadings();
	const columns = CustomerHeadingsData.fetchCustomerHeadings()
		.filter(x => (
			x.enabled
			|| (
				defaultHeadings.find(y => y.fieldName === x.fieldName) ?? new CustomerHeadingsData()
			).permanentlyEnabled
		));

	const breadCrumbTags = [
		{ label: 'Customers' },
	];

	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content list-page">
				<PageBreadcrumbs tags={breadCrumbTags} />
				<CustomerList
					columns={columns}
					sort={customerListPageConfig.sort}
					filter={customerListPageConfig.filter}
				/>
			</div>
		</AccessIntelSecuredPage>
	);
};
export default CustomerListPage;
