import { useESignStore } from 'ESign/Stores/ESignStore';
import ESignPaths from 'ESign/Router/ESignPaths';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
	Route, RouteProps, Switch, useParams,
} from 'react-router-dom';
import ESignAuthorisePage from './ESignAuthorisePage';
import LoadableState from 'ESign/Types/ESignLoadableState';
import Spinner from 'Views/Components/Spinner/Spinner';
import PageNotFound from 'Views/Pages/PageNotFound';
import ESignUserHomePage from './ESignUserHomePage';

const ESignUserPage: React.FunctionComponent = observer(() => {
	const { userId, workflowId } = useParams<{ userId: string, workflowId: string }>();
	const {
		commonStore: {
			isVerified, initiateESign, loadableState,
		},
	} = useESignStore();

	const pages: {
		path: string,
		component?: React.ComponentType<RouteProps> | React.ComponentType<any> | undefined,
		isAccessible?: boolean,
	}[] = [
		{
			path: ESignPaths.authorise,
			component: ESignAuthorisePage,
		},
		{
			path: ESignPaths.sign,
			component: ESignUserHomePage,
		},
	];

	useEffect(() => {
		if (!isVerified && loadableState === LoadableState.Pending) {
			initiateESign(userId, workflowId);
		}
	}, [initiateESign, isVerified, loadableState, userId, workflowId]);

	switch (loadableState) {
		case LoadableState.Pending:
			return <Spinner />;
		case LoadableState.FulFilled:
			return (
				<Switch>
					{
						pages.map((page, index) => {
							return (
							// eslint-disable-next-line react/no-array-index-key
								<Route key={`page-${index}`} path={page.path} component={page.component} />
							);
						})
					}
					<Route component={PageNotFound} />
				</Switch>
			);
		case LoadableState.Rejected:
		default:
			return <Route component={PageNotFound} />;
	}
});

export default ESignUserPage;
