import { observable } from 'mobx';
import { store } from 'Models/Store';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import CustomerColumnTypes from 'Views/Components/Intel/Customer/CustomerColumnTypes';

// The type for Customer Headings
export default class CustomerHeadingsData {
	@observable public fieldName: string;
	@observable public displayName: string;
	@observable public enabled: boolean;
	@observable public permanentlyEnabled: boolean = false;

	constructor(attributes?: Partial<CustomerHeadingsData>) {
		if (attributes) {
			if (attributes.fieldName !== undefined) {
				this.fieldName = attributes.fieldName;
			}
			if (attributes.displayName !== undefined) {
				this.displayName = attributes.displayName;
			}
			if (attributes.enabled !== undefined) {
				this.enabled = attributes.enabled;
			}
			if (attributes.permanentlyEnabled !== undefined) {
				this.permanentlyEnabled = attributes.permanentlyEnabled;
			}
		}
	}

	public static defaultHeadings() {
		return [
			new CustomerHeadingsData({
				fieldName: 'debtorID',
				displayName: CustomerColumnTypes.debtorID.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'businessName',
				displayName: CustomerColumnTypes.businessName.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'abn',
				displayName: CustomerColumnTypes.abn.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'entityStatus',
				displayName: CustomerColumnTypes.entityStatus.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'businessEntities',
				displayName: CustomerColumnTypes.businessEntities.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'groups',
				displayName: CustomerColumnTypes.groups.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'totalOverdue',
				displayName: CustomerColumnTypes.totalOverdue.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'totalDebt',
				displayName: CustomerColumnTypes.totalDebt.defaultDisplayName,
				enabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'paymentRiskRating',
				displayName: CustomerColumnTypes.paymentRiskRating.defaultDisplayName,
				enabled: true,
				permanentlyEnabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'insolvencyRiskRating',
				displayName: CustomerColumnTypes.insolvencyRiskRating.defaultDisplayName,
				enabled: true,
				permanentlyEnabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'adverseAlerts',
				displayName: CustomerColumnTypes.adverseAlerts.defaultDisplayName,
				enabled: true,
				permanentlyEnabled: true,
			}),
			new CustomerHeadingsData({
				fieldName: 'currentDebt',
				displayName: CustomerColumnTypes.currentDebt.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'dp1to30',
				displayName: CustomerColumnTypes.dp1to30.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'dp31to60',
				displayName: CustomerColumnTypes.dp31to60.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'dp61to90',
				displayName: CustomerColumnTypes.dp61to90.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'dp91to120',
				displayName: CustomerColumnTypes.dp91to120.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'dp120plus',
				displayName: CustomerColumnTypes.dp120plus.defaultDisplayName,
				enabled: false,
			}),
			new CustomerHeadingsData({
				fieldName: 'atoDebt',
				displayName: CustomerColumnTypes.atoDebt.defaultDisplayName,
				enabled: false,
			}),
		];
	}

	public static fetchCustomerHeadings() {
		const defaultHeadings = this.defaultHeadings();

		if (!!store.getUser?.organisation?.customerColumnSettings) {
			const columnSettings = store.getUser.organisation.customerColumnSettings;
			try {
				const rawHeadings = JSON.parse(columnSettings) as Partial<CustomerHeadingsData>[];

				if (rawHeadings.length !== 0) {
					const headings = rawHeadings.map(x => new this(x));
					return defaultHeadings
						.sort((a, b) => headings
							.findIndex(x => x.fieldName === a.fieldName) - headings
							.findIndex(x => x.fieldName === b.fieldName))
						.map(x => {
							return headings.find(y => x.fieldName === y.fieldName) ?? x;
						});
				}
			} catch (e) {
				// Do nothing, but return the default headings below
			}
		}

		// Default headings
		return defaultHeadings;
	}

	public static async saveCustomerHeadings(customerHeadings: CustomerHeadingsData[]) {
		// Save the new headings to the server. We use a custom endpoint, so that we don't need to worry about
		// fetching the organisation first, or overwriting other fields

		// we don't save if there's an empty list
		if (customerHeadings.filter(heading => heading.enabled === true).length <= 0) {
			return;
		}
		const jsonHeadings = JSON.stringify(customerHeadings);

		await axios.post(`${SERVER_URL}/api/entity/OrganisationEntity/updateCustomerHeaders`, {
			organisationId: store.getUser?.organisation?.id,
			customerHeaders: jsonHeadings,
		});

		// Update the current value in the store, so we don't need to refetch the organisation
		store.setCustomerHeaders(jsonHeadings);
	}
}
