/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { Button, Display, Sizes } from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { store } from 'Models/Store';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import alert from '../../Util/ToastifyUtils';
import Password from 'Views/Components/Password/Password';
import * as queryString from 'querystring';
import { resetPassword } from '../../Services/Api/AccountService';
// % protected region % [Add any extra imports here] on begin
import AccessGroupLogo from 'Assets/Images/Access_logo.svg';
// % protected region % [Add any extra imports here] end

interface IResetPasswordState {
	token: string;
	username: string;
	password: string;
	confirmPassword: string,
	errors: {
		password?: string,
		confirmPassword?: string,
		[attr: string]: string | undefined,
	};
	// % protected region % [Add extra reset password state properties here] off begin
	// % protected region % [Add extra reset password state properties here] end
}

const defaultResetPasswordState: IResetPasswordState = {
	token: '',
	username: '',
	password: '',
	confirmPassword: '',
	errors: {},
	// % protected region % [Instantiate extra reset password state properties here] off begin
	// % protected region % [Instantiate extra reset password state properties here] end
};

@observer
export default class ResetPasswordPage extends React.Component<RouteComponentProps> {
	@observable
	private resetPasswordState: IResetPasswordState = defaultResetPasswordState;

	constructor(props:RouteComponentProps) {
		super(props);
		const { location } = this.props;
		const queryParams = location.search.substring(1);
		const { token, username } = queryString.parse(queryParams);
		this.resetPasswordState.token = token as string;
		this.resetPasswordState.username = username as string;
	}

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		contents = (
			<div className="body-content login-page">
				<div className="login-form">
					<form className="reset-password" onSubmit={this.onResetPasswordClicked}>
						<div className="logo-container">
							<img alt="Access Intell Group Logo" src={AccessGroupLogo} />
						</div>
						<div className="title forgot-title">
							<h5>Reset your password</h5>
						</div>
						<div className="forgot-password-desc">
							<p>Please set a new password for your account.</p>
						</div>
						<Password
							id="new_password"
							className="new-password"
							model={this.resetPasswordState}
							modelProperty="password"
							isRequired
							placeholder="Password"
							errors={this.resetPasswordState.errors.password}
						/>
						<Password
							id="confirm_password"
							className="confirm-password"
							model={this.resetPasswordState}
							modelProperty="confirmPassword"
							isRequired
							placeholder="Confirm Password"
							errors={this.resetPasswordState.errors.confirmPassword}
						/>
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirm-pwd-buttons">
							<Button
								type="submit"
								className="confirm-reset-password"
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'confirm_reset_password' }}
							>
								Submit
							</Button>
						</ButtonGroup>
					</form>
				</div>
				<div className="login-gradient">
					<div className="login-pattern" />
				</div>
			</div>
		);
		// % protected region % [Override contents here] end
		return contents;
	}

	@action
	private onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onResetPasswordClicked here] off begin
		event.preventDefault();

		this.resetPasswordState.errors = {};

		if (!this.resetPasswordState.password) {
			this.resetPasswordState.errors.password = 'Password is required';
		} else if (this.resetPasswordState.password.length < 12) {
			this.resetPasswordState.errors.password = 'The minimum length of password is 12';
		} else if (!this.resetPasswordState.confirmPassword) {
			this.resetPasswordState.errors.confirmPassword = 'Confirm password is required';
		} else if (this.resetPasswordState.confirmPassword !== this.resetPasswordState.password) {
			this.resetPasswordState.errors.confirmPassword = 'Password and confirm password does not match';
		}

		if (Object.keys(this.resetPasswordState.errors).length === 0) {
			resetPassword(
				this.resetPasswordState.token,
				this.resetPasswordState.username,
				this.resetPasswordState.password,
			).then(() => {
				this.onConfirmPasswordSent();
			}).catch(errors => {
				const errorMsgs = errors.response.data.errors.map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Password could not be changed: </h6>
						{errorMsgs}
					</div>,
					'error',
				);
			});
		}
		// % protected region % [Override onResetPasswordClicked here] end
	};

	@action
	private onConfirmPasswordSent = () => {
		// % protected region % [Override login success logic here] off begin
		alert('Your password has been reset', 'success');
		store.routerHistory.push('/login');
		// % protected region % [Override login success logic here] end
	};

	// % protected region % [Add class methods here] off begin
	// % protected region % [Add class methods here] end
}
