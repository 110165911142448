import CustomerAuditsTable from './CustomerAuditsTable';
import { CustomerEntity } from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import React, { useCallback } from 'react';
import { 
	CUSTOMER_AUDITS_PAGE_LENGTH, 
	CustomerAuditEntityResponse, 
	CustomerAuditFilterParams 
} from './Gibs/FetchCustomerAudits';
import { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import CustomerAuditsSearchAndFilterSection from './CustomerAuditsSearchAndFilterSection';
import CustomerAuditDescriptionModal from './Gibs/CustomerAuditDescriptionModal';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

interface Props {
	customer: CustomerEntity;
}

const fetchCombinedCustomerAudits = AwesomeDebouncePromise(
	async (
		customerId: string, 
		search: string, 
		auditFilterParams: CustomerAuditFilterParams, 
		page: number): 
	Promise<{auditEntities: CustomerAuditEntityResponse[], totalCount: number}> => {
		const response = await axios.post(
			`${SERVER_URL}/api/entity/CustomerAuditEntity/combined`,
			{
				customerId: customerId,
				search: search,
				auditFilterParams: auditFilterParams,
				page: page,
				pageSize: CUSTOMER_AUDITS_PAGE_LENGTH
			}
		);
		return {
			auditEntities: response.data.auditEntries,
			totalCount: response.data.totalCount
		}
	}, 500);


const CustomerAuditsTab: React.FunctionComponent<Props> = ({ customer }) => {
	const [showCommentMessageModal, setShowCommentMessageModal] = React.useState(false);
	const [updateList, setUpdateList] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [search, setSearch] = React.useState('');
	const [auditFilterParams, setAuditFilterParams] = React.useState<CustomerAuditFilterParams>(() => (
		{
			category: 'ALL',
			dateRange: undefined,
		}
	));

	const refreshKey = React.useMemo(() => (GetRefreshKey(
		search,
		...Object.values(auditFilterParams),
		page,
		updateList,
	)), [auditFilterParams, page, search, updateList]);

	const fetchCombinedCustomerAudit = useCallback(async () => {
		const response = await fetchCombinedCustomerAudits(customer.id, search, auditFilterParams, page);
		setCount(response.totalCount);
		return response.auditEntities;
	}, [customer.id, search, auditFilterParams, page]);

	return (
		<div className="customer-audits">
			<div className="bread-crumbs">
				<div className="customer-audits-comment">
					<Button
						className="add-comment"
						display={Display.Solid}
						icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						onClick={() => {
							setShowCommentMessageModal(true);
						}}
						disabled={customer.monitoredStatus === "NOT_MONITORED"}
					>
						Add Comment
					</Button>
				</div>
				<CustomerAuditsSearchAndFilterSection
					search={search}
					auditFilterParams={auditFilterParams}
					resetPage={() => setPage(0)}
					setAuditFilterParams={setAuditFilterParams}
					setSearch={setSearch}
				/>
			</div>
			<CustomerAuditDescriptionModal
				initialDescription={{ title: '' }}
				customerId={customer.id}
				openModal={showCommentMessageModal}
				closeModal={() => setShowCommentMessageModal(false)}
				category="COMMENT"
				refreshList={() => setUpdateList(prevState => !prevState)}
				isReadOnly={false}
			/>
			<CustomerAuditsTable
				page={page}
				count={count}
				refreshKey={refreshKey}
				setPage={setPage}
				fetchCustomerAudits={fetchCombinedCustomerAudit}
			/>
		</div>
	);
};
export default CustomerAuditsTab;
