import * as Enums from 'Models/Enums';
import * as React from 'react';

import {
	AddressEntity,
	CustomerEntity,
	GroupEntity,
	GroupsCustomers,
	AtoDebtEntity,
	AtoRecordEntity,
	AtoFileEntity,
	MonitoredEntity,
	MonitoredsCustomers,
} from 'Models/Entities';
import { action, observable, runInAction } from 'mobx';

import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import AccountInformation from 'Views/Components/Intel/Customer/AccountInformation';
import AdverseAlertsReport from 'Views/Components/Intel/Customer/AdverseAlerts/AdverseAlertsReport';
import BusinessInformation from 'Views/Components/Intel/Customer/BusinessInformation';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import CustomerAuditsTab from 'Views/Components/Intel/Customer/CustomerAudits/CustomerAuditsTab';
import CustomerOverview from 'Views/Components/Intel/Customer/Overview/CustomerOverview';
import EditCustomerBusinessEntityModal from '../../Components/Intel/EditCustomerBusinessEntityModal';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import PpsrRegistrations from 'Views/Components/Intel/Customer/PpsrRegistrations';
import ReferForCollection from 'Views/Components/Intel/Customer/ReferForCollection';
import { RouteComponentProps } from 'react-router';
import { SERVER_URL } from 'Constants';
import Spinner from 'Views/Components/Spinner/Spinner';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import alertToast from 'Util/ToastifyUtils';
import axios from 'axios';
import classNames from 'classnames';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';

type CustomerTabs = 'overview' | 'ppsr-registrations' | 'business-information'
	| 'accounts-information' | 'aged-debt-configuration' | 'adverse-alerts'
	| 'refer-for-collection' | 'history'
type RequestState = 'loading' | 'error' | 'complete'

export interface CustomerPageProps {
	customerId?: string,
}

@observer
class CustomerPage extends React.Component<RouteComponentProps<CustomerPageProps>> {
	@observable private currentTab: CustomerTabs = 'overview';

	@observable private customer?: CustomerEntity;

	@observable private requestState: RequestState = 'loading';

	@observable private showEditCustomerBusinessEntityModal: boolean = false;

	public componentDidMount(): void {
		this.fetchCustomerData();
		this.handleTabRerouting();
		window.history.replaceState({}, document.title);
	}

	@action public handleTabRerouting(): void {
		try {
			const { location } = this.props;
			const { navigateTo } = location.state as { navigateTo: string };
			this.currentTab = (navigateTo || 'overview') as CustomerTabs;
		} catch (exception) {
			// ignored
		}
	}

	@action public onAdverseAlertsClick = () => {
		this.currentTab = 'adverse-alerts';
	};

	@action public setShowEditCustomerBusinessEntityModal = (state: boolean) => {
		this.showEditCustomerBusinessEntityModal = state;
	};

	private fetchCustomerData = async () => {
		const { match } = this.props;
		const { customerId } = match.params;

		try {
			const results = await store.apolloClient.query({
				query: gql`
					query fetchCustomerById($customerId: ID) {
						customerEntity(id: $customerId) {
							${CustomerEntity.getAllAttributes().join('\n')}
							paymentRiskRating
							businessEntity {
								id
								name
								enabledForApprove
								enabledForPPSR
								enableDrinksLicence
								organisation {
									id
									ppsrEnabled
									approveEnabled
								}
							}
							physicalAddress {
								${AddressEntity.getAllAttributes().join('\n')}
							}
							postalAddress {
								${AddressEntity.getAllAttributes().join('\n')}
							}
							groupss {
								${GroupsCustomers.getAllAttributes().join('\n')}
								groups {
									${GroupEntity.getAllAttributes().join('\n')}
								}
							}
							monitoredss {
								${MonitoredsCustomers.getAttributes().join('\n')}
								monitoreds {
									${MonitoredEntity.getAttributes().join('\n')}
									atoDebt {
										${AtoDebtEntity.getAllAttributes().join('\n')}
									}
									atoRecordss {
										${AtoRecordEntity.getAllAttributes().join('\n')}
										atoFile {
											${AtoFileEntity.getAttributes().join('\n')}
										}
									}
								}
							}
							newestAtbRecord {
								id
								totalOverdue
								totalDebt
							}
							newestAlares {
								id
								insolvencyRiskRating
								adverseAlerts
								# jsonreport - Don't include this - it's too big
							}
							atbRecords {
								id
							}
						}
					}`,
				variables: {
					customerId: customerId,
				},
				fetchPolicy: 'no-cache',
			});

			runInAction(() => {
				if (!!results.data.customerEntity) {
					this.customer = new CustomerEntity(results.data.customerEntity);
					this.requestState = 'complete';
				} else {
					this.requestState = 'error';
				}
			});
		} catch (e) {
			runInAction(() => {
				this.requestState = 'error';
			});
		}
	};

	render() {
		const {
			match,
		} = this.props;
		const { customerId } = match.params;

		if (this.requestState === 'error') {
			alertToast('Unable to find matching customer', 'error');
			const redirectUrl = customerId != null
				? '/monitor/customers'
				: '/';
			store.routerHistory.push(redirectUrl);
			return <Spinner />;
		}

		const growWhiteSpaceTab = () => {
			return this.currentTab === 'overview' ||
				(this.currentTab === 'ppsr-registrations' && !store.canAccessPPSR) ||
				this.currentTab === 'adverse-alerts' ||
				this.currentTab === 'history';
		};

		const monitored = this.customer?.monitoredStatus === 'MONITORED';
		const isSuperUser = store.userType === 'SUPER_USER';
		const disableUpdateBusinessEntity = (this.customer?.atbRecords.length ?? -1) !== 0;
		const pageBreadcrumbTags = [
			{ label: 'Customers', link: '/monitor/customers' },
			isSuperUser ? {
				label: this.customer?.businessEntity.name ?? '',
				actionButton: {
					onClick: () => this.setShowEditCustomerBusinessEntityModal(true),
					icon: 'edit',
					disabledTooltip: {
						id: 'disabled-edit-business-entity-tooltip',
						content: 'Unable to edit a customer with existing Aged Trial Balance data.',
					},
				},
				disableLink: disableUpdateBusinessEntity,
			} : {
				label: this.customer?.businessEntity.name ?? '',
				disableLink: true,
			},
			{ label: this.customer?.businessName ?? '' }, 
		];

		const handleAfterChange = async (updatedStatus: Enums.customerMonitoredStatus) => {
			if (!this.customer || !updatedStatus) return;

			if (updatedStatus === 'INSOLVENT') {
				try {
					await confirmModal(
						'Please confirm',
						'Changing a customer’s status to insolvent cannot be undone.'
					+ ' Please confirm to continue.');
				} catch {
					this.fetchCustomerData();
					return;
				}
			}

			if (updatedStatus === 'DELETED') {
				try {
					await confirmModal(
						'Please confirm',
						'Deleting a customer will remove all data associated with the customer.'
					+ ' Please confirm to continue.',
					);
				} catch {
					this.fetchCustomerData();
					return;
				}
			}

			const cus = this.customer;
			if (!cus) return;

			const savedEntity = new CustomerEntity(this.customer);

			try {
				return axios({
					method: 'POST',
					url: `${SERVER_URL}/api/entity/CustomerEntity/`
						+ `monitored-status/${cus.id}/${cus.monitoredStatus}`
				}).then(
					action(() => {
						alertToast(
							'Successfully changed the customer monitored status', 
							'success'
						);
					}),
					action(() => {
						alertToast(
							`Cannot alter a Customer. `
							+ 'Please contact support if this status is incorrect.',
							'info'
						);
					})
				).then(() => {
					if (updatedStatus !== 'DELETED') {
						this.fetchCustomerData();
					} else {
						store.routerHistory.push('/monitor/customers');
					}
				});
			} catch (exception) {
				this.customer?.assignAttributes(savedEntity);
				alertToast(
					'Customer entity status could not be changed, '
					+ 'please refresh and try again', 
					'error'
				);
			}
		}

		return (
			<AccessIntelSecuredPage routeComponentProps={this.props} product="monitor">
				<div className="body-content customer-page">
					<div
						className={
							classNames(['invisible-page-wrap'], { grow: growWhiteSpaceTab() })
						}
					>
						<If condition={this.requestState === 'loading'}>
							<InlineSpinner />
						</If>
						<If condition={this.requestState === 'complete'}>
							<div className="top-container">
								<PageBreadcrumbs
									tags={pageBreadcrumbTags}
								/>
								<If condition={isSuperUser && this.showEditCustomerBusinessEntityModal}>
									<EditCustomerBusinessEntityModal
										customer={this.customer ?? new CustomerEntity()}
										onClose={() => {
											this.setShowEditCustomerBusinessEntityModal(false);
											this.fetchCustomerData();
										}}
									/>
								</If>
								<div className="status-dropdown-container">
									<Tooltip
										id="active-status-tooltip"
										content={'A customer may be change between monitored and not monitored as'
											+ ' frequently as required. However, once set as insolvent or deleted'
											+ ' the customer cannot be changed back.'}
									/>
									<Combobox
										label=""
										options={
											Object.entries(Enums.customerMonitoredStatusOptions)
												.map(([k, v]) => ({ value: k.toString(), display: `Status: ${v}` }))
										}
										model={this.customer}
										modelProperty="monitoredStatus"
										className={
											!this.customer
												? 'status-dropdown'
												: classNames(
													{
														MONITORED: 'active',
														NOT_MONITORED: 'inactive',
														INSOLVENT: 'error',
														DELETED: 'error',
													}[this.customer.monitoredStatus],
													'status-dropdown',
													{ disabled: false },
												)
										}
										isDisabled={!(
											store.userType === 'SUPER_USER' ||
											store.userType === 'ORGANISATION_MANAGER' ||
											store.userType === 'ADMIN'
										)}
										searchable={false}
										onAfterChange={async(_, data) => {
											await handleAfterChange(data.value as Enums.customerMonitoredStatus);
										}}
									/>
								</div>
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								 	IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								{
									store.userPermissions.intelCustomerOverview
										? (
											<button
												className={classNames('tab-selector', 'overview', {
													active: this.currentTab === 'overview',
												})}
												{...HandleEvents(action(() => {
													this.currentTab = 'overview';
												}))}
											>
												Overview
											</button>
										) : null
								}
								{
									store.userPermissions.intelViewCustomerBusinessInformation
										? (
											<button
												className={classNames('tab-selector', 'business-information', {
													active: this.currentTab === 'business-information',
												})}
												disabled={!monitored}
												{...HandleEvents(action(() => {
													this.currentTab = 'business-information';
												}))}
											>
												Business information
											</button>
										) : null
								}
								{
									store.userPermissions.intelViewCustomerPpsrRegistrations
										? (
											<button
												className={classNames('tab-selector', 'ppsr-registrations', {
													active: this.currentTab === 'ppsr-registrations',
												})}
												disabled={!monitored}
												{...HandleEvents(action(() => {
													this.currentTab = 'ppsr-registrations';
												}))}
											>
												PPSR registrations
											</button>
										) : null
								}
								{
									store.userPermissions.intelViewCustomerAccountsInformation
										? (
											<button
												className={classNames('tab-selector', 'accounts-information', {
													active: this.currentTab === 'accounts-information',
												})}
												disabled={!monitored}
												{...HandleEvents(action(() => {
													this.currentTab = 'accounts-information';
												}))}
											>
												Accounts information
											</button>
										) : null
								}
								<button
									className={classNames('tab-selector', 'adverse-alerts', {
										active: this.currentTab === 'adverse-alerts',
									})}
									disabled={!monitored}
									{...HandleEvents(action(() => {
										this.currentTab = 'adverse-alerts';
									}))}
								>
									Adverse alerts
								</button>
								<button
									className={classNames('tab-selector', 'customer-audits-tab', {
										active: this.currentTab === 'history',
									})}
									{...HandleEvents(action(() => {
										this.currentTab = 'history';
									}))}
								>
									History
								</button>
								{
									['ADMIN', 'ORGANISATION_MANAGER', 'SUPER_USER'].includes(store.userType)
										? (
											<button
												className={classNames('tab-selector', 'refer-for-collection', {
													active: this.currentTab === 'refer-for-collection',
												})}
												disabled={!monitored}
												{...HandleEvents(action(() => {
													this.currentTab = 'refer-for-collection';
												}))}
											>
												Refer for collection
											</button>
										) : null
								}
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': this.currentTab === 'overview',
							})}
							>
								<If condition={this.currentTab === 'overview'}>
									{!!this.customer && <CustomerOverview customer={this.customer} onAdverseAlertsClick={this.onAdverseAlertsClick} />}
								</If>
								<If condition={this.currentTab === 'ppsr-registrations'}>
									{!!this.customer && <PpsrRegistrations customer={this.customer} />}
								</If>
								<If condition={this.currentTab === 'business-information'}>
									{!!this.customer && <BusinessInformation customer={this.customer} />}
								</If>
								<If condition={this.currentTab === 'accounts-information'}>
									{!!this.customer && <AccountInformation customer={this.customer} />}
								</If>
								<If condition={this.currentTab === 'refer-for-collection'}>
									{ !!this.customer && <ReferForCollection customer={this.customer} />}
								</If>
								<If condition={this.currentTab === 'adverse-alerts'}>
									{!!this.customer && <AdverseAlertsReport customer={this.customer} />}
								</If>
								<If condition={this.currentTab === 'history'}>
									{!!this.customer && <CustomerAuditsTab customer={this.customer} />}
								</If>
							</div>
						</If>
					</div>
				</div>
			</AccessIntelSecuredPage>
		);
	}
}
export default CustomerPage;
