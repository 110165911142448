import { observer } from 'mobx-react';
import * as React from 'react';
import { IIconProps } from '../Helpers/Common';
import {
	Button, Colors, Display, Sizes, Widths,
} from './Button';
import { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import InlineSpinner from '../Spinner/InlineSpinner';

export interface ButtonAsyncStateProps {
    type?: 'submit' | 'reset' | 'button';
	display?: Display;
	colors?: Colors;
	sizes?: Sizes;
	widths?: Widths;
	buttonProps?: React.ButtonHTMLAttributes<Element>;
	children: React.ReactNode;
	className?: string;
	readonly?: boolean;
	onPress?: ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>);
	icon?: IIconProps;
	labelVisible?: false;
	// Saving... will be used by default
    waitingText?: string;
}

function ButtonAsyncState(props: ButtonAsyncStateProps) {
	const {
		waitingText, onPress, readonly: disabled, children,
	} = props;

	const [canRender, setCanRender] = React.useState(true);
	const [waiting, setWaiting] = React.useState(false);

	const onButtonPress = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const button = event.currentTarget;
		const previousMinWidth = button.style.minWidth;
		const width = `${button.clientWidth}px`;
		button.style.minWidth = width;

		setWaiting(true);
		if (onPress) {
			await onPress(event);
		}

		// If the component this button is in changes before the button changes state back,
		// a React render error is thrown. If this component has been destroyed, don't
		// bother changing state to re-render back to original text.
		if (canRender) {
			button.style.minWidth = previousMinWidth;
			setWaiting(false);
		}
	};

	useEffect(() => {
		return () => setCanRender(false);
	}, []);

	return (
		<Button
			onClick={onButtonPress}
			disabled={disabled || waiting}
			{...props}
		>
			{waiting
				? (
					<>
						<InlineSpinner size="Small" width="None" />
						{waitingText ?? 'Saving...'}
					</>
				)
				: children}
		</Button>
	);
}

export default ButtonAsyncState;
