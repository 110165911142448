import { Button, Display } from 'Views/Components/Button/Button';
import { observer, useLocalStore } from 'mobx-react';

import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import FileUpload from 'Views/Components/FileUpload/FileUpload';
import Modal from 'Views/Components/Modal/Modal';
import React from 'react';
import { SERVER_URL } from 'Constants';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import alertToast from 'Util/ToastifyUtils';
import axios from 'axios';
import { runInAction } from 'mobx';
import uploadAtoDebt from 'Hooks/Api/uploadAtoDebt';

interface Props {
	onClose: () => void;
}

const AtoUploadModal: React.FunctionComponent<Props> = observer(({ onClose }) => {
	const modalState = useLocalStore(() => ({
		file: undefined as File | undefined,
		extractedDate: undefined as Date | undefined,
		hasPreviousFile: false as boolean,
	}));

	async function checkFileIfExist(filename: string) {
		const url = `${SERVER_URL}/api/entity/AtoFileEntity/checkIfFileExists`;
		try {
			const response = await axios.get<Promise<boolean>>(url, { params: { filename } });
			return response.data;
		} catch (error) {
			console.error(error);
			alertToast('Failed to check if file exists. Please try again.', 'error');
			return false;
		}
	}

	async function handleAfterChange(file: File) {
		const validateFile = validateAtoDebtCsv(file);
		if (validateFile) {
			alertToast(validateFile.message, 'error');
			modalState.file = undefined;
			modalState.extractedDate = undefined;
			return;
		}

		const extractedDate = getExtractedDateFromFileName(file.name);
		if (!extractedDate) {
			alertToast('Invalid File Name: Extracted Date is missing', 'error');
			modalState.file = undefined;
			modalState.extractedDate = undefined;
			return;
		}

		// check if file already exists
		const isExist = await checkFileIfExist(file.name);

		runInAction(() => {
			modalState.file = file;
			modalState.extractedDate = extractedDate;
			modalState.hasPreviousFile = isExist;
		});
	}

	async function handleUpload() {
		if (!modalState.file || !modalState.extractedDate) {
			return;
		}

		try {
			await uploadAtoDebt(modalState.file, modalState.extractedDate.toString(), modalState.hasPreviousFile);
			alertToast('Successfully uploaded the ATO Debt File', 'success');
		} catch (error) {
			alertToast('Failed to upload ATO debt', 'error');
		}
		onClose();
	}

	return (
		<Modal
			isOpen
			label="Upload ATO Debt"
			onRequestClose={onClose}
			className="upload-ato-debt-modal"
		>
			<div>
				<h4>
					Upload file
					<Tooltip
						id="upload-ato-debt-tooltip"
						content="Upload a Disclosure of Business Tax Debt(DOBTD) file to update customers' ATO debt records."
						place="right"
					/>
				</h4>
			</div>
			<FileUpload
				model={modalState}
				modelProperty="file"
				contentType="text/csv,.csv"
				label="ATO Debt CSV*"
				labelVisible
				preview
				onDelete={() => {
					modalState.file = undefined;
					modalState.extractedDate = undefined;
					modalState.hasPreviousFile = false;
				}}
				onAfterChange={(file: File) => handleAfterChange(file)}
			/>

			{modalState.extractedDate && (
				<p className="alert alert__info centered">
					You are uploading a file for {modalState.extractedDate.toLocaleDateString()}
				</p>
			)}

			{modalState.hasPreviousFile && (
				<p className="alert alert__warning centered">
					A file for this date already exists. <br />Uploading this file will overwrite the existing file.
				</p>
			)}
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					onPress={() => handleUpload()}
					display={Display.Solid}
					readonly={!modalState.file}
					waitingText="Uploading..."
				>
					Upload CSV
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});

export default AtoUploadModal;

export function validateAtoDebtCsv(file: File): Error | undefined {
	function validateFileName(fileName: string, regExp: RegExp) {
		if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
			return new Error('Invalid Type');
		}

		const isValidFileName = regExp.test(fileName);
		if (!isValidFileName) {
			return new Error('Invalid File Name');
		}

		return undefined;
	}

	if (file.size === 0) {
		return new Error("File can't be empty");
	}

	// validate file naming convention
	const fileNamingRegx: RegExp = /^\d+_DOBTD_Extract_\d{14}_\d+_\d+\.CSV$/i;
	const hasError = validateFileName(file.name, fileNamingRegx);
	if (hasError) {
		return hasError;
	}

	return undefined;
}

export function getExtractedDateFromFileName(fileName: string): Date | undefined {
	const extractedDateRegx = /(Extract_)(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
	const match = extractedDateRegx.exec(fileName);

	if (!match) return undefined;

	const [,, year, month, day, hour, minute, second] = match;
	return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
}
