import * as React from 'react';
import ReferralPartnerGroupComponent from './ReferralPartnerGroupComponent';
import { SERVER_URL } from 'Constants';
import { store } from '../../../../Models/Store';
import If from '../../If/If';
import * as ReferralPartnerUtils from 'Util/ReferralPartnerUtils';
import alertToast from '../../../../Util/ToastifyUtils';
import { observer } from 'mobx-react';
import { LocaleFormatCurrency } from 'Util/StringUtils';
import { CustomerAuditDescription, saveCustomerAudit } from './CustomerAudits/Gibs/FetchCustomerAudits';

export interface IReferralPartnerTileProps {
	customerId: string;
	organisationId: string;
	orgName: string; // Name of the partner organisation
	blurb: string; // Referral blurb text
	groupName: string; // Name of the Referral Partner Group
	groupColour: string; // Base colour of the Referral Partner Group
	logoId: string; // Organisation logo
	isModal: boolean; // Show full blurb
}

class ReferralPartnerTile extends React.Component<IReferralPartnerTileProps> {
	public render() {
		const {
			customerId,
			organisationId,
			orgName,
			blurb,
			groupName,
			groupColour,
			logoId,
			isModal,
		} = this.props;

		const proceedButtonClick = async () => {
			const emailContent = await ReferralPartnerUtils.getEmailContent(customerId, organisationId);

			ReferralPartnerUtils
				.getReferralEmailLink(customerId, organisationId, emailContent)
				.then(link => window.open(link))
				.catch(ex => alertToast('An error occurred generating email.'));

			const descriptionData: CustomerAuditDescription = {
				title: 'Referred for Collection',
				// eslint-disable-next-line max-len
				message: `Partner Type: ${groupName} \nPartner Name: ${orgName} \nTotal Outstanding: ${LocaleFormatCurrency(emailContent.totalOutstanding)} \nTotal Overdue: ${LocaleFormatCurrency(emailContent.totalOverdue)} \n`,
			};

			try {
				await saveCustomerAudit(customerId, descriptionData, 'OTHER');
			} catch (e) {
				alertToast('Referral Partner changes could not be saved at this moment. Please'
					+ 'refresh and try again.', 'error');
				return;
			}
			alertToast('Referral Partner changes saved successfully', 'success');
		};

		return (
			<div className="referral-partner-tile">
				<div className="header">
					<img
						src={logoId !== null ? `${SERVER_URL}/api/files/${logoId}` : `${SERVER_URL}/favicon.ico`}
						alt={`Logo for ${orgName}`}
					/>
					<ReferralPartnerGroupComponent
						name={groupName}
						colour={groupColour}
						selected
					/>
				</div>
				<div className="body">
					<h5>{orgName}</h5>
					<p className="referral-partner-blurb">{blurb}</p>
					<If condition={isModal}>
						<div key="actions" className="modal__actions">
							<button
								type="button"
								className="btn btn--text"
								onClick={() => store.modal.hide()}
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn--solid btn--primary"
								onClick={proceedButtonClick}
							>
								Proceed
							</button>
						</div>
					</If>
					<If condition={!isModal}>
						<button
							type="button"
							className="btn btn--solid btn--primary"
							onClick={() => {
								store.modal.show(
									'Referral Partner',
									<ReferralPartnerTile
										customerId={customerId}
										organisationId={organisationId}
										orgName={orgName}
										blurb={blurb}
										groupName={groupName}
										groupColour={groupColour}
										logoId={logoId}
										isModal
									/>,
								);
							}}
						>
							Find Out More
						</button>
					</If>
				</div>
			</div>
		);
	}
}

export default observer(ReferralPartnerTile);
