// Used to add both a click event and an 'Enter'/'Space' keyboard event to the same element
// You can use the spread operator to add both to a component within JSX
export default function HandleEvents(callback?: (event: React.KeyboardEvent | React.MouseEvent) => void) {
	if (callback === undefined) {
		return {};
	}

	return {
		onClick: (event: React.MouseEvent) => {
			event.preventDefault();
			callback(event);
		},
		onKeyDown: HandleKeyboardEvent(['Enter', ' '], callback),
	};
}

export function HandleKeyboardEvent(
	keyboardKeys: string[],
	callback?: (event: React.KeyboardEvent | React.MouseEvent) => void,
) {
	return (event: React.KeyboardEvent) => {
		if (callback !== undefined && keyboardKeys.some(key => key === event.key)) {
			event.preventDefault(); // Otherwise the code will sometimes fire the event twice
			callback(event);
		}
	};
}
