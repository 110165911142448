const ESignPaths = {
	esign: '/esign',
	user: '/esign/user/:userId/sign/:workflowId',
	getUser: (userId: string, workflowId: string) => `/esign/user/${userId}/sign/${workflowId}`,
	authorise: '/esign/user/:userId/sign/:workflowId/authorise',
	getAuthorised: (userId: string, workflowId: string) => `/esign/user/${userId}/sign/${workflowId}/authorise`,
	sign: '/esign/user/:userId/sign/:workflowId/initiate',
	getSign: (userId: string, workflowId: string) => `/esign/user/${userId}/sign/${workflowId}/initiate`,
};

export default ESignPaths;
