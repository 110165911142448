import * as React from 'react';
import { observer } from 'mobx-react';
import Navigation, { Orientation } from '../Navigation/Navigation';
import SecuredPage from './SecuredPage';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from '../../FrontendNavLinks';
import MonitorNavLinks from '../../MonitorNavLinks';
import If from 'Views/Components/If/If';

export interface IAccessIntelSecuredPageProps {
    /**
     * The groups that can access this page.
     * If this is undefined then the page will be available to all
     * defaults to: ['Super Administrators', 'User']
     */
    groups?: string[];
    /** Children elements */
    children: React.ReactNode;
	/** product defaults to 'hub' */
	product?: ('hub' | 'monitor');
    /** Route Component Props */
    routeComponentProps: RouteComponentProps;
	/** Should the navigation bar be hidden */
	hideNavigation?: boolean;
}

/**
 * An Access Intell secured page is a component to manage the page access with respect to security groups, which defaults
 * to having Super Administrators and User as the groups and HubNavLinks as the links groups.
 */
const AccessIntelSecuredPage = observer((props: IAccessIntelSecuredPageProps) => {
	const {
		groups, children, routeComponentProps, product, hideNavigation,
	} = props;
	const {
		history, location, match, staticContext,
	} = routeComponentProps;

	const defaultGroups = ['Super Administrators', 'User'];

	const getNavLinks = () => {
		switch (product) {
			case 'hub':
				return getFrontendNavLinks(routeComponentProps);
			case 'monitor':
				return MonitorNavLinks(routeComponentProps);
			default:
				return getFrontendNavLinks(routeComponentProps);
		}
	};

	return (
		<SecuredPage groups={groups ?? defaultGroups}>
			<If condition={!hideNavigation}>
				<Navigation
					linkGroups={getNavLinks()}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
			</If>
			{children}
		</SecuredPage>
	);
});

export default AccessIntelSecuredPage;
