/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import AccountTile from 'Views/Components/Account/AccountTile';
import { UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import Spinner from 'Views/Components/Spinner/Spinner';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import ToggleUserActivatedButton from 'Views/Components/Account/ToggleUserActivatedButton';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
// % protected region % [Add any extra imports here] end

export interface AccountPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class AccountPage extends React.Component<AccountPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable private currentTab = 'account-information' as const;

	@observable private user?: UserEntity;

	public componentDidMount(): void {
		this.fetchUserData();
	}

	private fetchUserData = async () => {
		if (!store.userId) {
			return;
		}

		const userResult = await UserEntity.fetchUser(store.userId);
		if (userResult) {
			runInAction(() => {
				this.user = userResult;
			});
		}
	}
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'User']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for 42a903c0-3795-45f1-a272-54f49e80361c here] off begin
					}
					{
					// % protected region % [Add code for 42a903c0-3795-45f1-a272-54f49e80361c here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			<AccessIntelSecuredPage routeComponentProps={this.props}>
				<div className="body-content account-page">
					<div className="invisible-page-wrap">
						<div className="top-container">
							<PageBreadcrumbs
								tags={
									[
										{ label: 'Your Account' },
									]
								}
							/>
							<div>
								{!!this.user
									? <ToggleUserActivatedButton user={this.user} />
									: null}
							</div>
						</div>
						<div className="tab-selector-wrap">
							{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								 IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
							<button
								className={classNames('tab-selector', 'account-information', {
									active: this.currentTab === 'account-information',
								})}
								{...HandleEvents(action(() => { this.currentTab = 'account-information'; }))}
							>
								Your account information
							</button>
							{/* TODO: Add Account Plan Tab */}
						</div>
						<div className={classNames('white-box', {
							'square-top-left-border': this.currentTab === 'account-information',
						})}
						>
							<h4>Your account information</h4>
							<If condition={this.currentTab === 'account-information'}>
								{!!this.user
									? <AccountTile originalUser={this.user} />
									: <Spinner />}
							</If>
						</div>
					</div>
				</div>
			</AccessIntelSecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default AccountPage;
// % protected region % [Override export here] end
