import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import Success from 'Assets/Animations/success.json';
import ReferralPartnerRegistration from 'Assets/Animations/referral-partner-registration.json';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import LottieIcon from 'Views/Components/Animations/LottieIcon';
import useAsync from 'Hooks/useAsync';
import RegistrationData from 'Models/Entities/RegistrationData';
import { RegistrationEntity } from 'Models/Entities';
import { useEffect } from 'react';
import alertToast from 'Util/ToastifyUtils';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import If from '../../If/If';

const RegistrationSubmittedStep = observer((props: WizardComponentProps) => {
	const { changePage, currentStep } = props;
	const { model, data }: { model: RegistrationData, data?: RegistrationEntity } = props;

	const isReferralPartnerSubmission = currentStep?.options?.submissionType === 'referral-partner';

	// When we first reach this page, we save the submission, then submit it
	// (This is two separate steps, so that we can use the normal save to save the data to the db, then just
	// trigger the submit endpoint over the top of that)
	const submissionResult = useAsync(async () => {
		if (!data) {
			return Promise.reject();
		}

		return data.submitRegistration(isReferralPartnerSubmission);
	}, []);

	useEffect(() => {
		if (submissionResult.type !== 'error') {
			return;
		}

		if (submissionResult.error.response.data) {
			alertToast(submissionResult.error.response.data, 'error');
		} else {
			alertToast('There was an issue submitting your registration. Please try again, or contact Access Intell for'
			+ ' help.', 'error');
		}

		changePage(false);
	}, [changePage, submissionResult]);

	if (submissionResult.type !== 'data') {
		// If the submission failed, we still show this until the useEffect above redirects them to the previous page
		return (
			<div className="step-wrap registration-submitted-step">
				<InlineSpinner />
			</div>
		);
	}

	const renderClientCompleted = () => {
		return (
			<div className="step-wrap registration-submitted-step">
				<div className="step-contents registration-step align-one-third">
					<div className="alignment-box">
						<div className="submit-icon-container">
							<LottieIcon autoplay width={200} height={135} animationData={Success} />
						</div>
						<div className="step-header">
							<h5>Registration submitted, you can now activate your account</h5>
						</div>
						<div>
							<p>A confirmation email has been sent to your email address.</p>
							<p>It contains a link to set your password and access the platform.</p>
							<p>If you can&apos;t see the email in your inbox, check your junk folder.</p>
						</div>
						<div>
							<Button
								display={Display.Solid}
								colors={Colors.Secondary}
								onClick={() => { store.routerHistory.push('/'); }}
								className="return-to-login"
							>
								Return to login
							</Button>
						</div>
						<div className="questions-container">
							<p>If there are any questions, contact support@accessintell.com</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderReferralPartnerCompleted = () => {
		return (
			<div className="step-wrap registration-submitted-step">
				<div className="step-contents registration-step align-one-third">
					<div className="alignment-box">
						<LottieIcon
							autoplay
							width={250}
							height={200}
							animationData={ReferralPartnerRegistration}
						/>
						<div className="referral-partner-text">
							<div className="step-header">
								<h5>Sign up complete</h5>
							</div>
							<div>
								<p>
									Your referral link will be available when you sign in, please visit the sign in page
									to complete your registration.
								</p>
								<p>
									Please find your login details and instructions in the email sent to {data
										?.parsedReferralData.user?.email}. If you cannot see the email, please check
									your junk folder or contact support@accessintell.com
								</p>
							</div>
						</div>
						<div>
							<Button
								display={Display.Solid}
								colors={Colors.Primary}
								onClick={() => { store.routerHistory.push('/'); }}
								className="return-to-login"
							>
								Sign in
							</Button>
						</div>
					</div>
				</div>
			</div>

		);
	};

	return isReferralPartnerSubmission ? (
		renderReferralPartnerCompleted()
	) : (
		renderClientCompleted()
	);
});

export default RegistrationSubmittedStep;
