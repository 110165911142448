/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserOrganisationEntity from 'Models/Security/Acl/UserOrganisationEntity';
import VisitorsOrganisationEntity from 'Models/Security/Acl/VisitorsOrganisationEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { noSpaces } from 'Util/StringUtils';
import { IAttributeGroup } from 'Models/Model';
import { makeDropdownFetchOneToManyFunc } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] end

export interface IOrganisationEntityAttributes extends IModelAttributes {
	referrerBenefitType: Enums.referrerBenefitType;
	referralPartnerEmail: string;
	isClient: boolean;
	legacyID: number;
	isReferralPartner: boolean;
	monthlyExposure: number;
	referralPartnerBlurb: string;
	referrerBusinessOther: string;
	referrerBankAccountName: string;
	referralPartnerLogoId: string;
	referralPartnerLogo: Blob;
	maxActiveUserCount: number;
	customerCount: number;
	referrerCharityName: string;
	approveEnabled: boolean;
	referrerBusinessType: Enums.referrerBusinessType;
	ppsrEnabled: boolean;
	referrerCommissionPercent: number;
	isActive: boolean;
	referrerBSB: string;
	referrerCommissionFrequency: Enums.referrerCommissionFrequency;
	howDidYouHearAboutUs: string;
	intelEnabled: boolean;
	criskcoApplicantID: string;
	customerColumnSettings: string;
	acceptedterms: Date;
	referrerAcceptedTerms: boolean;
	referrerBankAccountNumber: string;
	enforceOnlinePayments: boolean;

	authorisedCreditBureaus: Array<
		| Models.OrganisationAuthorisedCreditBureau
		| Models.IOrganisationAuthorisedCreditBureauAttributes
	>;
	businessEntitys: Array<
		| Models.BusinessEntity
		| Models.IBusinessEntityAttributes
	>;
	customerss: Array<
		| Models.CustomerEntity
		| Models.ICustomerEntityAttributes
	>;
	discountss: Array<
		| Models.DiscountEntity
		| Models.IDiscountEntityAttributes
	>;
	groupss: Array<
		| Models.GroupEntity
		| Models.IGroupEntityAttributes
	>;
	invoices: Array<
		| Models.InvoiceEntity
		| Models.IInvoiceEntityAttributes
	>;
	monitoringProviders: Array<
		| Models.MonitoringProviderOrganisation
		| Models.IMonitoringProviderOrganisationAttributes
	>;
	industryCodesId?: string;
	industryCodes?: Models.IndustryCodesEntity | Models.IIndustryCodesEntityAttributes;
	referralPartnerGroupId?: string;
	referralPartnerGroup?: Models.ReferralPartnerGroupEntity | Models.IReferralPartnerGroupEntityAttributes;
	primaryBusinessEntityId?: string;
	primaryBusinessEntity?: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	providerProductOrganisations: Array<
		| Models.ProviderProductOrganisationEntity
		| Models.IProviderProductOrganisationEntityAttributes
	>;
	referredRegistrationss: Array<
		| Models.RegistrationEntity
		| Models.IRegistrationEntityAttributes
	>;
	registration?: Models.RegistrationEntity |
		Models.IRegistrationEntityAttributes;
	users: Array<
		| Models.UserEntity
		| Models.IUserEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	integrationXeroEnabled: boolean;
	integrationXeroTokenExpiry?: Date|null;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] on begin
export const AttributeGroupPrimary: number = 1;
export const AttributeGroupIntegrations: number = 2;
export const AttributeGroupReferralPartner: number = 3;
export const AttributeGroupOther: number = 99;

@entity('OrganisationEntity', 'Organisation')
// % protected region % [Customise your entity metadata here] end
export default class OrganisationEntity extends Model
	implements IOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserOrganisationEntity(),
		new VisitorsOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Benefit Type'] on begin
	/**
	 * The mode which they would like to receive commission
	 */
	@observable
	@attribute<OrganisationEntity, Enums.referrerBenefitType>()
	@CRUD({
		name: 'Referrer Benefit Type',
		displayType: 'enum-combobox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.referrerBenefitTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.referrerBenefitTypeOptions),
		displayFunction: (attr: Enums.referrerBenefitType) => Enums.referrerBenefitTypeOptions[attr],
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBenefitType: Enums.referrerBenefitType;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Benefit Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Email'] on begin
	@Validators.Email()
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referral Partner Email',
		displayType: 'textfield',
		order: 260,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referralPartnerEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Client'] on begin
	/**
	 * Is the organisation a client
	 */
	@Validators.Required()
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Is Client',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public isClient: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Client'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] on begin
	@Validators.Integer()
	@observable
	@attribute<OrganisationEntity, number>()
	@CRUD({
		name: 'Legacy ID',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
		groupId: AttributeGroupPrimary,
		isReadonly: true,
	})
	public legacyID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Referral Partner'] on begin
	/**
	 * Is the organisation a referral partner
	 */
	@Validators.Required()
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Is Referral Partner',
		displayType: 'checkbox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public isReferralPartner: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Referral Partner'] end

	// % protected region % [Modify props to the crud options here for attribute 'Monthly Exposure'] on begin
	/**
	 * This is the current monthly exposure of the organisation
	 */
	@Validators.Numeric()
	@observable
	@attribute<OrganisationEntity, number>()
	@CRUD({
		name: 'Monthly Exposure',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
		groupId: AttributeGroupPrimary,
	})
	public monthlyExposure: number;
	// % protected region % [Modify props to the crud options here for attribute 'Monthly Exposure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Blurb'] on begin
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referral Partner Blurb',
		displayType: 'textarea',
		order: 250,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referralPartnerBlurb: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Blurb'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Business Other'] on begin
	/**
	 * Other Business Type Description
	 */
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referrer Business Other',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBusinessOther: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Business Other'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Bank Account Name'] on begin
	/**
	 * Bank Account Name for the referral partner
	 */
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referrer Bank Account Name',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBankAccountName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Bank Account Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Logo'] on begin
	@observable
	@attribute<OrganisationEntity, string>({ file: 'referralPartnerLogo' })
	@CRUD({
		name: 'Referral Partner Logo',
		displayType: 'file',
		order: 270,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'referralPartnerLogo',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Organisation" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
		groupId: AttributeGroupReferralPartner,
	})
	public referralPartnerLogoId: string;

	@observable
	public referralPartnerLogo: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Referral Partner Logo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Active User Count'] on begin
	/**
	 * The maximum Count of Active Users used for billing calculation
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<OrganisationEntity, number>()
	@CRUD({
		name: 'Max Active User Count',
		displayType: 'textfield',
		order: 240,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
		groupId: AttributeGroupPrimary,
	})
	public maxActiveUserCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Active User Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Customer Count'] on begin
	/**
	 * This customer count is used for pricing if the organisation has not added any customers yet in the Monitor platform
	 */
	@Validators.Integer()
	@observable
	@attribute<OrganisationEntity, number>()
	@CRUD({
		name: 'Customer Count',
		displayType: 'textfield',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
		groupId: AttributeGroupPrimary,
	})
	public customerCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Customer Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Charity Name'] on begin
	/**
	 * The name of the charity they would like to receive their commission
	 */
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referrer Charity Name',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerCharityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Charity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Approve Enabled'] on begin
	/**
	 * Approve Enabled
	 */
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Approve Enabled',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public approveEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Approve Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Business Type'] on begin
	/**
	 * What does your business do
	 */
	@observable
	@attribute<OrganisationEntity, Enums.referrerBusinessType>()
	@CRUD({
		name: 'Referrer Business Type',
		displayType: 'enum-combobox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.referrerBusinessTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.referrerBusinessTypeOptions),
		displayFunction: (attr: Enums.referrerBusinessType) => Enums.referrerBusinessTypeOptions[attr],
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBusinessType: Enums.referrerBusinessType;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Business Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'PPSR Enabled'] on begin
	/**
	 * PPSR Enabled
	 */
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'PPSR Enabled',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public ppsrEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'PPSR Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Commission Percent'] on begin
	/**
	 * The discount applied to referred clients from this organisation
	 */
	@Validators.Integer()
	@observable
	@attribute<OrganisationEntity, number>()
	@CRUD({
		name: 'Referrer Commission Percent',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerCommissionPercent: number;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Commission Percent'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] on begin
	/**
	 * Is Active
	 */
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 80,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer BSB'] on begin
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referrer BSB',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBSB: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer BSB'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer  Commission Frequency'] on begin
	/**
	 * How often does the organisaion receive the commission
	 */
	@observable
	@attribute<OrganisationEntity, Enums.referrerCommissionFrequency>()
	@CRUD({
		name: 'Referrer  Commission Frequency',
		displayType: 'enum-combobox',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.referrerCommissionFrequencyOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.referrerCommissionFrequencyOptions),
		displayFunction: (attr: Enums.referrerCommissionFrequency) => Enums.referrerCommissionFrequencyOptions[attr],
		groupId: AttributeGroupReferralPartner,
	})
	public referrerCommissionFrequency: Enums.referrerCommissionFrequency;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer  Commission Frequency'] end

	// % protected region % [Modify props to the crud options here for attribute 'How Did You Hear About Us'] on begin
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'How Did You Hear About Us',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupPrimary,
	})
	public howDidYouHearAboutUs: string;
	// % protected region % [Modify props to the crud options here for attribute 'How Did You Hear About Us'] end

	// % protected region % [Modify props to the crud options here for attribute 'Intel Enabled'] on begin
	/**
	 * Intel Enabled
	 */
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Intel Enabled',
		displayType: 'checkbox',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupPrimary,
	})
	public intelEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Intel Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'CRiskCo Applicant ID'] on begin
	/**
	 * CRiskCo Applicant ID
	 */
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'CRiskCo Applicant ID',
		displayType: 'textfield',
		order: 10,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public criskcoApplicantID: string;
	// % protected region % [Modify props to the crud options here for attribute 'CRiskCo Applicant ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Customer Column Settings'] on begin
	/**
	 * Customer Column Settings
	 */
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Customer Column Settings',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		isReadonly: true,
	})
	public customerColumnSettings: string;
	// % protected region % [Modify props to the crud options here for attribute 'Customer Column Settings'] end

	// % protected region % [Modify props to the crud options here for attribute 'AcceptedTerms'] on begin
	/**
	 * Date the user last accepted terms. If this is prior to the last time the terms were updated, the user may need to accept them again.
	 */
	@observable
	@attribute<OrganisationEntity, Date>()
	@CRUD({
		name: 'AcceptedTerms',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public acceptedterms: Date;
	// % protected region % [Modify props to the crud options here for attribute 'AcceptedTerms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Accepted Terms'] on begin
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Referrer Accepted Terms',
		displayType: 'checkbox',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupReferralPartner,
	})
	public referrerAcceptedTerms: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Accepted Terms'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referrer Bank Account Number'] on begin
	@observable
	@attribute<OrganisationEntity, string>()
	@CRUD({
		name: 'Referrer Bank Account Number',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		groupId: AttributeGroupReferralPartner,
	})
	public referrerBankAccountNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referrer Bank Account Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enforce Online Payments'] off begin
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Enforce Online Payments',
		displayType: 'checkbox',
		order: 280,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enforceOnlinePayments: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enforce Online Payments'] end

	/**
	 * Credit bureaus the organisation has agreed to share data with
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Authorised Credit Bureau'] on begin
		name: 'Authorised Credit Bureau',
		displayType: 'reference-multicombobox',
		order: 330,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationAuthorisedCreditBureau,
		optionEqualFunc: makeJoinEqualsFunc('authorisedCreditBureauId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'organisationEntity',
			oppositeEntityName: 'creditBureauEntity',
			relationName: 'organisation',
			relationOppositeName: 'authorisedCreditBureau',
			entity: () => Models.OrganisationEntity,
			joinEntity: () => Models.OrganisationAuthorisedCreditBureau,
			oppositeEntity: () => Models.CreditBureauEntity,
		}),
		groupId: AttributeGroupPrimary,
		// % protected region % [Modify props to the crud options here for reference 'Authorised Credit Bureau'] end
	})
	public authorisedCreditBureaus: Models.OrganisationAuthorisedCreditBureau[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entitys',
		displayType: 'reference-multicombobox',
		order: 300,
		referenceTypeFunc: () => Models.BusinessEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'businessEntitys',
			oppositeEntity: () => Models.BusinessEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntitys: Models.BusinessEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customers'] off begin
		name: 'Customerss',
		displayType: 'reference-multicombobox',
		order: 310,
		referenceTypeFunc: () => Models.CustomerEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerss',
			oppositeEntity: () => Models.CustomerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customers'] end
	})
	public customerss: Models.CustomerEntity[] = [];

	/**
	 * An organisation can have discounts
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Discounts'] off begin
		name: 'Discountss',
		displayType: 'reference-multicombobox',
		order: 320,
		referenceTypeFunc: () => Models.DiscountEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'discountss',
			oppositeEntity: () => Models.DiscountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Discounts'] end
	})
	public discountss: Models.DiscountEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Groups'] off begin
		name: 'Groupss',
		displayType: 'reference-multicombobox',
		order: 330,
		referenceTypeFunc: () => Models.GroupEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'groupss',
			oppositeEntity: () => Models.GroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Groups'] end
	})
	public groupss: Models.GroupEntity[] = [];

	/**
	 * Invoice
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Invoice'] on begin
		name: 'Invoices',
		displayType: 'reference-multicombobox',
		order: 270,
		referenceTypeFunc: () => Models.InvoiceEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeDropdownFetchOneToManyFunc({
			relationName: 'invoices',
			oppositeEntity: () => Models.InvoiceEntity,
		}),
		isReadonly: true,
		// % protected region % [Modify props to the crud options here for reference 'Invoice'] end
	})
	public invoices: Models.InvoiceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Monitoring Provider'] off begin
		name: 'Monitoring Provider',
		displayType: 'reference-multicombobox',
		order: 350,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MonitoringProviderOrganisation,
		optionEqualFunc: makeJoinEqualsFunc('monitoringProviderId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'organisationEntity',
			oppositeEntityName: 'monitoringProviderEntity',
			relationName: 'organisation',
			relationOppositeName: 'monitoringProvider',
			entity: () => Models.OrganisationEntity,
			joinEntity: () => Models.MonitoringProviderOrganisation,
			oppositeEntity: () => Models.MonitoringProviderEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Monitoring Provider'] end
	})
	public monitoringProviders: Models.MonitoringProviderOrganisation[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Industry Codes'] on begin
		name: 'Industry Codes',
		displayType: 'reference-combobox',
		order: 280,
		referenceTypeFunc: () => Models.IndustryCodesEntity,
		groupId: AttributeGroupPrimary,
		// % protected region % [Modify props to the crud options here for reference 'Industry Codes'] end
	})
	public industryCodesId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public industryCodes: Models.IndustryCodesEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Referral Partner Group'] on begin
		name: 'Referral Partner Group',
		displayType: 'reference-combobox',
		order: 340,
		referenceTypeFunc: () => Models.ReferralPartnerGroupEntity,
		groupId: AttributeGroupReferralPartner,
		// % protected region % [Modify props to the crud options here for reference 'Referral Partner Group'] end
	})
	public referralPartnerGroupId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public referralPartnerGroup: Models.ReferralPartnerGroupEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Primary Business Entity'] on begin
		name: 'Primary Business Entity',
		displayType: 'reference-combobox',
		order: 310,
		referenceTypeFunc: () => Models.BusinessEntity,
		referenceResolveFunction: makeDropdownFetchOneToManyFunc({
			relationName: 'primaryBusinessEntitys',
			oppositeEntity: () => Models.BusinessEntity,
		}),
		groupId: AttributeGroupPrimary,
		isReadonly: true,
		// % protected region % [Modify props to the crud options here for reference 'Primary Business Entity'] end
	})
	public primaryBusinessEntityId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public primaryBusinessEntity: Models.BusinessEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] off begin
		name: 'Provider Product Organisations',
		displayType: 'reference-multicombobox',
		order: 390,
		referenceTypeFunc: () => Models.ProviderProductOrganisationEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'providerProductOrganisations',
			oppositeEntity: () => Models.ProviderProductOrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] end
	})
	public providerProductOrganisations: Models.ProviderProductOrganisationEntity[] = [];

	/**
	 * Keep track of organisation which made the referral
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Referred Registrations'] on begin
		name: 'Referred Registrationss',
		displayType: 'reference-multicombobox',
		order: 290,
		referenceTypeFunc: () => Models.RegistrationEntity,
		referenceResolveFunction: makeDropdownFetchOneToManyFunc({
			relationName: 'referredRegistrationss',
			oppositeEntity: () => Models.RegistrationEntity,
		}),
		groupId: AttributeGroupReferralPartner,
		isReadonly: true,
		// % protected region % [Modify props to the crud options here for reference 'Referred Registrations'] end
	})
	public referredRegistrationss: Models.RegistrationEntity[] = [];

	/**
	 * Registration which created this organisation
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Registration'] on begin
		name: 'Registration',
		displayType: 'reference-combobox',
		order: 320,
		referenceTypeFunc: () => Models.RegistrationEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeDropdownFetchOneToManyFunc({
			relationName: 'registrations',
			oppositeEntity: () => Models.RegistrationEntity,
		}),
		isReadonly: true,
		// % protected region % [Modify props to the crud options here for reference 'Registration'] end
	})
	public registration?: Models.RegistrationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'Users',
		displayType: 'reference-multicombobox',
		order: 420,
		referenceTypeFunc: () => Models.UserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'users',
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public users: Models.UserEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute<OrganisationEntity, boolean>()
	@CRUD({
		name: 'Integration Xero Enabled',
		displayType: 'checkbox',
		order: 340,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		groupId: AttributeGroupIntegrations,
	})
	public integrationXeroEnabled: boolean;

	@observable
	@attribute<OrganisationEntity, Date>()
	@CRUD({
		name: 'Integration Xero Token Expiry',
		displayType: 'datetimepicker',
		order: 350,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
		groupId: AttributeGroupIntegrations,
		isReadonly: true,
	})
	public integrationXeroTokenExpiry?: Date|null;

	public attributeGroups = [
		{
			id: AttributeGroupPrimary,
			order: AttributeGroupPrimary,
			name: 'Primary',
			showName: true,
		},
		{
			id: AttributeGroupIntegrations,
			order: AttributeGroupIntegrations,
			name: 'Integrations',
			showName: true,
		},
		{
			id: AttributeGroupReferralPartner,
			order: AttributeGroupReferralPartner,
			name: 'Referral Partner',
			showName: true,
		},
		{
			id: AttributeGroupOther,
			order: AttributeGroupOther,
			name: 'Other',
			showName: true,
		},
	] as IAttributeGroup[];

	@action public validateField = async (field: string) => {
		switch (field) {
			case 'businessType': {
				this.setFieldError('businessType', !this.referrerBusinessType, 'A business type must be selected');
				break;
			}
			case 'businessTypeOther': {
				this.setFieldError(
					'businessTypeOther',
					this.referrerBusinessType === 'OTHER' && !this.referrerBusinessOther,

					'A summary must be provided',
				);
				break;
			}
			case 'benefitType': {
				this.setFieldError('benefitType', !this.referrerBenefitType, 'A benefit type must be selected');
				break;
			}
			case 'commissionFrequency': {
				this.setFieldError('commissionFrequency', this.referrerBenefitType === 'COMMISSION'
					&& !this.referrerCommissionFrequency, 'A commission frequency must be selected');
				break;
			}
			case 'accountName': {
				this.setFieldError('accountName', this.referrerBenefitType === 'COMMISSION'
					&& !this.referrerBankAccountName, 'A bank account name must be entered');
				break;
			}
			case 'bsb': {
				this.setFieldError('bsb', this.referrerBenefitType === 'COMMISSION' && (!this.referrerBSB
					|| !OrganisationEntity.validBSB(this.referrerBSB)), 'A valid BSB must be entered');
				break;
			}
			case 'accountNumber': {
				this.setFieldError('accountNumber', this.referrerBenefitType === 'COMMISSION'
					&& (!this.referrerBankAccountNumber || !OrganisationEntity.validAccountNumber(
						this.referrerBankAccountNumber,
					)), 'A valid bank account number must be entered');
				break;
			}
			case 'charity': {
				this.setFieldError(
					'charity',
					this.referrerBenefitType === 'CHARITY' && !this.referrerCharityName,
					'A charity must be selected',
				);
				break;
			}
		}
	};

	public validateBenefitFields = async () => {
		const fieldList = [
			'businessType', 'charity', 'accountNumber', 'bsb', 'accountName', 'commissionFrequency', 'benefitType',
			'businessTypeOther',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	};
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.referrerBenefitType !== undefined) {
				this.referrerBenefitType = attributes.referrerBenefitType;
			}
			if (attributes.referralPartnerEmail !== undefined) {
				this.referralPartnerEmail = attributes.referralPartnerEmail;
			}
			if (attributes.isClient !== undefined) {
				this.isClient = attributes.isClient;
			}
			if (attributes.legacyID !== undefined) {
				this.legacyID = attributes.legacyID;
			}
			if (attributes.isReferralPartner !== undefined) {
				this.isReferralPartner = attributes.isReferralPartner;
			}
			if (attributes.monthlyExposure !== undefined) {
				this.monthlyExposure = attributes.monthlyExposure;
			}
			if (attributes.referralPartnerBlurb !== undefined) {
				this.referralPartnerBlurb = attributes.referralPartnerBlurb;
			}
			if (attributes.referrerBusinessOther !== undefined) {
				this.referrerBusinessOther = attributes.referrerBusinessOther;
			}
			if (attributes.referrerBankAccountName !== undefined) {
				this.referrerBankAccountName = attributes.referrerBankAccountName;
			}
			if (attributes.referralPartnerLogo !== undefined) {
				this.referralPartnerLogo = attributes.referralPartnerLogo;
			}
			if (attributes.referralPartnerLogoId !== undefined) {
				this.referralPartnerLogoId = attributes.referralPartnerLogoId;
			}
			if (attributes.maxActiveUserCount !== undefined) {
				this.maxActiveUserCount = attributes.maxActiveUserCount;
			}
			if (attributes.customerCount !== undefined) {
				this.customerCount = attributes.customerCount;
			}
			if (attributes.referrerCharityName !== undefined) {
				this.referrerCharityName = attributes.referrerCharityName;
			}
			if (attributes.approveEnabled !== undefined) {
				this.approveEnabled = attributes.approveEnabled;
			}
			if (attributes.referrerBusinessType !== undefined) {
				this.referrerBusinessType = attributes.referrerBusinessType;
			}
			if (attributes.ppsrEnabled !== undefined) {
				this.ppsrEnabled = attributes.ppsrEnabled;
			}
			if (attributes.referrerCommissionPercent !== undefined) {
				this.referrerCommissionPercent = attributes.referrerCommissionPercent;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.referrerBSB !== undefined) {
				this.referrerBSB = attributes.referrerBSB;
			}
			if (attributes.referrerCommissionFrequency !== undefined) {
				this.referrerCommissionFrequency = attributes.referrerCommissionFrequency;
			}
			if (attributes.howDidYouHearAboutUs !== undefined) {
				this.howDidYouHearAboutUs = attributes.howDidYouHearAboutUs;
			}
			if (attributes.intelEnabled !== undefined) {
				this.intelEnabled = attributes.intelEnabled;
			}
			if (attributes.criskcoApplicantID !== undefined) {
				this.criskcoApplicantID = attributes.criskcoApplicantID;
			}
			if (attributes.customerColumnSettings !== undefined) {
				this.customerColumnSettings = attributes.customerColumnSettings;
			}
			if (attributes.acceptedterms !== undefined) {
				if (attributes.acceptedterms === null) {
					this.acceptedterms = attributes.acceptedterms;
				} else {
					this.acceptedterms = moment(attributes.acceptedterms).toDate();
				}
			}
			if (attributes.referrerAcceptedTerms !== undefined) {
				this.referrerAcceptedTerms = attributes.referrerAcceptedTerms;
			}
			if (attributes.referrerBankAccountNumber !== undefined) {
				this.referrerBankAccountNumber = attributes.referrerBankAccountNumber;
			}
			if (attributes.enforceOnlinePayments !== undefined) {
				this.enforceOnlinePayments = attributes.enforceOnlinePayments;
			}
			if (attributes.authorisedCreditBureaus !== undefined && Array.isArray(attributes.authorisedCreditBureaus)) {
				for (const model of attributes.authorisedCreditBureaus) {
					if (model instanceof Models.OrganisationAuthorisedCreditBureau) {
						this.authorisedCreditBureaus.push(model);
					} else {
						this.authorisedCreditBureaus.push(new Models.OrganisationAuthorisedCreditBureau(model));
					}
				}
			}
			if (attributes.businessEntitys !== undefined && Array.isArray(attributes.businessEntitys)) {
				for (const model of attributes.businessEntitys) {
					if (model instanceof Models.BusinessEntity) {
						this.businessEntitys.push(model);
					} else {
						this.businessEntitys.push(new Models.BusinessEntity(model));
					}
				}
			}
			if (attributes.customerss !== undefined && Array.isArray(attributes.customerss)) {
				for (const model of attributes.customerss) {
					if (model instanceof Models.CustomerEntity) {
						this.customerss.push(model);
					} else {
						this.customerss.push(new Models.CustomerEntity(model));
					}
				}
			}
			if (attributes.discountss !== undefined && Array.isArray(attributes.discountss)) {
				for (const model of attributes.discountss) {
					if (model instanceof Models.DiscountEntity) {
						this.discountss.push(model);
					} else {
						this.discountss.push(new Models.DiscountEntity(model));
					}
				}
			}
			if (attributes.groupss !== undefined && Array.isArray(attributes.groupss)) {
				for (const model of attributes.groupss) {
					if (model instanceof Models.GroupEntity) {
						this.groupss.push(model);
					} else {
						this.groupss.push(new Models.GroupEntity(model));
					}
				}
			}
			if (attributes.invoices !== undefined && Array.isArray(attributes.invoices)) {
				for (const model of attributes.invoices) {
					if (model instanceof Models.InvoiceEntity) {
						this.invoices.push(model);
					} else {
						this.invoices.push(new Models.InvoiceEntity(model));
					}
				}
			}
			if (attributes.monitoringProviders !== undefined && Array.isArray(attributes.monitoringProviders)) {
				for (const model of attributes.monitoringProviders) {
					if (model instanceof Models.MonitoringProviderOrganisation) {
						this.monitoringProviders.push(model);
					} else {
						this.monitoringProviders.push(new Models.MonitoringProviderOrganisation(model));
					}
				}
			}
			if (attributes.industryCodesId !== undefined) {
				this.industryCodesId = attributes.industryCodesId;
			}
			if (attributes.industryCodes !== undefined) {
				if (attributes.industryCodes === null) {
					this.industryCodes = attributes.industryCodes;
				} else if (attributes.industryCodes instanceof Models.IndustryCodesEntity) {
					this.industryCodes = attributes.industryCodes;
					this.industryCodesId = attributes.industryCodes.id;
				} else {
					this.industryCodes = new Models.IndustryCodesEntity(attributes.industryCodes);
					this.industryCodesId = this.industryCodes.id;
				}
			}
			if (attributes.referralPartnerGroupId !== undefined) {
				this.referralPartnerGroupId = attributes.referralPartnerGroupId;
			}
			if (attributes.referralPartnerGroup !== undefined) {
				if (attributes.referralPartnerGroup === null) {
					this.referralPartnerGroup = attributes.referralPartnerGroup;
				} else if (attributes.referralPartnerGroup instanceof Models.ReferralPartnerGroupEntity) {
					this.referralPartnerGroup = attributes.referralPartnerGroup;
					this.referralPartnerGroupId = attributes.referralPartnerGroup.id;
				} else {
					this.referralPartnerGroup = new Models.ReferralPartnerGroupEntity(attributes.referralPartnerGroup);
					this.referralPartnerGroupId = this.referralPartnerGroup.id;
				}
			}
			if (attributes.primaryBusinessEntityId !== undefined) {
				this.primaryBusinessEntityId = attributes.primaryBusinessEntityId;
			}
			if (attributes.primaryBusinessEntity !== undefined) {
				if (attributes.primaryBusinessEntity === null) {
					this.primaryBusinessEntity = attributes.primaryBusinessEntity;
				} else if (attributes.primaryBusinessEntity instanceof Models.BusinessEntity) {
					this.primaryBusinessEntity = attributes.primaryBusinessEntity;
					this.primaryBusinessEntityId = attributes.primaryBusinessEntity.id;
				} else {
					this.primaryBusinessEntity = new Models.BusinessEntity(attributes.primaryBusinessEntity);
					this.primaryBusinessEntityId = this.primaryBusinessEntity.id;
				}
			}
			if (attributes.providerProductOrganisations !== undefined && Array.isArray(attributes.providerProductOrganisations)) {
				for (const model of attributes.providerProductOrganisations) {
					if (model instanceof Models.ProviderProductOrganisationEntity) {
						this.providerProductOrganisations.push(model);
					} else {
						this.providerProductOrganisations.push(new Models.ProviderProductOrganisationEntity(model));
					}
				}
			}
			if (attributes.referredRegistrationss !== undefined && Array.isArray(attributes.referredRegistrationss)) {
				for (const model of attributes.referredRegistrationss) {
					if (model instanceof Models.RegistrationEntity) {
						this.referredRegistrationss.push(model);
					} else {
						this.referredRegistrationss.push(new Models.RegistrationEntity(model));
					}
				}
			}
			if (attributes.registration !== undefined) {
				if (attributes.registration === null) {
					this.registration = attributes.registration;
				} else if (attributes.registration instanceof Models.RegistrationEntity) {
					this.registration = attributes.registration;
				} else {
					this.registration = new Models.RegistrationEntity(attributes.registration);
				}
			}
			if (attributes.users !== undefined && Array.isArray(attributes.users)) {
				for (const model of attributes.users) {
					if (model instanceof Models.UserEntity) {
						this.users.push(model);
					} else {
						this.users.push(new Models.UserEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.integrationXeroTokenExpiry !== undefined) {
				if (attributes.integrationXeroTokenExpiry === null) {
					this.integrationXeroTokenExpiry = attributes.integrationXeroTokenExpiry;
				} else {
					this.integrationXeroTokenExpiry = moment(attributes.integrationXeroTokenExpiry).toDate();
				}
			}
			if (attributes.integrationXeroEnabled !== undefined) {
				this.integrationXeroEnabled = attributes.integrationXeroEnabled;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public listExpands = `
		primaryBusinessEntity {
			id
			name
		}
	`;

	public defaultExpands = `
		authorisedCreditBureaus {
			${Models.OrganisationAuthorisedCreditBureau.getAttributes().join('\n')}
			authorisedCreditBureau {
				${Models.CreditBureauEntity.getAttributes().join('\n')}
			}
		}
		businessEntitys {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		customerss {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
		discountss {
			${Models.DiscountEntity.getAttributes().join('\n')}
		}
		invoices {
			${Models.InvoiceEntity.getAttributes().join('\n')}
			organisation {
				id
				primaryBusinessEntity {
					id
					name
				}
			}
		}
		industryCodes {
			${Models.IndustryCodesEntity.getAttributes().join('\n')}
		}
		referredRegistrationss {
			${Models.RegistrationEntity.getAttributes().join('\n')}
		}
		users {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		primaryBusinessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		registration {
			${Models.RegistrationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = this.getAttributeCRUDOptions()
			.filter(crudOptions => crudOptions.isReference() && !crudOptions.isReadonlyCrud())
			.reduce(
				(previousValue, crudOptions) => {
					previousValue[crudOptions.attributeName] = {};
					return previousValue;
				},
				{},
			);

		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: Object.keys(relationPath),
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.primaryBusinessEntity?.name ?? this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public static async fetchOrganisationList(search: string|undefined, skip?: number, take?: number) {
		const conditions: { case: string, comparison: string, path: string, value: string[]}[] = [];
		if (!!search && search !== '') {
			// We are only searching on organisation
			conditions.push({
				path: 'primaryBusinessEntity.name',
				comparison: 'like',
				value: [`%${search}%`],
				case: 'INVARIANT_CULTURE_IGNORE_CASE',
			});
		}

		try {
			const results = await store.apolloClient.query({
				query: gql`
					query fetchOrganisations($conditions: [[WhereExpressionGraph]], $skip: Int, $take: Int) {
						organisationEntitys(conditions: $conditions, skip: $skip, take: $take,
							orderBy: [{path: "primaryBusinessEntity.name"}]) {
							id
							primaryBusinessEntity {
								id
								name
							}
						}
					}`,
				variables: {
					conditions: conditions.length ? [conditions] : null,
					skip: skip,
					take: take,
				},
				fetchPolicy: 'no-cache',
			});

			return results.data.organisationEntitys
				.map((x: Partial<IModelAttributes>) => new this(x)) as OrganisationEntity[];
		} catch {
			return undefined;
		}
	}

	/**
	 * Function to remove intel product and the associated many to many references with the
	 * disclosed bureaus. This function deletes the references but doesn't save the organisation
	 * entity
	 */
	@action public async removeIntelProduct() {
		this.intelEnabled = false;
		const promises = [];
		for (const reference of this.authorisedCreditBureaus) {
			promises.push(reference.delete());
		}

		for (const be of this.businessEntitys) {
			be.removeMonitorProduct();
		}
		await Promise.all(promises);
	}

	/**
	 * This function removes the ppsr product from the organisation
	 * and all business entities. However, does not save the entities,
	 * this must be handled externally
	 */
	@action public removePpsrProduct() {
		this.ppsrEnabled = false;
		for (const be of this.businessEntitys) {
			be.removePpsrProduct();
		}
	}

	/**
	 * This function removes the approve product from the organisation
	 * and all business entities. However, does not save the entities,
	 * this must be handled externally
	 */
	@action public removeApproveProduct() {
		this.approveEnabled = false;
		for (const be of this.businessEntitys) {
			be.removeApproveProduct();
		}
	}

	public getSearchConditions<T extends Model>(searchTerm?: string): Array<Array<IWhereCondition<T>>> | undefined {
		const conditions = super.getSearchConditions(searchTerm);

		if (searchTerm && searchTerm.trim() !== '' && conditions?.length) {
			const valid = AttrUtils.standardiseString(searchTerm.trim());
			conditions[0].push({
				path: 'primaryBusinessEntity.name',
				comparison: 'like',
				value: valid?.query,
				...valid?.extraOptions,
			});
		}

		return conditions;
	}

	public static validBSB = (bsb: string) : boolean => {
		return (bsb.match(/[^0-9- ]/) === null)
			&& (bsb.replace(/[^0-9]/g, '').length === 6);
	};

	public static validAccountNumber = (accountNumber: string) : boolean => {
		return noSpaces(accountNumber).match(/^[0-9]{8,}$/) !== null;
	};

	@action
	public clearBenefitTypeFields = () => {
		this.setFieldError('commissionFrequency', false, '');
		this.setFieldError('accountName', false, '');
		this.setFieldError('bsb', false, '');
		this.setFieldError('accountNumber', false, '');
		this.setFieldError('charity', false, '');
		// @ts-ignore
		this.referrerCommissionFrequency = undefined;
		// @ts-ignore
		this.referrerBankAccountName = undefined;
		// @ts-ignore
		this.referrerBSB = undefined;
		// @ts-ignore
		this.referrerBankAccountNumber = undefined;
		// @ts-ignore
		this.referrerCharityName = undefined;
	};
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(OrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(OrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
