import * as React from 'react';
import { ApplicationEntity } from '../../../Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import Modal from '../Modal/Modal';
import ApplicationFields from '../Approve/ApplicationFields';
import { Button, Display } from '../Button/Button';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface ApplicationDetailsModalProps {
    applicationEntity: ApplicationEntity,
    onClose: () => void,
    onSubmit: () => Promise<void>,
}

const ApplicationDetailsModal = observer((props: ApplicationDetailsModalProps) => {
	const { applicationEntity, onClose, onSubmit } = props;

	const privateApplicationEntity = useLocalStore(() => {
		return new ApplicationEntity(applicationEntity);
	});

	const submit = async () => {
		await privateApplicationEntity.validateAllFields();
		if (Object.keys(privateApplicationEntity.errors).length === 0) {
			runInAction(() => {
				applicationEntity.assignAttributes(privateApplicationEntity);
			});
			await onSubmit();
		}
	};

	return (
		<Modal
			isOpen
			label="Create new application"
			onRequestClose={onClose}
			className="access-modal add-application-modal"
		>
			<h4>Create Approve application</h4>
			<ApplicationFields applicationEntity={privateApplicationEntity} create />
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					readonly={Object.keys(privateApplicationEntity.errors).length > 0}
					onPress={submit}
					display={Display.Solid}
				>
					Add
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});

export default ApplicationDetailsModal;
