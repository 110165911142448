import { ESignWitnessDetail } from 'ESign/Types/ESignWitnessDetail';
import { isPhone } from 'Validators/Functions/Custom';
import { isEmail } from 'Validators/Functions/Email';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { observer } from 'mobx-react';
import React from 'react';
import { Segment } from 'semantic-ui-react';

type Props = {
	witnessDetails: ESignWitnessDetail;
	setWitnessDetails: (witnessDetails: ESignWitnessDetail) => void;
	requireMobileNumber: boolean;
	handleError: (hasError: boolean) => void;
}

const ESignWitnessDetailsSection: React.FunctionComponent<Props> = ({
	witnessDetails,
	setWitnessDetails,
	requireMobileNumber,
	handleError,
}) => {
	const validateWitnessMobileNumber = (value: string): string | undefined => {
		if (!requireMobileNumber && value.length === 0) {
			return undefined;
		}
		return !isPhone(value) ? 'Please enter a valid Mobile Number' : undefined;
	};

	const validateWitnessEmail = (value: string): string | undefined => {
		if (!isEmail(value)) {
			return 'Please Enter a valid Email Address';
		}
		return undefined;
	};

	const validateWitnessName = (value: string): string | undefined => {
		if (value.trim().length === 0) {
			return 'Please enter your full Name';
		}
		return undefined;
	};

	const validWitnessMobileNumber = validateWitnessMobileNumber(witnessDetails.phone ?? '');
	const validWitnessEmail = validateWitnessEmail(witnessDetails.email);
	const validWitnessName = validateWitnessName(witnessDetails.name);

	const validateFields = () => {
		handleError(
			validateWitnessName(witnessDetails.name) !== undefined
			|| validateWitnessMobileNumber(witnessDetails.phone ?? '') !== undefined
			|| validateWitnessEmail(witnessDetails.email) !== undefined,
		);
	};

	return (
		<Segment padded raised className="esign-witness-form">
			<h2 className="esign-witness-form-item"> Add Witness Details</h2>
			<TextField
				className="esign-witness-form-item"
				model={witnessDetails}
				modelProperty="name"
				label="Witness Full Name"
				onAfterChange={value => {
					setWitnessDetails({
						...witnessDetails,
						name: value.target.value,
					});
					validateFields();
				}}
				errors={validWitnessName}
				isRequired
			/>
			<TextField
				className="esign-witness-form-item"
				inputProps={{ type: 'email', required: true }}
				model={witnessDetails}
				modelProperty="email"
				label="Witness Email"
				onAfterChange={value => {
					setWitnessDetails({
						...witnessDetails,
						email: value.target.value,
					});
					validateFields();
				}}
				errors={validWitnessEmail}
				isRequired
			/>
			<TextField
				className="esign-witness-form-item"
				model={witnessDetails}
				modelProperty="phone"
				label="Witness Mobile Number"
				onAfterChange={value => {
					setWitnessDetails({
						...witnessDetails,
						phone: value.target.value,
					});
					validateFields();
				}}
				errors={validWitnessMobileNumber}
				isRequired={requireMobileNumber}
			/>
		</Segment>
	);
};

export default observer(ESignWitnessDetailsSection);
