import { useESignStore } from 'ESign/Stores/ESignStore';
import LoadableState from 'ESign/Types/ESignLoadableState';
import ESignSigningStage from 'ESign/Types/ESignSigningStage';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import If from 'Views/Components/If/If';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import {
	Grid, GridColumn, GridRow, Segment,
} from 'semantic-ui-react';
import ESignRejectModal from './ESignRejectModal';
import classNames from 'classnames';
import { SERVER_URL } from 'Constants';

type Props = {
	stage: ESignSigningStage;
	documentName: string;
	handleSaveAndExit: () => Promise<void>;
	handleReject: (comment: string) => Promise<void>;
}

const SigningStepHeader: React.FunctionComponent<Props> = ({
	stage, documentName, handleSaveAndExit, handleReject,
}) => {
	const { workflowStore: { loadingState, selectedCeremony } } = useESignStore();

	const [showRejectModal, setShowRejectModal] = useState(false);

	const businessEntityFileId = selectedCeremony?.workflow.template.businessLogoFileId;

	const title = useMemo(() => {
		switch (stage) {
			case 'Content':
				return `Signing - ${documentName}`;
			case 'Witness':
				return 'Add Witness Details';
			case 'Review':
				return `Review - ${documentName}`;
			default:
				return '';
		}
	}, [documentName, stage]);

	return (
		<>
			<Segment basic>
				<Grid
					columns="equal"
					textAlign="justified"
					stackable
					className={classNames('esign-ceremony-header', { content: stage === 'Content' })}
					verticalAlign="middle"
				>

					<GridRow>
						<GridColumn width={16} className="esign-column">
							<Grid
								columns="equal"
								verticalAlign="middle"
							>
								<GridRow>
									<GridColumn textAlign="left">
										<If condition={stage === 'Content'}>
											<Button
												sizes={Sizes.Medium}
												colors={Colors.Error}
												display={Display.Solid}
												onClick={() => setShowRejectModal(true)}
												disabled={loadingState === LoadableState.Pending}
											>
												Reject
											</Button>
										</If>
									</GridColumn>
									{businessEntityFileId && (
										<img
											src={`${SERVER_URL}/api/files/${businessEntityFileId}`}
											alt="Company Logo"
											height="100vh"
										/>
									)}
									<GridColumn textAlign="right">
										<If condition={stage === 'Content'}>
											<ButtonAsyncState
												display={Display.Solid}
												colors={Colors.Primary}
												sizes={Sizes.Medium}
												onPress={handleSaveAndExit}
												readonly={loadingState === LoadableState.Pending}
											>
												Save & Exit
											</ButtonAsyncState>
										</If>
									</GridColumn>
								</GridRow>
							</Grid>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn width={16} className="esign-column">
							{stage !== 'Witness' && (<h5>{title}</h5>)}
						</GridColumn>
					</GridRow>
				</Grid>
			</Segment>
			{showRejectModal && (
				<ESignRejectModal
					isOpen={showRejectModal}
					onClose={() => setShowRejectModal(false)}
					confirmReject={handleReject}
				/>
			)}
		</>
	);
};

export default observer(SigningStepHeader);
