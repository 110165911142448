import * as React from 'react';
import { useState } from 'react';
import {
	BusinessEntity, CreditBureauEntity, OrganisationEntity, TrustEntityCodesEntity,
} from 'Models/Entities';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import If from 'Views/Components/If/If';
import classnames from 'classnames';
import { store } from 'Models/Store';
import FileDownloadLink from 'Views/Components/FileUpload/FileDownloadLink';
import CheckboxSetter from 'Views/Components/Checkbox/CheckboxSetter';
import AddBusinessDetailsModal from 'Views/Components/BusinessEntities/AddBusinessDetailsModal';
import EditPpsrDetailsModal from 'Views/Components/BusinessEntities/EditPpsrDetailsModal';
import EditApproveDetailsModal from 'Views/Components/BusinessEntities/EditApproveDetailsModal';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { action } from 'mobx';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import moment from 'moment';
import { formatAbn } from 'Util/StringUtils';

export type BusinessEntityListItemProps = {
	organisation: OrganisationEntity,
	businessEntity: BusinessEntity,
	readonly?: boolean,
	defaultOpen: boolean,
	isPrimary: boolean,
}

type BusinessEntityListItemModalState = 'none'|'business-details'|'approve-product'|'ppsr-product'

const BusinessEntityListItem = observer((props: BusinessEntityListItemProps) => {
	const {
		organisation, businessEntity, readonly, isPrimary, defaultOpen,
	} = props;

	const [modalState, setModalState] = useState('none' as BusinessEntityListItemModalState);
	const [expanded, setExpanded] = useState(defaultOpen);
	const initials = !!businessEntity.name
		? businessEntity.name
			.split(' ')
			.map(word => word[0])
			.slice(0, 2)
			.join('')
			.toUpperCase()
		: '';

	const emailInUse = businessEntity.useOrganisationEmail
		? organisation.primaryBusinessEntity?.email
		: businessEntity.email;

	return (
		<div className="business-entity-item">
			<div className="business-entity-label-area">
				<div className="business-entity-image">
					{!!businessEntity.applicationss.length && !!businessEntity.applicationss[0].logoId
						? (
							<img
								src={`${SERVER_URL}/api/files/${businessEntity.applicationss[0].logoId}`}
								alt={`${businessEntity.name} logo`}
							/>
						)
						: initials}
				</div>
				<div className="business-entity-details">
					<div className="business-entity-name">
						{businessEntity.name}
						<If condition={isPrimary}>
							<span className="default-tag"> (Default)</span>
						</If>
					</div>
					<div className="business-entity-abn">
						<If condition={!!businessEntity.abn}>
							ABN {formatAbn(businessEntity.abn)}
						</If>
					</div>
				</div>
				<div className="business-entity-products">
					<If condition={businessEntity.enabledForApprove && organisation.approveEnabled}>
						<span className="product-tag approve-product">Approve</span>
					</If>
					<If condition={businessEntity.enabledForPPSR && organisation.ppsrEnabled}>
						<span className="product-tag ppsr-product">PPSR</span>
					</If>
					<If condition={businessEntity.enabledForMonitor && organisation.intelEnabled}>
						<span className="product-tag intel-product">Monitor</span>
					</If>
				</div>
				<button
					type="button"
					className={classnames('expand-business-entity', {
						expanded: expanded,
					})}
					onClick={() => setExpanded(oldValue => !oldValue)}
					aria-label="Expand business entity"
				/>
			</div>
			<If condition={expanded}>
				<div className="business-entity-expand-area">
					<If condition={
						!readonly
						&& !isPrimary
						&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
					}
					>
						<button
							type="button"
							className="remove-business-entity-btn icon-right icon-cross"
							onClick={
								() => confirmModal(
									'Please confirm',
									'Are you sure you want to delete this business entity?',
								)
									.then(action(() => {
										businessEntity.isActive = false;
										businessEntity.save();
									}))
							}
						>
							Remove business entity
						</button>
					</If>
					<div className="business-entity-view-section business-entity-details">
						<If condition={
							!readonly
							&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
						}
						>
							<button
								type="button"
								className="edit-business-entity-btn"
								onClick={() => setModalState('business-details')}
								aria-label="Edit business entity details"
							/>
						</If>
						<If condition={modalState === 'business-details'}>
							<AddBusinessDetailsModal
								businessEntity={businessEntity}
								organisationEmail={emailInUse}
								onClose={() => setModalState('none')}
								onSubmit={async () => {
									await businessEntity.save({
										address: {},
									});
									setModalState('none');
								}}
								additionalValidationFields={['summary']}
								isPrimaryBusinessEntity={isPrimary}
							/>
						</If>
						<div className="view-section-content-area">
							<p className="view-section-title">Business Entity Details</p>

							<p className="view-section-field-name">Phone number</p>
							<p className="view-section-field phone">{businessEntity.phone}</p>

							<p className="view-section-field-name">Entity email</p>
							<p className="view-section-field email">{emailInUse ?? 'N/A'}</p>

							<If condition={businessEntity.entityTypeCode !== ''
								&& TrustEntityCodesEntity.trusteeCodesSync.includes(businessEntity.entityTypeCode)}
							>
								{businessEntity.trusteeACN !== '' ? (
									<>
										<p className="view-section-field-name">Trustee ACN</p>
										<p className="view-section-field trustee-acn">{businessEntity.trusteeACN}</p>
									</>
								)
									: (null) }

								{businessEntity.trusteeName !== '' ? (
									<>
										<p className="view-section-field-name">Trustee name</p>
										<p className="view-section-field trustee-name">{businessEntity.trusteeName}</p>
									</>
								)
									: (null) }

							</If>

							<If condition={businessEntity.entityTypeCode === 'IND'}>
								{businessEntity.dateOfBirth !== undefined ? (
									<>
										<p className="view-section-field-name">Sole trader DOB</p>
										<p className="view-section-field DOB">
											{moment(businessEntity.dateOfBirth).format('DD/MM/YYYY')}
										</p>
									</>
								)
									: (null) }

							</If>

							<p className="view-section-field-name">Address</p>
							<p className="view-section-field address">
								{businessEntity?.address?.getDisplayName() ?? 'N/A'}
							</p>
						</div>
					</div>
					<If condition={
						organisation.approveEnabled
						&& store.getUser?.canAccessApprove
						&& (
							businessEntity.enabledForApprove
							|| (
								!readonly
								&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
							)
						)
					}
					>
						<div className="business-entity-view-section approve-details">
							<If
								condition={
									businessEntity.enabledForApprove
									&& !readonly
									&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
								}
							>
								<button
									type="button"
									className="edit-business-entity-btn"
									onClick={() => setModalState('approve-product')}
									aria-label="Edit business entity details"
								/>
							</If>
							<div className="view-section-content-area">
								<p className="view-section-title">
									Approve product

									<If condition={
										businessEntity.enabledForApprove
										&& !readonly
										&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
									}
									>
										<button
											type="button"
											className="remove-product-btn icon-material-delete-forever icon-left"
											onClick={
												() => confirmModal(
													'Please confirm',
													'Are you sure you want to remove this product '
													+ 'from this business entity?',
												)
													.then(async () => {
														await businessEntity.removeApproveProduct();
														await businessEntity.save();
													})
											}
										>
											Remove product
										</button>
									</If>
								</p>

								<If condition={businessEntity.enabledForApprove === true
										&& businessEntity.applicationss.length > 0}
								>
									<p className="view-section-field-name">
										Setup digital online application
									</p>
									<p className="view-section-field approve-product">Yes</p>

									<p className="view-section-field-name">Terms and Conditions</p>
									<p className="view-section-field terms-and-conditions">
										<FileDownloadLink
											fileId={businessEntity.applicationss.length > 0 ? businessEntity
												.applicationss[0].termsAndConditionsId : ''}
											fallbackValue="N/A"
										/>
									</p>
									<p className="view-section-field-name">Privacy Policy</p>
									<p className="view-section-field privacy-policy">
										<FileDownloadLink
											fileId={businessEntity.applicationss.length > 0 ? businessEntity
												.applicationss[0].privacyPolicyId : ''}
											fallbackValue="N/A"
										/>
									</p>
								</If>
								<If condition={
									!businessEntity.enabledForApprove
									&& !readonly
									&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
								}
								>
									<Button
										className="add-approve icon-plus icon-left"
										onClick={() => setModalState('approve-product')}
										colors={Colors.Alternate}
										display={Display.OutlineDashed}
									>
										Add Approve
									</Button>
								</If>
							</div>
							<If condition={modalState === 'approve-product'}>
								<EditApproveDetailsModal
									businessEntity={businessEntity}
									onClose={() => setModalState('none')}
									onSubmit={async () => {
										await businessEntity.save(
											{
												applicationss: {},
											},
											{
												contentType: 'multipart/form-data',
											},
										);
										setModalState('none');
									}}
								/>
							</If>
						</div>
					</If>
					<If condition={
						organisation.ppsrEnabled
						&& store.getUser?.canAccessPPSR
						&& (
							businessEntity.enabledForPPSR
							|| (
								!readonly
								&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
							)
						)
					}
					>
						<div className="business-entity-view-section ppsr-details">
							<If
								condition={
									businessEntity.enabledForPPSR
									&& !readonly
									&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
								}
							>
								<button
									type="button"
									className="edit-business-entity-btn"
									onClick={() => setModalState('ppsr-product')}
									aria-label="Edit business entity details"
								/>
							</If>
							<div className="view-section-content-area">
								<p className="view-section-title">
									PPSR product

									<If condition={
										businessEntity.enabledForPPSR
										&& !readonly
										&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
									}
									>
										<button
											type="button"
											className="remove-product-btn icon-material-delete-forever icon-left"
											onClick={
												() => confirmModal(
													'Please confirm',
													'Are you sure you want to remove this product '
													+ 'from this business entity?',
												)
													.then(async () => {
														await businessEntity.removePpsrProduct();
														await businessEntity.save();
													})
											}
										>
											Remove product
										</button>
									</If>
								</p>

								<If condition={businessEntity.enabledForPPSR && businessEntity.spgss.length > 0}>
									<p className="view-section-field-name">Existing SPG no.</p>
									<p className="view-section-field spg-number">
										{businessEntity.spgss.length > 0
											? businessEntity.spgss[0].spgNumber ?? 'N/A' : 'N/A'}
									</p>

									<p className="view-section-field-name">Existing access code</p>
									<p className="view-section-field access-code">
										{businessEntity.spgss.length > 0 ? businessEntity.spgss[0].accessCode : ''}
									</p>

									<p className="view-section-field-name">Type of business</p>
									<CheckboxSetter
										className="business-type-checkbox hire"
										value={businessEntity.hire}
										label="Hire"
										isReadOnly
									/>
									<CheckboxSetter
										className="business-type-checkbox supply"
										value={businessEntity.supplyGnE}
										label="Supply of Goods & Equipment"
										isReadOnly
									/>
									<CheckboxSetter
										className="business-type-checkbox finance"
										value={businessEntity.finance}
										label="Finance"
										isReadOnly
									/>

									<p className="view-section-field-name">Brief summary of business</p>
									<p className="view-section-field summary">{businessEntity.summary}</p>
								</If>
								<If condition={
									!businessEntity.enabledForPPSR
									&& !readonly
									&& store.userPermissions.commonManageBusinessEntities !== 'NONE'
								}
								>
									<Button
										className="add-ppsr icon-plus icon-left"
										onClick={() => setModalState('ppsr-product')}
										colors={Colors.Alternate}
										display={Display.OutlineDashed}
									>
										Add PPSR
									</Button>
								</If>
								<If condition={modalState === 'ppsr-product'}>
									<EditPpsrDetailsModal
										businessEntity={businessEntity}
										onClose={() => setModalState('none')}
										onSubmit={async () => {
											await businessEntity.save({
												spgss: {},
											});
											setModalState('none');
										}}
									/>
								</If>
							</div>
						</div>
					</If>
				</div>
			</If>
		</div>
	);
});
export default BusinessEntityListItem;
