import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import RegistrationData from 'Models/Entities/RegistrationData';
import IndustryCodeSelector from 'Views/Components/Wizard/OrganisationRegistration/IndustryCodeSelector';
import { useState } from 'react';
import { Errors } from 'Util/CustomTypes';
import { monthlyExposureComboboxOptions } from 'Util/PricingHelper';
import If from 'Views/Components/If/If';

const PricingQuestionsStep = observer((props: WizardComponentProps) => {
	const { changePage } = props;
	const { model }: { model: RegistrationData } = props;

	const [errors, setErrors] = useState({} as Errors);

	const onNext = () => {
		const newErrors = { ...errors };

		if (!!model.activeCustomers) {
			delete newErrors.activeCustomers;
		} else {
			newErrors.activeCustomers = 'Number of active customers required';
		}

		if (!!model.monthlyExposure) {
			delete newErrors.monthlyExposure;
		} else {
			newErrors.monthlyExposure = 'Total monthly exposure required';
		}

		if (!!model.industryCode) {
			delete newErrors.industryCode;
		} else {
			newErrors.industryCode = 'Industry required';
		}
		setErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			changePage();
		}
	};

	const unsetError = (fieldName: string) => {
		setErrors(oldErrors => {
			delete oldErrors[fieldName];
			return { ...oldErrors };
		});
	};

	return (
		<div className="step-wrap pricing-questions-step">
			<div className="step-contents align-one-third">
				<div className="alignment-box">
					<div className="limit-width-box">
						<h5 className="step-header">Tell us about your organisation</h5>
						<p className="step-description">
							The following questions include all of your business entities
						</p>

						<NumberTextField
							className="active-customers"
							model={model}
							modelProperty="activeCustomers"
							label="Number of active customers"
							placeholder="No. active customers"
							onAfterChange={() => {
								if (!!errors.activeCustomers) {
									unsetError('activeCustomers');
								}
							}}
							errors={errors.activeCustomers}
							isRequired
						/>

						<Combobox
							className="monthly-exposure"
							model={model}
							modelProperty="monthlyExposure"
							label="What is your total monthly exposure value?"
							placeholder="Select value"
							onAfterChange={() => unsetError('monthlyExposure')}
							options={monthlyExposureComboboxOptions}
							errors={errors.monthlyExposure}
							isRequired
						/>

						<p className="input-extra-information">
							i.e. Accumulative amount of your debtors in a month
						</p>

						<IndustryCodeSelector
							model={model}
							modelProperty="industryCode"
							className="industry-code"
							onChange={() => unsetError('industryCode')}
							errors={errors.industryCode}
							isRequired
						/>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right next-btn"
					colors={Colors.Primary}
					display={Display.Solid}
					disabled={Object.keys(errors).length > 0}
					onClick={onNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
});

export default PricingQuestionsStep;
