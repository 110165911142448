import { useEffect, useMemo, useState } from 'react';
/**
### usePersistState
A custom React hook that persists state in `localStorage`.
#### Parameters
- `initialValue` (`T`): The initial value of the state. This can be of any type `T`.
- `key` (`string`): The key under which the state will be stored in `localStorage`.
- `clearAfter` (`number`, optional): The number of milliseconds after which the state will be cleared from `localStorage`. Defaults to (5 min).
#### Returns
An array `[state, setState]` where:
- `state` (`T`): The current state. This will be initialized to the value from `localStorage` if it exists, otherwise to `initialValue`.
- `setState` (`(newState: T) => void`): A function to update the state. This will also update the value in `localStorage`.
#### Usage
```typescript
 */

function usePersistState<T>(
	initialValue: T,
	key: string,
	clearAfter: number = (5 * 60 * 1000),
) : [T, (newState: T) => void ] {
	// setting initial value
	const updatedInitialValue = useMemo(() => {
		const localStorageValue = localStorage.getItem(getAccessIntellLocalStageKey(key));

		return localStorageValue ? JSON.parse(localStorageValue) : initialValue;
	}, [initialValue, key]);

	const [state, setState] = useState<T>(updatedInitialValue);

	useEffect(() => {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(getAccessIntellLocalStageKey(key), serializedState);

		// Schedule removal of the key from localStorage after 5 minutes
		const timeoutId = setTimeout(() => {
			localStorage.removeItem(getAccessIntellLocalStageKey(key));
		}, clearAfter); // 300000ms = 5 minutes
		// cleanup function to clear the timeout
		// if the component is unmounted before the timeout is reached
		return () => clearTimeout(timeoutId);
	}, [key, state, clearAfter]);

	return [state, setState];
}

export default usePersistState;

function getAccessIntellLocalStageKey(key: string) {
	return `access-intell_${key}`;
}
