import { BusinessEntity, SpgEntity } from "Models/Entities";
import { store } from "Models/Store";
import { useState } from "react";
import alertToast from "Util/ToastifyUtils";
import EditPpsrDetailsModal from "../BusinessEntities/EditPpsrDetailsModal";
import If from "../If/If";
import { BusinessEntitySelector } from "./BusinessEntitySelector";
import SectionTitle from "./SectionTitle";

export const PpsrSection = () => {
	const isSuper = store.userType === 'SUPER_USER';
	const isManager = store.userType === 'ORGANISATION_MANAGER';
	const isBusinessEntityAdmin = store.userType === 'ADMIN';
	const isPpsrEnabled = store.getUser?.organisation?.ppsrEnabled;
	const showPpsrSetup = (isSuper || isManager || (isBusinessEntityAdmin && isPpsrEnabled)) && store.canAccessPPSR;
	const [showBusinessEntitySelectorModal, setShowBusinessEntitySelectorModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [businessEntity, setBusinessEntity] = useState<BusinessEntity | undefined>(undefined); 

	const openModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		setShowBusinessEntitySelectorModal(true);
	}

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const link = e.currentTarget.querySelector('a');
		if (link) {
			link.click();
		}
	}

	const setLinkClass = () => {
		return store.canAccessPPSR ? "hub-link" : "hub-inactive-link";
	};
	
	return (
		<>
			<SectionTitle
				Link={store.canAccessPPSR
					? `${store.appSettings.ppsrBaseUrl}${store.appSettings.ppsrLoginPath}`
					: store.appSettings.ppsrMarketingUrl}
				Text="PPSR"
				DisplayButton={!isPpsrEnabled}
			/>
			<div className="action-content extra-margin">
				<div className={setLinkClass()}>
					<div className="icon-left icon-clipboard" />
					<a href={store.appSettings.ppsrBaseUrl + "/registration/home.php"} target="_blank" rel="noreferrer">
						View Registrations
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-check-circle-2" />
					<a href={store.appSettings.ppsrBaseUrl + "/registration/create.php"} 
						target="_blank" 
						rel="noreferrer"
					>
						Process new registration
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-plugin" />
					<a href={store.appSettings.ppsrBaseUrl + "/registration/home.php"} target="_blank" rel="noreferrer">
						Discharge PPSR registration
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-search" />
					<a href={store.appSettings.ppsrBaseUrl + "/searches/create.php"} target="_blank" rel="noreferrer">
						Perform PPSR search
					</a>
				</div>
				<If condition={showPpsrSetup}>
					<div className="hub-link" onClick={showPpsrSetup ? openModal : handleClick}>
						<div className="icon-left icon-settings" />
						<a href="/hub/organisation/products" target="_blank" rel="noreferrer">
							Configure PPSR profile
						</a>
					</div> 
				</If>
			</div>

			<If condition={showBusinessEntitySelectorModal}>
				<BusinessEntitySelector
					onClose={() => { setShowBusinessEntitySelectorModal(false); setBusinessEntity(undefined);}}
					onSelect={() => { setShowBusinessEntitySelectorModal(false); setShowProductModal(true) }}
					businessEntity={businessEntity}
					setBusinessEntity={setBusinessEntity}
					productFilter="ppsr"
				/>
			</If>

			<If condition={showProductModal && businessEntity !== null}>
				<EditPpsrDetailsModal
					businessEntity={businessEntity ?? new BusinessEntity()}
					onClose={() => { setShowProductModal(false); setBusinessEntity(undefined); }}
					onSubmit={async () => {
						try {
							await businessEntity?.saveWithFetchBack({
								spgss: {},
							}, {}, `
								spgss {
									${SpgEntity.getAttributes().join('\n')}
								}
							`);
							setBusinessEntity(undefined);
							setShowProductModal(false);
							alertToast('Access PPSR successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access PPSR could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>
		</>
	)
}