import { SERVER_URL } from 'Constants';
import { EsigntemplateEntity, EsigntemplatecontentEntity, LogoEntity } from 'Models/Entities';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import TemplateContent, { DocumentContentDetail } from './TemplateContent';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';

export interface EditableTemplateContentSection {
	requireWitness: boolean;
	useSmsAuth:boolean;
	content: string;
}
interface Props {
	template: EsigntemplateEntity;
	updateTemplate: (template: EsigntemplateEntity, version: number) => void;
	currentVersion: number;
	logos: LogoEntity[];
	hasLogoChanged: (hasChanged: boolean) => void;
	isReadOnly?: boolean;
}

const TemplateFields: React.FunctionComponent<Props> = observer(({
	template,
	updateTemplate,
	currentVersion,
	logos,
	hasLogoChanged,
	isReadOnly,
}) => {
	const [logoDetails, setLogoDetails] = React.useState<{[key: string]: string}>({
		initial: template.logoId ?? '',
		current: template.logoId ?? '',
	});

	const initialEditableTemplateContent:{[version: number]: EditableTemplateContentSection} = useMemo(() => {
		const initialContents = template.esigntemplatecontents.reduce((acc, content) => {
			return {
				...acc,
				[content.version]: {
					requireWitness: content.requireswitness ?? false,
					useSmsAuth: content.usesmsauth ?? false,
					content: content.content ?? '',
				},
			};
		}, {});

		return initialContents;
	}, [template.esigntemplatecontents]);

	const [editableTemplateContent, setEditableTemplateContent] = useState<{
		[version: number]: EditableTemplateContentSection
	}>(initialEditableTemplateContent);

	const [enableEdit, setEnableEdit] = useState(template.id === undefined);

	function addNewVersion() {
		// add new version for the template content
		const newVersion = Math.max(...Object.keys(editableTemplateContent).map(Number)) + 1;
		const newEditableTemplateContent: EditableTemplateContentSection = {
			requireWitness: editableTemplateContent[currentVersion].requireWitness,
			useSmsAuth: editableTemplateContent[currentVersion].useSmsAuth,
			content: editableTemplateContent[currentVersion].content,
		};

		// update the template with the new version
		const newTemplateContent = new EsigntemplatecontentEntity({
			requireswitness: newEditableTemplateContent.requireWitness,
			usesmsauth: newEditableTemplateContent.useSmsAuth,
			content: newEditableTemplateContent.content,
			created: new Date(),
			modified: new Date(),
			version: newVersion,
			esigntemplateId: template.id,
			esigntemplate: template,
		});

		const updatedTemplateContents = [...template.esigntemplatecontents, newTemplateContent];
		const updatedTemplate = new EsigntemplateEntity(template);
		updatedTemplate.esigntemplatecontents = updatedTemplateContents;
		updateTemplate(updatedTemplate, newVersion);

		setEditableTemplateContent({
			...editableTemplateContent,
			[newVersion]: newEditableTemplateContent,
		});
		setEnableEdit(!enableEdit);
	}

	function handleContentChange(requireswitness?: boolean, usesmsauth?: boolean, content?: string) {
		const updatedTemplateContentSection = {
			requireswitness: requireswitness ?? editableTemplateContent[currentVersion].requireWitness,
			useSmsAuth: usesmsauth ?? editableTemplateContent[currentVersion].useSmsAuth,
			content: content ?? editableTemplateContent[currentVersion].content,
		};

		const selectedTemplateContent = template.esigntemplatecontents
			.find(contentDetail => contentDetail.version === currentVersion);
		const isNewVersion = !selectedTemplateContent?.id;

		if (!isNewVersion) {
			// unexpected scenario
			return;
		}

		const updatedTemplateContent = new EsigntemplatecontentEntity(selectedTemplateContent);
		updatedTemplateContent.requireswitness = updatedTemplateContentSection.requireswitness;
		updatedTemplateContent.usesmsauth = updatedTemplateContentSection.useSmsAuth;
		updatedTemplateContent.content = updatedTemplateContentSection.content;

		const updatedTemplateContents = template.esigntemplatecontents.map(contentDetail => {
			if (contentDetail.version === currentVersion) {
				return updatedTemplateContent;
			}
			return contentDetail;
		});

		const updatedTemplate = new EsigntemplateEntity(template);
		updatedTemplate.esigntemplatecontents = updatedTemplateContents;

		const updatedEditableTemplateContent: EditableTemplateContentSection = {
			requireWitness: updatedTemplateContent.requireswitness,
			useSmsAuth: updatedTemplateContent.usesmsauth,
			content: updatedTemplateContent.content,
		};
		updateTemplate(updatedTemplate, currentVersion);
		setEditableTemplateContent({
			...editableTemplateContent,
			[currentVersion]: updatedEditableTemplateContent,
		});
	}

	function handleLogoChange(logoId: string) {
		const updatedLogo = logos.find(logo => logo.id === logoId);
		if (!updatedLogo) {
			return;
		}

		// track the logo id changes
		// helps to enable and disable the save button
		setLogoDetails({
			initial: logoDetails.initial,
			current: updatedLogo.id,
		});

		if (logoDetails.initial === updatedLogo.id) {
			hasLogoChanged(false);
		} else {
			hasLogoChanged(true);
		}

		const updatedTemplate = new EsigntemplateEntity(template);
		updatedTemplate.logoId = updatedLogo.id;
		updatedTemplate.logo = updatedLogo;
		updateTemplate(updatedTemplate, currentVersion);
	}

	async function handleTemplateChange(field: string) {
		await template.validateField(field);
		updateTemplate(template, currentVersion);
	}

	function getDocumentContentDetail() {
		const content = editableTemplateContent[currentVersion]?.content;
		let templateContentDetail: DocumentContentDetail = {
			availableFieldIds: [],
			preFillFields: {},
			requiredFields: {},
			htmlString: '',
		};
		if (!content) {
			return templateContentDetail;
		}

		try {
			templateContentDetail = JSON.parse(content);
		} catch (error) {
			console.error('Invalid JSON:', error);
		}
		return templateContentDetail;
	}

	function renderLogoSection() {
		if (logos.length === 0) {
			return (
				<div className="template-logo-container empty">
					<p>No Logo available currently for this template</p>
				</div>
			);
		}

		return (
			<div className="template-logo-container">
				<h6>Choose Logo</h6>
				<div className="logo-list">
					{logos.map(logo => (
						<div
							key={logo.id}
							className={classNames('logo-item', {
								selected: template.logo?.id === logo.id,
							})}
							role="button"
							onClick={() => {
								handleLogoChange(logo.id);
							}}
							onKeyDown={event => {
								event.preventDefault();
								if (event.key === 'Enter' || event.key === ' ') {
									handleLogoChange(logo.id);
								}
							}}
							tabIndex={0}
						>
							<img
								src={`${SERVER_URL}/api/files/${logo.fileNameId}`}
								alt="Business Logo"
								width="75px"
								height="75px"
							/>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="template-fields">
			<section>
				<p className="template-section-title">CONFIGURATION DETAILS</p>
				<TextField
					model={template}
					modelProperty="name"
					label="Template Name"
					placeholder="Template Name"
					onAfterChange={() => handleTemplateChange('name')}
					isReadOnly={isReadOnly}
					errors={template.errors.name}
					isRequired
				/>
				<NumberTextField
					model={template}
					modelProperty="lifespanindays"
					label="Lifespan in Days"
					placeholder="Lifespan in Days"
					tooltip="Indicates the number of days the document should remain valid"
					onAfterChange={() => handleTemplateChange('lifespanindays')}
					isDisabled={isReadOnly}
					errors={template.errors.lifespanindays}
					isRequired
				/>
				<Combobox
					model={template}
					modelProperty="reminderfrequencyindays"
					label="Reminder Frequency in Days"
					placeholder="Reminder Frequency in Days"
					tooltip="Frequency of remainders received based on the lifespan of the document"
					options={Array.from({ length: template.lifespanindays }, (_, i) => i).map(item => ({
						display: item !== 0
							? `Every ${item} day(s)`
							: 'No reminders',
						value: item,
					}))}
					searchable={false}
					onAfterChange={() => handleTemplateChange('reminderfrequencyindays')}
					isDisabled={isReadOnly}
					errors={template.errors.reminderfrequencyindays}
					isRequired
				/>
				<NumberTextField
					model={template}
					modelProperty="minimumnumberofsignees"
					label="Number of Signatory needed"
					placeholder="Number of Signatory needed"
					tooltip="Indicates the number of signee required to sign the document"
					onAfterChange={() => handleTemplateChange('minimumnumberofsignees')}
					isReadOnly={isReadOnly}
					errors={template.errors.minimumnumberofsignees}
					isRequired
				/>
				<Checkbox
					model={editableTemplateContent[currentVersion]}
					modelProperty="requireWitness"
					label="Require Witness"
					onAfterChecked={(_, checked) => handleContentChange(checked)}
					isDisabled={isReadOnly || !enableEdit}
				/>
				<Checkbox
					model={editableTemplateContent[currentVersion]}
					modelProperty="useSmsAuth"
					label="Use SMS Authentication"
					tooltip="Enable to receive SMS authentication for the document"
					onAfterChecked={(_, checked) => handleContentChange(undefined, checked)}
					isDisabled={isReadOnly || !enableEdit}
				/>
				{renderLogoSection()}
			</section>
			<section>
				<p className="template-section-title">
					<span>TEMPLATE CONTENT</span>
					<If condition={!isReadOnly}>
						<Button
							className="add-version-button"
							colors={Colors.Primary}
							sizes={Sizes.Small}
							display={Display.Text}
							icon={{ icon: 'edit', iconPos: 'icon-only' }}
							onClick={() => addNewVersion()}
							disabled={isReadOnly || enableEdit}
						/>
					</If>
				</p>
				<TemplateContent
					numberOfSignatory={template.minimumnumberofsignees}
					requireWitness={editableTemplateContent[currentVersion].requireWitness}
					isReadOnly={isReadOnly || !enableEdit}
					templateContentDetail={getDocumentContentDetail()}
					updateTemplateContent={
						value => handleContentChange(undefined, undefined, value)
					}
				/>
			</section>
		</div>
	);
});

export default TemplateFields;
