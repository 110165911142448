import DOMPurify from 'dompurify';

export const getSanitizedHtml = (htmlString: string) => {
	const parser = new DOMParser();
	const htmlDoc = parser.parseFromString(htmlString, 'text/html');
	const sanitizedHtml = DOMPurify.sanitize(htmlDoc.documentElement.outerHTML);
	return sanitizedHtml;
};

export const getInputFieldType = (inputFieldId: string) => {
	switch (true) {
		case inputFieldId.includes('signature'):
			return 'signature';
		case inputFieldId.includes('date-signed'):
			return 'date-signed';
		case inputFieldId.includes('date'):
			return 'date';
		default:
			return 'text';
	}
};

export const getInputFieldForCurrentUser = (
	isSignee: boolean,
	currentUserUniqueElementId: string,
	inputFieldsWithValue: Record<string, Record<string, string>>,
) => {
	// retrieve the editable input field for the current user
	// this list all input field of both signee and witness
	const possibleEditableInputField = inputFieldsWithValue[currentUserUniqueElementId];

	if (!possibleEditableInputField) {
		return {} as Record<string, string>;
	}

	if (isSignee) {
		// if the current user is signee, return all the input field
		// remove possible input field which include word witness in the record key
		const currentSigneeEditableIds: Record<string, string> = {};
		Object.entries(possibleEditableInputField)
			.forEach(([key, value]) => {
				if (!key.includes('witness')) {
					currentSigneeEditableIds[key] = value;
				}
			});
		return currentSigneeEditableIds;
	}

	// if the current user is witness
	// return all the input field which include word witness in the record key
	const currentWitnessEditableIds: Record<string, string> = {};
	Object.entries(possibleEditableInputField).forEach(([key, value]) => {
		if (key.includes('-witness-')) {
			currentWitnessEditableIds[key] = value;
		}
	});
	return currentWitnessEditableIds;
};

export const checkIfAllFieldsAreFilled = (
	inputFieldWithValue: Record<string, string>,
	isSignee: boolean,
	currentUserUniqueElementId: string,
	inputFieldsWithValue: Record<string, Record<string, string>>,
) => {
	const editableInputFieldIdWithValue = getInputFieldForCurrentUser(
		isSignee,
		currentUserUniqueElementId,
		inputFieldsWithValue,
	);
	const editableElementIds = Object.keys(editableInputFieldIdWithValue);

	const possibleErrors: Record<string, boolean> = {};

	Object.entries(inputFieldWithValue).forEach(([key, value]) => {
		if (editableElementIds.includes(key)) {
			possibleErrors[key] = value.length === 0;
		}
	});

	const hasError = Object.values(possibleErrors).some(value => value);
	return !hasError;
};
