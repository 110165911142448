import moment from "moment"

export interface AlaresReport {
	cases: {
		[key: string]: Case,
	},
	casesList: Case[],
	adjudications: {
		[key: string]: Adjudication,
	},
	adjudicationsList: Adjudication[],
	insolvencies: {
		[key: string]: Insolvency,
	},
	insolvenciesList: Insolvency[],
	licences: {
		[key: string]: AlaresLicence,
	},
	licencesList: AlaresLicence[],
	'asic_documents': {
		[key: string]: AsicDocument,
	},
	'asic_documents_list': AsicDocument[],
	'created_at': string,
	'insolvency_risk_factor': number,
	'abn_status': string,
	'abn_gst_status': string,
	'abn_gst_registration_date': string,
	'abn_postcode': string,
	'abn_state': string,
	'asic_status': string,
	'asic_date_of_registration': string,
	'trustee_asic_status': string,
	'trustee_asic_date_of_registration': string,
}

export interface Case {
	index: number,
	uuid: string
	type: string
	'notification_time': string
	notificationTime: Date,
	'court_name': string
	'case_type': string
	'case_number': string
	jurisdiction: string
	suburb: string
	'next_hearing_date': string
	'case_name': string
	url: string
	'total_parties': number
	'total_documents': number
	'total_hearings': number
	parties: Party[]
	hearings: Hearing[]
	documents: Document[]
	'internal_reference': any
	name: string
	'other_names': string
	'insolvency_risk_factor': number
	'party_role': string
	'most_recent_event': string
	'match_on': string
}

export interface Party {
	name: string
	type: string
	role: string
	offence: string
	plea: string
	'representative_firm': string
	'representative_name': string
	'start_date': string,
	'end_date': string,
	address: string
	phone: string
	fax: string
	abn: string
	acn: string
	url: string
}

export interface Hearing {
	datetime: string
	reason: string
	officer: string
	'court_room': string
	'court_name': string
	'court_phone': string
	'court_address': string
	'court_suburb': string
	type: string
	'list_no': string
	outcome: string
	orders?: string
}

export interface Document {
	datetime: string
	title: string
	description: string
	'filed_by': string
}

export interface AsicDocument {
	date: string | null,
	convertedDate: Date | null,
	'form_code': string,
	description: string,
	identifier: string,
}

export interface AlaresLicence {
	uuid: string,
	state: string,
	authority: string,
	number: string,
	'former_number': string,
	name: string,
	'licence_holder': string,
	'trading_name': string,
	type: string,
	address: string,
	abn: string,
	acn: string,
	category: string,
	url: string,
	classes: Class[],
	parties: Party[],
	'licence_name': string,
	'licence_type': string,
	'notification_time': string,
	'other_names': string,
	'match_on': string,
	'insolvency_risk_factor': number,
}

export interface Class {
	'unique_id': string,
	class: string,
	grade: string,
	status: string,
	details: string,
}

export interface Insolvency {
	uuid: string,
	type: string,
	'notification_time': string,
	'court_name': string,
	'case_type': string,
	'case_number': number,
	'asic_notice_id': number,
	'case_name': number,
	'total_parties': number,
	parties: Party[],
	name: string,
	'other_names': string,
	'match_on': string,
	'insolvency_risk_factor': number,
}

export interface Adjudication {
	uuid: string,
	type: string,
	'notification_time': string,
	'court_name': string,
	'case_type': string,
	'case_number': string,
	'case_name': string,
	'total_parties': number,
	parties: Party[],
	name: string,
	'other_names': string,
	'party_role': string,
	'match_on': string,
	'insolvency_risk_factor': number,
}

/**
 * Util on a interface to provide class-like methods.
 * Developer note:
 * - This should really be a class that implements the interface and abstracts the business logic into methods.
 * - Doing a util instead as a simpler way to achieve this without re-writing to much.
 */
export const AlaresReportUtil = {
	/**
	 * Return list of cases ordered by the latest notification time
	 */
	getOrderedCasesList(report: AlaresReport) {
		return Object.values(report.cases)
			.map(x => {
				const copy = { ...x };
				copy.notificationTime = moment(copy.notification_time).toDate(); // Convert notification_time to Date
				return copy;
			})
			.sort((a, b) => b.notificationTime.getTime() - a.notificationTime.getTime()) // Sort by notificationTime descending
			.map((x, index) => {
				const copy = { ...x };
				copy.index = index + 1; // Add index to each case
				return copy;
			});
	},

	/**
	 * Return list of asic documents ordered by the latest notification time.
	 * Developer note: asic documents with 'null' dates will be showed first as
	 * these are most likely asic documents that have been appended with missing dates. 
	 * This is a known issue with missing data from the the webhook payload 
	 */
	getOrderedAsicDocuments(report: AlaresReport) {
		return Object.values(report.asic_documents)
			.map(x => {
				const copy = { ...x };
				copy.convertedDate = copy.date ? moment(copy.date).toDate() : null;
				return copy;
			})
			// Show null dates first followed by most recent.
			.sort((a, b) => {
				if (a.convertedDate === null && b.convertedDate === null) return 0;
				if (a.convertedDate === null) return -1;
				if (b.convertedDate === null) return 1;

				// If both dates are not null, sort by date descending
				return b.convertedDate.getTime() - a.convertedDate.getTime();
			});
	}
};