/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserMonitored from 'Models/Security/Acl/UserMonitored';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMonitoredEntityAttributes extends IModelAttributes {
	legalName: string;
	businessName: string;
	abn: string;
	abnEntityName: string;
	abnActive: boolean;
	abnActiveSince: Date;
	abnLastUpdated: Date;
	abnEntityTypeCode: string;
	abnLocality: string;
	abnEntityStatus: string;
	abnRegisteredForGSTFrom: string;
	acn: string;
	acnEntityName: string;
	acnEntityTypeCode: string;
	acnClass: string;
	acnLastUpdated: Date;
	acnSubClass: string;
	acnRegistrationDate: string;
	acnStatus: string;

	atoDebt?: Models.AtoDebtEntity |
		Models.IAtoDebtEntityAttributes;
	atoRecordss: Array<
		| Models.AtoRecordEntity
		| Models.IAtoRecordEntityAttributes
	>;
	creditReports: Array<
		| Models.CreditReportEntity
		| Models.ICreditReportEntityAttributes
	>;
	customerss: Array<
		| Models.MonitoredsCustomers
		| Models.IMonitoredsCustomersAttributes
	>;
	monitoredentityaudits: Array<
		| Models.MonitoredentityauditEntity
		| Models.IMonitoredentityauditEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MonitoredEntity', 'Monitored')
// % protected region % [Customise your entity metadata here] end
export default class MonitoredEntity extends Model
	implements IMonitoredEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserMonitored(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Legal Name'] off begin
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'Legal Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public legalName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Legal Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] off begin
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'Business Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public businessName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Business Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN'] off begin
	@Validators.Unique()
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Name'] on begin
	/**
	 * Entity Name
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN Entity Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abnEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Active'] on begin
	/**
	 * ABN is current indicator
	 */
	@observable
	@attribute<MonitoredEntity, boolean>()
	@CRUD({
		name: 'ABN Active',
		displayType: 'checkbox',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public abnActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Active Since'] off begin
	/**
	 * Active Since
	 */
	@observable
	@attribute<MonitoredEntity, Date>()
	@CRUD({
		name: 'ABN Active Since',
		displayType: 'datetimepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public abnActiveSince: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Active Since'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Last Updated'] off begin
	/**
	 * ABR Last Updated
	 */
	@observable
	@attribute<MonitoredEntity, Date>()
	@CRUD({
		name: 'ABN Last Updated',
		displayType: 'datetimepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public abnLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Type Code'] off begin
	/**
	 * Entity Type Code
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN Entity Type Code',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abnEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Locality'] off begin
	/**
	 * Locality
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN Locality',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abnLocality: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Locality'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Status'] off begin
	/**
	 * Entity Status
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN Entity Status',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abnEntityStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Entity Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'ABN Registered for GST From'] off begin
	/**
	 * Registered for GST From
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ABN Registered for GST From',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abnRegisteredForGSTFrom: string;
	// % protected region % [Modify props to the crud options here for attribute 'ABN Registered for GST From'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN'] on begin
	@Validators.Unique()
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN',
		displayType: 'textfield',
		order: 120,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acn: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] off begin
	/**
	 * The entity name sent back in the ACN response
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Entity Name',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityName: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] off begin
	/**
	 * The entity type code sent back in the ACN response
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Entity Type Code',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnEntityTypeCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Entity Type Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Class'] off begin
	/**
	 * ACN Class from external endpoint
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Class',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] off begin
	@observable
	@attribute<MonitoredEntity, Date>()
	@CRUD({
		name: 'ACN Last Updated',
		displayType: 'datetimepicker',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public acnLastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Sub Class'] off begin
	/**
	 * ACN Sub Class from external endpoint
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Sub Class',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnSubClass: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Sub Class'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Registration Date'] off begin
	/**
	 * ACN Registration Date from external endpoint
	 */
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Registration Date',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnRegistrationDate: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Registration Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] off begin
	@observable
	@attribute<MonitoredEntity, string>()
	@CRUD({
		name: 'ACN Status',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public acnStatus: string;
	// % protected region % [Modify props to the crud options here for attribute 'ACN Status'] end

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATO Debt'] off begin
		name: 'ATO Debt',
		displayType: 'displayfield',
		order: 200,
		inputProps: {
			displayFunction: (model?: Models.AtoDebtEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'ATO Debt'] end
	})
	public atoDebt?: Models.AtoDebtEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATO Records'] off begin
		name: 'ATO Recordss',
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.AtoRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atoRecordss',
			oppositeEntity: () => Models.AtoRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATO Records'] end
	})
	public atoRecordss: Models.AtoRecordEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Credit Report'] off begin
		name: 'Credit Reports',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.CreditReportEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'creditReports',
			oppositeEntity: () => Models.CreditReportEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Credit Report'] end
	})
	public creditReports: Models.CreditReportEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customers'] off begin
		name: 'Customers',
		displayType: 'reference-multicombobox',
		order: 230,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MonitoredsCustomers,
		optionEqualFunc: makeJoinEqualsFunc('customersId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'monitoredEntity',
			oppositeEntityName: 'customerEntity',
			relationName: 'monitoreds',
			relationOppositeName: 'customers',
			entity: () => Models.MonitoredEntity,
			joinEntity: () => Models.MonitoredsCustomers,
			oppositeEntity: () => Models.CustomerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customers'] end
	})
	public customerss: Models.MonitoredsCustomers[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MonitoredEntityAudit'] off begin
		name: 'MonitoredEntityAudits',
		displayType: 'reference-multicombobox',
		order: 240,
		referenceTypeFunc: () => Models.MonitoredentityauditEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'monitoredentityaudits',
			oppositeEntity: () => Models.MonitoredentityauditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MonitoredEntityAudit'] end
	})
	public monitoredentityaudits: Models.MonitoredentityauditEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMonitoredEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMonitoredEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.legalName !== undefined) {
				this.legalName = attributes.legalName;
			}
			if (attributes.businessName !== undefined) {
				this.businessName = attributes.businessName;
			}
			if (attributes.abn !== undefined) {
				this.abn = attributes.abn;
			}
			if (attributes.abnEntityName !== undefined) {
				this.abnEntityName = attributes.abnEntityName;
			}
			if (attributes.abnActive !== undefined) {
				this.abnActive = attributes.abnActive;
			}
			if (attributes.abnActiveSince !== undefined) {
				if (attributes.abnActiveSince === null) {
					this.abnActiveSince = attributes.abnActiveSince;
				} else {
					this.abnActiveSince = moment(attributes.abnActiveSince).toDate();
				}
			}
			if (attributes.abnLastUpdated !== undefined) {
				if (attributes.abnLastUpdated === null) {
					this.abnLastUpdated = attributes.abnLastUpdated;
				} else {
					this.abnLastUpdated = moment(attributes.abnLastUpdated).toDate();
				}
			}
			if (attributes.abnEntityTypeCode !== undefined) {
				this.abnEntityTypeCode = attributes.abnEntityTypeCode;
			}
			if (attributes.abnLocality !== undefined) {
				this.abnLocality = attributes.abnLocality;
			}
			if (attributes.abnEntityStatus !== undefined) {
				this.abnEntityStatus = attributes.abnEntityStatus;
			}
			if (attributes.abnRegisteredForGSTFrom !== undefined) {
				this.abnRegisteredForGSTFrom = attributes.abnRegisteredForGSTFrom;
			}
			if (attributes.acn !== undefined) {
				this.acn = attributes.acn;
			}
			if (attributes.acnEntityName !== undefined) {
				this.acnEntityName = attributes.acnEntityName;
			}
			if (attributes.acnEntityTypeCode !== undefined) {
				this.acnEntityTypeCode = attributes.acnEntityTypeCode;
			}
			if (attributes.acnClass !== undefined) {
				this.acnClass = attributes.acnClass;
			}
			if (attributes.acnLastUpdated !== undefined) {
				if (attributes.acnLastUpdated === null) {
					this.acnLastUpdated = attributes.acnLastUpdated;
				} else {
					this.acnLastUpdated = moment(attributes.acnLastUpdated).toDate();
				}
			}
			if (attributes.acnSubClass !== undefined) {
				this.acnSubClass = attributes.acnSubClass;
			}
			if (attributes.acnRegistrationDate !== undefined) {
				this.acnRegistrationDate = attributes.acnRegistrationDate;
			}
			if (attributes.acnStatus !== undefined) {
				this.acnStatus = attributes.acnStatus;
			}
			if (attributes.atoDebt !== undefined) {
				if (attributes.atoDebt === null) {
					this.atoDebt = attributes.atoDebt;
				} else if (attributes.atoDebt instanceof Models.AtoDebtEntity) {
					this.atoDebt = attributes.atoDebt;
				} else {
					this.atoDebt = new Models.AtoDebtEntity(attributes.atoDebt);
				}
			}
			if (attributes.atoRecordss !== undefined && Array.isArray(attributes.atoRecordss)) {
				for (const model of attributes.atoRecordss) {
					if (model instanceof Models.AtoRecordEntity) {
						this.atoRecordss.push(model);
					} else {
						this.atoRecordss.push(new Models.AtoRecordEntity(model));
					}
				}
			}
			if (attributes.creditReports !== undefined && Array.isArray(attributes.creditReports)) {
				for (const model of attributes.creditReports) {
					if (model instanceof Models.CreditReportEntity) {
						this.creditReports.push(model);
					} else {
						this.creditReports.push(new Models.CreditReportEntity(model));
					}
				}
			}
			if (attributes.customerss !== undefined && Array.isArray(attributes.customerss)) {
				for (const model of attributes.customerss) {
					if (model instanceof Models.MonitoredsCustomers) {
						this.customerss.push(model);
					} else {
						this.customerss.push(new Models.MonitoredsCustomers(model));
					}
				}
			}
			if (attributes.monitoredentityaudits !== undefined && Array.isArray(attributes.monitoredentityaudits)) {
				for (const model of attributes.monitoredentityaudits) {
					if (model instanceof Models.MonitoredentityauditEntity) {
						this.monitoredentityaudits.push(model);
					} else {
						this.monitoredentityaudits.push(new Models.MonitoredentityauditEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		customerss {
			${Models.MonitoredsCustomers.getAttributes().join('\n')}
			customers {
				${Models.CustomerEntity.getAttributes().join('\n')}
			}
		}
		atoDebt {
			${Models.AtoDebtEntity.getAttributes().join('\n')}
		}
		atoRecordss {
			${Models.AtoRecordEntity.getAttributes().join('\n')}
		}
		creditReports {
			${Models.CreditReportEntity.getAttributes().join('\n')}
		}
		monitoredentityaudits {
			${Models.MonitoredentityauditEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			customerss: {},
			atoRecordss: {},
			creditReports: {},
			monitoredentityaudits: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'customerss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MonitoredEntity.prototype, 'created');
CRUD(modifiedAttr)(MonitoredEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
