import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import alertToast from 'Util/ToastifyUtils';
import axios from 'axios';
import { SERVER_URL } from 'Constants';

export interface ToggleUserActivatedButtonProps {
	user: UserEntity, // Should be an observable
}

@observer
class ToggleUserActivatedButton extends React.Component<ToggleUserActivatedButtonProps> {
	public toggleIsActive = async () => {
		const { user } = this.props;
		if (!user) {
			return;
		}
		const makeActive = !user.isActive;

		// If you're deactivating the last organisation manager, we prevent this
		if (!makeActive && user.userType === 'ORGANISATION_MANAGER') {
			if (await user.isLastOrganisationManager()) {
				alertToast('You cannot deactivate the last organisation manager in an organisation', 'error');
				return;
			}
		}

		const deactivateSelf = !makeActive && user.id === store.userId;
		try {
			if (!makeActive) {
				await confirmModal(
					`Deactivate ${deactivateSelf ? 'your account?' : 'user?'}`,
					deactivateSelf
						? "If you deactivate your account, you will be logged out, and won't be able to log in again."
						: 'If you deactivate this user, they will be unable to login to the Access Intell platform.',
				);
			} else {
				await confirmModal(
					'Activate user?',
					'If you activate this user, they will be able to login to the Access Intell platform again.',
				);
			}
		} catch {
			return;
		}

		try {
			await axios.post(`${SERVER_URL}/api/entity/UserEntity/updateFields`, {
				id: user.id,
				isActive: makeActive,
			});
		} catch (e: any) {
			alertToast(`You do not have access to ${makeActive ? 'activate' : 'deactivate'} this user`, 'error');
			return;
		}

		if (deactivateSelf) {
			store.routerHistory.push('/logout');
		} else {
			alertToast(`User ${makeActive ? 'activated' : 'deactivated'}`, 'success');

			// Update the user, so we see the change
			runInAction(() => {
				if (!!user) {
					user.isActive = makeActive;
				}
			});
		}
	};

	render() {
		const { user } = this.props;
		return (
			<Button
				className="activate-deactivate-btn"
				display={Display.Outline}
				colors={Colors.Primary}
				onClick={() => this.toggleIsActive()}
			>
				{user.isActive ? 'Deactivate' : 'Activate'} {user.id === store.userId ? 'my account' : 'user'}
			</Button>
		);
	}
}

export default ToggleUserActivatedButton;
