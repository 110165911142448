import * as React from 'react';
import { CustomerEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { insolvencyRiskToTooltip, paymentRiskToTooltip } from 'Models/Enums';
import { Link } from 'react-router-dom';
import riskScoreBox from 'Views/Components/Intel/RiskScoreBox';

const CustomerOverviewRiskScore = (props: {
	customer: CustomerEntity,
}) => {
	const { customer } = props;
	const [showTooltip, setShowTooltip] = React.useState(false);
	return (
		<div className="overview-risk-score">
			<div className="header">
				<h6>Risk Score</h6>
				<span
					className="icon icon-right icon-information-2"
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
				/>
				<If condition={showTooltip}>
					<div className="risk-tooltip">
						<div>
							<div className="individual-risk">
								<b>Insolvency Risk</b>
								{riskScoreBox({
									score: customer.newestAlares?.insolvencyRiskRating,
								})}
							</div>
							<p>{insolvencyRiskToTooltip[customer.newestAlares?.insolvencyRiskRating ?? 'UNKNOWN']}
							</p>
						</div>
						<div>
							<div className="individual-risk">
								<b>Payment Risk</b>
								{riskScoreBox({
									score: customer.paymentRiskRating ?? 'UNKNOWN',
								})}
							</div>
							<p>
								{
									paymentRiskToTooltip[customer.paymentRiskRating ?? 'UNKNOWN']
								}
							</p>
						</div>
					</div>
				</If>
				{/* TODO: Remove the if condition when we have the credit report link */}
				<If condition={false}>
					<div className="credit-report-link">
						<Link to="/" className="icon icon-right icon-arrow-right-2" target="_blank">
							See Credit Report
						</Link>
					</div>
				</If>
			</div>
			<div className="scores">
				<div>
					<p className="field-name">Insolvency Risk</p>
					{riskScoreBox({
						score: customer.newestAlares?.insolvencyRiskRating,
					})}
				</div>
				<div>
					<p className="field-name">Payment Risk</p>
					{riskScoreBox({
						score: customer.paymentRiskRating ?? 'UNKNOWN',
					})}
				</div>
				<a
					href="/api/files/document/disclaimer"
					className="icon-right icon-material-north-east"
				>
					View Disclaimer
				</a>
			</div>
		</div>
	);
};

export default CustomerOverviewRiskScore;
