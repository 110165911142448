import * as React from 'react';
import { riskScore, riskScoreOptions } from 'Models/Enums';
import RiskScoreBox from 'Views/Components/Intel/RiskScoreBox';
import { Link } from 'react-router-dom';
import { AtbDashboardContext } from 'Views/Pages/Monitor/MonitorDashboardPage';
import { LocaleFormatCurrency } from 'Util/StringUtils';

const riskScores = Object.keys(riskScoreOptions) as riskScore[];

function DashboardAgedTrialBalanceRiskBuckets(props: {
	beIds: string[]
}) {
	const { beIds } = props;
	const value = React.useContext(AtbDashboardContext);
	const customerRiskBucket = value.uniqueCustomersInBuckets;

	function getCustomerListPageRiskBucketPath(risk: riskScore) {
		let url = `/monitor/customers?riskBucket=${risk}`;
		if (beIds.length > 0) {
			url += beIds.map(id => `&businessEntityIds=${id}`).join('');
		}
		return url;
	}
	return (
		<div className="dashboard-aged-trial-balance-risk-buckets">
			<div className="bucket rounded-box flex-row header">
				<div className="risk">Risk<br />Rating</div>
				<div className="count">Unique<br />Entities</div>
				<div className="amount">Amount<br />Outstanding</div>
			</div>
			{[...riskScores].reverse().map(risk => {
				const riskBucket = customerRiskBucket ? customerRiskBucket[risk] : undefined;
				const countText = !!riskBucket
					? riskBucket.count
					: '-';
				const amountText = !!riskBucket
					? LocaleFormatCurrency(riskBucket.amount)
					: '-';
				return (
					(
						<Link
							key={risk}
							className="bucket rounded-box flex-row"
							to={getCustomerListPageRiskBucketPath(risk)}
						>
							<div className="risk">
								<RiskScoreBox score={risk} />
							</div>
							<div className="count">{countText}</div>
							<div className="amount">{amountText}</div>
						</Link>
					)
				);
			})}
		</div>
	);
}

export default DashboardAgedTrialBalanceRiskBuckets;
