import React, { ReactElement, useEffect, useState } from 'react';
import './Error.scss';
import classNames from 'classnames';

type ErrorProps = {
	title: string;
	message?: string;
	error?: string;
	children?: React.ReactNode;
}

const ErrorSection: React.FunctionComponent<ErrorProps> = ({ title, message, error }) => {
	const titleClassNames = classNames('error-section-title', 'error-section-title-medium');
	return (
		<div className="error-section">
			<div className="error-section-inner">
				<div className={titleClassNames}>
					<div className="error-section-title-header">
						<div className="error-section-title-heading">
							{title}
							{message && <div className="error-section-title-description">{message}</div>}
						</div>
					</div>
				</div>
				{error && <div className="error-section-stack">{error}</div>}
			</div>
		</div>
	);
};

export default ErrorSection;

type ErrorBoundaryProps = {
	title? : string;
	children?: React.ReactNode;
}

export const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> = ({ title, children }) => {
	const [errorInfo, setErrorInfo] = useState<{ errorInstance: Error, info: React.ErrorInfo } | undefined>(undefined);

	useEffect(() => {
		const handleError = (event: ErrorEvent) => {
			const errorInstance = event.error;
			const info = { componentStack: '' }; // This is a placeholder. The global error handler doesn't provide React component stack info.
			setErrorInfo({ errorInstance, info });
		};

		window.addEventListener('error', handleError);

		return () => {
			window.removeEventListener('error', handleError);
		};
	}, []);

	if (errorInfo) {
		const titleText = title || 'An error occurred';
		const message = errorInfo.errorInstance.message
			|| 'An unexpected error occurred. Please refresh the page and try again.';
		const stack = errorInfo.info.componentStack.trim() || 'No error details included.';

		return (
			<ErrorSection title={titleText} message={message} error={stack} />
		);
	}

	return children as ReactElement;
};
