import * as React from 'react';
import { DisplayType } from '../Models/Enums';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';

interface InternalComboboxSetterProps<T> {
	/** The current value */
	value?: T;
	/** The setter to apply a new value */
	setValue?: ((newValue: T) => void);
	/**
	 * Gets an identifying property from the value object. This must be a unique value and not an object.
	 * By default this will get the value object itself
	 */
	getOptionValue?: (option: T | undefined) => undefined | boolean | number | string;
	/**
	 * A function to compare the model property to the selected option
	 * @param modelProperty The model property to compare
	 * @param option The option from the combobox
	 */
	optionEqualFunc?: (modelProperty: string | number | boolean | undefined, option: T | undefined) => boolean;
	/** The to display around the combobox */
	label: string;
	/** Whether the label is visible */
	labelVisible?: boolean;
	/** The tooltip to display */
	tooltip?: string;
	/** The display type to use */
	displayType?: DisplayType;
	/** The classname for to combobox */
	className?: string;
	/** Raw props that are passed through to the react-select component */
	inputProps?: DropdownProps;
	/** The placeholder text when the combobox is empty */
	placeholder?: string;
	/** A list of errors that are to be displayed around the combobox */
	errors?: string | string[];
	/** The minimum length of search string with can be searched, default to 1 */
	minSearchLength?: number;
	/** If the combobox is isDisabled */
	isDisabled?: boolean;
	/** If the field is required */
	isRequired?: boolean;
	/** Override of the onChange function. Using this will remove the model binding logic of the component */
	onChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
	/** Action to perform after the onChange method is called */
	onAfterSet?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
	/** Is the select value clearable */
	isClearable?: boolean;
}

export interface SyncComboboxSetterProps<T> extends InternalComboboxSetterProps<T> {
	/**
	 * The options on the dropdown
	 * An array of JSON objects that by default will have the form of {display: string, value: string}
	 *
	 * The key and value properties can be overriden with the getOptionLabel and getOptionValue props
	 */
	options: ComboboxOption<T | undefined>[];
	/** If the combobox is searchable */
	searchable?: boolean | ((options: DropdownItemProps[], value: string) => DropdownItemProps[]);
	/** If the combobox is in a loading state */
	loading?: boolean;
}

export interface AsyncComboboxSetterProps<T> extends InternalComboboxSetterProps<T> {
	/**
	 * The options on the dropdown
	 * A function that returns an promise resolving to an array of JSON objects that by default will have the form of
	 * {display: string, value: string}
	 *
	 * The key and value properties can be overriden with the getOptionLabel and getOptionValue props
	 */
	options: (input: string) => Promise<ComboboxOption<T | undefined>[]>;
	/**
	 * The initial options that are displayed in the combobox before any search occurs
	 */
	initialOptions?: () => Promise<ComboboxOption<T | undefined>[]>;
}

interface InternalSyncComboboxSetterProps<T> extends SyncComboboxSetterProps<T> {
	getOptionValue: (option: T | undefined) => undefined | boolean | number | string;
}

interface InternalAsyncComboboxSetterProps<T> extends AsyncComboboxSetterProps<T> {
	getOptionValue: (option: T | undefined) => undefined | boolean | number | string;
}

export type IComboboxSetterProps<T> = InternalSyncComboboxSetterProps<T> | InternalAsyncComboboxSetterProps<T>;

// Wrapper around Combobox which uses a setter instead of setting a value directly
// This makes it easier to use with values taken from useState
function ComboboxSetter<T>(props: IComboboxSetterProps<T>) {
	const { value, setValue, onAfterSet } = props;

	// Setup an object to store the value on, so that the combobox can read it
	const valueParent = {
		value: value,
	};

	return (
		<Combobox
			model={valueParent}
			modelProperty="value"
			onAfterChange={(event, data) => {
				if (!!setValue && !!valueParent.value) {
					setValue(valueParent.value);
				}

				if (!!onAfterSet) {
					onAfterSet(event, data);
				}
			}}
			{...props}
		/>
	);
}
export default ComboboxSetter;
