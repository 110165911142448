import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

export interface IReferralPartnerGroupProps {
	onClick?: (group: string, isSelected: boolean) => void;
	name: string; // Name of group
	colour: string; // Base colour of the component
	selected: boolean;
}

const ReferralPartnerGroupComponent = observer((props: IReferralPartnerGroupProps) => {
	const {
		onClick, name, colour, selected,
	} = props;

	if (onClick !== undefined) {
		return (
			<button
				className="referral-partner-group"
				style={{ backgroundColor: selected ? colour : 'grey' }}
				onClick={() => {
					onClick(name, selected);
				}}
			>
				{name}
				<span className={classNames('icon icon-right', selected ? 'icon-check' : 'icon-cross')} />
			</button>
		);
	}
	return (
		<div className="referral-partner-group" style={{ backgroundColor: colour }}>
			{name}
		</div>
	);
});

export default ReferralPartnerGroupComponent;
