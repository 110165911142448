import { useLocalStore } from 'mobx-react';
import React, { useState } from 'react';
import { CustomerEntity } from '../../../Models/Entities';
import { Button, Colors, Display } from '../Button/Button';
import If from '../If/If';
import PpsrNZFields from './PpsrNZFields';
import { RequestPropertySearchModel } from './PropertySearchRequest/RequestPropertySearchModal';
import { ReferToCollection } from "./ReferToCollection/ReferToCollection";
import ValidateCustomerFields from './ValidateCustomer';
import { LodgeDefaultModal } from './LodgeDefault/LodgeDefaultModal';


export const MiscSection = () => {
	const [showRequestPropertySearchModel, setShowRequestPropertySearchModel] = useState(false);
	const [showReferToCollectionModel, setShowReferToCollectionModel] = useState(false);
	const [showValidateCustomerFields, setShowValidateCustomerFields] = useState(false);
	const [showLodgeDefaultModal, setShowLodgeDefaultModal] = useState(false);
	const [showPpsrNZFields, setShowPpsrNZFields] = useState(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const link = e.currentTarget.querySelector('a');
		if (link) {
			link.click();
		}
	}

	return (
		<>
			<div className="action-content">
				<div className="hub-link" onClick={handleClick}>
					<div className="icon-left icon-person-check"/>
					<div className='validate-customer' 
						onClick={() => setShowValidateCustomerFields(true)}>
						Validate customer
					</div>
					<If condition={showValidateCustomerFields}>
						<ValidateCustomerFields
							customer={useLocalStore(() => new CustomerEntity())}
							onClose={() => setShowValidateCustomerFields(false)}
						/>
					</If>
				</div>
				<Button
					className="hub-link"
					display={Display.Text}
					colors={Colors.None}
					icon={{icon: "search", iconPos: "icon-left"}}
					onClick={() => setShowRequestPropertySearchModel(true)}
				>
					<span>Request property title search</span>
				</Button>
				<Button
					className="hub-link"
					display={Display.Text}
					colors={Colors.None}
					icon={{icon: "inbox", iconPos: "icon-left"}}
					onClick={() => setShowLodgeDefaultModal(true)}
				>
					<span>Lodge a default</span>
				</Button>
				<div className="hub-link" onClick={handleClick}>
					<div className="icon-left icon-roadmap extra-margin"/>
					<div className='extra-margin' onClick={() => setShowPpsrNZFields(true)}>NZ PPSR</div>
					<If condition={showPpsrNZFields}>
						<PpsrNZFields
							onClose={() => setShowPpsrNZFields(false)}
						/>
					</If>
				</div>
				<Button
					className="hub-link"
					display={Display.Text}
					colors={Colors.None}
					icon={{icon: "actions", iconPos: "icon-left"}}
					onClick={() => setShowReferToCollectionModel(true)}
				>
					<span>Refer to collection partners</span>
				</Button>
				<div className="hub-link"/>
			</div>
			{showRequestPropertySearchModel && (
				<RequestPropertySearchModel onClose={() => setShowRequestPropertySearchModel(false)}/>
			)}
			{showReferToCollectionModel && (
				<ReferToCollection onClose={() => setShowReferToCollectionModel(false)}/>
			)}
			{showLodgeDefaultModal && (
				<LodgeDefaultModal onClose={() => setShowLodgeDefaultModal(false)}/>
			)}
		</>
	)
}