/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserAlaresEntity from 'Models/Security/Acl/UserAlaresEntity';
import VisitorsAlaresEntity from 'Models/Security/Acl/VisitorsAlaresEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import type { AlaresReport } from 'Models/Interfaces/AlaresReport';
// % protected region % [Add any further imports here] end

export interface IAlaresEntityAttributes extends IModelAttributes {
	insolvencyRiskRatingValue: number;
	lastUpdated: Date;
	adverseAlerts: number;
	insolvencyRiskRating: Enums.riskScore;
	businessNumber: string;
	processed: boolean;
	jsonreport: string;
	reportID: number;

	customerId: string;
	customer: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AlaresEntity', 'Alares')
// % protected region % [Customise your entity metadata here] end
export default class AlaresEntity extends Model
	implements IAlaresEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserAlaresEntity(),
		new VisitorsAlaresEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Insolvency Risk Rating Value'] off begin
	/**
	 * The raw decimal value of the insolvency risk rating
	 */
	@Validators.Numeric()
	@observable
	@attribute<AlaresEntity, number>()
	@CRUD({
		name: 'Insolvency Risk Rating Value',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public insolvencyRiskRatingValue: number;
	// % protected region % [Modify props to the crud options here for attribute 'Insolvency Risk Rating Value'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Updated'] off begin
	@observable
	@attribute<AlaresEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Last Updated',
		displayType: 'datepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public lastUpdated: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Updated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adverse Alerts'] off begin
	@Validators.Integer()
	@observable
	@attribute<AlaresEntity, number>()
	@CRUD({
		name: 'Adverse Alerts',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public adverseAlerts: number;
	// % protected region % [Modify props to the crud options here for attribute 'Adverse Alerts'] end

	// % protected region % [Modify props to the crud options here for attribute 'Insolvency Risk Rating'] off begin
	@observable
	@attribute<AlaresEntity, Enums.riskScore>()
	@CRUD({
		name: 'Insolvency Risk Rating',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.riskScoreOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.riskScoreOptions),
		displayFunction: (attr: Enums.riskScore) => Enums.riskScoreOptions[attr],
	})
	public insolvencyRiskRating: Enums.riskScore;
	// % protected region % [Modify props to the crud options here for attribute 'Insolvency Risk Rating'] end

	// % protected region % [Modify props to the crud options here for attribute 'Business Number'] off begin
	@observable
	@attribute<AlaresEntity, string>()
	@CRUD({
		name: 'Business Number',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public businessNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Business Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Processed'] off begin
	/**
	 * Whether or not the status has been processed
	 */
	@observable
	@attribute<AlaresEntity, boolean>()
	@CRUD({
		name: 'Processed',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public processed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Processed'] end

	// % protected region % [Modify props to the crud options here for attribute 'JsonReport'] off begin
	/**
	 * Alares report in json
	 */
	@observable
	@attribute<AlaresEntity, string>()
	@CRUD({
		name: 'JsonReport',
		displayType: 'textarea',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public jsonreport: string;
	// % protected region % [Modify props to the crud options here for attribute 'JsonReport'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report ID'] off begin
	@Validators.Integer()
	@Validators.Unique()
	@observable
	@attribute<AlaresEntity, number>()
	@CRUD({
		name: 'Report ID',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reportID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Report ID'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer'] off begin
		name: 'Customer',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.CustomerEntity,
		// % protected region % [Modify props to the crud options here for reference 'Customer'] end
	})
	public customerId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customer: Models.CustomerEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public report: AlaresReport;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAlaresEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAlaresEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.insolvencyRiskRatingValue !== undefined) {
				this.insolvencyRiskRatingValue = attributes.insolvencyRiskRatingValue;
			}
			if (attributes.lastUpdated !== undefined) {
				if (attributes.lastUpdated === null) {
					this.lastUpdated = attributes.lastUpdated;
				} else {
					this.lastUpdated = moment(attributes.lastUpdated).toDate();
				}
			}
			if (attributes.adverseAlerts !== undefined) {
				this.adverseAlerts = attributes.adverseAlerts;
			}
			if (attributes.insolvencyRiskRating !== undefined) {
				this.insolvencyRiskRating = attributes.insolvencyRiskRating;
			}
			if (attributes.businessNumber !== undefined) {
				this.businessNumber = attributes.businessNumber;
			}
			if (attributes.processed !== undefined) {
				this.processed = attributes.processed;
			}
			if (attributes.jsonreport !== undefined) {
				this.jsonreport = attributes.jsonreport;
			}
			if (attributes.reportID !== undefined) {
				this.reportID = attributes.reportID;
			}
			if (attributes.customerId !== undefined) {
				this.customerId = attributes.customerId;
			}
			if (attributes.customer !== undefined) {
				if (attributes.customer === null) {
					this.customer = attributes.customer;
				} else if (attributes.customer instanceof Models.CustomerEntity) {
					this.customer = attributes.customer;
					this.customerId = attributes.customer.id;
				} else {
					this.customer = new Models.CustomerEntity(attributes.customer);
					this.customerId = this.customer.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.jsonreport !== undefined) {
				try {
					this.report = JSON.parse(attributes.jsonreport) as AlaresReport;
				} catch (e) {
					// most likely because non json information was saved
					console.error(e);
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		customer {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AlaresEntity.prototype, 'created');
CRUD(modifiedAttr)(AlaresEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
