import * as React from 'react';
import { BusinessEntity, SpgEntity } from '../../../Models/Entities';
import { observer } from 'mobx-react';
import { TextField } from '../TextBox/TextBox';
import { store } from '../../../Models/Store';
import OrganisationalNumberBox from './OrganisationalNumberBox';

export interface SpgFieldsProps {
    spgEntity: SpgEntity,
    onAfterChange?: (validateSuccessful: boolean) => void,
	readonly?: boolean,
	create?: boolean,
}

const SpgFields = observer((props: SpgFieldsProps) => {
	const {
		spgEntity, onAfterChange, readonly, create,
	} = props;

	/**
	 * Handle for the change event for the inputs. This method will attempt to validate a given field
	 * iff there are existing errors. So that the user can immediately see when their input stops the error
	 * @param field The name of the field to be validated
	 * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
	 * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
	 */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!spgEntity.errors[field]) {
			await spgEntity.validateField(field);
		}
	};

	// WHEN ADDING INPUTS TO THIS COMPONENT, ENSURE ANY DEPENDENCY ARRAYS ARE UPDATED THAT ARE USING THIS COMPONENT,
	// E.G. SpgListItem.tsx -> useHasChanged
	return (
		<div className="spg-fields">
			<section>
				<p className="spg-section-title">SECURED PARTY DETAILS</p>
				<TextField
					model={spgEntity}
					modelProperty="spgName"
					className="spg-name"
					label="SPG name"
					placeholder="SPG name"
					isDisabled={(store.userType !== 'SUPER_USER' && !create) || readonly}
					onChangeAndBlur={async () => { await onChange('spgName', true); }}
					onAfterChange={async () => { await onChange('spgName'); }}
					errors={spgEntity.errors.spgName}
				/>
				<TextField
					model={spgEntity}
					modelProperty="spgNumber"
					className="spg-number"
					label="SPG number"
					placeholder="SPG number"
					isDisabled={(store.userType !== 'SUPER_USER' && !create) || readonly}
					onChangeAndBlur={async () => { await onChange('spgNumber', true); }}
					onAfterChange={async () => { await onChange('spgNumber'); }}
					errors={spgEntity.errors.spgNumber}
				/>

				<TextField
					model={spgEntity}
					modelProperty="accessCode"
					className="access-code"
					label="SPG access number"
					placeholder="SPG access number"
					isDisabled={(store.userType !== 'SUPER_USER' && !create) || readonly}
					onChangeAndBlur={async () => { await onChange('accessCode', true); }}
					onAfterChange={async () => { await onChange('accessCode'); }}
					errors={spgEntity.errors.accessCode}
				/>

				<OrganisationalNumberBox
					model={spgEntity}
					modelProperty="organisationalNumbers"
					readonly={readonly}
				/>
			</section>

			<section>
				<p className="spg-section-title">CONTACT DETAILS</p>
				<TextField
					model={spgEntity}
					modelProperty="contactName"
					className="contact-name"
					label="Contact"
					placeholder="Contact name"
					isDisabled={readonly}
				/>

				<TextField
					model={spgEntity}
					modelProperty="email"
					className="email"
					label="Email address"
					placeholder="Email address"
					isDisabled={readonly}
					onChangeAndBlur={async () => { await onChange('email', true); }}
					onAfterChange={async () => { await onChange('email'); }}
					errors={spgEntity.errors.email}
				/>

				<TextField
					model={spgEntity}
					modelProperty="phone"
					className="phone"
					label="Phone number"
					placeholder="Phone number"
					isDisabled={readonly}
					onChangeAndBlur={async () => { await onChange('phone', true); }}
					onAfterChange={async () => { await onChange('phone'); }}
					errors={spgEntity.errors.phone}
				/>
			</section>
		</div>
	);
});

export default SpgFields;
