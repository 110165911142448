import React, { useMemo, useState } from 'react';
import {
	Button, Colors, Display, Sizes,
} from '../../Button/Button';
import { BusinessEntity, EsigntemplateEntity, EsigntemplatecontentEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { observer, useLocalStore } from 'mobx-react';
import LogoUpload from './Gibs/LogoUpload';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import BusinessEntityTemplate from './BusinessEntityTemplate';
import ESignRequestTemplateModal from './ESignRequestTemplateModal';

interface Props {
	businessEntity: BusinessEntity;
	reload: () => void;
}

const BusinessEntityTemplateList: React.FunctionComponent<Props> = observer(({ businessEntity, reload }) => {
	const initialTemplates = useMemo(() => {
		if (businessEntity.esigntemplatess) {
			return businessEntity.esigntemplatess
				.slice()
				.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
		}

		return [];
	}, [businessEntity.esigntemplatess]);

	const [eSignTemplates, setESignTemplates] = useState<EsigntemplateEntity[]>(initialTemplates);
	const [showModal, setShowModal] = useState(false);
	const newRequestEsignTemplateEntity = useLocalStore(() => new EsigntemplateEntity());
	const [showRequestVersionModal, setShowRequestVersionModal] = useState(false);
	const [enableRefresh, setEnableRefresh] = useState(false);

	const isReadOnly = store.userType !== 'SUPER_USER';

	function handleAddNewTemplate() {
		const newTemplate = new EsigntemplateEntity();
		newTemplate.businessEntity = businessEntity;
		newTemplate.businessEntityId = businessEntity.id;
		newTemplate.esigntemplatecontents = [new EsigntemplatecontentEntity({
			version: 1,
		})];
		newTemplate.name = 'New Document Template';
		setESignTemplates([newTemplate, ...eSignTemplates]);
		setEnableRefresh(true);
	}

	function handleESignTemplateChange(template: EsigntemplateEntity) {
		const updatedTemplates = eSignTemplates.map(t => {
			if (t.id === template.id) {
				return template;
			}

			return t;
		});
		setESignTemplates(updatedTemplates);
	}

	return (
		<>
			<div className="business-entity-template-details">
				<div className="title-container">
					<h3>Template details</h3>
					<ButtonGroup>
						{isReadOnly && (
							<>
								<Button
									className="add-template"
									colors={Colors.Primary}
									sizes={Sizes.Medium}
									display={Display.Outline}
									icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
									onClick={() => setShowRequestVersionModal(true)}
									disabled={!(store.userType === 'ADMIN' || store.userType === 'ORGANISATION_MANAGER')}
								>
									Request new template
								</Button>
								{showRequestVersionModal && (
									<ESignRequestTemplateModal
										businessEntityName={businessEntity.name}
										esigntemplateEntity={newRequestEsignTemplateEntity}
										onClose={() => setShowRequestVersionModal(false)}
										isNewTemplate
									/>
								)}
							</>
						)}
						{!isReadOnly && (
							<>
								<Button
									className="add-template"
									colors={Colors.Primary}
									sizes={Sizes.Medium}
									display={Display.Outline}
									icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
									onClick={() => handleAddNewTemplate()}
									disabled={isReadOnly}
								>
									Add new template
								</Button>
								<Button
									className="add-logo"
									colors={Colors.Primary}
									sizes={Sizes.Medium}
									display={Display.Outline}
									icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
									onClick={() => setShowModal(true)}
									disabled={isReadOnly}
								>
									Add new Logo
								</Button>

								{enableRefresh && (
									<Button
										className="reload"
										colors={Colors.Warning}
										sizes={Sizes.Medium}
										display={Display.Text}
										icon={{ icon: 'refresh-ccw', iconPos: 'icon-left' }}
										onClick={() => {
											setEnableRefresh(false);
											reload();
										}}
									>
										Refresh
									</Button>
								)}
							</>
						)}
					</ButtonGroup>
				</div>
				<div className="template-list-wrap">
					{eSignTemplates.map((template, index) => (
						<BusinessEntityTemplate
							key={template.id ?? `${index}`}
							businessName={businessEntity.name}
							template={template}
							updateTemplate={(value: EsigntemplateEntity) => handleESignTemplateChange(value)}
							enableRefresh={() => setEnableRefresh(true)}
							logos={businessEntity.logoss}
							isReadOnly={isReadOnly}
						/>
					))}
				</div>
			</div>
			{showModal && (
				<LogoUpload
					businessEntityId={businessEntity.id}
					isReadOnly={isReadOnly}
					enableRefresh={() => setEnableRefresh(true)}
					onClose={() => setShowModal(false)}
				/>
			)}
		</>
	);
});

export default BusinessEntityTemplateList;
