import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ComboboxSetter from '../Combobox/ComboboxSetter';
import { Tooltip } from '../Tooltip/Tooltip';
import { ComboboxOption } from '../Combobox/Combobox';
import React, { useEffect, useState } from 'react';

export type EntitySwitcherProps = {
	value: string | undefined;
	setValue: (value: string | undefined) => void;
	getOptions: (searchTerm?: string) => Promise<ComboboxOption<string>[]>;
	handleAfterSet?: () => void;
	tooltipContent?: string;
	// The type of the object in the options to be used for ui text.
	objectName?: string;
}

/**
 * Component for selecting difference options from an entity list.
 * - Note - difference between ComboBoxSelector and MultiComboBoxSelector
 *
 * Tech debt: other areas to de-duplicate in the future
 * - CustomerPage.tsx - active status selector
 * - AgedTrialBalanceSteps.tsx - business entity selector
 * - BusinessEntitySelector.tsx - business entity selector > but multiple selections
 */
export default function EntitySwitcher(props: EntitySwitcherProps) {
	const {
		getOptions, setValue, value, tooltipContent, objectName = 'object', handleAfterSet,
	} = props;

	const [optionsLength, setOptionsLength] = useState<number>(0);

	useEffect(() => {
		const fetchOptionsLength = async () => {
			const options = await getOptions();
			setOptionsLength(options.length);
		};
		fetchOptionsLength();
	}, [getOptions, value]);

	const placeholder = optionsLength > 1 ? `Select a ${objectName}` : `No ${objectName} to select`;
	const disabled = optionsLength <= 1;

	function isOptionalEqual(id1: string | number | boolean | undefined, id2?: string) {
		return id1 === id2;
	}

	function onHandleAfterSet() {
		if (handleAfterSet) handleAfterSet();
	}
	return (

		<div className="entity-context-switcher">
			{tooltipContent && (
				<Tooltip
					id="entity-switcher-tooltip"
					content={tooltipContent}
				/>
			)}

			<ComboboxSetter
				isDisabled={disabled}
				placeholder={placeholder}
				value={value}
				setValue={setValue}
				className="entity-switcher-dropdown"
				searchable
				getOptionValue={(id?: string) => id}
				optionEqualFunc={(id1, id2) => isOptionalEqual(id1, id2)}
				onAfterSet={() => onHandleAfterSet()}
				label=""
				labelVisible={false}
				initialOptions={getOptions}
				options={AwesomeDebouncePromise(getOptions, 250)}
			/>
		</div>
	);
}
