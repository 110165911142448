import { Button, Colors, Display } from '../../Button/Button';
import React, { useState } from 'react';

import AtoUploadModal from './Gibs/AtoUploadModal';
import { Tooltip } from '../../Tooltip/Tooltip';

const MonitorAtoUpload: React.FC = () => {
	const [showAtoUploadModal, setShowAtoUploadModal] = useState(false);

	return (
		<>
			<div>
				<h5>Disclosure of Business Tax Debt</h5>
				<div>
					<div className="label-wrapper">
						<p>Upload ATO debt</p>
						<Tooltip
							id="upload-ato-debt-tooltip"
							content="Upload a Disclosure of Business Tax Debt(DOBTD) file to update customers' ATO debt records."
							place="right"
						/>
					</div>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						className="upload-ato-debt-button"
						onClick={() => setShowAtoUploadModal(true)}
					>
						Upload CSV
					</Button>
				</div>
			</div>
			{showAtoUploadModal && (<AtoUploadModal onClose={() => setShowAtoUploadModal(false)} />)}
		</>
	);
};

export default MonitorAtoUpload;
