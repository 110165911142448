import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useESignStore } from 'ESign/Stores/ESignStore';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import OtpInput from 'Views/Components/OtpInput/OtpInput';
import { useParams } from 'react-router';
import usePersistState from 'Hooks/usePersistState';
import useInterval from 'Hooks/useInterval';
import { isPhone } from 'Validators/Functions/Custom';
import { isEmail } from 'Validators/Functions/Email';
import {
	Container,
	Grid, GridColumn, GridRow,
} from 'semantic-ui-react';
import If from 'Views/Components/If/If';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';

const OTP_INPUT_LENGTH = 6;

const ESignAuthorisePage: React.FunctionComponent = observer(() => {
	const { userId, workflowId } = useParams<{userId: string, workflowId: string}>();

	const {
		authoriseStore: {
			optValue,
			setOtpValue,
			loading,
			sendAccessCode,
			isResendDisabled,
			setIsResendDisabled,
		},
		commonStore: {
			hasCode,
			userDetail,
			initiateESign,
			setHasCode,
		},
	} = useESignStore();

	const [showContactUs, setShowContactUs] = useState(false);
	const [countdown, setCountdown] = usePersistState(59, 'countdown', (3 * 60 * 1000)); // 3 mins (5mins - 2 mins) cause we are setting the count down to 0 after 5 seconds.
	const hasPhoneNumber = userDetail.useSmsAuth && userDetail.phone && userDetail.phone.trim().length > 0;

	const updateCountdown = () => {
		if (hasCode) {
			if (countdown > 1) {
				setCountdown(countdown - 1);
			} else {
				setCountdown(0);
				setIsResendDisabled(false);
			}
		}
	};

	const resetCountdown = () => {
		setCountdown(59);
		setIsResendDisabled(true);
	};

	const handleOtpChange = (value: string) => {
		setOtpValue(value, workflowId);
	};

	function renderContactUsInfo() {
		const hasValidPhone = userDetail.contactPhone !== null && isPhone(userDetail.contactPhone);
		const hasEmail = isEmail(userDetail.contactEmail);

		let contactInfo = `Please contact ${userDetail.contactName}`;
		if (hasEmail) {
			contactInfo += ` - Email ${userDetail.contactEmail}`;
		}

		if (hasValidPhone) {
			contactInfo += ` - Phone ${userDetail.contactPhone}`;
		}

		return (
			<Container textAlign="center">
				<span>
					Please contact {userDetail.contactName}
					<br />
					{hasEmail && (<span>Email - {userDetail.contactEmail}</span>)}
					<br />
					{hasPhoneNumber && (<span>Phone - {userDetail.contactPhone}</span>)}
				</span>
			</Container>
		);
	}

	useInterval(updateCountdown, 1000, [countdown, updateCountdown]);

	useEffect(() => {
		if (!hasCode) {
			initiateESign(userId, workflowId);
		}

		// Reset the hasCode flag after 5 minutes
		const interval = setInterval(() => {
			if (hasCode) {
				setHasCode(false);
			}
		}, (5 * 60 * 1000)); // 5 mins

		return () => clearInterval(interval);
	}, [hasCode, initiateESign, setHasCode, userId, workflowId]);

	return (
		<div className="body-content esign-authorise-page">
			<div className="invisible-page-wrap centre centre-vertically limit-width">
				<div className="white-box">
					<Grid className="esign-identification-container" stackable textAlign="center">
						<GridRow>
							<GridColumn width={16}>
								<div
									className="access-group-logo"
									role="img"
									aria-label="Access Intell - Customer Intelligence & Insight"
								/>
								<h5> Identity Verification</h5>
							</GridColumn>
						</GridRow>
						<If condition={!loading}>
							{!hasCode ? (
								<>
									<GridRow>
										<GridColumn width={16}>
											<Container textAlign="center">
												<p>
													According to our records, your
													{hasPhoneNumber
														? ' mobile number ' : ' email address '}
													is registered as
													<strong>
														{hasPhoneNumber
															? ` ${userDetail.phone}` : ` ${userDetail.email}`}
													</strong>
												</p>
											</Container>
										</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn width={16}>
											<Button
												display={Display.Solid}
												onClick={() => {
													sendAccessCode(userDetail.id, workflowId);
												}}
											>
												Send me the Code
											</Button>
										</GridColumn>
									</GridRow>
								</>
							) : (
								<>
									<GridRow>
										<GridColumn width={16}>
											<Container>
												<p>
													We&rsquo;ve sent a 6-digit code to your registered

													{hasPhoneNumber
														? <span> phone number <strong>{userDetail.phone}</strong></span>
														// eslint-disable-next-line max-len
														: <span> email address <strong>{userDetail.email}</strong></span>}
													, please enter it below.
												</p>
												<p>Didn&rsquo;t receive the code?
													<Button
														display={Display.Text}
														colors={Colors.Primary}
														onClick={() => {
															sendAccessCode(userDetail.id, workflowId);
															resetCountdown();
														}}
														disabled={isResendDisabled}
													>
														Resend Code
													</Button>
												</p>
											</Container>
										</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn width={16} textAlign="center">
											<OtpInput
												value={optValue}
												valueLength={OTP_INPUT_LENGTH}
												onChange={handleOtpChange}
											/>
										</GridColumn>
									</GridRow>

									{isResendDisabled
										? (
											<div>
												Resend code in 00:{countdown.toString().length === 1
													? `0${countdown}` : countdown}
											</div>
										)
										: null}
								</>
							)}
						</If>
						<If condition={loading}>
							<InlineSpinner />
						</If>
						<GridRow>
							<GridColumn width={16}>
								<Container textAlign="center">
									<span>Having difficulties logging in?</span>
									<Button
										colors={Colors.Primary}
										display={Display.Text}
										onClick={() => setShowContactUs(true)}
									>
										Contact us
									</Button>
								</Container>
								{showContactUs && (renderContactUsInfo())}
							</GridColumn>
						</GridRow>
					</Grid>
				</div>
			</div>
		</div>
	);
});

export default ESignAuthorisePage;
