import * as React from 'react';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { store } from 'Models/Store';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { IndustryCodesEntity } from 'Models/Entities';
import { IIndustryCodesEntityAttributes } from 'Models/Entities/IndustryCodesEntity';
import { useLocalStore } from 'mobx-react';
import useAsync from 'Hooks/useAsync';
import { FunctionComponent, useEffect, useRef } from 'react';
import If from 'Views/Components/If/If';
import RegistrationData from 'Models/Entities/RegistrationData';
import { DropdownProps } from 'semantic-ui-react';

export interface IndustryCodeSelectorProps<T> {
	model: T;
	modelProperty: string;
	className?: string;
	errors?: string | string[];
	isDisabled?: boolean,
	isRequired?: boolean,
	onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}

const IndustryCodeSelector = <T, >(props: IndustryCodeSelectorProps<T>) => {
	const {
		model, modelProperty, isDisabled, isRequired, onChange, className, errors,
	} = props;

	const reference = useRef<Combobox<any, any>>(null);

	// This is a promise to keep the combobox types happy
	const getOptions = async (searchTerm?: string): Promise<ComboboxOption<IndustryCodesEntity>[]> => {
		const industryCodes = await IndustryCodesEntity.industryCodes();

		return industryCodes
			.filter(x => !searchTerm || `${x.code} - ${x.description}`.toLowerCase().includes(searchTerm.toLowerCase()))
			.map(x => {
				return { display: `${!!x.code ? `${x.code} - ` : ''}${x.description}`, value: x };
			});
	};

	return (
		<Combobox
			className={classNames('industry-code-selector', className)}
			model={model}
			modelProperty={modelProperty}
			label="What industry are you in?"
			placeholder="Select industry"
			isDisabled={isDisabled}
			isRequired={isRequired}
			initialOptions={getOptions}
			options={getOptions}
			searchable
			onAfterChange={onChange}
			errors={errors}
			getOptionValue={(entity?: IndustryCodesEntity) => entity?.id}
			optionEqualFunc={
				(id: string|number|boolean|undefined, entity?: IndustryCodesEntity) => entity?.id === id
			}
			ref={reference}
		/>
	);
};

export default IndustryCodeSelector;
