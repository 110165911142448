import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import classNames from 'classnames';
import If from '../If/If';
import { FocusEventHandler } from 'react';

interface OrganisationalNumberComboboxProps<T> {
    model: T;
	modelProperty: string,
	readonly?: boolean,
	editOnBackspace?: boolean,
	keyPressSeparators?: string[],
	customStringSeparator?: string,
}

function OrganisationalNumberBox<T>(props: OrganisationalNumberComboboxProps<T>) {
	const {
		model, modelProperty, readonly, editOnBackspace, keyPressSeparators, customStringSeparator,
	} = props;
	const separator = customStringSeparator ?? ',';
	// create a set from data to ensure we don't have duplicate information.
	const [numbers, setNumbers] = React.useState([...new Set((!model[modelProperty] ? [] : model[modelProperty]
		.split(separator)))] as string[]);

	const defaultSeparators = ['Enter', ','];

	// function to handle removing a number from the list
	const onNumberRemove = (e: (React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>), text: string) => {
		e.stopPropagation();
		// remove all numbers from the list that aren't the input in focus
		setNumbers(numbers.filter(number => number !== text));
		// set it externally
		runInAction(() => {
			model[modelProperty] = [...numbers.filter(number => number !== text)].join(separator);
		});
	};

	// handle keypress
	const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		e.stopPropagation();
		// grab the current input text in focus (not just a char, entire input)
		const input = (e.target as HTMLInputElement).value;

		// if backspace was pressed and we have at least one number and there's no text
		// in focus, remove the previous number from the list
		if (e.key === 'Backspace' && numbers.length && !input) {
			// set the value of the input in focus to be the previous number if we are editing on backspace
			(e.target as HTMLInputElement).value = editOnBackspace ? `${numbers[numbers.length - 1]} ` : '';
			// update the numbers list
			setNumbers([...numbers.slice(0, -1)]);
			runInAction(() => {
				model[modelProperty] = [...numbers.slice(0, -1)].join(',');
			});
		}

		// if there is a number in the input value and a separator key is pressed, we add the number to the numbers list
		if (input && (keyPressSeparators || defaultSeparators).includes(e.key)) {
			// if the number is already in the list we don't allow adding it
			if (numbers.includes(input)) {
				return;
			}
			// update the numbers list
			setNumbers([...numbers, input]);
			// clear the input value
			(e.target as HTMLInputElement).value = '';
			e.preventDefault();
			runInAction(() => {
				model[modelProperty] = [...numbers, input].join(',');
			});
		}
	};

	// handle blur
	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
		e.stopPropagation();
		// grab the current input text in focus (not just a char, entire input)
		const input = (e.target as HTMLInputElement).value;

		// if the number is already in the list we don't allow adding it
		if (numbers.includes(input) || !input) {
			return;
		}
		// update the numbers list
		setNumbers([...numbers, input]);
		// clear the input value
		(e.target as HTMLInputElement).value = '';
		e.preventDefault();
		runInAction(() => {
			model[modelProperty] = [...numbers, input].join(',');
		});
	};

	return (
		<div className="organisational-numbers">
			<label htmlFor="input">Organisational numbers</label>
			<div className={classNames('input-container', { disabled: readonly })}>
				<div className="tags-container">
					{numbers.map(number => (
						<span className="tag-container" key={`tag-${number}`}>
							<span className="tag-title">{number}</span>
							{!readonly && (
								<i
									className="remove-tag icon icon-only icon-clear "
									aria-label={`remove ${number}`}
									{...HandleEvents(event => onNumberRemove(event, number))}
								/>
							)}
						</span>
					))}
				</div>
				<input
					type="text"
					name="organisational-numbers"
					onKeyDown={handleOnKeyUp}
					disabled={readonly}
					onBlur={handleOnBlur}
				/>
			</div>
			<If condition={!readonly}>
				<em>press enter or comma to add new organisational number</em>
			</If>
		</div>
	);
}
export default observer(OrganisationalNumberBox);
