import { cloneDeep, isEqual } from 'lodash';
import { UserEntity } from 'Models/Entities';
import {
	permissionScopeCombobox, reducedUserTypeComboboxOptions, UserTypeOrder,
} from 'Models/Enums';
import { IUserResult, store } from 'Models/Store';
import {
	calculateOtherUserTypeVisiblePermissions, calculateUserPermissions,
	defaultPermissionsForUserType, UserPermissions, userPermissionsToDisplay,
} from 'Models/UserPermissions';
import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { Colors, Display } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { Combobox, ComboboxOption } from '../Combobox/Combobox';
import If from '../If/If';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import { NumberTextField } from '../NumberTextBox/NumberTextBox';
import { Errors } from 'Util/CustomTypes';

export interface AccountPermissionsProps {
	user: UserEntity,
}

const productComboboxOptions = () : ComboboxOption<string>[] => {
	const productOptions: ComboboxOption<string>[] = [];

	// NOTE: Items must be added alphabetical
	if (store.canAccessApprove) {
		productOptions.push({ display: 'Approve Permissions', value: 'approve' });
	}
	productOptions.push({ display: 'HUB Permissions', value: 'common' });
	if (store.canAccessIntel) {
		productOptions.push({ display: 'Monitor Permissions', value: 'intel' });
	}
	if (store.canAccessPPSR) {
		productOptions.push({ display: 'PPSR Permissions', value: 'ppsr' });
	}

	return productOptions;
};

function AccountPermissions(props: AccountPermissionsProps) {
	const { user: originalUser } = props;

	// Create a user state to observe changes on the user
	const [user, setUser] = React.useState(cloneDeep(originalUser));

	// Memoise the initial permission and listen to originalUser changes
	const initialPermissions = React.useMemo(() => {
		return calculateUserPermissions(originalUser.userType, originalUser.permissionOverridesObject);
	}, [originalUser.permissionOverridesObject, originalUser.userType]);

	// Memoise the default permissions and listen to when the user type changes
	const defaultPermissions = React.useMemo(() => defaultPermissionsForUserType(user.userType), [user.userType]);
	const [userPermissions, setUserPermissions] = React.useState(
		calculateUserPermissions(user.userType, user.permissionOverridesObject),
	);

	const isUserAboveMe = UserTypeOrder[user.userType] > UserTypeOrder[store.userType];
	const [productTab, setProductTab] = React.useState(
		{ product: 'common' },
	);

	// should the form be disabled => Can I manage users or is the user above me
	const formDisabled = (store.userPermissions.commonManageUsers === 'SELF') || isUserAboveMe;
	const [errors, setErrors] = React.useState({} as Errors);

	// update Credit Approval Limit
	const updateCreditApprovalLimit = () => {
		const newErrors = { ...errors };

		const currentUserCreditLimit = store.getUser?.creditApprovalLimit ?? 0;
		// update the errors list
		if (!user.canAccessApprove) {
			delete newErrors.creditApprovalLimit;
		// eslint-disable-next-line max-len
		} else if (user.creditApprovalLimit > currentUserCreditLimit) {
			newErrors.creditApprovalLimit = 'Credit Approval Limit cannot be greater than your own limit';
		} else {
			delete newErrors.creditApprovalLimit;
		}
		setErrors(newErrors);

		// set the credit approval limit to 0
		// when access to approve is removed
		if (!user.canAccessApprove) {
			user.creditApprovalLimit = 0;
		}
		// change the credit approval amount
		setUser(cloneDeep(user));
	};

	// on change handler for the permission inputs
	const onPermissionChange = (permission: string, value: (boolean | string | undefined)) => {
		const oldUserPermissions = { ...userPermissions };
		oldUserPermissions[permission] = value;
		setUserPermissions(oldUserPermissions);

		// ensure there is an object set
		if (!user.permissionOverridesObject) {
			user.permissionOverridesObject = {};
		}

		user.setPermission(user.permissionOverridesObject, permission, value);
		setUser(cloneDeep(user));
	};

	// handler for when a product access is changed
	const onProductChecked = (product: ('ppsr' | 'intel' | 'approve'), checked: boolean, hadProduct?: boolean) => {
		const oldUserPermissions = { ...userPermissions };

		// loop through all permissions and change the permissions for that product to false because we don't have access
		for (const permission in userPermissions) {
			if (permission.startsWith(product)) {
				if (!checked) {
					oldUserPermissions[permission] = false;
					if (user.permissionOverridesObject) {
						user.setPermission(user.permissionOverridesObject, permission, false);
					}
				} else if (hadProduct) { // if the user ticked and unticked the box then load back in the initial state
					if (originalUser.permissionOverridesObject) {
						oldUserPermissions[permission] = initialPermissions[permission];
						if (user.permissionOverridesObject) {
							user.setPermission(
								user.permissionOverridesObject,
								permission,
								originalUser.permissionOverrides[permission],
							);
						}
					}
				} else {
					oldUserPermissions[permission] = defaultPermissions[permission];
					if (user.permissionOverridesObject) {
						user.setPermission(user.permissionOverridesObject, permission, defaultPermissions[permission]);
					}
				}
			}
		}

		// update the credit approval limit if we are removing access to approve
		if (product === 'approve' && !checked) {
			updateCreditApprovalLimit();
		}

		setUserPermissions(oldUserPermissions);
		setUser(cloneDeep(user));
	};

	const disableSaveButton = () => {
		const hasErrors = Object.keys(errors).length > 0;
		const hasChanges = !isEqual(originalUser, user);
		return formDisabled || hasErrors || !hasChanges;
	};

	// handler for save button
	const onSavePressed = async () => {
		// We can't demote the last organisation manager of an organisation
		if (user.userType !== originalUser.userType) {
			if (await originalUser.isLastOrganisationManager()) {
				alertToast('You cannot demote the last organisation manager in an organisation.', 'error');
				return;
			}
		}

		try {
			await user.save(
				{
					businessEntities: {},
				},
			);
			const newPermissions = calculateUserPermissions(user.userType, user.permissionOverridesObject);
			const clonedUser = cloneDeep(user);

			// update the user and user permission in case backend changed due to security
			setUserPermissions(newPermissions);
			setUser(clonedUser);

			// update the user within the props so that we can revert back to this new saved user
			if (originalUser.permissionOverridesObject && clonedUser.permissionOverridesObject) {
				originalUser.permissionOverridesObject = { ...clonedUser.permissionOverridesObject };
			}
			originalUser.userType = clonedUser.userType;
			originalUser.canAccessApprove = clonedUser.canAccessApprove;
			originalUser.canAccessPPSR = clonedUser.canAccessPPSR;
			originalUser.canAccessIntel = clonedUser.canAccessIntel;

			// if we edited our current user then set the logged in user with updated info
			if (store.userId === user.id) {
				const userResult: IUserResult = {
					type: 'user-data',
					id: user.id,
					email: user.email,
					userName: user.email,
					userDisplayName: `${user.firstName} ${user.lastName}`,
					groups: store.userGroups,
					userType: user.userType,
					organisation: user.organisation,
					canAccessIntel: user.canAccessIntel,
					canAccessApprove: user.canAccessApprove,
					canAccessPPSR: user.canAccessPPSR,
					userPermissions: newPermissions,
				};
				store.setLoggedInUser(userResult);
			}

			alertToast('User has been successfully saved', 'success');
		} catch (error) {
			alertToast(
				`User could not be saved. Please refresh and try again. Error: ${error}`,
				'error',
			);
		}
	};

	/**
	 * Function to return an array of JSX elements as permissions given a specific product
	 * @param product The product we are rendering (ppsr | common | approve | intel)
	 * @returns An array of JSX elements (checkboxes and comboboxes)
	 */
	const renderPermissions = (product: ('ppsr' | 'common' | 'approve' | 'intel'), access: boolean = false) => {
		const relevantPermissions = Object.keys(userPermissions)
			.filter(permission => permission.startsWith(product)
				&& (
					store.userType === 'SUPER_USER'
					|| !UserPermissions.hiddenPermissions.includes(permission)
				))
			.sort((left, right) => userPermissionsToDisplay[left].localeCompare(userPermissionsToDisplay[right]));

		const permissions = [];
		for (const permission of relevantPermissions) {
			const disabled = (
				formDisabled
				|| !store.userPermissions[permission]
				|| !defaultPermissions[permission]
			);

			// are we rendering a boolean or a combobox
			if (typeof (userPermissions[permission]) === 'boolean') {
				permissions.push(
					<Checkbox
						key={`${user.id} ${permission}`}
						id={permission}
						className="checkbox"
						label={userPermissionsToDisplay[permission]}
						model={userPermissions}
						modelProperty={permission}
						isDisabled={disabled || !access}
						onChecked={(event, checked) => onPermissionChange(permission, checked)}
					/>,
				);
			} else if (typeof (userPermissions[permission]) === 'string') {
				// The field type is an enum, which appears as a string
				// We show a different combobox depending on the field in question
				let permissionType: 'user'|'organisation'|'businessEntity'|undefined;
				switch (permission) {
					case 'commonManageUsers':
					case 'commonViewUsers':
						permissionType = 'user';
						break;
					case 'commonManageOrganisations':
					case 'commonViewOrganisations':
						permissionType = 'organisation';
						break;
					case 'commonManageBusinessEntities':
					case 'commonViewBusinessEntities':
						permissionType = 'businessEntity';
						break;
				}

				if (permissionType !== undefined) {
					permissions.push(
						<Combobox
							key={`${user.id} ${permission}`}
							isDisabled={formDisabled || !access}
							model={userPermissions}
							modelProperty={permission}
							label={userPermissionsToDisplay[permission]}
							options={permissionScopeCombobox(
								permissionType,
								store.userPermissions[permission],
								defaultPermissions[permission],
								formDisabled,
							)}
							className={`combobox ${permission}`}
							onChange={(event, data) => onPermissionChange(permission, data.value?.toString())}
						/>,
					);
				}
			}
		}

		return permissions;
	};

	return (
		<div className="account-permissions">
			<div className="top-container">
				<h3>Permissions settings</h3>
			</div>
			<div className="chooser-container">
				<Combobox
					className="combobox user-type"
					model={user}
					modelProperty="userType"
					label="User Type"
					options={reducedUserTypeComboboxOptions(store.userType, formDisabled)}
					isDisabled={formDisabled}
					onAfterChange={(event, data) => {
						if (user.userType === originalUser.userType) {
							setUserPermissions(calculateUserPermissions(
								user.userType,
								originalUser.permissionOverridesObject,
							));
							setUser(cloneDeep(originalUser));
						} else {
							// when changing user types we are resetting the permission overrides object
							user.permissionOverridesObject = {};
							setUserPermissions(calculateOtherUserTypeVisiblePermissions(user.userType));
						}
					}}
				/>

				<Combobox
					className="combobox product-option"
					model={productTab}
					modelProperty="product"
					label="Product Permissions"
					options={productComboboxOptions()}
					onChange={(event, data) => {
						const value: string = data.value ? data.value.toString() : 'common';
						setProductTab({ product: value });
					}}
				/>
			</div>

			<If condition={productTab.product === 'common'}>
				<div className="permissions-container">
					<div className="permissions-box">
						<h5 className="permission-title">HUB Permission Settings</h5>
						<div className="permissions-list">
							{renderPermissions('common', true)}
						</div>
					</div>
				</div>
			</If>

			<If condition={productTab.product === 'approve'}>
				<div className="permissions-container">
					<div className="permissions-box">
						<h5 className="permission-title">Approve Permission Settings</h5>
						<Checkbox
							className="checkbox"
							model={user}
							modelProperty="canAccessApprove"
							label="Can Access Approve"
							isDisabled={formDisabled || !store.canAccessApprove}
							onChecked={(event, checked) => {
								user.canAccessApprove = checked;
								onProductChecked('approve', checked, originalUser.canAccessApprove);
							}}
						/>
						<div className="permissions-list">
							{renderPermissions('approve', user.canAccessApprove)}
						</div>
					</div>
					<div className="approval-credit-limit">
						<NumberTextField
							model={user}
							modelProperty="creditApprovalLimit"
							label="Credit Approval Limit"
							onAfterChange={updateCreditApprovalLimit}
							acceptWholeNumbersOnly
							isDisabled={formDisabled || !user.canAccessApprove}
							errors={errors.creditApprovalLimit}
						/>
					</div>
				</div>
			</If>

			<If condition={productTab.product === 'ppsr'}>
				<div className="permissions-container">
					<div className="permissions-box">
						<h5 className="permission-title">PPSR Permission Settings</h5>
						<Checkbox
							className="checkbox"
							model={user}
							modelProperty="canAccessPPSR"
							label="Can Access PPSR"
							isDisabled={formDisabled || !store.canAccessPPSR}
							onChecked={(event, checked) => {
								user.canAccessPPSR = checked;
								onProductChecked('ppsr', checked, originalUser.canAccessPPSR);
							}}
						/>
						<div className="permissions-list">
							{renderPermissions('ppsr', user.canAccessPPSR)}
						</div>
					</div>
				</div>
			</If>

			<If condition={productTab.product === 'intel'}>
				<div className="permissions-container">
					<div className="permissions-box">
						<h5 className="permission-title">Monitor Permission Settings</h5>
						<Checkbox
							className="checkbox"
							model={user}
							modelProperty="canAccessIntel"
							label="Can Access Monitor"
							isDisabled={formDisabled || !store.canAccessIntel}
							onChecked={(event, checked) => {
								user.canAccessIntel = checked;
								onProductChecked('intel', checked, originalUser.canAccessIntel);
							}}
						/>
						<div className="permissions-list">
							{renderPermissions('intel', user.canAccessIntel)}
						</div>
					</div>
				</div>
			</If>

			<div className="button-container">
				<ButtonAsyncState
					className="save"
					colors={Colors.Primary}
					display={Display.Solid}
					onPress={onSavePressed}
					readonly={disableSaveButton()}
				>
					Save Changes
				</ButtonAsyncState>
			</div>
		</div>
	);
}

export default AccountPermissions;
