import { store } from '../../../Models/Store';
import ComboboxSetter from '../Combobox/ComboboxSetter';
import useAsync from '../../../Hooks/useAsync';
import gql from 'graphql-tag';
import { HasCondition, IWhereCondition } from '../ModelCollection/ModelQuery';
import Modal from '../Modal/Modal';
import * as React from 'react';
import { Button, Display } from '../Button/Button';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import { ComboboxOption } from '../Combobox/Combobox';
import { ICustomerEntityAttributes } from '../../../Models/Entities';

export type LinkExistingCustomerModalProps = {
	onSubmit: (id: string) => Promise<void>,
	businessEntityId: string,
	onClose: () => void,
	atbFileId: string,
}

const LinkExistingCustomerModal = ((props: LinkExistingCustomerModalProps) => {
	const {
		businessEntityId, onSubmit, onClose, atbFileId,
	} = props;
	const [customer, setCustomer] = React.useState(undefined as string | undefined);
	const customers = useAsync(async (): Promise<ComboboxOption<string>[]> => {
		const results = await store.apolloClient.query({
			query: gql`
				query customerEntitys ($where: [WhereExpressionGraph], $has: [[HasConditionType]]) {
					customerEntitys (where: $where, has: $has) {
						id
						businessName
					}
				}
			`,
			fetchPolicy: 'no-cache',
			variables: {
				where: [{
					path: 'businessEntityId',
					value: [businessEntityId],
					comparison: 'equal',
				}] as IWhereCondition<string>[],
				has: [[
					{
						path: 'atbRecords',
						negate: true,
						conditions: [[
							{
								path: 'atbFileId',
								comparison: 'equal',
								value: atbFileId,
							},
						]],
					},
				]],
			},
		});

		return results.data.customerEntitys.map((entity: Partial<ICustomerEntityAttributes> | undefined) => {
			return {
				display: entity?.businessName,
				value: entity?.id,
			};
		});
	}, [businessEntityId], { suppressSubsequentLoadingState: true });

	if (customers.type === 'loading') {
		return null;
	}

	return (
		<Modal
			isOpen
			label="Find Existing Customer"
			onRequestClose={onClose}
			className="access-modal link-existing-customer-modal"
		>
			<h6>Find Existing Customer</h6>
			<ComboboxSetter
				value={customer}
				setValue={setCustomer}
				getOptionValue={(value?: string) => value}
				placeholder="Search Customer Name"
				searchable
				options={customers.data ?? [] as ComboboxOption<string>[]}
				label="Search for existing customer"
				labelVisible
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					onPress={async () => {
						await onSubmit(customer ?? '');
					}}
					display={Display.Solid}
					readonly={!customer}
					waitingText="Submitting"
				>
					Submit
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});

export default LinkExistingCustomerModal;
