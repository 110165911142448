import * as React from 'react';
import If from 'Views/Components/If/If';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import useStore from 'Hooks/useStore';

function DashboardAgedTrialBalanceNoData() {
	const store = useStore();

	return (
		<div className="dashboard-aged-trial-balance-no-data">
			<div className="white-box">
				<div>
					<h4>Get a credit risk analysis on all your customers</h4>
					<div className="text-container">
						<p>
							Upload your Aged Trial Balance to access risk payment scores and insolvency risk ratings
							for each customer.
						</p>
					</div>
					<If condition={store.userPermissions.intelUploadAtb === true}>
						<div className="button-container">
							<Button
								onClick={() => store.routerHistory.push('upload/agedtrialbalance')}
								display={Display.Solid}
								colors={Colors.Primary}
							>
								Upload your ATB Data
							</Button>
						</div>
					</If>
				</div>
			</div>
		</div>
	);
}

export default DashboardAgedTrialBalanceNoData;
