import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { CustomerEntity } from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import If from 'Views/Components/If/If';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import alertToast from 'Util/ToastifyUtils';
import MonitorNavLinks from 'Views/MonitorNavLinks';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import {
	FetchIndividualRegistrationResponse,
	IndividualRegistrationGrantor,
} from 'Views/Components/Intel/Customer/PpsrRegistrationTypes';
import classNames from 'classnames';
import moment from 'moment/moment';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface PpsrRegistrationPageProps {
	customerId?: string,
	registrationId?: string,
}

const PpsrRegistrationPage = (props: RouteComponentProps<PpsrRegistrationPageProps>) => {
	const {
		match,
	} = props;
	const { customerId, registrationId } = match.params;

	const customer = useAsync(async () => {
		const results = await store.apolloClient.query({
			query: gql`
				query fetchCustomerById($customerId: ID) {
					customerEntity(id: $customerId) {
						id
						businessName
					}
				}`,
			variables: {
				customerId: customerId,
			},
		});
		return new CustomerEntity(results.data.customerEntity);
	}, [customerId]);

	const registrationResponse = useAsync(
		async () => {
			const response = await axios.get(
				`${SERVER_URL}/api/entity/CustomerEntity/registration/${registrationId}`,
			);
			return response.data as FetchIndividualRegistrationResponse;
		},
		[registrationId],
	);

	if (customer.type === 'error' || registrationResponse.type === 'error') {
		alertToast('Unable to find matching registration', 'error');
		const redirectUrl = customerId != null
			? '/monitor/customers'
			: '/';
		store.routerHistory.push(redirectUrl);
		return <Spinner />;
	}

	const grantorName = (grantor?: IndividualRegistrationGrantor) => {
		if (!grantor) {
			return '';
		}
		return grantor.grantorType === 'Organisation'
			? grantor.organisationName || '-'
			: `${grantor.givenNames} ${grantor.familyName}`;
	};

	const registration = registrationResponse.data?.registration;
	const grantor = !!registration?.grantors && registration.grantors.length > 0
		? registration.grantors[0]
		: undefined;
	return (
		<AccessIntelSecuredPage routeComponentProps={props} product="monitor">
			<div className="body-content registration-page">
				<div className="invisible-page-wrap">
					<If condition={customer.type === 'loading' || registrationResponse.type === 'loading'}>
						<InlineSpinner />
					</If>
					{customer.type === 'data' && !!registration && (
						<>
							<div className="top-container">
								<PageBreadcrumbs
									tags={[
										{ label: 'Customers', link: '/monitor/customers' },
										{
											label: customer.data.businessName ?? '',
											link: `/monitor/customers/${customer.data.id}`,
										},
										{ label: grantorName(grantor) },
									]}
								/>
							</div>
							<div className="white-box">
								<div className="registration-section">
									<h4 className="registration-title">Grantor information</h4>
									<div className="registration-field">
										<p className="registration-field-label">Grantor type</p>
										<p className="registration-field-content bold grantor-type">
											{grantor?.grantorType || '-'}
										</p>
									</div>
									<If condition={grantor?.grantorType === 'Organisation'}>
										<div className="registration-field">
											<p className="registration-field-label">Org. number</p>
											<p className="registration-field-content bold org-number">
												{grantor?.organisationNumber || '-'}
											</p>
										</div>
									</If>
									<If condition={grantor?.grantorType !== 'Organisation'}>
										<div className="registration-field">
											<p className="registration-field-label">Date of birth</p>
											<p className="registration-field-content bold date-of-birth">
												{!!grantor?.dateOfBirth
													? moment(grantor.dateOfBirth).format('DD/MM/yyyy')
													: '-'}
											</p>
										</div>
									</If>
									<div className="registration-field">
										<p className="registration-field-label">Grantor name</p>
										<p className="registration-field-content bold grantor-name">
											{grantorName(grantor)}
										</p>
									</div>
								</div>

								<div className="registration-section">
									<h4 className="registration-title">General information</h4>
									<div className="registration-field">
										<p className="registration-field-label">Status</p>
										<p className="registration-field-content status">
											<span className={classNames('highlight', {
												green: registration.status === 'Registered',
												red: registration.status !== 'Registered',
											})}
											>
												{registration.status || 'N/A'}
											</span>
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Registration ID</p>
										<p className="registration-field-content registration-id">
											{registration.id || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Secure party</p>
										<p className="registration-field-content secure-party">
											{registration.securedPartyGroup.name || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">GONI</p>
										<p className="registration-field-content goni">
											{registration.goni || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Collateral description</p>
										<p className="registration-field-content collateral-description">
											{registration.collateralDescription || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Collateral class</p>
										<p className="registration-field-content collateral-class">
											{registration.collateralClass || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Contract profile</p>
										<p className="registration-field-content contract-id">
											{registration.contractId || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Free text</p>
										<p className="registration-field-content free-text">
											{registration.freeText1 || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Created by</p>
										<p className="registration-field-content created-by">
											{registration.createdBy?.name || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Updated by</p>
										<p className="registration-field-content updated-by">
											{registration.updatedBy?.name || '-'}
										</p>
									</div>
								</div>

								<div className="registration-section">
									<h4 className="registration-title">PPSR information</h4>
									<div className="registration-field">
										<p className="registration-field-label">Registration number</p>
										<p className="registration-field-content registration-number">
											{registration.ppsrRegistrationNumber || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Change number</p>
										<p className="registration-field-content change-number">
											{registration.ppsrRegistrationChangeNumber || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Registration start date</p>
										<p className="registration-field-content registration-start-date">
											{!!registration.ppsrRegistrationStartTime
												? moment(registration.ppsrRegistrationStartTime).format('DD/MM/yyyy')
												: '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Registration end date</p>
										<p className="registration-field-content registration-end-date">
											{!!registration.ppsrRegistrationEndTime
												? moment(registration.ppsrRegistrationEndTime).format('DD/MM/yyyy')
												: '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Registration discharge date</p>
										<p className="registration-field-content registration-discharge-date">
											{!!registration.ppsrDischargeDate
												? moment(registration.ppsrDischargeDate).format('DD/MM/yyyy')
												: '-'}
										</p>
									</div>
								</div>

								<div className="registration-section">
									<h4 className="registration-title">Serialisation information</h4>
									<div className="registration-field">
										<p className="registration-field-label">Serial number</p>
										<p className="registration-field-content serial-number">
											{registration.serialNumber || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Serial number type</p>
										<p className="registration-field-content serial-number-type">
											{registration.serialNumberType || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Vehicle registration number</p>
										<p className="registration-field-content vehicle-registration-number">
											{registration.vehicleRegistrationNumber || '-'}
										</p>
									</div>
									<div className="registration-field">
										<p className="registration-field-label">Vehicle descriptive text</p>
										<p className="registration-field-content vehicle-descriptive-text">
											{registration.vehicleDescriptiveText || '-'}
										</p>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};
export default PpsrRegistrationPage;
