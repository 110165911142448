import * as React from 'react';
import moment from 'moment';
import If from 'Views/Components/If/If';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import useStore from 'Hooks/useStore';
import { AtbDashboardContext } from 'Views/Pages/Monitor/MonitorDashboardPage';

const DashboardAgedTrialBalanceUpdateButton = () => {
	const store = useStore();
	const canUploadAtbData = store.userPermissions.intelUploadAtb === true;

	return (
		<AtbDashboardContext.Consumer>
			{value => (
				<div className="dashboard-aged-trial-balance-update-button">
					<p>{!!value.latestUploadTime
						? (<>Last Updated {moment(value.latestUploadTime).format('h:mma, DD/MM/yyyy')}</>)
						: (<>&nbsp;</>)}
					</p>
					<If condition={canUploadAtbData}>
						<Tooltip
							id="update-atb-data-reminder"
							content={'Upload your Customer Aged Trial Balance before the 10th of each month to '
								+ 'maintain accurate ongoing risk scores'}
						/>
						<Button
							onClick={() => store.routerHistory.push('upload/agedtrialbalance')}
							display={Display.Solid}
							colors={Colors.Primary}
						>
							Update ATB Data
						</Button>
					</If>
				</div>
			)}
		</AtbDashboardContext.Consumer>
	);
};
export default DashboardAgedTrialBalanceUpdateButton;
