import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RegistrationData from 'Models/Entities/RegistrationData';
import { RadioButtonGroup } from 'Views/Components/RadioButton/RadioButtonGroup';
import { action } from 'mobx';
import { RegistrationEntity } from 'Models/Entities';
import { useState } from 'react';
import { Errors } from 'Util/CustomTypes';

const ReviewTermsStep = observer((props: WizardComponentProps) => {
	const { changePage, currentStep } = props;
	const { model, data }: { model: RegistrationData, data?: RegistrationEntity } = props;

	const [errors, setErrors] = useState({} as Errors);

	// Gives us a string field we can pass the radio button group
	const internalModel = useLocalStore(() => {
		const internalModelObject = {
			requestTermsReview: undefined as string|undefined,
		};

		if (model.requestTermsReview) {
			internalModelObject.requestTermsReview = 'true';
		}
		if (model.requestTermsReview === false) {
			internalModelObject.requestTermsReview = 'false';
		}

		return internalModelObject;
	});

	const onNext = () => {
		if (model.requestTermsReview === null || model.requestTermsReview === undefined) {
			setErrors(newErrors => {
				newErrors.requestTermsReview = 'Please let us know if you need a review of your Terms & Conditions';
				return { ...newErrors };
			});
			return;
		}

		setErrors(newErrors => {
			delete newErrors.requestTermsReview;
			return { ...newErrors };
		});
		changePage();
	};

	return (
		<div className="step-wrap review-terms-step">
			<div className="step-contents align-one-fourth">
				<div className="alignment-box">
					<div className="limit-width-box">
						<h5 className="step-header">Review your terms & conditions</h5>
						<p className="step-description">
							We offer a terms & conditions of trade review service. Are you satisfied with your current
							terms & conditions or would you like a review?
						</p>

						<RadioButtonGroup
							className="terms-radio-group"
							label="Request a terms & conditions of trade review"
							model={internalModel}
							modelProperty="requestTermsReview"
							options={[
								{ value: 'true', display: 'Yes, please review my terms & conditions' },
								{ value: 'false', display: 'No' },
							]}
							onAfterChange={action(value => {
								model.requestTermsReview = value === 'true';

								if (errors.requestTermsReview) {
									setErrors(newErrors => {
										delete newErrors.requestTermsReview;
										return { ...newErrors };
									});
								}
							})}
							errors={errors.requestTermsReview}
						/>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right next-btn"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onNext}
					disabled={Object.keys(errors).length > 0}
				>
					Submit
				</Button>
			</div>
		</div>
	);
});
export default ReviewTermsStep;
