import { useESignStore } from 'ESign/Stores/ESignStore';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DocumentContentDetail } from 'Views/Components/BusinessEntities/ESignTemplateSection/Gibs/TemplateContent';
import ESignSigningContent from './Gibs/ESignSigningContent';
import ErrorSection from './Error/ErrorSection';

export interface SigningDocumentContentDetail extends DocumentContentDetail {
	inputFieldWithValue: Record<string, Record<string, string>>;
}

type Props = {
	templateContent: string;
	updateTemplateContent: (content: string, hasCompleted: boolean, signature: string) => void;
}

const ESignSigningSection: React.FunctionComponent<Props> = ({ templateContent, updateTemplateContent }) => {
	const {
		workflowStore: { userSummary, selectedCeremony, setLocation },
	} = useESignStore();

	useEffect(() => {
		if (selectedCeremony) {
			setLocation();
		}
	}, [selectedCeremony, setLocation]);

	if (!selectedCeremony) {
		return (
			<ErrorSection
				title="An error has occurred. Please refresh the page and try again"
				message="Please select a ceremony to proceed"
			/>
		);
	}

	return (
		<ESignSigningContent
			signature={userSummary.signature ?? ''}
			ceremonyDetail={selectedCeremony}
			signingDocumentContentDetail={JSON.parse(templateContent)}
			updateDocumentContent={updateTemplateContent}
		/>
	);
};

export default observer(ESignSigningSection);
