import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import * as React from 'react';
import { CustomerEntity } from 'Models/Entities';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import useStore from 'Hooks/useStore';

export async function overViewNoAtbRecordsModal() {
	await confirmModal(
		'Please upload your ATB data spreadsheet to view',
		(
			<>
				<p>To access risk payment scores and debt information, you must <b>upload your ATB CSV</b>.</p>
				<p>Would you like to upload your data now?</p>
			</>
		),
		{
			confirmText: 'Yes, upload',
		},
	);
}
const CustomerOverviewNoAtbRecords = (props: {
	customer: CustomerEntity,
}) => {
	const { customer } = props;
	const store = useStore();

	const onNoAtbDataClick = async () => {
		overViewNoAtbRecordsModal().then(() => {
			store.routerHistory.push('/monitor/upload/agedtrialbalance');
		}).catch(() => {});
	};

	return (
		<If condition={!customer?.newestAtbRecord?.id}>
			<p className="no-atb-button" {...HandleEvents(onNoAtbDataClick)}>
				Why can&apos;t I see outstanding and overdue amounts?
			</p>
		</If>
	);
};

export default CustomerOverviewNoAtbRecords;
