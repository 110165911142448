import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { BusinessEntity } from 'Models/Entities';

type GqlBusinessEntityResponse = {
	businessEntitys: BusinessEntity[];
}

export type RequestBusinessArgs = {
	// Filter business entities for like names
	filterByName?: string;

	// Filter business entities for matching organisation id
	filterByOrgId?: string;

	// Filter business entities that have monitor enabled
	filterByMonitor?: boolean;
}

export async function requestBusiness(args: RequestBusinessArgs) {
	const { filterByName, filterByOrgId, filterByMonitor } = args;
	const whereCondtitions: IWhereCondition<any>[] = [];

	if (filterByName) {
		whereCondtitions.push({
			path: 'name',
			comparison: 'like',
			value: [!!filterByName ? `%${filterByName}%` : '%'],
			case: 'CURRENT_CULTURE_IGNORE_CASE',
		});
	}

	if (filterByOrgId) {
		whereCondtitions.push({
			path: 'organisationId',
			value: filterByOrgId,
			comparison: 'equal',
		});
	}

	if (filterByMonitor !== undefined) {
		whereCondtitions.push({
			path: 'enabledForMonitor',
			value: filterByMonitor ? 'true' : 'false',
			comparison: 'equal',
		});
	}

	const result = await store.apolloClient
		.query<GqlBusinessEntityResponse>({
			query: gql`
                        query fetchBusinessEntitys($whereConditions: [WhereExpressionGraph]) {
                            businessEntitys(
                                where: $whereConditions,
                                orderBy: [{path: "name", descending: false}],
                            ) {
                                id
                                name
                                enabledForMonitor
                                organisationId
                            }
                        }
                    `,
			variables: {
				whereConditions: whereCondtitions,
			},
			fetchPolicy: 'no-cache',
		});
	return result;
}
