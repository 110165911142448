import * as React from 'react';
import { SpgEntity } from '../../../Models/Entities';
import { observer } from 'mobx-react';
import If from '../If/If';
import classnames from 'classnames';
import SpgFields from '../Ppsr/SpgFields';
import EntityActiveStatus from './EntityActiveStatus';
import alertToast from '../../../Util/ToastifyUtils';
import { Button, Colors, Display } from '../Button/Button';
import { debounce } from 'lodash';
import useHasChanged from '../../../Hooks/useHasChanged';
import { observable } from 'mobx';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface SpgListItemProps {
    spgEntity: SpgEntity,
    readonly?: boolean,
}

const SpgEntityListItem = observer((props: SpgListItemProps) => {
	const { spgEntity, readonly } = props;

	const [expanded, setExpanded] = React.useState(false);
	const [privateSpgEntity, setPrivateSpgEntity] = React.useState(new SpgEntity(spgEntity));
	// use a custom hasChanged hook to determine if the save should be disabled because nothing has changed
	// this assumes that the reference to errors always changes when an error
	const saveDisabled = useHasChanged(
		SpgEntity,
		privateSpgEntity,
		['spgName', 'spgNumber', 'email', 'accessCode', 'organisationalNumbers', 'contactName', 'phone'],
		(oldValue, newValue) => oldValue !== newValue,
		['errors'],
		(original, changed) => Object.keys(changed.errors).length > 0,
	);

	return (
		<div className="spg-entity-item">
			<div className="spg-entity-label-area">
				<div className="spg-entity-details">
					<div className="spg-entity-number">
						SPG number: {spgEntity.spgNumber} ({spgEntity.spgName})
					</div>
					<div className="spg-entity-email">
						Email address: {spgEntity.email}
					</div>
				</div>
				<div className="spg-entity-active-status">
					<EntityActiveStatus
						model={spgEntity}
						modelProperty="isActive"
						statusText="SPG status:"
						disabled={readonly}
						onAfterChange={async () => {
							const savedEntity = new SpgEntity(spgEntity);
							try {
								await spgEntity.save();
							} catch (exception) {
								spgEntity.assignAttributes(savedEntity);
								alertToast('SPG status could not be changed,'
									+ 'please refresh and try again', 'error');
							}
						}}
					/>
				</div>
				<button
					type="button"
					className={classnames('expand-spg-entity', {
						expanded: expanded,
					})}
					onClick={() => setExpanded(oldValue => !oldValue)}
					aria-label="Expand spg entity"
				/>
			</div>
			<If condition={expanded}>
				<div className="spg-entity-expanded-area">
					<SpgFields spgEntity={privateSpgEntity} readonly={!spgEntity.isActive || readonly} />
					<If condition={spgEntity.isActive && !readonly}>
						<ButtonAsyncState
							className="spg-entity-save"
							colors={Colors.Primary}
							display={Display.Solid}
							onPress={async () => {
								try {
									if (Object.keys(privateSpgEntity.errors).length > 0) return;
									await privateSpgEntity.save();
									spgEntity.assignAttributes(privateSpgEntity);
									setPrivateSpgEntity(new SpgEntity(privateSpgEntity));
									alertToast('SPG successfully saved', 'success');
								} catch (exception) {
									privateSpgEntity.assignAttributes(spgEntity);
									alertToast('SPG could not be saved, '
										+ 'please refresh and try again', 'error');
								}
							}}
							readonly={saveDisabled}
						>
							Save changes
						</ButtonAsyncState>
					</If>
				</div>
			</If>
		</div>
	);
});

export default SpgEntityListItem;
