import { AttributeCRUDOptions } from 'Models/CRUDOptions';
import { LogoEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import { Button, Display } from 'Views/Components/Button/Button';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import CustomAttributeFile from 'Views/Components/CRUD/Attributes/CustomAttributeFile';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import Modal from 'Views/Components/Modal/Modal';
import { useLocalStore } from 'mobx-react';
import React, { useState } from 'react';

interface Props {
	businessEntityId: string;
	isReadOnly: boolean
	enableRefresh: () => void;
	onClose: () => void;
}

const LogoUpload: React.FunctionComponent<Props> = ({
	businessEntityId,
	isReadOnly = true,
	enableRefresh,
	onClose,
}) => {
	const logo = useLocalStore(() => new LogoEntity({
		businessEntityId: businessEntityId,
	}));

	const [hasFile, setHasFile] = useState(false);

	async function handleUploadLogo() {
		try {
			await logo.saveFromCrud(EntityFormMode.EDIT);
			alertToast('Successfully uploaded the logo. Please choose the latest logo for template now.', 'success');

			onClose();
			enableRefresh();
		} catch (error) {
			alertToast('Failed to upload the logo. Please try again', 'error');
			enableRefresh();
		}
	}

	return (
		<Modal
			isOpen
			label="Upload logo for E-Sign templates"
			onRequestClose={onClose}
			className="access-modal business-entity-logo-modal"
		>
			<h4>Upload Business Logo</h4>
			<CustomAttributeFile
				model={logo}
				fileAttribute="fileName"
				options={new AttributeCRUDOptions(
					'fileNameId',
					{
						name: '',
						displayType: 'file',
					},
				)}
				formMode={EntityFormMode.EDIT}
				onAfterChange={(file: File) => {
					if (!file.name) {
						setHasFile(false);
					} else {
						setHasFile(true);
					}
				}}
				onAfterDelete={() => {
					setHasFile(false);
				}}
				imageOnly
				isReadonly={isReadOnly}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					onPress={() => handleUploadLogo()}
					display={Display.Solid}
					readonly={isReadOnly || !hasFile}
					waitingText="Uploading..."
				>
					Upload
				</ButtonAsyncState>
			</div>
		</Modal>
	);
};

export default LogoUpload;
