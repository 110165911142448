import * as React from 'react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import { DatePicker } from '../DatePicker/DatePicker';
import { observer } from 'mobx-react';
import { AddressEntity, BusinessEntity, TrustEntityCodesEntity } from 'Models/Entities';
import { debounce } from 'lodash';
import If from '../If/If';
import { action, runInAction } from 'mobx';
import { Checkbox } from '../Checkbox/Checkbox';
import RadioButtonGroupSetter from '../RadioButton/RadioButtonGroupSetter';
import { TextArea } from '../TextArea/TextArea';
import { useEffect } from 'react';
import useAsync from 'Hooks/useAsync';
import { acnStatusOptions, acnTypeOptions } from '../../../Models/Enums';
import axios from 'axios';
import { SERVER_URL } from 'Constants';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface OrganisationFieldsProps {
	primaryBusinessEntity?: BusinessEntity,
    businessEntity: BusinessEntity,
	onAfterChange?: (validateSuccessful: boolean) => void,
	type: 'organisation'|'primaryBusinessEntity'|'nonPrimaryBusinessEntity',
	disabled?: boolean,
	quickRegister?: boolean,
}

const OrganisationFields = observer((props: OrganisationFieldsProps) => {
	const {
		primaryBusinessEntity,
		businessEntity,
		onAfterChange = () => {},
		type,
		disabled,
		quickRegister,
	} = props;

	const [previousAbn] = React.useState(businessEntity.abn);

	useEffect(() => {
		if (!businessEntity.address) {
			runInAction(() => {
				businessEntity.address = new AddressEntity();
			});
		}
	}, [businessEntity]);

	const entityName = type === 'organisation' ? 'Organisation' : 'Entity';

	useAsync(async () => {
		const validationPromises = [];
		if (businessEntity.abn) {
			validationPromises.push(businessEntity.validateField('abn'));
		}

		if (businessEntity.acn) {
			validationPromises.push(businessEntity.validateField('acn'));
		}

		if (businessEntity.trusteeACN) {
			validationPromises.push(businessEntity.validateField('trusteeACN'));
		}
		Promise.all(validationPromises);
	}, [businessEntity]);

	/**
	 * Handle for the change event for the inputs. This method will attempt to validate a given field
	 * iff there are existing errors. So that the user can immediately see when their input stops the error
	 * @param field The name of the field to be validated
	 * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
	 * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
	 */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateField(field);
		}

		const parsedPreviousAbn = previousAbn?.replace(/\s/g, '');
		const parsedCurrentAbn = businessEntity.abn.replace(/\s/g, '');

		if (field === 'abn' 
			&& businessEntity.abnFinishedValidating 
			&& businessEntity.errors['abn'] === undefined 
			&& parsedPreviousAbn !== parsedCurrentAbn) {
			const response = await axios.get<boolean>(
				`${SERVER_URL}/api/entity/RegistrationEntity/abn-exists/${businessEntity.abn}`);
			runInAction(() => {
				if (businessEntity) {
					businessEntity.name = businessEntity.entityName;  // Prefill organisation name
				}

				if (response.data && (type === 'organisation' || type === 'primaryBusinessEntity')) {
					businessEntity.errors[field] = 'This ABN is already registered with us';
				}
			});
		}

		// Handle optional fields (like email, business description, etc.)
		const fieldValue = (businessEntity as any)[field] || '';
		const optionalFields = ['email', 'summary'];

		if (optionalFields.includes(field) && (fieldValue.trim() === '')) {
			runInAction(() => {
				delete businessEntity.errors[field];
			});
		}

		setTimeout(() => {
			if (!!onAfterChange) {
				const validateSuccessful = Object.keys(businessEntity.errors).length <= 0;
				onAfterChange(validateSuccessful);
			}
		}, 100);
	};

	return (
		<div className="organisation-fields">
			<div>
				<IconTextBox
					className="business-number abn"
					model={businessEntity}
					modelProperty="abn"
					label="Australian Business Number (ABN)"
					placeholder="12 345 678 901"
					onChangeAndBlur={async () => {
						await onChange('abn', true);

						runInAction(() => {
							businessEntity.useCorporateTrusteeACN = '';
							businessEntity.trusteeACN = '';
							businessEntity.trusteeName = '';
							// @ts-ignore: settings dates to undefined
							businessEntity.dateOfBirth = null;
						});
					}}
					onAfterChange={debounce(async () => {
						await onChange('abn', true);

						runInAction(() => {
							businessEntity.useCorporateTrusteeACN = '';
							businessEntity.trusteeACN = '';
							businessEntity.trusteeName = '';
							// @ts-ignore: settings dates to undefined
							businessEntity.dateOfBirth = null;
						});
					}, 300)}
					errors={businessEntity.errors.abn}
					isRequired
					displayIconContainer={businessEntity.abnFinishedValidating}
					iconDisplay={businessEntity.abnFinishedValidating && businessEntity.entityStatus !== '' && businessEntity.entityStatus === 'Active'
						? IconDisplay.Valid : IconDisplay.Error}
					isDisabled={disabled}
				/>
				{businessEntity.entityName && (
					<small className="field-details">
						<b>Status:</b> { businessEntity.entityStatus }<br />
						<b>Type:</b> { businessEntity.entityTypeDescription }<br />
						<b>Entity Name:</b> { businessEntity.entityName }
					</small>
				)}
			</div>

			<TextField
				className="business-name"
				model={businessEntity}
				modelProperty="name"
				label={`${entityName} name`}
				placeholder="Acme Pty Ltd"
				onChangeAndBlur={async () => { await onChange('name', true); }}
				onAfterChange={async () => { await onChange('name'); }}
				errors={businessEntity.errors.name}
				isRequired
				isDisabled={disabled}
			/>

			{!quickRegister && (
				<div>
					<IconTextBox
						className="business-number acn"
						model={businessEntity}
						modelProperty="acn"
						label="Australian Company Number (ACN)"
						placeholder="123 456 789"
						onChangeAndBlur={async () => {
							await onChange('acn', true);
						}}
						onAfterChange={debounce(async () => {
							await onChange('acn');
						}, 300)}
						errors={businessEntity.errors.acn}
						displayIconContainer={businessEntity.acnFinishedValidating}
						iconDisplay={businessEntity.acnStatus !== '' && businessEntity.acnStatus === 'REGD'
							? IconDisplay.Valid : IconDisplay.Error}
						isDisabled={disabled}
					/>
					<small className="field-details">
						<b>Status:</b> { acnStatusOptions[businessEntity.acnStatus] }<br />
						<b>Type:</b> { acnTypeOptions[businessEntity.acnEntityTypeCode] }<br />
						<b>Entity Name:</b> { businessEntity.acnEntityName }
					</small>
				</div>
			)}

			{!quickRegister && (
				<TextField
					className="phone"
					model={businessEntity}
					modelProperty="phone"
					label="Business phone number"
					placeholder="1300 123 456"
					onChangeAndBlur={async () => { await onChange('phone', true); }}
					onAfterChange={async () => { await onChange('phone'); }}
					errors={businessEntity.errors.phone}
					isDisabled={disabled}
				/>
			)}

			{/* If we aren't the primary business entity render checkbox */}
			<If condition={
				type === 'nonPrimaryBusinessEntity'
				&& (
					!!primaryBusinessEntity
					&& !!primaryBusinessEntity.email
					&& primaryBusinessEntity.email !== ''
				)
			}
			>
				<Checkbox
					model={businessEntity}
					modelProperty="useOrganisationEmail"
					label="Same as Organisation email"
					className="business-entity-org-email-checkbox"
					onAfterChecked={action((event, checked) => {
						if (checked) {
							businessEntity.email = '';
							delete businessEntity.errors.email;
						}
					})}
					isDisabled={disabled}
				/>
			</If>

			{/* if the entity typecode is an individual show the date of birth */}
			<If condition={businessEntity.entityTypeCode !== '' && businessEntity.entityTypeCode === 'IND'}>
				<DatePicker
					model={businessEntity}
					modelProperty="dateOfBirth"
					label="Sole trader's date of birth"
					placeholder="Date of birth"
					onChangeAndBlur={() => onChange('dob', true)}
					onAfterChange={() => onChange('dob')}
					errors={businessEntity.errors.dob}
					isRequired
					isDisabled={disabled}
				/>
			</If>

			{/* if the entity typecode is a trust show the radio button */}
			<If condition={businessEntity.entityTypeCode !== ''
				&& TrustEntityCodesEntity.trusteeCodesSync.includes(businessEntity.entityTypeCode)}
			>
				<RadioButtonGroupSetter
					className="trusteeRadio"
					value={businessEntity.useCorporateTrusteeACN}
					setValue={newValue => {
						runInAction(() => {
							businessEntity.useCorporateTrusteeACN = newValue;
						});
					}}
					options={[
						{ value: 'Yes', display: 'Yes, I have an ACN' },
						{ value: 'No', display: 'No' },
					]}
					onAfterSet={() => {
						runInAction(() => {
							businessEntity.trusteeName = '';
							businessEntity.trusteeACN = '';
							businessEntity.trusteeACNStatus = '';
							// @ts-ignore
							businessEntity.abrLastUpdated = undefined;
							businessEntity.trusteeAcnFinishedValidating = false;
						});
					}}
					label="Do you have a trustee Australian Company Number (ACN)?"
					// the error on this radio button is if the user has pressed next before choosing an
					// option. so both trusteeName and trustee acn are empty meaning this radio button has an error
					errors={(businessEntity.errors.trusteeName === 'Trustee name cannot be empty'
						&& businessEntity.errors.trusteeACN === 'Trustee ACN cannot be empty'
						&& !businessEntity.useCorporateTrusteeACN ? 'An option must be selected' : undefined)}
					isRequired
					isDisabled={disabled}
				/>

				{/* display the ACN input if the radio button was yes */}
				<If condition={businessEntity.useCorporateTrusteeACN === 'Yes'}>
					<div>
						<IconTextBox
							className="business-number trusteeACN"
							model={businessEntity}
							modelProperty="trusteeACN"
							label="Trustee ACN"
							placeholder="Trustee ACN"
							onChangeAndBlur={action(async () => {
								await onChange('trusteeACN', true);
								runInAction(() => {
									delete businessEntity.errors.trusteeName;
								});
							})}
							onAfterChange={debounce(action(async () => {
								await onChange('trusteeACN');
								runInAction(() => {
									delete businessEntity.errors.trusteeName;
								});
							}), 300)}
							errors={businessEntity.errors.trusteeACN}
							isRequired
							displayIconContainer={businessEntity.trusteeAcnFinishedValidating
								&& !businessEntity.errors.trusteeACN}
							iconDisplay={IconDisplay.Valid}
							isDisabled={disabled}
						/>
						<small className="field-details">
							<b>Status:</b> { acnStatusOptions[businessEntity.trusteeACNStatus] }<br />
							<b>Type:</b> { acnTypeOptions[businessEntity.trusteeACNEntityTypeCode] }<br />
							<b>Entity Name:</b> { businessEntity.trusteeACNEntityName }
						</small>
					</div>
				</If>

				{/* display the trustee name input if the radio button was no */}
				<If condition={businessEntity.useCorporateTrusteeACN === 'No'}>
					<TextField
						className="trustee-name"
						model={businessEntity}
						modelProperty="trusteeName"
						label="Trustee name"
						placeholder="Trustee name"
						onChangeAndBlur={action(async () => {
							await onChange('trusteeName', true);
							runInAction(() => {
								delete businessEntity.errors.trusteeACN;
							});
						})}
						onAfterChange={action(async () => {
							await onChange('trusteeName');
							runInAction(() => {
								delete businessEntity.errors.trusteeACN;
							});
						})}
						errors={businessEntity.errors.trusteeName}
						isRequired
						isDisabled={disabled}
					/>
				</If>
			</If>

			<If condition={quickRegister}>
				<TextArea
					className="business-description"
					model={businessEntity}
					modelProperty="summary"
					label="Tell us a bit about your business"
					placeholder="Describe your business, industry, and the products you’re interested in."
					onChangeAndBlur={() => onChange('summary', true)}
					onAfterChange={() => onChange('summary')}
					errors={businessEntity.errors.summary}
					isDisabled={disabled}
				/>
			</If>
			{/* If user selects use organisation email, we disable the input and use primary business entity value */}
			<TextField
				className="email"
				model={businessEntity.useOrganisationEmail ? primaryBusinessEntity : businessEntity}
				modelProperty="email"
				label={`${entityName} email`}
				placeholder="hello@company.com"
				onChangeAndBlur={() => onChange('email', true)}
				onAfterChange={() => onChange('email')}
				errors={businessEntity.errors.email}
				isDisabled={disabled || businessEntity.useOrganisationEmail}
				isRequired={quickRegister}
			/>

			{!quickRegister && !!businessEntity.address && (
				<>
					<TextField
						className="line-1"
						label="Street address"
						model={businessEntity.address}
						modelProperty="line1"
						onChangeAndBlur={() => onChange('line1', true)}
						onAfterChange={() => onChange('line1')}
						isDisabled={disabled}
						placeholder="Main St"
					/>
					<TextField
						className="line-2"
						label="Unit, suite, or building"
						model={businessEntity.address}
						modelProperty="line2"
						onChangeAndBlur={() => onChange('line2', true)}
						onAfterChange={() => onChange('line2')}
						isDisabled={disabled}
						placeholder="Suite 2, Building 5"
					/>
					<TextField
						className="suburb half-width"
						label="Suburb"
						model={businessEntity.address}
						modelProperty="suburb"
						onChangeAndBlur={() => onChange('suburb', true)}
						onAfterChange={() => onChange('suburb')}
						isDisabled={disabled}
						placeholder="Brisbane"
					/>
					<TextField
						className="state half-width"
						label="State"
						model={businessEntity.address}
						modelProperty="state"
						onChangeAndBlur={() => onChange('state', true)}
						onAfterChange={() => onChange('state')}
						isDisabled={disabled}
						placeholder="QLD"
					/>
					<TextField
						className="postcode half-width"
						label="Postal code"
						model={businessEntity.address}
						modelProperty="postcode"
						errors={businessEntity.errors['address.postcode']}
						onAfterChange={() => onChange('address.postcode')}
						onChangeAndBlur={() => onChange('address.postcode', true)}
						isDisabled={disabled}
						placeholder="4000"
					/>
					<TextField
						className="country half-width"
						label="Country"
						model={businessEntity.address}
						modelProperty="country"
						onChangeAndBlur={() => onChange('country', true)}
						onAfterChange={() => onChange('country')}
						isDisabled={disabled}
						placeholder="Australia"
					/>
				</>
			)}
		</div>
	);
});

export default OrganisationFields;
