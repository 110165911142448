import * as React from 'react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { observer } from 'mobx-react';
import { OrganisationEntity } from 'Models/Entities';
import { runInAction } from 'mobx';
import { Combobox } from '../Combobox/Combobox';
import {
	referrerBenefitTypeRegistrationComboboxOptions,
	referrerBusinessTypeRegistrationComboboxOptions, referrerCommissionFrequencyComboboxOptions,
} from 'Models/Enums';
import { TextArea } from '../TextArea/TextArea';
import If from '../If/If';
import CharitySelector from '../ReferralPartners/CharitySelector';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface ReferralPartnerBusinessFieldsProps {
    organisation: OrganisationEntity,
    onAfterChange?: (validateSuccessful: boolean) => void,
    disabled?: boolean,
}

const ReferralPartnerBenefitsFields = observer((props: ReferralPartnerBusinessFieldsProps) => {
	const {
		organisation, onAfterChange, disabled,
	} = props;

	/**
     * Handle for the change event for the inputs. This method will attempt to validate a given field
     * iff there are existing errors. So that the user can immediately see when their input stops the error
     * @param field The name of the field to be validated
     * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
     * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
     */
	const onChange = async (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!organisation.errors[field]) {
			await organisation.validateField(field);
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(organisation.errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};

	return (
		<div className="benefits-fields">
			<Combobox
				className="business-type"
				label="What does your business do?"
				options={referrerBusinessTypeRegistrationComboboxOptions}
				model={organisation}
				modelProperty="referrerBusinessType"
				placeholder="Business type"
				onAfterChange={async () => {
					await onChange('businessType', true);
					// since this option effects other fields, we clear those fields to ensure no data is kept
					runInAction(() => {
						organisation.setFieldError('businessTypeOther', false, '');
						// @ts-ignore
						organisation.referrerBusinessOther = null;
					});
				}}
				isDisabled={disabled}
				searchable
				isRequired
				errors={organisation.errors.businessType}
			/>

			<If condition={organisation.referrerBusinessType === 'OTHER'}>
				<TextArea
					className="business-type-other"
					label="Brief summary of your business"
					model={organisation}
					modelProperty="referrerBusinessOther"
					placeholder="Summary"
					onChangeAndBlur={_ => onChange('businessTypeOther', true)}
					onAfterChange={_ => onChange('businessTypeOther')}
					isRequired
					isDisabled={disabled}
					errors={organisation.errors.businessTypeOther}
				/>
			</If>

			<Combobox
				className="benefit-type"
				label="What type of benefit would you like to receive?"
				options={referrerBenefitTypeRegistrationComboboxOptions}
				model={organisation}
				modelProperty="referrerBenefitType"
				placeholder="Which benefit"
				onAfterChange={async () => {
					await onChange('benefitType', true);
					// since this option effects other fields, we clear those fields to ensure no data is kept
					organisation.clearBenefitTypeFields();
				}}
				isDisabled={disabled}
				searchable
				isRequired
				errors={organisation.errors.benefitType}
			/>

			<If condition={organisation.referrerBenefitType === 'COMMISSION'}>
				<Combobox
					className="commission-frequency"
					label="How would you like to receive your commission?"
					options={referrerCommissionFrequencyComboboxOptions()}
					model={organisation}
					modelProperty="referrerCommissionFrequency"
					placeholder="How to receive your commission"
					onAfterChange={_ => onChange('commissionFrequency', true)}
					isDisabled={disabled}
					searchable
					isRequired
					errors={organisation.errors.commissionFrequency}
				/>

				<TextField
					className="account-name"
					model={organisation}
					modelProperty="referrerBankAccountName"
					label="Account name"
					placeholder="i.e. John Smith"
					onChangeAndBlur={_ => onChange('accountName', true)}
					onAfterChange={_ => onChange('accountName')}
					isRequired
					isDisabled={disabled}
					errors={organisation.errors.accountName}
				/>

				<TextField
					className="bsb"
					model={organisation}
					modelProperty="referrerBSB"
					label="BSB"
					placeholder="Valid BSB number"
					onChangeAndBlur={_ => onChange('bsb', true)}
					onAfterChange={_ => onChange('bsb')}
					isRequired
					isDisabled={disabled}
					errors={organisation.errors.bsb}
				/>

				<TextField
					className="account-number"
					model={organisation}
					modelProperty="referrerBankAccountNumber"
					label="Account number"
					placeholder="Valid account number"
					onChangeAndBlur={_ => onChange('accountNumber', true)}
					onAfterChange={_ => onChange('accountNumber')}
					isRequired
					isDisabled={disabled}
					errors={organisation.errors.accountNumber}
				/>
			</If>

			<If condition={organisation.referrerBenefitType === 'CHARITY'}>
				<CharitySelector
					className="charity"
					organisation={organisation}
					isDisabled={disabled}
					isRequired
					label="Which charity would you like to support?"
					onAfterChange={_ => onChange('charity', true)}
					errors={organisation.errors.charity}
				/>
			</If>
		</div>
	);
});

export default ReferralPartnerBenefitsFields;
