import * as React from 'react';
import { observer } from 'mobx-react';
import { AttributeCRUDOptions } from 'Models/CRUDOptions';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { BusinessEntity, RegistrationEntity, RegistrationFileEntity } from 'Models/Entities';
import { runInAction } from 'mobx';
import CustomAttributeFile from 'Views/Components/CRUD/Attributes/CustomAttributeFile';

export interface ApproveFilesManagementProps {
	businessEntity: BusinessEntity,

	// If not undefined, this is being called from a registration
	// In that case, we need to save the file against the registration, since the business entity doesn't exist yet
	registrationEntity?: RegistrationEntity,
}

const ApproveFilesManagement = observer((props: ApproveFilesManagementProps) => {
	const { businessEntity, registrationEntity } = props;

	return (
		<div className="attach-file-item">
			<CustomAttributeFile
				model={businessEntity.applicationss[0]}
				fileAttribute="logo"
				options={new AttributeCRUDOptions(
					'logoId',
					{
						name: 'Business logo',
						displayType: 'file',
					},
				)}
				formMode={EntityFormMode.EDIT}
				imageOnly
				onAfterChange={async () => {
					if (!!registrationEntity) {
						// Save the file to the database, then set the correct ID back here
						const fileEntity = new RegistrationFileEntity({
							registrationId: registrationEntity.id,
							fileId: businessEntity.applicationss[0].logoId,
							file: businessEntity.applicationss[0].logo,
						});
						await fileEntity.saveFromCrud(EntityFormMode.CREATE);

						runInAction(() => {
							businessEntity.applicationss[0].logoId = fileEntity.fileId;
							// @ts-ignore
							businessEntity.applicationss[0].logo = undefined; // toJSON ignores actual ID if this is set
						});
					}
				}}
			/>

			<CustomAttributeFile
				model={businessEntity.applicationss[0]}
				fileAttribute="termsAndConditions"
				options={new AttributeCRUDOptions(
					'termsAndConditionsId',
					{
						name: 'Terms and conditions',
						displayType: 'file',
					},
				)}
				formMode={EntityFormMode.EDIT}
				onAfterChange={async () => {
					if (!!registrationEntity) {
						// Save the file to the database, then set the correct ID back here
						const fileEntity = new RegistrationFileEntity({
							registrationId: registrationEntity.id,
							fileId: businessEntity.applicationss[0].termsAndConditionsId,
							file: businessEntity.applicationss[0].termsAndConditions,
						});
						await fileEntity.saveFromCrud(EntityFormMode.CREATE);

						runInAction(() => {
							businessEntity.applicationss[0].termsAndConditionsId = fileEntity.fileId;
							// @ts-ignore
							businessEntity.applicationss[0].termsAndConditions = undefined; // toJSON ignores actual ID if this is set
						});
					}
				}}
			/>

			<CustomAttributeFile
				model={businessEntity.applicationss[0]}
				fileAttribute="privacyPolicy"
				options={new AttributeCRUDOptions(
					'privacyPolicyId',
					{
						name: 'Privacy policy',
						displayType: 'file',
					},
				)}
				formMode={EntityFormMode.EDIT}
				onAfterChange={async () => {
					if (!!registrationEntity) {
						// Save the file to the database, then set the correct ID back here
						const fileEntity = new RegistrationFileEntity({
							registrationId: registrationEntity.id,
							fileId: businessEntity.applicationss[0].privacyPolicyId,
							file: businessEntity.applicationss[0].privacyPolicy,
						});
						await fileEntity.saveFromCrud(EntityFormMode.CREATE);

						runInAction(() => {
							businessEntity.applicationss[0].privacyPolicyId = fileEntity.fileId;
							// @ts-ignore
							businessEntity.applicationss[0].privacyPolicy = undefined; // toJSON ignores actual ID if this is set
						});
					}
				}}
			/>

		</div>
	);
});

export default ApproveFilesManagement;
