/* eslint-disable import/no-unresolved */
import copyTextToClipboard from 'Util/CopyTextToClipboard';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import Modal from 'Views/Components/Modal/Modal';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import React from 'react';

export type UserAuthTokenResponse = {
	userUrl: string;
	otpCode: string;
	hasAuthorised: boolean;
};

type Props = {
	userAuthTokenResponse: UserAuthTokenResponse;
	closeModal: () => void;
}

const ESignUserViewOtpModal: React.FunctionComponent<Props> = ({ userAuthTokenResponse, closeModal }) => {
	return (
		<Modal isOpen={!!userAuthTokenResponse} label="Generated Code">
			<div className="modal__content">
				<h4>Generated OTP Code
					<Tooltip
						id="otp-tooltip"
						content="Use generated One-Time Password to authorise the user"
						place="right"
					/>
				</h4>
				<div>
					<ul style={{ listStyleType: 'none' }}>
						<li>
							<strong>OTP Code:</strong>
							<Button
								display={Display.Text}
								colors={Colors.Primary}
								sizes={Sizes.Large}
								icon={{ icon: 'content-copy', iconPos: 'icon-right' }}
								onClick={() => {
									copyTextToClipboard(userAuthTokenResponse.otpCode);
								}}
							>
								{userAuthTokenResponse.otpCode}
							</Button>
						</li>
						<li>
							<strong>Authorisation Link:</strong>&nbsp;
							<Button
								display={Display.Text}
								colors={Colors.Primary}
								sizes={Sizes.Medium}
								icon={{ icon: 'content-copy', iconPos: 'icon-right' }}
								onClick={() => {
									copyTextToClipboard(userAuthTokenResponse.userUrl);
								}}
							>
								Login Url
							</Button>
						</li>
					</ul>
				</div>
				<div className="modal__actions">
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={closeModal}
					>
						Close
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ESignUserViewOtpModal;
