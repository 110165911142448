import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { OrganisationEntity } from 'Models/Entities';

type GqlOrganisationEntityResponse = {
	organisationEntitys: OrganisationEntity[];
}

export type RequestOrganisationArgs = {
	// Filter Organisation entities for primary entity name
	filterByName?: string;

	// Filter Organisation entities that have monitor enabled
	filterByMonitor?: boolean;
}

export async function requestOrganisation(args: RequestOrganisationArgs) {
	const { filterByName, filterByMonitor } = args;
	const whereCondtitions: IWhereCondition<any>[] = [];

	if (filterByName) {
		whereCondtitions.push({
			path: 'primaryBusinessEntity.name',
			comparison: 'like',
			value: [!!filterByName ? `%${filterByName}%` : '%'],
			case: 'CURRENT_CULTURE_IGNORE_CASE',
		});
	}

	if (filterByMonitor !== undefined) {
		whereCondtitions.push({
			path: 'intelEnabled',
			value: filterByMonitor ? 'true' : 'false',
			comparison: 'equal',
		});
	}

	const result = await store.apolloClient
		.query<GqlOrganisationEntityResponse>({
			query: gql`
                    query fetchOrganisations($whereConditions: [WhereExpressionGraph]) {
                        organisationEntitys (
                            where: $whereConditions,
                            orderBy: [{path: "primaryBusinessEntity.name", descending: false}],
                        ) {
                            id
                            primaryBusinessEntity {
                                id
                                name
                                enabledForMonitor
                            }
                        }
                    }
                `,
			variables: {
				whereConditions: whereCondtitions,
			},
			fetchPolicy: 'no-cache',
		});
	return result;
}
