import * as React from 'react';
import classNames from 'classnames';
import InputWrapper, { LabelPositions, InputType } from '../Inputs/InputWrapper';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { DisplayType } from '../Models/Enums';
import * as uuid from 'uuid';
import { Tooltip } from '../Tooltip/Tooltip';
import { RadioAlignment, RadioButtonGroup } from './RadioButtonGroup';

export interface IRadioButtonGroupSetterProps<T> {
	id?: string;
	/** The current value */
	value?: T;
	/** The setter to apply a new value */
	setValue?: ((newValue: T) => void);
	className?: string;
	name?: string;
	alignment?: RadioAlignment;
	displayType?: DisplayType;
	label?: string;
	tooltip?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	options: Array<{value: string, display: string}>;
	errors?: string | string[];
	/** Action to perform after the onChange method is called */
	onAfterSet?: (value: string) => void;
	/**
	 * Should the key for the fields be random uuids. This is useful for when there is a chance for non unique values.
	 * If this is not set or is false then the option field is used as the key.
	 */
	uuidKey?: boolean;
}

function RadioButtonGroupSetter<T>(props: IRadioButtonGroupSetterProps<T>) {
	const { value, setValue, onAfterSet } = props;

	// Setup an object to store the value on, so that the combobox can read it
	const valueParent = {
		value: value,
	};

	return (
		<RadioButtonGroup
			model={valueParent}
			modelProperty="value"
			onAfterChange={newValue => {
				if (!!setValue && !!valueParent.value) {
					setValue(valueParent.value);
				}

				if (!!onAfterSet) {
					onAfterSet(newValue);
				}
			}}
			{...props}
		/>
	);
}

export default RadioButtonGroupSetter;
