import ESignSigningStage from 'ESign/Types/ESignSigningStage';
import { ESignWitnessDetail } from 'ESign/Types/ESignWitnessDetail';
import ESignCeremonyType from 'ESign/Types/Enums/ESignCeremonyType';
import { ESignUserSigneeCeremony, ESignUserWitnessCeremony } from 'ESign/Types/Summary/ESignUserCeremony';
import { checkIfAllFieldsAreFilled } from './SigningContentHelper';
import { SigningDocumentContentDetail } from 'ESign/Components/ESignSigningSection';
import { isPhone } from 'Validators/Functions/Custom';
import { isEmail } from 'Validators/Functions/Email';
import moment from 'moment';

export const initialWitnessDetails = (
	selectedCeremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony | undefined,
): ESignWitnessDetail | undefined => {
	if (selectedCeremony === undefined) {
		return undefined;
	}

	const signeeType = selectedCeremony.type === ESignCeremonyType.SIGNEE;

	if (signeeType && selectedCeremony.workflow.templateContent.requireWitness) {
		const { witnessCeremony } = selectedCeremony;
		const requirePhone = selectedCeremony.workflow.templateContent.useSmsAuthentication ?? false;
		if (witnessCeremony === null || witnessCeremony === undefined) {
			return {
				name: '',
				email: '',
				phone: requirePhone ? '' : undefined,
			};
		}
		return {
			name: selectedCeremony.witnessCeremony.name ?? '',
			email: selectedCeremony.witnessCeremony.email ?? '',
			phone: selectedCeremony.witnessCeremony.phone,
		};
	}

	// witness details is not required for witness
	// witness details is redundant for signee when witness is not required
	return undefined;
};

export const initialStage = (
	selectedCeremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony | undefined,
): ESignSigningStage => {
	if (selectedCeremony === undefined) {
		return 'Review';
	}

	const expiryDate = moment(selectedCeremony.workflow.created)
		.add(selectedCeremony.workflow.template.lifeSpan, 'days');

	const currentDate = moment();
	if (expiryDate.isBefore(currentDate) || selectedCeremony.workflow.status === 'INCOMPLETE') {
		return 'Review';
	}

	if (selectedCeremony.status === 'VISITED' || selectedCeremony.status === 'SENT') {
		return 'Content';
	}

	return 'Review';
};

export const initialError = (
	selectedCeremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony | undefined,
	templateContent: string,
	witnessDetails?: ESignWitnessDetail,
) : Record<ESignSigningStage, boolean> => {
	const response: Record<ESignSigningStage, boolean> = {
		Content: false,
		Witness: false,
		Review: false,
	};

	if (selectedCeremony === undefined) {
		return response;
	}

	if (witnessDetails !== undefined) {
		if (selectedCeremony?.workflow.templateContent.useSmsAuthentication
			&& !isPhone(witnessDetails.phone ?? '')) {
			response.Witness = true;
		}

		if (witnessDetails.name.length <= 0) {
			response.Witness = true;
		}

		if (!isEmail(witnessDetails.email)) {
			response.Witness = true;
		}
	}

	// check if all fields are filled for the current user document
	const parsedDocumentContentDetail = JSON.parse(templateContent) as SigningDocumentContentDetail;
	const isSignee = selectedCeremony?.type === ESignCeremonyType.SIGNEE;
	const inputFieldsWithValue = parsedDocumentContentDetail.inputFieldWithValue;
	const currentUserUniqueElementId = isSignee
		? selectedCeremony?.id
		: selectedCeremony?.signeeCeremony?.id;

	const inputFieldValue = inputFieldsWithValue[currentUserUniqueElementId ?? ''];

	const hasCompleted = checkIfAllFieldsAreFilled(
		inputFieldValue,
		isSignee,
		currentUserUniqueElementId ?? '',
		inputFieldsWithValue,
	);

	return { ...response, Content: !hasCompleted };
};
