/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable max-len */
import * as React from 'react';
import { action } from 'mobx';
import * as AdminPages from './Pages/Admin/Entity';
import Auth from './Components/Auth/Auth';
import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from './Components/Topbar/Topbar';
import PageLinks from './Pages/Admin/PageLinks';
import StyleguidePage from './Pages/Admin/StyleguidePage';
import Spinner from 'Views/Components/Spinner/Spinner';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
/* eslint-disable semi */
/* eslint-disable indent */
/* eslint-disable react/jsx-no-useless-fragment */
// % protected region % [Add any extra imports here] end

// % protected region % [Customize lazy imports here] off begin
const GraphiQlLazy = React.lazy(() => import('./Pages/Admin/Graphiql'));
// % protected region % [Customize lazy imports here] end

export default class Admin extends React.Component<RouteComponentProps> {
	path = this.props.match.path === '/' ? '' : this.props.match.path;

	// % protected region % [Override componentDidMount here] off begin
	public componentDidMount() {
		this.setAppLocation();
	}
	// % protected region % [Override componentDidMount here] end

	// % protected region % [Override setAppLocation here] off begin
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}
	// % protected region % [Override setAppLocation here] end

	private adminSwitch = () => {
		// % protected region % [Override access control logic here] off begin
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Redirect to="/404" />;
		}
		// % protected region % [Override access control logic here] end

		return (
			<>
				{
				// % protected region % [Override contents here] off begin
				}
				<PageLinks {...this.props} />
				{
				// % protected region % [Override contents here] end
				}
				<div className="body-content">
					<Switch>
						{/* These routes require a login to view */}

						{/* Admin entity pages */}
						<Route exact path={`${this.path}`} component={AdminPage} />
						<Route path={`${this.path}/User`} component={AllUsersPage} />
						<Route path={`${this.path}/ActiveCustomerPricingTierEntity`} component={AdminPages.ActiveCustomerPricingTierEntityPage} />
						<Route path={`${this.path}/ActiveUserPricingTierEntity`} component={AdminPages.ActiveUserPricingTierEntityPage} />
						<Route path={`${this.path}/AddressEntity`} component={AdminPages.AddressEntityPage} />
						<Route path={`${this.path}/AlaresCategoryRankingEntity`} component={AdminPages.AlaresCategoryRankingEntityPage} />
						<Route path={`${this.path}/AlaresEntity`} component={AdminPages.AlaresEntityPage} />
						<Route path={`${this.path}/AlaresWatchlistNotificationEntity`} component={AdminPages.AlaresWatchlistNotificationEntityPage} />
						<Route path={`${this.path}/AlaresWebhookAlertEntity`} component={AdminPages.AlaresWebhookAlertEntityPage} />
						<Route path={`${this.path}/ApplicationEntity`} component={AdminPages.ApplicationEntityPage} />
						<Route path={`${this.path}/AtbFileEntity`} component={AdminPages.AtbFileEntityPage} />
						<Route path={`${this.path}/AtbRecordEntity`} component={AdminPages.AtbRecordEntityPage} />
						<Route path={`${this.path}/AtoDebtEntity`} component={AdminPages.AtoDebtEntityPage} />
						<Route path={`${this.path}/AtoFileEntity`} component={AdminPages.AtoFileEntityPage} />
						<Route path={`${this.path}/AtoRecordEntity`} component={AdminPages.AtoRecordEntityPage} />
						<Route path={`${this.path}/BusinessEntity`} component={AdminPages.BusinessEntityPage} />
						<Route path={`${this.path}/CreditBureauEntity`} component={AdminPages.CreditBureauEntityPage} />
						<Route path={`${this.path}/CreditReportEntity`} component={AdminPages.CreditReportEntityPage} />
						<Route path={`${this.path}/CustomerAuditEntity`} component={AdminPages.CustomerAuditEntityPage} />
						<Route path={`${this.path}/CustomerEntity`} component={AdminPages.CustomerEntityPage} />
						<Route path={`${this.path}/DiscountEntity`} component={AdminPages.DiscountEntityPage} />
						<Route path={`${this.path}/DocumentEntity`} component={AdminPages.DocumentEntityPage} />
						<Route path={`${this.path}/EsignceremonysigneeEntity`} component={AdminPages.EsignceremonysigneeEntityPage} />
						<Route path={`${this.path}/EsignceremonywitnessEntity`} component={AdminPages.EsignceremonywitnessEntityPage} />
						<Route path={`${this.path}/EsigntemplateEntity`} component={AdminPages.EsigntemplateEntityPage} />
						<Route path={`${this.path}/EsigntemplatecontentEntity`} component={AdminPages.EsigntemplatecontentEntityPage} />
						<Route path={`${this.path}/EsignuserEntity`} component={AdminPages.EsignuserEntityPage} />
						<Route path={`${this.path}/EsignworkflowEntity`} component={AdminPages.EsignworkflowEntityPage} />
						<Route path={`${this.path}/GroupEntity`} component={AdminPages.GroupEntityPage} />
						<Route path={`${this.path}/IndustryCodesEntity`} component={AdminPages.IndustryCodesEntityPage} />
						<Route path={`${this.path}/InvoiceEntity`} component={AdminPages.InvoiceEntityPage} />
						<Route path={`${this.path}/LogoEntity`} component={AdminPages.LogoEntityPage} />
						<Route path={`${this.path}/MonitoredEntity`} component={AdminPages.MonitoredEntityPage} />
						<Route path={`${this.path}/MonitoredentityauditEntity`} component={AdminPages.MonitoredentityauditEntityPage} />
						<Route path={`${this.path}/MonitoringProviderEntity`} component={AdminPages.MonitoringProviderEntityPage} />
						<Route path={`${this.path}/MonthlyExposurePricingTierEntity`} component={AdminPages.MonthlyExposurePricingTierEntityPage} />
						<Route path={`${this.path}/OptionHowDidYouHearEntity`} component={AdminPages.OptionHowDidYouHearEntityPage} />
						<Route path={`${this.path}/OptionReferrerCharityEntity`} component={AdminPages.OptionReferrerCharityEntityPage} />
						<Route path={`${this.path}/OrganisationEntity`} component={AdminPages.OrganisationEntityPage} />
						<Route path={`${this.path}/PaymentStatusEntity`} component={AdminPages.PaymentStatusEntityPage} />
						<Route path={`${this.path}/ProviderEntity`} component={AdminPages.ProviderEntityPage} />
						<Route path={`${this.path}/ProviderProductEntity`} component={AdminPages.ProviderProductEntityPage} />
						<Route path={`${this.path}/ProviderProductOrganisationEntity`} component={AdminPages.ProviderProductOrganisationEntityPage} />
						<Route path={`${this.path}/ProviderTypeEntity`} component={AdminPages.ProviderTypeEntityPage} />
						<Route path={`${this.path}/ReferralPartnerGroupEntity`} component={AdminPages.ReferralPartnerGroupEntityPage} />
						<Route path={`${this.path}/RegistrationEntity`} component={AdminPages.RegistrationEntityPage} />
						<Route path={`${this.path}/RegistrationFileEntity`} component={AdminPages.RegistrationFileEntityPage} />
						<Route path={`${this.path}/ReportRequestEntity`} component={AdminPages.ReportRequestEntityPage} />
						<Route path={`${this.path}/SmsTokenEntity`} component={AdminPages.SmsTokenEntityPage} />
						<Route path={`${this.path}/SpgEntity`} component={AdminPages.SpgEntityPage} />
						<Route path={`${this.path}/TrustEntityCodesEntity`} component={AdminPages.TrustEntityCodesEntityPage} />
						<Route path={`${this.path}/UserEntity`} component={AdminPages.UserEntityPage} />

						{
						// % protected region % [Add any extra page routes here] off begin
						}
						{
						// % protected region % [Add any extra page routes here] end
						}
					</Switch>
				</div>
				{
				// % protected region % [Add any admin footer content here] off begin
				}
				{
				// % protected region % [Add any admin footer content here] end
				}
			</>
		);
	}

	public render() {
		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] off begin
					}
					<Topbar currentLocation="admin" />
					{
					// % protected region % [Modify Topbar] end
					}
					<Switch>
						<Route path={`${this.path}/styleguide`}>
							<Auth {...this.props}>
								<div className="admin">
									<PageLinks {...this.props} />
								</div>
								<div className="frontend">
									<div className="body-content">
										<StyleguidePage {...this.props} />
									</div>
								</div>
							</Auth>
						</Route>
						<Route>
							<div className="admin">
								<Auth {...this.props}>
									<Switch>
										{
										// % protected region % [Modify top level admin routing here] off begin
										}
										<Route path={`${this.path}/graphiql`}>
											<React.Suspense fallback={<Spinner />}>
												<GraphiQlLazy />
											</React.Suspense>
										</Route>
										<Route component={this.adminSwitch} />
										{
										// % protected region % [Modify top level admin routing here] end
										}
									</Switch>
								</Auth>
							</div>
						</Route>
					</Switch>
				</div>
			</>
		);
	}
}
