import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import { action } from 'mobx';
import { SERVER_URL } from 'Constants';
import FileUpload from '../FileUpload/FileUpload';
import axios from 'axios';
import If from '../If/If';
import InlineSpinner from '../Spinner/InlineSpinner';
import alertToast from '../../../Util/ToastifyUtils';
import { saveAs } from 'file-saver';
import { buildBlob } from '../../../Util/CsvUtils';
import { LocaleCompareAsc } from '../../../Util/StringUtils';

export type ExportCustomerInformationModalProps = {
	onClose: () => void,
}

const RiskRatingsUploadModal = observer((props: ExportCustomerInformationModalProps) => {
	const {
		onClose,
	} = props;

	const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

	/**
	 * Saves the errors report to the user's file system
	 * @param errors A list of errors
	 * @param warnings A list of warnings
	 */
	const saveErrorsReport = (errors: string[], warnings: string[]) => {
		// initialise the errors and warnings columns
		const csvReport = [] as string[][];
		// set the column headings
		csvReport.push(['Detail']);
		const allErrors = [...errors, ...warnings].sort(LocaleCompareAsc);
		// add errors and warnings
		csvReport.push(...allErrors.map(x => [x]));
		// create the file blob and save to the user's file system
		const blob = buildBlob(csvReport, ',', '"');
		saveAs(blob, `errors-report-${moment(modalState.date).format('DD-MM-YYYY')}`);
	};

	/**
	 * Function to handle uploading the payment risk ratings
	 */
	const submit = async () => {
		// ensure a date is set and file is uploaded
		if (!modalState.date || !modalState.file) {
			return;
		}
		// create the form data for the endpoint
		const formData = new FormData();
		formData.append('csvFile', modalState.file);
		// upload the risk ratings
		axios.post(`${SERVER_URL}/api/AgedDebtData/upload-risk-ratings`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response: {
				data: {
					errors?: string[],
					warnings?: string[],
				}
			}) => {
				// if there are errors, raise an error toast and save the report
				// if there are no errors and warnings, raise a warning toast, save the report and close the modal
				// if there are no errors and no warnings, raise a success toast and close the modal
				if (!!response.data.errors && response.data.errors.length > 0) {
					alertToast('There were errors within the uploaded CSV, please see the downloaded file',
						'error');
					saveErrorsReport(response.data.errors, response.data.warnings ?? []);
				} else if (!!response.data.warnings && response.data.warnings.length > 0) {
					alertToast('The risk ratings were successfully updated, but there were warnings please see '
						+ 'the downloaded file', 'warning');
					saveErrorsReport(response.data.errors ?? [], response.data.warnings ?? []);
					onClose();
				} else {
					alertToast('The risk ratings were successfully updated', 'success');
					onClose();
				}
			})
			.catch(() => {
				// alert error toast if exception was raised
				alertToast('The risk ratings could not be updated', 'error');
			})
			.finally(() => {
				// hide the loading spinner as the request is finished
				setShowLoadingSpinner(false);
			});
		// show the loading spinner whilst the request is being made
		setShowLoadingSpinner(true);
	};

	const modalState = useLocalStore(() => ({
		date: undefined as Date | undefined,
		file: undefined as File | undefined,
	}));

	return (
		<Modal
			isOpen
			label="Upload payment risk ratings"
			onRequestClose={onClose}
			className="access-modal upload-risk-ratings-modal"
		>
			<If condition={!showLoadingSpinner}>
				<h4>Upload payment risk ratings</h4>
				<p>
					Choose for which month and year you are uploading the payment risk ratings
				</p>
				<DatePicker
					model={modalState}
					modelProperty="month"
					placeholder="Select month"
					flatpickrProps={{
						options: {
							plugins: [
								new (monthSelectPlugin as any)({
									shorthand: true,
									dateFormat: 'Z',
									altFormat: 'F Y',
								}),
							],
							altInput: true,
							maxDate: moment().utc().subtract(1, 'months').toDate(),
						},
					}}
					label="Month and year"
					labelVisible
					isRequired
					onAfterChange={action((dateList, dateString) => {
						// Recalculate the date from the date string, since we just want the month and year, and don't want
						// the timezone to affect it.
						modalState.date = moment.utc(dateString).toDate();
					})}
				/>
				<FileUpload
					model={modalState}
					modelProperty="file"
					contentType=".csv"
					label="Payment Risk Ratings CSV*"
					labelVisible
					preview
					onDelete={action(() => {
						modalState.file = undefined;
					})}
				/>
			</If>
			<If condition={showLoadingSpinner}>
				<InlineSpinner />
			</If>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button
					key="confirm"
					onClick={submit}
					display={Display.Solid}
					disabled={!modalState.date || !modalState.file}
				>
					Upload CSV
				</Button>
			</div>
		</Modal>
	);
});
export default RiskRatingsUploadModal;
