import { state } from 'Models/Enums';
import { LiquorLicenceDetails } from 'Types/liquorLicenceValidationResponse';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import EntityList from 'Views/Components/EntityList/EntityList';
import Modal from 'Views/Components/Modal/Modal';
// eslint-disable-next-line object-curly-newline
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

interface Props {
	openModal: boolean;
	closeModal: () => void;
	liquorLicenceNumber: string;
	liquorLicenceState: state;
	previousUniqueId: string | undefined;
	updateLiquorLicenceDetails: (uniqueId: string) => void;
	liquorLicenceDetails?: {
		data: Record<string, LiquorLicenceDetails>;
		responseCode: number;
		responseMessage: string
	};
}

const LiquorLicenceDetailsTable: React.FunctionComponent<Props> = observer(({
	updateLiquorLicenceDetails,
	liquorLicenceDetails,
	liquorLicenceNumber,
	liquorLicenceState,
	previousUniqueId,
	openModal,
	closeModal,
}) => {
	const availableLiquorLicenceDetails = React.useMemo(() => {
		if (liquorLicenceDetails === undefined) {
			return {
				liquorLicenceDetails: [],
				selectedUniqueId: undefined,
			};
		}
		const parsedLicenceDetails: Record<string, LiquorLicenceDetails> = liquorLicenceDetails.data;

		let selectedUniqueId: string | undefined;
		const keys = Object.keys(parsedLicenceDetails);
		keys.forEach(key => {
			const [uniqueId, isSelected] = key.split('::');
			if (isSelected === 'TRUE') {
				selectedUniqueId = uniqueId;
			}
		});

		const unsortedLicenceDetails = Object.values(parsedLicenceDetails);

		const sortedLicenceDetails = unsortedLicenceDetails.sort((a, b) => {
			const statusA = a.tradingName.toUpperCase();
			const statusB = b.tradingName.toUpperCase();

			if (statusA < statusB) {
				return -1;
			}

			if (statusA > statusB) {
				return 1;
			}

			return 0;
		});

		return {
			liquorLicenceDetails: sortedLicenceDetails,
			selectedUniqueId: selectedUniqueId?.toUpperCase() ?? undefined,
		};
	}, [liquorLicenceDetails]);

	// eslint-disable-next-line max-len
	const [selectedUniqueId, setSelectedUniqueId] = React.useState<string | undefined>(availableLiquorLicenceDetails.selectedUniqueId ?? previousUniqueId);

	return (
		// eslint-disable-next-line max-len
		<Modal className="liquor-licence-details" isOpen={openModal} label="Liquor Licence Details">
			<h4>Licence Details for {liquorLicenceNumber} ({liquorLicenceState})</h4>
			<div className="collection-component">
				<EntityList
					collection={availableLiquorLicenceDetails.liquorLicenceDetails as LiquorLicenceDetails[]}
					columns={[
						{
							displayName: 'Trading Name',
							columnName: 'tradingName',
							value: (item: LiquorLicenceDetails) => item.tradingName,
							sortable: false,
							className: 'field-trading',
						},
						{
							displayName: 'Status',
							columnName: 'status',
							value: (item: LiquorLicenceDetails) => item.status,
							sortable: false,
							className: 'field-status',
						},
						{
							displayName: 'Channel',
							columnName: 'channel',
							value: (item: LiquorLicenceDetails) => item.channel,
							sortable: false,
							className: 'field-channel',
						},
						{
							displayName: 'Sub Channel',
							columnName: 'subChannel',
							value: (item: LiquorLicenceDetails) => item.subChannel,
							sortable: false,
							className: 'field-sub',
						},
						{
							displayName: 'Suburb',
							columnName: 'suburb',
							value: (item: LiquorLicenceDetails) => item.suburb,
							sortable: false,
							className: 'field-suburb',
						},
						{
							displayName: 'Postcode',
							columnName: 'postcode',
							value: (item: LiquorLicenceDetails) => item.postcode,
							sortable: false,
							className: 'field-postcode',
						},
						{
							displayName: 'Date Created',
							columnName: 'dateCreated',
							// eslint-disable-next-line max-len
							value: (item: LiquorLicenceDetails) => item.dateCreated ? moment(item.dateCreated).format('Do MMM YYYY') : 'N/A',
							sortable: false,
							className: 'field-created',
						},
						{
							displayName: 'Date Closed',
							columnName: 'dateClosed',
							// eslint-disable-next-line max-len
							value: (item: LiquorLicenceDetails) => item.dateClosed ? moment(item.dateClosed).format('Do MMM YYYY') : 'N/A',
							sortable: false,
							className: 'field-closed',
						},
					]}
					onClickRow={(item: LiquorLicenceDetails) => { setSelectedUniqueId(item.uniqueId); }}
					idColumn="uniqueId"
					sortColumn="status"
					sortDescending={false}
					rowClassName={(item: LiquorLicenceDetails) => {
						let classNames = '';
						if (item.uniqueId === selectedUniqueId) {
							classNames += ' selected';
						}

						if (['SURRENDERED', 'SUSPENDED', 'REMOVAL'].includes(item.status.toUpperCase())) {
							classNames += ' danger';
						}

						return classNames;
					}}
				/>
			</div>
			<div className="footer">
				<Button
					colors={Colors.Error}
					display={Display.Text}
					onClick={closeModal}
				>
					Close
				</Button>
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					// eslint-disable-next-line max-len
					disabled={selectedUniqueId === undefined || selectedUniqueId.trim().length === 0 || selectedUniqueId === previousUniqueId}
					onClick={() => {
						updateLiquorLicenceDetails(selectedUniqueId ?? '');
						setSelectedUniqueId(selectedUniqueId);
						closeModal();
					}}
				>
					Confirm
				</Button>
			</div>
		</Modal>
	);
});

export default LiquorLicenceDetailsTable;
