import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import { action } from 'mobx';
import { SERVER_URL } from 'Constants';
import { saveAs } from 'file-saver';

export type ExportCustomerInformationModalProps = {
	onClose: () => void,
}

const ExportCustomerInformationModal = observer((props: ExportCustomerInformationModalProps) => {
	const {
		onClose,
	} = props;

	const modalState = useLocalStore(() => ({
		date: undefined as Date|undefined,
	}));

	const submit = () => {
		if (!modalState.date) {
			return;
		}
		// Open the aged debt data collation and creditor information lists in new tabs so that the files can be saved
		// to the file system
		saveAs(
			`${SERVER_URL}/api/AgedDebtData/export/${moment(modalState.date).format('DD-MM-YYYY')}`,
		);
		saveAs(
			`${SERVER_URL}/api/AgedDebtData/export-creditor-information/${moment(modalState.date)
				.format('DD-MM-YYYY')}`,
		);
		onClose();
	};

	return (
		<Modal
			isOpen
			label="Export customer information"
			onRequestClose={onClose}
			className="access-modal export-customer-information-modal"
		>
			<h4>Export customer information</h4>
			<p>
				Choose for which month and year you need to export all your customer data
			</p>
			<DatePicker
				model={modalState}
				modelProperty="month"
				placeholder="Select month"
				isRequired
				flatpickrProps={{
					options: {
						plugins: [
							new (monthSelectPlugin as any)({
								shorthand: true,
								dateFormat: 'Z',
								altFormat: 'F Y',
							}),
						],
						altInput: true,
						maxDate: moment().utc().subtract(1, 'months').toDate(),
					},
				}}
				label="Month and year"
				labelVisible
				onAfterChange={action((dateList, dateString) => {
					// Recalculate the date from the date string, since we just want the month and year, and don't want
					// the timezone to affect it.
					modalState.date = moment.utc(dateString).toDate();
				})}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button
					key="confirm"
					onClick={submit}
					display={Display.Solid}
					disabled={!modalState.date}
				>
					Export CSV
				</Button>
			</div>
		</Modal>
	);
});
export default ExportCustomerInformationModal;
