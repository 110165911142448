/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserSpgEntity from 'Models/Security/Acl/UserSpgEntity';
import VisitorsSpgEntity from 'Models/Security/Acl/VisitorsSpgEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import type { Errors } from '../../Util/CustomTypes';
import { isPhone } from '../../Validators/Functions/Custom';
import { isEmail } from '../../Validators/Functions/Email';
// % protected region % [Add any further imports here] end

export interface ISpgEntityAttributes extends IModelAttributes {
	contactName: string;
	organisationalNumbers: string;
	spgNumber: string;
	phone: string;
	accessCode: string;
	isActive: boolean;
	legacyID: string;
	email: string;
	spgName: string;

	businessEntityId?: string;
	businessEntity?: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SpgEntity', 'SPG')
// % protected region % [Customise your entity metadata here] end
export default class SpgEntity extends Model
	implements ISpgEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserSpgEntity(),
		new VisitorsSpgEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Contact Name'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Contact Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Organisational Numbers'] off begin
	/**
	 * Comma seperated string of organisation numbers
	 */
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Organisational Numbers',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public organisationalNumbers: string;
	// % protected region % [Modify props to the crud options here for attribute 'Organisational Numbers'] end

	// % protected region % [Modify props to the crud options here for attribute 'SPG Number'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'SPG Number',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public spgNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'SPG Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Access Code'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Access Code',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public accessCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Access Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	@observable
	@attribute<SpgEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] off begin
	@Validators.Unique()
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Legacy ID',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public legacyID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'SPG Name'] off begin
	@observable
	@attribute<SpgEntity, string>()
	@CRUD({
		name: 'SPG Name',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public spgName: string;
	// % protected region % [Modify props to the crud options here for attribute 'SPG Name'] end

	/**
	 * PPSR SPGs
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@action public validateField = async (field: string) => {
		switch (field) {
			case 'phone': {
				this.setFieldError('phone', !!this.phone && !isPhone(this.phone), 'Phone number must be valid');
				break;
			}
			case 'email': {
				this.setFieldError('email', !!this.email && !isEmail(this.email), 'Email address must be valid');
				break;
			}
		}
	}

	public validateAllFields = async () => {
		const fieldList = ['phone', 'email'];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	}
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISpgEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISpgEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.contactName !== undefined) {
				this.contactName = attributes.contactName;
			}
			if (attributes.organisationalNumbers !== undefined) {
				this.organisationalNumbers = attributes.organisationalNumbers;
			}
			if (attributes.spgNumber !== undefined) {
				this.spgNumber = attributes.spgNumber;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.accessCode !== undefined) {
				this.accessCode = attributes.accessCode;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.legacyID !== undefined) {
				this.legacyID = attributes.legacyID;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.spgName !== undefined) {
				this.spgName = attributes.spgName;
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public validateDetailsFields = async () => {
		const fieldList = [
			'phone', 'email',
		];
		const validationPromises = fieldList.map(field => this.validateField(field));
		await Promise.all(validationPromises);
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SpgEntity.prototype, 'created');
CRUD(modifiedAttr)(SpgEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
