import React from 'react';
import { Icon } from 'semantic-ui-react';

const ESignNavbar: React.FunctionComponent = () => {
	return (
		<div className="nav nav--expanded nav--horizontal esign-navbar">
			<div className="access-group-logo" />
			<div className="nav--item">
				<Icon name="bell outline" className="nav--icon" size="big" />
				<Icon name="user circle outline" className="nav--icon" size="big" />
			</div>

		</div>
	);
};

export default ESignNavbar;
