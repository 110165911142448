import React from 'react';
import classNames from 'classnames';

export interface ProductSelectedBoxProps {
	ppsrEnabled: boolean,
	approveEnabled: boolean,
	intelEnabled: boolean,
	isReferrer?: boolean,
	greyOutOnDisable?: boolean,
	hideNoneSelected?: boolean,
}

const ProductSelectedBox = (props: ProductSelectedBoxProps) => {
	const {
		ppsrEnabled, approveEnabled, intelEnabled, greyOutOnDisable, hideNoneSelected, isReferrer,
	} = props;

	return (
		<div className="product-selected-boxes">
			{intelEnabled || greyOutOnDisable
				? (
					<span className={classNames('product-selected-box intel', { disabled: !intelEnabled })}>
						Monitor
					</span>
				) : null}

			{approveEnabled || greyOutOnDisable
				? (
					<span className={classNames('product-selected-box approve', { disabled: !approveEnabled })}>
						Approve
					</span>
				) : null}

			{ppsrEnabled || greyOutOnDisable
				? (
					<span className={classNames('product-selected-box ppsr', { disabled: !ppsrEnabled })}>
						PPSR
					</span>
				) : null}

			{isReferrer
				? (
					<span className={classNames('product-selected-box rp')}>
						RP
					</span>
				) : null}

			{!ppsrEnabled && !approveEnabled && !intelEnabled && !isReferrer && !hideNoneSelected
				? (<span>None Selected</span>) : null}
		</div>
	);
};
export default ProductSelectedBox;
