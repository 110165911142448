import * as React from 'react';
import { observer } from 'mobx-react';
import {
	CreditBureauEntity,
	OrganisationAuthorisedCreditBureau,
	RegistrationEntity,
	TrustEntityCodesEntity,
} from 'Models/Entities';
import RegistrationStatusBox from 'Views/Components/Registration/RegistrationStatusBox';
import { registrationStepNames } from 'Views/Pages/OrganisationRegistrationPage';
import If from 'Views/Components/If/If';
import { CheckboxGroup } from 'Views/Components/Checkbox/CheckboxGroup';
import CheckboxSetter from 'Views/Components/Checkbox/CheckboxSetter';
import { monthlyExposureComboboxOptions } from 'Util/PricingHelper';
import moment from 'moment';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface RegistrationSummaryProps {
	registrationEntity: RegistrationEntity,
}

const RegistrationSummary = observer((props: RegistrationSummaryProps) => {
	const {
		registrationEntity,
	} = props;
	const registrationData = registrationEntity.parsedRegistrationData;

	const renderCheckboxes = () => {
		return CreditBureauEntity.creditBureausSync.map(bureau => {
			if (registrationData.organisation === undefined) {
				return null;
			}

			return (
				<CheckboxSetter
					value={registrationData.organisation.authorisedCreditBureaus.some(
						(x: OrganisationAuthorisedCreditBureau) => x.authorisedCreditBureauId === bureau.id,
					)}
					label={bureau.name}
					key={bureau.id}
					isDisabled
				/>
			);
		});
	};

	const registrationBusinessEntity = registrationData?.businessEntitys?.length
		? registrationData.businessEntitys[0]
		: undefined;

	return (
		<div className="registration-summary">
			<div className="information-box bold">
				<p className="information-title">Registration Status</p>
				<RegistrationStatusBox status={registrationEntity.status} />
			</div>
			<div className="information-box bold">
				<p className="information-title">Current Step</p>
				<p className="information-contents current-step">
					{
						!!registrationData.page
						&& registrationStepNames[registrationData.page]
					}
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">Products</p>
				<div className="products-wrap">
					<If condition={registrationData.organisation?.approveEnabled}>
						<span className="product-tag approve-product">Approve</span>
					</If>
					<If condition={registrationData.organisation?.ppsrEnabled}>
						<span className="product-tag ppsr-product">PPSR</span>
					</If>
					<If condition={registrationData.organisation?.intelEnabled}>
						<span className="product-tag intel-product">Monitor</span>
					</If>
				</div>
			</div>

			<h6 className="registration-summary-section-header">Personal information</h6>
			<div className="information-box">
				<p className="information-title">First name</p>
				<p className="information-contents first-name">
					{ registrationData?.user?.firstName || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">Last name</p>
				<p className="information-contents last-name">
					{ registrationData?.user?.lastName || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box full-width">
				<p className="information-title">Email address</p>
				<p className="information-contents email">
					{ registrationData?.user?.email || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box full-width">
				<p className="information-title">Mobile number</p>
				<p className="information-contents user-phone">
					{ registrationData?.user?.phone || <span className="not-provided">Not Provided</span> }
				</p>
			</div>

			<h6 className="registration-summary-section-header">Business information</h6>
			<div className="information-box">
				<p className="information-title">Organisation name</p>
				<p className="information-contents organisation-name">
					{ registrationBusinessEntity?.name || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">Business description</p>
				<p className="information-contents business-decription">
					{ registrationBusinessEntity?.summary || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">ABN</p>
				<p className="information-contents abn">
					{ registrationBusinessEntity?.abn || <span className="not-provided">Not Provided</span> }<br />
				</p>
				<small className="field-details">
					<b>Status:</b> { registrationBusinessEntity?.entityStatus || '-' }<br />
					<b>Type:</b> { registrationBusinessEntity?.entityTypeDescription || '-' }<br />
					<b>Entity Name:</b> { registrationBusinessEntity?.entityName || '-' }
				</small>
			</div>
			<div className="information-box">
				<p className="information-title">ACN</p>
				<p className="information-contents acn">
					{ registrationBusinessEntity?.acn || <span className="not-provided">Not Provided</span> }<br />
				</p>
				<small className="field-details">
					<b>Status:</b> { registrationBusinessEntity?.acnStatus || '-' }<br />
					<b>Type:</b> { registrationBusinessEntity?.acnEntityTypeCode || '-' }<br />
					<b>Entity Name:</b> { registrationBusinessEntity?.acnEntityName || '-' }
				</small>
			</div>
			<div className="information-box">
				<p className="information-title">Postal address</p>
				<p className="information-contents address">
					{ registrationBusinessEntity?.address?.getDisplayName()
						? registrationBusinessEntity.address.getDisplayName()
						: <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">{
					(registrationBusinessEntity?.trusteeACN ? 'Trustee ACN' : null)
					|| (registrationBusinessEntity?.trusteeName ? 'Trustee Name' : null)
				}
				</p>
				<p className="information-contents trusteeDetails">
					{ registrationBusinessEntity?.trusteeACN
						|| registrationBusinessEntity?.trusteeName}
				</p>
				<If condition={registrationBusinessEntity?.trusteeACN !== ''}>
					<small className="field-details">
						<b>Status:</b> { registrationBusinessEntity?.acnStatus || '-' }<br />
						<b>Type:</b> { registrationBusinessEntity?.acnEntityTypeCode || '-' }<br />
						<b>Entity Name:</b> { registrationBusinessEntity?.acnEntityName || '-' }
					</small>
				</If>
			</div>
			<div className="information-box">
				<p className="information-title">Phone number</p>
				<p className="information-contents organisation-phone">
					{ registrationBusinessEntity?.phone || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<If condition={registrationBusinessEntity?.entityTypeCode === 'IND'}>
					<p className="information-title">Sole trader&apos;s date of birth</p>
					<p className="information-contents dateOfBirth">
						{ registrationBusinessEntity?.dateOfBirth
							? moment(registrationBusinessEntity.dateOfBirth).format('YYYY-MM-DD')
							: <span className="not-provided">Not Provided</span> }
					</p>
				</If>
			</div>
			<div className="information-box">
				<p className="information-title">Number of active customers</p>
				<p className="information-contents active-customers">
					{ registrationData?.activeCustomers || <span className="not-provided">Not Provided</span> }
				</p>
			</div>
			<div className="information-box">
				<p className="information-title">Monthly exposure value</p>
				<p className="information-contents monthly-exposure">
					{ registrationData?.monthlyExposure
						? monthlyExposureComboboxOptions
							.find(x => x.value === registrationData.monthlyExposure)?.display
						: <span className="not-provided">Not Provided</span> }
				</p>
			</div>

			<If condition={registrationData.organisation?.intelEnabled === true}>
				<h6 className="registration-summary-section-header">Authorised applications</h6>
				<CheckboxGroup
					label="Authorised applications"
					className="authorised-applications"
				>
					{renderCheckboxes()}
				</CheckboxGroup>
			</If>
		</div>
	);
});
export default RegistrationSummary;
