import * as React from 'react';
import { Party } from 'Models/Interfaces/AlaresReport';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import AdverseAlertsTable from './AdverseAlertsTable';
import moment from 'moment';
import If from 'Views/Components/If/If';
import { IEntityListHeaderProps } from 'Views/Components/EntityList/EntityList';
import { useMemo } from 'react';
import { UpdateTableType } from './AdverseAlertsInsolvencies';

export interface AdverseAlertsUpdateSummaryModalProps {
	update: UpdateTableType,
	onClose: () => void,
}

const AdverseAlertsUpdateSummaryModal = (props: AdverseAlertsUpdateSummaryModalProps) => {
	const { update, onClose } = props;

	const partyColumns = useMemo<IEntityListHeaderProps<Party>[]>(() => {
		if (!!update.adjudication) {
			return [
				{
					displayName: 'Name',
					columnName: 'name',
					value: party => party.name,
					sortable: false,
					className: 'field-name',
				},
				{
					displayName: 'Role',
					columnName: 'role',
					value: party => party.role,
					sortable: false,
					className: 'field-role',
				},
				{
					displayName: 'ABN',
					columnName: 'abn',
					value: party => party.abn,
					sortable: false,
					className: 'field-abn',
				},
				{
					displayName: 'ACN',
					columnName: 'acn',
					value: party => party.acn,
					sortable: false,
					className: 'field-acn',
				},
			];
		}

		if (!!update.insolvency) {
			return [
				{
					displayName: 'Name',
					columnName: 'name',
					value: party => party.name,
					sortable: false,
					className: 'field-name',
				},
				{
					displayName: 'ACN',
					columnName: 'acn',
					value: party => party.acn,
					sortable: false,
					className: 'field-acn',
				},
				{
					displayName: 'URL',
					columnName: 'url',
					value: party => {
						if (!party.url || party.url.includes('alares')) {
							return null;
						}
						return (
							<a href={party.url} target="_blank" rel="noreferrer">
								Published notice
							</a>
						);
					},
					sortable: false,
					className: 'field-acn',
				},
			];
		}

		return [];
	}, [update]);

	return (
		<Modal
			isOpen
			label={update.dataType === 'insolvency' ? 'Insolvency notice' : 'Adjudication'}
			onRequestClose={onClose}
			className="access-modal adverse-alerts-details-modal"
		>
			<div className="top-row">
				<h4>{update.dataType === 'insolvency' ? 'Insolvency notice' : 'Adjudication'}</h4>
			</div>

			<div className="content">
				<div className="fields">
					<p className="field"><b>Court name:</b> {!update.courtName ? 'N/A' : update.courtName}</p>
					<p className="field"><b>Case type:</b> {!update.caseType ? 'N/A' : update.caseType}</p>
					<p className="field"><b>Case number:</b> {!update.caseNumber ? 'N/A' : update.caseNumber}</p>
					<p className="field"><b>Case name:</b> {!update.caseName ? 'N/A' : update.caseName}</p>
					<If condition={!!update.insolvency}>
						<p className="field"><b>ASIC notice ID:</b> {
							!update.insolvency?.asic_notice_id
								? 'N/A'
								: update.insolvency?.asic_notice_id
						}
						</p>
					</If>
					<p className="field">
						<b>Notification date:</b> {!update.notificationTime
							? 'N/A'
							: moment(update.notificationTime).format('Do MMMM YYYY')}
					</p>
				</div>
				<AdverseAlertsTable
					tableName="Parties"
					collection={update.parties ?? []}
					columns={partyColumns}
					sortColumn="start_date"
					sortDescending
					idColumn="name"
				/>
			</div>

			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
			</div>
		</Modal>
	);
};

export default AdverseAlertsUpdateSummaryModal;
