import { DependencyList, useEffect } from 'react';

export default function useInterval(callback: () => void, delay: number, deps?: DependencyList) {
	useEffect(() => {
		const interval = setInterval(callback, delay);

		return () => clearInterval(interval);

	// Disabled eslint rule because we want to run the effect only when provided dependencies change
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}
