import React from 'react';
import {
	registrationStatus, registrationStatusOptions,
} from 'Models/Enums';
import classNames from 'classnames';

export interface RegistrationStatusBoxProps {
	status: registrationStatus,
}

const RegistrationStatusBox = (props: RegistrationStatusBoxProps) => {
	const { status } = props;

	return (
		<span className={classNames('registration-status-box', status.toLowerCase())}>
			{registrationStatusOptions[status]}
		</span>
	);
};
export default RegistrationStatusBox;
