import {
	useState, useEffect, useCallback, useMemo,
} from 'react';
import { debounce } from 'lodash';

export default function useHasChanged<T>(
	EntityClass: any,
	entity: T,
	fields: string[],
	comparator: (oldValue: any, newValue: any) => boolean,
	extraFieldDependencies: string[],
	extraEquivalenceCheck?: (copy: T, original: T) => boolean,
) {
	const copyEntity = useMemo(() => new EntityClass(entity), [entity, EntityClass]);
	const [equivalent, setEquivalent] = useState(true);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkEquality = useCallback(
		debounce(() => {
			setEquivalent((!!extraEquivalenceCheck ? extraEquivalenceCheck(copyEntity, entity) : true) || !fields
				.find(field => comparator(getFieldIfExists(field, copyEntity), getFieldIfExists(field, entity))));
		}, 300),
		[copyEntity, entity],
	);

	function getFieldIfExists(field: string, ent: T) {
		if (ent && field in ent) {
			return ent[field];
		}

		return null;
	}

	// we enable save whenever something has changed, so we use a useEffect to do this watching on the specific fields
	useEffect(() => {
		checkEquality();
	}, [
		copyEntity, entity, checkEquality, extraEquivalenceCheck,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...fields.map(f => getFieldIfExists(f, copyEntity)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...fields.map(f => getFieldIfExists(f, entity)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...extraFieldDependencies.map(f => getFieldIfExists(f, entity)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...extraFieldDependencies.map(f => getFieldIfExists(f, copyEntity)),
	]);

	return equivalent;
}
