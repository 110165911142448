import moment from 'moment/moment';
import * as React from 'react';
import { Case } from 'Models/Interfaces/AlaresReport';
import { useCallback, useState } from 'react';
import If from 'Views/Components/If/If';
import HandleEvents from 'Util/HandleEvents';
import AdverseAlertsCaseDetailsModal from './AdverseAlertsCaseDetailsModal';
import AdverseAlertsTable from './AdverseAlertsTable';

const AdverseAlertsCases = (props: {
	cases: Case[],
}) => {
	const { cases } = props;
	const [highlightedCase, setHighlightedCase] = useState<Case | undefined>(undefined);

	const closeCaseModal = useCallback(() => {
		setHighlightedCase(undefined);
	}, []);

	return (
		<>
			<AdverseAlertsTable
				tableName="Court cases"
				className="cases"
				collection={cases}
				columns={[
					{
						displayName: '#',
						columnName: 'index',
						value: courtCase => courtCase.index,
						sortable: false,
						className: 'field-index',
					},
					{
						displayName: 'Date',
						columnName: 'date',
						value: courtCase => moment(courtCase.notification_time).format('Do MMMM YYYY'),
						sortable: false,
						className: 'field-date',
					},
					{
						displayName: 'Case name',
						columnName: 'caseName',
						value: courtCase => courtCase.case_name,
						sortable: false,
						className: 'field-case-name',
					},
					{
						displayName: 'Court name',
						columnName: 'courtName',
						value: courtCase => courtCase.court_name,
						sortable: false,
						className: 'field-court-name',
					},
					{
						displayName: 'Case type',
						columnName: 'caseType',
						value: courtCase => courtCase.case_type,
						sortable: false,
						className: 'field-case-type',
					},
					{
						displayName: 'Case ID',
						columnName: 'caseId',
						value: courtCase => courtCase.case_number,
						sortable: false,
						className: 'field-case-number',
					},
					{
						displayName: 'Party role',
						columnName: 'partyRole',
						value: courtCase => courtCase.party_role,
						sortable: false,
						className: 'field-party-role',
					},
					{
						displayName: '',
						columnName: 'details',
						value: courtCase => (
							<span
								className="icon icon-right icon-open-in-new"
								{...HandleEvents(() => setHighlightedCase(courtCase))}
							>
								DETAILS
							</span>
						),
						sortable: false,
						className: 'field-details',
					},
				]}
				sortColumn="date"
				sortDescending
				idColumn="index"
			/>
			<If condition={!!highlightedCase}>
				<AdverseAlertsCaseDetailsModal
					courtCase={highlightedCase ?? {} as Case}
					onClose={closeCaseModal}
				/>
			</If>
		</>
	);
};

export default AdverseAlertsCases;
