import { RouteComponentProps } from 'react-router';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import { ProductSection } from 'Views/Components/HubDashboard/ProductSection';
import { QuickActionSection } from 'Views/Components/HubDashboard/QuickActionSection';

export interface HubPageProps extends RouteComponentProps {}

const ProductPage = (props: HubPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content product-page">
				<div className="invisible-page-wrap centre grow">
					<ProductSection />
					<QuickActionSection />
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default ProductPage;
