import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

const editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
	acceptSuggestionOnCommitCharacter: true,
	acceptSuggestionOnEnter: 'on',
	accessibilitySupport: 'auto',
	autoClosingBrackets: 'always',
	autoIndent: 'full',
	automaticLayout: true,
	bracketPairColorization: {
		enabled: true,
	},
	codeLens: true,
	colorDecorators: true,
	contextmenu: true,
	cursorBlinking: 'blink',
	cursorSmoothCaretAnimation: 'off',
	cursorStyle: 'line',
	disableLayerHinting: false,
	disableMonospaceOptimizations: false,
	dragAndDrop: false,
	fixedOverflowWidgets: false,
	folding: true,
	foldingStrategy: 'auto',
	fontLigatures: true,
	formatOnPaste: true,
	formatOnType: true,
	hideCursorInOverviewRuler: false,
	links: true,
	mouseWheelZoom: false,
	multiCursorMergeOverlapping: true,
	multiCursorModifier: 'alt',
	overviewRulerBorder: true,
	overviewRulerLanes: 2,
	quickSuggestions: true,
	quickSuggestionsDelay: 100,
	readOnly: false,
	renderControlCharacters: false,
	renderFinalNewline: 'on',
	renderLineHighlight: 'all',
	renderWhitespace: 'none',
	revealHorizontalRightPadding: 30,
	roundedSelection: true,
	rulers: [],
	scrollBeyondLastColumn: 5,
	scrollBeyondLastLine: true,
	selectOnLineNumbers: true,
	selectionClipboard: true,
	selectionHighlight: true,
	showFoldingControls: 'never',
	smoothScrolling: false,
	suggestOnTriggerCharacters: true,
	wordBasedSuggestions: 'matchingDocuments',
	wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
	wordWrap: 'off',
	wordWrapBreakAfterCharacters: '\t})]?|&,;',
	wordWrapBreakBeforeCharacters: '{([+',
	wordWrapColumn: 80,
	wrappingIndent: 'none',
	tabSize: 4,
	detectIndentation: true,
};

export default editorOptions;
