import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import useAsync from '../../Hooks/useAsync';
import gql from 'graphql-tag';
import {
	AddressEntity, BusinessEntity, OrganisationEntity, RegistrationEntity,
} from '../../Models/Entities';
import { store } from '../../Models/Store';
import { action } from 'mobx';
import alertToast from '../../Util/ToastifyUtils';
import Spinner from '../Components/Spinner/Spinner';
import SecuredPage from '../Components/Security/SecuredPage';
import { getFrontendNavLinks } from '../FrontendNavLinks';
import Navigation, { Orientation } from '../Components/Navigation/Navigation';
import If from '../Components/If/If';
import InlineSpinner from '../Components/Spinner/InlineSpinner';
import { PageBreadcrumbs } from '../Components/Breadcrumbs/PageBreadcrumbs';
import classNames from 'classnames';
import HandleEvents from '../../Util/HandleEvents';
import ReferralPartnerDetails from '../Components/ReferralPartners/ReferralPartnerDetails';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface ReferralPartnerPageProps {
    registrationId?: string,
}

const ReferralPartnerPage = observer((props: RouteComponentProps<ReferralPartnerPageProps>) => {
	const {
		match,
	} = props;

	const { registrationId } = match.params;
	const [name, setName] = React.useState('');
	const [currentTab, setCurrentTab] = React.useState('organisation-details' as
		('organisation-details' | 'referrals'));

	const registration = useAsync(async () => {
		const query = gql`
            query fetchRegistrationById($registrationId: ID) {
                registrationEntity(id: $registrationId) {
                    ${RegistrationEntity.getAllAttributes().join('\n')}
                    createdOrganisation {
                        ${OrganisationEntity.getAllAttributes().join('\n')}
                        primaryBusinessEntity {
                            ${BusinessEntity.getAllAttributes().join('\n')}
                            address {
                                ${AddressEntity.getAllAttributes().join('\n')}
                            }
                        }
                    }
                }
            }
        `;
		const results = await store.apolloClient.query({
			query: query,
			variables: {
				registrationId: registrationId,
			},
			fetchPolicy: 'no-cache',
		});

		if (!!results.data.registrationEntity) {
			return new RegistrationEntity(results.data.registrationEntity);
		}

		throw new Error('Could not fetch relevant registration');
	}, []);

	React.useEffect(() => {
		if (registration.data?.createdOrganisation?.primaryBusinessEntity != null) {
			setName(registration.data?.createdOrganisation?.primaryBusinessEntity?.name);
		} else if (registration.data?.organisationName) {
			setName(registration.data?.organisationName);
		}
	}, [registration]);

	if (registration.type === 'error') {
		alertToast('Unable to find matching referral partner', 'error');
		const redirectUrl = registrationId != null ? '/hub/referral-partners' : '/';
		store.routerHistory.push(redirectUrl);
		return <Spinner />;
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content referral-partner-page">
				<div className="invisible-page-wrap">
					<If condition={registration.type === 'loading'}>
						<InlineSpinner />
					</If>
					{registration.type === 'data' && (
						<>
							<div className="top-container">
								<PageBreadcrumbs
									tags={
										[
											{ label: 'Referral Partners', link: '/hub/referral-partners' },
											{ label: `${name}` },
										]
									}
								/>
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								<button
									className={classNames('tab-selector', 'organisation-details', {
										active: currentTab === 'organisation-details',
									})}
									{...HandleEvents(action(() => {
										setCurrentTab('organisation-details');
									}))}
								>
									Organisation details
								</button>
								<button
									className={classNames('tab-selector', 'referrals', {
										active: currentTab === 'referrals',
									})}
									{...HandleEvents(() => {
										setCurrentTab('referrals');
									})}
								>
									Referrals
								</button>
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': currentTab === 'organisation-details',
							})}
							>
								<If condition={currentTab === 'organisation-details'}>
									<ReferralPartnerDetails
										organisation={registration.data.createdOrganisation ?? registration.data
											.parsedReferralData.organisation}
										orgName={registration.data.organisationName}
										disabled={registration.data.referralStatus !== 'COMPLETE'}
										setName={setName}
									/>
								</If>
							</div>
						</>
					)}
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
});

export default ReferralPartnerPage;
