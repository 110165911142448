/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import classNames from 'classnames';
import { store } from 'Models/Store';
import { Button, Display } from '../Button/Button';
// % protected region % [Add any additional includes] on begin
import ButtonAsyncState from '../Button/ButtonAsyncState';
import { useState } from 'react';
// % protected region % [Add any additional includes] end

export interface IConfirmModalOptions {
	/** The text to be placed in the confirm button */
	confirmText?: string;
	/** The text to be placed in the cancel button */
	cancelText?: string
	/** Class name on the modal */
	className?: string;
	/** Class name on the header div */
	headerClassName?: string;
	/** Class name on the message div */
	messageClassName?: string;
	/** Class name on the action div */
	actionClassName?: string;
	/** Class name for the modal overlay */
	overlayClassName?: string;
	/**
	 * If this option is set to true, when the cancel button is pressed the promise is resolved with a value of false
	 * instead being rejected.
	 */
	resolveOnCancel?: boolean;
	/** Boolean indicating if the overlay should close the modal. Defaults to true. */
	shouldCloseOnOverlayClick?: boolean;
	/** Boolean indicating if pressing the esc key should close the modal */
	shouldCloseOnEsc?: boolean;
	// % protected region % [Add any extra IConfirmModalOptions here] off begin
	// % protected region % [Add any extra IConfirmModalOptions here] end
}

/**
 * Opens a confirm modal which gives a user an option to confirm or cancel
 * @param title The title on the modal
 * @param message The message to display in the modal
 * @param options Extra options for the modal
 * @returns A promise that is resolved on the user confirming or rejected on the user cancelling
 */
export function confirmModal(title: string, message: React.ReactNode, options: IConfirmModalOptions = {}) {
	return new Promise<boolean>((resolve, reject) => {
		const onConfirm = () => {
			store.modal.hide();
			resolve(true);
		};

		const onCancel = () => {
			store.modal.hide();
			if (options.resolveOnCancel) {
				resolve(false);
			} else {
				reject();
			}
		};

		const confirmText = options.confirmText ?? 'Confirm';
		const cancelText = options.cancelText ?? 'Cancel';

		// % protected region % [update confirm modal if required] on begin
		const confirmDom = (
			<>
				<h4 key="header-item" className="modal__title">{title}</h4>
				<div key="message" className="modal__message">
					{message}
				</div>
				<div key="actions" className="modal__actions">
					<Button
						className="modal--cancel"
						key="cancel"
						onClick={onCancel}
						display={Display.Outline}
					>
						{cancelText}
					</Button>
					<Button
						className="modal--confirm"
						key="confirm"
						onClick={onConfirm}
						display={Display.Solid}
					>
						{confirmText}
					</Button>
				</div>
			</>
		);
		// % protected region % [update confirm modal if required] end

		store.modal.show(title, confirmDom, {
			className: classNames('confirm-modal', options.className),
			overlayClassName: options.overlayClassName,
			shouldCloseOnEsc: options.shouldCloseOnEsc,
			shouldCloseOnOverlayClick: options.shouldCloseOnOverlayClick,
			onRequestClose: onCancel,
			// % protected region % [Add additional options to the confirm modal] off begin
			// % protected region % [Add additional options to the confirm modal] end
		});
	});
}

export interface IAlertModalProps {
	/** The text to be placed in the cancel button */
	cancelText?: string;
	/** Class name on the modal */
	className?: string;
	/** Class name on the header div */
	headerClassName?: string;
	/** Class name on the message div */
	messageClassName?: string;
	/** Class name for the modal overlay */
	overlayClassName?: string;
	/** Boolean indicating if the overlay should close the modal. Defaults to true. */
	shouldCloseOnOverlayClick?: boolean;
	/** Boolean indicating if pressing the esc key should close the modal */
	shouldCloseOnEsc?: boolean;
	// % protected region % [Add any extra IAlertModalProps here] off begin
	// % protected region % [Add any extra IAlertModalProps here] end
}

/**
 * Displays an alert on the screen
 * @param title A title for the modal
 * @param message The message content for the modal
 * @param options Extra options for the modal
 * @returns A promise that is resolved when the modal is closed
 */
export function alertModal(title: string, message: React.ReactNode, options: IAlertModalProps = {}) {
	return new Promise<void>(resolve => {
		const onClose = () => {
			store.modal.hide();
			resolve();
		};

		const cancelText = options.cancelText ?? 'Cancel';

		// % protected region % [update alert modal if required] off begin
		const alertDom = (
			<>
				<div key="header" className={classNames('modal__header', options.headerClassName)}>
					<h2 key="header-item" className="modal__title">{title}</h2>
					<Button
						key="header-cancel"
						icon={{ icon: 'square-x', iconPos: 'icon-left' }}
						display={Display.Text}
						onClick={onClose}
						labelVisible={false}
					>
						{cancelText}
					</Button>
				</div>
				<div key="message" className={classNames('modal__message', options.messageClassName)}>
					{message}
				</div>
			</>
		);
		// % protected region % [update alert modal if required] end

		store.modal.show(title, alertDom, {
			className: classNames('alert-modal', options.className),
			overlayClassName: options.overlayClassName,
			onRequestClose: onClose,
			shouldCloseOnEsc: options.shouldCloseOnEsc,
			shouldCloseOnOverlayClick: options.shouldCloseOnOverlayClick,
			// % protected region % [Add additional options to the alert modal] off begin
			// % protected region % [Add additional options to the alert modal] end
		});
	});
}

// % protected region % [Add any additional methods here] on begin
interface ConfirmModalAsyncProps {
	title: string;
	confirmText: string;
	cancelText: string;
	waitingText?: string;
	onConfirm: () => Promise<void>,
	onCancel: () => void,
	children: React.ReactNode;
}

const ConfirmModalAsync = (props: ConfirmModalAsyncProps) => {
	const {
		title,
		confirmText,
		cancelText,
		waitingText,
		onConfirm,
		onCancel,
		children,
	} = props;

	const [confirmed, setConfirmed] = useState(false);

	return (
		<>
			<h4 key="header-item" className="modal__title">{title}</h4>
			<div key="message" className="modal__message">
				{children}
			</div>
			<div key="actions" className="modal__actions">
				<Button
					className="modal--cancel"
					key="cancel"
					onClick={onCancel}
					display={Display.Outline}
					disabled={confirmed}
				>
					{cancelText}
				</Button>
				<ButtonAsyncState
					className="modal--confirm"
					key="confirm"
					onPress={async () => {
						setConfirmed(true);
						await onConfirm();
					}}
					display={Display.Solid}
					waitingText={waitingText}
				>
					{confirmText}
				</ButtonAsyncState>
			</div>
		</>
	);
};

/**
 * Opens a confirm modal which gives a user an option to confirm or cancel
 * @param title The title on the modal
 * @param message The message to display in the modal
 * @param options Extra options for the modal
 * @returns A promise that is resolved on the user confirming or rejected on the user cancelling
 */
export function confirmModalAsync(
	title: string,
	message: React.ReactNode,
	options: IConfirmModalOptions & { waitingText?: string } = {},
): { result: () => Promise<boolean>, close: () => void } {
	let resolveClose: () => void;
	const closePromise = new Promise<void>(r => {
		resolveClose = r;
	});

	const resultPromise = new Promise<boolean>((resolve, reject) => {
		let resolved = false;

		const onConfirm = async () => {
			resolve(true);
			resolved = true;

			try {
				await closePromise;
			} catch (e) {
				// Do nothing
			}

			store.modal.hide();
		};

		const onCancel = () => {
			if (resolved) {
				return;
			}

			store.modal.hide();
			if (options.resolveOnCancel) {
				resolve(false);
			} else {
				reject();
			}
		};

		store.modal.show(
			title,
			(
				<ConfirmModalAsync
					title={title}
					confirmText={options.confirmText ?? 'Confirm'}
					cancelText={options.cancelText ?? 'Cancel'}
					waitingText={options.waitingText}
					onConfirm={onConfirm}
					onCancel={onCancel}
				>
					{message}
				</ConfirmModalAsync>
			),
			{
				className: classNames('confirm-modal', options.className),
				overlayClassName: options.overlayClassName,
				shouldCloseOnEsc: options.shouldCloseOnEsc,
				shouldCloseOnOverlayClick: options.shouldCloseOnOverlayClick,
				onRequestClose: onCancel,
			},
		);
	});

	return {
		close: () => resolveClose(),
		result: () => resultPromise,
	};
}
// % protected region % [Add any additional methods here] end
