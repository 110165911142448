import { observer } from 'mobx-react';
import {
	BusinessEntity, OrganisationEntity,
} from 'Models/Entities';
import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { Colors, Display } from 'Views/Components/Button/Button';
import If from '../If/If';
import { AddressEntity } from 'Models/Entities';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import useHasChanged from 'Hooks/useHasChanged';
import ReferralPartnerBusinessFields from 'Views/Components/Organisation/ReferralPartnerBusinessFields';
import ReferralPartnerBenefitsFields from 'Views/Components/Organisation/ReferralPartnerBenefitsFields';
import classNames from 'classnames';
import { booleanToActive } from 'Util/StringUtils';

export interface ReferralPartnerDetailsProps {
	organisation: OrganisationEntity,
	setName?: (name: string) => void,
	orgName: string,
	hideBenefitDetails?: boolean,
	disabled?: boolean,
}

const OrganisationDetails = observer((props: ReferralPartnerDetailsProps) => {
	const {
		organisation, setName, orgName, hideBenefitDetails, disabled,
	} = props;

	// use a private organisation for this page
	const [privateOrganisation, setPrivateOrganisation] = React.useState(() => {
		const organisationEntity = new OrganisationEntity(organisation);
		// we need to create new objects for the nested objects so they don't use pass by reference
		if (organisation?.primaryBusinessEntity) {
			organisationEntity.primaryBusinessEntity = new BusinessEntity(organisation.primaryBusinessEntity);
			organisationEntity.primaryBusinessEntity.address = new AddressEntity(organisationEntity
				.primaryBusinessEntity.address);
		}
		return organisationEntity;
	});

	// we detect if anything has changed in the three entities that are used in this page, true (or errors) if no changes false otherwise
	const organisationSaveDisabled = useHasChanged(
		OrganisationEntity,
		privateOrganisation,
		['referrerBusinessType', 'referrerBusinessOther', 'referrerBenefitType', 'referrerCommissionFrequency',
			'referrerBankAccountName', 'referrerBSB', 'referrerBankAccountName', 'referrerBankAccountNumber',
			'referrerCharityName'],
		(oldValue, newValue) => oldValue !== newValue,
		['errors'],
		(original, changed) => (
			Object.keys(changed?.errors ?? {}).length > 0),
	);
	const primaryBusinessEntitySaveDisabled = useHasChanged(
		BusinessEntity,
		privateOrganisation?.primaryBusinessEntity,
		['name', 'abn', 'email', 'phone'],
		(oldValue, newValue) => oldValue !== newValue,
		['errors'],
		(original, changed) => Object.keys(changed?.errors ?? {}).length > 0,
	);
	const addressSaveDisabled = useHasChanged(
		AddressEntity,
		privateOrganisation?.primaryBusinessEntity?.address,
		['line1', 'line2', 'suburb', 'postcode', 'state', 'country'],
		(oldValue, newValue) => oldValue !== newValue,
		['errors'],
		(original, changed) => Object.keys(changed?.errors ?? {}).length > 0,
	);

	// if there are errors present in any of the objects
	const errorsPresent = privateOrganisation !== null ? React.useMemo(() => {
		if (Object.keys(privateOrganisation?.errors ?? {}).length > 0) {
			return true;
		}
		if (Object.keys(privateOrganisation?.primaryBusinessEntity?.errors ?? {}).length > 0) {
			return true;
		}
		return Object.keys(privateOrganisation?.primaryBusinessEntity?.address?.errors ?? {})
			.length > 0;
	}, [privateOrganisation?.errors, privateOrganisation
		?.primaryBusinessEntity?.errors, privateOrganisation?.primaryBusinessEntity?.address
		?.errors]) : false;

	/**
	 * Method to save the updated referral partner information. Raises an alert toast on success and on error.
	 */
	const onSavePressed = async () => {
		if (disabled) {
			return;
		}
		if (privateOrganisation === null) {
			return;
		}
		await privateOrganisation.primaryBusinessEntity.validateReferralPartnerFields();
		await privateOrganisation.validateBenefitFields();
		await privateOrganisation.primaryBusinessEntity.address?.validateField('postcode');

		if (Object.keys(privateOrganisation.primaryBusinessEntity.errors).length !== 0
			|| Object.keys(privateOrganisation.errors).length !== 0
			|| Object.keys(privateOrganisation.primaryBusinessEntity.address?.errors ?? {}).length
			!== 0) {
			return;
		}

		try {
			if (!!privateOrganisation.primaryBusinessEntity.address) {
				privateOrganisation.primaryBusinessEntity.address
					.businessEntityId = privateOrganisation.primaryBusinessEntityId;
			}
			await privateOrganisation.saveWithFetchBack({
				primaryBusinessEntity: {
					address: {},
				},
			}, {}, `
					primaryBusinessEntity {
						${BusinessEntity.getAttributes().join('\n')}
						address {
							${AddressEntity.getAttributes().join('\n')}
						}
					}
				`);
		} catch (e) {
			setPrivateOrganisation(new OrganisationEntity(organisation));
			alertToast('Organisation details could not be saved at this moment. Please '
				+ 'refresh and try again.', 'error');
			return;
		}
		organisation.assignAttributes(privateOrganisation);
		alertToast('Referral partner successfully updated', 'success');
		if (!!setName) {
			setName(privateOrganisation?.primaryBusinessEntity?.name);
		}
	};

	return (
		<>
			<div className="referral-partner-details">
				<div className="details-container">
					<section>
						<h3>Business details</h3>
						<ReferralPartnerBusinessFields
							businessEntity={privateOrganisation.primaryBusinessEntity
								?? new BusinessEntity({ name: orgName })}
							disabled={disabled}
							showAddress
						/>
					</section>
					<If condition={!hideBenefitDetails}>
						<section>
							<h3>Benefit details</h3>
							<ReferralPartnerBenefitsFields
								organisation={privateOrganisation ?? new OrganisationEntity()}
								disabled={disabled}
							/>
							<div className="organisation-status">
								<label htmlFor="organisation-status-box">
									Access Intell Client
								</label>
								<span
									id="organisation-status-box"
									className={classNames({
										active: privateOrganisation?.isClient,
									})}
								>
									{booleanToActive(privateOrganisation?.isClient)}
								</span>
							</div>
						</section>
					</If>
				</div>
				<If condition={!disabled}>
					<div>
						<ButtonAsyncState
							className="save"
							colors={Colors.Primary}
							display={Display.Solid}
							onPress={onSavePressed}
							readonly={disabled || (organisationSaveDisabled && primaryBusinessEntitySaveDisabled
								&& addressSaveDisabled) || errorsPresent}
						>
							Save Changes
						</ButtonAsyncState>
					</div>
				</If>
			</div>
		</>
	);
});
export default OrganisationDetails;
