import React, { ReactNode } from 'react';
import useAsync from 'Hooks/useAsync';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';

export type RequestState = 'loading' | 'error' | 'done';

interface IRequestWrapProps<T> {
	// Render prop that is shown once the request has finished
	children: (data: T) => ReactNode,

	// The request which fetches a list of objects of type T
	request: () => Promise<T>,

	// refresh key -- If this changes, we re-request the data
	refreshKey?: string,

	// Override the default error message displayed to the user
	loadingContent?: ReactNode,

	// Override the default error message displayed to the user
	errorContent?: ReactNode,
}

// Define the spinner manually, rather than using the Spinner component, so we can add custom classes
// We want the spinner to exist within this component, rather than floating above the page
const defaultLoadingContent = (
	<InlineSpinner />
);

const defaultErrorContent = (
	<div className="error-msg">
		<h3>There has been an error</h3>
		<p>Unable to load the page at this time. Please contact us if the problem persists.</p>
	</div>
);

function RequestWrap<T>(props: IRequestWrapProps<T>) {
	const {
		children,
		request,
		refreshKey,
		loadingContent = defaultLoadingContent,
		errorContent = defaultErrorContent,
	} = props;
	const response = useAsync(
		request,
		[refreshKey],
		{ suppressSubsequentLoadingState: true },
	);

	switch (response.type) {
		case 'loading':
			return <>{loadingContent}</>;
		case 'error':
			return <>{errorContent}</>;
		case 'data':
			return <>{children(response.data)}</>;
	}
}

export default RequestWrap;

export const GetRefreshKey = (...args: any[]) => args
	.map(x => {
		if (x === null || x === undefined) {
			return '';
		}
		return x.toString();
	})
	.join('::');
