import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AccountFields, { AccountFieldValidationFunctions } from 'Views/Components/Account/AccountFields';
import { useEffect, useState } from 'react';
import { UserEntity } from 'Models/Entities';
import { Errors } from 'Util/CustomTypes';
import { observer, useLocalStore } from 'mobx-react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import alertToast from 'Util/ToastifyUtils';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import If from 'Views/Components/If/If';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';
import { CalculatePricing, PricingDetail } from 'Util/PricingHelper';
import { LocaleFormatCurrency, pluralise } from 'Util/StringUtils';
import useStore from 'Hooks/useStore';

interface CustomerCredits {
	remainingUserCredits: number,
	newPrice: number,
}
export interface NewUserPageProps extends RouteComponentProps {}

function UserPriceNotification(props: { userCreditInfo: CustomerCredits|null }) {
	const { userCreditInfo } = props;

	if (!userCreditInfo) {
		return null;
	}

	const { newPrice, remainingUserCredits } = userCreditInfo;

	// Default message if we can't find pricing, or if we're already in the top tier
	let message = (
		<>
			<b>You have free user credits.</b>
			&nbsp;Creating this user will <b>not</b> change your monthly subscription.
		</>
	);
	if (remainingUserCredits === 0) {
		message = (
			<>
				<b>You have no remaining user credits.</b>
				&nbsp;Creating this user will increase your monthly subscription
				to <b>{LocaleFormatCurrency(newPrice)}</b>.
			</>
		);
	} else if (remainingUserCredits > 0) {
		message = (
			<>
				<b>You have {pluralise(remainingUserCredits, 'free user credit')}.</b>
				&nbsp;Creating this user will <b>not</b> change your monthly subscription.
			</>
		);
	}

	return (
		<p className="alert-box info">
			{message}
		</p>
	);
}

const NewUserPage = observer((props: NewUserPageProps) => {
	const store = useStore();

	const user = useLocalStore(() => new UserEntity({
		organisation: store.getUser?.organisation,
		isActive: true,
	}));
	const [errors, setErrors] = useState({} as Errors);

	const [userCreditInfo, setUserCreditInfo] = React.useState<CustomerCredits|null>(null);

	const validationSuccessful = () => {
		Object.keys(AccountFieldValidationFunctions).forEach(field => {
			const error = AccountFieldValidationFunctions[field](user);
			if (!error) {
				delete errors[field];
			} else {
				errors[field] = error;
			}
		});

		// disable save if there are errors
		const errorFound = Object.keys(errors).length > 0;
		setErrors({ ...errors }); // Clone the object to that React recognises the change
		return !errorFound;
	};

	const onCancelPressed = () => {
		store.routerHistory.push('/hub/users');
	};

	const onSavePressed = async () => {
		if (!validationSuccessful()) {
			return;
		}

		try {
			await confirmModal(
				'Confirm new user',
				(
					<>
						<If condition={store.userType !== 'SUPER_USER'
							&& userCreditInfo != null && userCreditInfo.remainingUserCredits === 0}
						>
							<p>
								Creating this user will increase your total monthly subscription
								to {LocaleFormatCurrency(userCreditInfo?.newPrice ?? 0)}.
							</p>
						</If>
						<p>
							The new user will receive an email inviting them to setup their password.
						</p>
					</>
				),
			);
		} catch {
			return;
		}

		try {
			await user.save(
				{
					businessEntitys: {},
					permissionOverrides: {},
				},
				{
					graphQlInputType: '[UserEntityCreateInput]',
					jsonTransformFn: input => {
						input.password = 'toBeOverwritten'; // Add a password we'll overwrite on the serverside, so GraphQL succeeds
						return input;
					},
				},
			);
			alertToast('User has been created, and sent an email to confirm their account.', 'success');
			store.routerHistory.push(`/hub/users/${user.id}/permissions`);
		} catch (exception) {
			alertToast('User could not be created. Please refresh and try again.', 'error');
		}
	};

	const calculateUserCredits = React.useCallback(
		async (organisationId: string|undefined):Promise<CustomerCredits|null> => {
			if (!organisationId) {
				return null;
			}

			const currentPricingDetail = await CalculatePricing({
				OrganisationId: organisationId,
			});

			if (!currentPricingDetail.userCountBucket) {
			// We're already in the largest bucket
				return {
					remainingUserCredits: -1,
					newPrice: 0,
				};
			}

			if (currentPricingDetail.userCount < currentPricingDetail.userCountBucket) {
				return {
					remainingUserCredits: currentPricingDetail.userCountBucket - currentPricingDetail.userCount,
					newPrice: 0,
				};
			}

			const { total: newPricingTotal } = await CalculatePricing({
				OrganisationId: organisationId,
				UserCountDelta: 1,
			});

			return {
				remainingUserCredits: 0,
				newPrice: newPricingTotal,
			};
		}, [],
	);

	useEffect(() => {
		if (store.userType !== 'SUPER_USER') {
			calculateUserCredits(user.organisationId).then(setUserCreditInfo);
		}
	}, [user.organisationId, calculateUserCredits, store]);

	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content new-user-page">
				<div className="invisible-page-wrap">
					<PageBreadcrumbs
						className="with-subtitle"
						tags={
							[
								{ label: 'Users', link: '/hub/users' },
								{ label: 'New User' },
							]
						}
					/>
					<p className="subtitle">
						Create a new user account. The new user will receive an email inviting them to setup their
						password.
					</p>
					<div className="white-box">
						<AccountFields
							user={user}
							errors={errors}
							setErrors={setErrors}
							isNewUser
							readonly={false}
						/>

						<UserPriceNotification userCreditInfo={userCreditInfo} />
						<div className="actions-wrap">
							<Button
								className="cancel"
								colors={Colors.Primary}
								display={Display.Outline}
								onClick={onCancelPressed}
							>
								Cancel
							</Button>
							<ButtonAsyncState
								className="save"
								colors={Colors.Primary}
								display={Display.Solid}
								onPress={onSavePressed}
								readonly={Object.keys(errors).length > 0}
								waitingText="Creating..."
							>
								Create
							</ButtonAsyncState>
						</div>
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
});

export default NewUserPage;
