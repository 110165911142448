import { CustomerEntity } from 'Models/Entities';
import { stateOptions, state } from 'Models/Enums';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { IconDisplay, IconTextBox } from 'Views/Components/TextBox/IconTextBox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback } from 'react';
import LiquorLicenceDetailsTable from './LiquorLicenceDetailsTable';
import {
	Button,
	Colors,
	Display,
} from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import ComboInput from 'Views/Components/ComboInput/ComboInput';

interface Props {
	privateCustomer: CustomerEntity;
	editDisabled: boolean;
}

// eslint-disable-next-line max-len, object-curly-newline
const LiquorLicenceInformation: React.FunctionComponent<Props> = observer(({ privateCustomer, editDisabled }) => {
	const [showLiquorLicenceTable, setShowLiquorLicenceTable] = React.useState(false);

	const validateLiquorLicence = useCallback(async () => {
		await privateCustomer.validateField('drinksLicence');
	}, [privateCustomer]);

	// eslint-disable-next-line max-len
	const hasManyDetails = Object.keys(privateCustomer.liquorLicenceResponseDetails?.data ?? {}).length > 1;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateLiquorLicence = useCallback(debounce(validateLiquorLicence, 300), [validateLiquorLicence]);

	return (
		<div className="inputs">
			<div className="inputs-column stretch">
				<div className="liquor-licence-container input-container">
					<ComboInput
						label="Liquor Licence"
						errors={[privateCustomer.errors.drinksLicenceState, privateCustomer.errors.drinksLicence]}
					>
						<Combobox
							className="liquor-licence-state"
							model={privateCustomer}
							modelProperty="drinksLicenceState"
							label=""
							placeholder="State"
							options={(Object.keys(stateOptions) as [state])
								.map<ComboboxOption<state>>(value => {
									return {
										display: value === 'NONE' ? 'Select Jurisdiction' : stateOptions[value],
										value: value,
									};
								})}
							onAfterChange={debouncedValidateLiquorLicence}
							isRequired
							isDisabled={editDisabled}
							errors={privateCustomer.errors.drinksLicenceState}
							searchable={false}
						/>
						<IconTextBox
							className="liquor-licence-number"
							model={privateCustomer}
							modelProperty="drinksLicence"
							placeholder="Licence Number"
							onChangeAndBlur={validateLiquorLicence}
							onAfterChange={debouncedValidateLiquorLicence}
							errors={privateCustomer.errors.drinksLicence}
							isRequired
							displayIconContainer={privateCustomer.liquorLicenceFinishedValidating}
							// eslint-disable-next-line max-len
							iconDisplay={privateCustomer.liquorLicenceResponseDetails?.responseCode === 0
								? IconDisplay.Valid : IconDisplay.Error}
							isDisabled={editDisabled}
							label=""
						/>
					</ComboInput>
					<If condition={hasManyDetails}>
						<Button
							className="liquor-licence-details__button"
							display={Display.Text}
							colors={Colors.Primary}
							onClick={() => setShowLiquorLicenceTable(true)}
						>
							{/* eslint-disable-next-line max-len */}
							{`${privateCustomer.selectedLiquorLicenceDetails ? 'View' : 'Choose'} Licence Details`}
						</Button>
						<LiquorLicenceDetailsTable
							liquorLicenceDetails={privateCustomer.liquorLicenceResponseDetails}
							liquorLicenceNumber={privateCustomer.drinksLicence}
							liquorLicenceState={privateCustomer.drinksLicenceState}
							previousUniqueId={privateCustomer.selectedLiquorLicenceDetails?.uniqueId}
							// eslint-disable-next-line max-len
							updateLiquorLicenceDetails={(uniqueId:string) => { privateCustomer.updateLiquorLicenceFields(uniqueId); }}
							openModal={showLiquorLicenceTable}
							closeModal={() => setShowLiquorLicenceTable(false)}
						/>
					</If>
					<div className="liquor-licence-status">
						<b>Status:</b>
						{privateCustomer.drinksLicenceStatus ? ` ${privateCustomer.drinksLicenceStatus}` : ' -'}
						<br />
					</div>
					<div className="channel">
						<b>Channel:</b>
						{/* eslint-disable-next-line max-len */}
						{privateCustomer.selectedLiquorLicenceDetails ? ` ${privateCustomer.selectedLiquorLicenceDetails.channel}` : ' -'}
					</div>
					<div className="sub-channel">
						<b>Sub Channel:</b>
						{/* eslint-disable-next-line max-len */}
						{privateCustomer.selectedLiquorLicenceDetails ? ` ${privateCustomer.selectedLiquorLicenceDetails.subChannel}` : ' -'}
					</div>
					<div className="active-since">
						<b>Active Since:</b>
						{/* eslint-disable-next-line max-len */}
						{privateCustomer.selectedLiquorLicenceDetails ? ` ${moment(privateCustomer.selectedLiquorLicenceDetails.dateCreated).format('Do MMM YYYY')}` : ' -'}
					</div>
				</div>
			</div>
			<div className="inputs-column">
				<div className="input-container">
					<TextField
						className="liquor-licence-active-since"
						// eslint-disable-next-line max-len
						model={{ dateCreated: !privateCustomer.selectedLiquorLicenceDetails?.grantedDate ? '-' : moment(privateCustomer.selectedLiquorLicenceDetails.grantedDate).format('Do MMM YYYY') }}
						modelProperty="dateCreated"
						isDisabled
						label="Granted Date"
					/>
				</div>
				<div className="input-container">
					<TextField
						className="liquor-licence-record-updated"
						model={{
							// eslint-disable-next-line max-len
							drinksLicenceLastUpdated: (privateCustomer.drinksLicenceLastUpdated?.getDay() === (new Date(1001, 0, 1)).getDay()) ? '-' : moment(privateCustomer.drinksLicenceLastUpdated, 'DD-MM-YYYY').format('Do MMM YYYY'),
						}}
						modelProperty="drinksLicenceLastUpdated"
						isDisabled
						label="Record updated"
					/>
				</div>
				<div className="input-container">
					<TextField
						className="liquor-licence-channel-category"
						// eslint-disable-next-line max-len
						model={{ channelCategory: !privateCustomer.selectedLiquorLicenceDetails ? '-' : privateCustomer.selectedLiquorLicenceDetails.channelCategory }}
						modelProperty="channelCategory"
						isDisabled
						label="Channel Category"
					/>
				</div>
			</div>
		</div>
	);
});

export default LiquorLicenceInformation;
