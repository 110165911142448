import * as React from 'react';
import classNames from 'classnames';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { IIconProps } from 'Views/Components/Helpers/Common';
import { Image } from 'semantic-ui-react';
import { Button, Colors, Display } from '../Button/Button';
import { Link } from 'react-router-dom';

export interface ActionCardCoreProps {
	logo?: string;
	title: React.ReactNode;
	content?: React.ReactNode;
	className?: string;
	onHover?: (enter: boolean) => void;
}

export interface ActionCardNoActionProps extends ActionCardCoreProps {
	link?: undefined;
	button?: undefined;
}

export interface ActionCardButtonProps {
	text: React.ReactNode;
	display?: Display;
	colors?: Colors;
	className?: string;
	readonly?: boolean;
	onPress?: ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>);
	icon?: IIconProps;
	labelVisible?: false;
	// Saving... will be used by default
	waitingText?: string;
}
export interface ActionCardWithButtonProps extends ActionCardCoreProps {
	link?: undefined;
	button: ActionCardButtonProps;
}

export interface ActionCardLinkProps {
	text: React.ReactNode;
	href: string;
	display?: Display;
	colors?: Colors;
	className?: string;
	readonly?: boolean;
	icon?: IIconProps;
	labelVisible?: false;
}
export interface ActionCardWithLinkProps extends ActionCardCoreProps {
	button?: undefined;
	link: ActionCardLinkProps;
}

export type ActionCardProps = ActionCardNoActionProps | ActionCardWithButtonProps | ActionCardWithLinkProps;

const ActionCard = (props: ActionCardProps) => {
	const {
		logo,
		title,
		content,
		className,
		onHover = () => {},
	} = props;

	const { button } = props as ActionCardWithButtonProps;
	const { link } = props as ActionCardWithLinkProps;

	if (button) {
		button.className = classNames(button.className, 'action');
	}
	if (link) {
		link.className = classNames(link.className, 'action');
	}

	return (
		<div
			className={classNames('action-card', className)}
			onMouseEnter={() => onHover(true)}
			onMouseLeave={() => onHover(false)}
		>
			{button && (
				<ButtonAsyncState
					className={classNames(button.className, 'action')}
					{...button}
				>
					{button.text}
				</ButtonAsyncState>
			)}
			{link && (
				<Link to={link.href} className="action-link">
					<Button
						className={classNames(link.className, 'action')}
						{...link}
					>
						{link.text}
					</Button>
				</Link>
			)}
			<div className="title">
				{logo && (<Image className="logo" src={logo} />)}
				{title}
			</div>
			{content && (<div className="content">{content}</div>)}
		</div>
	);
};

export default ActionCard;
