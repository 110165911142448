import * as React from 'react';
import { BusinessEntity, SpgEntity } from '../../../Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import Modal from '../Modal/Modal';
import SpgFields from '../Ppsr/SpgFields';
import { Button, Display } from '../Button/Button';
import { runInAction } from 'mobx';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface SpgDetailsModalProps {
    spgEntity: SpgEntity,
    onClose: () => void,
    onSubmit: () => Promise<void>,
}

const SpgDetailsModal = observer((props: SpgDetailsModalProps) => {
	const { spgEntity, onClose, onSubmit } = props;

	const privateSpgEntity = useLocalStore(() => {
		return new SpgEntity(spgEntity);
	});

	const submit = async () => {
		await privateSpgEntity.validateAllFields();
		if (Object.keys(privateSpgEntity.errors).length === 0) {
			runInAction(() => {
				spgEntity.assignAttributes(privateSpgEntity);
			});
			await onSubmit();
		}
	};

	return (
		<Modal
			isOpen
			label="Add new SPG"
			onRequestClose={onClose}
			className="access-modal add-spg-modal"
		>
			<h4>Add new SPG</h4>
			<SpgFields spgEntity={privateSpgEntity} create />
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<ButtonAsyncState
					key="confirm"
					readonly={Object.keys(privateSpgEntity.errors).length > 0}
					onPress={submit}
					display={Display.Solid}
				>
					Add
				</ButtonAsyncState>
			</div>
		</Modal>
	);
});

export default SpgDetailsModal;
