import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import CustomerHeadingsData from 'Models/Entities/CustomerHeadingsData';
import { observer, useLocalStore } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { action } from 'mobx';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import CustomerColumnTypes from 'Views/Components/Intel/Customer/CustomerColumnTypes';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import If from 'Views/Components/If/If';

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const EditCustomerHeadings = observer(() => {
	const headings = useLocalStore(() => ({
		list: CustomerHeadingsData.fetchCustomerHeadings(),
	}));

	const defaultHeadings = CustomerHeadingsData.defaultHeadings();

	const onDragEnd = action((result: any) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		headings.list = reorder(
			headings.list,
			result.source.index,
			result.destination.index,
		);
	});

	return (
		<div className="customer-headings-component">
			<h6>Column Names</h6>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided: any, snapshot: any) => (
						<div
							className="customer-headings-list-wrap"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{headings.list.map((heading, index) => (
								<Draggable key={heading.fieldName} draggableId={heading.fieldName} index={index}>
									{(innerProvided: any, innerSnapshot: any) => (
										<div
											ref={innerProvided.innerRef}
											{...innerProvided.draggableProps}
											{...innerProvided.dragHandleProps}
											className={classnames('customer-heading-item', {
												dragging: innerSnapshot.isDragging,
											})}
											data-fieldname={heading.fieldName}
										>
											<span className="drag-indicator icon-left icon-material-drag-indicator" />
											<TextField
												className="header-display-name"
												model={heading}
												modelProperty="displayName"
											/>
											<If condition={!!CustomerColumnTypes[heading.fieldName].tooltip}>
												<Tooltip
													id="custom-header-tooltip"
													content={CustomerColumnTypes[heading.fieldName].tooltip
														?? CustomerColumnTypes[heading.fieldName].defaultDisplayName}
												/>
											</If>
											{
												(defaultHeadings.find(x => x.fieldName === heading.fieldName)
													?? new CustomerHeadingsData()).permanentlyEnabled
													? (
														<Checkbox
															className="header-enabled"
															model={{ value: true }}
															modelProperty="value"
															isDisabled
														/>
													) : (
														<Checkbox
															className="header-enabled"
															model={heading}
															modelProperty="enabled"
														/>
													)
											}
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<div className="actions-wrap">
				<Button
					className="cancel-btn"
					onClick={() => store.routerHistory.push('/monitor/customers')}
					colors={Colors.Primary}
					display={Display.Outline}
				>
					Cancel
				</Button>
				<ButtonAsyncState
					className="save-btn"
					onPress={async () => {
						if (headings.list.filter(heading => heading.enabled === true).length <= 0) {
							alertToast('At least one heading needs to be selected', 'error');
						} else {
							await CustomerHeadingsData.saveCustomerHeadings(headings.list);
							alertToast('Table headers updated', 'success');
							store.routerHistory.push('/monitor/customers');
						}
					}}
					colors={Colors.Primary}
					display={Display.Solid}
				>
					Save changes
				</ButtonAsyncState>
			</div>
		</div>
	);
});
export default EditCustomerHeadings;
