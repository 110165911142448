import { auditCategory, customerAuditCategory, customerAuditCategoryComboboxOptions } from 'Models/Enums';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import { DateRangePicker } from 'Views/Components/DateRangePicker/DateRangePicker';
import If from 'Views/Components/If/If';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { CustomerAuditFilterParams } from './Gibs/FetchCustomerAudits';

interface Props {
	search: string;
	auditFilterParams: CustomerAuditFilterParams;
	resetPage: () => void;
	setAuditFilterParams: React.Dispatch<React.SetStateAction<CustomerAuditFilterParams>>;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const CustomerAuditsSearchAndFilterSection: React.FunctionComponent<Props> = ({
	search,
	auditFilterParams,
	resetPage,
	setAuditFilterParams,
	setSearch,
}) => {
	const [showFilters, setShowFilters] = React.useState(false);

	const updateAuditFilterParams = React.useCallback((params: Partial<CustomerAuditFilterParams>) => {
		resetPage();
		setAuditFilterParams(prevState => {
			const updatedState = { ...prevState, ...params };
			return updatedState;
		});
	}, [resetPage, setAuditFilterParams]);

	const onToggleShowFilter = React.useCallback(() => {
		setShowFilters(prevState => !prevState);
		updateAuditFilterParams({
			category: 'ALL',
			dateRange: undefined,
		});
	}, [updateAuditFilterParams]);

	return (
		<div className="search-container">
			<div className="searchbar search">
				<TextFieldSetter
					className={classNames('search-input search__collection')}
					value={search}
					setValue={setSearch}
					label=""
					onAfterChange={resetPage}
					labelVisible={false}
					placeholder="Search Update Details"
				/>
				<Button
					className={classNames('filter-toggle-btn', { active: showFilters })}
					icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
					colors={Colors.Primary}
					display={showFilters ? Display.Solid : Display.Outline}
					onClick={onToggleShowFilter}
				>
					Filter By
				</Button>
			</div>
			<If condition={showFilters}>
				<div className="search-filter-section">
					<ComboboxSetter
						value={auditFilterParams.category}
						setValue={v => updateAuditFilterParams({ category: v as customerAuditCategory | auditCategory | 'ALL' })}
						getOptionValue={(value?: string) => value}
						placeholder="All Update Types"
						label=""
						labelVisible={false}
						searchable
						options={customerAuditCategoryComboboxOptions}
					/>
					<DateRangePicker
						model={auditFilterParams}
						modelProperty="dateRange"
						placeholder="All Start Dates"
						flatpickrOptions={{
							formatDate: (date: Date) => moment.utc(date).toDate().toLocaleDateString(),
							maxDate: moment().utc().toDate(),
						}}
						dateFormat="DD/MM/YYYY"
						onAfterChange={() => updateAuditFilterParams({ dateRange: auditFilterParams.dateRange })}
					/>
				</div>
			</If>
		</div>
	);
};

export default CustomerAuditsSearchAndFilterSection;
