import { useESignStore } from 'ESign/Stores/ESignStore';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import SigningStepHeader from './Gibs/SigningStepHeader';
import ESignSigningStage from 'ESign/Types/ESignSigningStage';
import SigningStepFooter from './Gibs/SigningStepFooter';
import ESignSigningSection from './ESignSigningSection';
import ESignWitnessDetailsSection from './ESignWitnessDetailsSection';
import ESignReviewSection from './ESignReviewSection';
import {
	Grid, GridColumn, GridRow,
} from 'semantic-ui-react';
import { initialError, initialStage, initialWitnessDetails } from 'ESign/Utils/DefaultSigningSteps';
import classNames from 'classnames';
import ErrorSection from './Error/ErrorSection';
import LoadableState from 'ESign/Types/ESignLoadableState';
import Spinner from 'Views/Components/Spinner/Spinner';
import alertToast from 'Util/ToastifyUtils';

type Props = {
	previousSection: () => void;
	nextSection: () => void;
}

const ESignSigningSteps:React.FunctionComponent<Props> = ({
	previousSection,
	nextSection,
}) => {
	const {
		workflowStore: {
			selectedCeremony,
			saveTemplateContent,
			rejectCeremony,
			userSummary: { signature, id },
			loadingState,
			isWorkflowExpired,
			getUserSummary,
		},
	} = useESignStore();

	const [currentStage, setCurrentStage] = useState<ESignSigningStage>(initialStage(selectedCeremony));
	const [templateContent, setTemplateContent] = useState(selectedCeremony?.content ?? '');
	const [updatedSignature, setUpdatedSignature] = useState(signature ?? '');
	const [witnessDetails, setWitnessDetails] = useState(initialWitnessDetails(selectedCeremony));
	const [hasError, setHasError] = useState(initialError(selectedCeremony, templateContent, witnessDetails));

	const renderContent = (stage: ESignSigningStage) => {
		switch (stage) {
			case 'Content':
				return (
					<ESignSigningSection
						templateContent={templateContent}
						updateTemplateContent={(content, hasCompleted, value) => {
							setTemplateContent(content);
							setUpdatedSignature(value);
							setHasError(prevState => ({
								...prevState,
								Content: !hasCompleted,
							}));
						}}
					/>
				);
			case 'Witness':
				return (
					<ESignWitnessDetailsSection
						witnessDetails={witnessDetails ?? { name: '', email: '' }}
						setWitnessDetails={details => setWitnessDetails(details)}
						requireMobileNumber={selectedCeremony?.workflow.templateContent.useSmsAuthentication ?? false}
						handleError={value => {
							setHasError(prevState => ({
								...prevState,
								Witness: value,
							}));
						}}
					/>
				);
			case 'Review':
				return (
					<ESignReviewSection
						templateContent={templateContent}
						witnessDetails={witnessDetails}
					/>
				);
		}
	};

	useEffect(() => {
		if (loadingState === LoadableState.FulFilled && isWorkflowExpired) {
			alertToast('The document has expired. Please contact sender and refresh page to view document', 'error');
			previousSection();
		}
	}, [loadingState, isWorkflowExpired, previousSection, getUserSummary, id]);

	if (selectedCeremony === undefined) {
		return (
			<ErrorSection title="An error has occurred." message="Please refresh the page and try again." />
		);
	}

	if (loadingState === LoadableState.Pending) {
		return (<Spinner />);
	}

	return (
		<Grid className="esign-ceremony-section" padded="horizontally">
			<GridRow className="esign-ceremony-actions">
				<GridColumn>
					<SigningStepHeader
						stage={currentStage}
						documentName={selectedCeremony.workflow.template.name}
						handleSaveAndExit={async () => {
							await saveTemplateContent(templateContent, updatedSignature);
							previousSection();
						}}
						handleReject={async comment => {
							await rejectCeremony(comment);
							previousSection();
						}}
					/>
				</GridColumn>
			</GridRow>
			<GridRow className="esign-ceremony-content">
				<GridColumn className={classNames(
					'esign-ceremony-content-section',
					{ 'set-center': currentStage === 'Witness' },
				)}
				>
					{renderContent(currentStage)}
				</GridColumn>
			</GridRow>
			<GridRow className="esign-ceremony-actions">
				<GridColumn>
					<SigningStepFooter
						templateContent={templateContent}
						updatedSignature={updatedSignature}
						witnessDetail={witnessDetails}
						previousSection={previousSection}
						nextSection={nextSection}
						stage={currentStage}
						changeStage={value => { setCurrentStage(value); }}
						hasError={hasError}
					/>
				</GridColumn>
			</GridRow>
		</Grid>
	);
};

export default observer(ESignSigningSteps);
