/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import {
	AddressEntity,
	ApplicationEntity,
	BusinessEntity,
	IndustryCodesEntity,
	OrganisationEntity,
	SpgEntity,
} from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import If from 'Views/Components/If/If';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import alertToast from 'Util/ToastifyUtils';
import BusinessEntityList from 'Views/Components/BusinessEntities/BusinessEntityList';
import OrganisationDetails from 'Views/Components/Organisation/OrganisationDetails';
import OrganisationMonitor from 'Views/Components/Organisation/OrganisationMonitor';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { SERVER_URL } from 'Constants';
import OrganisationProductsSelector from 'Views/Components/Products/OrganisationProductsSelector';
import useAsync from 'Hooks/useAsync';
import { useEffect, useState } from 'react';
import moment from 'moment';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface OrganisationPageProps {
	organisationId?: string,
}

const OrganisationPage = (props: RouteComponentProps<OrganisationPageProps>) => {
	const {
		match,
		location,
	} = props;

	const { organisationId } = match.params;
	// If we haven't been passed an organisation ID, we use the current user's
	const relevantOrganisationId = organisationId ?? store.getUser?.organisation?.id;
	const { path } = match;

	const [name, setName] = useState('');

	const organisation = useAsync(async () => {
		const org = await fetchOrganisationData();
		setName(org.primaryBusinessEntity.name);
		return org;
	}, [organisationId]);

	const showProductsTab = store.userPermissions.commonManageOrganisations !== 'NONE';
	const showIntelTab = (organisation.data?.intelEnabled && store.canAccessIntel);
	const fetchOrganisationData = async (): Promise<OrganisationEntity> => {
		const results = await store.apolloClient.query({
			query: gql`
				query fetchOrganisationById($organisationId: ID) {
					organisationEntity(id: $organisationId) {
						${OrganisationEntity.getAllAttributes().join('\n')}
						primaryBusinessEntity {
							${BusinessEntity.getAllAttributes().join('\n')}
							address {
								${AddressEntity.getAllAttributes().join('\n')}
							}
						}
						authorisedCreditBureaus {
							id
							authorisedCreditBureauId
						}
						industryCodes {
							${IndustryCodesEntity.getAllAttributes().join('\n')}
						}
						registration {
							id
							registrationData
						}
						users {
							id
						}
						businessEntitys {
							${BusinessEntity.getAllAttributes().join('\n')}
							address {
								${AddressEntity.getAllAttributes().join('\n')}
							}
							primaryOrganisation {
								intelEnabled
							}
							spgss {
								${SpgEntity.getAllAttributes().join('\n')}
							}
							applicationss {
								${ApplicationEntity.getAllAttributes().join('\n')}
							}
						}
						discountss (where: [ {path: "expirationDate", comparison: greaterThanOrEqual,
								value: ["${moment().utc().format('YYYY-MM-DD')}"] }]) {
							id
							discountPercent
							expirationDate
							source
						}
					}
				}`,
			variables: {
				organisationId: relevantOrganisationId,
			},
			fetchPolicy: 'no-cache',
		});

		if (!!results.data.organisationEntity) {
			return observable(new OrganisationEntity(results.data.organisationEntity));
		}
		throw new Error();
	};

	if (organisation.type === 'error') {
		alertToast('Unable to find matching organisation', 'error');
		const redirectUrl = organisationId != null
			? '/hub/clients'
			: '/';
		store.routerHistory.push(redirectUrl);
		return <Spinner />;
	}

	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content organisation-page">
				<div className="invisible-page-wrap">
					<If condition={organisation.type === 'loading'}>
						<InlineSpinner />
					</If>
					{organisation.type === 'data' && (
						<>
							<div className="top-container">
								<PageBreadcrumbs
									tags={
										organisationId != null
											? [
												{ label: 'Clients', link: '/hub/clients' },
												{ label: name },
											]
											: [
												{ label: 'Organisation' },
											]
									}
								/>
								{organisationId != null ? (
									<div>
										<Button
											display={Display.Outline}
											colors={Colors.Primary}
											onClick={() => store.routerHistory.push(
												`${SERVER_URL}/hub/registrations/${organisation.data.registration?.id}`,
											)}
										>
											Go to Registration Details
										</Button>
									</div>
								) : null}
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								<button
									className={classNames('tab-selector', 'organisation-details', {
										active: !location.pathname.includes('business-entities')
											&& !location.pathname.includes('products')
											&& !location.pathname.includes('monitor'),
									})}
									{...HandleEvents(() => {
										if (relevantOrganisationId !== organisationId) {
											store.routerHistory.push('/hub/organisation');
										} else {
											store.routerHistory.push(`/hub/clients/${organisationId}`);
										}
									})}
								>
									Organisation details
								</button>
								<button
									className={classNames('tab-selector', 'business-entities', {
										active: location.pathname.includes('business-entities'),
									})}
									{...HandleEvents(() => {
										if (relevantOrganisationId !== organisationId) {
											store.routerHistory.push('/hub/organisation/business-entities');
										} else {
											store.routerHistory.push(
												`/hub/clients/${organisationId}/business-entities`,
											);
										}
									})}
								>
									Business entities
								</button>
								<If condition={showProductsTab}>
									<button
										className={classNames('tab-selector', 'products', {
											active: location.pathname.includes('products'),
										})}
										{...HandleEvents(() => {
											if (relevantOrganisationId !== organisationId) {
												store.routerHistory.push('/hub/organisation/products');
											} else {
												store.routerHistory.push(`/hub/clients/${organisationId}/products`);
											}
										})}
									>
										Products
									</button>
								</If>
								<If condition={showIntelTab}>
									<button
										className={classNames('tab-selector', 'intel', {
											active: location.pathname.includes('monitor'),
										})}
										{...HandleEvents(() => {
											if (relevantOrganisationId !== organisationId) {
												store.routerHistory.push('/hub/organisation/monitor');
											} else {
												store.routerHistory.push(`/hub/clients/${organisationId}/monitor`);
											}
										})}
									>
										Monitor
									</button>
								</If>
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': !location.pathname.includes('business-entities')
									&& !location.pathname.includes('products')
									&& !location.pathname.includes('monitor'),
								'inner-padding': location.pathname.includes('products'),
							})}
							>
								<Switch>
									<Route
										path={`${path}/business-entities/:id?`}
										// eslint-disable-next-line react/no-unstable-nested-components
										component={(routeProps: RouteComponentProps) => {
											const { params } : { [key : string] : any } = routeProps.match;
											return !!organisation.data.businessEntitys
												? (
													<BusinessEntityList
														organisation={organisation.data}
														currentPath={relevantOrganisationId
														!== organisationId ? '/hub/organisation/business-entities'
															: `/hub/clients/${organisationId}/business-entities`}
													/>
												)
												: null;
										}}
									/>
									<Route
										path={`${path}/products`}
										component={() => {
											return (
												<OrganisationProductsSelector
													organisation={organisation.data}
													fetchOrganisation={() => organisation.refresh()}
												/>
											);
										}}
									/>
									<Route
										path={`${path}/monitor/:integrationPartner?`}
										component={() => {
											return (
												<OrganisationMonitor
													originalOrganisationEntity={organisation.data}
												/>
											);
										}}
									/>
									<Route
										path="/"
										component={() => (
											<OrganisationDetails
												originalOrganisationEntity={organisation.data}
												setName={setName}
											/>
										)}
									/>
								</Switch>
							</div>
						</>
					)}
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default OrganisationPage;
