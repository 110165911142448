/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserCreditBureauEntity from 'Models/Security/Acl/UserCreditBureauEntity';
import VisitorsCreditBureauEntity from 'Models/Security/Acl/VisitorsCreditBureauEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import { computed, runInAction } from 'mobx';
// % protected region % [Add any further imports here] end

export interface ICreditBureauEntityAttributes extends IModelAttributes {
	name: string;
	humanReadableName: string;

	organisations: Array<
		| Models.OrganisationAuthorisedCreditBureau
		| Models.IOrganisationAuthorisedCreditBureauAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CreditBureauEntity', 'Credit Bureau')
// % protected region % [Customise your entity metadata here] end
export default class CreditBureauEntity extends Model
	implements ICreditBureauEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserCreditBureauEntity(),
		new VisitorsCreditBureauEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Name
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<CreditBureauEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Name'] off begin
	/**
	 * Human Readable version of name
	 */
	@observable
	@attribute<CreditBureauEntity, string>()
	@CRUD({
		name: 'Human Readable Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public humanReadableName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Name'] end

	/**
	 * Credit bureaus the organisation has agreed to share data with
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationAuthorisedCreditBureau,
		optionEqualFunc: makeJoinEqualsFunc('organisationId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'creditBureauEntity',
			oppositeEntityName: 'organisationEntity',
			relationName: 'authorisedCreditBureau',
			relationOppositeName: 'organisation',
			entity: () => Models.CreditBureauEntity,
			joinEntity: () => Models.OrganisationAuthorisedCreditBureau,
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisations: Models.OrganisationAuthorisedCreditBureau[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICreditBureauEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICreditBureauEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.humanReadableName !== undefined) {
				this.humanReadableName = attributes.humanReadableName;
			}
			if (attributes.organisations !== undefined && Array.isArray(attributes.organisations)) {
				for (const model of attributes.organisations) {
					if (model instanceof Models.OrganisationAuthorisedCreditBureau) {
						this.organisations.push(model);
					} else {
						this.organisations.push(new Models.OrganisationAuthorisedCreditBureau(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisations {
			${Models.OrganisationAuthorisedCreditBureau.getAttributes().join('\n')}
			organisation {
				${Models.OrganisationEntity.getAttributes().join('\n')}
				${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			organisations: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'organisations',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	// We need credit bureaus in a lot of places throughout the app, and it's unlikely to change between requests,
	// so we use the store to cache the value to prevent fetching it more than once
	@observable private static _creditBureaus = [] as CreditBureauEntity[];
	@observable private static _promise = null as Promise<CreditBureauEntity[]>|null;
	public static creditBureaus = async (): Promise<CreditBureauEntity[]> => {
		if (CreditBureauEntity._promise) {
			return CreditBureauEntity._promise;
		}

		CreditBureauEntity._promise = new Promise(resolve => {
			store.apolloClient.query({
				query: gql`
					query {
						creditBureauEntitys (orderBy: [{path: "name"}]) {
							id
							name
						}
					}
				`,
				fetchPolicy: 'no-cache',
			}).then(({ data }) => {
				runInAction(() => {
					CreditBureauEntity._creditBureaus = data.creditBureauEntitys
						.map((bureau: ICreditBureauEntityAttributes) => new CreditBureauEntity(bureau));

					resolve(CreditBureauEntity._creditBureaus);
				});
			});
		});

		return CreditBureauEntity._promise;
	}

	// If we want to get the credit bureaus, but can't wait, then this will return the current value
	// It will still fetch the credit bureaus, but you won't see the results until a future call
	// If called from within an observer, though, it'll still rerender because this is a computed field
	@computed
	public static get creditBureausSync() {
		CreditBureauEntity.creditBureaus();
		return CreditBureauEntity._creditBureaus;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CreditBureauEntity.prototype, 'created');
CRUD(modifiedAttr)(CreditBureauEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
