import {
	Button, Colors, Display,
} from 'Views/Components/Button/Button';
import React, { useCallback, useEffect, useState } from 'react';

type NavigationButtonProps = {
	inputFieldWithValue: Record<string, string>;
}

const NavigationButton: React.FunctionComponent<NavigationButtonProps> = ({
	inputFieldWithValue,
}) => {
	const elementIds = Object.keys(inputFieldWithValue).filter(key => {
		if (key.includes('date-signed')) {
			return false;
		}
		return true;
	});

	const [currentElementIndex, setCurrentElementIndex] = useState<number>(0);

	const placeButtonNextToElement = useCallback((elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			const button = document.getElementById('navigation-button');
			if (button) {
				element.parentNode?.insertBefore(button, element.nextSibling);
			}
		}
	}, []);

	useEffect(() => {
		placeButtonNextToElement(elementIds[currentElementIndex]);
	}, [currentElementIndex, elementIds, placeButtonNextToElement]);

	const scrollToElement = (element: HTMLElement) => {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	const handleNext = () => {
		if (currentElementIndex < elementIds.length - 1) {
			const element = document.getElementById(elementIds[currentElementIndex + 1]);
			if (element) {
				element.focus();
				scrollToElement(element);
			}
			setCurrentElementIndex(currentElementIndex + 1);
		} else {
			const element = document.getElementById(elementIds[0]);
			if (element) {
				scrollToElement(element);
			}
			setCurrentElementIndex(0);
		}
	};

	return (
		<Button
			buttonProps={{ id: 'navigation-button' }}
			display={Display.Solid}
			colors={Colors.Primary}
			onClick={handleNext}
		>
			{currentElementIndex === 0 ? 'Start' : 'Next'}
		</Button>
	);
};

export default NavigationButton;
