/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import {
	Grid, GridColumn, GridRow, Header, Icon, List, ListContent, ListHeader, ListItem, ListList, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow,
} from 'semantic-ui-react';
import { DocumentContentDetail } from '../Gibs/TemplateContent';
import { observer } from 'mobx-react';
import HtmlTemplateUtil from './HtmlTemplateUtil';

type Props = {
	numberOfSignatory: number;
	requireWitness: boolean;
	documentContentDetail: DocumentContentDetail;
}

const ESignTemplateContentInputFields:React.FunctionComponent<Props> = observer(({
	numberOfSignatory,
	requireWitness,
	documentContentDetail,
}) => {
	const requiredFieldsWithIdAndName = HtmlTemplateUtil.requiredFieldsWithIdAndNames(numberOfSignatory, requireWitness);
	const getFieldIdFromHtmlString = HtmlTemplateUtil.getFieldIdFromHtmlString(documentContentDetail.htmlString);
	const matchRequiredFieldIdsWithAvailableIds = HtmlTemplateUtil.matchRequiredFieldIdsWithAvailableIds(documentContentDetail.htmlString, numberOfSignatory, requireWitness);
	const availablePreFillFields = HtmlTemplateUtil.availablePreFillFields(documentContentDetail.htmlString);

	return (
		<Grid>
			<GridRow>
				<GridColumn width={8}>
					<List bulleted>
						<ListItem>
							<ListHeader>
								<Header size="small">
									Required Field Id
								</Header>
							</ListHeader>
							<ListList>
								{Object.entries(requiredFieldsWithIdAndName).map(([key, value]) => {
									return (
										<ListItem key={key}>
											<ListHeader>{key}</ListHeader>
											<ListContent>
												<Table celled>
													<TableHeader>
														<TableRow>
															<TableHeaderCell>Field Type</TableHeaderCell>
															<TableHeaderCell>Field Id</TableHeaderCell>
															<TableHeaderCell>Field Name</TableHeaderCell>
														</TableRow>
													</TableHeader>
													<TableBody>
														<TableRow>
															<TableCell>Signature</TableCell>
															<TableCell>{value.signature}</TableCell>
															<TableCell>
																<Icon
																	name={
																		matchRequiredFieldIdsWithAvailableIds.includes(value.signature)
																			? 'check' : 'close'
																	}
																	color={
																		matchRequiredFieldIdsWithAvailableIds.includes(value.signature)
																			? 'green' : 'red'
																	}
																/>
															</TableCell>
														</TableRow>
														{value.witness && (
															<TableRow>
																<TableCell>Witness</TableCell>
																<TableCell>{value.witness}</TableCell>
																<TableCell><Icon
																	name={
																		matchRequiredFieldIdsWithAvailableIds.includes(value.witness)
																			? 'check' : 'close'
																	}
																	color={
																		matchRequiredFieldIdsWithAvailableIds.includes(value.witness)
																			? 'green' : 'red'
																	}
																/>
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</ListContent>
										</ListItem>
									);
								})}
							</ListList>
						</ListItem>
					</List>
				</GridColumn>
				<GridColumn width={4}>
					<GridRow>
						<List>
							<ListHeader>
								<Header size="small">
									Available Pre-fill Fields
								</Header>
							</ListHeader>
							<ListList as="ol">
								{Object.entries(availablePreFillFields).map(([key, value]) => {
									return (
										<ListItem key={key} as="li">
											<span>{key}</span>
											<List bulleted>
												{value.map((item, index) => {
													return (
														<ListItem key={index}>
															<span>{item}</span>
														</ListItem>
													);
												})}
											</List>
										</ListItem>
									);
								})}
							</ListList>
						</List>
					</GridRow>
				</GridColumn>
				<GridColumn width={4}>
					<GridRow>
						<List>
							<ListHeader>
								<Header size="small">
									Available Field Ids
								</Header>
							</ListHeader>
							<ListList as="ol">
								{getFieldIdFromHtmlString.map(id => {
									return (
										<ListItem key={id} as="li">
											<span>{id}</span>
										</ListItem>
									);
								})}
							</ListList>
						</List>
					</GridRow>
				</GridColumn>
			</GridRow>
		</Grid>
	);
});

export default ESignTemplateContentInputFields;
