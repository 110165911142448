import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import ClientList from 'Views/Components/Clients/ClientList';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface ClientListPageProps extends RouteComponentProps {
}

const ClientListPage = (props: ClientListPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content list-page">
				<PageBreadcrumbs
					tags={
						[
							{ label: 'Clients' },
						]
					}
				/>
				<ClientList />
			</div>
		</AccessIntelSecuredPage>
	);
};
export default ClientListPage;
