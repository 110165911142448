/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserCreditReport from 'Models/Security/Acl/UserCreditReport';
import VisitorsCreditReport from 'Models/Security/Acl/VisitorsCreditReport';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICreditReportEntityAttributes extends IModelAttributes {
	reportcontent: string;
	status: Enums.creditReportStatus;
	filepath: string;
	retrivalDate: Date;

	monitoredId: string;
	monitored: Models.MonitoredEntity | Models.IMonitoredEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CreditReportEntity', 'Credit Report')
// % protected region % [Customise your entity metadata here] end
export default class CreditReportEntity extends Model
	implements ICreditReportEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserCreditReport(),
		new VisitorsCreditReport(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'ReportContent'] off begin
	@observable
	@attribute<CreditReportEntity, string>()
	@CRUD({
		name: 'ReportContent',
		displayType: 'textarea',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportcontent: string;
	// % protected region % [Modify props to the crud options here for attribute 'ReportContent'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<CreditReportEntity, Enums.creditReportStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.creditReportStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.creditReportStatusOptions),
		displayFunction: (attr: Enums.creditReportStatus) => Enums.creditReportStatusOptions[attr],
	})
	public status: Enums.creditReportStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'FilePath'] off begin
	@observable
	@attribute<CreditReportEntity, string>()
	@CRUD({
		name: 'FilePath',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public filepath: string;
	// % protected region % [Modify props to the crud options here for attribute 'FilePath'] end

	// % protected region % [Modify props to the crud options here for attribute 'Retrival Date'] off begin
	@observable
	@attribute<CreditReportEntity, Date>()
	@CRUD({
		name: 'Retrival Date',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public retrivalDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Retrival Date'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Monitored'] off begin
		name: 'Monitored',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.MonitoredEntity,
		// % protected region % [Modify props to the crud options here for reference 'Monitored'] end
	})
	public monitoredId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public monitored: Models.MonitoredEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICreditReportEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICreditReportEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.reportcontent !== undefined) {
				this.reportcontent = attributes.reportcontent;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.filepath !== undefined) {
				this.filepath = attributes.filepath;
			}
			if (attributes.retrivalDate !== undefined) {
				if (attributes.retrivalDate === null) {
					this.retrivalDate = attributes.retrivalDate;
				} else {
					this.retrivalDate = moment(attributes.retrivalDate).toDate();
				}
			}
			if (attributes.monitoredId !== undefined) {
				this.monitoredId = attributes.monitoredId;
			}
			if (attributes.monitored !== undefined) {
				if (attributes.monitored === null) {
					this.monitored = attributes.monitored;
				} else if (attributes.monitored instanceof Models.MonitoredEntity) {
					this.monitored = attributes.monitored;
					this.monitoredId = attributes.monitored.id;
				} else {
					this.monitored = new Models.MonitoredEntity(attributes.monitored);
					this.monitoredId = this.monitored.id;
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		monitored {
			${Models.MonitoredEntity.getAttributes().join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			reportRequests: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CreditReportEntity.prototype, 'created');
CRUD(modifiedAttr)(CreditReportEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
