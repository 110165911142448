import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RegistrationData from 'Models/Entities/RegistrationData';
import alertToast from 'Util/ToastifyUtils';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import ToggleSwitch from 'Views/Components/ToggleSwitch/ToggleSwitch';
import { action } from 'mobx';
import If from 'Views/Components/If/If';
import ApproveFilesManagement from 'Views/Components/Approve/ApproveFilesManagement';
import { ApplicationEntity, BusinessEntity, RegistrationEntity } from 'Models/Entities';
import { TextField } from 'Views/Components/TextBox/TextBox';
import moment from 'moment';

const SetupApplicationsStep = observer((props: WizardComponentProps) => {
	const { changePage } = props;
	const { currentStep } = props;
	const { model, data }: { model: RegistrationData, data?: RegistrationEntity } = props;

	const onNext = async () => {
		if (!model.businessEntitys?.some(x => x.enabledForApprove)) {
			alertToast('You will need at least one Digital Online Application to use the Approve product.',
				'error');
			return;
		}

		const validatePromises: Promise<void>[] = [];
		model.businessEntitys?.forEach(x => {
			validatePromises.push(x.validateField('approveEmail'));
			validatePromises.push(x.validateField('applicationName'));
		});
		if (validatePromises) {
			await Promise.all(validatePromises);
		}
		if (!model.businessEntitys?.every(x => Object.keys(x.errors).length === 0)) {
			return;
		}

		changePage();
	};

	const onChange = async (businessEntity: BusinessEntity, field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!businessEntity.errors[field]) {
			await businessEntity.validateField(field);
		}
	};

	const digitalApplicationTooltip = 'Use Approve for simple credit look ups to help make a decision on potential '
    + 'customers and/or setup a digital online form based on your existing trade credit application.';
	return (
		<div className="step-wrap setup-applications-step">
			<div className="step-contents align-one-fourth">
				<div className="alignment-box">
					<h5 className="step-header">
						<span>Setup your Access Approve</span>
						<Tooltip
							id="digital-application-tooltip"
							content={digitalApplicationTooltip}
						/>
					</h5>

					<div className="digital-application-list">
						<p className="step-description">
							Please identify your supplying entities:
						</p>
						{model.businessEntitys?.map((businessEntity, index) => {
							// This is a hacky way to bypass eslint's complaints about putting the index in the key
							// We do this because the usual eslint comment is breaking a different eslint rule
							// We don't expect the business entity list to change once we're on this step, so
							// we're not worried about losing performance by rerendering items. However, a new
							// registration has no business entity IDs, and the user could have used duplicate names
							// so this is necessary to prevent duplicate keys.
							const arrayIndex = index;
							return (
								<div
									key={`${businessEntity.id} ${businessEntity.name} ${arrayIndex}`}
									className="business-entity-wrap"
								>
									<div className="limit-width-box">
										<div className="approve-business-entity">
											{/* top container with slider */}
											<div>
												<h6>{businessEntity.name}</h6>
												<p>ABN {businessEntity.abn}</p>
											</div>
											<ToggleSwitch
												model={businessEntity}
												modelProperty="enabledForApprove"
												onAfterChecked={action(() => {
													if (businessEntity.enabledForApprove) {
														businessEntity.applicationss.push(
															new ApplicationEntity({
																businessEntityId: businessEntity.id,
																isActive: true,
																applicationName: businessEntity.name,
																applicationDate: moment().utc().toDate(),
															}),
														);
														businessEntity.applicationss = businessEntity.applicationss
															.slice().reverse();
													} else {
														businessEntity.applicationss.shift();
													}
													['approveEmail'].forEach(field => {
														delete businessEntity.errors[field];
													});
												})}
											/>
										</div>
										<If condition={businessEntity.enabledForApprove}>
											<TextField
												model={businessEntity.applicationss[0]}
												modelProperty="applicationName"
												className="application-name"
												label="Application name"
												placeholder="Application name"
												onChangeAndBlur={() => {
													onChange(businessEntity, 'applicationName', true);
												}}
												onAfterChange={() => { onChange(businessEntity, 'applicationName'); }}
												errors={businessEntity.errors.applicationName}
												isRequired
											/>
											<TextField
												className="email"
												model={businessEntity.applicationss[0]}
												modelProperty="email"
												label="Credit email address"
												placeholder="Email address"
												onChangeAndBlur={() => onChange(businessEntity, 'approveEmail', true)}
												onAfterChange={() => onChange(businessEntity, 'approveEmail')}
												errors={businessEntity.errors.approveEmail}
												isRequired
											/>
											<ApproveFilesManagement
												businessEntity={businessEntity}
												registrationEntity={data}
												key={`${businessEntity.id} ${businessEntity.name} ${arrayIndex}`}
											/>
										</If>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right next-btn"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onNext}
				>
					{!!currentStep.options?.showSubmit ? 'Submit' : 'Next'}
				</Button>
			</div>
		</div>
	);
});

export default SetupApplicationsStep;
