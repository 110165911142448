/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { Gauge, GaugeConfig } from "@ant-design/plots";

export interface ProductGaugeProps {
    MinValue?: number;
    MaxValue: number;
    CurrentValue: number;
    Color: string;
    Label: string;
    ItemsName: string;
}

const ProductGauge = ({ MinValue, MaxValue, CurrentValue, Color, Label, ItemsName }: ProductGaugeProps) => {
	const percentage = (CurrentValue - (MinValue ?? 0)) / (MaxValue - (MinValue ?? 0));
    const config: GaugeConfig = {  
        animation: false,
        useDeferredLabel: true,
        startAngle: Math.PI,
        endAngle: Math.PI * 2,
        percent: percentage,
        indicator: undefined,
        padding: [5,0,36,0],
        gaugeStyle: {
            lineCap: "round",
        },
        range: {
            color: Color,
            width: 40,
        },
        statistic: {
            content: {
                content: CurrentValue.toLocaleString(),
                style: { fontSize: "48px", color: "black" },
                offsetY: -20
            },
            title: {
                content: `of ${MaxValue.toLocaleString()} ${ItemsName}`,
                style: { fontSize: "16px", lineHeight:"52px", color: "black" },
                offsetY: -20
            }
        }
    }

    return (
        <>
            <h5>{Label}</h5>
            <Gauge {...config}/>
        </>
    );
}

export default ProductGauge;