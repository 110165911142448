import Modal from 'Views/Components/Modal/Modal';
import InputWrapper, { InputType } from 'Views/Components/Inputs/InputWrapper';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { UserEntity } from 'Models/Entities';
import HandleEvents from 'Util/HandleEvents';
import { capitalize } from 'lodash';

export interface IResetPasswordState {
	token: string;
	currentPassword: string,
	username: string;
	password: string;
	confirmPassword: string,
	errors: { [attr: string]: string };
}

const defaultResetPasswordState: IResetPasswordState = {
	token: '',
	username: '',
	currentPassword: '',
	password: '',
	confirmPassword: '',
	errors: {},
};

export interface ResetPasswordModalProps {
	showModal: boolean,
	user: UserEntity,
	onModalClose?: () => void,
}

function ResetPasswordModal(props: ResetPasswordModalProps) {
	const { showModal, user, onModalClose } = props;

	const [state, setState] = useState(defaultResetPasswordState);
	const isMyAccount = store.userId === user.id;
	const whoseAccount = isMyAccount ? 'your' : "user's";
	const isSuper = store.userType === 'SUPER_USER';

	const onClose = () => {
		setState(defaultResetPasswordState);

		if (!!onModalClose) {
			onModalClose();
		}
	};

	/**
	 * Method to change the specified (param) attribute of the reset password state to the value in the event target.
	 * @param event The event param from the Change Event Handler, event.target.value holds the value of the input
	 * @param param The name of the attribute in the reset password state to be changed/set
	 */
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
		const updatedPasswordState: IResetPasswordState = { ...state };
		updatedPasswordState[param] = event.target.value;
		setState(updatedPasswordState);
	};

	/**
	 * Method to determine if the passwords are valid.
	 * - All password fields must be present
	 * - New Password must be a minimum length of 6
	 * - New Password and Confirm New Password must match
	 * @param event The event parameter passed in by the React Form Handler
	 */
	const onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Store the current state to be changed and set later
		const updatedPasswordState: IResetPasswordState = { ...state };

		// Clear the error list
		updatedPasswordState.errors = {};

		// Check the validity of the password fields and add to the error list if not valid
		if (isMyAccount && !updatedPasswordState.currentPassword) {
			updatedPasswordState.errors.currentPassword = 'Current Password is required';
		} else if (!updatedPasswordState.password) {
			updatedPasswordState.errors.password = 'Password is required';
		} else if (updatedPasswordState.password.length < 12) {
			updatedPasswordState.errors.password = 'The minimum length of password is 12';
		} else if (!updatedPasswordState.confirmPassword) {
			updatedPasswordState.errors.confirmPassword = 'Confirm password is required';
		} else if (updatedPasswordState.confirmPassword !== updatedPasswordState.password) {
			updatedPasswordState.errors.confirmPassword = 'Password and confirm password does not match';
		}

		// Store the errors in the reset password state
		setState(updatedPasswordState);

		// If there are no errors then we post to the endpoint
		if (Object.keys(updatedPasswordState.errors).length <= 0) {
			axios.post(
				`${SERVER_URL}/api/account/UpdatePassword`,
				{
					Id: user.id,
					CurrentPassword: state.currentPassword,
					NewPassword: state.password,
				},
			)
				.then(({ data }) => {
					alertToast(`${capitalize(whoseAccount)} password has successfully been updated`, 'success');
					onClose();
					// store.setLoggedInUser(data);
					// me();
				})
				.catch(({ response }) => {
					const errorMessage = response.status === 401 ? 'Incorrect current password' : 'Authorization Error';
					alertToast(`Password could not be changed: ${errorMessage}`, 'error');
				});
		}
	};

	return (
		<Modal
			isOpen={showModal}
			label="Reset password"
			onRequestClose={onClose}
			className="access-modal reset-password-modal"
		>
			<div className="update-modal">
				<div className="update-form">
					<form className="reset-password" onSubmit={onResetPasswordClicked}>
						<div className="title forgot-title">
							<h5>Reset {whoseAccount} password</h5>
						</div>
						<p className="forgot-password-desc">Please set a new password for {whoseAccount} account.</p>
						{isSuper && !isMyAccount ? null
							: (
								<InputWrapper
									inputType={InputType.PASSWORD}
									id="current_password"
									inputId="current_password-field"
									className="current-password"
									isRequired
									errors={state.errors.currentPassword}
									label="Current Password"
								>
									<input
										type="password"
										id="current_password-field"
										value={state.currentPassword}
										onChange={event => onInputChange(event, 'currentPassword')}
										placeholder="Current password"
									/>
								</InputWrapper>
							)}

						<InputWrapper
							inputType={InputType.PASSWORD}
							id="new_password"
							inputId="new_password-field"
							className="new-password"
							isRequired
							errors={state.errors.password}
							label="New Password"
						>
							<input
								type="password"
								id="new_password-field"
								value={state.password}
								onChange={event => onInputChange(event, 'password')}
								placeholder="New password"
							/>
						</InputWrapper>

						<InputWrapper
							inputType={InputType.PASSWORD}
							id="confirm_password"
							inputId="confirm_password-field"
							className="confirm-password"
							isRequired
							errors={state.errors.confirmPassword}
							label="Confirm New Password"
						>
							<input
								type="password"
								id="confirm_password-field"
								value={state.confirmPassword}
								onChange={event => onInputChange(event, 'confirmPassword')}
								placeholder="Confirm new password"
							/>
						</InputWrapper>

						<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirm-pwd-buttons">
							<Button
								type="button"
								className="cancel-btn"
								display={Display.Outline}
								sizes={Sizes.Medium}
								{...HandleEvents(onClose)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="confirm-reset-password"
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'confirm_reset_password' }}
							>
								Reset Password
							</Button>
						</ButtonGroup>
					</form>
				</div>
			</div>
		</Modal>
	);
}
export default ResetPasswordModal;
