import * as React from 'react';
import {
	AddressEntity, BusinessEntity, BusinessEntityUser, OrganisationEntity,
} from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import { store } from 'Models/Store';
import BusinessEntityListItem from 'Views/Components/BusinessEntities/BusinessEntityListItem';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { useState } from 'react';
import If from 'Views/Components/If/If';
import AddBusinessDetailsModal from 'Views/Components/BusinessEntities/AddBusinessDetailsModal';
import { action } from 'mobx';
import { businessEntitiesPermissionScopeOrder } from 'Models/Enums';

export type BusinessEntityListProps = {
	organisation: OrganisationEntity,
	businessEntities: BusinessEntity[],
	readonly?: boolean,
}

const RegistrationBusinessEntityList = observer((props: BusinessEntityListProps) => {
	const {
		organisation,
		businessEntities,
		// TODO - This component is only used in RegistrationBusinessEntityList. its always true.
		// User's cant add business entity in the registration page?
		// We can delete bunch of code in here.
		readonly,
	} = props;

	const [showAddModal, setShowAddModal] = useState(false);
	const newBusinessEntity = useLocalStore(() => new BusinessEntity());

	/**
	 * Function to determine if a business entity is readonly.
	 * A business entity is read-only if:
	 *     the readonly prop is true
	 *     OR if the user has less than WITHIN_ORGANISATION canManageBusinessEntities permission set AND if they
	 *         are not directly attached to the business entity.
	 * @param businessEntityId The ID of the business entity in the list
	 * @returns Whether or not the business entity is read-only
	 */
	const isReadOnly = (businessEntityId: string, isPrimary: boolean) => {
		if (readonly) {
			return true;
		}

		if (isPrimary) {
			return store.userPermissions.commonManageOrganisations === 'NONE';
		}

		switch (businessEntitiesPermissionScopeOrder[store.userPermissions.commonManageBusinessEntities ?? 'NONE']) {
			case (businessEntitiesPermissionScopeOrder.NONE):
				return true;
			case (businessEntitiesPermissionScopeOrder.WITHIN_BUSINESS_ENTITIES):
				return !store.getUser?.businessEntityIds?.includes(businessEntityId);
			case (businessEntitiesPermissionScopeOrder.WITHIN_ORGANISATION):
			case (businessEntitiesPermissionScopeOrder.ALL):
				return false;
			default:
				return true; // should never get down here but make read-only to be safe
		}
	};

	const showAddBusiness = !readonly && store.userPermissions.commonManageOrganisations !== 'NONE';

	return (
		<div className="registration-business-entity-list">
			<h3>Business entities</h3>
			<If condition={showAddBusiness}>
				<Button
					className="add-business-entity-btn icon-plus icon-left"
					onClick={() => setShowAddModal(true)}
					colors={Colors.Primary}
					display={Display.Outline}
				>
					Add business entity
				</Button>
			</If>
			<If condition={showAddModal}>
				<AddBusinessDetailsModal
					businessEntity={newBusinessEntity}
					organisationEmail={organisation?.primaryBusinessEntity?.email}
					onClose={() => setShowAddModal(false)}
					onSubmit={action(async () => {
						// Clone the business entity, so we can use the old object to add future business entities
						const businessEntityToAdd = new BusinessEntity(newBusinessEntity);
						businessEntityToAdd.organisationId = organisation.id;
						businessEntityToAdd.isActive = true;
						organisation.businessEntitys.push(businessEntityToAdd);
						store.getUser?.organisation?.businessEntitys.push(businessEntityToAdd);
						businessEntityToAdd.users = [new BusinessEntityUser({
							userId: store.getUser?.id,
						})];
						await businessEntityToAdd.save({
							address: {},
							users: {},
						});

						// Reset the original business entity
						newBusinessEntity.name = '';
						newBusinessEntity.abn = '';
						newBusinessEntity.phone = '';
						newBusinessEntity.email = '';
						newBusinessEntity.address = new AddressEntity();

						setShowAddModal(false);
					})}
					additionalValidationFields={['summary']}
					isPrimaryBusinessEntity={false}
				/>
			</If>

			<div className="entity-list-wrap">
				{businessEntities.map((businessEntity, index) => (
					<BusinessEntityListItem
						organisation={organisation}
						businessEntity={businessEntity}
						readonly={isReadOnly(businessEntity.id, (!!businessEntity.id
							? businessEntity.id === organisation.primaryBusinessEntityId
							: index === 0))}
						key={businessEntity.id + businessEntity.name}
						defaultOpen={false}
						isPrimary={!!businessEntity.id
							? businessEntity.id === organisation.primaryBusinessEntityId
							: index === 0} // If we're viewing a registration, this won't have an id, but the first will be primary
					/>
				))}
			</div>
		</div>
	);
});
export default RegistrationBusinessEntityList;
