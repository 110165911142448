import * as React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { Button, Colors, Display } from '../Button/Button';
import If from '../If/If';
import ExportCustomerInformationModal from './ExportCustomerInformationModal';

const MonitorExportCustomers = () => {
	const [showCustomerExportModal, setShowCustomerExportModal] = React.useState(false);
	return (
		<>
			<div>
				<h5>Customer Information</h5>
				<div>
					<div className="label-wrapper">
						<p>Export customer information</p>
						<Tooltip
							id="export-customers-tooltip"
							content="Export all customer information for a given month"
							place="right"
						/>
					</div>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						className="export-customers-button"
						onClick={() => setShowCustomerExportModal(true)}
					>
						Export CSV
					</Button>
				</div>
			</div>
			<If condition={showCustomerExportModal}>
				<ExportCustomerInformationModal onClose={() => setShowCustomerExportModal(false)} />
			</If>
		</>
	);
};

export default MonitorExportCustomers;
