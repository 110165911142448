import { EsigntemplateEntity, LogoEntity } from 'Models/Entities';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import alertToast from 'Util/ToastifyUtils';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import useHasChanged from 'Hooks/useHasChanged';
import { isEqual } from 'lodash';
import TemplateFields from './Gibs/TemplateFields';
import { store } from '../../../../Models/Store';
import ESignRequestTemplateModal from './ESignRequestTemplateModal';

interface Props {
	businessName: string;
	template: EsigntemplateEntity;
	updateTemplate: (template: EsigntemplateEntity) => void;
	enableRefresh: () => void;
	logos: LogoEntity[];
	isReadOnly?: boolean;
}
const BusinessEntityTemplate: React.FunctionComponent<Props> = observer(({
	businessName,
	template,
	updateTemplate,
	enableRefresh,
	logos,
	isReadOnly = true,
}) => {
	const [showTemplateDetail, setShowTemplateDetail] = React.useState(false);
	const [showRequestVersionModal, setShowRequestVersionModal] = useState(false);
	const initialVersion = useMemo(() => {
		const versions = template.esigntemplatecontents
			.slice()
			.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
			.map(content => content.version);

		return Math.max(...versions);
	}, [template.esigntemplatecontents]);

	const [selectedVersion, setSelectedVersion] = useState<{version: number}>({ version: initialVersion });

	const availableTemplateVersions = useMemo(() => {
		return template.esigntemplatecontents
			.slice()
			.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).map(content => ({
				key: content.version,
				value: content.version,
				display: `Version ${content.version} - ${moment(content.created).format('DD/MM/YYYY')}`,
			}));
	}, [template.esigntemplatecontents]);

	const [hasLogoChanged, setHasLogoChanged] = React.useState(false);

	// Save button should be disabled if nothing has changed
	// this assumes that the reference to errors always changes when an error
	// when template content is changed, the save button should be enabled
	// as template content change affect the versioning of the template.s
	const templateEntityHasChanged = useHasChanged(
		EsigntemplateEntity,
		template,
		['name', 'lifespanindays', 'reminderfrequencyindays', 'minimumnumberofsignees'],
		(oldValue, newValue) => !isEqual(oldValue, newValue),
		['errors'],
		(original, changed) => Object.keys(changed.errors).length > 0,
	);

	const hasTemplateContentChanged = useMemo(() => {
		const currentTemplateContent = template.esigntemplatecontents
			.find(content => content.version === selectedVersion.version);

		if (currentTemplateContent === undefined) {
			return false;
		}

		if (currentTemplateContent.id === undefined) {
			return true;
		}

		return false;
	}, [template.esigntemplatecontents, selectedVersion.version]);

	const saveDisabled = templateEntityHasChanged && !hasLogoChanged && !hasTemplateContentChanged;

	async function handleSaveChange() {
		try {
			await template.validateTemplateDetailsFields();

			if (Object.keys(template.errors).length > 0) {
				alertToast('Template could not be saved, '
					+ 'please correct the errors and try again', 'error');
				return;
			}
			await template.save({
				esigntemplatecontents: {},
			});
			enableRefresh();
			alertToast('Template successfully saved', 'success');
		} catch (exception) {
			alertToast('Template could not be saved, '
				+ 'please refresh and try again', 'error');
		}
	}

	return (
		<div className="template-item">
			<div className="template-item-label-area">
				<div className="template-item-details">
					<div className="template-item-name">
						{template.name}
					</div>
					<div className="template-item-date">
						Created date: {moment(template.created).format('DD/MM/YYYY')}
					</div>
				</div>
				<div className="template-item-version">
					<div className="template-item-version-container">
						<Combobox
							className={classNames(
								selectedVersion.version
									? 'active'
									: 'inactive',
								'active-dropdown',
							)}
							model={selectedVersion}
							modelProperty="version"
							label="Version number"
							labelVisible={false}
							searchable={false}
							options={availableTemplateVersions}
							onAfterChange={(_, data) => {
								setSelectedVersion({ version: data.value as number });
							}}
						/>
					</div>
				</div>
				<button
					type="button"
					className={classNames('expand-template-item', {
						expanded: showTemplateDetail,
					})}
					onClick={() => setShowTemplateDetail(oldValue => !oldValue)}
					aria-label="Expand template item"
				/>
			</div>
			{showTemplateDetail && (
				<>
					<div className="template-item-detail-area">
						<TemplateFields
							template={template}
							updateTemplate={(value, version) => {
								if (version !== selectedVersion.version) {
									setSelectedVersion({ version: version });
								}
								updateTemplate(value);
							}}
							currentVersion={selectedVersion.version}
							logos={logos}
							hasLogoChanged={value => { setHasLogoChanged(value); }}
							isReadOnly={isReadOnly}
						/>
					</div>
					<div>
						{!isReadOnly && (
							<ButtonAsyncState
								className="template-detail-save"
								display={Display.Solid}
								colors={Colors.Primary}
								readonly={isReadOnly || saveDisabled}
								onPress={() => handleSaveChange()}
							>
								Save Changes
							</ButtonAsyncState>
						)}
						{isReadOnly && (
							<>
								<Button
									className="template-detail-save"
									colors={Colors.Primary}
									sizes={Sizes.Medium}
									display={Display.Outline}
									icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
									onClick={() => setShowRequestVersionModal(true)}
									disabled={!(store.userType === 'ADMIN' || store.userType === 'ORGANISATION_MANAGER')}
								>
									Request new version
								</Button>
								{showRequestVersionModal && (
									<ESignRequestTemplateModal
										businessEntityName={businessName}
										esigntemplateEntity={template}
										onClose={() => setShowRequestVersionModal(false)}
										isNewTemplate={false}
									/>
								)}
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
});

export default BusinessEntityTemplate;
