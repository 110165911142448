import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import ReferralPartnerBenefitsFields from '../../Organisation/ReferralPartnerBenefitsFields';
import { action, runInAction } from 'mobx';
import { RadioButtonGroup } from '../../RadioButton/RadioButtonGroup';
import RadioButtonGroupSetter from '../../RadioButton/RadioButtonGroupSetter';

const ReferralPartnerBenefitsStep = observer((props: WizardComponentProps) => {
	const { model, changePage } = props;

	const onNext = async () => {
		if (termsAccepted !== 'Yes') {
			setErrorMessage('Terms & conditions must be accepted to proceed');
			return;
		}

		if (errorMessage !== '') {
			return;
		}

		changePage();
	};

	const [termsAccepted, setTermsAccepted] = React.useState(model.termsAccepted ? 'Yes' : '' as ('' | 'Yes' | 'No'));
	const [errorMessage, setErrorMessage] = React.useState('' as string);

	return (
		<div className="step-wrap">
			<div className="step-contents registration-step terms-step align-one-third">
				<div className="alignment-box" ref={props.alignmentBoxRef}>
					<h5 className="step-header">Terms & conditions</h5>
					<div className="content-box">
						<div>
							<p>
								Access Intell may offer services within its platforms or refer clients to complimentary
								business services.  Access Intell will not offer clients referred by you any services in
								the category you have selected i.e. if you have selected debt collector.
							</p>
							<p>
								Access Intell will not offer alternative debt collection services to clients introduced
								by you for the duration of the referral period unless the client specifically requests
								those services be available to them within the platforms.
							</p>
							<p>
								Please read the full {' '}
								<a href="/api/files/document/referral-partner-terms" target="_blank">
									Terms &amp; Conditions
								</a>
							</p>
						</div>
						<RadioButtonGroupSetter
							className="terms-and-conditions"
							value={termsAccepted}
							setValue={setTermsAccepted}
							options={[
								{ value: 'Yes', display: 'Yes, I accept' },
								{ value: 'No', display: 'No, I do not accept' },
							]}
							onAfterSet={value => {
								if (value === 'Yes') {
									setErrorMessage('');
								} else {
									setErrorMessage('Terms & conditions must be accepted to proceed');
								}

								runInAction(() => {
									model.termsAccepted = value === 'Yes';
								});
							}}
							label="Do you accept the terms & conditions?"
							errors={errorMessage}
						/>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					disabled={errorMessage !== ''}
					onClick={onNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
});

export default ReferralPartnerBenefitsStep;
