import DOMPurify from 'dompurify';

const HtmlTemplateUtil = {
	getFieldIdFromHtmlString: function getFieldIdFromHtmlString(htmlString: string) {
		// use dom purify to sanitize the html string
		const sanitizedHtmlString = DOMPurify.sanitize(htmlString);
		const parser = new DOMParser();
		const htmlDoc = parser.parseFromString(sanitizedHtmlString, 'text/html');
		const availableIds: string[] = [];

		// get all the tags that have id attribute
		const elements = htmlDoc.querySelectorAll('[id]');
		elements.forEach(element => {
			availableIds.push(element.id);
		});

		return availableIds;
	},

	availablePreFillFields: function availablePreFillFields(htmlString: string) {
		const availableIds = this.getFieldIdFromHtmlString(htmlString);
		const preFillFields: string[] = [];
		availableIds.forEach(id => {
			if (id.includes('-text-')) {
				preFillFields.push(id);
			}
		});

		const data = preFillFields.map(id => id.split('-text-'));

		const availableList: Record<string, string[]> = {};
		for (const item of data) {
			const [key, value] = item;
			if (!key.includes('witness')) {
				if (availableList[key]) {
					availableList[key].push(value);
				} else {
					availableList[key] = [value];
				}
			}
		}
		return availableList;
	},

	requiredFieldsWithIdAndNames: function requiredFieldsWithIdAndNames(
		numberOfSignatory: number,
		requireWitness: boolean,
	) {
		const requiredFields: Record<string, {signature: string, witness?: string}> = {};
		const numberOfSignatoryFields = numberOfSignatory;
		for (let i = 0; i < numberOfSignatoryFields; i++) {
			requiredFields[`signee-${i + 1}`] = {
				signature: `signee-${i + 1}-signature`,
				witness: requireWitness ? `signee-${i + 1}-witness-signature` : undefined,
			};
		}

		return requiredFields;
	},

	matchRequiredFieldIdsWithAvailableIds: function matchRequiredFieldIdsWithAvailableIds(
		htmlString: string,
		numberOfSignatory: number,
		requireWitness: boolean,
	) {
		const requiredFields = this.requiredFieldsWithIdAndNames(numberOfSignatory, requireWitness);
		const availableIds = this.getFieldIdFromHtmlString(htmlString);
		const matchedFields: string[] = [];

		Object.entries(requiredFields).forEach(([key, value]) => {
			if (availableIds.includes(value.signature)) {
				matchedFields.push(value.signature);
			}

			if (value.witness) {
				if (availableIds.includes(value.witness)) {
					matchedFields.push(value.witness);
				}
			}
		});

		return matchedFields;
	},
};

export default HtmlTemplateUtil;
