import * as React from 'react';
import { useState } from 'react';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import CustomAttributeFile from 'Views/Components/CRUD/Attributes/CustomAttributeFile';
import { AttributeCRUDOptions } from 'Models/CRUDOptions';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { Model } from 'Models/Model';
import { AtbFileEntity, BusinessEntity } from 'Models/Entities';
import { LocaleCompareAttrAsc, pluralise } from 'Util/StringUtils';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import { observer } from 'mobx-react';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import alertToast from 'Util/ToastifyUtils';
import useAsync from 'Hooks/useAsync';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { saveAs } from 'file-saver';
import { Colors, Display } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { action } from 'mobx';
import moment from 'moment/moment';
import { DatePicker } from '../DatePicker/DatePicker';
import useStore from 'Hooks/useStore';
import { PricingDetail } from 'Util/PricingHelper';
import { atbFileType } from 'Models/Enums';

interface AgedTrialBalanceStepsProps {
	atbFileEntity: AtbFileEntity;
	currentPricingDetail: PricingDetail|null,
	onBeginUpload: () => void;
	onCompleteUpload: (atbFileEntity: AtbFileEntity) => void;
}

class ATBFile extends Model {
	file?: File;
	lastFile?: File;
}

const expectedAtbHeadings = [
	['Debtor/Customer ID', 'Debtor/Customer ID *', 'DebtorId'],
	['Customer Name *', 'Customer Name', 'CustomerName'],
	['Customer ABN / NZBN', 'Customer ABN / NZBN *', 'CustomerAbn'],
	['Customer ACN / NCN', 'CustomerAcn'],
	['Customer ACN Corp Trustee', 'Customer ACN Corp Trustee ', 'CustomerCorpTrusteeAcn'],
	['Entity Name', 'Entity Name *', 'EntityName'],
	['SIC CODE', 'SicCode'],
	['Terms', 'Terms *', 'Terms'],
	['Street 1', 'Street1'],
	['Street 2', 'Street2'],
	['City'],
	['State'],
	['Postcode'],
	['Country'],
	['Phone No', 'Phone No *', 'Phone'],
	['Total'],
	['Current', 'Current *'],
	['Overdue 30', 'Overdue 30 *', 'Overdue30'],
	['Overdue 60', 'Overdue 60 *', 'Overdue60'],
	['Overdue 90', 'Overdue 90 *', 'Overdue90'],
	['Overdue 120', 'Overdue 120 *', 'Overdue120'],
	['Overdue 120 Plus', 'Overdue 120 Plus *', 'Overdue120Plus'],
];

const expectedCustomerHeadings = [
	['Debtor/Customer ID', 'Debtor/Customer ID *', 'DebtorId'],
	['Customer Name *', 'Customer Name', 'CustomerName'],
	['Customer ABN / NZBN', 'Customer ABN / NZBN *', 'CustomerAbn'],
	['Customer ACN / NCN', 'CustomerAcn'],
	['Customer ACN Corp Trustee', 'Customer ACN Corp Trustee ', 'CustomerCorpTrusteeAcn'],
	['Entity Name', 'Entity Name *', 'EntityName'],
	['SIC CODE', 'SicCode'],
	['Terms', 'Terms *', 'Terms'],
	['Street 1', 'Street1'],
	['Street 2', 'Street2'],
	['City'],
	['State'],
	['Postcode'],
	['Country'],
	['Phone No', 'Phone No *', 'Phone'],
	['DrinksLicenceNumber', 'Drinks Licence Number', 'DrinksLicence', 'Drinks Licence'],
	['DrinksLicenceState', 'Drinks Licence State', 'LicenceState', 'Licence State'],
];

const readFile = async (file?: File): Promise<string> => {
	if (!file) {
		return Promise.reject(new Error('No File'));
	}

	return new Promise<string>((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onabort = () => {
			reject(new Error('File Abort'));
		};
		fileReader.onerror = () => {
			reject(new Error(`File Error: ${fileReader?.error?.message ?? 'Unknown'}`));
		};
		fileReader.onload = () => {
			if (!fileReader.result) {
				reject(new Error('File Error: Load Error'));
			} else {
				resolve(fileReader.result.toString());
			}
		};
		fileReader.readAsText(file);
	});
};

const validateFile = async (file: File, fileType: atbFileType): Promise<Error|null> => {
	if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
		return new Error('Invalid Type');
	}

	let data;

	try {
		data = await readFile(file);
	} catch (e: any) {
		return e;
	}

	const expectedHeadings = fileType === 'CUSTOMERS_ONLY' ? expectedCustomerHeadings : expectedAtbHeadings;
	const headingString = data.split(/\r?\n/, 3); // Only need to split one for header

	// Some files have this as an extra heading row. We just want to skip it.
	if (headingString[0].indexOf('For trusts only') > 0) {
		headingString.shift();
	}

	const headings = headingString[0].split(',');

	let hasHeadingRow = false;
	for (let i = 0; i < expectedHeadings[0].length; i++) {
		if (headings.includes(expectedHeadings[0][i])) {
			hasHeadingRow = true;
			break;
		}
	}

	if (!hasHeadingRow) {
		return new Error('Missing Heading Row');
	}

	const missingHeadings = [];
	for (let i = 0; i < expectedHeadings.length; i++) {
		let missing = true;
		for (let x = 0; x < expectedHeadings[i].length; x++) {
			if (headings.includes(expectedHeadings[i][x])) {
				missing = false;
				break;
			}
		}

		if (missing) {
			missingHeadings.push(expectedHeadings[i][0]);
		}
	}

	if (missingHeadings.length > 0) {
		return new Error(`Missing Headings: ${missingHeadings.join(', ')}`);
	}

	if (headingString.length < 2) {
		return new Error('No data included');
	}

	return null;
};

function CustomerCreditsTooltip(props: { currentPricingDetail: PricingDetail|null }) {
	const { currentPricingDetail } = props;

	if (!currentPricingDetail?.customerCountBucket) {
		return null;
	}

	const remainingCredits = Math.max(0, currentPricingDetail.customerCountBucket - currentPricingDetail.customerCount);

	const message = `${(
		remainingCredits === 0
			? 'Uploading more customers'
			: `Uploading more than ${pluralise(remainingCredits, 'new customer')}`
	)} will increase your monthly subscription.`;

	return (
		<Tooltip
			id="customer-credits-tooltip"
			content={message}
		/>
	);
}

const AgedTrialBalanceSteps = observer((props: AgedTrialBalanceStepsProps) => {
	const store = useStore();

	const {
		atbFileEntity,
		currentPricingDetail,
		onBeginUpload,
		onCompleteUpload,
	} = props;

	const [atbFileErrors, setAtbFileErrors] = useState<string[]>([]);
	const [atbFileModel, setAtbFileModel] = useState(() => {
		const atbFile = new ATBFile();

		if (atbFileEntity?.fileName) {
			atbFile.file = new File([], atbFileEntity.fileName);
			atbFile.lastFile = atbFile.file;
		}

		return atbFile;
	});

	const fieldsDisabledForStatus = (
		atbFileEntity.atbProcessingStatus === 'PROCESSED'
		|| (
			!!atbFileEntity.id
			&& atbFileEntity.atbJobStatus !== 'PROCESSED'
		)
	);
	const fieldsDisabledForFileType = (
		atbFileEntity.atbFileType === 'XERO'
	);

	const uploadEnabled = useAsync(async (): Promise<boolean> => {
		if (!atbFileEntity.businessEntityId || !atbFileModel.file || atbFileErrors.length > 0) {
			return false;
		}

		let fileSize = atbFileModel.file.size;

		if (!fileSize) {
			try {
				const data = await readFile(atbFileModel.file);
				fileSize = data.length;
			} catch (e: any) {
				return false;
			}
		}
		if (!fileSize) {
			return false;
		}

		if (atbFileModel.lastFile) {
			if (atbFileModel.lastFile === atbFileModel.file) {
				return false;
			}
		}

		return true;
	}, [atbFileEntity, atbFileErrors, atbFileEntity.businessEntityId]);

	const isCustomerUpload = React.useMemo(() => (
		atbFileEntity.atbFileType === 'CUSTOMERS_ONLY'
	), [atbFileEntity.atbFileType]);

	const uploadFile = async () => {
		if (!atbFileModel.file?.name) {
			alertToast('File Error. Please remove file and upload again.', 'error');
			return;
		}

		onBeginUpload();

		setAtbFileModel(_atbFileModel => {
			_atbFileModel.lastFile = _atbFileModel.file;
			return _atbFileModel;
		});

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		atbFileEntity.fileName = atbFileModel.file!.name;

		const data = new FormData();
		data.append('variables', JSON.stringify(atbFileEntity));
		data.append('file', atbFileModel.file);

		const minTimeoutPromise = new Promise(resolve => {
			setTimeout(resolve, 1000);
		});

		const resultPromise = axios.post<{ 'errors': string[] | any }>(
			`${SERVER_URL}/api/entity/AtbFileEntity/upload`,
			data,
		);

		// Make the upload process take at least `minTimeoutPromise` time, for UX.
		const [_, result] = await Promise.all([minTimeoutPromise, resultPromise]);

		if (result.data?.errors) {
			alertToast(result.data.errors.join('; '), 'error');
			return;
		}

		const atbFileEntityResult = new AtbFileEntity(result.data as any);

		onCompleteUpload(atbFileEntityResult);
	};
	const downloadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const fileType = isCustomerUpload ? 'customer' : 'atb';
		event.preventDefault();
		saveAs(`${SERVER_URL}/api/entity/AtbFileEntity/template?fileType=${fileType}`);
	};

	const displayTitle = isCustomerUpload ? 'customer' : 'ATB';
	const guideLink = isCustomerUpload ? 'CustomerGuide' : 'ATBGuide';

	return (
		<div className="agedtrialbalancesteps">
			<ol className="steps">
				<li>
					Download the {displayTitle} template<br />
					<button
						type="button"
						className="btn btn--solid btn--primary download-template"
						onClick={downloadClick}
					>
						Download the {displayTitle} template
					</button>
				</li>
				<li>
					Fill out the {displayTitle} template with the selected customer data
					<Tooltip
						id="active-status-tooltip"
						content={`This document provides guidance on how to complete the ${isCustomerUpload
							? 'customer'
							: 'ATB'} template`}
					/>
					<br />
					<a href={`/api/files/document/${guideLink}`} id="atb-user-guide" target="_blank" rel="noreferrer">
						How do I fill out the {displayTitle} template?
					</a>
				</li>
				<li>
					Select the month that the upload applies to. <br />
					<DatePicker
						className="month-picker"
						model={atbFileEntity}
						modelProperty="targetMonth"
						placeholder="Select month"
						staticInput={fieldsDisabledForFileType}
						isRequired
						flatpickrOptions={{
							plugins: [
								new (monthSelectPlugin as any)({
									shorthand: true,
									dateFormat: 'Z',
									altFormat: 'F Y',
								}),
							],
							altInput: true,
							maxDate: moment().utc().subtract(1, 'months').toDate(),
						}}
						onAfterChange={action((dateList, dateString) => {
							// Recalculate the date from the date string, since we just want the month and year, and don't want
							// the timezone to affect it.
							atbFileEntity.targetMonth = moment.utc(dateString).toDate();
						})}
					/>
				</li>
				<li>
					Select the Business Entity.<br />
					<ComboboxSetter
						className="business-entity-selector"
						value={atbFileEntity.businessEntityId}
						setValue={value => {
							atbFileEntity.businessEntityId = value;
						}}
						getOptionValue={(value?: string) => value}
						placeholder="Business Entity"
						label=""
						searchable
						isDisabled={fieldsDisabledForStatus || fieldsDisabledForFileType}
						options={store.getUser?.organisation?.businessEntitys
							.filter(BusinessEntity.IsLinkedToUser)
							.filter(x => x.enabledForMonitor)
							.sort(LocaleCompareAttrAsc('name'))
							.map(businessEntity => {
								return {
									display: businessEntity.name,
									value: businessEntity.id,
								};
							}) ?? []}
					/>
				</li>
				<li>
					Select the completed {displayTitle} template<br />
					<CustomAttributeFile
						className="file-upload"
						model={atbFileModel}
						fileAttribute="file"
						contentType="text/csv,.csv"
						options={new AttributeCRUDOptions(
							'fileId',
							{
								name: '', // Hide the label
								displayType: 'file',
							},
						)}
						isReadonly={fieldsDisabledForStatus}
						errors={atbFileErrors}
						formMode={EntityFormMode.EDIT}
						onAfterChange={async (file: File) => {
							const validationError = await validateFile(file, atbFileEntity.atbFileType);
							if (validationError) {
								setAtbFileErrors([validationError.message]);
							} else {
								setAtbFileErrors([]);
							}
						}}
						onAfterDelete={() => {
							setAtbFileErrors([]);
						}}
					/>
					<Checkbox
						model={atbFileEntity}
						modelProperty="addNewCustomers"
						isDisabled={fieldsDisabledForStatus || fieldsDisabledForFileType}
						label="Add new customers by default"
						labelVisible
						tooltip="If a row within the uploaded file does not match an existing customer, a new customer
							will automatically be created. If not selected, you will have the option to add a new
							customer or link the row to an existing customer during the Resolve Errors step"
					/>
				</li>
				<li>
					Upload File <CustomerCreditsTooltip currentPricingDetail={currentPricingDetail} /><br />
					<ButtonAsyncState
						display={Display.Solid}
						colors={Colors.Primary}
						className="file-upload-button"
						readonly={!uploadEnabled.data}
						onPress={uploadFile}
						waitingText="Uploading..."
					>
						Upload File
					</ButtonAsyncState>
				</li>
				<li>
					Resolve Errors
				</li>
				<li>
					Submit Data
				</li>
			</ol>
		</div>
	);
});

export default AgedTrialBalanceSteps;
