import React, { useState } from 'react';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import { RegistrationEntity } from 'Models/Entities';
import EntityList from 'Views/Components/EntityList/EntityList';
import {
	referrerBenefitTypeOptions,
	referrerBusinessTypeOptions,
	referrerBenefitTypeComboboxOptions,
	referrerBusinessTypeComboboxOptions,
	registrationComboboxOptions,
	referrerBenefitType,
	referrerBusinessType,
	registrationStatus,
} from 'Models/Enums';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import classNames from 'classnames';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { gql } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import moment from 'moment';
import RegistrationStatusBox from 'Views/Components/Registration/RegistrationStatusBox';
import MeatballMenu from '../EntityList/MeatballMenu';
import { DatePicker } from '../DatePicker/DatePicker';
import { buildSearchConditions } from '../../../Util/GraphQLUtils';
import { confirmModal } from '../Modal/ModalUtils';
import { buildBlob } from '../../../Util/CsvUtils';
import { saveAs } from 'file-saver';

// Method to fetch and return the RegistrationEntities based on the page number and sorting
const fetchReferrals = async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	status: registrationStatus | 'ALL',
	type: referrerBusinessType | 'ALL',
	benefitType: referrerBenefitType | 'ALL',
	dateRange: string,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['organisationName', 'userEmail'], search, comparison);
	}

	// Setup status filter
	const statusConditions: IWhereCondition<string>[] = [];
	if (!!status && status !== 'ALL') {
		statusConditions.push({
			comparison: 'equal', path: 'referralStatus', value: status,
		});
	}

	// Setup type filter
	const typeConditions: IWhereCondition<string>[] = [];
	if (!!type && type !== 'ALL') {
		typeConditions.push({
			comparison: 'equal', path: 'createdOrganisation.referrerBusinessType', value: type,
		}, {
			comparison: 'contains', path: 'referralData', value: `"referrerBusinessType":"${type}"`,
		});
	}

	// Setup referrer benefit type filter
	const benefitTypeConditions: IWhereCondition<string>[] = [];
	if (!!benefitType && benefitType !== 'ALL') {
		benefitTypeConditions.push({
			comparison: 'equal',
			path: 'createdOrganisation.referrerBenefitType',
			value: benefitType,
		}, {
			comparison: 'contains',
			path: 'referralData',
			value: `"referrerBenefitType":"${benefitType}"`,
		});
	}

	// Setup referrer start / end date type filter
	const startDateConditions: IWhereCondition<string>[] = [];
	const endDateConditions: IWhereCondition<string>[] = [];

	if (dateRange.length > 0) {
		startDateConditions.push({
			path: 'startDate',
			value: [moment(dateRange[0]).format('YYYY-MM-DD')],
			comparison: 'greaterThanOrEqual',
		});

		endDateConditions.push({
			path: 'startDate',
			value: [moment(dateRange[1]).format('YYYY-MM-DD')],
			comparison: 'lessThanOrEqual',
		});
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];

	// Only include Referral Registrations
	allConditions.push([
		{
			path: 'status', comparison: 'equal', value: 'CREATED',
		},
		// OR
		{
			path: 'referralStatus', comparison: 'equal', value: 'CREATED', negate: true,
		},
	]);

	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}
	if (statusConditions.length > 0) {
		allConditions.push(statusConditions);
	}
	if (typeConditions.length > 0) {
		allConditions.push(typeConditions);
	}
	if (benefitTypeConditions.length > 0) {
		allConditions.push(benefitTypeConditions);
	}
	if (startDateConditions.length > 0) {
		allConditions.push(startDateConditions);
	}
	if (endDateConditions.length > 0) {
		allConditions.push(endDateConditions);
	}

	const referralPartnersCondition: IWhereCondition<string>[] = [];
	referralPartnersCondition.push({
		comparison: 'equal', path: 'referralStatus', value: ['Created'], negate: true,
	});

	referralPartnersCondition.push({
		comparison: 'equal', path: 'createdOrganisation.isReferralPartner', value: ['true'],
	});

	allConditions.push(referralPartnersCondition);

	// Add sorting
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: sortColumn, descending: sortDescending });

	const query = gql`
		query registrationEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]]
		) {
			registrationEntitys(conditions: $conditions, skip: $skip, take:$take, orderBy: $orderBy) {
				id
				organisationName
				userEmail
				created
				startDate
				registrationData
				createdOrganisation {
					id
					isReferralPartner
					referrerBusinessType
					primaryBusinessEntity {
					  id
					}
					referrerBenefitType
					referrerCommissionPercent

				}
				referralStatus
				referralData
				createdUser {
					email
				}
			}
			${!noPagination ? `countRegistrationEntitys(conditions: $conditions) {
				number
			}` : ''}
		}
	`;

	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: null,
			skip: null,
			conditions: allConditions,
		},
	});
	return data.registrationEntitys?.map((registration: any) => new
	RegistrationEntity(registration)) as RegistrationEntity[];
};

// Method to fetch and return the RegistrationEntitys based on the page number and sorting
const fetchRegistrations = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	status: registrationStatus | 'ALL',
	type: referrerBusinessType | 'ALL',
	benefitType: referrerBenefitType | 'ALL',
	dateRange: string,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	return fetchReferrals(
		search,
		sortColumn,
		sortDescending,
		page,
		status,
		type,
		benefitType,
		dateRange,
		setCount,
		noPagination,
	);
}, 300, {
	// We use leading, so that one-off changes like sorting columns apply immediately
	// Changing the search text will trigger on the first character, and then again after the final change
	leading: true,
});

const ReferralPartnersList = () => {
	// For the search bar display
	const [search, setSearch] = useState('');
	const [sortColumn, setSortColumn] = useState('organisationName');
	const [sortDescending, setSortDescending] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [status, setStatus] = useState('ALL' as registrationStatus | 'ALL');
	const [type, setType] = useState('ALL' as referrerBusinessType | 'ALL');
	const [benefitType, setBenefitType] = useState('ALL' as referrerBenefitType | 'ALL');
	const [dateRange, setDateRange] = useState('');

	// Setup an object to store the value on, so that the date picker can read it
	const dateEntity = {
		value: dateRange,
	};

	/**
	 * Callback for sorting changes
	 * @param header The name of the sorting column
	 */
	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};

	const changeIsActive = async () => {
		// TODO: Add set benefit function
	};

	// Method to fetch and return the referrals without any sorting
	const fetchReferralPartnersForExport = async () => {
		const referrals: RegistrationEntity[] = await fetchReferrals(
			search,
			sortColumn,
			sortDescending,
			page,
			status,
			type,
			benefitType,
			dateRange,
			setCount,
			true,
		);
		const result = [] as string[][];
		const columns = [
			{
				displayName: 'Organisation',
				value: ({ organisationName }: RegistrationEntity) => organisationName,
			},
			{
				displayName: 'Email',
				value: ({ userEmail }: RegistrationEntity) => userEmail,
			},
			{
				displayName: 'Type',
				value: ({ createdOrganisation, parsedReferralData }: RegistrationEntity) => {
					return (createdOrganisation?.referrerBusinessType
						?? parsedReferralData.organisation?.referrerBusinessType)?.toString();
				},
			},
			{
				displayName: 'Start date',
				value: ({ startDate }: RegistrationEntity) => moment(startDate).format('DD/MM/YYYY'),
			},
			{
				displayName: 'Benefit type',
				value: ({ createdOrganisation, parsedReferralData }: RegistrationEntity) => {
					return (createdOrganisation?.referrerBenefitType
						?? parsedReferralData.organisation?.referrerBenefitType)?.toString();
				},
			},
			{
				displayName: 'Benefit',
				value: ({ createdOrganisation, parsedReferralData }: RegistrationEntity) => {
					return `${(createdOrganisation?.referrerCommissionPercent
						?? parsedReferralData.organisation?.referrerCommissionPercent)?.toString()}%`;
				},
			},
			{
				displayName: 'Status',
				value: ({ referralStatus }: RegistrationEntity) => referralStatus,
			},
		];
		const headers = columns.map(column => column.displayName);
		result.push(headers);
		referrals?.forEach(referral => {
			result.push(
				columns.map(column => column.value(referral) ?? ''),
			);
		});
		return result;
	};

	return (
		<div className="referral-partners-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						labelVisible={false}
						placeholder="Search by Name or Email"
					/>

					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => {
							setShowFilters(show => !show);
							setStatus('ALL');
							setType('ALL');
							setBenefitType('ALL');
						}}
					>
						Filter By
					</Button>
				</div>

				<div className="actions">
					<Button
						className="export-button"
						colors={Colors.Alternate}
						display={Display.Outline}
						onClick={async () => {
							confirmModal(
								'Export CSV',
								'Export referral partners and save to a CSV file?',
								{
									cancelText: 'Cancel',
									confirmText: 'Export',
								},
							).then(async () => {
								const exportCustomers = await fetchReferralPartnersForExport();
								const blob = buildBlob(exportCustomers, ',', '"');
								saveAs(blob, `exported-referral-partners-${moment().format('YYYY-MM-DD')}.csv`);
							});
						}}
					>
						Export CSV
					</Button>
				</div>
				<If condition={showFilters}>
					<div className="search-filter-section referral-partner-list">
						<ComboboxSetter
							className="type-filter"
							value={type}
							setValue={setType}
							getOptionValue={(value?: string) => value}
							placeholder="Type"
							label=""
							labelVisible={false}
							searchable
							options={referrerBusinessTypeComboboxOptions}
						/>
						<ComboboxSetter
							className="benefit-type-filter"
							value={benefitType}
							setValue={setBenefitType}
							getOptionValue={(value?: string) => value}
							placeholder="Benefit Type"
							label=""
							labelVisible={false}
							searchable
							options={referrerBenefitTypeComboboxOptions}
						/>
						<ComboboxSetter
							className="status-filter"
							value={status}
							setValue={setStatus}
							getOptionValue={(value?: string) => value}
							placeholder="Status"
							label=""
							labelVisible={false}
							searchable
							options={registrationComboboxOptions}
						/>
						<DatePicker
							className="start-date-filter"
							model={dateEntity}
							modelProperty="value"
							mode="range"
							placeholder="All Start Dates"
							onAfterChange={() => {
								if (!!setDateRange && !!dateEntity.value) {
									setDateRange(dateEntity.value);
								}
							}}
						/>
					</div>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the Registration List */}
				<RequestWrap
					request={() => fetchRegistrations(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
						type,
						benefitType,
						dateRange,
						setCount,
						true,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
						type,
						benefitType,
						dateRange,
					)}
				>
					{(registrations: RegistrationEntity[]) => (
						<>
							<EntityList
								collection={registrations}
								columns={[
									{
										displayName: 'Organisation',
										columnName: 'organisationName',
										value: registration => registration.createdOrganisation
											?.primaryBusinessEntity?.name
											?? registration.organisationName
											?? <span className="not-provided">Not Provided</span>,
										sortable: false,
										className: 'field-organisation-name',
									},
									{
										displayName: 'Email Address',
										columnName: 'userEmail',
										value: registration => registration.createdUser?.email
											?? registration?.userEmail
											?? <span className="not-provided">Not Provided</span>,
										sortable: false,
										className: 'field-user-email',
									},
									{
										displayName: 'Type',
										columnName: 'referrerBusinessType',
										value: registration => {
											const { createdOrganisation, parsedReferralData } = registration;
											const businessType = (createdOrganisation?.referrerBusinessType
												?? parsedReferralData.organisation?.referrerBusinessType);
											return (businessType !== undefined
												? referrerBusinessTypeOptions[businessType]
												: <span className="not-provided">Not Provided</span>);
										},
										sortable: false,
										className: 'field-type',
									},
									{
										displayName: 'Start Date',
										columnName: 'startDate',
										value: registration => !!registration.startDate
											? moment(registration.startDate).format('DD/MM/YYYY')
											: <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('startDate');
										},
										className: 'field-start-date',
									},
									{
										displayName: 'Benefit type',
										columnName: 'referrerBenefitType',
										value: registration => {
											const { createdOrganisation, parsedReferralData } = registration;
											const benefitTypeValue = (createdOrganisation?.referrerBenefitType
												?? parsedReferralData.organisation?.referrerBenefitType);
											return (benefitTypeValue !== undefined
												? referrerBenefitTypeOptions[benefitTypeValue]
												: <span className="not-provided">Not Provided</span>);
										},
										sortable: false,
										className: 'field-benefit-type',
									},
									{
										displayName: 'Benefit',
										columnName: 'createdOrganisation.referrerCommissionPercent',
										value: registration => {
											const { createdOrganisation, parsedReferralData } = registration;
											const discountPercent = (createdOrganisation?.referrerCommissionPercent
												?? parsedReferralData.organisation?.referrerCommissionPercent);
											return discountPercent ? `${discountPercent}%`
												: <span className="not-provided">Not Provided</span>;
										},
										sortable: true,
										sortClicked: () => {
											onSortChange('createdOrganisation.referrerCommissionPercent');
										},
										className: 'field-benefit',
									},
									{
										displayName: 'Status',
										columnName: 'status',
										value: registration => {
											return (
												<RegistrationStatusBox
													status={registration.referralStatus}
												/>
											);
										},
										sortable: false,
										className: 'field-status',
									},
									{
										displayName: '',
										columnName: 'meatball-menu',
										value: () => (
											<MeatballMenu actions={[
												{
													display: 'Set Benefit',
													action: () => changeIsActive(),
													visible: true,
												},
											]}
											/>
										),
										sortable: false,
										className: 'meatball-menu',
										hide: store.userPermissions.commonManageUsers === 'SELF',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								onClickRow={entity => {
									store.routerHistory.push(`/hub/referral-partners/${entity.id}`);
								}}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={REGISTRATIONS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default ReferralPartnersList;

export const REGISTRATIONS_PAGE_LENGTH = 10;
