/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import ProductSwitcher from 'Views/Components/Navigation/ProductSwitcher';
import { userPermissionScopeOrder } from '../Models/Enums';
// % protected region % [Add any extra imports here] end

// % protected region % [Customise getFrontendNavLinks signature here] off begin
// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/prefer-default-export
export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
// % protected region % [Customise getFrontendNavLinks signature here] end

	// % protected region % [Add any logic before displaying page links here] on begin
	const isClientUser = store.getUser?.organisation?.isClient === true;
	const isReferralPartnerUser = store.getUser?.organisation?.isReferralPartner === true;
	const canManageUsers = (isClientUser && userPermissionScopeOrder[store.userPermissions.commonManageUsers ?? 'SELF']
		> userPermissionScopeOrder.SELF);
	const isSuperUser = store.userType === 'SUPER_USER';
	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{
				label: 'Product Switcher',
				customComponent: (
					<ProductSwitcher productClass="hub" />
				),
				path: '/',
				icon: 'home',
				iconPos: 'icon-left',
			},
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{
				label: 'Your Hub',
				path: '/hub/products',
			},
			{
				label: 'Your Account',
				path: '/hub/account',
			},
			{
				label: 'Organisation',
				path: '/hub/organisation',
				shouldDisplay: () => isClientUser,
			},
			{
				label: 'Business',
				path: '/hub/business',
				shouldDisplay: () => isReferralPartnerUser && !isClientUser,
			},
			{
				label: 'Referrals',
				path: '/hub/referrals',
				shouldDisplay: () => isReferralPartnerUser,
			},
			{
				label: 'Users',
				path: '/hub/users',
				shouldDisplay: () => canManageUsers,
			},
			{
				label: 'Admin',
				customComponent: (
					<p className="nav-subheading">ADMIN</p>
				),
				path: '',
				shouldDisplay: () => isSuperUser,
			},
			{
				label: 'Registrations',
				path: '/hub/registrations',
				shouldDisplay: () => isSuperUser,
			},
			{
				label: 'Clients',
				path: '/hub/clients',
				shouldDisplay: () => isSuperUser,
			},
			{
				label: 'Referral Partners',
				path: '/hub/referral-partners',
				shouldDisplay: () => isSuperUser,
			},
			{
				label: 'Settings',
				path: '/hub/settings',
				shouldDisplay: () => isSuperUser,
			},
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			{
				label: 'Events & Webinars',
				path: 'https://accessintell.com/events/', // placeholder for actual link which is yet to be determind
				// Must open in new tab
				useATag: true,
				icon: 'link-external', // placeholder for actual icon which is yet to be added
				iconPos: 'icon-right',
				className: 'link-external-btn',
			},
			{
				label: 'News & Updates',
				path: 'https://accessintell.com/articles/', // placeholder for actual link which is yet to be determind
				// Must open in new tab
				useATag: true,
				icon: 'link-external', // placeholder for actual icon which is yet to be added
				iconPos: 'icon-right',
				className: 'link-external-btn',
			},
			{
				label: 'Login',
				path: '/login',
				icon: 'login',
				iconPos: 'icon-right',
				shouldDisplay: () => !store.loggedIn,
				className: 'login-logout-btn',
			},
			{
				label: 'Logout',
				path: '/logout',
				icon: 'logout',
				iconPos: 'icon-right',
				shouldDisplay: () => store.loggedIn,
				className: 'login-logout-btn',
			},
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
