import * as React from 'react';
import { observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { Button, Display, Sizes } from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import alert from '../../Util/ToastifyUtils';
import Password from 'Views/Components/Password/Password';
import * as queryString from 'querystring';
import AccessGroupLogo from 'Assets/Images/Access_logo.svg';

interface ISetPasswordState {
	token: string;
	username: string;
	password: string;
	confirmPassword: string,
	errors: { [attr: string]: string };
}

const defaultSetPasswordState: ISetPasswordState = {
	token: '',
	username: '',
	password: '',
	confirmPassword: '',
	errors: {},
};

// Copied from the reset password page, but with some wording changed to make it clearer for new users
@observer
export default class SetPasswordPage extends React.Component<RouteComponentProps> {
	@observable
	private setPasswordState: ISetPasswordState = defaultSetPasswordState;

	constructor(props:RouteComponentProps, context: any) {
		super(props, context);
		const { location } = this.props;

		const queryParams = location.search.substring(1);
		const { token, username } = queryString.parse(queryParams);
		this.setPasswordState.token = token as string;
		this.setPasswordState.username = username as string;
	}

	public render() {
		let contents = null;

		if (store.loggedIn) {
			return <Redirect to="/" />;
		}

		contents = (
			<div className="body-content login-page">
				<div className="login-form">
					<form className="reset-password" onSubmit={this.onSetPasswordClicked}>
						<div className="logo-container">
							<img alt="Access Intell Group Logo" src={AccessGroupLogo} />
						</div>
						<div className="title forgot-title">
							<h5>Set your password</h5>
						</div>
						<div className="forgot-password-desc">
							<p>
								Please set a password for your Access Intell
								account <b>{this.setPasswordState.username}</b>
							</p>
						</div>
						<Password
							id="new_password"
							className="new-password"
							model={this.setPasswordState}
							modelProperty="password"
							isRequired
							placeholder="Password"
							errors={this.setPasswordState.errors.password}
						/>
						<Password
							id="confirm_password"
							className="confirm-password"
							model={this.setPasswordState}
							modelProperty="confirmPassword"
							isRequired
							placeholder="Confirm Password"
							errors={this.setPasswordState.errors.confirmPassword}
						/>
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirm-pwd-buttons">
							<Button
								type="submit"
								className="confirm-set-password"
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'confirm_set_password' }}
							>
								Submit
							</Button>
						</ButtonGroup>
					</form>
				</div>
				<div className="login-gradient">
					<div className="login-pattern" />
				</div>
			</div>
		);
		return contents;
	}

	@action
	private onSetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.setPasswordState.errors = {};

		if (!this.setPasswordState.password) {
			this.setPasswordState.errors.password = 'Password is required';
		} else if (this.setPasswordState.password.length < 12) {
			this.setPasswordState.errors.password = 'The minimum length of password is 12';
		} else if (!this.setPasswordState.confirmPassword) {
			this.setPasswordState.errors.confirmPassword = 'Confirm password is required';
		} else if (this.setPasswordState.confirmPassword !== this.setPasswordState.password) {
			this.setPasswordState.errors.confirmPassword = 'Password and confirm password does not match';
		}

		if (Object.keys(this.setPasswordState.errors).length <= 0) {
			axios.post(
				`${SERVER_URL}/api/account/reset-password`,
				{
					token: this.setPasswordState.token,
					username: this.setPasswordState.username,
					password: this.setPasswordState.password,
				},
			)
				.then(() => {
					this.onConfirmPasswordSent();
				})
				.catch(exception => {
					const errorMsgs = exception.response.data.errors.map((error: any) => (error.message));
					alert(errorMsgs,
						'error');
				});
		}
	};

	@action
	private onConfirmPasswordSent = () => {
		alert('Your password has been set. You may now log in with your new password.', 'success');
		store.routerHistory.push('/login');
	};
}
