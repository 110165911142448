import React from 'react';
import HandleEvents from 'Util/HandleEvents';
import * as uuid from 'uuid';

export interface MeatballMenuAction {
	display: (string | React.ReactNode);
	action: () => void;
	visible: boolean;
}

export interface MeatballMenuProps {
	actions: MeatballMenuAction[];
}

const MeatballMenu = (props: MeatballMenuProps) => {
	const { actions } = props;

	if (actions.filter(x => x.visible).length === 0) {
		return null;
	}

	return (
		<>
			<div
				className="meatball-menu-toggle-btn icon-left icon-more-vertical"
				aria-label="Focus to open more options"
				tabIndex={0}
				role="button"
				{...HandleEvents(e => {
					// Prevent row action from firing
					e.stopPropagation();
				})}
			>
				<div className="meatball-menu-actions-list">
					{actions.filter(x => x.visible).map(action => (
						<button
							key={uuid.v4()}
							className="meatball-menu-action"
							onClick={action.action}
						>
							{action.display}
						</button>
					))}
				</div>
			</div>
		</>
	);
};
export default MeatballMenu;
