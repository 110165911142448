import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { observable, runInAction } from 'mobx';
import {
	CreditBureauEntity,
	RegistrationEntity,
	OrganisationEntity,
	OrganisationAuthorisedCreditBureau, BusinessEntity, SpgEntity, ApplicationEntity,
} from 'Models/Entities';
import { store } from 'Models/Store';
import Wizard, { WizardStepInformation } from 'Views/Components/Wizard/Wizard';
import RegistrationData from 'Models/Entities/RegistrationData';
import useAsync from 'Hooks/useAsync';
import Spinner from 'Views/Components/Spinner/Spinner';
import setupApplicationsStep from 'Views/Components/Wizard/OrganisationRegistration/Approve/SetupApplicationsStep';
import PpsrLogo from 'Assets/Images/AccessPpsr_logo-white.svg';
import ApproveLogo from 'Assets/Images/AccessApprove_logo-white.svg';
import MonitorLogo from 'Assets/Images/AccessMonitor_logo-white.svg';
import PpsrDetailsStep from 'Views/Components/Wizard/OrganisationRegistration/PpsrDetailsStep';
import IntelDisclosingStep from 'Views/Components/Wizard/OrganisationRegistration/IntelDisclosingStep';
import gql from 'graphql-tag';
import AttachFilesStep from 'Views/Components/Wizard/OrganisationRegistration/Approve/AttachFilesStep';
import ProductSetupCompletedStep from 'Views/Components/Products/ProductSetupCompletedStep';
import PricingQuestionsStep from '../Components/Wizard/OrganisationRegistration/PricingQuestionsStep';
import moment from 'moment';
import { CalculatePricing } from 'Util/PricingHelper';

export interface ProductSetupPageProps {
	products?: string
	organisationId?: string,
}

const ProductSetupPage = observer((props: RouteComponentProps<ProductSetupPageProps>) => {
	const { match } = props;
	const {
		products, organisationId,
	} = match.params;

	// we split the products on '-' to determine each product being setup
	const productsList = products?.split('-') ?? [];
	const intel = productsList.includes('intel');
	const approve = productsList.includes('approve');
	const ppsr = productsList.includes('ppsr');

	// If we haven't been passed an organisation ID, we use the current user's
	const relevantOrganisationId = organisationId ?? store.getUser?.organisation?.id;

	const response = useAsync(
		async () => {
			// fetch all the credit bureaus and organisation from the database
			const { data } = (await store.apolloClient.query({
				query: gql`
					query {
						creditBureauEntitys {
							id
						}
						registrationEntity (where: {
								path: "createdOrganisationId",
								comparison: equal,
								value: "${relevantOrganisationId}"
							}) {
							registrationData
						}
						organisationEntity(id: "${relevantOrganisationId}") {
							${OrganisationEntity.getAllAttributes().join('\n')}
							primaryBusinessEntity {
								${BusinessEntity.getAllAttributes().join('\n')}
								spgss {
									${SpgEntity.getAllAttributes().join('\n')}
								}
								applicationss {
									${ApplicationEntity.getAllAttributes().join('\n')}
								}
							}
							authorisedCreditBureaus {
								id
								authorisedCreditBureauId
							}
							industryCodes {
								id
								description
								code
							}
							registration {
								id
							}
							discountss (where: [{
								path: "expirationDate", 
								comparison: greaterThanOrEqual, 
								value: ["${moment().utc().format('YYYY-MM-DD')}"]
							}]) {
								id
								source
							}
							businessEntitys {
								${BusinessEntity.getAllAttributes().join('\n')}
								spgss {
									${SpgEntity.getAllAttributes().join('\n')}
								}
								applicationss {
									${ApplicationEntity.getAllAttributes().join('\n')}
								}
							}
						}
					}
				`,
			}));

			// this is so that the bureaus can be serialized properly
			// we do this because all bureaus are disclosed by default
			// so we do on initial registration creation as this is the one place
			// we guarantee that they have not edited anything.
			const bureaus = data.creditBureauEntitys as CreditBureauEntity[];
			const authorisedCreditBureaus = bureaus.map(bureau => {
				return new OrganisationAuthorisedCreditBureau({
					authorisedCreditBureauId: bureau.id,
				});
			});
			const organisation = data.organisationEntity as OrganisationEntity;

			const currentPricingDetail = await CalculatePricing({
				OrganisationId: organisation.id,
			});

			const requirePricingChangeOrOrgDetails = (
				currentPricingDetail.lineItems.some(d => d.type === 'DiscountMigration')
				|| currentPricingDetail.customerCount === 0 // Default value when unset
				|| currentPricingDetail.monthlyExposure === 0 // Default value when unset
			);

			// although we aren't going through a registration process we still create a registration entity
			// to utilise the same steps. However, we disable saving across steps
			const wizardEntity = observable(new RegistrationEntity({
				parsedRegistrationData: new RegistrationData({
					organisation: organisation,
					businessEntitys: organisation.businessEntitys,
				}),
			}));

			// We enable the products being setup for an organisation
			runInAction(() => {
				if (wizardEntity.parsedRegistrationData.organisation) {
					if (!!intel) {
						wizardEntity.parsedRegistrationData.organisation.intelEnabled = true;
						wizardEntity.parsedRegistrationData.organisation
							.authorisedCreditBureaus = authorisedCreditBureaus;
					}
					if (!!approve) {
						wizardEntity.parsedRegistrationData.organisation.approveEnabled = true;
					}
					if (!!ppsr) {
						wizardEntity.parsedRegistrationData.organisation.ppsrEnabled = true;
					}
				}
			});

			return {
				wizardEntity,
				requirePricingChangeOrOrgDetails,
			};
		},
		[],
	);

	if (response.type === 'loading' || response.type === 'error') {
		return <Spinner />;
	}

	const { wizardEntity: registrationEntity, requirePricingChangeOrOrgDetails } = response.data;

	// we create the wizard step information based upon the products being setup
	const wizardStepInformation = () : WizardStepInformation[] => {
		const stepInformation = [] as WizardStepInformation[];
		if (requirePricingChangeOrOrgDetails) {
			stepInformation.push({
				name: 'About your organisation',
				component: PricingQuestionsStep,
			});
		}
		let productCount = 0;
		if (approve) {
			stepInformation.push({
				name: 'Setup Access Approve',
				component: setupApplicationsStep,
				hideSlide: !approve,
				sidebarSubtitle: 'Approve',
				topBarLogo: ApproveLogo,
				topBarType: 'approve',
				options: {
					showSubmit: !ppsr && !intel,
				},
			});
			productCount++;
		}
		if (ppsr) {
			stepInformation.push({
				name: 'Setup Access PPSR',
				component: PpsrDetailsStep,
				hideSlide: !ppsr,
				sidebarSubtitle: 'PPSR',
				topBarLogo: PpsrLogo,
				topBarType: 'ppsr',
				options: {
					showSubmit: !intel,
				},
			});
			productCount++;
		}
		if (intel) {
			stepInformation.push({
				name: 'Authorise Applications',
				component: IntelDisclosingStep,
				hideSlide: !intel,
				sidebarSubtitle: 'Monitor',
				topBarLogo: MonitorLogo,
				topBarType: 'intel',
				options: {
					showSubmit: true,
				},
			});
			productCount++;
		}
		stepInformation.push({
			name: 'Product Setup Complete',
			component: ProductSetupCompletedStep,
			hideStepInSidebar: true,
			hideTopBar: true,
			hideSidebarComponents: true,
			options: {
				setupMultipleProducts: productCount > 1,
				updatedBilling: requirePricingChangeOrOrgDetails,
				ppsr: ppsr,
				approve: approve,
			},
		});
		return stepInformation;
	};

	return (
		<div className="body-content organisation-registration-page product-setup-page">
			<Wizard
				stepInformation={wizardStepInformation()}
				wizardName="Product Setup"
				model={registrationEntity.parsedRegistrationData}
				pageProperty="page"
				onCancel={() => {
					const redirectUrl = organisationId != null
						? `/hub/clients/${organisationId}`
						: '/hub/organisation';
					store.routerHistory.push(redirectUrl);
				}}
				onSaveDraft={() => {}}
				onChangePage={() => {}}
				data={registrationEntity} // We pass in the registration entity so that attach files step can get the ID
			/>
			<div className="preload">
				<img src={PpsrLogo} alt="" />
				<img src={ApproveLogo} alt="" />
				<img src={MonitorLogo} alt="" />
			</div>
		</div>
	);
});

export default ProductSetupPage;
