import * as React from 'react';
import If from '../If/If';
import classNames from 'classnames';

export interface WarningsCountProps {
	count?: number;
}

const WarningsCount = (props: WarningsCountProps) => {
	const { count } = props;

	return (
		<div>
			<If condition={count !== null}>
				<span className={classNames({
					'warnings-count': count && count > 0,
					'icon-left icon-warning-2': count && count > 0,
				})}
				>
					<p>{count}</p>
				</span>
			</If>
		</div>
	);
};

export default WarningsCount;
