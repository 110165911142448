/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsRegistrationEntity from 'Models/Security/Acl/VisitorsRegistrationEntity';
import UserRegistrationEntity from 'Models/Security/Acl/UserRegistrationEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import RegistrationData from 'Models/Entities/RegistrationData';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import ReferralRegistrationData from './ReferralRegistrationData';
// % protected region % [Add any further imports here] end

export interface IRegistrationEntityAttributes extends IModelAttributes {
	referralStatus: Enums.registrationStatus;
	completedDate: Date;
	products: string;
	status: Enums.registrationStatus;
	userName: string;
	userEmail: string;
	referralData: string;
	refereeEmail: string;
	registrationData: string;
	organisationName: string;
	startDate: Date;
	refereeName: string;

	referringOrganisationId?: string;
	referringOrganisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	referringUserId?: string;
	referringUser?: Models.UserEntity | Models.IUserEntityAttributes;
	registrationFiless: Array<
		| Models.RegistrationFileEntity
		| Models.IRegistrationFileEntityAttributes
	>;
	createdOrganisationId?: string;
	createdOrganisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	createdUserId?: string;
	createdUser?: Models.UserEntity | Models.IUserEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	parsedRegistrationData?: RegistrationData;
	parsedReferralData?: ReferralRegistrationData;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RegistrationEntity', 'Registration')
// % protected region % [Customise your entity metadata here] end
export default class RegistrationEntity extends Model
	implements IRegistrationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRegistrationEntity(),
		new UserRegistrationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Referral Status'] off begin
	@observable
	@attribute<RegistrationEntity, Enums.registrationStatus>()
	@CRUD({
		name: 'Referral Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.registrationStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.registrationStatusOptions),
		displayFunction: (attr: Enums.registrationStatus) => Enums.registrationStatusOptions[attr],
	})
	public referralStatus: Enums.registrationStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Referral Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] off begin
	/**
	 * Date registration was finished. Null if registration is still pending.
	 */
	@observable
	@attribute<RegistrationEntity, Date>()
	@CRUD({
		name: 'Completed Date',
		displayType: 'datetimepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public completedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Products'] off begin
	/**
	 * Products
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Products',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public products: string;
	// % protected region % [Modify props to the crud options here for attribute 'Products'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	/**
	 * Status
	 */
	@observable
	@attribute<RegistrationEntity, Enums.registrationStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.registrationStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.registrationStatusOptions),
		displayFunction: (attr: Enums.registrationStatus) => Enums.registrationStatusOptions[attr],
	})
	public status: Enums.registrationStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'User Name'] off begin
	/**
	 * User Name
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'User Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'User Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'User Email'] off begin
	/**
	 * User Email
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'User Email',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public userEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'User Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referral Data'] off begin
	/**
	 * JSON field to hold information for the referral partner registration
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Referral Data',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public referralData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referral Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referee Email'] off begin
	/**
	 * The email of the person that the referral link was created for
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Referee Email',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public refereeEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referee Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Registration Data'] off begin
	/**
	 * JSON field used to hold contents of various questions
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Registration Data',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public registrationData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Registration Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Organisation Name'] off begin
	/**
	 * Organisation Name
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Organisation Name',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public organisationName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Organisation Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] off begin
	/**
	 * The actual start date of the registration, as registration can be created before actually being started
	 */
	@observable
	@attribute<RegistrationEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Start Date',
		displayType: 'datepicker',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public startDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Referee Name'] off begin
	/**
	 * The name of the person that the referral link was created for
	 */
	@observable
	@attribute<RegistrationEntity, string>()
	@CRUD({
		name: 'Referee Name',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public refereeName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Referee Name'] end

	/**
	 * Keep track of organisation which made the referral
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Referring Organisation'] off begin
		name: 'Referring Organisation',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Referring Organisation'] end
	})
	public referringOrganisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public referringOrganisation: Models.OrganisationEntity;

	/**
	 * Keep track of users who create referrals
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Referring User'] off begin
		name: 'Referring User',
		displayType: 'reference-combobox',
		order: 140,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'Referring User'] end
	})
	public referringUserId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public referringUser: Models.UserEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Registration Files'] off begin
		name: 'Registration Filess',
		displayType: 'reference-multicombobox',
		order: 150,
		referenceTypeFunc: () => Models.RegistrationFileEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'registrationFiless',
			oppositeEntity: () => Models.RegistrationFileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Registration Files'] end
	})
	public registrationFiless: Models.RegistrationFileEntity[] = [];

	/**
	 * Registration which created this organisation
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Created Organisation'] off begin
		name: 'Created Organisation',
		displayType: 'reference-combobox',
		order: 160,
		referenceTypeFunc: () => Models.OrganisationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'createdOrganisations',
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Created Organisation'] end
	})
	public createdOrganisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public createdOrganisation: Models.OrganisationEntity;

	/**
	 * Registration which created this user
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Created User'] off begin
		name: 'Created User',
		displayType: 'reference-combobox',
		order: 170,
		referenceTypeFunc: () => Models.UserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'createdUsers',
			oppositeEntity: () => Models.UserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Created User'] end
	})
	public createdUserId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public createdUser: Models.UserEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable public parsedRegistrationData: RegistrationData;
	@observable public parsedReferralData: ReferralRegistrationData;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRegistrationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRegistrationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.referralStatus !== undefined) {
				this.referralStatus = attributes.referralStatus;
			}
			if (attributes.completedDate !== undefined) {
				if (attributes.completedDate === null) {
					this.completedDate = attributes.completedDate;
				} else {
					this.completedDate = moment(attributes.completedDate).toDate();
				}
			}
			if (attributes.products !== undefined) {
				this.products = attributes.products;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.userEmail !== undefined) {
				this.userEmail = attributes.userEmail;
			}
			if (attributes.referralData !== undefined) {
				this.referralData = attributes.referralData;
			}
			if (attributes.refereeEmail !== undefined) {
				this.refereeEmail = attributes.refereeEmail;
			}
			if (attributes.registrationData !== undefined) {
				this.registrationData = attributes.registrationData;
			}
			if (attributes.organisationName !== undefined) {
				this.organisationName = attributes.organisationName;
			}
			if (attributes.startDate !== undefined) {
				if (attributes.startDate === null) {
					this.startDate = attributes.startDate;
				} else {
					this.startDate = moment(attributes.startDate).toDate();
				}
			}
			if (attributes.refereeName !== undefined) {
				this.refereeName = attributes.refereeName;
			}
			if (attributes.referringOrganisationId !== undefined) {
				this.referringOrganisationId = attributes.referringOrganisationId;
			}
			if (attributes.referringOrganisation !== undefined) {
				if (attributes.referringOrganisation === null) {
					this.referringOrganisation = attributes.referringOrganisation;
				} else if (attributes.referringOrganisation instanceof Models.OrganisationEntity) {
					this.referringOrganisation = attributes.referringOrganisation;
					this.referringOrganisationId = attributes.referringOrganisation.id;
				} else {
					this.referringOrganisation = new Models.OrganisationEntity(attributes.referringOrganisation);
					this.referringOrganisationId = this.referringOrganisation.id;
				}
			}
			if (attributes.referringUserId !== undefined) {
				this.referringUserId = attributes.referringUserId;
			}
			if (attributes.referringUser !== undefined) {
				if (attributes.referringUser === null) {
					this.referringUser = attributes.referringUser;
				} else if (attributes.referringUser instanceof Models.UserEntity) {
					this.referringUser = attributes.referringUser;
					this.referringUserId = attributes.referringUser.id;
				} else {
					this.referringUser = new Models.UserEntity(attributes.referringUser);
					this.referringUserId = this.referringUser.id;
				}
			}
			if (attributes.registrationFiless !== undefined && Array.isArray(attributes.registrationFiless)) {
				for (const model of attributes.registrationFiless) {
					if (model instanceof Models.RegistrationFileEntity) {
						this.registrationFiless.push(model);
					} else {
						this.registrationFiless.push(new Models.RegistrationFileEntity(model));
					}
				}
			}
			if (attributes.createdOrganisationId !== undefined) {
				this.createdOrganisationId = attributes.createdOrganisationId;
			}
			if (attributes.createdOrganisation !== undefined) {
				if (attributes.createdOrganisation === null) {
					this.createdOrganisation = attributes.createdOrganisation;
				} else if (attributes.createdOrganisation instanceof Models.OrganisationEntity) {
					this.createdOrganisation = attributes.createdOrganisation;
					this.createdOrganisationId = attributes.createdOrganisation.id;
				} else {
					this.createdOrganisation = new Models.OrganisationEntity(attributes.createdOrganisation);
					this.createdOrganisationId = this.createdOrganisation.id;
				}
			}
			if (attributes.createdUserId !== undefined) {
				this.createdUserId = attributes.createdUserId;
			}
			if (attributes.createdUser !== undefined) {
				if (attributes.createdUser === null) {
					this.createdUser = attributes.createdUser;
				} else if (attributes.createdUser instanceof Models.UserEntity) {
					this.createdUser = attributes.createdUser;
					this.createdUserId = attributes.createdUser.id;
				} else {
					this.createdUser = new Models.UserEntity(attributes.createdUser);
					this.createdUserId = this.createdUser.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (!!attributes.parsedRegistrationData) {
				// If we create an entity directly, we can assign this without needing to convert to JSON first
				this.parsedRegistrationData = attributes.parsedRegistrationData;
			} else {
				// After setting up all the botwritten fields, we also parse the registration data into a usable object
				let registrationData;
				try {
					registrationData = JSON.parse(this.registrationData);
				} catch (e) {
					registrationData = {};
				}
				this.parsedRegistrationData = new RegistrationData(registrationData);
			}

			if (!!attributes.parsedReferralData) {
				// If we create an entity directly, we can assign this without needing to convert to JSON first
				this.parsedReferralData = attributes.parsedReferralData;
			} else {
				// After setting up all the botwritten fields, we also parse the registration data into a usable object
				let registrationData;
				try {
					registrationData = JSON.parse(this.referralData);
				} catch (e) {
					registrationData = {};
				}
				this.parsedReferralData = new ReferralRegistrationData(registrationData);
			}

			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public listExpands = '';

	public defaultExpands = `
		referringOrganisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		referringUser {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		registrationFiless {
			${Models.RegistrationFileEntity.getAttributes().join('\n')}
			${Models.RegistrationFileEntity.getFiles().map(f => f.name).join('\n')}
		}
		createdOrganisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		createdUser {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			registrationFiless: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'registrationFiless',
							'createdOrganisation',
							'createdUser',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public beforeSave() {
		if (!!this.parsedRegistrationData) {
			this.registrationData = JSON.stringify(this.parsedRegistrationData.toJSON(
				{
					user: {},
					organisation: {
						authorisedCreditBureaus: {},
					},
					businessEntitys: {
						address: {},
						spgss: {},
						applicationss: {},
					},
					industryCode: {},
				},
			));
		}
		if (!!this.parsedReferralData) {
			this.referralData = JSON.stringify(this.parsedReferralData.toJSON(
				{
					user: {},
					organisation: {
						primaryBusinessEntity: {},
					},
				},
			));
		}
	}

	// Upon finishing the registration wizard, call this function to finalise it
	public submitRegistration = async (referralPartner = false) => {
		await this.save();
		const path = referralPartner ? 'submit-referral-partner' : 'submit';
		const result = await axios.post(
			`${SERVER_URL}/api/entity/RegistrationEntity/${path}/${this.id}`,
		);

		return result.data as boolean;
	};
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RegistrationEntity.prototype, 'created');
CRUD(modifiedAttr)(RegistrationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
