import {
	AddressEntity,
	BusinessEntity,
} from 'Models/Entities';
import { Colors, Display } from '../Button/Button';

import BusinessEntityFields from './BusinessEntityFields';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import If from '../If/If';
import React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import { useState } from 'react';

export interface BusinessEntityDetailsProps {
	isPrimary: boolean,
	originalBusinessEntity: BusinessEntity,
	primaryBusinessEntity: BusinessEntity,
	setName?: (name: string) => void,
	openProductModal?: ('add-ppsr' | 'add-approve' | 'add-intel' | 'none');
}

export const canEditBusinessEntity = (businessEntity: BusinessEntity): boolean => {
	if (!businessEntity.isActive || !store.userPermissions.commonManageOrganisations) {
		return false;
	}
	if (store.userType === 'SUPER_USER') {
		return true;
	}
	if (store.userPermissions.commonManageBusinessEntities === 'ALL') {
		return true;
	}
	if (store.userPermissions.commonManageBusinessEntities === 'WITHIN_ORGANISATION') {
		if (!store.getUser?.organisation?.id) {
			return false;
		}
		return businessEntity.organisationId === store.getUser?.organisation?.id;
	}
	if (store.userPermissions.commonManageBusinessEntities === 'WITHIN_BUSINESS_ENTITIES') {
		if (!store.getUser?.businessEntityIds) {
			return false;
		}
		return store.getUser?.businessEntityIds?.indexOf(businessEntity.id) >= 0;
	}

	// In case of any other state, default to no access
	return false;
};

const BusinessEntityDetails: React.FunctionComponent<BusinessEntityDetailsProps> = observer(props => {
	const {
		isPrimary,
		originalBusinessEntity,
		primaryBusinessEntity,
		setName,
		openProductModal,
	} = props;

	const disabled = !canEditBusinessEntity(originalBusinessEntity);

	const [hasChanges, setHasChanges] = useState(false);
	const [businessEntity, setBusinessEntity] = useState(() => {
		const be = new BusinessEntity(originalBusinessEntity);
		be.address = new AddressEntity(originalBusinessEntity.address);
		return be;
	});

	const onAfterChange = (validateSuccessful: boolean) => {
		setHasChanges(validateSuccessful);
	};

	/**
	 * Method to save the updated user information. Raises an alert toast on success and on error.
	 */
	const onSavePressed = async () => {
		await businessEntity.validateDetailsFields();
		if (Object.keys(businessEntity.errors).length === 0) {
			try {
				await businessEntity.saveWithFetchBack({
					address: {},
				}, {}, `
					address {
						${AddressEntity.getAttributes().join('\n')}
					}
				`);

				originalBusinessEntity.applicationss = [];
				originalBusinessEntity.spgss = [];

				originalBusinessEntity.assignAttributes(businessEntity);
			} catch (e) {
				alertToast('Business Entity details could not be saved at this moment. Please '
					+ 'refresh and try again.', 'error');
				return;
			}

			alertToast('Business Entity details have been successfully updated', 'success');
			if (!!setName) {
				setName(businessEntity.name);
			}
			setHasChanges(false);
		}
	};

	return (
		<div className="business-entity-details">
			<h3>Business entity details</h3>
			<BusinessEntityFields
				organisationEmail={primaryBusinessEntity.email}
				businessEntity={businessEntity}
				originalBusinessEntity={originalBusinessEntity}
				disabled={disabled}
				isPrimary={isPrimary}
				onAfterChange={onAfterChange}
				openProductModal={openProductModal}
			/>

			<If condition={!disabled}>
				<div>
					<ButtonAsyncState
						className="save"
						colors={Colors.Primary}
						display={Display.Solid}
						onPress={onSavePressed}
						readonly={!hasChanges}
					>
						Save Changes
					</ButtonAsyncState>
				</div>
			</If>
		</div>
	);
});
export default BusinessEntityDetails;
