import type { ESignPagingOptions, ESignUserSummary } from 'ESign/Types/Summary/ESignUserSummary';
import {
	action,
	observable,
	runInAction,
} from 'mobx';
import { ESignUserSigneeCeremony, ESignUserWitnessCeremony } from '../Types/Summary/ESignUserCeremony';

import eSignEndpoints from 'ESign/Api/ESignEndpoints';
import ESignPaths from 'ESign/Router/ESignPaths';
import LoadableState from 'ESign/Types/ESignLoadableState';
import { ESignUserCeremonySaveRequest } from 'ESign/Types/ESignSaveRequest';
import { ESignWitnessDetail } from 'ESign/Types/ESignWitnessDetail';
import getLocationCoordinates from 'ESign/Utils/LocationPermission';
import alertToast from 'Util/ToastifyUtils';
import { AxiosError } from 'axios';
import moment from 'moment';
import { eSignStore } from './ESignStore';

const defaultUserSummary: ESignUserSummary = {
	id: '',
	name: '',
	phone: '',
	email: '',
	status: 'INACTIVE',
	signature: null,
	signeeCeremonyDetails: null,
	witnessCeremonyDetails: null,
};

const defaultPagingOptions: ESignPagingOptions = {
	pageNumber: 1,
	pageSize: 5,
	userType: 'Both',
};

export default class ESignWorkflowStore {
	@observable loadingState: LoadableState = LoadableState.Unloaded;
	@observable summaryState: LoadableState = LoadableState.Pending;
	@observable userSummary: ESignUserSummary = defaultUserSummary;
	@observable selectedCeremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony | undefined;
	@observable isWorkflowExpired = false;
	@observable signeeCeremonyPagingOptions: ESignPagingOptions = defaultPagingOptions;
	@observable witnessCeremonyPagingOptions: ESignPagingOptions = defaultPagingOptions;
	@observable signeeCeremonyState: LoadableState = LoadableState.Unloaded;
	@observable witnessCeremonyState = LoadableState.FulFilled;

	constructor() {
		this.loadingState = LoadableState.Unloaded;
		this.userSummary = defaultUserSummary;
		this.summaryState = LoadableState.Pending;
		this.selectedCeremony = undefined;
		this.isWorkflowExpired = false;
		this.signeeCeremonyPagingOptions = defaultPagingOptions;
		this.witnessCeremonyPagingOptions = defaultPagingOptions;
	}

	@action handleSigneeCeremonyPagingOptions = async (pageNumber: number, pageSize: number) => {
		if (!this.userSummary.signeeCeremonyDetails) {
			return;
		}

		let updatedPageNumber = pageNumber;
		const updatedPageSize = pageSize;
		const { totalPages } = this.userSummary.signeeCeremonyDetails;
		updatedPageNumber = Math.max(1, Math.min(updatedPageNumber, totalPages));

		if (updatedPageSize !== this.signeeCeremonyPagingOptions.pageSize) {
			this.signeeCeremonyPagingOptions.pageNumber = 1;
		} else {
			this.signeeCeremonyPagingOptions.pageNumber = updatedPageNumber;
		}

		this.signeeCeremonyPagingOptions.pageSize = updatedPageSize;

		try {
			this.signeeCeremonyState = LoadableState.Pending;
			const response = await eSignEndpoints.ESignUser.getSummary(this.userSummary.id, {
				pageNumber: updatedPageNumber,
				pageSize: updatedPageSize,
				userType: 'Signee',
			});
			runInAction(() => {
				this.userSummary.signeeCeremonyDetails = response.signeeCeremonyDetails;
				this.signeeCeremonyState = LoadableState.FulFilled;
			});
		} catch (error) {
			alertToast('Failed to fetch details. Please try again', 'error');
			throw error;
		}
	};

	@action handleWitnessCeremonyPagingOptions = async (pageNumber: number, pageSize: number) => {
		if (!this.userSummary.witnessCeremonyDetails) {
			return;
		}

		let updatedPageNumber = pageNumber;
		const updatedPageSize = pageSize;
		const { totalPages } = this.userSummary.witnessCeremonyDetails;
		updatedPageNumber = Math.max(1, Math.min(updatedPageNumber, totalPages));

		if (updatedPageSize !== this.signeeCeremonyPagingOptions.pageSize) {
			this.witnessCeremonyPagingOptions.pageNumber = 1;
		} else {
			this.witnessCeremonyPagingOptions.pageNumber = updatedPageNumber;
		}

		this.witnessCeremonyPagingOptions.pageSize = updatedPageSize;

		try {
			this.witnessCeremonyState = LoadableState.Pending;
			const response = await eSignEndpoints.ESignUser.getSummary(this.userSummary.id, {
				pageNumber: this.witnessCeremonyPagingOptions.pageNumber,
				pageSize: this.witnessCeremonyPagingOptions.pageSize,
				userType: 'Witness',
			});
			runInAction(() => {
				this.userSummary.witnessCeremonyDetails = response.witnessCeremonyDetails;
				this.witnessCeremonyState = LoadableState.FulFilled;
			});
		} catch (error) {
			alertToast('Failed to fetch details. Please try again', 'error');
			throw error;
		}
	};

	@action resetStore = () => {
		this.loadingState = LoadableState.Unloaded;
		this.userSummary = defaultUserSummary;
		this.summaryState = LoadableState.Pending;
		this.selectedCeremony = undefined;
	};

	@action setLoadingState = (state: LoadableState) => {
		this.loadingState = state;
	};

	@action setIsWorkflowExpired = (isExpired: boolean) => {
		this.isWorkflowExpired = isExpired;
		this.loadingState = LoadableState.Unloaded;
		this.getUserSummary(this.userSummary.id);
	};

	@action getUserSummary = async (userId: string) => {
		this.summaryState = LoadableState.Pending;
		try {
			const response = await eSignEndpoints.ESignUser.getSummary(
				userId,
				{ pageNumber: 1, pageSize: 5, userType: 'Both' },
			);
			runInAction(() => {
				this.userSummary = response;
				this.summaryState = LoadableState.FulFilled;
			});
		} catch (error) {
			alertToast('Failed to fetch details. Please try again', 'error');
			runInAction(() => {
				this.userSummary = defaultUserSummary;
				this.summaryState = LoadableState.Rejected;
			});
			throw error;
		}
	};

	@action setSelectedCeremony = async (ceremonyId: string) => {
		const signeeCeremony = this.userSummary.signeeCeremonyDetails?.items
			.find(ceremony => ceremony.id === ceremonyId);
		const witnessCeremony = this.userSummary.witnessCeremonyDetails?.items
			.find(ceremony => ceremony.id === ceremonyId);

		if (witnessCeremony) {
			this.selectedCeremony = witnessCeremony;
		} else if (signeeCeremony) {
			this.selectedCeremony = signeeCeremony;
		} else {
			this.selectedCeremony = undefined;
		}

		if (this.selectedCeremony) {
			const isExpired = moment().isAfter(moment(this.selectedCeremony.workflow.created)
				.add(this.selectedCeremony.workflow.template.lifeSpan ?? 0, 'days'));
			if (isExpired && this.selectedCeremony.workflow.status !== 'INCOMPLETE') {
				this.loadingState = LoadableState.Pending;
				try {
					await eSignEndpoints.EsignWorkflow.markExpired(this.selectedCeremony.workflow.id);
					runInAction(() => {
						this.loadingState = LoadableState.FulFilled;
						this.isWorkflowExpired = true;
					});
				} catch (error) {
					runInAction(() => {
						this.loadingState = LoadableState.Rejected;
					});
					alertToast('Please refresh the page and try again.', 'error');
				}
			}
		}
	};

	@action setLocation = async () => {
		if (this.selectedCeremony) {
			const response = await getLocationCoordinates()
				.catch(error => {
					console.error(error);
					return undefined;
				})
				.then(data => data);

			runInAction(() => {
				if (response) {
					this.selectedCeremony!.location = JSON.stringify(response);
				}
			});
		}
	};

	@action async getIpAddresses(): Promise<string> {
		try {
			const ipAddress = await eSignEndpoints.ESignUser.getIpAddressFromIpIfy();
			return ipAddress.ip;
		} catch (ipIfyError) {
			console.error(ipIfyError);
			try {
				const ipAddress = await eSignEndpoints.ESignUser.getIpFromGeoLocation();
				return ipAddress.IPV4;
			} catch (geoLocationError) {
				console.error(geoLocationError);
				try {
					const ipAddress = await eSignEndpoints.ESignUser.getIpFromIpApi();
					return ipAddress.ip;
				} catch (ipApiError) {
					console.error(ipApiError);
					throw new Error('Failed to get IP address');
				}
			}
		}
	}

	@action saveTemplateContent = async (
		updatedTemplateContent: string,
		updatedSignature: string,
	): Promise<void> => {
		this.setLoadingState(LoadableState.Pending);

		if (this.selectedCeremony) {
			try {
				const userId = this.userSummary.id;
				const ceremonyId = this.selectedCeremony.id;
				const { type } = this.selectedCeremony;

				await eSignEndpoints.ESignUser.saveTemplateContentOnly(
					userId,
					ceremonyId,
					updatedTemplateContent,
					updatedSignature,
					type,
				);

				runInAction(async () => {
					this.getUserSummary(userId);
					this.setLoadingState(LoadableState.Unloaded);
				});
				alertToast('Template content saved successfully', 'success');
			} catch (error) {
				const axiosError = error as AxiosError;
				if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
					alertToast('Please verify your identity and try again.', 'error');
					runInAction(() => {
						eSignStore.commonStore.updateIsVerified(false);
						eSignStore.commonStore.setHasCode(false);
						eSignStore.commonStore.history.push(ESignPaths.getAuthorised(this.userSummary.id, this.selectedCeremony!.workflow.id));
						this.resetStore();// Clear the loading state cause we are redirecting to authorise page
					});
				} else {
					alertToast('Failed to save details. Please try again', 'error');
					this.setLoadingState(LoadableState.Rejected);
				}
			}
			return;
		}
		alertToast('Unable to save details. Please try again', 'error');
		this.setLoadingState(LoadableState.Rejected);
	};

	@action rejectCeremony = async (comment: string): Promise<void> => {
		this.setLoadingState(LoadableState.Pending);
		if (this.selectedCeremony) {
			const userId = this.userSummary.id;
			const ceremonyId = this.selectedCeremony.id;
			const ceremonyType = this.selectedCeremony.type;

			try {
				await eSignEndpoints.ESignUser
					.rejectCeremony(userId, ceremonyId, comment, ceremonyType);
				runInAction(async () => {
					await this.getUserSummary(userId);
					this.setLoadingState(LoadableState.FulFilled);
				});
				alertToast('Successfully updated the request changes', 'success');
			} catch (error) {
				const axiosError = error as AxiosError;
				if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
					alertToast('Please verify your identity and try again.', 'error');
					runInAction(() => {
						eSignStore.commonStore.updateIsVerified(false);
						eSignStore.commonStore.setHasCode(false);
						eSignStore.commonStore.history.push(ESignPaths.getAuthorised(userId, this.selectedCeremony!.workflow.id));
						this.resetStore();// Clear the loading state cause we are redirecting to authorise page
					});
				} else {
					alertToast('Failed to made changes. Please try again', 'error');
					this.setLoadingState(LoadableState.Rejected);
				}
			}
			return;
		}
		alertToast('Unable to reject the request. Please try again', 'error');
		this.setLoadingState(LoadableState.Rejected);
	};

	@action saveCeremony = async (
		updatedTemplateContent: string,
		updatedSignature: string,
		witnessDetails?: ESignWitnessDetail,
	): Promise<void> => {
		this.setLoadingState(LoadableState.Pending);
		if (this.selectedCeremony) {
			this.selectedCeremony.ipAddress = await this.getIpAddresses();
			const {
				id,
				location,
				ipAddress,
				type,
				workflow: { id: workflowId },
			} = this.selectedCeremony;

			const userId = this.userSummary.id;

			const requestBody: ESignUserCeremonySaveRequest = {
				workflowId: workflowId,
				type: type,
				signature: updatedSignature,
				location: location,
				ipAddress: ipAddress,
				templateContent: updatedTemplateContent,
				witnessDetail: witnessDetails,
			};

			try {
				const response = await eSignEndpoints.ESignUser.saveCeremony(userId, id, requestBody);
				runInAction(async () => {
					await this.getUserSummary(userId);
					runInAction(() => {
						this.loadingState = LoadableState.FulFilled;
					});
				});
				if (response.succeed) {
					alertToast('Successfully saved the request changes', 'success');
				} else {
					alertToast(response.errors.Workflow, 'error');
				}
			} catch (error) {
				const axiosError = error as AxiosError;
				if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
					alertToast('Please verify your identity and try again.', 'error');
					runInAction(() => {
						eSignStore.commonStore.updateIsVerified(false);
						eSignStore.commonStore.setHasCode(false);
						eSignStore.commonStore.history.push(ESignPaths.getAuthorised(userId, this.selectedCeremony!.workflow.id));
						this.resetStore();// Clear the loading state cause we are redirecting to authorise page
					});
				} else {
					alertToast('Failed to save details. Please try again,', 'error');
					runInAction(() => {
						this.setLoadingState(LoadableState.Rejected);
					});
				}
			}
			return;
		}
		alertToast('Unable to save details. Please try again', 'error');
		this.setLoadingState(LoadableState.Rejected);
	};
}
