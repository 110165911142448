import * as React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import If from '../If/If';
import InputWrapper from '../Inputs/InputWrapper';
import * as uuid from 'uuid';
import { DisplayType } from '../Models/Enums';
import InputsHelper from '../Helpers/InputsHelper';

export interface ITextFieldSetterProps {
	value?: string;
	setValue?: (newValue: string) => void;
	id?: string;
	name?:string;
	className?: string;
	inputClassName?: string;
	defaultToUndefined?: boolean;
	displayType?: DisplayType;
	label?: string;
	labelVisible?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	staticInput?: boolean;
	tooltip?: string;
	subDescription?: string;
	inputProps?: React.InputHTMLAttributes<Element>;
	placeholder?: string;
	clickToClear?: boolean;
	autoFocus?: boolean;
	errors?: string | string[];
	onAfterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickToClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	sideButton?: React.ReactNode;
}

// Updated version of TextField which uses a setter instead of setting a value directly
// This makes it easier to use with values taken from useState
@observer
export class TextFieldSetter extends React.Component<ITextFieldSetterProps, any> {
	public static defaultProps = {
		clickToClear: false,
		inputProps: {},
		className: '',
	};

	private _input?: HTMLInputElement;
	private uuid = uuid.v4();
	private valueWhenFocused: string = '';

	componentDidMount() {
		const { autoFocus } = this.props;
		if (autoFocus && this._input) {
			this._input.focus();
		}
	}

	public render() {
		const {
			value,
			name,
			className,
			id = this.uuid,
			displayType,
			label,
			labelVisible = true,
			isRequired,
			isDisabled,
			isReadOnly,
			staticInput,
			tooltip,
			subDescription,
			clickToClear,
			placeholder,
			errors,
			sideButton,
			inputProps,
			inputClassName,
		} = this.props;
		const fieldId = `${id}-field`;

		const ariaLabel = !labelVisible ? label : undefined;
		const ariaDescribedby = InputsHelper.getAriaDescribedBy(id, tooltip, subDescription);
		return (
			<InputWrapper
				id={id}
				inputId={fieldId}
				className={className}
				displayType={displayType}
				isRequired={isRequired}
				staticInput={staticInput}
				tooltip={tooltip}
				subDescription={subDescription}
				label={label}
				labelVisible={labelVisible}
				errors={errors}
				sideButton={sideButton}
			>
				<input
					className={inputClassName}
					type="text"
					name={name}
					id={fieldId}
					value={value}
					onChange={this.onChange}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					placeholder={placeholder || (label || undefined)}
					disabled={isDisabled}
					readOnly={staticInput || isReadOnly}
					aria-label={ariaLabel}
					aria-describedby={ariaDescribedby}
					ref={i => { this._input = i ?? undefined; }}
					{...inputProps}
				/>
				<If condition={clickToClear}>
					<button
						className="click-to-clear icon-cross icon-right"
						onClick={this.onClickToClear}
						type="button"
						aria-label="Click to clear"
					/>
				</If>
			</InputWrapper>
		);
	}

	@action
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Need to disable the rule here since we change the type which is incompatible with destructuring
		// eslint-disable-next-line prefer-destructuring
		let value: string | undefined = event.target.value;
		value = (value === '' && this.props.defaultToUndefined) ? undefined : value;

		if (this.props.setValue) {
			this.props.setValue(value ?? '');
		}

		if (this.props.onAfterChange) {
			this.props.onAfterChange(event);
		}
	}

	@action
	private onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.valueWhenFocused = event.target.value;
	}

	@action
	private onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (this.valueWhenFocused !== event.target.value && this.props.onChangeAndBlur) {
			this.props.onChangeAndBlur(event);
		}
	}

	@action
	private onClickToClear = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (this.props.onClickToClear) {
			return this.props.onClickToClear(event);
		}

		if (this.props.setValue) {
			this.props.setValue('');
		}
	}
}
