import moment from 'moment';
import { ProcessingErrorInputType, ProcessingErrorType } from '../../../Models/Enums';
import { TextField } from '../TextBox/TextBox';
import If from '../If/If';
import { DatePicker } from '../DatePicker/DatePicker';
import { IconDisplay, IconTextBox } from '../TextBox/IconTextBox';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { EmptyGuid, verifyAbn, verifyAcn } from 'Util/StringUtils';
import classNames from 'classnames';
import { action } from 'mobx';
import { Button, Colors, Display } from '../Button/Button';
import LinkExistingCustomerModal from './LinkExistingCustomerModal';
import AddNewCustomerModal from './AddNewCustomerModal';
import ButtonAsyncState from '../Button/ButtonAsyncState';
import { ProcessingError } from './AgedTrialBalanceDetail';
import { isPhone } from '../../../Validators/Functions/Custom';

interface ProcessingErrorItemProps {
	id: string;
	row: number;
	column: string;
	field: string;
	customer: string;
	detail: string|undefined;
	errorType: ProcessingErrorType;
	inputType: ProcessingErrorInputType;
	originalValue: string|undefined;
	required: boolean;
	expanded: boolean;
	atbRecordEntityId: string;
	businessEntityId: string;
	atbFileId: string;
	onToggleExpand: (id: string) => void;
	onResolveError: (
		atbRecordEntityId: string,
		processingError: ProcessingError,
		value: string|undefined
	) => Promise<void>;
	deleteAtbRow: (atbRecordEntityId: string) => Promise<void>;
}

const ProcessingErrorTitle = (errorType: ProcessingErrorType, errorColumn: string, customer: string) => {
	switch (errorType) {
		case 'Internal':
			return 'System Processing Error';
		case 'Required':
			return 'Field Required';
		case 'CustomerNoMatch':
			return `Customer Not Found: ${customer}`;
		case 'CustomerPartialMatch':
			return `Customer Update Required: ${customer}`;
		case 'CustomerDuplicateMatch':
			return `Duplicate Customer Row: ${customer}`;
		case 'Invalid':
		// Fallthrough
		default:
			return `Invalid ${errorColumn}`;
	}
};

const DateStringFormat = 'YYYY/MM/DD';

const ProcessingErrorItem = (props: ProcessingErrorItemProps) => {
	const {
		id,
		row,
		column,
		field,
		customer,
		detail,
		errorType,
		inputType,
		originalValue,
		required,
		expanded,
		atbRecordEntityId,
		businessEntityId,
		atbFileId,
		onToggleExpand,
		onResolveError,
		deleteAtbRow,
	} = props;

	const collapsed = !expanded;
	const [value, setValue] = useState<string|undefined>(originalValue);
	const [inputError, setInputError] = useState<string|undefined>();
	const [showExistingCustomerModal, setShowExistingCustomerModal] = useState<boolean>(false);
	const [showAddNewCustomerModal, setShowAddNewCustomerModal] = useState<boolean>(false);

	const processingError = {
		Type: errorType,
		InputType: inputType,
		Required: required,
		Column: column,
		Field: field,
		Detail: detail,
	} as ProcessingError;

	const validateTextField = React.useMemo(() => {
		return debounce((
			_inputType: ProcessingErrorInputType,
			text: string,
			fieldName: string,
		) => {
			if (fieldName === 'PhoneNo') {
				if (!!text && !isPhone(text)) {
					setInputError('Phone number must be valid');
					return;
				}
			}
			if (fieldName === 'AddressPostcode') {
				if (text !== '' && !text.match(/^[0-9]/)) {
					setInputError('Postcode must only contain numerical characters');
					return;
				}
			}
			if (fieldName === 'AddressCountry') {
				if (text !== '' && !text.match(/^[a-zA-Z]/)) {
					setInputError('Country must only contain letters');
					return;
				}
			}
			if (_inputType === 'Integer') {
				const int = Number.parseInt(text, 10);
				if (Number.isNaN(int)) {
					setInputError('Invalid value');
					return;
				}
				if (int < 0) {
					setInputError('Number must be positive');
					return;
				}
			}
			if (_inputType === 'Currency') {
				const float = Number.parseFloat(text);
				if (Number.isNaN(float)) {
					setInputError('Invalid value');
					return;
				}
				if (float < 0) {
					setInputError('Number must be positive');
					return;
				}
			}

			setInputError('');
		}, 300);
	}, []);

	const validateAbn = React.useMemo(() => {
		return debounce(async (abn?: string) => {
			if (!abn) {
				setInputError('ABN is required');
				return;
			}

			if (abn.replace(/\D/g, '').length < 11) {
				setInputError('The ABN must contain 11 digits');
				return;
			}

			const response = await verifyAbn(abn.toString());

			if (response?.exception?.exceptionDescription) {
				setInputError(response.exception.exceptionDescription);
				return;
			}

			const status = response.businessEntity.entityStatus.entityStatusCode;
			if (status !== 'Active') {
				setInputError('The ACN must be currently active');
				return;
			}

			setInputError('');
		}, 300);
	}, []);

	const validateAcn = React.useMemo(() => {
		return debounce(async (acn?: string) => {
			if (!acn) {
				setInputError('ACN is required');
				return;
			}

			if (acn.replace(/\D/g, '').length < 9) {
				setInputError('The ACN must contain 9 digits');
				return;
			}

			const response = await verifyAcn(acn.toString());

			if (response?.exception?.exceptionDescription) {
				setInputError(response.exception.exceptionDescription);
				return;
			}

			if ((response?.result?.records?.length ?? 0) === 0) {
				setInputError('No results found');
				return;
			}

			const anyValidRecords = (response?.result?.records as [] ?? [])
				?.some((x: { Status: string }) => x.Status === 'REGD');
			// ACN is valid if there is at least one REGD status ACN
			if (!anyValidRecords) {
				setInputError('The ACN must be currently active');
				return;
			}

			setInputError('');
		}, 300);
	}, []);

	useEffect(() => {
		if (collapsed) {
			return;
		}

		if (inputType === 'Abn') {
			validateAbn(originalValue);
		} else if (inputType === 'Acn') {
			validateAcn(originalValue);
		}
	}, [collapsed, inputType, originalValue, validateAbn, validateAcn]);

	return (
		<div className={classNames('processing-error', { collapsed })}>
			<div className="indicator icon-warning icon-only" />
			<div className="detail">
				<div className="title">{ProcessingErrorTitle(errorType, column, customer)}</div>
				<If condition={!!detail}>
					<div className="additional-detail">{detail}</div>
				</If>
				<div className="location">
					<div>for <b>{customer}</b>,</div>
					<div>row: {row},</div>
					<div>column <b>{column}</b></div>
				</div>
			</div>
			<div className="action">
				<If condition={inputType === 'String' || inputType === 'Integer' || inputType === 'Currency'}>
					<TextField
						model={{ value }}
						modelProperty="value"
						errors={inputError}
						isRequired={required}
						onAfterChange={event => {
							validateTextField(inputType, event.target.value, field);
							setValue(event.target.value);
						}}
					/>
				</If>
				<If condition={inputType === 'Date'}>
					<DatePicker
						model={{ value: moment(value).toDate() }}
						modelProperty="value"
						dateFormat={DateStringFormat}
						onAfterChange={dates => {
							setValue(moment(dates[0]).format(DateStringFormat));
						}}
						isRequired={required}
					/>
				</If>
				<If condition={inputType === 'Abn'}>
					<IconTextBox
						model={{ value }}
						modelProperty="value"
						onChangeAndBlur={action(event => {
							setValue(event.target.value);
							validateAbn(event.target.value);
						})}
						onAfterChange={action(event => {
							setValue(event.target.value);
							validateAbn(event.target.value);
						})}
						errors={inputError}
						isRequired={required}
						displayIconContainer
						iconDisplay={inputError === '' ? IconDisplay.Valid : IconDisplay.Warning}
					/>
				</If>
				<If condition={inputType === 'Acn'}>
					<IconTextBox
						model={{ value }}
						modelProperty="value"
						onChangeAndBlur={action(event => {
							setValue(event.target.value);
							validateAcn(event.target.value);
						})}
						onAfterChange={action(event => {
							setValue(event.target.value);
							validateAcn(event.target.value);
						})}
						errors={inputError}
						isRequired={required}
						displayIconContainer
						iconDisplay={inputError === '' ? IconDisplay.Valid : IconDisplay.Warning}
					/>
				</If>
				<If condition={inputType === 'Customer'}>
					<Button
						display={Display.Outline}
						colors={Colors.Primary}
						onClick={() => setShowExistingCustomerModal(true)}
					>
						Link Existing Customer
					</Button>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => setShowAddNewCustomerModal(true)}
					>
						Add New Customer
					</Button>
					<If condition={showExistingCustomerModal}>
						<LinkExistingCustomerModal
							atbFileId={atbFileId}
							businessEntityId={businessEntityId}
							onSubmit={action(async (customerEntityId: string) => {
								await onResolveError(atbRecordEntityId, processingError, customerEntityId);
							})}
							onClose={() => setShowExistingCustomerModal(false)}
						/>
					</If>
					<If condition={showAddNewCustomerModal}>
						<AddNewCustomerModal
							atbRecordId={atbRecordEntityId}
							businessEntityId={businessEntityId}
							onSubmit={action(async () => {
								await onResolveError(atbRecordEntityId, processingError, EmptyGuid);
							})}
							onClose={() => setShowAddNewCustomerModal(false)}
						/>
					</If>
				</If>
				<If condition={inputType !== 'Customer'}>
					<ButtonAsyncState
						type="button"
						display={Display.Solid}
						colors={Colors.Primary}
						readonly={inputError !== ''}
						onPress={action(async () => {
							await onResolveError(atbRecordEntityId, processingError, value);
						})}
					>
						Update
					</ButtonAsyncState>
				</If>
				<ButtonAsyncState
					type="button"
					display={Display.Solid}
					colors={Colors.Error}
					onPress={async () => {
						await deleteAtbRow(atbRecordEntityId);
					}}
					waitingText="Removing..."
				>
					Remove Row
				</ButtonAsyncState>
			</div>
			<button
				type="button"
				className={classNames('expander icon-only', collapsed ? 'icon-chevron-down' : 'icon-chevron-up')}
				onClick={() => onToggleExpand(id)}
				aria-label="Toggle Expand"
			/>
		</div>
	);
};

export default React.memo(ProcessingErrorItem);
