import { state } from 'Models/Enums'
import React from 'react'
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import { getPropertySearchByTitleAvailableOptions, PropertySearchByTitleOptionForRegion } from './PropertySearchByRegionType';
import { SearchByDetailInfoSection } from './SearchByDetailInfoSection';
import { getDetailInfoRequirementAsPerSelectedSearchByOption, retrieveSubItems } from './PropertySearchDefaults';

type Props = {
	searchBy: PropertySearchByTitleOptionForRegion | undefined;
	setSearchBy: React.Dispatch<React.SetStateAction<PropertySearchByTitleOptionForRegion | undefined>>
	searchByDetailInfo: string;
	setSearchByDetailInfo: React.Dispatch<React.SetStateAction<string | undefined>>
	selectedRegion: state;
	errors: string[] | undefined;
}

export const PropertySearchByRegionSection: React.FunctionComponent<Props> = ({
	searchBy, setSearchBy,  selectedRegion, setSearchByDetailInfo, searchByDetailInfo, errors
}) => {
	function renderDetailInfoSection(selectedOption: PropertySearchByTitleOptionForRegion, selectedState: state) {
		const detailInfoRequirement = getDetailInfoRequirementAsPerSelectedSearchByOption(selectedOption, selectedState);
		const searchByDetailInfoSubItems = retrieveSubItems(detailInfoRequirement.subItems, selectedState); 
		return (
			<SearchByDetailInfoSection
				value={searchByDetailInfo}
				setValue={setSearchByDetailInfo}
				label={detailInfoRequirement.label}
				isRequired={detailInfoRequirement.isRequired}
				placeholder={detailInfoRequirement.placeholder}
				subItems={searchByDetailInfoSubItems}
				errors={errors}
			/>
		)
	}

	return (
		<>
			<ComboboxSetter
				value={searchBy}
				setValue={data => {
					setSearchBy(data); setSearchByDetailInfo(undefined);}}
				label={"Search Type"}
				options={getPropertySearchByTitleAvailableOptions(selectedRegion)}
				getOptionValue={option => option}
				isRequired
			/>

			{searchBy && renderDetailInfoSection(searchBy, selectedRegion)}
		</>
	)
}