import { useEffect, useState } from 'react';
import { store } from 'Models/Store';
import {
	AtbFileEntity, OrganisationEntity,
} from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import EntityList from 'Views/Components/EntityList/EntityList';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import classNames from 'classnames';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { gql } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import moment from 'moment';
import { atbProcessingStatusOptions, atbFileTypeOptions, atbFileType, atbFileTypeComboboxOptions } from 'Models/Enums';
import { buildSearchConditions } from 'Util/GraphQLUtils';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import If from 'Views/Components/If/If';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import MultiComboboxSetter from 'Views/Components/Combobox/MultiComboboxSetter';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { requestBusiness } from 'Hooks/Api/requestBusiness';

const fetchUploadHistoryDebounced = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	dateRange: string,
	type: atbFileType | 'ALL',
	organisationIds: string[],
	businessEntityIds: string[],
	setCount: (newCount: number) => void,
) => {
	return fetchUploadHistory(
		search,
		sortColumn,
		sortDescending,
		page,
		dateRange,
		type,
		organisationIds,
		businessEntityIds,
		setCount,
	);
}, 300, {
	leading: true,
});

const fetchUploadHistory = (async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	dateRange: string,
	type: atbFileType | 'ALL',
	organisationIds: string[],
	businessEntityIds: string[],
	setCount: (newCount: number) => void,
) => {
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['fileName', 'businessEntity.organisation.primaryBusinessEntity.name',
			'businessEntity.name'], search, comparison);
	}

	// Setup type filter
	const typeConditions: IWhereCondition<string>[] = [];
	if (!!type && type !== 'ALL') {
		typeConditions.push({
			comparison: 'equal', path: 'atbFileType', value: type,
		});
	}

	// Setup Target month date type filter
	const startTargetMonthConditions: IWhereCondition<string>[] = [];
	const endTargetMonthConditions: IWhereCondition<string>[] = [];

	if (dateRange.length > 0) {
		startTargetMonthConditions.push({
			path: 'targetMonth',
			value: [moment(dateRange[0]).format('YYYY-MM')],
			comparison: 'greaterThanOrEqual',
		});

		endTargetMonthConditions.push({
			path: 'targetMonth',
			value: [moment(dateRange[1]).format('YYYY-MM')],
			comparison: 'lessThanOrEqual',
		});
	}

	// Setup organisation ID filter
	const organisationConditions: IWhereCondition<string>[] = [];
	if (!!organisationIds && organisationIds.length > 0) {
		organisationConditions.push({
			comparison: 'in', path: 'businessEntity.organisationId', value: organisationIds,
		});
	}

	// Setup business ID filter
	const businessEntityConditions: IWhereCondition<string>[] = [];
	if (!!businessEntityIds && businessEntityIds.length > 0) {
		businessEntityConditions.push({
			comparison: 'in', path: 'businessEntityId', value: businessEntityIds,
		});
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];
	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}
	if (startTargetMonthConditions.length > 0) {
		allConditions.push(startTargetMonthConditions);
	}
	if (endTargetMonthConditions.length > 0) {
		allConditions.push(endTargetMonthConditions);
	}
	if (typeConditions.length > 0) {
		allConditions.push(typeConditions);
	}
	if (organisationConditions.length > 0) {
		allConditions.push(organisationConditions);
	}
	if (businessEntityConditions.length > 0) {
		allConditions.push(businessEntityConditions);
	}
	
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: sortColumn, descending: sortDescending });
	
	const query = gql`
		query atbFileEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]]
		) {
			atbFileEntitys(conditions: $conditions, skip: $skip, take:$take, orderBy: $orderBy) {
				id
				fileName
				atbFileType
				atbProcessingStatus
				targetMonth
				businessEntity {
					name
					id
					organisation {
						id
						businessEntitys {
							id
						}
						primaryBusinessEntity {
						name
						id
					}
				}
			}
			}
			${`countAtbFileEntitys(conditions: $conditions) {
				number
			}`}
		}
	`;
	
	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: ATBFILES_PAGE_LENGTH,
			skip: ATBFILES_PAGE_LENGTH * (page || 0),
			conditions: allConditions,
		},
	});
	
	setCount(data?.countAtbFileEntitys?.number);
	return data.atbFileEntitys.map((file: any) => new
	AtbFileEntity(file)) as AtbFileEntity[];
});

// Fetch organisations options. Used for organisation ID filter
const fetchOrganisationOptions = AwesomeDebouncePromise(async (
	search?: string,
) => {
	const organisations = await OrganisationEntity.fetchOrganisationList(search) ?? [];
	return organisations
		.map(x => {
			return {
				display: x.primaryBusinessEntity?.name,
				value: x.id,
			};
		}) as ComboboxOption<string>[];
}, 300);

const fetchBusinessentityOptions = AwesomeDebouncePromise(async (
	search?: string,
	orgIds?: string[],
) => {
	const resposne = await requestBusiness({
		filterByName: search,
	});
	let businesses = (resposne.data.businessEntitys);
	if (orgIds && orgIds.length > 0) {
		businesses = businesses
			.filter(business => orgIds.includes(business.organisationId ?? ''))
	}
	return businesses
		.map(business => {
			return {
				display: business.name,
				value: business.id,
			};
		}) as ComboboxOption<string>[];
}, 300);

const UploadHistoryList = () => {
	// For the search bar display
	const [search, setSearch] = useState('');
	const [sortColumn, setSortColumn] = useState('fileName');
	const [sortDescending, setSortDescending] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [dateRange, setDateRange] = useState('');
	const [type, setType] = useState('ALL' as atbFileType | 'ALL');
	const [organisationIds, setOrganisationIds] = useState([] as string[]);
	const [businessEntityIds, setBusinessEntityIds] = useState([] as string[]);
	const [businessEntityState, setBusinessEntityState] = useState([] as  ComboboxOption<string>[]);

	useEffect(() => {
		if (!businessEntityState || businessEntityState.length <= 0) {
			const fetchData = async () => {
				const data = await requestBusiness({
					filterByName: search,
				});
				const businesses = data.data.businessEntitys;
				const options = businesses.map(business => ({
					display: business.name,
					value: business.id,
				})) as ComboboxOption<string>[];
				setBusinessEntityState(options);
			};
			fetchData();
		}
	}, [businessEntityState, search]);

	const updateBeState = async () => {
		setBusinessEntityState(await fetchBusinessentityOptions('', organisationIds));
	}
	const dateEntity = {
		value: dateRange,
	};

	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};
	
	return (
		<div className="client-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						onAfterChange={() => setPage(0)}
						labelVisible={false}
						placeholder="Search by Business Entity or Organisation"
					/>
					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => {
							setShowFilters(show => !show);
							setType('ALL');
							setOrganisationIds([]);
							setBusinessEntityIds([]);
							setBusinessEntityState([]);
						}}
					>
						{showFilters ? 'Clear Filter' : 'Filter By'}
					</Button>
				</div>
				<If condition={showFilters}>
					<div className="search-filter-section referral-partner-list">
						<DatePicker
							className="start-date-filter"
							model={dateEntity}
							modelProperty="value"
							mode="range"
							placeholder="Target Month"
							flatpickrOptions={{
								plugins: [
									new (monthSelectPlugin as any)({
										shorthand: true,
										dateFormat: 'Z',
										altFormat: 'F Y',
									}),
								],
								altInput: true,
								maxDate: moment().utc().subtract(1, 'months').toDate(),
							}}
							onAfterChange={() => {
								if (!!setDateRange && !!dateEntity.value) {
									setDateRange(dateEntity.value);
								}
							}}
						/>
						<ComboboxSetter
							className="type-filter"
							value={type}
							setValue={setType}
							getOptionValue={(value?: string) => value}
							placeholder="ATB File Type"
							label=""
							onAfterSet={() => setPage(0)}
							labelVisible={false}
							searchable
							options={atbFileTypeComboboxOptions}
						/>
						<MultiComboboxSetter
							className="organisation-filter"
							value={organisationIds}
							setValue={setOrganisationIds}
							getOptionValue={(value?: string) => value}
							placeholder="Organisations"
							label=""
							labelVisible={false}
							searchable
							initialOptions={fetchOrganisationOptions}
							options={fetchOrganisationOptions}
							onAfterSet={() => { setBusinessEntityIds([]); setPage(0); updateBeState(); }}
						/>
						<MultiComboboxSetter
							className="business-entity-filter"
							value={businessEntityIds}
							setValue={setBusinessEntityIds}
							getOptionValue={(value?: string) => value}
							placeholder="Business Entities"
							label=""
							labelVisible={false}
							searchable
							initialOptions={fetchBusinessentityOptions}
							options={businessEntityState}
							onAfterSet={() => setPage(0)}
						/>
					</div>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the Client List */}
				<RequestWrap
					request={() => fetchUploadHistoryDebounced(
						search,
						sortColumn,
						sortDescending,
						page,
						dateRange,
						type,
						organisationIds,
						businessEntityIds,
						setCount,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
						dateRange,
						type,
						organisationIds,
						businessEntityIds,
					)}
				>
					{(files: AtbFileEntity[]) => (
						<>
							<EntityList
								collection={files}
								columns={[
									{
										displayName: 'Organisation name',
										columnName: 'businessEntity.organisation.primaryBusinessEntity.name',
										value: file => file.businessEntity.organisation.primaryBusinessEntity?.name,
										sortable: true,
										sortClicked: () => {
											onSortChange('businessEntity.organisation.primaryBusinessEntity.name');
										},
										className: 'field-organisation-name',
									},
									{
										displayName: 'Business Entity Name',
										columnName: 'businessEntity.name',
										value: file => file.businessEntity.name,
										sortable: true,
										sortClicked: () => {
											onSortChange('businessEntity.name');
										},
										className: 'field-business-name',
									},
									{
										displayName: 'Target Month',
										columnName: 'targetMonth',
										value: file => moment(file.targetMonth).format("MMM YYYY"),
										sortable: true,
										sortClicked: () => {
											onSortChange('targetMonth');
										},
										className: 'field-target-month',
									},
									{
										displayName: 'File Type',
										columnName: 'atbFileType',
										value: file =>  atbFileTypeOptions[file.atbFileType],
										sortable: false,
										className: 'field-type',
									},
									{
										displayName: 'File Name',
										columnName: 'fileName',
										value: file => file.fileName
											?? <span>File name not provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('fileName');
										},
										className: 'field-name',
									},
									{
										displayName: 'Status',
										columnName: 'atbProcessingStatus',
										value: file => atbProcessingStatusOptions[file.atbProcessingStatus],
										className: 'field-status',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								onClickRow={file => {
									store.routerHistory.push(`/monitor/upload/agedtrialbalance/${file.id}`);
								}}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={ATBFILES_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default UploadHistoryList;

export const ATBFILES_PAGE_LENGTH = 10;
