import eSignEndpoints from 'ESign/Api/ESignEndpoints';
import ESignPaths from 'ESign/Router/ESignPaths';
import LoadableState from 'ESign/Types/ESignLoadableState';
import type { ESignInitialResponse } from 'ESign/Types/ESignInitialResponse';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { History } from 'history';
import {
	action, observable, runInAction,
} from 'mobx';

const defaultUserDetail: ESignInitialResponse = {
	id: '',
	name: '',
	email: '',
	phone: '',
	useSmsAuth: false,
	hasCode: false,
	isVerified: false,
	hasSigneeCeremony: false,
	hasWitnessCeremony: false,
	businessLogoFileId: '',
	contactName: '',
	contactEmail: '',
	contactPhone: '',
};

export default class ESignCommonStore {
	history: History;

	@observable loadableState: LoadableState = LoadableState.Pending;
	@observable userDetail: ESignInitialResponse = defaultUserDetail;
	@observable isVerified: boolean = false;
	@observable hasCode: boolean = false;

	constructor() {
		this.loadableState = LoadableState.Pending;
		this.history = store.routerHistory;
	}

	@action setHasCode = (value: boolean) => {
		this.hasCode = value;
	};

	@action updateIsVerified = (value: boolean) => {
		this.isVerified = value;
	};

	@action initiateESign = async (userId: string, workflowId: string) => {
		try {
			const user = await eSignEndpoints.ESignUser.get(userId, workflowId);
			runInAction(() => {
				this.userDetail = user;
				this.hasCode = user.hasCode;
				this.isVerified = user.isVerified;
				this.loadableState = LoadableState.FulFilled;
				if (user.isVerified) {
					this.history.push(ESignPaths.getSign(userId, workflowId));
				} else {
					this.history.push(ESignPaths.getAuthorised(userId, workflowId));
				}
			});
		} catch (error) {
			alertToast('No Matching User found. It may have not be created yet. Please try again.', 'error');
			runInAction(() => {
				this.userDetail = defaultUserDetail;
				this.hasCode = false;
				this.isVerified = false;
				this.loadableState = LoadableState.Rejected;
			});
			this.history.push(ESignPaths.getAuthorised(userId, workflowId));
			throw error;
		}
	};
}
