import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardComponentProps } from 'Views/Components/Wizard/Wizard';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import PpsrBusinessEntityRegistration from 'Views/Components/Ppsr/PpsrBusinessEntityRegistration';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import alertToast from 'Util/ToastifyUtils';
import { BusinessEntity } from 'Models/Entities';

const PpsrDetailsStep = observer((props: WizardComponentProps) => {
	const {
		model, changePage, currentStep,
	} = props;

	const errorsExist = () => {
		return model.businessEntitys.some((businessEntity: BusinessEntity) => {
			return Object.keys(businessEntity.errors).length > 0;
		});
	};

	const onNext = async () => {
		if (!model.businessEntitys?.some((x: BusinessEntity) => x.enabledForPPSR)) {
			alertToast('You will at least one SPG created for a business entity to use the PPSR product', 'error');
			return;
		}

		const validationPromises = [];
		for (const businessEntity of model.businessEntitys) {
			if (businessEntity.enabledForPPSR) {
				validationPromises.push(businessEntity.validatePpsrFields());
			}
		}
		await Promise.all(validationPromises);

		if (!errorsExist()) {
			changePage();
		}
	};

	const renderBusinessEntitys = () => {
		const businessEntitys = [];
		for (let i = 0; i < model.businessEntitys.length; i++) {
			businessEntitys.push(
				<div id={`businessEntitys[${i}]`} key={`businessEntitys[${i}]`} className="business-entity-wrap">
					<PpsrBusinessEntityRegistration
						businessEntity={model.businessEntitys[i]}
					/>
				</div>,
			);
		}

		return businessEntitys;
	};

	const spgTooltip = 'A Secured Party Group (SPG) is a system construct used by the PPSR to help '
		+ 'secured parties manage their registrations.  The SPG can have any combination of individual '
		+ 'or organisation secured parties.';
	return (
		<div className="step-wrap">
			<div className="step-contents registration-step ppsr-details-step align-one-third">
				<div className="alignment-box">
					<h5 className="step-header">
						Setup Access PPSR
						<Tooltip id="digital-application-tooltip" content={spgTooltip} place="top" />
					</h5>
					<p className="center-text">
						Please identify which entities require PPSR?
					</p>
					<div>
						{ renderBusinessEntitys() }
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					className="left"
					colors={Colors.Primary}
					display={Display.Outline}
					onClick={() => changePage(false)}
				>
					Back
				</Button>
				<Button
					className="right"
					colors={Colors.Primary}
					display={Display.Solid}
					disabled={errorsExist()}
					onClick={onNext}
				>
					{!!currentStep.options?.showSubmit ? 'Submit' : 'Next'}
				</Button>
			</div>
		</div>
	);
});

export default PpsrDetailsStep;
