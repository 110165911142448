import classNames from "classnames"
import ProductGauge from "./ProductGauge";
import useAsync from "Hooks/useAsync";
import { SERVER_URL } from "Constants";
import axios from "axios";
import useStore from "Hooks/useStore";
import { ApproveSummary, BusinessEntityApproveSummary, BusinessEntityPpsrSummary } from "../Intel/Dashboard/DashboardBrownfieldProducts"; 
import { RegistrationSummary } from "../Intel/Customer/PpsrRegistrationTypes";

interface CustomerSummary {
	currentValue: number;
	maxValue: number;
}

export const ProductSection: React.FunctionComponent = () => {
	const store = useStore();

	const approveResponse = useAsync<ApproveSummary>(async () => {
		const emptySummary = {
			notSubmitted: 0,
			referred: 0,
			pending: 0,
			declined: 0,
			approved: 0,
			notStarted: 0,
		} as ApproveSummary;
		if (store.canAccessApprove !== true) {
			return emptySummary;
		}
		try {
			const { data } = await axios.get(
				`${SERVER_URL}/api/entity/CustomerEntity/business-entities/approve-summary`,
			);
			const result = data as BusinessEntityApproveSummary;
			if (result.errorMessage != null || result.errors != null) {
				return emptySummary;
			}
			return result.approveSummary ?? emptySummary;
		} catch (e) {
			return emptySummary;
		}
	}, [store], { suppressSubsequentLoadingState: true });

	const approveGaugeData = {
		currentValue: approveResponse.data?.pending ?? 0,
		maxValue: (approveResponse.data?.notStarted ?? 0) 
			+ (approveResponse.data?.notSubmitted ?? 0) 
			+ (approveResponse.data?.pending ?? 0),
	}

	const ppsrResponse = useAsync<RegistrationSummary>(async () => {
		const emptySummary = {
			expireNextThreeMonths: 0,
			expireNextSixMonths: 0,
			expireNextYear: 0,
			current: 0,
		} as RegistrationSummary;
		if (store.canAccessPPSR !== true) {
			return emptySummary;
		}
		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/CustomerEntity/business-entities/ppsr-summary`);
			const result = data as BusinessEntityPpsrSummary;
			if (result.errorMessage != null || result.errors != null) {
				return emptySummary;
			}
			return result.ppsrSummary ?? emptySummary;
		} catch (e) {
			return emptySummary;
		}
	}, [store], { suppressSubsequentLoadingState: true });

	const ppsrGaugeData = {
		currentValue: ppsrResponse.data?.expireNextThreeMonths ?? 0,
		maxValue: ppsrResponse.data?.current ?? 0,
	}

	const customerResponse = useAsync<CustomerSummary>(async () => {
		const emptySummary = {
			currentValue: 0,
			maxValue: 0,
		} as CustomerSummary;

		if (store.canAccessIntel !== true) {
			return emptySummary;
		}

		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/CustomerEntity/customers-summary`);
			const result = data as CustomerSummary;
			return result ?? emptySummary;
		} catch (e) {
			return emptySummary;
		}
	}, [store], { suppressSubsequentLoadingState: true });

	const handleClick = (url: string) => {
		window.open(url, '_self');
	}

	return (
		<div className="top-container product-boxes">
			<div className={classNames('product-box-wrap approve', {
				disabled: !store.canAccessApprove,
			})}
			>
				<div 
					className="product-box light-blue" 
					onClick={() => {if(store.canAccessApprove) handleClick(`${store.appSettings.approveBaseUrl}${store.appSettings.approveLoginPath}`)}}
				>
					{store.canAccessApprove && <div className="icon-north-east icon-right"/>}
					<div className="icon-north-east icon-right"/>
					<div className="product-box-logo approve">
						<ProductGauge 
							Label="Pending approval"
							MaxValue={approveGaugeData.maxValue} 
							CurrentValue={approveGaugeData.currentValue}
							Color="#537eef"
							ItemsName="applications"
						/>
					</div>
				</div>
			</div>
			<div className={classNames('product-box-wrap', 'ppsr', {
				disabled: !store.canAccessPPSR,
			})}
			>
				<div 
					className="product-box blue" 
					onClick={() => {if(store.canAccessPPSR) handleClick(`${store.appSettings.ppsrBaseUrl}${store.appSettings.ppsrLoginPath}`)}}
				>
					{store.canAccessPPSR && <div className="icon-north-east icon-right"/>}
					<div className="product-box-logo ppsr">
						<ProductGauge 
							Label="Expiring within 90 days"
							MaxValue={ppsrGaugeData.maxValue} 
							CurrentValue={ppsrGaugeData.currentValue}
							Color="#394ec4"
							ItemsName="registrations"
						/>
					</div>
				</div>
			</div>
			<div className={classNames('product-box-wrap intel', {
				disabled: !store.canAccessIntel,
			})}
			>
				<div className="product-box purple">
					{store.canAccessIntel && <div className="icon-north-east icon-right"/>}
					<div 
						className="product-box-logo  intel" 
						onClick={() => {if(store.canAccessIntel) handleClick("/monitor/dashboard")}}
					>
						<ProductGauge 
							Label="Customers monitored"
							MaxValue={customerResponse.data?.maxValue ?? 0} 
							CurrentValue={customerResponse.data?.currentValue ?? 0}
							Color="#664bed"
							ItemsName="customers"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}