import React from 'react';

export type IInputGroupProps = {
	label?: string;
	errors?: string[];
} & React.HTMLAttributes<HTMLDivElement>;

const ComboInput: React.FunctionComponent<IInputGroupProps> = ({ label, errors, children }) => {
	const classNames = () => {
		const classes = ['combo-input--children'];
		if (errors === undefined) {
			return classes.join(' ');
		}
		const hasError = errors.filter(x => x !== undefined && x !== null && x !== '').length > 0;

		if (errors && hasError) {
			classes.push('combo-input--error');
		}
		return classes.join(' ');
	};

	const renderCombinedErrors = () => {
		if (errors === undefined) {
			return null;
		}

		return errors.map(error => {
			const errorExists = error !== undefined && error !== null && error !== '';
			if (errorExists) {
				return <p key={error} className="combo-input--errors">{error}</p>;
			}

			return null;
		});
	};

	return (
		<div className="combo-input">
			{label && <label className="combo-input--label">{label}</label>}
			<div className={classNames()}>
				{children}
			</div>
			{renderCombinedErrors()}
		</div>
	);
};

export default ComboInput;
