/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IIconProps } from '../Helpers/Common';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import NavigationLinks from './NavigationLinks';
// % protected region % [Add extra page imports here] off begin
// % protected region % [Add extra page imports here] end

export enum Orientation {
	VERTICAL,
	HORIZONTAL,
	// % protected region % [Add orientations here] off begin
	// % protected region % [Add orientations here] end
}

export interface ILink extends IIconProps {
	shouldDisplay?: () => boolean;
	path: string;
	label: React.ReactNode;
	onClick?: (event?: any) => void;
	subLinks?: ILink[];
	subLinksFilter?: (link: ILink) => boolean;
	isDisabled?: boolean;
	useATag?: boolean;
	customComponent?: React.ReactNode;
	className?: string;
	// % protected region % [Add extra ILink fields here] off begin
	// % protected region % [Add extra ILink fields here] end
}

export interface INavigationProps<T extends ILink> extends RouteComponentProps {
	className?: string;
	orientation: Orientation;
	linkGroups: Array<Array<T>>;
	filter?: (link: T) => boolean;
	alwaysExpanded?: boolean;
	// % protected region % [Add extra INavigationProps fields here] off begin
	// % protected region % [Add extra INavigationProps fields here] end
}

// % protected region % [Add extra interfaces here] off begin
// % protected region % [Add extra interfaces here] end

@observer
// % protected region % [Customise class implementation here] off begin
class Navigation<T extends ILink> extends React.Component<INavigationProps<T>> {
// % protected region % [Customise class implementation here] end
	// % protected region % [Customise navigation fields here] on begin
	@computed
	private get alwaysExpanded() {
		// Access doesn't have a collapsed navbar
		return true;
	}

	@observable
	private navCollapsed: boolean = false;
	// % protected region % [Customise navigation fields here] end

	public render() {
		// % protected region % [Customise expand button here] on begin
		const { className, linkGroups, ...routerProps } = this.props;

		// We remove the expand button, because we don't want it in the design.
		// But we keep the expanded class to make use of existing styling.
		const navClassName = classNames(className, 'nav', 'nav--expanded', this.getOrientationClassName());

		return (
			<nav className={navClassName}>
				{linkGroups.map((links, index) => {
					const key = `key-${index}`;
					return (
						<NavigationLinks
							key={key}
							{...routerProps}
							links={links}
						/>
					);
				})}
			</nav>
		);
		// % protected region % [Customise expand button here] end
	}

	private getOrientationClassName = () => {
		// % protected region % [Customise getOrientationClassName here] off begin
		const { orientation } = this.props;
		switch (orientation) {
			case Orientation.HORIZONTAL:
				return 'nav--horizontal';
			case Orientation.VERTICAL:
				return 'nav--vertical';
			default:
				break;
		}
		return '';
		// % protected region % [Customise getOrientationClassName here] end
	};

	@action
	private onClickNavCollapse = () => {
		// % protected region % [Customise onClickNavCollapse here] off begin
		this.navCollapsed = !this.navCollapsed;
		// % protected region % [Customise onClickNavCollapse here] end
	};

	// % protected region % [Add extra methods here] off begin
	// % protected region % [Add extra methods here] end
}

// % protected region % [Customise export here] off begin
export default Navigation;
// % protected region % [Customise export here] end
