import eSignEndpoints from 'ESign/Api/ESignEndpoints';
import {
	action, observable, runInAction,
} from 'mobx';
import alertToast from 'Util/ToastifyUtils';
import { eSignStore } from './ESignStore';
import ESignPaths from 'ESign/Router/ESignPaths';

export default class ESignAuthoriseStore {
	@observable loading: boolean = false;
	@observable token: string | null = null;
	@observable optValue: string = '';
	@observable isResendDisabled: boolean = true;

	constructor() {
		this.loading = false;
		this.token = null;
		this.optValue = '';
		this.isResendDisabled = true;
	}

	@action setIsResendDisabled = (value: boolean) => {
		this.isResendDisabled = value;
	};

	@action setOtpValue = (value: string, workflowId: string) => {
		this.optValue = value;

		if (this.optValue.trim().length === 6) {
			this.verifyAccessCode(workflowId);
		}
	};

	@action sendAccessCode = async (userId: string, workflowId: string) => {
		this.loading = true;
		try {
			const successResponse = await eSignEndpoints.ESignVerification.sendOTP(userId, workflowId);
			alertToast(successResponse, 'success');

			runInAction(() => {
				eSignStore.commonStore.setHasCode(true);
				this.loading = false;
			});
		} catch (error) {
			runInAction(() => {
				eSignStore.commonStore.setHasCode(false);
				this.loading = false;
			});
			throw error;
		}
	};

	@action verifyAccessCode = async (workflowId: string) => {
		this.loading = true;
		try {
			const userId = eSignStore.commonStore.userDetail.id;
			await eSignEndpoints.ESignVerification.verifyOTP(userId, this.optValue);

			alertToast('Access code verified successfully', 'success');
			runInAction(() => {
				eSignStore.commonStore.updateIsVerified(true);
				this.loading = false;
				this.setOtpValue('', workflowId);
			});
			eSignStore.commonStore.history.push(ESignPaths.getSign(userId, workflowId));
		} catch (error) {
			alertToast('Access code verification failed', 'error');
			runInAction(() => {
				eSignStore.commonStore.updateIsVerified(false);
				this.loading = false;
				this.setOtpValue('', workflowId);
			});
		}
	};
}
