import { state } from "Models/Enums";
import { PropertySearchByTitleOptionForRegion } from "./PropertySearchByRegionType";

export type SearchByDetailInfoSubItem = {
	name: string;
	type: 'text' | 'select';
	selectOptions?: string[];
	placeholder: string;
	label: string;
	isRequired: boolean;
	requiredText?: string;
};

interface OptionConfig {
	label: string;
	isRequired: boolean;
	placeholder?: string;
	subItems?: SearchByDetailInfoSubItem[] | ((selectedState: state) => SearchByDetailInfoSubItem[] | undefined);
}

const optionConfigs: Record<PropertySearchByTitleOptionForRegion, OptionConfig> = {
	'title-reference': {
		label: 'Reference Number',
		isRequired: true,
		placeholder: 'Enter title reference number',
	},
	'lot-plan': {
		label: '',
		isRequired: true,
		subItems: getLotPlanSubItems,
	},
	'individual-owner': {
		label: '',
		isRequired: true,
		subItems: getIndividualNameSubItems,
	},
	'organisation-owner': {
		label: 'Organisation Name',
		isRequired: true,
		placeholder: 'Enter organisation name',
	},
	'address': {
		label: '',
		isRequired: true,
		subItems: getAddressSubItems,
	},
	'previous-title-reference': {
		label: 'Previous Title Reference Number',
		isRequired: true,
		placeholder: 'Enter title reference number',
	},
	'volume-folio': {
		label: '',
		isRequired: true,
		subItems: getVolumeFolioSubItems,
	},
	'parcel': {
		label: '',
		isRequired: true,
		subItems: getParcelSubItems,
	},
	'lot-town': {
		label: '',
		isRequired: true,
		subItems: getLotTownSubItems,
	},
	'plan-parcel': {
		label: '',
		isRequired: true,
		subItems: getPlanParcelSubItems,
	},
};

export function getDetailInfoRequirementAsPerSelectedSearchByOption(selectedOption: PropertySearchByTitleOptionForRegion, selectedState: state): OptionConfig {
	const config = optionConfigs[selectedOption];
    
	if (!config) {
		throw new Error(`No configuration found for option: ${selectedOption}`);
	}
	if (typeof config.subItems === 'function') {
		return { ...config, subItems: config.subItems(selectedState) };
	}
	return config;
}

export function retrieveSubItems(subItems: SearchByDetailInfoSubItem[] | ((selectedState: state) => SearchByDetailInfoSubItem[] | undefined) | undefined, selectedState: state): SearchByDetailInfoSubItem[] | undefined {
	if (!subItems) {
		return undefined;
	}
	if (typeof subItems === 'function') {
		return subItems(selectedState);
	}
	return subItems;
}

function getIndividualNameSubItems(): SearchByDetailInfoSubItem[] {
	return [
		{ name: 'firstName', type: 'text', placeholder: 'Jane', label: 'First Name', isRequired: true, requiredText: 'First name is required' },
		{ name: 'lastName', type: 'text', placeholder: 'Smith', label: 'Last Name', isRequired: true, requiredText: 'Last name is required' },
	];
}

function getLotPlanSubItems(): SearchByDetailInfoSubItem[] {
	return [
		{ name: 'lot', type: 'text', placeholder: '414', label: 'Lot number', isRequired: true, requiredText: 'Lot is required' },
		{ name: 'plan', type: 'text', placeholder: 'SL8740', label: 'Plan number', isRequired: true, requiredText: 'Plan is required' },
	];
}

function getAddressSubItems(selectedState: state): SearchByDetailInfoSubItem[] {
	switch (selectedState) {
		case 'QLD':
		case 'WA':
		case 'NSW':
			return [
				{ name: 'streetNumber', type: 'text', placeholder: '15', label: 'Street Number', isRequired: true, requiredText: 'Street number is required' },
				{ name: 'streetName', type: 'text', placeholder: 'Main Street', label: 'Street Name', isRequired: true, requiredText: 'Street name is required' },
				{ name: 'suburb', type: 'text', placeholder: 'Springfield', label: 'Suburb', isRequired: true, requiredText: 'Suburb is required' },
			];
		case 'ACT':
		case 'NT':
		case 'TAS':
			return [
				{ name: 'unitNumber', type: 'text', placeholder: '5', label: 'Unit Number', isRequired: false },
				{ name: 'streetNumber', type: 'text', placeholder: '15', label: 'Street Number', isRequired: true, requiredText: 'Street number is required' },
				{ name: 'streetName', type: 'text', placeholder: 'Main Street', label: 'Street Name', isRequired: true, requiredText: 'Street name is required' },
				{ name: 'suburb', type: 'text', placeholder: 'Springfield', label: 'Suburb/Locality', isRequired: true, requiredText: 'Suburb is required' },
			];
		case 'SA':
			return [
				{ name: 'level', type: 'text', placeholder: '3', label: 'Level', isRequired: true, requiredText: 'Level is required' },
				{ name: 'lotNumber', type: 'text', placeholder: '2', label: 'Lot', isRequired: true, requiredText: 'Lot is required' },
				{ name: 'unitNumber', type: 'text', placeholder: '5', label: 'Unit Number', isRequired: false },
				{ name: 'streetNumber', type: 'text', placeholder: '15', label: 'Street Number', isRequired: true, requiredText: 'Street number is required' },
				{ name: 'streetName', type: 'text', placeholder: 'Main Street', label: 'Street Name', isRequired: true, requiredText: 'Street name is required' },
				{ name: 'suburb', type: 'text', placeholder: 'Springfield', label: 'Suburb/Locality', isRequired: true, requiredText: 'Suburb is required' },
			];
		default:
			throw new Error(`Unsupported state: ${selectedState}`);
	}
}

function getVolumeFolioSubItems(selectedState: state): SearchByDetailInfoSubItem[] | undefined {
	if (selectedState === 'SA') {
		return [
			{ name: 'register', type: 'select', selectOptions: ['CT', 'CL', 'CR'], placeholder: 'Select register', label: 'Register', isRequired: true, requiredText: 'Register is required' },
			{ name: 'volume', type: 'text', placeholder: '6277', label: 'Volume', isRequired: true, requiredText: 'Volume is required' },
			{ name: 'folio', type: 'text', placeholder: '537', label: 'Folio', isRequired: true, requiredText: 'Folio is required' },
		];
	}
	else {       
		return [
			{ name: 'volume', type: 'text', placeholder: '2400', label: 'Volume', isRequired: true, requiredText: 'Volume is required' },
			{ name: 'folio', type: 'text', placeholder: '39', label: 'Folio', isRequired: true, requiredText: 'Folio is required' },
		]
	}
}

function getParcelSubItems(): SearchByDetailInfoSubItem[] {
	return [
		{ name: 'district', type: 'text', placeholder: 'Parkes', label: 'District', isRequired: true, requiredText: 'District is required' },
		{ name: 'section', type: 'text', placeholder: '39', label: 'Section', isRequired: true, requiredText: 'Section is required' },
		{ name: 'block', type: 'text', placeholder: '1', label: 'Block', isRequired: true, requiredText: 'Block is required' },
		{ name: 'unit', type: 'text', placeholder: '3', label: 'Unit', isRequired: false },
	];
}

function getLotTownSubItems(): SearchByDetailInfoSubItem[] {
	return [
		{ name: 'lot', type: 'text', placeholder: '11231', label: 'Lot', isRequired: true, requiredText: 'Lot is required' },
		{ name: 'town', type: 'text', placeholder: 'Darwin', label: 'Town', isRequired: true, requiredText: 'Town is required' },
	];
}

function getPlanParcelSubItems(): SearchByDetailInfoSubItem[] {
	return [
		{ name: 'plan', type: 'text', placeholder: 'D128908', label: 'Plan number', isRequired: true, requiredText: 'Plan number is required' },
		{ name: 'parcel', type: 'text', placeholder: 'AL609', label: 'Parcel number', isRequired: true, requiredText: 'Parcel is required' },
	];
}