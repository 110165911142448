import * as React from 'react';
import { ApplicationEntity, BusinessEntity } from '../../../Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import { store } from '../../../Models/Store';
import { businessEntitiesPermissionScopeOrder } from '../../../Models/Enums';
import { Button, Colors, Display } from '../Button/Button';
import If from '../If/If';
import { action, runInAction } from 'mobx';
import alertToast from '../../../Util/ToastifyUtils';
import ApplicationListItem from './ApplicationListItem';
import ApplicationDetailsModal from './ApplicationDetailsModal';
import moment from 'moment';

export interface BusinessEntityApproveDetailsProps {
    businessEntity: BusinessEntity,
}

const BusinessEntityApproveDetails = observer((props: BusinessEntityApproveDetailsProps) => {
	const { businessEntity } = props;

	const [showAddModal, setShowAddModal] = React.useState(false);
	const newApplicationEntity = useLocalStore(() => new ApplicationEntity());
	const accessibleBusinessEntities = businessEntity.organisation?.businessEntitys.map(entity => entity.id) ?? [''];

	// function to determine if an application entity is readonly. readonly if the user cannot manage business entities or
	// they can manage business entities within their organisation and they can access the business entity
	const canEdit = (id: string) => {
		const managePermission = store.userPermissions.commonManageBusinessEntities ?? 'NONE';
		// for users below all and above none, I can edit business entities I belong to
		const canEditMyBusinessEntity = businessEntitiesPermissionScopeOrder[managePermission]
			> businessEntitiesPermissionScopeOrder.NONE && accessibleBusinessEntities.includes(id);
		return managePermission === 'ALL' ? true : canEditMyBusinessEntity;
	};

	const renderCantAccessApprove = () => {
		if (store.userType === 'ORGANISATION_MANAGER' || store.userType === 'SUPER_USER') {
			return (
				<div className="cant-access-approve">
					<div className="content">
						<h3>Looking for Access Approve?</h3>
						<p>
							Approve allows you to receive digital trade applications with verified data and the ability
							to approve new customers with confidence in seconds, not days.
						</p>
						<p>
							To setup Approve, please click below.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								if (businessEntity.organisationId === store.getUser?.organisation?.id) {
									if (businessEntity.organisation.approveEnabled) {
										store.routerHistory
											.push(
												`/hub/organisation/business-entities/${businessEntity.id}`,
												{ showProductModal: 'add-approve' },
											);
									} else {
										store.routerHistory
											.push('/hub/organisation/products');
									}
								} else if (businessEntity.organisation.approveEnabled) {
									store.routerHistory.push(
										`/hub/clients/${businessEntity.organisationId}/`
                                        + `business-entities/${businessEntity.id}`,
										{ showProductModal: 'add-approve' },
									);
								} else {
									store.routerHistory.push(`/hub/clients/${businessEntity.organisationId}/products`);
								}
							}}
							className="approve-setup"
						>
							Approve product setup
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="cant-access-approve">
				<div className="content">
					<h3>Looking for Access Approve?</h3>
					<p>
						Approve allows you to receive digital trade applications with verified data and the ability to
						approve new customers with confidence in seconds, not days.
					</p>
					<br />
					<p>
						To setup Approve, please contact your Organisation Manager.
					</p>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.approveMarketingUrl, '_blank');
						}}
						className="approve-learn-more"
					>
						Learn more about Access Approve
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="business-entity-approve-details">
			{ store.canAccessApprove && businessEntity.organisation.approveEnabled && businessEntity.enabledForApprove
				? (
					<>
						<div className="title-container">
							<h3>Approve details</h3>
							<Button
								className="add-application"
								colors={Colors.Primary}
								display={Display.Outline}
								icon={{ icon: 'plus-2', iconPos: 'icon-left' }}
								onClick={() => setShowAddModal(true)}
								disabled={!canEdit(businessEntity.id)}
							>
								Add new application
							</Button>
						</div>
						<div className="entity-list-wrap">
							{businessEntity.applicationss.map((applicationEntity, index) => (
								<ApplicationListItem
									applicationEntity={applicationEntity}
									key={applicationEntity.id}
									readonly={!canEdit(businessEntity.id)}
								/>
							))}
						</div>
						<If condition={showAddModal}>
							<ApplicationDetailsModal
								applicationEntity={newApplicationEntity}
								onClose={() => setShowAddModal(false)}
								onSubmit={action(async () => {
									const applicationToAdd = new ApplicationEntity(newApplicationEntity);
									applicationToAdd.businessEntityId = businessEntity.id;
									applicationToAdd.isActive = true;
									applicationToAdd.applicationDate = moment().utc().toDate();
									try {
										await applicationToAdd.save({}, {
											contentType: 'multipart/form-data',
										});
										runInAction(() => {
											businessEntity.applicationss = [...businessEntity.applicationss,
												applicationToAdd];
											alertToast('Application entity successfully created', 'success');
										});
									} catch (exception) {
										alertToast('Application entity could not be created, please refresh '
                                        + 'and try again', 'error');
									}

									runInAction(() => {
										newApplicationEntity.applicationName = '';
										newApplicationEntity.termsAndConditionsId = '';
										// @ts-ignore
										newApplicationEntity.termsAndConditions = null;
										newApplicationEntity.privacyPolicyId = '';
										// @ts-ignore
										newApplicationEntity.privacyPolicy = null;
										newApplicationEntity.logoId = '';
										// @ts-ignore
										newApplicationEntity.logo = null;
										newApplicationEntity.contactName = '';
										newApplicationEntity.email = '';
										newApplicationEntity.phone = '';
										setShowAddModal(false);
									});
								})}
							/>
						</If>
					</>
				) : renderCantAccessApprove()}
		</div>
	);
});

export default BusinessEntityApproveDetails;
