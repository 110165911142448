import * as React from 'react';
import { Adjudication } from 'Models/Interfaces/AlaresReport';
import AdverseAlertsTable from './AdverseAlertsTable';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import AdverseAlertsAtoUpdateDetailsModal from './AdverseAlertsAtoUpdateDetailsModal';
import { UpdateTableType } from './AdverseAlertsInsolvencies';

const AdverseAlertsAdjudications = (props: {
	adjudications: Adjudication[],
}) => {
	const { adjudications } = props;
	const [highlightedAdjudication, setHighlightedAdjudication] = useState<UpdateTableType | undefined>(undefined);

	const adjudicationsList = useMemo<UpdateTableType[]>(() => {
		const list = adjudications.map(x => {
			return {
				uuid: x.uuid,
				notificationTimeString: x.notification_time,
				notificationTime: moment(x.notification_time).toDate(),
				type: x.type,
				courtName: x.court_name,
				caseType: x.case_type,
				caseNumber: x.case_number,
				caseName: x.case_name,
				dataType: 'adjudication',
				adjudication: x,
				insolvency: undefined,
				parties: x.parties,
			} as UpdateTableType;
		});

		list.sort((a, b) => b.notificationTime.getTime() - a.notificationTime.getTime());

		return list;
	}, [adjudications]);

	const closeAdjudicationModal = useCallback(() => {
		setHighlightedAdjudication(undefined);
	}, []);

	return (
		<>
			<AdverseAlertsTable
				tableName="Adjudications"
				className="adjudications"
				collection={adjudicationsList ?? []}
				columns={[
					{
						displayName: 'Date',
						columnName: 'date',
						value: update => moment(update.notificationTime).format('Do MMMM YYYY'),
						sortable: false,
						className: 'field-date',
					},
					{
						displayName: 'Type',
						columnName: 'type',
						value: update => update.type,
						sortable: false,
						className: 'field-update',
					},
					{
						displayName: 'Court name',
						columnName: 'courtName',
						value: update => update.courtName,
						sortable: false,
						className: 'field-court-name',
					},
					{
						displayName: 'Case type',
						columnName: 'caseType',
						value: update => update.caseType,
						sortable: false,
						className: 'field-case-type',
					},
					{
						displayName: 'Case number',
						columnName: 'number',
						value: update => update.caseNumber,
						sortable: false,
						className: 'field-case-number',
					},
					{
						displayName: 'Case name',
						columnName: 'name',
						value: update => update.caseName,
						sortable: false,
						className: 'field-case-name',
					},
					{
						displayName: '',
						columnName: 'details',
						value: update => (
							<span
								className="icon icon-right icon-open-in-new"
								{...HandleEvents(() => setHighlightedAdjudication(update))}
							>
								DETAILS
							</span>
						),
						sortable: false,
						className: 'field-details',
					},
				]}
				sortColumn="date"
				sortDescending
				idColumn="uuid"
			/>
			<If condition={!!highlightedAdjudication}>
				<AdverseAlertsAtoUpdateDetailsModal
					update={highlightedAdjudication ?? {} as UpdateTableType}
					onClose={closeAdjudicationModal}
				/>
			</If>
		</>
	);
};

export default AdverseAlertsAdjudications;
