import React from 'react';
import ProcessingErrorItem from './ProcessingErrorItem';
import { ProcessingError, ProcessingErrorRow } from './AgedTrialBalanceDetail';
import If from '../If/If';

interface ProcessingErrorListProps {
	atbFileEntityId: string,
	businessEntityId: string,
	errorRows: ProcessingErrorRow[];
	expandedErrorRows: string[];
	onToggleExpand: (id: string) => void;
	onResolveError: (
		atbRecordEntityId: string,
		processingError: ProcessingError,
		value: string|undefined
	) => Promise<void>;
	deleteAtbRow: (atbRecordEntityId: string) => Promise<void>;
}

const ProcessingErrorList = (props: ProcessingErrorListProps) => {
	const {
		atbFileEntityId,
		businessEntityId,
		errorRows,
		expandedErrorRows,
		onToggleExpand,
		onResolveError,
		deleteAtbRow,
	} = props;

	return (
		<>
			{!errorRows.length && (
				<div className="no-errors">
					<b>You can now submit this file.</b><br />
					No errors require manual resolution.
				</div>
			)}
			<If condition={!!errorRows.length}>
				{errorRows.map(errorRow => (
					<ProcessingErrorItem
						key={errorRow.ErrorId}
						id={errorRow.ErrorId}
						row={errorRow.Row}
						customer={errorRow.Customer}
						column={errorRow.Error.Column}
						field={errorRow.Error.Field}
						detail={errorRow.Error.Detail}
						errorType={errorRow.Error.Type}
						inputType={errorRow.Error.InputType}
						originalValue={errorRow.Error.Value}
						required={errorRow.Error.Required}
						expanded={expandedErrorRows.includes(errorRow.ErrorId)}
						atbFileId={atbFileEntityId}
						atbRecordEntityId={errorRow.AtbRecordEntityId}
						businessEntityId={businessEntityId}
						onToggleExpand={onToggleExpand}
						onResolveError={onResolveError}
						deleteAtbRow={deleteAtbRow}
					/>
				))}
			</If>
		</>
	);
};

export default React.memo(ProcessingErrorList);
