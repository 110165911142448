import { Button, Colors, Display } from 'Views/Components/Button/Button';
import React, { useMemo, useState } from 'react';

import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import Modal from 'Views/Components/Modal/Modal';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import { observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	confirmReject: (value: string) => Promise<void>;
}

const ESignRejectModal: React.FunctionComponent<Props> = observer(({ isOpen, onClose, confirmReject }) => {
	const [comment, setComment] = useState<{value: string}>({ value: ' ' });

	const validateTextArea = useMemo(() => {
		return comment.value.length > 0;
	}, [comment.value]);

	return (
		<Modal
			isOpen={isOpen}
			label="ESign Document Rejection Modal"
			onRequestClose={onClose}
		>
			<>
				<Header as="h2" color="red">
					Add Comment
					<Tooltip
						id="reject"
						content="Add a reason why you are rejecting this document"
						place="right"
					/>
				</Header>
				<TextArea
					model={comment}
					modelProperty="value"
					placeholder="Please provide a reason for rejecting this document"
					onAfterChange={e => setComment({ value: e.target.value })}
					label="Please provide a reason for rejecting this document"
					errors={validateTextArea ? [] : ['Please provide a reason for rejecting this document']}
				/>
				<div className="modal__actions">
					<Button
						colors={Colors.Error}
						display={Display.Solid}
						onClick={onClose}
					>
						Cancel
					</Button>
					<ButtonAsyncState
						colors={Colors.Primary}
						display={Display.Solid}
						onPress={async () => {
							await confirmReject(comment.value);
							onClose();
						}}
						readonly={!validateTextArea || comment.value.trim().length === 0}
					>
						Reject
					</ButtonAsyncState>
				</div>
			</>
		</Modal>
	);
});

export default ESignRejectModal;
