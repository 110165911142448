import * as React from 'react';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';
import { store } from 'Models/Store';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import UserEntity from 'Models/Entities/UserEntity';
import { gql } from '@apollo/client';
import {
	BusinessEntity,
	BusinessEntityUser,
	IBusinessEntityAttributes,
} from 'Models/Entities';
import MultiComboboxSetter from '../Combobox/MultiComboboxSetter';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { LocaleCompareAttrAsc } from 'Util/StringUtils';

export interface BusinessEntitySelectorProps {
	className?: string;
	user: UserEntity, // should be observable
	errors?: string | string[];
	isDisabled?: boolean,
	isRequired?: boolean,
	onAfterChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
}

const BusinessEntitySelector = observer((props: BusinessEntitySelectorProps) => {
	const {
		user, isDisabled, isRequired, onAfterChange, className, errors,
	} = props;

	const getBusinessEntityOptions = async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		// If we're a super user, we fetch a list of all organisations
		if (store.userType === 'SUPER_USER') {
			try {
				const businessEntitysResult = await store.apolloClient
					.query({
						query: gql`
							query fetchBusinessEntitys($searchTerm: String) {
								businessEntitys(
									where: [{
										path: "name",
										comparison: like,
										value: [$searchTerm],
										case: CURRENT_CULTURE_IGNORE_CASE
									},
									{
										path: "organisationId"
										comparison: equal,
										value: ["${user.organisationId}"],
									}],
									orderBy: [{path: "name", descending: false}],
								) {
									id
									name
								}
							}
						`,
						variables: {
							searchTerm: !!searchTerm ? `%${searchTerm}%` : '%',
						},
						fetchPolicy: 'no-cache',
					});

				return businessEntitysResult.data.businessEntitys
					.map((x: Partial<IBusinessEntityAttributes> | undefined) => {
						const businessEntity = new BusinessEntity(x);
						return {
							display: businessEntity.name,
							value: businessEntity.id,
						};
					});
			} catch (e) {
				// If we can't get business entities, we fall back to the code below.
			}
		}

		// Otherwise we return a list with just the current user's organisation
		if (!store.getUser?.organisation) {
			return []; // Current user doesn't actually have an organisation
		}
		return store.getUser?.organisation.businessEntitys
			.filter(BusinessEntity.IsLinkedToUser)
			.sort(LocaleCompareAttrAsc('name'))
			.map(businessEntity => {
				return {
					display: businessEntity.name,
					value: businessEntity.id,
				};
			});
	};

	return (
		<MultiComboboxSetter
			className={classNames('businessentity-selector', className)}
			label="Business Entities"
			placeholder="Business Entities"
			value={user.businessEntitys.map(x => x.businessEntityId)}
			setValue={values => {
				runInAction(() => {
					user.businessEntitys = values.map(x => new BusinessEntityUser({
						businessEntityId: x,
					}));
				});
			}}
			getOptionValue={(value?: string) => value}
			isDisabled={isDisabled}
			isRequired={isRequired}
			initialOptions={getBusinessEntityOptions}
			options={AwesomeDebouncePromise(getBusinessEntityOptions, 250)}
			searchable
			onAfterSet={onAfterChange}
			errors={errors}
			key={user.organisationId}
		/>
	);
});

export default BusinessEntitySelector;
