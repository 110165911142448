import * as React from 'react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { store } from 'Models/Store';
import { isEmail } from 'Validators/Functions/Email';
import { isPhone } from 'Validators/Functions/Custom';
import { Errors } from 'Util/CustomTypes';
import { UserTypeComboBoxOptions, UserTypeOrder } from 'Models/Enums';
import { observer } from 'mobx-react';
import OrganisationSelector from 'Views/Components/Account/OrganisationSelector';
import { UserEntity } from 'Models/Entities';
import If from '../If/If';
import BusinessEntitySelector from './BusinessEntitySelector';
import { runInAction } from 'mobx';

export interface AccountTileProps {
	originalUser: UserEntity,
	setName?: (firstName: string, lastName: string) => void,
}

// Holds the validate function for each input
export const AccountFieldValidationFunctions = {
	organisation: ((userToValidate: UserEntity) => {
		if (!userToValidate.organisation) {
			return 'Organisation cannot be empty';
		}
		return null;
	}),
	businessEntity: ((userToValidate: UserEntity) => {
		if (userToValidate.businessEntitys.length === 0) {
			return 'Business Entities cannot be empty';
		}
		return null;
	}),
	firstName: ((userToValidate: UserEntity) => {
		if (!userToValidate.firstName) {
			return 'First Name cannot be empty';
		}
		return null;
	}),
	lastName: ((userToValidate: UserEntity) => {
		if (!userToValidate.lastName) {
			return 'Last Name cannot be empty';
		}
		return null;
	}),
	phone: ((userToValidate: UserEntity) => {
		if (!isPhone(userToValidate.phone)) {
			return 'Phone Number must be valid';
		}
		return null;
	}),
	email: ((userToValidate: UserEntity) => {
		if (!userToValidate.email) {
			return 'Email Address cannot be empty';
		}
		if (!isEmail(userToValidate.email)) {
			return 'Email Address must be valid';
		}
		return null;
	}),
	userType: ((userToValidate: UserEntity) => {
		if (UserTypeOrder[userToValidate.userType] > UserTypeOrder[store.userType]) {
			return 'You cannot assign a user a higher user type than your own';
		}
		if (!userToValidate.userType) {
			return 'User type cannot be empty';
		}
		return null;
	}),
};
// handler for when a product access is changed
// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface AccountFieldsProps {
	user: UserEntity,
	errors: Errors,
	setErrors: (errors: Errors) => void,
	firstUser?: boolean,
	isNewUser?: boolean,
	readonly: boolean,
	onAfterChange?: (validateSuccessful: boolean) => void,
}

const AccountFields = observer((props: AccountFieldsProps) => {
	/* eslint-disable-next-line max-len */
	const {
		user,
		errors,
		setErrors,
		onAfterChange,
		isNewUser,
		readonly,
		firstUser,
	} = props;
	const isMyAccount = store.userId === user.id;

	/**
	 * Method to validate an input field
	 * @param field The name of the field to be validated
	 */
	const validateField = (field: string) => {
		const fieldError = AccountFieldValidationFunctions[field](user);
		if (!fieldError) {
			delete errors[field];
		} else {
			errors[field] = fieldError;
		}
		setErrors({ ...errors }); // Clone the object so that React recognises the change
	};

	/**
	 * Handle for the change event for the inputs. This method will attempt to validate a given field
	 * iff there are existing errors. So that the user can immediately see when their input stops the error
	 * @param field The name of the field to be validated
	 * @param alwaysValidate If set to false, will only run the validation function if the given field already has an error
	 * We use this to prevent showing a new error before we're ready. (Like when a user has only just started typing into a field)
	 */

	const disableApprovePermission = !user.organisation?.approveEnabled;
	const disablePPSRPermission = !user.organisation?.ppsrEnabled;
	const disableIntelPermission = !user.organisation?.intelEnabled;

	const onChange = (field: string, alwaysValidate: boolean = false) => {
		if (alwaysValidate || !!errors[field]) {
			validateField(field);
		}

		if (!!onAfterChange) {
			const validateSuccessful = Object.keys(errors).length <= 0;
			onAfterChange(validateSuccessful);
		}
	};
	return (
		<div className="account-fields">
			{!firstUser ? (
				<>
					<If condition={user.userType !== 'SUPER_USER'}>
						<OrganisationSelector
							user={user}
							isDisabled={readonly || !isNewUser}
							onAfterChange={() => {
								runInAction(() => {
									user.organisationId = user.organisation.id;
									user.canAccessIntel = false;
									user.canAccessPPSR = false;
									user.canAccessApprove = false;
								});
								onChange('organisation', true);
							}}
							errors={errors.organisation}
						/>
					</If>
					<BusinessEntitySelector
						user={user}
						isDisabled={readonly || user.organisationId === '' || isMyAccount}
						onAfterChange={() => onChange('businessEntity', true)}
						errors={errors.businessEntity}
					/>
				</>
			) : null}

			<div className="name-container">
				<TextField
					className="first-name"
					model={user}
					modelProperty="firstName"
					label="First name"
					isRequired={firstUser}
					placeholder="Jane"
					onChangeAndBlur={() => onChange('firstName', true)}
					onAfterChange={() => onChange('firstName')}
					errors={errors.firstName}
					isDisabled={readonly}
				/>

				<TextField
					className="last-name"
					model={user}
					modelProperty="lastName"
					label="Last name"
					isRequired={firstUser}
					placeholder="Smith"
					onChangeAndBlur={() => onChange('lastName', true)}
					onAfterChange={() => onChange('lastName')}
					errors={errors.lastName}
					isDisabled={readonly}
				/>
			</div>

			{!firstUser ? (
				<Combobox
					className="user-type"
					model={user}
					modelProperty="userType"
					label="User type"
					placeholder="User type"
					onAfterChange={() => onChange('userType', true)}
					options={UserTypeComboBoxOptions()}
					isDisabled={readonly || !isNewUser}
					searchable={false}
					errors={errors.userType}
				/>
			) : null}
			<TextField
				className="email"
				model={user}
				modelProperty="email"
				label="Work email"
				isRequired={firstUser}
				placeholder="jane.smith@company.com"
				onChangeAndBlur={() => onChange('email', true)}
				onAfterChange={() => onChange('email')}
				isDisabled={readonly || !isNewUser}
				errors={errors.email}
			/>

			<TextField
				className="phone"
				model={user}
				modelProperty="phone"
				label="Phone number"
				placeholder="+61 400 123 456"
				onChangeAndBlur={() => onChange('phone', true)}
				onAfterChange={() => onChange('phone')}
				errors={errors.phone}
				isDisabled={readonly}
			/>
			{!firstUser ? (
				<div>
					<h5 className="permission-new">Enable User Permissions</h5>
					{/* eslint-disable-next-line max-len */}
					<If condition={(store.userType === 'SUPER_USER' || store.userType === 'ADMIN' || store.userType === 'ORGANISATION_MANAGER')}>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="enableEmailNotifications"
							label="Email Notifications"
							isDisabled={false}
							onChecked={(event, checked) => {
								user.enableEmailNotifications = checked;
								onChange('enableEmailNotifications');
							}}
							errors={errors.enableEmailNotifications}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessApprove"
							label="Access Approve"
							isDisabled={disableApprovePermission}
							onChecked={(event, checked) => {
								user.canAccessApprove = checked;
								onChange('canAccessApprove');
							}}
							errors={errors.canAccessApprove}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessPPSR"
							label="Access PPSR"
							isDisabled={disablePPSRPermission}
							onChecked={(event, checked) => {
								user.canAccessPPSR = checked;
								onChange('canAccessPPSR');
							}}
							errors={errors.canAccessPPSR}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessIntel"
							label="Access Monitor"
							isDisabled={disableIntelPermission}
							onChecked={(event, checked) => {
								user.canAccessIntel = checked;
								onChange('canAccessIntel');
							}}
							errors={errors.canAccessIntel}
						/>
					</If>
					<If condition={store.userType !== 'SUPER_USER' && store.userType !== 'ADMIN' && store.userType !== 'ORGANISATION_MANAGER'}>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="enableEmailNotifications"
							label="Email Notifications"
							isDisabled={readonly || !isNewUser}
							onChecked={(event, checked) => {
								user.enableEmailNotifications = checked;
								onChange('enableEmailNotifications');
							}}
							errors={errors.enableEmailNotifications}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessApprove"
							label="Access Approve"
							isDisabled={readonly || !isNewUser || disableApprovePermission}
							onChecked={(event, checked) => {
								user.canAccessApprove = checked;
								onChange('canAccessApprove');
							}}
							errors={errors.canAccessApprove}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessPPSR"
							label="Access PPSR"
							isDisabled={readonly || !isNewUser || disablePPSRPermission}
							onChecked={(event, checked) => {
								user.canAccessPPSR = checked;
								onChange('canAccessPPSR');
							}}
							errors={errors.canAccessPPSR}
						/>
						<Checkbox
							className="input-group__checkbox2"
							model={user}
							modelProperty="canAccessIntel"
							label="Access Monitor"
							isDisabled={readonly || !isNewUser || disableIntelPermission}
							onChecked={(event, checked) => {
								user.canAccessIntel = checked;
								onChange('canAccessIntel');
							}}
							errors={errors.canAccessIntel}
						/>
					</If>
				</div>
			) : null}
		</div>
	);
});

export default AccountFields;
