import * as React from 'react';
import { isEmail } from 'Validators/Functions/Email';
import { Errors } from 'Util/CustomTypes';
import { observer } from 'mobx-react';
import { BusinessEntity, SpgEntity } from 'Models/Entities';
import If from '../If/If';
import { action } from 'mobx';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import PpsrFields from 'Views/Components/Ppsr/PpsrFields';

// We expect user to be an observable, but errors is managed through useState.
// It's awkward, but keeping user as an observable makes the input components work much more easily.
export interface PpsrBusinessEntityRegistrationProps {
    businessEntity: BusinessEntity,
	onAfterChange?: (validateSuccessful: boolean) => void,
}

const PpsrBusinessEntityRegistration = observer((props: PpsrBusinessEntityRegistrationProps) => {
	const {
		businessEntity, onAfterChange,
	} = props;

	return (
		<div className="account-fields">
			<div className="ppsr-business-entity">
				{/* top container with slider */}
				<div>
					<h6>{businessEntity.name}</h6>
					<p>ABN {businessEntity.abn}</p>
				</div>
				<ToggleSwitch
					model={businessEntity}
					modelProperty="enabledForPPSR"
					onAfterChecked={action(() => {
						if (businessEntity.enabledForPPSR) {
							businessEntity.spgss.push(new SpgEntity({
								businessEntityId: businessEntity.id,
								isActive: true,
								organisationalNumbers: [...new Set([businessEntity.abn, businessEntity.acn,
									businessEntity.trusteeACN].filter(x => !!x))].join(','),
							}));
							businessEntity.spgss = businessEntity.spgss.slice().reverse();
						} else {
							businessEntity.spgss.shift();
						}
						['summary', 'ppsrEmail'].forEach(field => {
							delete businessEntity.errors[field];
						});
					})}
				/>
			</div>

			{/* answers to ppsr questions */}
			<If condition={businessEntity.enabledForPPSR}>
				<PpsrFields
					businessEntity={businessEntity}
					onAfterChange={onAfterChange}
				/>
			</If>
		</div>
	);
});

export default PpsrBusinessEntityRegistration;
