import { observer } from 'mobx-react';
import { BusinessEntity, IndustryCodesEntity, OrganisationEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors, Display } from '../Button/Button';
import If from '../If/If';
import IndustryCodeSelector from '../Wizard/OrganisationRegistration/IndustryCodeSelector';
import OrganisationFields from './OrganisationFields';
import { useState } from 'react';
import OrganisationDetailsReferralPartner from './OrganisationDetailsReferralPartner';
import { AddressEntity } from '../../../Models/Entities';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface OrganisationDetailsProps {
	originalOrganisationEntity: OrganisationEntity,
	setName?: (name: string) => void,
}

const OrganisationDetails = observer((props: OrganisationDetailsProps) => {
	const { originalOrganisationEntity, setName } = props;
	const disabled = !(store.userType === 'SUPER_USER' || store.userPermissions.commonManageOrganisations !== 'NONE');
	const referralPartnersDisabled = !(store.userType === 'SUPER_USER'
		|| (store.userPermissions.commonManageOrganisations === 'WITHIN_ORGANISATION'
			&& store.userPermissions.commonManageReferrals === true));

	const [hasChanges, setHasChanges] = useState(false);
	const [organisation, setOrganisationEntity] = useState(() => {
		const organisationEntity = new OrganisationEntity(originalOrganisationEntity);
		organisationEntity.primaryBusinessEntity = new BusinessEntity(originalOrganisationEntity.primaryBusinessEntity);
		organisationEntity.primaryBusinessEntity.address = new AddressEntity(originalOrganisationEntity
			.primaryBusinessEntity.address);
		return organisationEntity;
	});

	const onAfterChange = (validateSuccessful: boolean) => {
		setHasChanges(validateSuccessful);
	};

	/**
	 * Method to save the updated user information. Raises an alert toast on success and on error.
	 */
	const onSavePressed = async () => {
		await organisation.primaryBusinessEntity.validateDetailsFields();
		if (Object.keys(organisation.primaryBusinessEntity.errors).length === 0) {
			try {
				organisation.industryCodesId = organisation.industryCodes?.id;
				if (!!organisation.primaryBusinessEntity.address) {
					organisation.primaryBusinessEntity.address.businessEntityId = organisation.primaryBusinessEntityId;
				}
				await organisation.saveWithFetchBack({
					primaryBusinessEntity: {
						address: {},
					},
				}, {}, `
					primaryBusinessEntity {
						${BusinessEntity.getAttributes().join('\n')}
						address {
							${AddressEntity.getAttributes().join('\n')}
						}
					}
				`);

				originalOrganisationEntity.businessEntitys = [];
				originalOrganisationEntity.assignAttributes(organisation);
			} catch (e) {
				alertToast('Organisation details could not be saved at this moment. Please '
					+ 'refresh and try again.', 'error');
				return;
			}

			alertToast('Organisation details have been successfully updated', 'success');
			if (!!setName) {
				setName(organisation.primaryBusinessEntity.name);
			}
			organisation.businessEntitys.find(b => b.id === organisation.primaryBusinessEntity.id)
				?.assignAttributes(organisation.primaryBusinessEntity);
			setHasChanges(false);
		}
	};

	return (
		<>
			<h4>Organisation details</h4>
			<div className="organisation-details ctr">
				<div>
					<OrganisationFields
						businessEntity={organisation.primaryBusinessEntity}
						disabled={disabled}
						onAfterChange={onAfterChange}
						type="organisation"
						quickRegister={false}
					/>

					<IndustryCodeSelector
						model={organisation}
						modelProperty="industryCodes"
						className="industry-code"
						onChange={() => {
							delete organisation.primaryBusinessEntity.errors.industryCode;
							setHasChanges(true);
						}}
						errors={organisation.primaryBusinessEntity.errors.industryCode}
						isDisabled={disabled}
					/>

					<If condition={!disabled}>
						<div>
							<ButtonAsyncState
								className="save"
								colors={Colors.Primary}
								display={Display.Solid}
								onPress={onSavePressed}
								readonly={Object.keys(organisation.primaryBusinessEntity.errors).length > 0
									|| !hasChanges}
							>
								Save Changes
							</ButtonAsyncState>
						</div>
					</If>
				</div>
			</div>
		</>
	);
});
export default OrganisationDetails;
