import * as React from 'react';
import { Combobox, ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';
import { store } from 'Models/Store';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import UserEntity from 'Models/Entities/UserEntity';
import { gql } from '@apollo/client';
import { OrganisationEntity } from 'Models/Entities';
import { IOrganisationEntityAttributes } from 'Models/Entities/OrganisationEntity';
import { observer } from 'mobx-react';

export interface OrganisationSelectorProps {
	className?: string;
	user: UserEntity, // should be observable
	errors?: string | string[];
	isDisabled?: boolean,
	isRequired?: boolean,
	onAfterChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
}

const OrganisationSelector = observer((props: OrganisationSelectorProps) => {
	const {
		user, isDisabled, isRequired, onAfterChange, className, errors,
	} = props;

	const getOrganisationOptions = async (searchTerm?: string): Promise<ComboboxOption<OrganisationEntity>[]> => {
		// If we're a super user, we fetch a list of all organisations
		if (store.userType === 'SUPER_USER') {
			try {
				const organisationsResult = await store.apolloClient
					.query({
						query: gql`
							query fetchOrganisations($searchTerm: String) {
								organisationEntitys(
									where: [{
										path: "primaryBusinessEntity.name",
										comparison: like,
										value: [$searchTerm],
										case: CURRENT_CULTURE_IGNORE_CASE
									}],
									orderBy: [{path: "primaryBusinessEntity.name", descending: false}],
								) {
									id
									intelEnabled
									ppsrEnabled
									approveEnabled
									primaryBusinessEntity {
										id
										name
									}
								}
							}
						`,
						variables: {
							searchTerm: !!searchTerm ? `%${searchTerm}%` : '%',
						},
						fetchPolicy: 'no-cache',
					});

				const organisationsList = organisationsResult.data.organisationEntitys.map(
					(x: Partial<IOrganisationEntityAttributes> | undefined) => new OrganisationEntity(x),
				) as OrganisationEntity[];
				return organisationsList.map(x => {
					return { display: x.primaryBusinessEntity?.name, value: x };
				});
			} catch (e) {
				// If we can't get organisations, we fall back to the code below.
			}
		}

		// Otherwise we return a list with just the current user's organisation
		if (!store.getUser?.organisation) {
			return []; // Current user doesn't actually have an organisation
		}
		return [
			{ display: store.getUser.organisation?.primaryBusinessEntity?.name, value: store.getUser.organisation },
		];
	};

	return (
		<Combobox
			className={classNames('organisation-selector', className)}
			model={user}
			modelProperty="organisation"
			label="Organisation"
			placeholder="Organisation"
			isDisabled={isDisabled || store.userType !== 'SUPER_USER'}
			isRequired={isRequired}
			initialOptions={getOrganisationOptions}
			options={AwesomeDebouncePromise(getOrganisationOptions, 250)}
			searchable
			onAfterChange={onAfterChange}
			errors={errors}
			getOptionValue={(organisation?: OrganisationEntity) => organisation?.id}
			optionEqualFunc={
				(id: string|number|boolean|undefined, organisation?: OrganisationEntity) => organisation?.id === id
			}
		/>
	);
});

export default OrganisationSelector;
