/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAddressEntity from 'Models/Security/Acl/VisitorsAddressEntity';
import UserAddressEntity from 'Models/Security/Acl/UserAddressEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IAddressEntityAttributes extends IModelAttributes {
	line1: string;
	city: string;
	state: string;
	line2: string;
	suburb: string;
	postcode: string;
	country: string;

	atbRecordId?: string;
	atbRecord?: Models.AtbRecordEntity | Models.IAtbRecordEntityAttributes;
	businessEntityId?: string;
	businessEntity?: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	customerWithPhysicalAddressId?: string;
	customerWithPhysicalAddress?: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	customerWithPostalAddressId?: string;
	customerWithPostalAddress?: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AddressEntity', 'Address')
// % protected region % [Customise your entity metadata here] end
export default class AddressEntity extends Model
	implements IAddressEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAddressEntity(),
		new UserAddressEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Line 1'] off begin
	/**
	 * Line 1
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'Line 1',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public line1: string;
	// % protected region % [Modify props to the crud options here for attribute 'Line 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'City'] off begin
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'City',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public city: string;
	// % protected region % [Modify props to the crud options here for attribute 'City'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	/**
	 * State
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'State',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public state: string;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Line 2'] off begin
	/**
	 * Line 2
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'Line 2',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public line2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Line 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] off begin
	/**
	 * Suburb
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'Suburb',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public suburb: string;
	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] end

	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] off begin
	/**
	 * Postcode
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'Postcode',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postcode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] end

	// % protected region % [Modify props to the crud options here for attribute 'Country'] off begin
	/**
	 * Country
	 */
	@observable
	@attribute<AddressEntity, string>()
	@CRUD({
		name: 'Country',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public country: string;
	// % protected region % [Modify props to the crud options here for attribute 'Country'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] off begin
		name: 'ATB Record',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.AtbRecordEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atbRecords',
			oppositeEntity: () => Models.AtbRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATB Record'] end
	})
	public atbRecordId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public atbRecord: Models.AtbRecordEntity;

	/**
	 * Address
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.BusinessEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'businessEntitys',
			oppositeEntity: () => Models.BusinessEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	/**
	 * Physical Address
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer with Physical Address'] off begin
		name: 'Customer with Physical Address',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.CustomerEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerWithPhysicalAddresss',
			oppositeEntity: () => Models.CustomerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customer with Physical Address'] end
	})
	public customerWithPhysicalAddressId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customerWithPhysicalAddress: Models.CustomerEntity;

	/**
	 * Postal Address
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer with Postal Address'] off begin
		name: 'Customer with Postal Address',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.CustomerEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerWithPostalAddresss',
			oppositeEntity: () => Models.CustomerEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customer with Postal Address'] end
	})
	public customerWithPostalAddressId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customerWithPostalAddress: Models.CustomerEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@action public validateField = async (field: string) => {
		switch (field) {
			case 'postcode': {
				this.setFieldError(
					'postcode',
					!!this.postcode && !this.postcode.match(/^[0-9]{4}$/),
					'Postcode must comprise of four numbers',
				);
				break;
			}
		}
	};
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAddressEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAddressEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.line1 !== undefined) {
				this.line1 = attributes.line1;
			}
			if (attributes.city !== undefined) {
				this.city = attributes.city;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.line2 !== undefined) {
				this.line2 = attributes.line2;
			}
			if (attributes.suburb !== undefined) {
				this.suburb = attributes.suburb;
			}
			if (attributes.postcode !== undefined) {
				this.postcode = attributes.postcode;
			}
			if (attributes.country !== undefined) {
				this.country = attributes.country;
			}
			if (attributes.atbRecordId !== undefined) {
				this.atbRecordId = attributes.atbRecordId;
			}
			if (attributes.atbRecord !== undefined) {
				if (attributes.atbRecord === null) {
					this.atbRecord = attributes.atbRecord;
				} else if (attributes.atbRecord instanceof Models.AtbRecordEntity) {
					this.atbRecord = attributes.atbRecord;
					this.atbRecordId = attributes.atbRecord.id;
				} else {
					this.atbRecord = new Models.AtbRecordEntity(attributes.atbRecord);
					this.atbRecordId = this.atbRecord.id;
				}
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.customerWithPhysicalAddressId !== undefined) {
				this.customerWithPhysicalAddressId = attributes.customerWithPhysicalAddressId;
			}
			if (attributes.customerWithPhysicalAddress !== undefined) {
				if (attributes.customerWithPhysicalAddress === null) {
					this.customerWithPhysicalAddress = attributes.customerWithPhysicalAddress;
				} else if (attributes.customerWithPhysicalAddress instanceof Models.CustomerEntity) {
					this.customerWithPhysicalAddress = attributes.customerWithPhysicalAddress;
					this.customerWithPhysicalAddressId = attributes.customerWithPhysicalAddress.id;
				} else {
					this.customerWithPhysicalAddress = new Models.CustomerEntity(attributes.customerWithPhysicalAddress);
					this.customerWithPhysicalAddressId = this.customerWithPhysicalAddress.id;
				}
			}
			if (attributes.customerWithPostalAddressId !== undefined) {
				this.customerWithPostalAddressId = attributes.customerWithPostalAddressId;
			}
			if (attributes.customerWithPostalAddress !== undefined) {
				if (attributes.customerWithPostalAddress === null) {
					this.customerWithPostalAddress = attributes.customerWithPostalAddress;
				} else if (attributes.customerWithPostalAddress instanceof Models.CustomerEntity) {
					this.customerWithPostalAddress = attributes.customerWithPostalAddress;
					this.customerWithPostalAddressId = attributes.customerWithPostalAddress.id;
				} else {
					this.customerWithPostalAddress = new Models.CustomerEntity(attributes.customerWithPostalAddress);
					this.customerWithPostalAddressId = this.customerWithPostalAddress.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		atbRecord {
			${Models.AtbRecordEntity.getAttributes().join('\n')}
		}
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		customerWithPhysicalAddress {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
		customerWithPostalAddress {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'atbRecord',
							'businessEntity',
							'customerWithPhysicalAddress',
							'customerWithPostalAddress',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		const localAddress = (this.line1 ? this.line1 : '')
			+ (this.line2 ? ` ${this.line2}` : '');

		return (localAddress ? `${localAddress},` : '')
			+ (this.suburb ? ` ${this.suburb}` : '')
			+ (this.state ? ` ${this.state}` : '')
			+ (this.postcode ? ` ${this.postcode}` : '');
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AddressEntity.prototype, 'created');
CRUD(modifiedAttr)(AddressEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
