import { Provider, observer } from 'mobx-react';
import React from 'react';
import { ESignStoreContext, eSignStore } from './Stores/ESignStore';
import ESignRoutes from './Router/ESignRoutes';
import { RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';

const ESignIndex: React.FunctionComponent<RouteComponentProps> = observer(props => {
	eSignStore.commonStore.history = store.routerHistory;
	return (
		<ESignStoreContext.Provider value={eSignStore}>
			<Provider eSignStore={eSignStore}>
				<ESignRoutes {...props} />
			</Provider>
		</ESignStoreContext.Provider>
	);
});

export default ESignIndex;
