import * as React from 'react';
import { BusinessEntity } from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import OrganisationFields from 'Views/Components/Organisation/OrganisationFields';
import { Button, Display } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';
import { useState } from 'react';

export type EditBusinessDetailsModalProps = {
	onClose: () => void,
	onSubmit: () => Promise<void>,
    product: string,
	label: string,
	isOpen: boolean,
	key?: string,
}

const RemoveProductModal = observer((props: EditBusinessDetailsModalProps) => {
	const {
		onClose, onSubmit, product, label, isOpen,
	} = props;
	const [onSubmitInProgress, setOnSubmitInProgress] = useState(false);

	const internalOnSubmit = async () => {
		setOnSubmitInProgress(true);
		await onSubmit();
		setOnSubmitInProgress(false);
	};

	return (
		<Modal
			isOpen={isOpen}
			label={`Remove ${label}`}
			onRequestClose={onClose}
			className="access-modal remove-product-modal"
		>
			<h4>Remove {label}?</h4>
			<div>
				<p>
					{'Continuing with this action will '}<b>deactivate the product</b>{' from your list and mean you '
					+ "no longer have access to the features. You will be billed for this month but won't be for "
					+ 'the next.'}
				</p>
				<p>
					{'If you change your mind, you can activate it but will need to complete the application'
					+ ' process again.'}
				</p>
			</div>
			<div className="modal__actions actions">
				<Button className="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button
					className="confirm"
					onClick={internalOnSubmit}
					display={Display.Solid}
					disabled={onSubmitInProgress}
				>
					Yes, remove
				</Button>
			</div>
		</Modal>
	);
});
export default RemoveProductModal;
