import * as React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { Button, Colors, Display } from '../Button/Button';
import If from '../If/If';
import RiskRatingsUploadModal from './RiskRatingsUploadModal';

const MonitorUploadRiskRatings = () => {
	const [showUploadModal, setShowUploadModal] = React.useState(false);
	return (
		<>
			<div>
				<h5>Payment Risk Ratings</h5>
				<div>
					<div className="label-wrapper">
						<p>Upload Illion payment risk ratings</p>
						<Tooltip
							id="upload-risk-ratings-tooltip"
							content="Upload Illion payment risk ratings to update customer risk ratings"
							place="right"
						/>
					</div>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						className="upload-risk-ratings-button"
						onClick={() => setShowUploadModal(true)}
					>
						Upload CSV
					</Button>
				</div>
			</div>
			<If condition={showUploadModal}>
				<RiskRatingsUploadModal onClose={() => setShowUploadModal(false)} />
			</If>
		</>
	);
};

export default MonitorUploadRiskRatings;
