// Calculate the monthly price for an organisation to use Access, based upon their monthly exposure, customer count
// user licenses, and active products
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { DiscountEntity } from 'Models/Entities';
import { SERVER_URL } from 'Constants';
import axios from 'axios';

export interface PricingDetailDto {
	OrganisationId?: string;
	PeriodYear?: number;
	PeriodMonth?: number;
	CalculateProRata?: boolean;
	IncludeAllProductDiscount?: boolean;
	UserCount?: number;
	UserCountDelta?: number;
	CustomerCount?: number;
	CustomerCountDelta?: number;
	MonthlyExposure?: number;
	ApproveEnabled?: boolean;
	PpsrEnabled?: boolean;
	IntelEnabled?: boolean;
	Discounts?: DiscountEntity[];
}

export interface PricingDetail {
	period: {
		periodBegin: string,
		periodEnd: string,
		billingBegin: string,
	};
	total: number;
	lineItems: {
		type: string,
		name: string,
		comment: string|null,
		total: number,
	}[];
	userCount: number;
	userCountBucket: number;
	customerCount: number;
	customerCountBucket: number;
	monthlyExposure: number;
	approveEnabled: boolean;
	ppsrEnabled: boolean;
	monitorEnabled: boolean;
}

export async function CalculatePricing(pricingDetailDto: PricingDetailDto): Promise<PricingDetail> {
	if (pricingDetailDto.PeriodYear === undefined && pricingDetailDto.PeriodMonth === undefined) {
		pricingDetailDto.PeriodYear = new Date().getFullYear();
		pricingDetailDto.PeriodMonth = new Date().getMonth() + 1;
	}
	if (pricingDetailDto.CalculateProRata === undefined) {
		pricingDetailDto.CalculateProRata = false;
	}

	const pricingDetail = await axios.post(`${SERVER_URL}/api/pricing/calculate`, pricingDetailDto)
		.then(data => {
			return data.data;
		})
		.catch(exception => {
			console.error(exception);
			return null;
		});

	return pricingDetail as PricingDetail;
}

export const monthlyExposureComboboxOptions = [
	{ display: '$0 - $83,000', value: 83000 },
	{ display: '$83,001 - $416,000', value: 416000 },
	{ display: '$416,001 - $830,000', value: 830000 },
	{ display: '$830,000 - $4,166,000', value: 4166000 },
	{ display: '$4,166,000 - $8,333,000', value: 8333000 },
	{ display: '$8,333,000+', value: 9999999999 },
] as ComboboxOption<number>[];
