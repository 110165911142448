import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import HandleEvents from 'Util/HandleEvents';
import { RegistrationEntity } from 'Models/Entities';
import Spinner from 'Views/Components/Spinner/Spinner';
import If from 'Views/Components/If/If';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RegistrationSummary from 'Views/Components/Registration/RegistrationSummary';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import RegistrationBusinessEntityList from '../Components/BusinessEntities/RegistrationBusinessEntityList';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

type ViewRegistrationTabs = 'organisation-details' | 'business-entities'
type RequestState = 'loading' | 'error' | 'complete'

export interface ViewRegistrationPageProps {
	registrationId?: string,
}

@observer
class ViewRegistrationPage extends React.Component<RouteComponentProps<ViewRegistrationPageProps>> {
	@observable private currentTab: ViewRegistrationTabs = 'organisation-details';

	@observable private registration?: RegistrationEntity;
	@observable private requestState: RequestState = 'loading';

	public componentDidMount(): void {
		this.fetchRegistrationData();
	}

	private fetchRegistrationData = async () => {
		const { match } = this.props;
		const { registrationId } = match.params;

		try {
			const results = await store.apolloClient.query({
				query: gql`
					query fetchRegistrationById($registrationId: ID) {
						registrationEntity(id: $registrationId) {
							id
							registrationData
							status
							userName
						}
					}`,
				variables: {
					registrationId: registrationId,
				},
				fetchPolicy: 'no-cache',
			});

			runInAction(() => {
				if (!!results.data.registrationEntity) {
					this.registration = new RegistrationEntity(results.data.registrationEntity);
					this.requestState = 'complete';
				} else {
					this.requestState = 'error';
				}
			});
		} catch (e) {
			runInAction(() => {
				this.requestState = 'error';
			});
		}
	};

	private getNameForBreadcrumb(): string {
		if (this.registration?.organisationName) {
			return this.registration?.organisationName;
		}
		if (this.registration?.parsedRegistrationData?.businessEntitys?.length
				&& this.registration?.parsedRegistrationData?.businessEntitys[0]?.name) {
			return this.registration.parsedRegistrationData.businessEntitys[0].name;
		}
		if (this.registration?.userName) {
			return this.registration?.userName;
		}
		return 'Not provided';
	}

	render() {
		if (this.requestState === 'error') {
			alertToast('Unable to find matching registration', 'error');
			store.routerHistory.push('/hub/registrations');
			return <Spinner />;
		}

		return (
			<AccessIntelSecuredPage groups={['Super Administrators', 'SUPER_USER']} routeComponentProps={this.props}>
				<div className="body-content organisation-page">
					<div className="invisible-page-wrap">
						<If condition={this.requestState === 'loading'}>
							<InlineSpinner />
						</If>
						<If condition={this.requestState === 'complete'}>
							<div className="top-container">
								<PageBreadcrumbs
									tags={
										[
											{ label: 'Registrations', link: '/hub/registrations' },
											{ label: this.getNameForBreadcrumb() },
										]
									}
								/>
								{this.registration?.status !== 'COMPLETE' ? (
									<div>
										<Button
											display={Display.Outline}
											colors={Colors.Primary}
											onClick={async () => {
												try {
													await confirmModal(
														'Please confirm',
														'Are you sure you want to delete this registration?',
													);
												} catch (e) {
													return;
												}

												await this.registration?.delete();

												alertToast('Registration deleted', 'success');
												store.routerHistory.push('/hub/registrations');
											}}
										>
											Delete Registration
										</Button>
									</div>
								) : null}
							</div>

							<div className="tab-selector-wrap">
								{/* WHEN USING THIS IN ANOTHER PAGE SET THE WIDTH OF THE TAB
								 	IN CSS SO IT DOESN'T SHRINK WHEN BEING UNSELECTED */}
								<button
									className={classNames('tab-selector', 'organisation-details', {
										active: this.currentTab === 'organisation-details',
									})}
									{...HandleEvents(action(() => {
										this.currentTab = 'organisation-details';
									}))}
								>
									Organisation details
								</button>
								<button
									className={classNames('tab-selector', 'business-entities', {
										active: this.currentTab === 'business-entities',
									})}
									{...HandleEvents(action(() => {
										this.currentTab = 'business-entities';
									}))}
								>
									Business entities
								</button>
							</div>
							<div className={classNames('white-box', {
								'square-top-left-border': this.currentTab === 'organisation-details',
							})}
							>
								<If condition={this.currentTab === 'organisation-details'}>
									{!!this.registration
										? (
											<RegistrationSummary registrationEntity={this.registration} />
										)
										: null}
								</If>
								<If condition={this.currentTab === 'business-entities'}>
									{!!this.registration?.parsedRegistrationData?.businessEntitys
									&& !!this.registration?.parsedRegistrationData?.organisation
										? (
											<RegistrationBusinessEntityList
												organisation={this.registration.parsedRegistrationData.organisation}
												businessEntities={
													this.registration.parsedRegistrationData.businessEntitys
												}
												readonly
											/>
										)
										: null}
								</If>
							</div>
						</If>
					</div>
				</div>
			</AccessIntelSecuredPage>
		);
	}
}
export default ViewRegistrationPage;
