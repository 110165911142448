import { useESignStore } from 'ESign/Stores/ESignStore';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import { observer } from 'mobx-react';
import React from 'react';
import ESignCeremonyTable from './Gibs/ESignCeremonyTable';

type Props = {
	nextSection: () => void;
}

const ESignOverviewSection: React.FunctionComponent<Props> = observer(({ nextSection }) => {
	const {
		workflowStore: {
			userSummary: {
				signeeCeremonyDetails, witnessCeremonyDetails, name,
			},
			handleSigneeCeremonyPagingOptions,
			handleWitnessCeremonyPagingOptions,
		},
	} = useESignStore();

	const hasSigneeCeremony = signeeCeremonyDetails && signeeCeremonyDetails.pageSize > 0;
	const hasWitnessCeremony = witnessCeremonyDetails && witnessCeremonyDetails.pageSize > 0;

	return (
		<>
			<h2 className="esign-heading">Hi, {name}</h2>
			{hasSigneeCeremony && (
				<>
					<ESignCeremonyTable
						ceremonies={signeeCeremonyDetails.items}
						isSigneeTable
						nextSection={nextSection}
					/>
					{signeeCeremonyDetails.totalCount > signeeCeremonyDetails.pageSize && (
						<TablePagination
							perPage={signeeCeremonyDetails.pageSize}
							pageNo={signeeCeremonyDetails.currentPage - 1}
							totalRecords={signeeCeremonyDetails.totalCount}
							onPageChange={pageNo => {
								const newPage = pageNo + 1;
								handleSigneeCeremonyPagingOptions(newPage, signeeCeremonyDetails.pageSize);
							}}
						/>
					)}
				</>
			)}
			{hasWitnessCeremony && (
				<>
					<ESignCeremonyTable
						ceremonies={witnessCeremonyDetails.items}
						isSigneeTable={false}
						nextSection={nextSection}
					/>
					{witnessCeremonyDetails.totalCount > witnessCeremonyDetails.pageSize && (
						<TablePagination
							perPage={witnessCeremonyDetails.pageSize}
							pageNo={witnessCeremonyDetails.currentPage - 1}
							totalRecords={witnessCeremonyDetails.totalCount}
							onPageChange={pageNo => {
								const newPage = pageNo + 1;
								handleWitnessCeremonyPagingOptions(newPage, witnessCeremonyDetails.pageSize);
							}}
						/>
					)}
				</>
			)}
		</>
	);
});

export default ESignOverviewSection;
