import * as React from 'react';
import { UserEntity } from 'Models/Entities';
import { useState } from 'react';
import {
	Button, Colors, Display,
} from '../Button/Button';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { UserTypeOrder } from 'Models/Enums';
import AccountFields, { AccountFieldValidationFunctions } from 'Views/Components/Account/AccountFields';
import { Errors } from 'Util/CustomTypes';
import ResetPasswordModal from 'Views/Components/Account/ResetPasswordModal';
import UpdateEmailModal from 'Views/Components/Account/UpdateEmailModal';
import ButtonAsyncState from '../Button/ButtonAsyncState';

export interface AccountTileProps {
	originalUser: UserEntity,
	setName?: (firstName: string, lastName: string) => void,
}

const defaultErrors: Errors = {};

function AccountTile(props: AccountTileProps) {
	const { originalUser, setName } = props;

	const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
	const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
	const [editErrors, setEditErrors] = useState(defaultErrors);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [user, setUser] = useState(new UserEntity(originalUser));

	const isMyAccount = store.userId === user.id;
	const whoseAccount = isMyAccount ? 'your' : "user's";
	const isSuper = store.userType === 'SUPER_USER';
	const isUserAboveMe = UserTypeOrder[user.userType] > UserTypeOrder[store.userType];
	const [disableApproveCheckbox, setDisableApprovecheckbox] = useState(!originalUser.canAccessApprove && (isMyAccount || isUserAboveMe));
	const [disablePPSRCheckbox, setDisablePPSRcheckbox] = useState(!originalUser.canAccessPPSR && (isMyAccount || isUserAboveMe));
	const [disableMonitorCheckbox, setDisableMonitorcheckbox] = useState(!originalUser.canAccessIntel && (isMyAccount || isUserAboveMe));

	// should the form be disabled => Can I manage users or is the user above me
	const formDisabled = !store.userPermissions.commonManageUsers || isUserAboveMe;

	const onAfterChange = (validateSuccessful: boolean) => {
		setSaveDisabled(!validateSuccessful);
	};

	/**
	 * Method to check for any errors in the edit account page
	 * @returns The updated accound edit state
	 */
	const validateAllErrors = () => {
		Object.keys(AccountFieldValidationFunctions).forEach(field => {
			const error = AccountFieldValidationFunctions[field](user);
			if (!error) {
				delete editErrors[field];
			} else {
				editErrors[field] = error;
			}
		});

		// disable save if there are errors
		const errorFound = Object.keys(editErrors).length > 0;
		setSaveDisabled(errorFound);
		setEditErrors({ ...editErrors }); // Clone the object to that React recognises the change
		return errorFound;
	};

	/**
	 * Method to save the updated user information. Raises an alert toast on success and on error.
	 */
	const onSavePressed = async () => {
		if (validateAllErrors()) {
			return;
		}
		try {
			await user.save(
				{
					businessEntitys: {},
				},
				{
					options: [
						{
							key: 'mergeReferences',
							graphQlType: '[String]',
							value: [
								'businessEntitys',
							],
						},
					],
				},
			);
			originalUser.assignAttributes(user);
		} catch (e) {
			alertToast(`${whoseAccount} account information could not be saved at this moment. Please `
				+ 'refresh and try again.', 'error');
			return;
		}

		if (setName) {
			setName(user.firstName, user.lastName);
		}
		setDisableApprovecheckbox(!user.canAccessApprove);
		setDisablePPSRcheckbox(!user.canAccessPPSR);
		setDisableMonitorcheckbox(!user.canAccessIntel);
		alertToast(`${whoseAccount} account information has been successfully updated`, 'success');
	};

	/**
	 * Method to close either modal and reset their state
	 */
	const onModalClose = () => {
		setShowPasswordResetModal(false);
		setShowUpdateEmailModal(false);
	};

	return (
		<div className="account-tile">
			<AccountFields
				user={user}
				errors={editErrors}
				setErrors={setEditErrors}
				readonly={formDisabled}
				onAfterChange={onAfterChange}
			/>

			{isSuper || isMyAccount
				? (
					<div className="update-sensitive-container">
						<Button
							className="update-email"
							onClick={() => setShowUpdateEmailModal(true)}
							colors={Colors.Primary}
							display={Display.Outline}
						>
							Change email address
						</Button>
						<Button
							className="reset"
							onClick={() => setShowPasswordResetModal(true)}
							colors={Colors.Primary}
							display={Display.Outline}
						>
							Reset {whoseAccount} password
						</Button>
					</div>
				)
				: null }

			<ResetPasswordModal showModal={showPasswordResetModal} user={user} onModalClose={onModalClose} />
			<UpdateEmailModal showModal={showUpdateEmailModal} user={user} onModalClose={onModalClose} />

			<div>
				<ButtonAsyncState
					className="save"
					colors={Colors.Primary}
					display={Display.Solid}
					onPress={onSavePressed}
					readonly={saveDisabled || formDisabled}
				>
					Save Changes
				</ButtonAsyncState>
			</div>
		</div>
	);
}

export default AccountTile;
