import moment from 'moment/moment';
import If from 'Views/Components/If/If';
import * as React from 'react';
import { AlaresReport } from 'Models/Interfaces/AlaresReport';

const AdverseAlertsAbrSummary = (props: {
	alaresReport?: AlaresReport,
}) => {
	const { alaresReport } = props;
	return (
		<div className="section abr-summary">
			<div className="fields">
				<p className="field"><b>ASIC status:</b> {alaresReport?.asic_status}</p>
				<p className="field">
					<b>ASIC registration date:</b> {moment(alaresReport?.asic_date_of_registration)
						.format('Do MMMM YYYY')}
				</p>
				<If condition={!!alaresReport?.trustee_asic_status}>
					<p className="field"><b>Trustee ASIC status:</b> {alaresReport?.trustee_asic_status}</p>
				</If>
				<If condition={!!alaresReport?.trustee_asic_date_of_registration}>
					<p className="field">
						<b>Trustee ASIC registration date:</b> {moment(alaresReport
							?.trustee_asic_date_of_registration)
							.format('Do MMMM YYYY')}
					</p>
				</If>
				<p className="field">
					<b>GST from date:</b> {moment(alaresReport?.abn_gst_registration_date)
						.format('Do MMMM YYYY')}
				</p>
			</div>
			<div className="fields">
				<p className="field"><b>ABN status:</b> {alaresReport?.abn_status }</p>
				<p className="field">
					<b>ABN state & postcode:</b> {alaresReport?.abn_state} {alaresReport?.abn_postcode}
				</p>
				<p className="field"><b>GST status:</b> {alaresReport?.abn_gst_status}</p>
			</div>
		</div>
	);
};

export default AdverseAlertsAbrSummary;
