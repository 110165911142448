import { ESignUserSigneeCeremony, ESignUserWitnessCeremony } from 'ESign/Types/Summary/ESignUserCeremony';
import { esignceremonystatusOptions, esignwitnessstatusOptions } from 'Models/Enums';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
import {
	Dimmer,
	DimmerDimmable,
	Segment,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from 'semantic-ui-react';

import { useESignStore } from 'ESign/Stores/ESignStore';
import LoadableState from 'ESign/Types/ESignLoadableState';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

type Props = {
	ceremonies: ESignUserSigneeCeremony[] | ESignUserWitnessCeremony[];
	isSigneeTable: boolean;
	nextSection: () => void;
}

const ESignCeremonyTable: React.FunctionComponent<Props> = observer(({ ceremonies, isSigneeTable, nextSection }) => {
	const { workflowStore: { setSelectedCeremony, signeeCeremonyState, witnessCeremonyState } } = useESignStore();

	const isWorkflowExpired = (createdDate: Date, lifespan: number) => {
		return moment().isAfter(moment(createdDate).add(lifespan, 'days'));
	};

	const sortedCeremonies = [...ceremonies].sort((a, b) => {
		const isUserActionRequired = (ceremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony) => {
			return ['CREATED', 'SENT', 'NOT_STARTED', 'VISITED'].includes(ceremony.status);
		};

		const compareCreatedDates = () => {
			return moment(a.workflow.created).isBefore(moment(b.workflow.created)) ? -1 : 1;
		};

		const signedOrCompleted = (ceremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony) => {
			return ceremony.status === 'SIGNED' || ceremony.status === 'COMPLETED';
		};

		const rejectedOrIncomplete = (ceremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony) => {
			return ceremony.status === 'REJECTED' || ceremony.status === 'INCOMPLETE';
		};

		const arrangeValue = compareCreatedDates();
		const aUserActionRequired = isUserActionRequired(a) && !isWorkflowExpired(a.workflow.created, a.workflow.template.lifeSpan ?? 0);
		const bUserActionRequired = isUserActionRequired(b) && !isWorkflowExpired(b.workflow.created, b.workflow.template.lifeSpan ?? 0);

		if (aUserActionRequired && bUserActionRequired) {
			return arrangeValue;
		}

		if (aUserActionRequired) {
			return -1;
		}

		if (bUserActionRequired) {
			return 1;
		}

		if (a.status === 'AWAITINGWITNESS' && b.status !== 'AWAITINGWITNESS') {
			return -1;
		}
		if (b.status === 'AWAITINGWITNESS' && a.status !== 'AWAITINGWITNESS') {
			return 1;
		}
		if (a.status === 'AWAITINGWITNESS' && b.status === 'AWAITINGWITNESS') {
			return arrangeValue;
		}

		const aSignedOrCompleted = signedOrCompleted(a);
		const bSignedOrCompleted = signedOrCompleted(b);
		if (aSignedOrCompleted && !bSignedOrCompleted) {
			return -1;
		}
		if (bSignedOrCompleted && !aSignedOrCompleted) {
			return 1;
		}

		if (aSignedOrCompleted && bSignedOrCompleted) {
			return arrangeValue;
		}

		const aRejectedOrIncomplete = rejectedOrIncomplete(a);
		const bRejectedOrIncomplete = rejectedOrIncomplete(b);
		if (aRejectedOrIncomplete && !bRejectedOrIncomplete) {
			return 1;
		}
		if (bRejectedOrIncomplete && !aRejectedOrIncomplete) {
			return -1;
		}

		return arrangeValue;
	});

	const ceremonyStatus = (status: string) => {
		if (isSigneeTable) {
			return esignceremonystatusOptions[status];
		}
		return esignwitnessstatusOptions[status];
	};

	const getExpiryDate = (createdDate: Date, lifespan: number): string => {
		return moment(createdDate).add(lifespan, 'days').fromNow();
	};

	const handleCeremonyClick = debounce((ceremonyId: string) => {
		setSelectedCeremony(ceremonyId);
		nextSection();
	}, 300);

	const getTableRowColor = (ceremony: ESignUserSigneeCeremony | ESignUserWitnessCeremony) => {
		const response = {
			positive: false,
			negative: false,
			warning: false,
		};

		// check if ceremony is signed or completed
		const isSigned = ceremony.status === 'SIGNED' || ceremony.status === 'COMPLETED';
		if (isSigned) {
			return { ...response, positive: true };
		}

		// check if ceremony status is awaiting signature from witness
		const isAwaitingWitness = ceremony.status === 'AWAITINGWITNESS';
		if (isAwaitingWitness) {
			return { ...response, warning: true };
		}

		// check if ceremony is rejected or incomplete
		// or if the ceremony has expired
		const isRejected = ceremony.status === 'REJECTED' || ceremony.status === 'INCOMPLETE';
		const isExpired = isWorkflowExpired(ceremony.workflow.created, ceremony.workflow.template.lifeSpan ?? 7);
		if (isRejected || isExpired) {
			return { ...response, negative: true };
		}

		return response;
	};

	const isBlur = isSigneeTable ? signeeCeremonyState === LoadableState.Pending : witnessCeremonyState === LoadableState.Pending;

	return (
		<DimmerDimmable as={Segment} blurring dimmed={isBlur} basic>
			<Dimmer active={isBlur} inverted />
			<h2 className="esign-heading">Documents to {isSigneeTable ? ' Review ' : ' Witness '} and Sign</h2>
			<Table selectable className="esign-table" textAlign="center" verticalAlign="middle">
				<TableHeader>
					<TableRow>
						<TableHeaderCell>Company Name</TableHeaderCell>
						<TableHeaderCell>Document Title</TableHeaderCell>
						<TableHeaderCell>Received Date</TableHeaderCell>
						{isSigneeTable && (<TableHeaderCell>Witness Signature Requirement</TableHeaderCell>)}
						<TableHeaderCell>Status</TableHeaderCell>
						<TableHeaderCell>Due</TableHeaderCell>
						<TableHeaderCell />
					</TableRow>
				</TableHeader>
				<TableBody>
					{sortedCeremonies.map(ceremony => (
						<TableRow
							key={ceremony.id}
							positive={getTableRowColor(ceremony).positive}
							negative={getTableRowColor(ceremony).negative}
							warning={getTableRowColor(ceremony).warning}
							onClick={() => handleCeremonyClick(ceremony.id)}
						>
							<TableCell>{ceremony.workflow.owner.name}</TableCell>
							<TableCell>{ceremony.workflow.template.name}</TableCell>
							<TableCell>{moment(ceremony.created).format('DD/MM/YYYY HH:mm')}</TableCell>
							{isSigneeTable && (
								<TableCell>{ceremony.workflow.templateContent.requireWitness ? 'Yes' : 'No'}</TableCell>
							)}
							<TableCell>{ceremonyStatus(ceremony.status)}</TableCell>
							<TableCell>
								{getExpiryDate(ceremony.workflow.created, ceremony.workflow.template.lifeSpan ?? 7)}
							</TableCell>
							<TableCell>
								<Button
									sizes={Sizes.Small}
									display={Display.Solid}
									onClick={() => handleCeremonyClick(ceremony.id)}
								>
									View
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</DimmerDimmable>
	);
});

export default ESignCeremonyTable;
