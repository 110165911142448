import * as React from 'react';
import copyTextToClipboard from './CopyTextToClipboard';
import { SERVER_URL } from '../Constants';
import axios from 'axios';
import { LocaleFormatCurrency } from 'Util/StringUtils';

export interface IReferralPartnerEmailDetails {
	to: string;
	subject: string;
	contactPerson: string;
	userFullName: string;
	customerBusinessEntityName: string;
	customerOrganisationBusinessEntityName: string;
	referralPartnerOrganisationName: string;
	referralPartnerGroup: string;
	businessName: string;
	customerAbn: string;
	customerEntityName: string;
	accountsContact: string;
	accountsEmail: string;
	accountsPhone: string;
	debtorId: string;
	totalOutstanding: number;
	totalOverdue: number;
	paymentTradingTerms: string;
}
// function to copy the referral link to the clipboard given a registrationId
export function copyReferralLinkToClipboard(registrationId: string) {
	const url = getReferralLink(registrationId);
	copyTextToClipboard(url);
}

// function to get a referral link for a given registrationId
export function getReferralLink(registrationId: string) {
	return `${window.location.origin}/hub/registration/r/${registrationId}`;
}

// function to get the mailTo link for a given registrationId and recipient fields
export function getMailToLink(
	recipientName: string,
	recipientEmail: string,
	registrationId: string,
	sendingUserName: string,
) {
	// below is the email templat given by Access Intell, for now this is static as the details shouldn't need to change
	const ccEmail = 'admin@accessintell.com';
	const subject = 'Access Intell link';
	const body = (
		`Hi ${recipientName},\r\n\r\n`
		+ 'Here is the Online Trade Application/PPSR/Customer Risk Monitoring solution I was telling you about.\r\n\r\n'
		+ `Click on the link ${getReferralLink(registrationId)} to get started, it's really easy to set up and only `
		+ 'takes a few minutes.\r\n\r\n'
		+ `Regards,\r\n${sendingUserName}`
	);
	// URL encode the data to replace the necessary characters
	return `mailto:${recipientEmail}?cc=${ccEmail}&subject=${encodeURIComponent(subject)}`
		+ `&body=${encodeURIComponent(body)}`;
}

export async function getEmailContent(customerId: string, organisationId:string) {
	const response = await axios.post<IReferralPartnerEmailDetails>(
		`${SERVER_URL}/api/entity/OrganisationEntity/getReferralPartnerEmail/${customerId}/${organisationId}`,
	).catch(ex => {
		throw new Error('Error occurred processing Referral Partner Email.');
	});
	return response.data;
}

export async function getReferralEmailLink(customerId: string, organisationId: string, details? : IReferralPartnerEmailDetails) {
	const detail = details ?? await getEmailContent(customerId, organisationId);

	// Check if the account fields are empty or not
	if (!detail) {
		throw new Error('Error occurred processing Referral Partner Email.');
	}
	const organisationString = detail.customerOrganisationBusinessEntityName !== detail.customerBusinessEntityName
		? `${detail.customerBusinessEntityName} within ${detail.customerOrganisationBusinessEntityName}`
		: `${detail.customerOrganisationBusinessEntityName}`;
	// Verifying the account fields
	const ACtemp = detail.accountsContact ?? ' ';
	const Emailtemp = detail.accountsEmail ?? ' ';
	const Phonetemp = detail.accountsPhone ?? ' ';
	// below is the email template given by Access Intell, for now this is static as the details shouldn't need to change
	const subject = `Access Intell Referral- ${organisationString}`;
	const body = (
		`Dear ${detail.contactPerson}\r\n\r\n`
		+ `${detail.userFullName} from ${organisationString} has referred the `
		+ 'below customer to you.\r\n\r\n'
		+ `Customer Name: ${detail.businessName}\r\n`
		+ `Customer ABN: ${detail.customerAbn}\r\n`
		+ `Customer Entity Name: ${detail.customerEntityName}\r\n`
		+ `Account Contact: ${ACtemp}\r\n`
		+ `Accounts Email: ${Emailtemp}\r\n`
		+ `Accounts Phone: ${Phonetemp}\r\n`
		+ `Debtor ID: ${detail.debtorId}\r\n`
		+ `Total Outstanding: ${LocaleFormatCurrency(detail.totalOutstanding)}\r\n`
		+ `Total Overdue: ${LocaleFormatCurrency(detail.totalOverdue)}\r\n`
		+ `Payment Trading Terms: ${detail.paymentTradingTerms}\r\n\r\n`
		+ `Best Contact Person within ${organisationString}:\r\n`
		+ 'Full Name:\r\n'
		+ 'Position:\r\n'
		+ 'Email:\r\n'
		+ 'Phone:\r\n\r\n'
		+ 'Additional Comments: *Please attach any additional information or documentation '
		+ `that may help ${detail.referralPartnerOrganisationName} to best action without delay*.\r\n\r\n`
		+ 'Regards,\r\n'
	);
	// URL encode the data to replace the necessary characters
	return `mailto:${detail.to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}
