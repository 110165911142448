import React from 'react';
import EntitySwitcher, { EntitySwitcherProps } from './EntitySwitcher';
import { SERVER_URL } from 'Constants';
import { store } from 'Models/Store';
import axios from 'axios';

export default function OrganisationContextSwitcher(props: EntitySwitcherProps) {
	const {
		getOptions,
		setValue,
		value,
	} = props;

	function handleAfterSet() {
		const requestOptions = {
			headers: {
				impersonatingOrganisationId: value,
			},
		};

		axios.get(`${SERVER_URL}/api/account/me`, requestOptions)
			.then(({ data }) => store.setLoggedInUser(data))
			.catch();
	}

	return (

		<EntitySwitcher
			objectName="organisation"
			tooltipContent="Allows you to view the Access Monitor platform from the perspective of another organisation"
			value={value}
			setValue={setValue}
			getOptions={getOptions}
			handleAfterSet={handleAfterSet}
		/>
	);
}
