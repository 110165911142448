import * as React from 'react';
import { observer } from 'mobx-react';
import { AttributeCRUDOptions } from 'Models/CRUDOptions';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { DocumentEntity } from 'Models/Entities';
import CustomAttributeFile from 'Views/Components/CRUD/Attributes/CustomAttributeFile';
import useAsync from 'Hooks/useAsync';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import If from 'Views/Components/If/If';

export interface UploadTermsProps {
}

const UploadTerms = observer((props: UploadTermsProps) => {
	const clientTermsDocument = useAsync(async () => {
		let terms = await DocumentEntity.getDocument('terms');
		if (!terms) {
			terms = new DocumentEntity({
				name: 'terms',
			});
		}
		return terms;
	}, []);

	const referralTermsDocument = useAsync(async () => {
		let referral = await DocumentEntity.getDocument('referral-partner-terms');
		if (!referral) {
			referral = new DocumentEntity({
				name: 'referral-partner-terms',
			});
		}
		return referral;
	}, []);

	if (clientTermsDocument.type !== 'data') {
		// This is mostly for the loading state. The error state shouldn't occur, since the getDocument
		// function catches errors and we use an empty document if it's not found
		return (<InlineSpinner />);
	}

	if (referralTermsDocument.type !== 'data') {
		// This is mostly for the loading state. The error state shouldn't occur, since the getDocument
		// function catches errors and we use an empty document if it's not found
		return (<InlineSpinner />);
	}

	return (
		<div className="upload-terms">
			<p className="description">
				Update or view your current&nbsp;
				<a
					href={`${SERVER_URL}/api/files/document/terms`}
					target="_blank"
					rel="noreferrer"
					className="file-link client-terms"
				>
					Terms & Conditions&nbsp;
				</a>

				and&nbsp;
				<a
					href={`${SERVER_URL}/api/files/document/referral-partner-terms`}
					target="_blank"
					rel="noreferrer"
					className="file-link referral-terms"
				>
					Referral Partner Terms & Conditions
				</a>
			</p>

			<div className="upload-terms-ctr">
				<div className="client-terms-ctr">
					<CustomAttributeFile
						className="settings-upload"
						model={clientTermsDocument.data}
						fileAttribute="file"
						options={new AttributeCRUDOptions(
							'fileId',
							{
								name: 'Upload client terms & conditions',
								displayType: 'file',
							},
						)}
						formMode={EntityFormMode.EDIT}
						onAfterChange={async () => {
							// We save any documents to the database directly, rather than waiting for the user to press a save button
							await clientTermsDocument.data.saveFromCrud(EntityFormMode.EDIT);
						}}
						disableDelete
					/>

					<If condition={!!clientTermsDocument.data.modified}>
						<p className="last-updated-text">
							Last updated {moment(clientTermsDocument.data.modified).format('HH:mmA, DD/MM/YYYY')}.
						</p>
					</If>
				</div>

				<div className="referral-terms-ctr">
					<CustomAttributeFile
						className="settings-upload"
						model={referralTermsDocument.data}
						fileAttribute="file"
						options={new AttributeCRUDOptions(
							'fileId',
							{
								name: 'Upload referral terms & conditions',
								displayType: 'file',
							},
						)}
						formMode={EntityFormMode.EDIT}
						onAfterChange={async () => {
							// We save any documents to the database directly, rather than waiting for the user to press a save button
							await referralTermsDocument.data.saveFromCrud(EntityFormMode.EDIT);
						}}
						disableDelete
					/>

					<If condition={!!referralTermsDocument.data.modified}>
						<p className="last-updated-text">
							Last updated {moment(referralTermsDocument.data.modified).format('HH:mmA, DD/MM/YYYY')}.
						</p>
					</If>
				</div>
			</div>
		</div>
	);
});
export default UploadTerms;
