import * as React from 'react';
import { Button, Display } from '../Button/Button';
import { TextField } from '../TextBox/TextBox';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';
import { range } from 'lodash';

export interface IPaginationProps {
	/** Number of records per page. */
	perPage: number;
	/** The page number of the collection. If this field is set then the pagination becomes controlled externally. */
	pageNo: number;
	/**
	 * The total number of records in the overall collection without pagination. If this field is not set then the size
	 * of the passed in model collection is used.
	 */
	totalRecords: number;
	/** Function to call on page change */
	onPageChange: (pageNo: number) => void;
}

enum validPageOptions {
	HIGH,
	VALID,
	LOW,
}

@observer
class TablePagination extends React.Component<IPaginationProps> {
	public render() {
		return (
			<div className="table-pagination">
				{this.renderFirstButton()}
				{this.renderPreviousButton()}
				{this.renderPages()}
				{this.renderNextButton()}
				{this.renderLastButton()}
			</div>
		);
	}

	/**
	 * Renders the first page button
	 * @returns The Buttton Element for the first page
	 */
	public renderFirstButton = () => {
		const { pageNo } = this.props;
		const isFirstPage = pageNo === 0;
		return (
			<Button
				disabled={isFirstPage}
				onClick={this.firstPage}
				display={Display.Text}
				labelVisible={false}
				className="icon-btn"
				icon={{ icon: 'first-page', iconPos: 'icon-only' }}
			>
				First
			</Button>
		);
	}

	/**
	 * Renders the previous page button
	 * @returns The Buttton Element for the previous page
	 */
	public renderPreviousButton = () => {
		const { pageNo } = this.props;
		const noPreviousPage = (pageNo < 1);
		return (
			<Button
				onClick={this.previousPage}
				display={Display.Text}
				disabled={noPreviousPage}
				labelVisible={false}
				className="icon-btn"
				icon={{ icon: 'chevron-left-2', iconPos: 'icon-only' }}
			>
				Previous
			</Button>
		);
	}

	/**
	 * Renders the Individual Page Buttons and Elipses where necessary.
	 * An elipsis is placed where there is a gap bigger than 2 spaces.
	 * @returns Returns the Individual Page Buttons and Elipses
	 */
	public renderPages = () => {
		const { pageNo } = this.props;
		// Check if there are spaces big enough for an elipsis at head and tail
		const headGap = (pageNo - 1) > 3;
		const tailGap = (this.totalPages - pageNo) > 3;
		// Initialise an array of page numbers that need to be shown, -1 == elipsis
		let indices: number[] = [];
		const elements: any[] = [];

		if (this.totalPages > 5) {
			indices.push(1);
			indices.push(2);
			if (headGap && tailGap) {
				indices.push(-1);
				// middle 3 pages
				indices = indices.concat(range(pageNo, pageNo + 3));
				indices.push(-1);
			} else if (headGap) {
				indices.push(-1);
				// last few pages
				indices = indices.concat(range(pageNo, this.totalPages - 1));
			} else if (tailGap) {
				// first few pages
				indices = indices.concat(range(3, pageNo + 2));
				indices.push(-1);
			} else {
				// no elipses
				indices = indices.concat(range(3, this.totalPages - 1));
			}
			indices.push(this.totalPages - 1);
			indices.push(this.totalPages);
			// loop through all page numbers and render buttons
			indices.forEach(i => {
				if (i !== -1) {
					this.addButton(elements, i);
				} else {
					elements.push(<p key="many">...</p>);
				}
			});
		} else {
			// add all page numbers
			for (let i = 0; i < this.totalPages; i++) {
				this.addButton(elements, i + 1);
			}
		}
		return elements;
	}

	/**
	 * Method to add page number button to the elements list
	 * @param elements This list of page number buttons
	 * @param pageNo The page number
	 */
	private addButton(elements: any[], pageToBeSet: number) {
		const { pageNo } = this.props;
		elements.push(
			<Button
				key={pageToBeSet}
				className={`page-no ${pageNo === pageToBeSet - 1 ? 'selected' : ''}`}
				display={Display.Text}
				onClick={() => this.gotoPage(pageToBeSet - 1)}
			>
				{pageToBeSet}
			</Button>,
		);
	}

	/**
	 * Renders the next page button
	 * @returns The Buttton Element for the next page
	 */
	public renderNextButton = () => {
		const { pageNo } = this.props;
		const noNextPage = pageNo >= (this.totalPages - 1);
		return (
			<Button
				key="next"
				onClick={this.nextPage}
				display={Display.Text}
				disabled={noNextPage}
				labelVisible={false}
				className="icon-btn"
				icon={{ icon: 'chevron-right-2', iconPos: 'icon-only' }}
			>
				Next
			</Button>
		);
	}

	/**
	 * Renders the laset page button
	 * @returns The Buttton Element for the last page
	 */
	public renderLastButton = () => {
		const { pageNo } = this.props;
		const isLastPage = (pageNo >= (this.totalPages - 1));
		return (
			<Button
				key="last"
				onClick={this.lastPage}
				display={Display.Text}
				disabled={isLastPage}
				labelVisible={false}
				className="icon-btn"
				icon={{ icon: 'last-page', iconPos: 'icon-only' }}
			>
				Last
			</Button>
		);
	}

	private firstPage = () => {
		this.gotoPage(0);
	}

	private previousPage = () => {
		const { pageNo } = this.props;
		this.gotoPage(pageNo - 1);
	}

	private nextPage = () => {
		const { pageNo } = this.props;
		this.gotoPage(pageNo + 1);
	}

	private lastPage = () => {
		this.gotoPage(this.totalPages - 1);
	}

	@action
	/**
	 * Method to determine if page is valid, and chagnes page number if valid
	 * @param pageNo The page number to go to
	 */
	public gotoPage = (pageNo: number) => {
		const { onPageChange } = this.props;

		const validPage = this.isValidPage(pageNo);

		let correctedPageNo: number = pageNo;
		if (validPage === validPageOptions.HIGH) {
			correctedPageNo = this.totalPages - 1;
		} else if (validPage === validPageOptions.LOW) {
			correctedPageNo = 0;
		}

		onPageChange(correctedPageNo);
	}

	/**
	 * Method to check if a page number is valid.
	 * @param pageNo Page number to check
	 * @returns Returns enum if the page was valid, too high or too low
	 */
	private isValidPage = (pageNo: number): validPageOptions => {
		if (pageNo >= this.totalPages) {
			return validPageOptions.HIGH;
		} if (pageNo < 0) {
			return validPageOptions.LOW;
		}
		return validPageOptions.VALID;
	}

	/**
	 * Method to get the number of pages
	 * @returns The total number of pages
	 */
	private get totalPages() {
		const { perPage, totalRecords } = this.props;

		if (totalRecords > 0) {
			return Math.ceil(totalRecords / perPage);
		}

		return 1;
	}
}

export default TablePagination;
