import React, { useState } from 'react';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import RequestWrap, { GetRefreshKey } from 'Views/Components/RequestWrap/RequestWrap';
import { RegistrationEntity } from 'Models/Entities';
import EntityList from 'Views/Components/EntityList/EntityList';
import {
	registrationComboboxOptions,
	registrationStatus,
} from 'Models/Enums';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import classNames from 'classnames';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { gql } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import moment from 'moment';
import RegistrationStatusBox from 'Views/Components/Registration/RegistrationStatusBox';
import { buildSearchConditions } from '../../../Util/GraphQLUtils';

// Method to fetch and return the UserEntitys based on the page number and sorting
const fetchRegistrations = AwesomeDebouncePromise(async (
	search: string,
	sortColumn: string,
	sortDescending: boolean,
	page: number,
	status: registrationStatus | 'ALL',
	setCount: (newCount: number) => void,
) => {
	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['userName', 'userEmail', 'organisationName'], search, comparison);
	}

	// Setup status filter
	const statusConditions: IWhereCondition<string>[] = [];
	if (!!status && status !== 'ALL') {
		statusConditions.push({
			comparison: 'equal', path: 'status', value: status,
		});
	} else {
		statusConditions.push({
			path: 'status', comparison: 'equal', value: 'CREATED', negate: true,
		});
	}

	// Aggregate conditions into single nested list
	const allConditions: IWhereCondition<string>[][] = [];
	if (searchConditions.length > 0) {
		allConditions.push(searchConditions);
	}
	if (statusConditions.length > 0) {
		allConditions.push(statusConditions);
	}

	// Add sorting
	const orderBy: {path: string, descending: boolean}[] = [];
	orderBy.push({ path: sortColumn, descending: sortDescending });

	const query = gql`
		query registrationEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]]
		) {
			registrationEntitys(conditions: $conditions, skip: $skip, take:$take, orderBy: $orderBy) {
				id
				userName
				userEmail
				organisationName
				created
				products
				status
				startDate
			}
			countRegistrationEntitys(conditions: $conditions) {
				number
			}
		}
	`;

	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: REGISTRATIONS_PAGE_LENGTH,
			skip: REGISTRATIONS_PAGE_LENGTH * (page || 0),
			conditions: allConditions,
		},
	});
	setCount(data.countRegistrationEntitys.number);
	return data.registrationEntitys.map((user: any) => new RegistrationEntity(user)) as RegistrationEntity[];
}, 300, {
	// We use leading, so that one-off changes like sorting columns apply immediately
	// Changing the search text will trigger on the first character, and then again after the final change
	leading: true,
});

const RegistrationList = () => {
	// For the search bar display
	const [search, setSearch] = useState('');
	const [sortColumn, setSortColumn] = useState('userName');
	const [sortDescending, setSortDescending] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [status, setStatus] = useState('ALL' as registrationStatus | 'ALL');

	/**
	 * Callback for sorting changes
	 * @param header The name of the sorting column
	 */
	const onSortChange = (header: string) => {
		if (sortColumn === header) {
			setSortDescending(!sortDescending);
		} else {
			setSortDescending(false);
		}

		setSortColumn(header);
	};

	return (
		<div className="registration-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						onAfterChange={() => setPage(0)}
						labelVisible={false}
						placeholder="Search by user name, user email, or organisation name"
					/>

					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => {
							setShowFilters(show => !show);
							setStatus('ALL');
						}}
					>
						Filter By
					</Button>
				</div>
				<If condition={showFilters}>
					<div className="search-filter-section">
						<If condition={store.userType === 'SUPER_USER'}>
							<ComboboxSetter
								className="status-filter"
								value={status}
								setValue={setStatus}
								getOptionValue={(value?: string) => value}
								placeholder="Status"
								label=""
								onAfterSet={() => setPage(0)}
								labelVisible={false}
								searchable
								options={registrationComboboxOptions}
							/>
						</If>
					</div>
				</If>
			</div>
			<div className="collection-component">
				{/* Component to fetch and render the Registration List */}
				<RequestWrap
					request={() => fetchRegistrations(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
						setCount,
					)}
					refreshKey={GetRefreshKey(
						search,
						sortColumn,
						sortDescending,
						page,
						status,
					)}
				>
					{(registrations: RegistrationEntity[]) => (
						<>
							<EntityList
								collection={registrations}
								columns={[
									{
										displayName: 'Name',
										columnName: 'userName',
										value: registration => registration.userName
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('userName');
										},
										className: 'field-user-name',
									},
									{
										displayName: 'Organisation',
										columnName: 'organisationName',
										value: registration => registration.organisationName
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('organisationName');
										},
										className: 'field-organisation-name',
									},
									{
										displayName: 'Email Address',
										columnName: 'userEmail',
										value: registration => registration.userEmail
											?? <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('userEmail');
										},
										className: 'field-user-email',
									},
									{
										displayName: 'Start Date',
										columnName: 'startDate',
										value: registration => !!registration.startDate
											? moment(registration.startDate).format('DD/MM/YYYY')
											: <span className="not-provided">Not Provided</span>,
										sortable: true,
										sortClicked: () => {
											onSortChange('startDate');
										},
										className: 'field-start-date',
									},
									{
										displayName: 'Products',
										columnName: 'products',
										value: registration => registration.products
											?? <span className="not-provided">No Selection</span>,
										sortable: false,
										className: 'field-products',
									},
									{
										displayName: 'Status',
										columnName: 'status',
										value: registration => <RegistrationStatusBox status={registration.status} />,
										sortable: true,
										sortClicked: () => {
											onSortChange('status');
										},
										className: 'field-status',
									},
								]}
								idColumn="id"
								sortColumn={sortColumn}
								sortDescending={sortDescending}
								onClickRow={registration => {
									store.routerHistory.push(`/hub/registrations/${registration.id}`);
								}}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={REGISTRATIONS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default RegistrationList;

export const REGISTRATIONS_PAGE_LENGTH = 10;
