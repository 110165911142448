import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import AccessIntelSecuredPage from '../Components/Security/AccessIntelSecuredPage';
import MonitorExportCustomers from '../Components/Intel/MonitorExportCustomers';
import MonitorUploadRiskRatings from '../Components/Intel/MonitorUploadRiskRatings';
import MonitorAtoUpload from 'Views/Components/Intel/AtoUploadSection/MonitorAtoUpload';

export interface MonitorSettingsPageProps extends RouteComponentProps {}

const MonitorSettingsPage = (props: MonitorSettingsPageProps) => {
	return (
		<AccessIntelSecuredPage
			routeComponentProps={props}
			groups={['Super Administrators', 'SUPER_USER']}
			product="monitor"
		>
			<div className="body-content monitor-settings-page">
				<div className="invisible-page-wrap grow">
					<div className="top-container">
						<PageBreadcrumbs
							tags={
								[
									{ label: 'Settings' },
								]
							}
						/>
					</div>
					<div className="white-box">
						<MonitorExportCustomers />
						<MonitorUploadRiskRatings />
						<MonitorAtoUpload />
					</div>
				</div>
			</div>
		</AccessIntelSecuredPage>
	);
};

export default MonitorSettingsPage;
