import React from 'react';
import ESignAuthoriseStore from './ESignAuthoriseStore';
import ESignCommonStore from './ESignCommonStore';
import ESignWorkflowStore from './ESignWorkflowStore';

interface ESignStore {
	workflowStore: ESignWorkflowStore;
	authoriseStore: ESignAuthoriseStore;
	commonStore: ESignCommonStore;
}

export const eSignStore: ESignStore = {
	workflowStore: new ESignWorkflowStore(),
	commonStore: new ESignCommonStore(),
	authoriseStore: new ESignAuthoriseStore(),
};

export const ESignStoreContext = React.createContext<ESignStore>(eSignStore);

export function useESignStore() {
	return React.useContext(ESignStoreContext);
}
