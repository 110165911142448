import { RequestESignTemplateFields } from 'Views/Components/BusinessEntities/ESignTemplateSection/ESignRequestTemplateModal';
import alertToast from './ToastifyUtils';

export async function sendTemplateRequestEmail(fieldsAvailable: RequestESignTemplateFields) {
	alertToast('Successfully, Created an email template. Now attach document to the email and send it through.', 'success');
	// below is the email template given by Access Intell, for now this is static as the details shouldn't need to change
	const recipientEmail = 'support@accessintell.com';
	const ccEmail = 'admin@accessintell.com';
	const subject = 'Requesting to create a new Esign template';
	const body = (
		'Hi Access Intell Team,\r\n\r\n'
		+ `I would like to request a new e-signature template for ${fieldsAvailable.businessName.data}:\r\n`
		+ 'The new template details are as follows:\r\n\r\n'
		+ `\tBusiness Entity Name: ${fieldsAvailable.businessName.data} \r\n\r\n`
		+ `\tReason Required: ${fieldsAvailable.reasonRequired.data} \r\n\r\n`
		+ `\tTemplate Name: ${fieldsAvailable.templateName.data} \r\n\r\n`
		+ `\tLifespan in Days: ${fieldsAvailable.lifespanIndays.data} \r\n\r\n`
		+ `\tReminder Frequency in Days: ${fieldsAvailable.reminderFrequencyInDays.data}\r\n\r\n`
		+ `\tNumber of Signatory needed: ${fieldsAvailable.numberofSignees.data} \r\n\r\n`
		+ `\tRequire to be Witnessed: ${fieldsAvailable.requireWitness.data} \r\n\r\n`
		+ `\tSMS Required: ${fieldsAvailable.smsAuth.data} \r\n\r\n`
		+ 'Type of Template: Attach a template document example (PDF or Word Docs only) \r\n\r\n'
		+ 'Regards,\r\n'
		+ '(Please Mention your name here)'
	);
	// URL encode the data to replace the necessary characters
	return window.open(`mailto:${recipientEmail}?cc=${ccEmail}&subject=${encodeURIComponent(subject)}`
		+ `&body=${encodeURIComponent(body)}`);
}

export async function sendUpdateTemplateRequestEmail(fieldsAvailable: RequestESignTemplateFields) {
	alertToast('Successfully, Created an email template. Now attach updated document to the email and send it through.', 'success');
	// below is the email template given by Access Intell, for now this is static as the details shouldn't need to change
	const recipientEmail = 'support@accessintell.com';
	const ccEmail = 'admin@accessintell.com';
	const subject = 'Requesting to update an existing e-signature template';
	const body = (
		'Hi Access Intell Team,\r\n\r\n'
		+ `I would like to request an update for an exisiting e-signature template(${fieldsAvailable.templateName.data}) for ${fieldsAvailable.businessName.data}:\r\n`
		+ 'The updated template details are as follows:\r\n\r\n'
		+ `\tBusiness Entity Name: ${fieldsAvailable.businessName.data} \r\n\r\n`
		+ `\tReason Required: ${fieldsAvailable.reasonRequired.data} \r\n\r\n`
		+ `\tTemplate Name: ${fieldsAvailable.templateName.data} \r\n\r\n`
		+ `\tLifespan in Days: ${fieldsAvailable.lifespanIndays.data} \r\n\r\n`
		+ `\tReminder Frequency in Days: ${fieldsAvailable.reminderFrequencyInDays.data}\r\n\r\n`
		+ `\tNumber of Signatory needed: ${fieldsAvailable.numberofSignees.data} \r\n\r\n`
		+ `\tRequire to be Witnessed: ${fieldsAvailable.requireWitness.data} \r\n\r\n`
		+ `\tSMS Required: ${fieldsAvailable.smsAuth.data} \r\n\r\n`
		+ 'Type of Template: Attach a template document example (PDF or Word Docs only) \r\n\r\n'
		+ 'Regards,\r\n'
		+ '(Please Mention your name here)'
	);
	// URL encode the data to replace the necessary characters
	return window.open(`mailto:${recipientEmail}?cc=${ccEmail}&subject=${encodeURIComponent(subject)}`
		+ `&body=${encodeURIComponent(body)}`);
}
