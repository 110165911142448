import * as React from 'react';
import { observer } from 'mobx-react';
import { WizardSidebarComponentProps } from 'Views/Components/Wizard/Wizard';
import classnames from 'classnames';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { action } from 'mobx';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { CalculatePricing, PricingDetail, PricingDetailDto } from 'Util/PricingHelper';
import { store } from 'Models/Store';
import { LocaleFormatCurrency } from 'Util/StringUtils';

const ProductsStepSidebar = observer((props: WizardSidebarComponentProps) => {
	const { model, changePage } = props;

	const [fullMonthlyPricing, setFullMonthlyPricing] = React.useState<PricingDetail|null>(null);
	const [monthlyPricing, setMonthlyPricing] = React.useState<PricingDetail|null>(null);

	const [referralDiscount] = React.useState<number|null>(() => {
		const registrationReferralRaw = Cookies.get('registrationReferral');

		if (!registrationReferralRaw) {
			return null;
		}

		const { benefitPercent }: { referralId: string, benefitPercent: string | null } = JSON
			.parse(atob(registrationReferralRaw));

		if (!benefitPercent) {
			return null;
		}

		return parseInt(benefitPercent, 10);
	});

	useEffect(() => {
		CalculatePricing({
			OrganisationId: store.getUser?.organisation?.id,
			CustomerCount: model.activeCustomers,
			MonthlyExposure: model.monthlyExposure,
			UserCount: 1,
			IntelEnabled: model.organisation.intelEnabled,
			ApproveEnabled: model.organisation.approveEnabled,
			PpsrEnabled: model.organisation.ppsrEnabled,
			Discounts: model.organisation.discountss,
			IncludeAllProductDiscount: false,
		}).then(setFullMonthlyPricing);
	}, [
		model.activeCustomers, model.monthlyExposure, model.organisation?.discountss,
		model.organisation.intelEnabled, model.organisation.approveEnabled, model.organisation.ppsrEnabled,
	]);

	useEffect(() => {
		const discounts = [
			...model.organisation.discountss,
			{
				source: 'REFERRER',
				discountPercent: referralDiscount ?? 0,
			},
		];

		CalculatePricing({
			OrganisationId: store.getUser?.organisation?.id,
			CustomerCount: model.activeCustomers,
			MonthlyExposure: model.monthlyExposure,
			UserCount: 1,
			IntelEnabled: model.organisation.intelEnabled,
			ApproveEnabled: model.organisation.approveEnabled,
			PpsrEnabled: model.organisation.ppsrEnabled,
			Discounts: discounts,
		}).then(setMonthlyPricing);
	}, [
		model.activeCustomers, model.monthlyExposure, model.organisation.discountss,
		model.organisation.intelEnabled, model.organisation.approveEnabled, model.organisation.ppsrEnabled,
		referralDiscount,
	]);

	let nextButtonText = 'Get Started';
	let nextButtonDisabled = false;
	if (!model.organisation?.intelEnabled && !model.organisation?.approveEnabled && !model.organisation?.ppsrEnabled) {
		nextButtonText = 'Select Products';
		nextButtonDisabled = true;
	} else if (!model.termsAccepted) {
		nextButtonText = 'Accept Terms';
		nextButtonDisabled = true;
	}

	const onNext = () => {
		if (!nextButtonDisabled) {
			changePage();
		}
	};

	const getDiscountMessage = React.useCallback(() => {
		const hasReferralDiscount = !!referralDiscount;
		const hasMultiProductDiscount = (
			fullMonthlyPricing?.ppsrEnabled
			&& fullMonthlyPricing?.approveEnabled
			&& fullMonthlyPricing?.monitorEnabled
		);
		if (hasMultiProductDiscount && hasReferralDiscount) {
			return (
				<>
					<b>You&apos;re saving 20%</b> plus&nbsp;
					<b>an extra {referralDiscount}%</b> with your referral bonus!
				</>
			);
		}
		if (hasReferralDiscount) {
			return <><b>You&apos;re saving {referralDiscount}%</b> with your referral bonus!</>;
		}
		if (hasMultiProductDiscount) {
			return <>You&apos;re saving 20%</>;
		}

		return null;
	}, [fullMonthlyPricing, referralDiscount]);

	return (
		<div className="products-step-sidebar">
			<h5>Subscription plan</h5>
			<div className="product-selection-status-wrap">
				<div className={classnames('product-selection-status', 'intel', {
					selected: model.organisation?.intelEnabled,
				})}
				>
					<span className="product-name">Access Monitor</span>
					<span className="product-status">
						{model.organisation?.intelEnabled ? 'Selected' : 'Not Selected'}
					</span>
				</div>
				<div className={classnames('product-selection-status', 'approve', {
					selected: model.organisation?.approveEnabled,
				})}
				>
					<span className="product-name">Access Approve</span>
					<span className="product-status">
						{model.organisation?.approveEnabled ? 'Selected' : 'Not Selected'}
					</span>
				</div>
				<div className={classnames('product-selection-status', 'ppsr', {
					selected: model.organisation?.ppsrEnabled,
				})}
				>
					<span className="product-name">Access PPSR</span>
					<span className="product-status">
						{model.organisation?.ppsrEnabled ? 'Selected' : 'Not Selected'}
					</span>
				</div>
			</div>
			<div className="inclusions-wrap">
				<p className="inclusions-title">Inclusions</p>
				<div className="inclusions-list">
					<p className="inclusion icon-left icon-check-2 active">
						1 user
					</p>
					<p className={classnames('inclusion icon-left icon-check-2', {
						active: model.organisation?.approveEnabled,
					})}
					>
						Online trade application
					</p>
					<p className={classnames('inclusion icon-left icon-check-2', {
						active: model.organisation?.ppsrEnabled,
					})}
					>
						PPSR processing and management
					</p>
					<p className={classnames('inclusion icon-left icon-check-2', {
						active: model.organisation?.intelEnabled,
					})}
					>
						Real-time credit scores and alerts
					</p>
					<p className={classnames('inclusion icon-left icon-check-2', {
						active: model.organisation?.intelEnabled,
					})}
					>
						Monitoring on all your customers
					</p>
				</div>
			</div>
			<div className="pricing-wrap">
				<div className={classnames('discount-wrap', {
					// We make the element invisible, rather than removing it, so the layout doesn't shift around it
					visible: getDiscountMessage() !== null,
				})}
				>
					<p className="alert-box mini success">
						{getDiscountMessage() ?? <>&nbsp;</>}
					</p>
					<p className="original-price">
						<span className="text">Was</span>
						<span className="amount">{
							fullMonthlyPricing
								? `$ ${LocaleFormatCurrency(fullMonthlyPricing.total, false)}/mo`
								: 'N/A'
						}
						</span>
					</p>
				</div>
				<p className="total-price">
					<span className={classnames('text', { visible: getDiscountMessage() !== null })}>Now only</span>
					<span className="amount">{
						monthlyPricing
							? `$ ${LocaleFormatCurrency(monthlyPricing.total, false)}/mo`
							: 'N/A'
					}
					</span>
				</p>
				<p className="pricing-info">
					Billed monthly. Credit card fees<br />
					(subscription &amp; transactions) apply. Price is subject to change based on users, exposure{' '}
					value, and customer count.
				</p>
			</div>
			<div className="actions-wrap">
				<label htmlFor="accepted-terms" className="terms-label">
					<input
						type="checkbox"
						id="accepted-terms"
						onChange={action(() => {
							model.termsAccepted = model.termsAccepted !== true;
						})}
						checked={model.termsAccepted ?? false}
						aria-describedby="terms-description"
					/>
					<span id="terms-description">
						I have read and agree to the{' '}
						<a href="/api/files/document/privacy" target="_blank">
							Privacy Policy
						</a>{' '}
						and &nbsp;
						<a href="/api/files/document/terms" target="_blank">
							Terms &amp; Conditions
						</a>
					</span>
				</label>
				<Button
					className="next-btn"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={onNext}
					disabled={nextButtonDisabled}
				>
					{nextButtonText}
				</Button>
			</div>
		</div>
	);
});
export default ProductsStepSidebar;
