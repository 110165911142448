/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import UserReportRequest from 'Models/Security/Acl/UserReportRequest';
import VisitorsReportRequest from 'Models/Security/Acl/VisitorsReportRequest';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IReportRequestEntityAttributes extends IModelAttributes {
	requestDate: Date;
	paymentTransactionId: string;

	businessEntityId?: string;
	businessEntity?: Models.BusinessEntity | Models.IBusinessEntityAttributes;
	creditReportId: string;
	creditReport: Models.CreditReportEntity | Models.ICreditReportEntityAttributes;
	customerId?: string;
	customer?: Models.CustomerEntity | Models.ICustomerEntityAttributes;
	paymentStatusId: string;
	paymentStatus: Models.PaymentStatusEntity | Models.IPaymentStatusEntityAttributes;
	providerProductOrganisationId: string;
	providerProductOrganisation: Models.ProviderProductOrganisationEntity | Models.IProviderProductOrganisationEntityAttributes;
	userId: string;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ReportRequestEntity', 'Report Request')
// % protected region % [Customise your entity metadata here] end
export default class ReportRequestEntity extends Model
	implements IReportRequestEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new UserReportRequest(),
		new VisitorsReportRequest(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Request Date'] off begin
	@observable
	@attribute<ReportRequestEntity, Date>()
	@CRUD({
		name: 'Request Date',
		displayType: 'datetimepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public requestDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Request Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Payment TransactionId'] off begin
	/**
	 * Stripe Transaction id
	 */
	@observable
	@attribute<ReportRequestEntity, string>()
	@CRUD({
		name: 'Payment TransactionId',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public paymentTransactionId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Payment TransactionId'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.BusinessEntity,
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntityId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public businessEntity: Models.BusinessEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Credit Report'] off begin
		name: 'Credit Report',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.CreditReportEntity,
		// % protected region % [Modify props to the crud options here for reference 'Credit Report'] end
	})
	public creditReportId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public creditReport: Models.CreditReportEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer'] off begin
		name: 'Customer',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.CustomerEntity,
		// % protected region % [Modify props to the crud options here for reference 'Customer'] end
	})
	public customerId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public customer: Models.CustomerEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Payment Status'] off begin
		name: 'Payment Status',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.PaymentStatusEntity,
		// % protected region % [Modify props to the crud options here for reference 'Payment Status'] end
	})
	public paymentStatusId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public paymentStatus: Models.PaymentStatusEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] off begin
		name: 'Provider Product Organisation',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.ProviderProductOrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Provider Product Organisation'] end
	})
	public providerProductOrganisationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public providerProductOrganisation: Models.ProviderProductOrganisationEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IReportRequestEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IReportRequestEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.requestDate !== undefined) {
				if (attributes.requestDate === null) {
					this.requestDate = attributes.requestDate;
				} else {
					this.requestDate = moment(attributes.requestDate).toDate();
				}
			}
			if (attributes.paymentTransactionId !== undefined) {
				this.paymentTransactionId = attributes.paymentTransactionId;
			}
			if (attributes.businessEntityId !== undefined) {
				this.businessEntityId = attributes.businessEntityId;
			}
			if (attributes.businessEntity !== undefined) {
				if (attributes.businessEntity === null) {
					this.businessEntity = attributes.businessEntity;
				} else if (attributes.businessEntity instanceof Models.BusinessEntity) {
					this.businessEntity = attributes.businessEntity;
					this.businessEntityId = attributes.businessEntity.id;
				} else {
					this.businessEntity = new Models.BusinessEntity(attributes.businessEntity);
					this.businessEntityId = this.businessEntity.id;
				}
			}
			if (attributes.creditReportId !== undefined) {
				this.creditReportId = attributes.creditReportId;
			}
			if (attributes.creditReport !== undefined) {
				if (attributes.creditReport === null) {
					this.creditReport = attributes.creditReport;
				} else if (attributes.creditReport instanceof Models.CreditReportEntity) {
					this.creditReport = attributes.creditReport;
					this.creditReportId = attributes.creditReport.id;
				} else {
					this.creditReport = new Models.CreditReportEntity(attributes.creditReport);
					this.creditReportId = this.creditReport.id;
				}
			}
			if (attributes.customerId !== undefined) {
				this.customerId = attributes.customerId;
			}
			if (attributes.customer !== undefined) {
				if (attributes.customer === null) {
					this.customer = attributes.customer;
				} else if (attributes.customer instanceof Models.CustomerEntity) {
					this.customer = attributes.customer;
					this.customerId = attributes.customer.id;
				} else {
					this.customer = new Models.CustomerEntity(attributes.customer);
					this.customerId = this.customer.id;
				}
			}
			if (attributes.paymentStatusId !== undefined) {
				this.paymentStatusId = attributes.paymentStatusId;
			}
			if (attributes.paymentStatus !== undefined) {
				if (attributes.paymentStatus === null) {
					this.paymentStatus = attributes.paymentStatus;
				} else if (attributes.paymentStatus instanceof Models.PaymentStatusEntity) {
					this.paymentStatus = attributes.paymentStatus;
					this.paymentStatusId = attributes.paymentStatus.id;
				} else {
					this.paymentStatus = new Models.PaymentStatusEntity(attributes.paymentStatus);
					this.paymentStatusId = this.paymentStatus.id;
				}
			}
			if (attributes.providerProductOrganisationId !== undefined) {
				this.providerProductOrganisationId = attributes.providerProductOrganisationId;
			}
			if (attributes.providerProductOrganisation !== undefined) {
				if (attributes.providerProductOrganisation === null) {
					this.providerProductOrganisation = attributes.providerProductOrganisation;
				} else if (attributes.providerProductOrganisation instanceof Models.ProviderProductOrganisationEntity) {
					this.providerProductOrganisation = attributes.providerProductOrganisation;
					this.providerProductOrganisationId = attributes.providerProductOrganisation.id;
				} else {
					this.providerProductOrganisation = new Models.ProviderProductOrganisationEntity(attributes.providerProductOrganisation);
					this.providerProductOrganisationId = this.providerProductOrganisation.id;
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		businessEntity {
			${Models.BusinessEntity.getAttributes().join('\n')}
		}
		creditReport {
			${Models.CreditReportEntity.getAttributes().join('\n')}
		}
		customer {
			${Models.CustomerEntity.getAttributes().join('\n')}
		}
		paymentStatus {
			${Models.PaymentStatusEntity.getAttributes().join('\n')}
		}
		providerProductOrganisation {
			${Models.ProviderProductOrganisationEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ReportRequestEntity.prototype, 'created');
CRUD(modifiedAttr)(ReportRequestEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
