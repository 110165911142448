import { SERVER_URL } from 'Constants';
import axios, { AxiosError } from 'axios';

export type UserAuthTokenResponse = {
	userUrl: string;
	otpCode: string;
	hasAuthorised: boolean;
}

/**
 * Requests an OTP (One-Time Password) for E-Signature by a user. This function sends a POST request to the server
 * to generate a new OTP for the specified user and business entity. If the request is successful, it returns
 * the user authentication token response which includes the user URL, OTP code, and a flag indicating whether
 * the user has authorized. In case of an error, the function throws an AxiosError.
 *
 * @param {string} businessEntityId - The ID of the business entity for which the OTP is requested.
 * @param {string} userId - The ID of the user for whom the OTP is requested.
 * @returns {Promise<UserAuthTokenResponse | undefined>} A promise that resolves to the user auth token response
 *          containing the userUrl, otpCode, and hasAuthorised fields, or undefined if an error occurs.
 * @throws {AxiosError} Throws an AxiosError if the request fails due to network or server issues.
 */
export async function requestESignUserOtp(businessEntityId: string, userId: string): Promise<UserAuthTokenResponse | undefined> {
	try {
		const url = `${SERVER_URL}/api/entity/ESignuserEntity/${businessEntityId}/otp`;
		const response = await axios.post<UserAuthTokenResponse>(url, { userId: userId });
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
}
