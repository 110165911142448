/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsOrganisationmonitoringprovidersmanytomany from '../Security/Acl/VisitorsOrganisationmonitoringprovidersmanytomany';
import UserOrganisationmonitoringprovidersmanytomany from '../Security/Acl/UserOrganisationmonitoringprovidersmanytomany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMonitoringProviderOrganisationAttributes extends IModelAttributes {
	monitoringProviderId: string;
	organisationId: string;

	monitoringProvider: Models.MonitoringProviderEntity | Models.IMonitoringProviderEntityAttributes;
	organisation: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('MonitoringProviderOrganisation')
export default class MonitoringProviderOrganisation
	extends Model
	implements IMonitoringProviderOrganisationAttributes {
	public static acls: IAcl[] = [
		new VisitorsOrganisationmonitoringprovidersmanytomany(),
		new UserOrganisationmonitoringprovidersmanytomany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public monitoringProviderId: string;

	@observable
	@attribute()
	public organisationId: string;

	@observable
	@attribute({ isReference: true })
	public monitoringProvider: Models.MonitoringProviderEntity;

	@observable
	@attribute({ isReference: true })
	public organisation: Models.OrganisationEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IMonitoringProviderOrganisationAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.monitoringProviderId) {
				this.monitoringProviderId = attributes.monitoringProviderId;
			}
			if (attributes.organisationId) {
				this.organisationId = attributes.organisationId;
			}

			if (attributes.monitoringProvider) {
				if (attributes.monitoringProvider instanceof Models.MonitoringProviderEntity) {
					this.monitoringProvider = attributes.monitoringProvider;
					this.monitoringProviderId = attributes.monitoringProvider.id;
				} else {
					this.monitoringProvider = new Models.MonitoringProviderEntity(attributes.monitoringProvider);
					this.monitoringProviderId = this.monitoringProvider.id;
				}
			} else if (attributes.monitoringProviderId !== undefined) {
				this.monitoringProviderId = attributes.monitoringProviderId;
			}

			if (attributes.organisation) {
				if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			} else if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
