import React from 'react';
import { riskScore, riskScoreOptions } from '../../../Models/Enums';

export interface RiskScoreBoxProps {
	score?: riskScore;
}

const RiskScoreBox = (props: RiskScoreBoxProps) => {
	const { score } = props;

	const actualRiskScore = score ?? 'UNKNOWN';

	return (
		<div>
			<span className={`risk-score-box ${actualRiskScore}`}>
				{riskScoreOptions[actualRiskScore]}
			</span>
		</div>
	);
};

export default RiskScoreBox;
