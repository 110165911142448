import { ApproveSection } from "./ApproveSection"
import { MiscSection } from "./MiscSection"
import { MonitorSection } from "./MonitorSection"
import { PpsrSection } from "./PpsrSection"

export const QuickActionSection = () => {
	return (
		<div className="quick-action-wrap">
			<div className="white-box">
				<div className="quick-action">
					<ApproveSection />
				</div>
				<div className="quick-action">
					<PpsrSection />
				</div>
				<div className="quick-action">
					<MonitorSection />
				</div>
				<div className="quick-action">
					<MiscSection />
				</div>
			</div>
		</div>
	)
}