import ESignOverviewSection from 'ESign/Components/ESignOverviewSection';
import ESignNavbar from 'ESign/Components/Common/Navbar/ESignNavbar';
import ESignSigningSteps from 'ESign/Components/ESignSigningSteps';
import ErrorSection, { ErrorBoundary } from 'ESign/Components/Error/ErrorSection';
import { useESignStore } from 'ESign/Stores/ESignStore';
import LoadableState from 'ESign/Types/ESignLoadableState';
import Spinner from 'Views/Components/Spinner/Spinner';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import classNames from 'classnames';

type ESignSection = 'Overview' | 'Signing';

const ESignUserHomePage:React.FunctionComponent = () => {
	const [currentSection, setCurrentSection] = React.useState<ESignSection>('Overview');
	const {
		commonStore: { userDetail },
		workflowStore: {
			getUserSummary,
			summaryState,
			loadingState,
			setIsWorkflowExpired,
		},
	} = useESignStore();

	const handlePreviousSection = () => {
		setIsWorkflowExpired(false);
		setCurrentSection('Overview');
	};

	const renderESignSection = (section: ESignSection) => {
		switch (section) {
			case 'Overview':
				return (<ESignOverviewSection nextSection={() => setCurrentSection('Signing')} />);
			case 'Signing': {
				return (
					<ESignSigningSteps
						previousSection={handlePreviousSection}
						nextSection={() => setCurrentSection('Overview')}
					/>
				);
			}
			default:
				return (
					<ErrorSection
						title="An error has occurred."
						message="An error has occurred. Please refresh page and try again"
					/>
				);
		}
	};

	useEffect(() => {
		if (summaryState === LoadableState.Pending) {
			getUserSummary(userDetail.id);
		}
	}, [getUserSummary, userDetail.id, summaryState]);

	return (
		<ErrorBoundary>
			{currentSection === 'Overview' && <ESignNavbar />}
			<div
				className={classNames(
					'body-content',
					'esign-page',
					'esign-user-page',
				)}
			>
				{/* ensure the user refresh the page if state is rejected */}
				{loadingState === LoadableState.Rejected && (
					<ErrorSection
						title="An error has occurred."
						message="An error has occurred. Please refresh page and try again"
					/>
				)}
				{loadingState !== LoadableState.Rejected && (
					<div className="invisible-page-wrap centre vertically-center grow">
						{summaryState === LoadableState.Pending && (<Spinner />)}
						{summaryState !== LoadableState.Pending && (
							<div className={classNames('white-box', { 'set-width': currentSection === 'Overview' })}>
								{renderESignSection(currentSection)}
							</div>
						)}
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
};

export default observer(ESignUserHomePage);
