import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import Spinner from 'Views/Components/Spinner/Spinner';

const OrganisationRegistrationReferralPage = observer((props: RouteComponentProps<{ registrationId?: string}>) => {
	const { match } = props;

	if (store.loggedIn) {
		return <Redirect to="/" />;
	}

	const response = useAsync(async () => {
		// Start the referral registration.
		const result = await axios.post(
			`${SERVER_URL}/api/entity/RegistrationEntity/checkReferral/${match.params.registrationId}`,
		);

		const { continueRegistration } = result.data;

		if (continueRegistration) {
			// This referral registration was still in the created state, and ready to be started
			store.routerHistory.push(`/hub/registration/${match.params.registrationId}`);
		} else {
			// This referral registration has already been started, to push to start a new registration
			store.routerHistory.push('/hub/registration');
		}
	}, []);

	if (response.type === 'loading') {
		return <Spinner />;
	}

	if (response.type === 'error') {
		console.log(response.error);
		return (
			<div className="body-content organisation-registration-referral-page">
				<h1>Invalid Referral Link</h1>
				Please contact <a href="mailto:support@accessintell.com">support@accessintell.com</a>
			</div>
		);
	}

	return <Spinner />;
});

export default OrganisationRegistrationReferralPage;
