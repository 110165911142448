import { observer } from 'mobx-react';
import { AddressEntity, CustomerAuditEntity, CustomerEntity } from 'Models/Entities';
import React, { useCallback, useMemo, useState } from 'react';
import useStore from 'Hooks/useStore';
import useAsync from 'Hooks/useAsync';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { UserTypeOrder } from 'Models/Enums';
import { CreditApplication, FetchApproveApplications } from './ApproveApplicationTypes';
import EntityList from 'Views/Components/EntityList/EntityList';
import If from 'Views/Components/If/If';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import { isNumeric, LocaleFormatCurrency } from 'Util/StringUtils';
import moment from 'moment';
import classNames from 'classnames';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { TextField } from 'Views/Components/TextBox/TextBox';
import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import useHasChanged from 'Hooks/useHasChanged';
import alertToast from 'Util/ToastifyUtils';
import { CreateCustomerAudit, GetCustomerAuditsDescription } from './CustomerAudits/Gibs/FetchCustomerAudits';

export interface AccountInformationProps {
	customer: CustomerEntity,
}

const AccountInformation = observer((props: AccountInformationProps) => {
	const { customer } = props;
	const store = useStore();

	const [privateCustomer, setPrivateCustomer] = useState(() => {
		const newCustomer = new CustomerEntity(customer);
		newCustomer.physicalAddress = new AddressEntity(customer.physicalAddress);
		newCustomer.postalAddress = new AddressEntity(customer.postalAddress);
		return newCustomer;
	});
	const [page, setPage] = useState(0);

	const editDisabled = useMemo(() => {
		return store.userPermissions.intelManageCustomerAccountsInformation !== true;
	}, [store]);

	const customerSaveDisabled = useHasChanged(
		CustomerEntity,
		privateCustomer,
		['creditLimit', 'tradingTerms', 'nameForInvoice', 'accountsContact', 'accountsPhoneNumber',
			'taxInvoicesEmail', 'statementsEmail'],
		(oldValue, newValue) => oldValue !== newValue && !(!oldValue && !newValue),
		['errors'],
		(original, changed) => (Object.keys(changed?.errors ?? {}).length > 0),
	);

	const postalAddressSaveDisabled = useHasChanged(
		AddressEntity,
		privateCustomer?.postalAddress,
		['line1', 'line2', 'suburb', 'postcode', 'state', 'country'],
		(oldValue, newValue) => oldValue !== newValue && !(!oldValue && !newValue),
		['errors'],
		(original, changed) => (Object.keys(changed?.errors ?? {}).length > 0),
	);

	const physicalAddressSaveDisabled = useHasChanged(
		AddressEntity,
		privateCustomer?.physicalAddress,
		['line1', 'line2', 'suburb', 'postcode', 'state', 'country'],
		(oldValue, newValue) => oldValue !== newValue && !(!oldValue && !newValue),
		['errors'],
		(original, changed) => (Object.keys(changed?.errors ?? {}).length > 0),
	);

	// if there are errors present in any of the objects
	const errorsPresent = privateCustomer !== null ? React.useMemo(() => {
		if (Object.keys(privateCustomer?.errors ?? {}).length > 0) {
			return true;
		}
		if (Object.keys(privateCustomer?.postalAddress?.errors ?? {}).length > 0) {
			return true;
		}
		return Object.keys(privateCustomer?.physicalAddress?.errors ?? {}).length > 0;
	}, [privateCustomer?.errors, privateCustomer?.physicalAddress?.errors, privateCustomer?.postalAddress
		?.errors]) : false;

	const approveAccess = useMemo<('access' | 'no-access-organisation')>(() => {
		const organisationAccess = customer.businessEntity.organisation.approveEnabled;
		if (!organisationAccess) {
			return 'no-access-organisation';
		}
		return 'access';
	}, [customer]);

	const response = useAsync<FetchApproveApplications>(async () => {
		try {
			const { data } = await axios.get(`${SERVER_URL}/api/entity/CustomerEntity/applications/${customer.id}`);
			return data as FetchApproveApplications;
		} catch (e) {
			return {} as FetchApproveApplications;
		}
	}, [customer]);

	const onSave = useCallback(async () => {
		// Set the customer audits to be empty array to avoid duplicate audits for account informations.
		privateCustomer.customerAuditss = [];

		const customerAuditsDescription = GetCustomerAuditsDescription(
			customer,
			privateCustomer,
			'ACCOUNT_INFORMATION',
		);
		if (customerAuditsDescription !== undefined) {
			const newCustomerAudit = CreateCustomerAudit(customer.id, 'ACCOUNT_INFORMATION', customerAuditsDescription);

			privateCustomer.customerAuditss.push(newCustomerAudit);
		}

		try {
			await privateCustomer.validateAccountInformationFields();
			// if there are any errors present, we exit
			if (errorsPresent || Object.keys(privateCustomer?.errors ?? {}).length > 0
					|| Object.keys(privateCustomer?.postalAddress?.errors ?? {}).length > 0
					|| Object.keys(privateCustomer?.physicalAddress?.errors ?? {}).length > 0) {
				return;
			}

			await privateCustomer.saveWithFetchBack(
				{
					physicalAddress: {},
					postalAddress: {},
					customerAuditss: {},
				},
				{},
				`
					physicalAddress {
						${AddressEntity.getAttributes().join('\n')}
					}
					postalAddress {
						${AddressEntity.getAttributes().join('\n')}
					}
					customerAuditss {
						${CustomerAuditEntity.getAttributes().join('\n')}
					}
				`,
			);
			const newCustomer = new CustomerEntity(privateCustomer);
			newCustomer.physicalAddress = new AddressEntity(privateCustomer.physicalAddress);
			newCustomer.postalAddress = new AddressEntity(privateCustomer.postalAddress);
			setPrivateCustomer(newCustomer);
			customer.assignAttributes(newCustomer);
			alertToast('Account information saved', 'success');
		} catch {
			alertToast('Account information could not be saved, please refresh and try again', 'error');
		}
	}, [privateCustomer, customer, errorsPresent]);

	const validateField = useCallback(async (field: string, alwaysRun: boolean = false) => {
		if (alwaysRun || !!privateCustomer.errors[field]) {
			await privateCustomer.validateField(field);
		}
	}, [privateCustomer]);

	const renderCantAccessApprove = useCallback(() => {
		if (UserTypeOrder[store.userType] > UserTypeOrder.ADMIN) {
			return (
				<div className="cant-access-approve">
					<div className="content">
						<h3>Looking for Access Approve?</h3>
						<p>
							Approve allows you to receive digital trade applications with verified data and the ability
							to approve new customers with confidence in seconds, not days.
						</p>
						<p>
							To setup Approve, please click below.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => {
								const myCustomer = customer.businessEntity.organisation.id === store
									.getUser?.organisation?.id;

								if (myCustomer && approveAccess === 'no-access-organisation') {
									store.routerHistory.push('/hub/organisation/products');
								} else {
									store.routerHistory.push(`/hub/clients/${customer.businessEntity
										.organisation.id}/products`);
								}
							}}
							className="approve-setup"
						>
							Approve product setup
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="cant-access-approve">
				<div className="content">
					<h3>Looking for Access Approve?</h3>
					<p>
						Approve allows you to receive digital trade applications with verified data and the ability to
						approve new customers with confidence in seconds, not days.
					</p>
					<br />
					<p>
						To setup Approve, please contact your Organisation Manager.
					</p>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						onClick={() => {
							window.open(store.appSettings.approveMarketingUrl, '_blank');
						}}
						className="approve-learn-more"
					>
						Learn more about Access Approve
					</Button>
				</div>
			</div>
		);
	}, [approveAccess, store, customer]);

	return (
		<div className="account-information">
			<h4>Account information</h4>
			<If condition={response.type === 'loading' || !response.data}>
				<InlineSpinner />
			</If>
			<If condition={response.type !== 'loading' && !!response.data && approveAccess !== 'access'}>
				{renderCantAccessApprove()}
			</If>
			<If condition={response.type !== 'loading' && !!response.data && approveAccess === 'access'}>
				<p className="section-title">Customer applications</p>
				<div className="collection-component">
					<EntityList
						collection={response.data?.creditApplications?.slice(
							(APPLICATIONS_PAGE_LENGTH * page),
							(APPLICATIONS_PAGE_LENGTH * page) + APPLICATIONS_PAGE_LENGTH,
						) ?? []}
						columns={[
							{
								displayName: 'App ID',
								columnName: 'id',
								value: (application: CreditApplication) => application.id,
								sortable: false,
								className: 'field-id',
							},
							{
								displayName: 'App name',
								columnName: 'name',
								value: (application: CreditApplication) => application.applicationName,
								sortable: false,
								className: 'field-name',
							},
							{
								displayName: 'Credit applied',
								columnName: 'applied',
								value: (application: CreditApplication) => isNumeric(application.requestedAmount)
									? LocaleFormatCurrency(Number.parseFloat(application.requestedAmount))
									: 'N/A',
								sortable: false,
								className: 'field-applied',
							},
							{
								displayName: 'Credit approved',
								columnName: 'approved',
								value: (application: CreditApplication) => isNumeric(application.creditApprovedAmount)
									? LocaleFormatCurrency(Number.parseFloat(application.creditApprovedAmount))
									: 'N/A',
								sortable: false,
								className: 'field-approved',
							},
							{
								displayName: 'Submitted',
								columnName: 'submitted',
								value: (application: CreditApplication) => !application.startDate
									? 'N/A'
									: moment(application.startDate, 'DD/MM/YYYY').format('D MMM YYYY'),
								sortable: false,
								className: 'field-submitted',
							},
							{
								displayName: 'Completed',
								columnName: 'completed',
								value: (application: CreditApplication) => !application.completedDate
									? 'N/A'
									: moment(application.completedDate, 'DD/MM/YYYY').format('D MMM YYYY'),
								sortable: false,
								className: 'field-completed',
							},
							{
								displayName: 'Status',
								columnName: 'status',
								value: (application: CreditApplication) => (
									<span className={classNames({
										approved: application.applicationStatus.toLowerCase() === 'approved',
										declined: application.applicationStatus.toLowerCase() === 'declined',
									})}
									>
										{application.applicationStatus}
									</span>
								),
								sortable: false,
								className: 'field-status',
							},
							{
								displayName: '',
								columnName: '',
								value: (application: CreditApplication) => (
									// eslint-disable-next-line max-len
									<a href={`${store.appSettings.approveBaseUrl}/index.php?page=Display_Summary.php&APP_CR=${application.id}`}>
										APPROVE
									</a>
								),
								sortable: false,
								className: 'field-access-approve',
							},
						].filter(x => x.className !== 'field-access-approve' || store.canAccessApprove)}
						idColumn="id"
						sortColumn="id"
						sortDescending
					/>
					<section className="collection__load">
						<TablePagination
							perPage={3}
							pageNo={page}
							totalRecords={response.data?.creditApplications?.length ?? 0}
							onPageChange={setPage}
						/>
					</section>
				</div>
			</If>

			<p className="section-title">Account information</p>
			<div className="input-section">
				<div className="input-row">
					<TextField
						className="credit-limit"
						model={privateCustomer}
						modelProperty="creditLimit"
						label="Current credit limit"
						labelVisible
						placeholder="Credit limit"
						isDisabled={editDisabled}
					/>
					<TextField
						className="trading-terms"
						model={privateCustomer}
						modelProperty="tradingTerms"
						label="Payment trading terms"
						labelVisible
						placeholder="Trading terms"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="name-for-invoice"
						model={privateCustomer}
						modelProperty="nameForInvoice"
						label="Name to appear on invoice"
						labelVisible
						placeholder="Name for invoice"
						isDisabled={editDisabled}
					/>
					<TextField
						className="accounts-contact"
						model={privateCustomer}
						modelProperty="accountsContact"
						label="Accounts contact"
						labelVisible
						placeholder="Accounts contact name"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="accounts-phone-number"
						model={privateCustomer}
						modelProperty="accountsPhoneNumber"
						label="Accounts contact number"
						labelVisible
						placeholder="Accounts contact number"
						onAfterChange={_ => validateField('accountsPhoneNumber')}
						onChangeAndBlur={_ => validateField('accountsPhoneNumber', true)}
						errors={privateCustomer.errors.accountsPhoneNumber}
						isDisabled={editDisabled}
					/>
					<TextField
						className="tax-invoices-email"
						model={privateCustomer}
						modelProperty="taxInvoicesEmail"
						label="Tax invoice email"
						labelVisible
						placeholder="Tax invoice email"
						onAfterChange={_ => validateField('taxInvoicesEmail')}
						onChangeAndBlur={_ => validateField('taxInvoicesEmail', true)}
						errors={privateCustomer.errors.taxInvoicesEmail}
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="statements-email"
						model={privateCustomer}
						modelProperty="statementsEmail"
						label="Statements email"
						labelVisible
						placeholder="Statements email"
						onAfterChange={_ => validateField('statementsEmail')}
						onChangeAndBlur={_ => validateField('statementsEmail', true)}
						errors={privateCustomer.errors.statementsEmail}
						isDisabled={editDisabled}
					/>
				</div>
			</div>

			<p className="section-title">Physical address</p>
			<div className="input-section">
				<div className="input-row">
					<TextField
						className="physical-line-1"
						label="Address 1"
						placeholder="Address 1"
						model={privateCustomer.physicalAddress}
						modelProperty="line1"
						isDisabled={editDisabled}
					/>
					<TextField
						className="physical-line-2"
						label="Address 2"
						placeholder="Address 2"
						model={privateCustomer.physicalAddress}
						modelProperty="line2"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="physical-suburb"
						label="Suburb"
						placeholder="Suburb"
						model={privateCustomer.physicalAddress}
						modelProperty="suburb"
						isDisabled={editDisabled}
					/>
					<TextField
						className="physical-state"
						label="State"
						placeholder="State"
						model={privateCustomer.physicalAddress}
						modelProperty="state"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="physical-postcode"
						label="Postcode"
						placeholder="Postcode"
						model={privateCustomer.physicalAddress}
						modelProperty="postcode"
						onAfterChange={_ => validateField('physicalAddress.postcode')}
						onChangeAndBlur={_ => validateField('physicalAddress.postcode', true)}
						errors={privateCustomer.physicalAddress?.errors.postcode}
						isDisabled={editDisabled}
					/>
					<TextField
						className="physical-country"
						label="Country"
						placeholder="Country"
						model={privateCustomer.physicalAddress}
						modelProperty="country"
						isDisabled={editDisabled}
					/>
				</div>
			</div>

			<p className="section-title">Postal address</p>
			<div className="input-section">
				<div className="input-row">
					<TextField
						className="postal-line-1"
						label="Address 1"
						placeholder="Address 1"
						model={privateCustomer.postalAddress}
						modelProperty="line1"
						isDisabled={editDisabled}
					/>
					<TextField
						className="postal-line-2"
						label="Address 2"
						placeholder="Address 2"
						model={privateCustomer.postalAddress}
						modelProperty="line2"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="postal-suburb"
						label="Suburb"
						placeholder="Suburb"
						model={privateCustomer.postalAddress}
						modelProperty="suburb"
						isDisabled={editDisabled}
					/>
					<TextField
						className="postal-state"
						label="State"
						placeholder="State"
						model={privateCustomer.postalAddress}
						modelProperty="state"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-row">
					<TextField
						className="postal-postcode"
						label="Postcode"
						placeholder="Postcode"
						model={privateCustomer.postalAddress}
						modelProperty="postcode"
						onAfterChange={_ => validateField('postalAddress.postcode')}
						onChangeAndBlur={_ => validateField('postalAddress.postcode', true)}
						errors={privateCustomer.postalAddress?.errors.postcode}
						isDisabled={editDisabled}
					/>
					<TextField
						className="postal-country"
						label="Country"
						placeholder="Country"
						model={privateCustomer.postalAddress}
						modelProperty="country"
						isDisabled={editDisabled}
					/>
				</div>
			</div>

			<ButtonAsyncState
				className="save"
				colors={Colors.Primary}
				display={Display.Solid}
				onPress={() => onSave()}
				readonly={editDisabled || (customerSaveDisabled && postalAddressSaveDisabled
					&& physicalAddressSaveDisabled) || errorsPresent}
				waitingText="Saving..."
			>
				Save changes
			</ButtonAsyncState>
		</div>
	);
});

export default AccountInformation;

export const APPLICATIONS_PAGE_LENGTH = 3;
