import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { PageBreadcrumbs } from 'Views/Components/Breadcrumbs/PageBreadcrumbs';
import ReferralPartnersList from 'Views/Components/ReferralPartners/ReferralPartnersList';
import RegistrationTotal from 'Views/Components/ReferralPartners/RegistrationTotal';
import AccessIntelSecuredPage from 'Views/Components/Security/AccessIntelSecuredPage';

export interface RegistrationListPageProps extends RouteComponentProps {
}

const ReferralPartnersListPage = (props: RegistrationListPageProps) => {
	return (
		<AccessIntelSecuredPage routeComponentProps={props}>
			<div className="body-content list-page referral-partner-list-page">
				<div className="top-container">
					<PageBreadcrumbs
						tags={
							[
								{ label: 'Referral Partners', _isCurrentTag: true },
							]
						}
					/>
					<RegistrationTotal
						entityName="referral partners"
						statusField="referralStatus"
					/>
				</div>
				<ReferralPartnersList />
			</div>
		</AccessIntelSecuredPage>
	);
};
export default ReferralPartnersListPage;
