import * as React from 'react';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';
import { store } from 'Models/Store';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import {
	CustomerEntity, GroupEntity, GroupsCustomers, IGroupEntityAttributes,
} from 'Models/Entities';
import MultiComboboxSetter from 'Views/Components/Combobox/MultiComboboxSetter';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { useCallback, useState } from 'react';
import _ from 'lodash';

export interface GroupSelectorProps {
	className?: string;
	customer: CustomerEntity, // should be observable
	errors?: string | string[];
	isDisabled?: boolean,
	isRequired?: boolean,
	onAfterChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
}

const GroupSelector = observer((props: GroupSelectorProps) => {
	const {
		customer, isDisabled, isRequired, onAfterChange, className, errors,
	} = props;

	// these are used to help with assigning groups to customers
	const [allGroups, setAllGroups] = useState<GroupEntity[]>([]);
	const [tempGroups, setTempGroups] = useState<GroupEntity[]>([]);
	// used to re-render component when item is selected
	const [refresh, setRefresh] = useState(0);

	// callback for fetching group entities
	const getGroupOptions = useCallback(async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		const groupsResult = await store.apolloClient
			.query({
				query: gql`
							query fetchGroupEntities {
								groupEntitys (
									where: [
									{
										path: "organisationId"
										comparison: equal,
										value: ["${customer.organisationId}"],
									}],
									orderBy: [{path: "name", descending: false}],
								) {
									${GroupEntity.getAllAttributes().join('\n')}
								}
							}
						`,
				fetchPolicy: 'no-cache',
			});

		const groups = groupsResult.data.groupEntitys
			.map((x: Partial<IGroupEntityAttributes> | undefined) => {
				return new GroupEntity(x);
			});
		setAllGroups(groups);
		// we return the list of temp groups as well for when a new group has been created but not been saved yet
		return [...groups, ...tempGroups].map((x: GroupEntity) => {
			return {
				display: x.name,
				value: x.name,
			};
		});
	}, [tempGroups, customer]);

	// combobox onSet callback
	const onSetValue = useCallback((values: string[], event?: React.SyntheticEvent<HTMLElement, Event>,
		data?: DropdownProps) => {
		// ensure we have received an array of values
		if (values.length === 0 && Array.isArray(data?.value) && data?.value.length !== 0) {
			return;
		}
		runInAction(() => {
			customer.groupss = values
				.map((x: string) => {
					// if a group with the same name is assigned to the customer previously we use that
					const customerGroup = customer.groupss.find(y => y.groups.name === x);
					if (customerGroup) {
						return customerGroup;
					}
					// if it is not currently assigned and it it's an existing database group we create
					// a new reference with the given group
					const group = allGroups.find(y => y.name === x);
					if (group) {
						return new GroupsCustomers({
							customersId: customer.id,
							groupsId: group.id,
							groups: group,
						});
					}
					// create a new reference with the new group
					return new GroupsCustomers({
						customersId: customer.id,
						groups: new GroupEntity({
							name: x,
							organisationId: customer.organisationId,
						}),
					});
				});
		});
	}, [customer, allGroups]);

	// combobox onAddItem callback
	const onAddItem = useCallback((event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		// If a group already exists for group name and organisation
		// return and take no action
		if (customer.groupss.some(g => g.groups.name === data.value?.toString()
			&& g.groups.organisationId === customer.organisationId)
		) {
			return;
		}

		const group = new GroupEntity({
			name: data.value?.toString(),
			organisationId: customer.organisationId,
		});
		runInAction(() => {
			const old = [...customer.groupss];
			old.push(new GroupsCustomers({
				customersId: customer.id,
				groups: { ...group },
			}));

			customer.groupss = [...old];
			setRefresh(oldRefresh => oldRefresh + 1);
			setTempGroups(prevState => [...prevState, new GroupEntity({ ...group })]);
		});
	}, [customer]);

	const hideCancelClass = store.userPermissions.intelManageCustomerGroups !== true
		? 'hide-delete'
		: '';

	return (
		<MultiComboboxSetter
			className={classNames('group-selector', hideCancelClass, className)}
			label="Groups"
			placeholder="Groups"
			value={customer.groupss.map(x => x.groups.name)}
			setValue={onSetValue}
			getOptionValue={(value?: string) => value}
			isDisabled={isDisabled}
			isRequired={isRequired}
			initialOptions={getGroupOptions}
			options={AwesomeDebouncePromise(getGroupOptions, 500)}
			searchable
			onAfterSet={onAfterChange}
			errors={errors}
			key={refresh}
			inputProps={{
				// only allow additions if we can manage groups
				allowAdditions: store.userPermissions.intelCreateCustomerGroups === true,
				additionLabel: 'Add new group: ',
				additionPosition: 'top',
				onAddItem: onAddItem,
				search: true,
			}}
		/>
	);
});

export default GroupSelector;
