import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import Modal from 'Views/Components/Modal/Modal';
import { Button, Display } from 'Views/Components/Button/Button';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import { action } from 'mobx';
import { SERVER_URL } from 'Constants';

export type ExportInvoicesModalProps = {
	onClose: () => void,
}

const ExportInvoicesModal = observer((props: ExportInvoicesModalProps) => {
	const {
		onClose,
	} = props;

	const modalState = useLocalStore(() => ({
		date: undefined as Date|undefined,
	}));

	const submit = () => {
		if (!modalState.date) {
			return;
		}

		const year = modalState.date.getFullYear();
		const month = modalState.date.getMonth() + 1; // Months are zero-indexed, but we need the actual number
		window.open(
			`${SERVER_URL}/api/entity/InvoiceEntity/export/${year}/${month}`,
			'_blank',
		);
		onClose();
	};

	return (
		<Modal
			isOpen
			label="Export client invoices"
			onRequestClose={onClose}
			className="access-modal"
		>
			<h4>Export client invoices</h4>
			<p>
				Export your invoices and save to a CSV file
			</p>
			<DatePicker
				model={modalState}
				modelProperty="month"
				placeholder="Select month"
				flatpickrProps={{
					options: {
						plugins: [
							new (monthSelectPlugin as any)({
								shorthand: true,
								dateFormat: 'Z',
								altFormat: 'F Y',
							}),
						],
						altInput: true,
					},
				}}
				onAfterChange={action((dateList, dateString) => {
					// Recalculate the date from the date string, since we just want the month and year, and don't want
					// the timezone to affect it.
					modalState.date = moment.utc(dateString).toDate();
				})}
			/>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Cancel</Button>
				<Button
					key="confirm"
					onClick={submit}
					display={Display.Solid}
					disabled={!modalState.date}
				>
					Export
				</Button>
			</div>
		</Modal>
	);
});
export default ExportInvoicesModal;
